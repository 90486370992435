import {
  UpdateDocumentDocument,
  MoveDocumentDocument,
  CreateDocumentDocument,
  CopyDocumentDocument,
  WorkspaceDocumentFragment
} from "@/graphql/operations";
import { v4 as uuid } from "uuid";
import graphqlClient from './graphql/client';
import * as Sentry from "@sentry/vue";

interface CreateProperties {
  workspaceId: string;
  storageId: string;
  thumbnailStorageId: string;
  thumbnailDownloadUrl: string;
  downloadUrl: string;
  fileName: string;
  topTitle: string;
  displayTitle: string;
  pages: number;
  fileSize: string;
  mimeType: string;
  originalMimeType: string | null;
  externalLinkId: string;
  externalId?: string;
  expirationDate?: string;
}

interface UpdateFileProperties {
  storageId: string;
  thumbnailStorageId: string;
  thumbnailDownloadUrl: string;
  downloadUrl: string;
  pages: number;
  fileSize: string;
  mimeType: string;
}

interface UpdateProperties {
  displayTitle: string,
  teaserText: string,
  topTitle: string,
  tags: Array<string>,
  doesSupportSharedspace: boolean
}

export default class DocumentService {

  static async copyToWorkspace(doc: WorkspaceDocumentFragment, targetWorkspaceId: string) {
    const result = await graphqlClient.mutation(
      CopyDocumentDocument,
      {
        withId: doc.id,
        newName: doc.displayTitle,
        targetWorkspaceId: targetWorkspaceId
      }
    ).toPromise();

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }

    return result?.data?.copyDocument?.copiedDocument?.id;
  }

  static async moveToWorkspace(
    doc: WorkspaceDocumentFragment,
    curWorkspaceId: string,
    newWorkspaceId: string) {
    const postgresId = doc.id;

    const result = await graphqlClient.mutation(
      MoveDocumentDocument,
      {
        documentId: postgresId,
        toWorkspaceId: newWorkspaceId
      }
    ).toPromise();

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }
  }

  static async create(props: CreateProperties) {
    const result = await graphqlClient.mutation(
      CreateDocumentDocument,
      {
        document: props
      }
    ).toPromise();

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }

    return result?.data?.createDocument?.document;
  }

  static async updateFile(
    postgresId: string,
    props: UpdateFileProperties) {
    const result = await graphqlClient.mutation(
      UpdateDocumentDocument,
      {
        id: postgresId,
        patch: props
      }
    ).toPromise();

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }
  }

  static async update(postgresId: string, props: UpdateProperties) {
    console.debug(`updating document ${postgresId}...`)
    const result = await graphqlClient.mutation(
      UpdateDocumentDocument,
      {
        id: postgresId,
        patch: props
      }
    ).toPromise();
    console.debug(`successfully updated document ${postgresId}`)

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }
  }

  static async delete(postgresId: string) {
    const result = await graphqlClient.mutation(
      UpdateDocumentDocument,
      {
        id: postgresId,
        patch: {
          deletedAt: new Date()
        }
      }
    ).toPromise();

    if (result.error) {
      console.error(result.error.message);
      Sentry.captureException(result.error.message);
      throw result.error;
    }
  }
}
