<template>
  <img
    ref="iconImage"
    :src="computedUrl"
    :alt="name"
    :class="iconSize"
    @error="handleImageError"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const iconImage = ref<HTMLImageElement | null>(null);

const iconSize = computed(() => {
  if (props.name === "info") {
    return "tall";
  }
  else if (props.name === "sf_plus.rectangle.fill" || props.name === "arrow.right" || props.name === "sf_link"){
    return "wide";
  }
  return "squared";
});

const computedUrl = computed(() => new URL(`/src/assets/sf_icons/${props.name}.svg`, import.meta.url).href);

const handleImageError = () => {
  if (iconImage.value) {
    iconImage.value.src = new URL(`/src/assets/sf_icons/arrow.right.svg`, import.meta.url).href;;
  }
};
</script>

<style lang="scss" scoped>
img {
  &.wide {
    width: 40px;
    height: auto;
  }

  &.tall {
    width: auto;
    height: 30px;
  }

  &.squared {
    width: 40px;
    max-height: 40px;
  }
}
</style>