<template>
  <div class="page_content">
    <div>
      <div v-if="myMembership">
        <button class="button secondary" @click="displayLeaveConfirmation = true">
          <span class="material-symbols-outlined">
            logout
          </span>
          {{ $t("teamDetail.leaveTeam.buttonText") }}
        </button>

        <breadcrumbs :items="navItems"></breadcrumbs>

        <Tabs :items="tabItems"></Tabs>
      </div>
    </div>

    <router-view></router-view>

    <template v-if="displayLeaveConfirmation">
      <Modal :active="true" @close="displayLeaveConfirmation = false">
        <template v-slot:header>{{
          $t("teamDetail.leaveTeam.modalHeader")
        }}</template>
        <template v-slot:body>
          {{ $t("teamDetail.leaveTeam.confirmationText") }}
        </template>
        <template v-slot:footer>
          <div class="buttons">
            <button class="button primary" @click="leaveCurrentTeam">
              {{ $t("teamDetail.leaveTeam.submit.buttonText") }}
            </button>
            <button
              class="button"
              @click="displayLeaveConfirmation = false"
            >
              {{ $t("teamDetail.leaveTeam.cancel.buttonText") }}
            </button>
          </div>
        </template>
      </Modal>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import {
  useTeamDetailsByIdQuery,
  useLeaveTeamMutation
} from "@/graphql/operations";
import { Breadcrumbs, Modal, Tabs } from "@/components";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const teamId = route.params.id as string;

const tabItems = computed(() => {
  return [
    {
      title: "Members",
      routeParams: { name: "TeamMembers", params: route.params },
    },
    {
      title: "Workspaces",
      routeParams: { name: "TeamWorkspaces", params: route.params }
    }
  ]
});

const displayLeaveConfirmation = ref<boolean>(false);

// fetch team with id given in route
const { data: teamResults, fetching: loading } = useTeamDetailsByIdQuery({
  variables: {
    id: teamId
  }
});

const team = computed(() => teamResults?.value?.team);
const myMembership = computed(() => team.value?.myMembership);

const navItems = computed(() => [
  {
    text: "Teams",
    href: "/u/settings",
  },
  {
    text: team.value?.name,
  },
]);

const { executeMutation: leaveTeam } = useLeaveTeamMutation();

async function leaveCurrentTeam() {
  await leaveTeam({ teamId: teamId });
  router.push({ name: "SettingsOverview" });
}
</script>

<style lang="scss" scoped>
$border-strong: #CECECE;
.breadcrumbs {
  font-size: 1.2rem;
  margin: 0 0 1em 0;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.4;
}

section {
  margin-bottom: 40px;
}

.cards {
  overflow: auto;
  padding-bottom: 10px;
}

.card {
  border-radius: .3em;
  border: 1px solid $border-strong;
  color: #4a4a4a;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  font-size: 0.9rem;
  background-color: #fff;
  border-radius: 5px;
  width: 150px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
}

.button {
  float: right;
  font-size: 0.9em;
  margin-left: 5px;
}
</style>
