<template>
  <div id="app_public">
    <NavbarNoAuth v-if="hasNavbar" />
    <PublicHeader v-else-if="hasHeader" />
    <div class="body">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { PublicHeader, NavbarNoAuth } from "@/components";

const route = useRoute();

const hasNavbar = computed(() => route.meta.hasNavbar);
const hasHeader = computed(() => route.meta.hasHeader !== false);

document?.getElementById("loader")?.classList?.add("hidden");
</script>

<style lang="scss">
#app_public {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.body {
  display: flex;
  flex: 1;
}

.content {
  flex: 1;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
