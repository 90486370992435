<template>
  <div id="viewerContainer" ref="containerRef" class="this__viewport">
    <!-- the laserpointer dot -->
    <svg
      class="this__laserpointer"
      :style="laserpointerStyles"
      height="20"
      width="20"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        :fill="props.laserpointer?.color ?? '#db006e'"
      />
    </svg>
    <div id="viewer" ref="viewerElementRef" class="this__page pdfViewer"></div>
  </div>
</template>

<script lang="ts" setup>
import { watch, computed, ref, markRaw, onUnmounted, CSSProperties } from "vue";
import {
  computedAsync,
  useVModel,
  toValue,
  watchThrottled,
  useElementBounding,
  useScroll,
} from "@vueuse/core";

// Import PDF.JS
import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer.mjs";

const BASE_URL: string | null = null;

const TextLayerMode = {
  DISABLE: 0,
  ENABLE: 1,
  ENABLE_PERMISSIONS: 2,
};

interface RawDims {
  pageWidth: number;
  pageHeight: number;
  pageX: number;
  pageY: number;
}

interface ZoomRect {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

interface Props {
  document: string | URL;
  page?: number;
  enableTextSelection?: boolean;
  enableAnnotations?: boolean;
  zoom?: ZoomRect | null;
  laserpointer?: { x: number; y: number; color?: string };
  interceptInternalLink?: (toPage: number, proceed: () => void) => void;
}

// Setup PDF.JS Worker
// We must load the PDF.JS worker to make the viewer work.
// We use a `postinstall` script in the `package.json` to copy the worker script to the right place.
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "/pdf.worker.mjs",
  window.location.href
).href;

const props = withDefaults(defineProps<Props>(), {
  page: 1,
  enableAnnotations: false,
  enableTextSelection: false,
  zoom: () => ({ top: 0, left: 0, bottom: 1, right: 1 }),
  laserpointer: undefined,
  // allows the calling component to intercept link clicks, e.g. to properly handle magic pages.
  interceptInternalLink: undefined,
});

// Load the PDF document with PDF.JS.
const pdf = computedAsync(async () => {
  try {
    const doc = await pdfjsLib.getDocument({
      url: props.document,
      cMapUrl: new URL("/cmaps", window.location.href).href,
      cMapPacked: true,
      enableXfa: false, // disable Xfa forms
      enableHWA: true, // enable hardware acceleration
    }).promise;
    // The document object must NEVER be made reactive, because this breaks PDF.JS.
    // That's why we mark the document to be `raw`, so Vue will not make it deeply reactive.
    // The computed reference `pdf` will be reactive. However, changes within it, will not.
    return markRaw(doc);
  } catch (e) {
    console.error(e);
  }
});

// Setup PDF.JS event bus.
const eventBus = new pdfjsViewer.EventBus();
const wrappedDispatch = eventBus.dispatch.bind(eventBus);
eventBus.dispatch = function (eventName: string, data: unknown) {
  return wrappedDispatch(eventName, data as any);
};

// Setup PDF.JS link service.
// The link service allows to intercept link clicks.
// This is important for the handling of magic pages.
const linkService = new pdfjsViewer.SimpleLinkService({ eventBus });
const wrappedGoToDestination = linkService.goToDestination.bind(linkService);

linkService.goToDestination = async function (dest: string | any[] | null) {
  const doc = toValue(pdf);
  if (!doc) throw new Error("document is not ready");
  if (typeof dest === "string") dest = await doc.getDestination(dest);
  if (dest === null) throw new Error("destination is null");

  if (typeof dest === "object" && dest !== null) {
    const pageIndex = await doc.getPageIndex(dest[0]);
    goToPage(pageIndex + 1); // take care, pages are zero-indexed
  } else if (typeof dest === "number" && Number.isInteger(dest)) {
    goToPage(dest + 1); // take care, pages are zero-indexed
  } else {
    return wrappedGoToDestination(dest);
  }
};

linkService.goToPage = async function (pageNumberOrLabel: number | string) {
  if (typeof pageNumberOrLabel === "string") {
    const doc = toValue(pdf);
    if (!doc) throw new Error("document is not ready");
    const labels = await doc.getPageLabels();
    if (!labels) {
      throw Error(
        `goToPage() called with label ${pageNumberOrLabel}, but the document does not support page labels.`
      );
    }
    const pageIndex = labels.indexOf(pageNumberOrLabel);
    if (pageIndex < 0) {
      throw Error(
        `current document has no page with label ${pageNumberOrLabel}`
      );
    }
    pageNumberOrLabel = pageIndex + 1; // take care, pages are zero-indexed
  }
  goToPage(pageNumberOrLabel);
};

function goToPage(pageNumber: number) {
  if (props.interceptInternalLink)
    // interceptInternalLink might be a callback to handle magic pages
    props.interceptInternalLink(
      pageNumber,
      () => (currentPageNumber.value = pageNumber)
    );
  else currentPageNumber.value = pageNumber;
}

async function getDestArrayForZoom(
  viewer: pdfjsViewer.PDFViewer,
  pageNumber: number,
  zoom?: ZoomRect | null
): Promise<any[] | undefined> {
  if (!zoom) return [pageNumber, { name: "Fit" }];

  const page = await viewer.pdfDocument?.getPage(pageNumber ?? 1);
  if (!page) return undefined;

  const viewport = page.getViewport({ scale: 1 });
  const { pageWidth, pageHeight } = viewport.rawDims as RawDims;

  // This is how the zoom rectangle is calculated in iOS:
  // https://github.com/Pitchview/Projector_iOS/blob/developer/PViOS/PViOS/UIModules/Presentation/PDFView%2BHelpers.swift
  let { top, left, bottom, right } = zoom;
  [left, right] = [Math.min(left, right), Math.max(left, right)];
  [top, bottom] = [Math.min(top, bottom), Math.max(top, bottom)];

  if (bottom <= top || right <= left) {
    console.warn("Invalid zoom rect:", { top, left, bottom, right });
    [top, left, bottom, right] = [0, 0, 1, 1];
  }

  // Withouth any zoom, we will return undefined, so the viewer will use the default zoom.
  if (top <= 0 && left <= 0 && bottom >= 1 && right >= 1) {
    return [pageNumber, { name: "Fit" }];
  }

  return [
    pageNumber,
    { name: "FitR" },
    left * pageWidth,
    pageHeight * (1 - top),
    right * pageWidth,
    pageHeight * (1 - bottom),
  ];
}

const emit = defineEmits<{
  (e: "update:page", page: number): void;
  (e: "update:numberOfPages", numberOfPages: number): void;
  (e: "loaded", url?: Props["document"]): void;
  (e: "rendered", pageNumber: number): void;
}>();

const containerRef = ref<HTMLDivElement>();
const currentPageNumber = useVModel(props, "page", emit, { passive: true });
const viewerElementRef = ref<HTMLDivElement>();
const {
  width: containerWidth,
  height: containerHeight,
  left: containerLeft,
  top: containerTop,
} = useElementBounding(containerRef);
const { y: containerScrollTop, x: containerScrollLeft } =
  useScroll(containerRef);
const {
  left: viewerLeft,
  top: viewerTop,
  height: viewerHeight,
  width: viewerWidth,
} = useElementBounding(viewerElementRef);

// Setup PDF.JS viewer when the required DOM elements get ready.
const viewerRef = computed(() => {
  const container = toValue(containerRef);
  const viewerElement = toValue(viewerElementRef);
  if (!container || !viewerElement) return null;
  return new pdfjsViewer.PDFSinglePageViewer({
    container,
    eventBus,
    viewer: viewerElement,
    linkService: linkService,
    textLayerMode: props.enableTextSelection
      ? TextLayerMode.ENABLE
      : TextLayerMode.DISABLE,
    annotationMode: props.enableAnnotations
      ? pdfjsLib.AnnotationMode.ENABLE
      : pdfjsLib.AnnotationMode.DISABLE,
  });
});

// As soon as `pdf` is available, we will load the current page.
const currentPage = computedAsync(async () => {
  const doc = toValue(pdf);
  if (!doc) return undefined;
  const pageNumber = Math.max(
    1,
    Math.min(currentPageNumber.value, doc.numPages)
  );
  return await doc.getPage(pageNumber);
});

// As soo as `pdf` is available, we will set how many pages we have.
const numberOfPages = computed(() => pdf.value?.numPages ?? undefined);

// This computed property is inserted into the `:style="..."` of the laserpointer.
const laserpointerStyles = computed<CSSProperties>(() => {
  const viewer = toValue(viewerRef);
  const page = toValue(currentPage);
  const point = props.laserpointer;
  if (!page || !point || !viewer || (point.x === -1 && point.y === -1))
    // By convention, a laserpointer with (-1, -1) means "no laserpointer"
    return {
      display: "none",
    };

  const pageview = viewer.getPageView(viewer.currentPageNumber - 1);
  const { pageWidth, pageHeight } = pageview.viewport.rawDims as RawDims;
  let [x, y] = pageview.viewport.convertToViewportPoint(
    Math.round(point.x * pageWidth),
    Math.round(point.y * pageHeight)
  );

  if ([0, 180].includes(pageview.viewport.rotation)) {
    y = toValue(viewerHeight) - y; // flip y-axis
  } else if ([90, 270].includes(pageview.viewport.rotation)) {
    x = toValue(viewerWidth) - x; // flip x-axis
  }

  x +=
    toValue(containerScrollLeft) + toValue(viewerLeft) - toValue(containerLeft);
  y += toValue(containerScrollTop) + toValue(viewerTop) - toValue(containerTop);
  return { top: `${y}px`, left: `${x}px` };
});

watch([pdf, viewerRef], ([newPdf, newViewer], [oldPdf, oldViewer]) => {
  if (newPdf && newViewer) {
    newViewer.setDocument(newPdf);
    linkService.setViewer(newViewer);
    linkService.setDocument(newPdf, BASE_URL);
    emit("loaded", props.document);
  }
  if (newPdf !== oldPdf && oldPdf) {
    oldPdf.cleanup();
    oldPdf.destroy();
  }
  if (newViewer !== oldViewer && oldViewer) {
    oldViewer.cleanup();
  }
});

watchThrottled(
  [
    containerWidth,
    containerHeight,
    viewerRef,
    () => props.zoom,
    currentPageNumber,
  ],
  async ([_width, _height, viewer, zoom, page]) => {
    console.debug("watchThrottled with page ", page);
    if (!viewer) return;
    const destArray = await getDestArrayForZoom(viewer, page, zoom);
    console.debug("watchThrottled destArray ", destArray);
    if (!destArray) {
      viewer.currentScaleValue = "page-fit";
      viewer.update();
    } else {
      viewer.scrollPageIntoView({ pageNumber: page, destArray });
    }
  },
  { throttle: 100, trailing: true, leading: true }
);

watch(currentPage, async (_newPage, oldPage) => {
  if (oldPage) oldPage.cleanup();
});

watch(numberOfPages, (newNumberOfPages) => {
  if (newNumberOfPages) {
    emit("update:numberOfPages", newNumberOfPages);
    if (newNumberOfPages < currentPageNumber.value) currentPageNumber.value = 1;
  }
});

eventBus.on("pagesinit", async () => {
  const viewer = toValue(viewerRef);
  if (!viewer) throw new Error("pdfViewer is not ready");
  viewer.currentScaleValue = "page-fit";
  const pageNumber = toValue(currentPageNumber) ?? 1;
  const destArray = await getDestArrayForZoom(viewer, pageNumber, props.zoom);
  viewer.scrollPageIntoView({
    pageNumber,
    destArray,
  });
});

eventBus.on("pagerendered", (event: { pageNumber: number }) => {
  emit("rendered", event.pageNumber);
});

onUnmounted(() => {
  const doc = toValue(pdf);
  const page = toValue(currentPage);
  if (page) {
    page.cleanup();
  }
  if (doc) {
    doc.cleanup();
    doc.destroy();
  }
});
</script>

<style scoped>
.this__viewport {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  display: grid;
  justify-items: center;
  align-items: center;
}

.this__laserpointer {
  position: absolute;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  z-index: 100;
}

.this__viewport :deep(#hiddenCopyElement) {
  display: none;
}

/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.this__viewport :deep(.dialog) {
  --dialog-bg-color: white;
  --dialog-border-color: white;
  --dialog-shadow: 0 2px 14px 0 rgb(58 57 68 / 0.2);
  --text-primary-color: #15141a;
  --text-secondary-color: #5b5b66;
  --hover-filter: brightness(0.9);
  --focus-ring-color: #0060df;
  --focus-ring-outline: 2px solid var(--focus-ring-color);
  --link-fg-color: #0060df;
  --link-hover-fg-color: #0250bb;
  --separator-color: #f0f0f4;

  --textarea-border-color: #8f8f9d;
  --textarea-bg-color: white;
  --textarea-fg-color: var(--text-secondary-color);

  --radio-bg-color: #f0f0f4;
  --radio-checked-bg-color: #fbfbfe;
  --radio-border-color: #8f8f9d;
  --radio-checked-border-color: #0060df;

  --button-secondary-bg-color: #f0f0f4;
  --button-secondary-fg-color: var(--text-primary-color);
  --button-secondary-border-color: var(--button-secondary-bg-color);
  --button-secondary-hover-bg-color: var(--button-secondary-bg-color);
  --button-secondary-hover-fg-color: var(--button-secondary-fg-color);
  --button-secondary-hover-border-color: var(--button-secondary-hover-bg-color);

  --button-primary-bg-color: #0060df;
  --button-primary-fg-color: #fbfbfe;
  --button-primary-border-color: var(--button-primary-bg-color);
  --button-primary-hover-bg-color: var(--button-primary-bg-color);
  --button-primary-hover-fg-color: var(--button-primary-fg-color);
  --button-primary-hover-border-color: var(--button-primary-hover-bg-color);
}

@media (prefers-color-scheme: dark) {
  .this__viewport :deep(.dialog) {
    --dialog-bg-color: #1c1b22;
    --dialog-border-color: #1c1b22;
    --dialog-shadow: 0 2px 14px 0 #15141a;
    --text-primary-color: #fbfbfe;
    --text-secondary-color: #cfcfd8;
    --focus-ring-color: #0df;
    --hover-filter: brightness(1.4);
    --link-fg-color: #0df;
    --link-hover-fg-color: #80ebff;
    --separator-color: #52525e;

    --textarea-bg-color: #42414d;

    --radio-bg-color: #2b2a33;
    --radio-checked-bg-color: #15141a;
    --radio-checked-border-color: #0df;

    --button-secondary-bg-color: #2b2a33;
    --button-primary-bg-color: #0df;
    --button-primary-fg-color: #15141a;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.dialog) {
    --dialog-bg-color: Canvas;
    --dialog-border-color: CanvasText;
    --dialog-shadow: none;
    --text-primary-color: CanvasText;
    --text-secondary-color: CanvasText;
    --hover-filter: none;
    --focus-ring-color: ButtonBorder;
    --link-fg-color: LinkText;
    --link-hover-fg-color: LinkText;
    --separator-color: CanvasText;

    --textarea-border-color: ButtonBorder;
    --textarea-bg-color: Field;
    --textarea-fg-color: ButtonText;

    --radio-bg-color: ButtonFace;
    --radio-checked-bg-color: ButtonFace;
    --radio-border-color: ButtonText;
    --radio-checked-border-color: ButtonText;

    --button-secondary-bg-color: ButtonFace;
    --button-secondary-fg-color: ButtonText;
    --button-secondary-border-color: ButtonText;
    --button-secondary-hover-bg-color: AccentColor;
    --button-secondary-hover-fg-color: AccentColorText;

    --button-primary-bg-color: ButtonText;
    --button-primary-fg-color: ButtonFace;
    --button-primary-hover-bg-color: AccentColor;
    --button-primary-hover-fg-color: AccentColorText;
  }
}

.this__viewport :deep(.dialog) {
  font: message-box;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid var(--dialog-border-color);
  background: var(--dialog-bg-color);
  color: var(--text-primary-color);
  box-shadow: var(--dialog-shadow);
}

.this__viewport :deep(:is(.dialog .mainContainer) *:focus-visible) {
  outline: var(--focus-ring-outline);
  outline-offset: 2px;
}

.this__viewport :deep(:is(.dialog .mainContainer) .title) {
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) .title) > span) {
  font-size: 13px;
  font-style: normal;
  font-weight: 590;
  line-height: 150%;
}

.this__viewport :deep(:is(.dialog .mainContainer) .dialogSeparator) {
  width: 100%;
  height: 1px;
  margin-block: 4px;
  background-color: var(--separator-color);
}

.this__viewport :deep(:is(.dialog .mainContainer) .dialogButtonsGroup) {
  display: flex;
  gap: 12px;
  align-self: flex-end;
}

.this__viewport :deep(:is(.dialog .mainContainer) .radio) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) .radio) > .radioButton) {
  display: flex;
  gap: 8px;
  align-self: stretch;
  align-items: center;
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .radio) > .radioButton) input) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--radio-bg-color);
  border: 1px solid var(--radio-border-color);
}

.this__viewport
  :deep(
    :is(:is(:is(:is(.dialog .mainContainer) .radio) > .radioButton) input):hover
  ) {
  filter: var(--hover-filter);
}

.this__viewport
  :deep(
    :is(
        :is(:is(:is(.dialog .mainContainer) .radio) > .radioButton) input
      ):checked
  ) {
  background-color: var(--radio-checked-bg-color);
  border: 4px solid var(--radio-checked-border-color);
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) .radio) > .radioLabel) {
  display: flex;
  padding-inline-start: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .radio) > .radioLabel) > span) {
  flex: 1 0 0;
  font-size: 11px;
  color: var(--text-secondary-color);
}

.this__viewport
  :deep(
    :is(.dialog .mainContainer) button:not(:is(.toggle-button, .closeButton))
  ) {
  border-radius: 4px;
  border: 1px solid;
  font: menu;
  font-weight: 600;
  padding: 4px 16px;
  width: auto;
  height: 32px;
}

.this__viewport
  :deep(
    :is(
        :is(.dialog .mainContainer)
          button:not(:is(.toggle-button, .closeButton))
      ):hover
  ) {
  cursor: pointer;
  filter: var(--hover-filter);
}

.this__viewport
  :deep(
    .secondaryButton:is(
        :is(.dialog .mainContainer)
          button:not(:is(.toggle-button, .closeButton))
      )
  ) {
  color: var(--button-secondary-fg-color);
  background-color: var(--button-secondary-bg-color);
  border-color: var(--button-secondary-border-color);
}

.this__viewport
  :deep(
    .secondaryButton:is(
        :is(.dialog .mainContainer)
          button:not(:is(.toggle-button, .closeButton))
      ):hover
  ) {
  color: var(--button-secondary-hover-fg-color);
  background-color: var(--button-secondary-hover-bg-color);
  border-color: var(--button-secondary-hover-border-color);
}

.this__viewport
  :deep(
    .primaryButton:is(
        :is(.dialog .mainContainer)
          button:not(:is(.toggle-button, .closeButton))
      )
  ) {
  color: var(--button-primary-fg-color);
  background-color: var(--button-primary-bg-color);
  border-color: var(--button-primary-border-color);
  opacity: 1;
}

.this__viewport
  :deep(
    .primaryButton:is(
        :is(.dialog .mainContainer)
          button:not(:is(.toggle-button, .closeButton))
      ):hover
  ) {
  color: var(--button-primary-hover-fg-color);
  background-color: var(--button-primary-hover-bg-color);
  border-color: var(--button-primary-hover-border-color);
}

.this__viewport :deep(:is(.dialog .mainContainer) a) {
  color: var(--link-fg-color);
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) a):hover) {
  color: var(--link-hover-fg-color);
}

.this__viewport :deep(:is(.dialog .mainContainer) textarea) {
  font: inherit;
  padding: 8px;
  resize: none;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--textarea-border-color);
  background: var(--textarea-bg-color);
  color: var(--textarea-fg-color);
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) textarea):focus) {
  outline-offset: 0;
  border-color: transparent;
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) textarea):disabled) {
  pointer-events: none;
  opacity: 0.4;
}

.this__viewport :deep(:is(.dialog .mainContainer) .messageBar) {
  --message-bar-warning-icon: url(images/messageBar_warning.svg);
  --closing-button-icon: url(images/messageBar_closingButton.svg);

  --message-bar-bg-color: #ffebcd;
  --message-bar-fg-color: #15141a;
  --message-bar-border-color: rgb(0 0 0 / 0.08);
  --message-bar-icon-color: #cd411e;
  --message-bar-close-button-border-radius: 4px;
  --message-bar-close-button-border: none;
  --message-bar-close-button-color: var(--text-primary-color);
  --message-bar-close-button-hover-bg-color: rgb(21 20 26 / 0.14);
  --message-bar-close-button-active-bg-color: rgb(21 20 26 / 0.21);
  --message-bar-close-button-focus-bg-color: rgb(21 20 26 / 0.07);
  --message-bar-close-button-color-hover: var(--text-primary-color);
}

@media (prefers-color-scheme: dark) {
  .this__viewport :deep(:is(.dialog .mainContainer) .messageBar) {
    --message-bar-bg-color: #5a3100;
    --message-bar-fg-color: #fbfbfe;
    --message-bar-border-color: rgb(255 255 255 / 0.08);
    --message-bar-icon-color: #e49c49;
    --message-bar-close-button-hover-bg-color: rgb(251 251 254 / 0.14);
    --message-bar-close-button-active-bg-color: rgb(251 251 254 / 0.21);
    --message-bar-close-button-focus-bg-color: rgb(251 251 254 / 0.07);
  }
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(:is(.dialog .mainContainer) .messageBar) {
    --message-bar-bg-color: HighlightText;
    --message-bar-fg-color: CanvasText;
    --message-bar-border-color: CanvasText;
    --message-bar-icon-color: CanvasText;
    --message-bar-close-button-color: ButtonText;
    --message-bar-close-button-border: 1px solid ButtonText;
    --message-bar-close-button-hover-bg-color: ButtonText;
    --message-bar-close-button-active-bg-color: ButtonText;
    --message-bar-close-button-focus-bg-color: ButtonText;
    --message-bar-close-button-color-hover: HighlightText;
  }
}

.this__viewport :deep(:is(.dialog .mainContainer) .messageBar) {
  display: flex;
  position: relative;
  padding: 12px 8px 12px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 4px;
  border: 1px solid var(--message-bar-border-color);
  background: var(--message-bar-bg-color);
  color: var(--message-bar-fg-color);
}

.this__viewport :deep(:is(:is(.dialog .mainContainer) .messageBar) > div) {
  display: flex;
  padding-inline-start: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) > div)::before) {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-mask-image: var(--message-bar-warning-icon);
  mask-image: var(--message-bar-warning-icon);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--message-bar-icon-color);
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) > div) > div) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.this__viewport
  :deep(
    :is(:is(:is(:is(.dialog .mainContainer) .messageBar) > div) > div) .title
  ) {
  font-size: 13px;
  font-weight: 590;
}

.this__viewport
  :deep(
    :is(:is(:is(:is(.dialog .mainContainer) .messageBar) > div) > div)
      .description
  ) {
  font-size: 13px;
}

.this__viewport
  :deep(:is(:is(.dialog .mainContainer) .messageBar) .closeButton) {
  position: absolute;
  width: 32px;
  height: 32px;
  inset-inline-end: 8px;
  inset-block-start: 8px;
  background: none;
  border-radius: var(--message-bar-close-button-border-radius);
  border: var(--message-bar-close-button-border);
}

.this__viewport
  :deep(
    :is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton)::before
  ) {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-mask-image: var(--closing-button-icon);
  mask-image: var(--closing-button-icon);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--message-bar-close-button-color);
}

.this__viewport
  :deep(
    :is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton):is(
        :hover,
        :active,
        :focus
      )::before
  ) {
  background-color: var(--message-bar-close-button-color-hover);
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton):hover) {
  background-color: var(--message-bar-close-button-hover-bg-color);
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton):active) {
  background-color: var(--message-bar-close-button-active-bg-color);
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton):focus) {
  background-color: var(--message-bar-close-button-focus-bg-color);
}

.this__viewport
  :deep(:is(:is(:is(.dialog .mainContainer) .messageBar) .closeButton) > span) {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.this__viewport :deep(:is(.dialog .mainContainer) .toggler) {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.this__viewport
  :deep(:is(:is(.dialog .mainContainer) .toggler) > .togglerLabel) {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.this__viewport :deep(.textLayer) {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: clip;
  opacity: 1;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  caret-color: CanvasText;
  z-index: 0;
}

.this__viewport :deep(.textLayer.highlighting) {
  touch-action: none;
}

.this__viewport :deep(.textLayer :is(span, br)) {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.this__viewport
  :deep(
    .textLayer > :not(.markedContent),
    .textLayer .markedContent span:not(.markedContent)
  ) {
  z-index: 1;
}

.this__viewport :deep(.textLayer span.markedContent) {
  top: 0;
  height: 0;
}

.this__viewport :deep(.textLayer .highlight) {
  --highlight-bg-color: rgb(180 0 170 / 0.25);
  --highlight-selected-bg-color: rgb(0 100 0 / 0.25);
  --highlight-backdrop-filter: none;
  --highlight-selected-backdrop-filter: none;
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.textLayer .highlight) {
    --highlight-bg-color: transparent;
    --highlight-selected-bg-color: transparent;
    --highlight-backdrop-filter: var(--hcm-highlight-filter);
    --highlight-selected-backdrop-filter: var(--hcm-highlight-selected-filter);
  }
}

.this__viewport :deep(.textLayer .highlight) {
  margin: -1px;
  padding: 1px;
  background-color: var(--highlight-bg-color);
  -webkit-backdrop-filter: var(--highlight-backdrop-filter);
  backdrop-filter: var(--highlight-backdrop-filter);
  border-radius: 4px;
}

.this__viewport :deep(.appended:is(.textLayer .highlight)) {
  position: initial;
}

.this__viewport :deep(.begin:is(.textLayer .highlight)) {
  border-radius: 4px 0 0 4px;
}

.this__viewport :deep(.end:is(.textLayer .highlight)) {
  border-radius: 0 4px 4px 0;
}

.this__viewport :deep(.middle:is(.textLayer .highlight)) {
  border-radius: 0;
}

.this__viewport :deep(.selected:is(.textLayer .highlight)) {
  background-color: var(--highlight-selected-bg-color);
  -webkit-backdrop-filter: var(--highlight-selected-backdrop-filter);
  backdrop-filter: var(--highlight-selected-backdrop-filter);
}

.this__viewport :deep(.textLayer ::-moz-selection) {
  background: rgba(0 0 255 / 0.25);
  background: color-mix(in srgb, AccentColor, transparent 75%);
}

.this__viewport :deep(.textLayer ::selection) {
  background: rgba(0 0 255 / 0.25);
  background: color-mix(in srgb, AccentColor, transparent 75%);
}

.this__viewport :deep(.textLayer br::-moz-selection) {
  background: transparent;
}

.this__viewport :deep(.textLayer br::selection) {
  background: transparent;
}

.this__viewport :deep(.textLayer .endOfContent) {
  display: block;
  position: absolute;
  inset: 100% 0 0;
  z-index: 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.this__viewport :deep(.textLayer.selecting .endOfContent) {
  top: 0;
}

.this__viewport :deep(.annotationLayer) {
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.annotationLayer) {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }

  .this__viewport
    :deep(
      .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
      .annotationLayer .choiceWidgetAnnotation select:required,
      .annotationLayer
        .buttonWidgetAnnotation:is(.checkBox, .radioButton)
        input:required
    ) {
    outline: 1.5px solid selectedItem;
  }

  .this__viewport :deep(.annotationLayer .linkAnnotation) {
    outline: var(--link-outline);
  }

  .this__viewport :deep(:is(.annotationLayer .linkAnnotation):hover) {
    -webkit-backdrop-filter: var(--hcm-highlight-filter);
    backdrop-filter: var(--hcm-highlight-filter);
  }

  .this__viewport :deep(:is(.annotationLayer .linkAnnotation) > a:hover) {
    opacity: 0 !important;
    background: none !important;
    box-shadow: none;
  }

  .this__viewport :deep(.annotationLayer .popupAnnotation .popup) {
    outline: calc(1.5px * var(--scale-factor)) solid CanvasText !important;
    background-color: ButtonFace !important;
    color: ButtonText !important;
  }

  .this__viewport :deep(.annotationLayer .highlightArea:hover::after) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: var(--hcm-highlight-filter);
    backdrop-filter: var(--hcm-highlight-filter);
    content: "";
    pointer-events: none;
  }

  .this__viewport :deep(.annotationLayer .popupAnnotation.focused .popup) {
    outline: calc(3px * var(--scale-factor)) solid Highlight !important;
  }
}

.this__viewport :deep(.annotationLayer) {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
}

.this__viewport :deep(.annotationLayer[data-main-rotation="90"] .norotate) {
  transform: rotate(270deg) translateX(-100%);
}

.this__viewport :deep(.annotationLayer[data-main-rotation="180"] .norotate) {
  transform: rotate(180deg) translate(-100%, -100%);
}

.this__viewport :deep(.annotationLayer[data-main-rotation="270"] .norotate) {
  transform: rotate(90deg) translateY(-100%);
}

.this__viewport
  :deep(.annotationLayer.disabled section, .annotationLayer.disabled .popup) {
  pointer-events: none;
}

.this__viewport :deep(.annotationLayer .annotationContent) {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.this__viewport :deep(.freetext:is(.annotationLayer .annotationContent)) {
  background: transparent;
  border: none;
  inset: 0;
  overflow: visible;
  white-space: nowrap;
  font: 10px sans-serif;
  line-height: 1.35;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.this__viewport :deep(.annotationLayer section) {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
}

.this__viewport
  :deep(
    :is(.annotationLayer section):has(div.annotationContent)
      canvas.annotationContent
  ) {
  display: none;
}

.this__viewport :deep(.textLayer.selecting ~ .annotationLayer section) {
  pointer-events: none;
}

.this__viewport
  :deep(
    .annotationLayer
      :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton)
      > a
  ) {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.this__viewport
  :deep(
    .annotationLayer
      :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton):not(.hasBorder)
      > a:hover
  ) {
  opacity: 0.2;
  background-color: rgb(255 255 0);
  box-shadow: 0 2px 10px rgb(255 255 0);
}

.this__viewport :deep(.annotationLayer .linkAnnotation.hasBorder:hover) {
  background-color: rgb(255 255 0 / 0.2);
}

.this__viewport :deep(.annotationLayer .hasBorder) {
  background-size: 100% 100%;
}

.this__viewport :deep(.annotationLayer .textAnnotation img) {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation :is(input, textarea),
    .annotationLayer .choiceWidgetAnnotation select,
    .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input
  ) {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
    .annotationLayer .choiceWidgetAnnotation select:required,
    .annotationLayer
      .buttonWidgetAnnotation:is(.checkBox, .radioButton)
      input:required
  ) {
  outline: 1.5px solid red;
}

.this__viewport :deep(.annotationLayer .choiceWidgetAnnotation select option) {
  padding: 0;
}

.this__viewport
  :deep(.annotationLayer .buttonWidgetAnnotation.radioButton input) {
  border-radius: 50%;
}

.this__viewport :deep(.annotationLayer .textWidgetAnnotation textarea) {
  resize: none;
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation [disabled]:is(input, textarea),
    .annotationLayer .choiceWidgetAnnotation select[disabled],
    .annotationLayer
      .buttonWidgetAnnotation:is(.checkBox, .radioButton)
      input[disabled]
  ) {
  background: none;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
    .annotationLayer .choiceWidgetAnnotation select:hover,
    .annotationLayer
      .buttonWidgetAnnotation:is(.checkBox, .radioButton)
      input:hover
  ) {
  border: 2px solid var(--input-hover-border-color);
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
    .annotationLayer .choiceWidgetAnnotation select:hover,
    .annotationLayer .buttonWidgetAnnotation.checkBox input:hover
  ) {
  border-radius: 2px;
}

.this__viewport
  :deep(
    .annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
    .annotationLayer .choiceWidgetAnnotation select:focus
  ) {
  background: none;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus
  ) {
  background-image: none;
  background-color: transparent;
}

.this__viewport
  :deep(.annotationLayer .buttonWidgetAnnotation.checkBox :focus) {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.this__viewport
  :deep(.annotationLayer .buttonWidgetAnnotation.radioButton :focus) {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
    .annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before
  ) {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after
  ) {
  height: 80%;
  left: 45%;
  width: 1px;
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before
  ) {
  transform: rotate(45deg);
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after
  ) {
  transform: rotate(-45deg);
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before
  ) {
  border-radius: 50%;
  height: 50%;
  left: 25%;
  top: 25%;
  width: 50%;
}

.this__viewport :deep(.annotationLayer .textWidgetAnnotation input.comb) {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.this__viewport :deep(.annotationLayer .textWidgetAnnotation input.comb:focus) {
  width: 103%;
}

.this__viewport
  :deep(
    .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input
  ) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.this__viewport
  :deep(.annotationLayer .fileAttachmentAnnotation .popupTriggerArea) {
  height: 100%;
  width: 100%;
}

.this__viewport :deep(.annotationLayer .popupAnnotation) {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  pointer-events: none;
  width: -moz-max-content;
  width: max-content;
  max-width: 45%;
  height: auto;
}

.this__viewport :deep(.annotationLayer .popup) {
  background-color: rgb(255 255 153);
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    rgb(136 136 136);
  border-radius: calc(2px * var(--scale-factor));
  outline: 1.5px solid rgb(255 255 74);
  padding: calc(6px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}

.this__viewport :deep(.annotationLayer .popupAnnotation.focused .popup) {
  outline-width: 3px;
}

.this__viewport :deep(.annotationLayer .popup *) {
  font-size: calc(9px * var(--scale-factor));
}

.this__viewport :deep(.annotationLayer .popup > .header) {
  display: inline-block;
}

.this__viewport :deep(.annotationLayer .popup > .header h1) {
  display: inline;
}

.this__viewport :deep(.annotationLayer .popup > .header .popupDate) {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
  width: -moz-fit-content;
  width: fit-content;
}

.this__viewport :deep(.annotationLayer .popupContent) {
  border-top: 1px solid rgb(51 51 51);
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}

.this__viewport :deep(.annotationLayer .richText > *) {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.this__viewport :deep(.annotationLayer .popupTriggerArea) {
  cursor: pointer;
}

.this__viewport :deep(.annotationLayer section svg) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.this__viewport :deep(.annotationLayer .annotationTextContent) {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.this__viewport :deep(:is(.annotationLayer .annotationTextContent) span) {
  width: 100%;
  display: inline-block;
}

.this__viewport :deep(.annotationLayer svg.quadrilateralsContainer) {
  contain: strict;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

:root {
  --xfa-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --xfa-focus-outline: auto;
}

@media screen and (forced-colors: active) {
  :root {
    --xfa-focus-outline: 2px solid CanvasText;
  }
  .this__viewport :deep(.xfaLayer *:required) {
    outline: 1.5px solid selectedItem;
  }
}

.this__viewport :deep(.xfaLayer) {
  background-color: transparent;
}

.this__viewport :deep(.xfaLayer .highlight) {
  margin: -1px;
  padding: 1px;
  background-color: rgb(239 203 237);
  border-radius: 4px;
}

.this__viewport :deep(.xfaLayer .highlight.appended) {
  position: initial;
}

.this__viewport :deep(.xfaLayer .highlight.begin) {
  border-radius: 4px 0 0 4px;
}

.this__viewport :deep(.xfaLayer .highlight.end) {
  border-radius: 0 4px 4px 0;
}

.this__viewport :deep(.xfaLayer .highlight.middle) {
  border-radius: 0;
}

.this__viewport :deep(.xfaLayer .highlight.selected) {
  background-color: rgb(203 223 203);
}

.this__viewport :deep(.xfaPage) {
  overflow: hidden;
  position: relative;
}

.this__viewport :deep(.xfaContentarea) {
  position: absolute;
}

.this__viewport :deep(.xfaPrintOnly) {
  display: none;
}

.this__viewport :deep(.xfaLayer) {
  position: absolute;
  text-align: initial;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  line-height: 1.2;
}

.this__viewport :deep(.xfaLayer *) {
  color: inherit;
  font: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-kerning: inherit;
  letter-spacing: -0.01px;
  text-align: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  line-height: inherit;
}

.this__viewport :deep(.xfaLayer *:required) {
  outline: 1.5px solid red;
}

.xfaLayer div,
.xfaLayer svg,
.this__viewport :deep(.xfaLayer svg *) {
  pointer-events: none;
}

.this__viewport :deep(.xfaLayer a) {
  color: blue;
}

.this__viewport :deep(.xfaRich li) {
  margin-left: 3em;
}

.this__viewport :deep(.xfaFont) {
  color: black;
  font-weight: normal;
  font-kerning: none;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0;
  text-decoration: none;
  vertical-align: 0;
}

.this__viewport :deep(.xfaCaption) {
  overflow: hidden;
  flex: 0 0 auto;
}

.this__viewport :deep(.xfaCaptionForCheckButton) {
  overflow: hidden;
  flex: 1 1 auto;
}

.this__viewport :deep(.xfaLabel) {
  height: 100%;
  width: 100%;
}

.this__viewport :deep(.xfaLeft) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.this__viewport :deep(.xfaRight) {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.this__viewport
  :deep(
    :is(.xfaLeft, .xfaRight) > :is(.xfaCaption, .xfaCaptionForCheckButton)
  ) {
  max-height: 100%;
}

.this__viewport :deep(.xfaTop) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.this__viewport :deep(.xfaBottom) {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.this__viewport
  :deep(
    :is(.xfaTop, .xfaBottom) > :is(.xfaCaption, .xfaCaptionForCheckButton)
  ) {
  width: 100%;
}

.this__viewport :deep(.xfaBorder) {
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}

.this__viewport :deep(.xfaWrapped) {
  width: 100%;
  height: 100%;
}

.this__viewport :deep(:is(.xfaTextfield, .xfaSelect):focus) {
  background-image: none;
  background-color: transparent;
  outline: var(--xfa-focus-outline);
  outline-offset: -1px;
}

.this__viewport :deep(:is(.xfaCheckbox, .xfaRadio):focus) {
  outline: var(--xfa-focus-outline);
}

.xfaTextfield,
.this__viewport :deep(.xfaSelect) {
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  border: none;
  resize: none;
  background-image: var(--xfa-unfocused-field-background);
}

.this__viewport :deep(.xfaSelect) {
  padding-inline: 2px;
}

.this__viewport
  :deep(:is(.xfaTop, .xfaBottom) > :is(.xfaTextfield, .xfaSelect)) {
  flex: 0 1 auto;
}

.this__viewport :deep(.xfaButton) {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
}

.this__viewport :deep(.xfaLink) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.xfaCheckbox,
.this__viewport :deep(.xfaRadio) {
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  border: none;
}

.this__viewport :deep(.xfaRich) {
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
}

.this__viewport :deep(.xfaImage) {
  -o-object-position: left top;
  object-position: left top;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.xfaLrTb,
.xfaRlTb,
.this__viewport :deep(.xfaTb) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.this__viewport :deep(.xfaLr) {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.this__viewport :deep(.xfaRl) {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
}

.this__viewport :deep(.xfaTb > div) {
  justify-content: left;
}

.this__viewport :deep(.xfaPosition) {
  position: relative;
}

.this__viewport :deep(.xfaArea) {
  position: relative;
}

.this__viewport :deep(.xfaValignMiddle) {
  display: flex;
  align-items: center;
}

.this__viewport :deep(.xfaTable) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.this__viewport :deep(.xfaTable .xfaRow) {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.this__viewport :deep(.xfaTable .xfaRlRow) {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  flex: 1;
}

.this__viewport :deep(.xfaTable .xfaRlRow > div) {
  flex: 1;
}

.this__viewport
  :deep(
    :is(.xfaNonInteractive, .xfaDisabled, .xfaReadOnly) :is(input, textarea)
  ) {
  background: initial;
}

@media print {
  .xfaTextfield,
  .this__viewport :deep(.xfaSelect) {
    background: transparent;
  }

  .this__viewport :deep(.xfaSelect) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}

.this__viewport :deep(.canvasWrapper svg) {
  transform: none;
}

[data-main-rotation="90"]:is(.canvasWrapper svg) mask,
[data-main-rotation="90"]:is(.canvasWrapper svg) use:not(.clip, .mask) {
  transform: matrix(0, 1, -1, 0, 1, 0);
}

[data-main-rotation="180"]:is(.canvasWrapper svg) mask,
[data-main-rotation="180"]:is(.canvasWrapper svg) use:not(.clip, .mask) {
  transform: matrix(-1, 0, 0, -1, 1, 1);
}

[data-main-rotation="270"]:is(.canvasWrapper svg) mask,
[data-main-rotation="270"]:is(.canvasWrapper svg) use:not(.clip, .mask) {
  transform: matrix(0, -1, 1, 0, 0, 1);
}

.this__viewport :deep(.highlight:is(.canvasWrapper svg)) {
  --blend-mode: multiply;
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.highlight:is(.canvasWrapper svg)) {
    --blend-mode: difference;
  }
}

.this__viewport :deep(.highlight:is(.canvasWrapper svg)) {
  position: absolute;
  mix-blend-mode: var(--blend-mode);
}

.this__viewport :deep(.highlight:is(.canvasWrapper svg):not(.free)) {
  fill-rule: evenodd;
}

.this__viewport :deep(.highlightOutline:is(.canvasWrapper svg)) {
  position: absolute;
  mix-blend-mode: normal;
  fill-rule: evenodd;
  fill: none;
}

.this__viewport
  :deep(
    .highlightOutline.hovered:is(.canvasWrapper svg):not(.free):not(.selected)
  ) {
  stroke: var(--hover-outline-color);
  stroke-width: var(--outline-width);
}

.this__viewport
  :deep(
    .highlightOutline.selected:is(.canvasWrapper svg):not(.free) .mainOutline
  ) {
  stroke: var(--outline-around-color);
  stroke-width: calc(var(--outline-width) + 2 * var(--outline-around-width));
}

.this__viewport
  :deep(
    .highlightOutline.selected:is(.canvasWrapper svg):not(.free)
      .secondaryOutline
  ) {
  stroke: var(--outline-color);
  stroke-width: var(--outline-width);
}

.this__viewport
  :deep(.highlightOutline.free.hovered:is(.canvasWrapper svg):not(.selected)) {
  stroke: var(--hover-outline-color);
  stroke-width: calc(2 * var(--outline-width));
}

.this__viewport
  :deep(.highlightOutline.free.selected:is(.canvasWrapper svg) .mainOutline) {
  stroke: var(--outline-around-color);
  stroke-width: calc(2 * (var(--outline-width) + var(--outline-around-width)));
}

.this__viewport
  :deep(
    .highlightOutline.free.selected:is(.canvasWrapper svg) .secondaryOutline
  ) {
  stroke: var(--outline-color);
  stroke-width: calc(2 * var(--outline-width));
}

.this__viewport :deep(.toggle-button) {
  --button-background-color: #f0f0f4;
  --button-background-color-hover: #e0e0e6;
  --button-background-color-active: #cfcfd8;
  --color-accent-primary: #0060df;
  --color-accent-primary-hover: #0250bb;
  --color-accent-primary-active: #054096;
  --border-interactive-color: #8f8f9d;
  --border-radius-circle: 9999px;
  --border-width: 1px;
  --size-item-small: 16px;
  --size-item-large: 32px;
  --color-canvas: white;
}

@media (prefers-color-scheme: dark) {
  .this__viewport :deep(.toggle-button) {
    --button-background-color: color-mix(in srgb, currentColor 7%, transparent);
    --button-background-color-hover: color-mix(
      in srgb,
      currentColor 14%,
      transparent
    );
    --button-background-color-active: color-mix(
      in srgb,
      currentColor 21%,
      transparent
    );
    --color-accent-primary: #0df;
    --color-accent-primary-hover: #80ebff;
    --color-accent-primary-active: #aaf2ff;
    --border-interactive-color: #bfbfc9;
    --color-canvas: #1c1b22;
  }
}

@media (forced-colors: active) {
  .this__viewport :deep(.toggle-button) {
    --color-accent-primary: ButtonText;
    --color-accent-primary-hover: SelectedItem;
    --color-accent-primary-active: SelectedItem;
    --border-interactive-color: ButtonText;
    --button-background-color: ButtonFace;
    --border-interactive-color-hover: SelectedItem;
    --border-interactive-color-active: SelectedItem;
    --border-interactive-color-disabled: GrayText;
    --color-canvas: ButtonText;
  }
}

.this__viewport :deep(.toggle-button) {
  --toggle-background-color: var(--button-background-color);
  --toggle-background-color-hover: var(--button-background-color-hover);
  --toggle-background-color-active: var(--button-background-color-active);
  --toggle-background-color-pressed: var(--color-accent-primary);
  --toggle-background-color-pressed-hover: var(--color-accent-primary-hover);
  --toggle-background-color-pressed-active: var(--color-accent-primary-active);
  --toggle-border-color: var(--border-interactive-color);
  --toggle-border-color-hover: var(--toggle-border-color);
  --toggle-border-color-active: var(--toggle-border-color);
  --toggle-border-radius: var(--border-radius-circle);
  --toggle-border-width: var(--border-width);
  --toggle-height: var(--size-item-small);
  --toggle-width: var(--size-item-large);
  --toggle-dot-background-color: var(--toggle-border-color);
  --toggle-dot-background-color-hover: var(--toggle-dot-background-color);
  --toggle-dot-background-color-active: var(--toggle-dot-background-color);
  --toggle-dot-background-color-on-pressed: var(--color-canvas);
  --toggle-dot-margin: 1px;
  --toggle-dot-height: calc(
    var(--toggle-height) - 2 * var(--toggle-dot-margin) - 2 *
      var(--toggle-border-width)
  );
  --toggle-dot-width: var(--toggle-dot-height);
  --toggle-dot-transform-x: calc(
    var(--toggle-width) - 4 * var(--toggle-dot-margin) - var(--toggle-dot-width)
  );

  -webkit-appearance: none;

  -moz-appearance: none;

  appearance: none;
  padding: 0;
  margin: 0;
  border: var(--toggle-border-width) solid var(--toggle-border-color);
  height: var(--toggle-height);
  width: var(--toggle-width);
  border-radius: var(--toggle-border-radius);
  background: var(--toggle-background-color);
  box-sizing: border-box;
  flex-shrink: 0;
}

.this__viewport :deep(.toggle-button:focus-visible) {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.this__viewport :deep(.toggle-button:enabled:hover) {
  background: var(--toggle-background-color-hover);
  border-color: var(--toggle-border-color);
}

.this__viewport :deep(.toggle-button:enabled:active) {
  background: var(--toggle-background-color-active);
  border-color: var(--toggle-border-color);
}

.this__viewport :deep(.toggle-button[aria-pressed="true"]) {
  background: var(--toggle-background-color-pressed);
  border-color: transparent;
}

.this__viewport :deep(.toggle-button[aria-pressed="true"]:enabled:hover) {
  background: var(--toggle-background-color-pressed-hover);
  border-color: transparent;
}

.this__viewport :deep(.toggle-button[aria-pressed="true"]:enabled:active) {
  background: var(--toggle-background-color-pressed-active);
  border-color: transparent;
}

.this__viewport :deep(.toggle-button::before) {
  display: block;
  content: "";
  background-color: var(--toggle-dot-background-color);
  height: var(--toggle-dot-height);
  width: var(--toggle-dot-width);
  margin: var(--toggle-dot-margin);
  border-radius: var(--toggle-border-radius);
  translate: 0;
}

.this__viewport :deep(.toggle-button[aria-pressed="true"]::before) {
  translate: var(--toggle-dot-transform-x);
  background-color: var(--toggle-dot-background-color-on-pressed);
}

.this__viewport
  :deep(
    .toggle-button[aria-pressed="true"]:enabled:hover::before,
    .toggle-button[aria-pressed="true"]:enabled:active::before
  ) {
  background-color: var(--toggle-dot-background-color-on-pressed);
}

[dir="rtl"] .toggle-button[aria-pressed="true"]::before {
  translate: calc(-1 * var(--toggle-dot-transform-x));
}

@media (prefers-reduced-motion: no-preference) {
  .this__viewport :deep(.toggle-button::before) {
    transition: translate 100ms;
  }
}

@media (prefers-contrast) {
  .this__viewport :deep(.toggle-button:enabled:hover) {
    border-color: var(--toggle-border-color-hover);
  }

  .this__viewport :deep(.toggle-button:enabled:active) {
    border-color: var(--toggle-border-color-active);
  }

  .this__viewport :deep(.toggle-button[aria-pressed="true"]:enabled) {
    border-color: var(--toggle-border-color);
    position: relative;
  }

  .this__viewport
    :deep(
      .toggle-button[aria-pressed="true"]:enabled:hover,
      .toggle-button[aria-pressed="true"]:enabled:hover:active
    ) {
    border-color: var(--toggle-border-color-hover);
  }

  .this__viewport :deep(.toggle-button[aria-pressed="true"]:enabled:active) {
    background-color: var(--toggle-dot-background-color-active);
    border-color: var(--toggle-dot-background-color-hover);
  }

  .this__viewport
    :deep(.toggle-button:hover::before, .toggle-button:active::before) {
    background-color: var(--toggle-dot-background-color-hover);
  }
}

@media (forced-colors) {
  .this__viewport :deep(.toggle-button) {
    --toggle-dot-background-color: var(--color-accent-primary);
    --toggle-dot-background-color-hover: var(--color-accent-primary-hover);
    --toggle-dot-background-color-active: var(--color-accent-primary-active);
    --toggle-dot-background-color-on-pressed: var(--button-background-color);
    --toggle-background-color-disabled: var(--button-background-color-disabled);
    --toggle-border-color-hover: var(--border-interactive-color-hover);
    --toggle-border-color-active: var(--border-interactive-color-active);
    --toggle-border-color-disabled: var(--border-interactive-color-disabled);
  }

  .this__viewport :deep(.toggle-button[aria-pressed="true"]:enabled::after) {
    border: 1px solid var(--button-background-color);
    content: "";
    position: absolute;
    height: var(--toggle-height);
    width: var(--toggle-width);
    display: block;
    border-radius: var(--toggle-border-radius);
    inset: -2px;
  }

  .this__viewport
    :deep(.toggle-button[aria-pressed="true"]:enabled:active::after) {
    border-color: var(--toggle-border-color-active);
  }
}

:root {
  --outline-width: 2px;
  --outline-color: #0060df;
  --outline-around-width: 1px;
  --outline-around-color: #f0f0f4;
  --hover-outline-around-color: var(--outline-around-color);
  --focus-outline: solid var(--outline-width) var(--outline-color);
  --unfocus-outline: solid var(--outline-width) transparent;
  --focus-outline-around: solid var(--outline-around-width)
    var(--outline-around-color);
  --hover-outline-color: #8f8f9d;
  --hover-outline: solid var(--outline-width) var(--hover-outline-color);
  --hover-outline-around: solid var(--outline-around-width)
    var(--hover-outline-around-color);
  --freetext-line-height: 1.35;
  --freetext-padding: 2px;
  --resizer-bg-color: var(--outline-color);
  --resizer-size: 6px;
  --resizer-shift: calc(
    0px - (var(--outline-width) + var(--resizer-size)) / 2 -
      var(--outline-around-width)
  );
  --editorFreeText-editing-cursor: text;
  --editorInk-editing-cursor: url(images/cursor-editorInk.svg) 0 16, pointer;
  --editorHighlight-editing-cursor: url(images/cursor-editorTextHighlight.svg)
      24 24,
    text;
  --editorFreeHighlight-editing-cursor: url(images/cursor-editorFreeHighlight.svg)
      1 18,
    pointer;

  --new-alt-text-warning-image: url(images/altText_warning.svg);
}
.this__viewport :deep(.visuallyHidden) {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
}

.this__viewport :deep(.textLayer.highlighting) {
  cursor: var(--editorFreeHighlight-editing-cursor);
}

.this__viewport :deep(.textLayer.highlighting:not(.free) span) {
  cursor: var(--editorHighlight-editing-cursor);
}

.this__viewport :deep(.textLayer.highlighting.free span) {
  cursor: var(--editorFreeHighlight-editing-cursor);
}

#viewerContainer.pdfPresentationMode:fullscreen .noAltTextBadge {
  display: none !important;
}

@media (min-resolution: 1.1dppx) {
  :root {
    --editorFreeText-editing-cursor: url(images/cursor-editorFreeText.svg) 0 16,
      text;
  }
}

@media screen and (forced-colors: active) {
  :root {
    --outline-color: CanvasText;
    --outline-around-color: ButtonFace;
    --resizer-bg-color: ButtonText;
    --hover-outline-color: Highlight;
    --hover-outline-around-color: SelectedItemText;
  }
}

[data-editor-rotation="90"] {
  transform: rotate(90deg);
}

[data-editor-rotation="180"] {
  transform: rotate(180deg);
}

[data-editor-rotation="270"] {
  transform: rotate(270deg);
}

.this__viewport :deep(.annotationEditorLayer) {
  background: transparent;
  position: absolute;
  inset: 0;
  font-size: calc(100px * var(--scale-factor));
  transform-origin: 0 0;
  cursor: auto;
}

.this__viewport :deep(.annotationEditorLayer .selectedEditor) {
  z-index: 100000 !important;
}

.this__viewport :deep(.annotationEditorLayer.drawing *) {
  pointer-events: none !important;
}

.this__viewport :deep(.annotationEditorLayer.waiting) {
  content: "";
  cursor: wait;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.this__viewport :deep(.annotationEditorLayer.disabled) {
  pointer-events: none;
}

.this__viewport :deep(.annotationEditorLayer.freetextEditing) {
  cursor: var(--editorFreeText-editing-cursor);
}

.this__viewport :deep(.annotationEditorLayer.inkEditing) {
  cursor: var(--editorInk-editing-cursor);
}

.this__viewport
  :deep(.annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)) {
  position: absolute;
  background: transparent;
  z-index: 1;
  transform-origin: 0 0;
  cursor: auto;
  max-width: 100%;
  max-height: 100%;
  border: var(--unfocus-outline);
}

.this__viewport
  :deep(
    .draggable.selectedEditor:is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      )
  ) {
  cursor: move;
}

.this__viewport
  :deep(
    .moving:is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      )
  ) {
  touch-action: none;
}

.this__viewport
  :deep(
    .selectedEditor:is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      )
  ) {
  border: var(--focus-outline);
  outline: var(--focus-outline-around);
}

.this__viewport
  :deep(
    .selectedEditor:is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      )::before
  ) {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--focus-outline-around);
  pointer-events: none;
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      ):hover:not(.selectedEditor)
  ) {
  border: var(--hover-outline);
  outline: var(--hover-outline-around);
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor)
      ):hover:not(.selectedEditor)::before
  ) {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--focus-outline-around);
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer
          :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
        .textLayer
      )
      .editToolbar
  ) {
  --editor-toolbar-delete-image: url(images/editor-toolbar-delete.svg);
  --editor-toolbar-bg-color: #f0f0f4;
  --editor-toolbar-highlight-image: url(images/toolbarButton-editorHighlight.svg);
  --editor-toolbar-fg-color: #2e2e56;
  --editor-toolbar-border-color: #8f8f9d;
  --editor-toolbar-hover-border-color: var(--editor-toolbar-border-color);
  --editor-toolbar-hover-bg-color: #e0e0e6;
  --editor-toolbar-hover-fg-color: var(--editor-toolbar-fg-color);
  --editor-toolbar-hover-outline: none;
  --editor-toolbar-focus-outline-color: #0060df;
  --editor-toolbar-shadow: 0 2px 6px 0 rgb(58 57 68 / 0.2);
  --editor-toolbar-vert-offset: 6px;
  --editor-toolbar-height: 28px;
  --editor-toolbar-padding: 2px;
  --alt-text-done-color: #2ac3a2;
  --alt-text-warning-color: #0090ed;
  --alt-text-hover-done-color: var(--alt-text-done-color);
  --alt-text-hover-warning-color: var(--alt-text-warning-color);
}

@media (prefers-color-scheme: dark) {
  .this__viewport
    :deep(
      :is(
          .annotationEditorLayer
            :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
          .textLayer
        )
        .editToolbar
    ) {
    --editor-toolbar-bg-color: #2b2a33;
    --editor-toolbar-fg-color: #fbfbfe;
    --editor-toolbar-hover-bg-color: #52525e;
    --editor-toolbar-focus-outline-color: #0df;
    --alt-text-done-color: #54ffbd;
    --alt-text-warning-color: #80ebff;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport
    :deep(
      :is(
          .annotationEditorLayer
            :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
          .textLayer
        )
        .editToolbar
    ) {
    --editor-toolbar-bg-color: ButtonFace;
    --editor-toolbar-fg-color: ButtonText;
    --editor-toolbar-border-color: ButtonText;
    --editor-toolbar-hover-border-color: AccentColor;
    --editor-toolbar-hover-bg-color: ButtonFace;
    --editor-toolbar-hover-fg-color: AccentColor;
    --editor-toolbar-hover-outline: 2px solid
      var(--editor-toolbar-hover-border-color);
    --editor-toolbar-focus-outline-color: ButtonBorder;
    --editor-toolbar-shadow: none;
    --alt-text-done-color: var(--editor-toolbar-fg-color);
    --alt-text-warning-color: var(--editor-toolbar-fg-color);
    --alt-text-hover-done-color: var(--editor-toolbar-hover-fg-color);
    --alt-text-hover-warning-color: var(--editor-toolbar-hover-fg-color);
  }
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer
          :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
        .textLayer
      )
      .editToolbar
  ) {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  height: var(--editor-toolbar-height);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  pointer-events: auto;
  box-sizing: content-box;
  padding: var(--editor-toolbar-padding);

  position: absolute;
  inset-inline-end: 0;
  inset-block-start: calc(100% + var(--editor-toolbar-vert-offset));

  border-radius: 6px;
  background-color: var(--editor-toolbar-bg-color);
  border: 1px solid var(--editor-toolbar-border-color);
  box-shadow: var(--editor-toolbar-shadow);
}

.this__viewport
  :deep(
    .hidden:is(
        :is(
            .annotationEditorLayer
              :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
            .textLayer
          )
          .editToolbar
      )
  ) {
  display: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            .annotationEditorLayer
              :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
            .textLayer
          )
          .editToolbar
      ):has(:focus-visible)
  ) {
  border-color: transparent;
}

[dir="ltr"]
  :is(
    :is(
        .annotationEditorLayer
          :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
        .textLayer
      )
      .editToolbar
  ) {
  transform-origin: 100% 0;
}

[dir="rtl"]
  :is(
    :is(
        .annotationEditorLayer
          :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
        .textLayer
      )
      .editToolbar
  ) {
  transform-origin: 0 0;
}

.this__viewport
  :deep(
    :is(
        :is(
            .annotationEditorLayer
              :is(.freeTextEditor, .inkEditor, .stampEditor, .highlightEditor),
            .textLayer
          )
          .editToolbar
      )
      .buttons
  ) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  height: 100%;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      .divider
  ) {
  width: 1px;
  height: calc(
    2 * var(--editor-toolbar-padding) + var(--editor-toolbar-height)
  );
  background-color: var(--editor-toolbar-border-color);
  display: inline-block;
  margin-inline: 2px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      .highlightButton
  ) {
  width: var(--editor-toolbar-height);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .highlightButton
      )::before
  ) {
  content: "";
  -webkit-mask-image: var(--editor-toolbar-highlight-image);
  mask-image: var(--editor-toolbar-highlight-image);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  display: inline-block;
  background-color: var(--editor-toolbar-fg-color);
  width: 100%;
  height: 100%;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .highlightButton
      ):hover::before
  ) {
  background-color: var(--editor-toolbar-hover-fg-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      .delete
  ) {
  width: var(--editor-toolbar-height);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .delete
      )::before
  ) {
  content: "";
  -webkit-mask-image: var(--editor-toolbar-delete-image);
  mask-image: var(--editor-toolbar-delete-image);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  display: inline-block;
  background-color: var(--editor-toolbar-fg-color);
  width: 100%;
  height: 100%;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .delete
      ):hover::before
  ) {
  background-color: var(--editor-toolbar-hover-fg-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      > *
  ) {
  height: var(--editor-toolbar-height);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      > :not(.divider)
  ) {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          > :not(.divider)
      ):hover
  ) {
  border-radius: 2px;
  background-color: var(--editor-toolbar-hover-bg-color);
  color: var(--editor-toolbar-hover-fg-color);
  outline: var(--editor-toolbar-hover-outline);
  outline-offset: 1px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          > :not(.divider)
      ):hover:active
  ) {
  outline: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          > :not(.divider)
      ):focus-visible
  ) {
  border-radius: 2px;
  outline: 2px solid var(--editor-toolbar-focus-outline-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(
                    .freeTextEditor,
                    .inkEditor,
                    .stampEditor,
                    .highlightEditor
                  ),
                .textLayer
              )
              .editToolbar
          )
          .buttons
      )
      .altText
  ) {
  --alt-text-add-image: url(images/altText_add.svg);
  --alt-text-done-image: url(images/altText_done.svg);

  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
  padding-inline: 8px;
  pointer-events: all;
  font: menu;
  font-weight: 590;
  font-size: 12px;
  color: var(--editor-toolbar-fg-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      ):disabled
  ) {
  pointer-events: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      )::before
  ) {
  content: "";
  -webkit-mask-image: var(--alt-text-add-image);
  mask-image: var(--alt-text-add-image);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  display: inline-block;
  width: 12px;
  height: 13px;
  background-color: var(--editor-toolbar-fg-color);
  margin-inline-end: 4px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      ):hover::before
  ) {
  background-color: var(--editor-toolbar-hover-fg-color);
}

.this__viewport
  :deep(
    .done:is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      )::before
  ) {
  -webkit-mask-image: var(--alt-text-done-image);
  mask-image: var(--alt-text-done-image);
}

.this__viewport
  :deep(
    .new:is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      )::before
  ) {
  width: 16px;
  height: 16px;
  -webkit-mask-image: var(--new-alt-text-warning-image);
  mask-image: var(--new-alt-text-warning-image);
  background-color: var(--alt-text-warning-color);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.this__viewport
  :deep(
    .new:is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      ):hover::before
  ) {
  background-color: var(--alt-text-hover-warning-color);
}

.this__viewport
  :deep(
    .new.done:is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      )::before
  ) {
  -webkit-mask-image: var(--alt-text-done-image);
  mask-image: var(--alt-text-done-image);
  background-color: var(--alt-text-done-color);
}

.this__viewport
  :deep(
    .new.done:is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      ):hover::before
  ) {
  background-color: var(--alt-text-hover-done-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    .annotationEditorLayer
                      :is(
                        .freeTextEditor,
                        .inkEditor,
                        .stampEditor,
                        .highlightEditor
                      ),
                    .textLayer
                  )
                  .editToolbar
              )
              .buttons
          )
          .altText
      )
      .tooltip
  ) {
  display: none;
}

.this__viewport
  :deep(
    .show:is(
        :is(
            :is(
                :is(
                    :is(
                        .annotationEditorLayer
                          :is(
                            .freeTextEditor,
                            .inkEditor,
                            .stampEditor,
                            .highlightEditor
                          ),
                        .textLayer
                      )
                      .editToolbar
                  )
                  .buttons
              )
              .altText
          )
          .tooltip
      )
  ) {
  --alt-text-tooltip-bg: #f0f0f4;
  --alt-text-tooltip-fg: #15141a;
  --alt-text-tooltip-border: #8f8f9d;
  --alt-text-tooltip-shadow: 0px 2px 6px 0px rgb(58 57 68 / 0.2);
}

@media (prefers-color-scheme: dark) {
  .this__viewport
    :deep(
      .show:is(
          :is(
              :is(
                  :is(
                      :is(
                          .annotationEditorLayer
                            :is(
                              .freeTextEditor,
                              .inkEditor,
                              .stampEditor,
                              .highlightEditor
                            ),
                          .textLayer
                        )
                        .editToolbar
                    )
                    .buttons
                )
                .altText
            )
            .tooltip
        )
    ) {
    --alt-text-tooltip-bg: #1c1b22;
    --alt-text-tooltip-fg: #fbfbfe;
    --alt-text-tooltip-shadow: 0px 2px 6px 0px #15141a;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport
    :deep(
      .show:is(
          :is(
              :is(
                  :is(
                      :is(
                          .annotationEditorLayer
                            :is(
                              .freeTextEditor,
                              .inkEditor,
                              .stampEditor,
                              .highlightEditor
                            ),
                          .textLayer
                        )
                        .editToolbar
                    )
                    .buttons
                )
                .altText
            )
            .tooltip
        )
    ) {
    --alt-text-tooltip-bg: Canvas;
    --alt-text-tooltip-fg: CanvasText;
    --alt-text-tooltip-border: CanvasText;
    --alt-text-tooltip-shadow: none;
  }
}

.this__viewport
  :deep(
    .show:is(
        :is(
            :is(
                :is(
                    :is(
                        .annotationEditorLayer
                          :is(
                            .freeTextEditor,
                            .inkEditor,
                            .stampEditor,
                            .highlightEditor
                          ),
                        .textLayer
                      )
                      .editToolbar
                  )
                  .buttons
              )
              .altText
          )
          .tooltip
      )
  ) {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% + 2px);
  inset-inline-start: 0;
  padding-block: 2px 3px;
  padding-inline: 3px;
  max-width: 300px;
  width: -moz-max-content;
  width: max-content;
  height: auto;
  font-size: 12px;

  border: 0.5px solid var(--alt-text-tooltip-border);
  background: var(--alt-text-tooltip-bg);
  box-shadow: var(--alt-text-tooltip-shadow);
  color: var(--alt-text-tooltip-fg);

  pointer-events: none;
}

.this__viewport :deep(.annotationEditorLayer .freeTextEditor) {
  padding: calc(var(--freetext-padding) * var(--scale-factor));
  width: auto;
  height: auto;
  touch-action: none;
}

.this__viewport :deep(.annotationEditorLayer .freeTextEditor .internal) {
  background: transparent;
  border: none;
  inset: 0;
  overflow: visible;
  white-space: nowrap;
  font: 10px sans-serif;
  line-height: var(--freetext-line-height);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.this__viewport :deep(.annotationEditorLayer .freeTextEditor .overlay) {
  position: absolute;
  display: none;
  background: transparent;
  inset: 0;
  width: 100%;
  height: 100%;
}

.this__viewport :deep(.annotationEditorLayer freeTextEditor .overlay.enabled) {
  display: block;
}

.this__viewport
  :deep(.annotationEditorLayer .freeTextEditor .internal:empty::before) {
  content: attr(default-content);
  color: gray;
}

.this__viewport :deep(.annotationEditorLayer .freeTextEditor .internal:focus) {
  outline: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}

.this__viewport :deep(.annotationEditorLayer .inkEditor) {
  width: 100%;
  height: 100%;
}

.this__viewport :deep(.annotationEditorLayer .inkEditor.editing) {
  cursor: inherit;
}

.this__viewport :deep(.annotationEditorLayer .inkEditor .inkEditorCanvas) {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.this__viewport :deep(.annotationEditorLayer .stampEditor) {
  width: auto;
  height: auto;
}

.this__viewport :deep(:is(.annotationEditorLayer .stampEditor) canvas) {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
}

.this__viewport
  :deep(:is(.annotationEditorLayer .stampEditor) .noAltTextBadge) {
  --no-alt-text-badge-border-color: #f0f0f4;
  --no-alt-text-badge-bg-color: #cfcfd8;
  --no-alt-text-badge-fg-color: #5b5b66;
}

@media (prefers-color-scheme: dark) {
  .this__viewport
    :deep(:is(.annotationEditorLayer .stampEditor) .noAltTextBadge) {
    --no-alt-text-badge-border-color: #52525e;
    --no-alt-text-badge-bg-color: #fbfbfe;
    --no-alt-text-badge-fg-color: #15141a;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport
    :deep(:is(.annotationEditorLayer .stampEditor) .noAltTextBadge) {
    --no-alt-text-badge-border-color: ButtonText;
    --no-alt-text-badge-bg-color: ButtonFace;
    --no-alt-text-badge-fg-color: ButtonText;
  }
}

.this__viewport
  :deep(:is(.annotationEditorLayer .stampEditor) .noAltTextBadge) {
  position: absolute;
  inset-inline-end: 5px;
  inset-block-end: 5px;
  display: inline-flex;
  width: 32px;
  height: 32px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;

  border-radius: 2px;
  border: 1px solid var(--no-alt-text-badge-border-color);
  background: var(--no-alt-text-badge-bg-color);
}

.this__viewport
  :deep(:is(:is(.annotationEditorLayer .stampEditor) .noAltTextBadge)::before) {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-mask-image: var(--new-alt-text-warning-image);
  mask-image: var(--new-alt-text-warning-image);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--no-alt-text-badge-fg-color);
}

.this__viewport
  :deep(
    :is(.annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor))
      > .resizers
  ) {
  position: absolute;
  inset: 0;
}

.this__viewport
  :deep(
    .hidden:is(
        :is(
            .annotationEditorLayer
              :is(.freeTextEditor, .inkEditor, .stampEditor)
          )
          > .resizers
      )
  ) {
  display: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            .annotationEditorLayer
              :is(.freeTextEditor, .inkEditor, .stampEditor)
          )
          > .resizers
      )
      > .resizer
  ) {
  width: var(--resizer-size);
  height: var(--resizer-size);
  background: content-box var(--resizer-bg-color);
  border: var(--focus-outline-around);
  border-radius: 2px;
  position: absolute;
}

.this__viewport
  :deep(
    .topLeft:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  top: var(--resizer-shift);
  left: var(--resizer-shift);
}

.this__viewport
  :deep(
    .topMiddle:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  top: var(--resizer-shift);
  left: calc(50% + var(--resizer-shift));
}

.this__viewport
  :deep(
    .topRight:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  top: var(--resizer-shift);
  right: var(--resizer-shift);
}

.this__viewport
  :deep(
    .middleRight:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  top: calc(50% + var(--resizer-shift));
  right: var(--resizer-shift);
}

.this__viewport
  :deep(
    .bottomRight:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  bottom: var(--resizer-shift);
  right: var(--resizer-shift);
}

.this__viewport
  :deep(
    .bottomMiddle:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  bottom: var(--resizer-shift);
  left: calc(50% + var(--resizer-shift));
}

.this__viewport
  :deep(
    .bottomLeft:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  bottom: var(--resizer-shift);
  left: var(--resizer-shift);
}

.this__viewport
  :deep(
    .middleLeft:is(
        :is(
            :is(
                .annotationEditorLayer
                  :is(.freeTextEditor, .inkEditor, .stampEditor)
              )
              > .resizers
          )
          > .resizer
      )
  ) {
  top: calc(50% + var(--resizer-shift));
  left: var(--resizer-shift);
}

.this__viewport
  :deep(
    .topLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      ),
    .bottomRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: nwse-resize;
}

.this__viewport
  :deep(
    .topMiddle:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      ),
    .bottomMiddle:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: ns-resize;
}

.this__viewport
  :deep(
    .topRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      ),
    .bottomLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: nesw-resize;
}

.this__viewport
  :deep(
    .middleRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      ),
    .middleLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: ew-resize;
}

.this__viewport
  :deep(
    .topLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      ),
    .bottomRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: nesw-resize;
}

.this__viewport
  :deep(
    .topMiddle:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      ),
    .bottomMiddle:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: ew-resize;
}

.this__viewport
  :deep(
    .topRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      ),
    .bottomLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: nwse-resize;
}

.this__viewport
  :deep(
    .middleRight:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      ),
    .middleLeft:is(
        :is(
            .annotationEditorLayer[data-main-rotation="0"]
              :is([data-editor-rotation="90"], [data-editor-rotation="270"]),
            .annotationEditorLayer[data-main-rotation="90"]
              :is([data-editor-rotation="0"], [data-editor-rotation="180"]),
            .annotationEditorLayer[data-main-rotation="180"]
              :is([data-editor-rotation="270"], [data-editor-rotation="90"]),
            .annotationEditorLayer[data-main-rotation="270"]
              :is([data-editor-rotation="180"], [data-editor-rotation="0"])
          )
          > .resizers
          > .resizer
      )
  ) {
  cursor: ns-resize;
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="90"],
            [data-main-rotation="90"] [data-editor-rotation="0"],
            [data-main-rotation="180"] [data-editor-rotation="270"],
            [data-main-rotation="270"] [data-editor-rotation="180"]
          )
      )
      .editToolbar
  ) {
  rotate: 270deg;
}

[dir="ltr"]
  :is(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="90"],
            [data-main-rotation="90"] [data-editor-rotation="0"],
            [data-main-rotation="180"] [data-editor-rotation="270"],
            [data-main-rotation="270"] [data-editor-rotation="180"]
          )
      )
      .editToolbar
  ) {
  inset-inline-end: calc(0px - var(--editor-toolbar-vert-offset));
  inset-block-start: 0;
}

[dir="rtl"]
  :is(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="90"],
            [data-main-rotation="90"] [data-editor-rotation="0"],
            [data-main-rotation="180"] [data-editor-rotation="270"],
            [data-main-rotation="270"] [data-editor-rotation="180"]
          )
      )
      .editToolbar
  ) {
  inset-inline-end: calc(100% + var(--editor-toolbar-vert-offset));
  inset-block-start: 0;
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="180"],
            [data-main-rotation="90"] [data-editor-rotation="90"],
            [data-main-rotation="180"] [data-editor-rotation="0"],
            [data-main-rotation="270"] [data-editor-rotation="270"]
          )
      )
      .editToolbar
  ) {
  rotate: 180deg;
  inset-inline-end: 100%;
  inset-block-start: calc(0pc - var(--editor-toolbar-vert-offset));
}

.this__viewport
  :deep(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="270"],
            [data-main-rotation="90"] [data-editor-rotation="180"],
            [data-main-rotation="180"] [data-editor-rotation="90"],
            [data-main-rotation="270"] [data-editor-rotation="0"]
          )
      )
      .editToolbar
  ) {
  rotate: 90deg;
}

[dir="ltr"]
  :is(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="270"],
            [data-main-rotation="90"] [data-editor-rotation="180"],
            [data-main-rotation="180"] [data-editor-rotation="90"],
            [data-main-rotation="270"] [data-editor-rotation="0"]
          )
      )
      .editToolbar
  ) {
  inset-inline-end: calc(100% + var(--editor-toolbar-vert-offset));
  inset-block-start: 100%;
}

[dir="rtl"]
  :is(
    :is(
        .annotationEditorLayer
          :is(
            [data-main-rotation="0"] [data-editor-rotation="270"],
            [data-main-rotation="90"] [data-editor-rotation="180"],
            [data-main-rotation="180"] [data-editor-rotation="90"],
            [data-main-rotation="270"] [data-editor-rotation="0"]
          )
      )
      .editToolbar
  ) {
  inset-inline-start: calc(0px - var(--editor-toolbar-vert-offset));
  inset-block-start: 0;
}

.this__viewport :deep(.dialog.altText::backdrop) {
  -webkit-mask: url(#alttext-manager-mask);
  mask: url(#alttext-manager-mask);
}

.this__viewport :deep(.dialog.altText.positioned) {
  margin: 0;
}

.this__viewport :deep(.dialog.altText #altTextContainer) {
  width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.this__viewport
  :deep(:is(.dialog.altText #altTextContainer) #overallDescription) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.this__viewport
  :deep(:is(:is(.dialog.altText #altTextContainer) #overallDescription) span) {
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(:is(.dialog.altText #altTextContainer) #overallDescription) .title
  ) {
  font-size: 13px;
  font-style: normal;
  font-weight: 590;
}

.this__viewport :deep(:is(.dialog.altText #altTextContainer) #addDescription) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

.this__viewport
  :deep(
    :is(:is(.dialog.altText #altTextContainer) #addDescription) .descriptionArea
  ) {
  flex: 1;
  padding-inline: 24px 10px;
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.altText #altTextContainer) #addDescription)
          .descriptionArea
      )
      textarea
  ) {
  width: 100%;
  min-height: 75px;
}

.this__viewport :deep(:is(.dialog.altText #altTextContainer) #buttons) {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.this__viewport :deep(.dialog.newAltText) {
  --new-alt-text-ai-disclaimer-icon: url(images/altText_disclaimer.svg);
  --new-alt-text-spinner-icon: url(images/altText_spinner.svg);
  --preview-image-bg-color: #f0f0f4;
  --preview-image-border: none;
}

@media (prefers-color-scheme: dark) {
  .this__viewport :deep(.dialog.newAltText) {
    --preview-image-bg-color: #2b2a33;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.dialog.newAltText) {
    --preview-image-bg-color: ButtonFace;
    --preview-image-border: 1px solid ButtonText;
  }
}

.this__viewport :deep(.dialog.newAltText) {
  width: 80%;
  max-width: 570px;
  min-width: 300px;
  padding: 0;
}

.this__viewport
  :deep(
    .dialog.newAltText.noAi #newAltTextDisclaimer,
    .dialog.newAltText.noAi #newAltTextCreateAutomatically
  ) {
  display: none !important;
}

.this__viewport
  :deep(.dialog.newAltText.aiInstalling #newAltTextCreateAutomatically) {
  display: none !important;
}

.this__viewport
  :deep(.dialog.newAltText.aiInstalling #newAltTextDownloadModel) {
  display: flex !important;
}

.this__viewport :deep(.dialog.newAltText.error #newAltTextNotNow) {
  display: none !important;
}

.this__viewport :deep(.dialog.newAltText.error #newAltTextCancel) {
  display: inline-block !important;
}

.this__viewport :deep(.dialog.newAltText:not(.error) #newAltTextError) {
  display: none !important;
}

.this__viewport :deep(.dialog.newAltText #newAltTextContainer) {
  display: flex;
  width: auto;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  flex: 0 1 auto;
  line-height: normal;
}

.this__viewport
  :deep(:is(.dialog.newAltText #newAltTextContainer) #mainContent) {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  flex: 1 1 auto;
}

.this__viewport
  :deep(
    :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
      #descriptionAndSettings
  ) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
      #descriptionInstruction
  ) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  flex: 1 1 auto;
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
          #descriptionInstruction
      )
      #newAltTextDescriptionContainer
  ) {
  width: 100%;
  height: 70px;
  position: relative;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDescriptionContainer
      )
      textarea
  ) {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
                  #descriptionInstruction
              )
              #newAltTextDescriptionContainer
          )
          textarea
      )::-moz-placeholder
  ) {
  color: var(--text-secondary-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
                  #descriptionInstruction
              )
              #newAltTextDescriptionContainer
          )
          textarea
      )::placeholder
  ) {
  color: var(--text-secondary-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDescriptionContainer
      )
      .altTextSpinner
  ) {
  display: none;
  position: absolute;
  width: 16px;
  height: 16px;
  inset-inline-start: 8px;
  inset-block-start: 8px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--text-secondary-color);
  pointer-events: none;
}

.this__viewport
  :deep(
    .loading:is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDescriptionContainer
      )
      textarea::-moz-placeholder
  ) {
  color: transparent;
}

.this__viewport
  :deep(
    .loading:is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDescriptionContainer
      )
      textarea::placeholder
  ) {
  color: transparent;
}

.this__viewport
  :deep(
    .loading:is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDescriptionContainer
      )
      .altTextSpinner
  ) {
  display: inline-block;
  -webkit-mask-image: var(--new-alt-text-spinner-icon);
  mask-image: var(--new-alt-text-spinner-icon);
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
          #descriptionInstruction
      )
      #newAltTextDescription
  ) {
  font-size: 11px;
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
          #descriptionInstruction
      )
      #newAltTextDisclaimer
  ) {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  font-size: 11px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
              #descriptionInstruction
          )
          #newAltTextDisclaimer
      )::before
  ) {
  content: "";
  display: inline-block;
  width: 17px;
  height: 16px;
  -webkit-mask-image: var(--new-alt-text-ai-disclaimer-icon);
  mask-image: var(--new-alt-text-ai-disclaimer-icon);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--text-secondary-color);
  flex: 1 0 auto;
}

.this__viewport
  :deep(
    :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
      #newAltTextDownloadModel
  ) {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
          #newAltTextDownloadModel
      )::before
  ) {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-mask-image: var(--new-alt-text-spinner-icon);
  mask-image: var(--new-alt-text-spinner-icon);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--text-secondary-color);
}

.this__viewport
  :deep(
    :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
      #newAltTextImagePreview
  ) {
  width: 180px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  background-color: var(--preview-image-bg-color);
  border: var(--preview-image-border);
}

.this__viewport
  :deep(
    :is(
        :is(:is(.dialog.newAltText #newAltTextContainer) #mainContent)
          #newAltTextImagePreview
      )
      > canvas
  ) {
  max-width: 100%;
  max-height: 100%;
}

.this__viewport :deep(.colorPicker) {
  --hover-outline-color: #0250bb;
  --selected-outline-color: #0060df;
  --swatch-border-color: #cfcfd8;
}

@media (prefers-color-scheme: dark) {
  .this__viewport :deep(.colorPicker) {
    --hover-outline-color: #80ebff;
    --selected-outline-color: #aaf2ff;
    --swatch-border-color: #52525e;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.colorPicker) {
    --hover-outline-color: Highlight;
    --selected-outline-color: var(--hover-outline-color);
    --swatch-border-color: ButtonText;
  }
}

.this__viewport :deep(.colorPicker .swatch) {
  width: 16px;
  height: 16px;
  border: 1px solid var(--swatch-border-color);
  border-radius: 100%;
  outline-offset: 2px;
  box-sizing: border-box;
  forced-color-adjust: none;
}

.this__viewport :deep(.colorPicker button:is(:hover, .selected) > .swatch) {
  border: none;
}

.this__viewport
  :deep(
    .annotationEditorLayer[data-main-rotation="0"]
      .highlightEditor:not(.free)
      > .editToolbar
  ) {
  rotate: 0deg;
}

.this__viewport
  :deep(
    .annotationEditorLayer[data-main-rotation="90"]
      .highlightEditor:not(.free)
      > .editToolbar
  ) {
  rotate: 270deg;
}

.this__viewport
  :deep(
    .annotationEditorLayer[data-main-rotation="180"]
      .highlightEditor:not(.free)
      > .editToolbar
  ) {
  rotate: 180deg;
}

.this__viewport
  :deep(
    .annotationEditorLayer[data-main-rotation="270"]
      .highlightEditor:not(.free)
      > .editToolbar
  ) {
  rotate: 90deg;
}

.this__viewport :deep(.annotationEditorLayer .highlightEditor) {
  position: absolute;
  background: transparent;
  z-index: 1;
  cursor: auto;
  max-width: 100%;
  max-height: 100%;
  border: none;
  outline: none;
  pointer-events: none;
  transform-origin: 0 0;
}

.this__viewport :deep(:is(.annotationEditorLayer .highlightEditor):not(.free)) {
  transform: none;
}

.this__viewport :deep(:is(.annotationEditorLayer .highlightEditor) .internal) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.this__viewport
  :deep(.disabled:is(.annotationEditorLayer .highlightEditor) .internal) {
  pointer-events: none;
}

.this__viewport
  :deep(.selectedEditor:is(.annotationEditorLayer .highlightEditor) .internal) {
  cursor: pointer;
}

.this__viewport
  :deep(:is(.annotationEditorLayer .highlightEditor) .editToolbar) {
  --editor-toolbar-colorpicker-arrow-image: url(images/toolbarButton-menuArrow.svg);

  transform-origin: center !important;
}

.this__viewport
  :deep(
    :is(:is(:is(.annotationEditorLayer .highlightEditor) .editToolbar) .buttons)
      .colorPicker
  ) {
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
              .buttons
          )
          .colorPicker
      )::after
  ) {
  content: "";
  -webkit-mask-image: var(--editor-toolbar-colorpicker-arrow-image);
  mask-image: var(--editor-toolbar-colorpicker-arrow-image);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  display: inline-block;
  background-color: var(--editor-toolbar-fg-color);
  width: 12px;
  height: 12px;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
              .buttons
          )
          .colorPicker
      ):hover::after
  ) {
  background-color: var(--editor-toolbar-hover-fg-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
              .buttons
          )
          .colorPicker
      ):has(.dropdown:not(.hidden))
  ) {
  background-color: var(--editor-toolbar-hover-bg-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
              .buttons
          )
          .colorPicker
      ):has(.dropdown:not(.hidden))::after
  ) {
  scale: -1;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
              .buttons
          )
          .colorPicker
      )
      .dropdown
  ) {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 11px;
  padding-block: 8px;
  border-radius: 6px;
  background-color: var(--editor-toolbar-bg-color);
  border: 1px solid var(--editor-toolbar-border-color);
  box-shadow: var(--editor-toolbar-shadow);
  inset-block-start: calc(100% + 4px);
  width: calc(100% + 2 * var(--editor-toolbar-padding));
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
                  .buttons
              )
              .colorPicker
          )
          .dropdown
      )
      button
  ) {
  width: 100%;
  height: auto;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    :is(
                        :is(.annotationEditorLayer .highlightEditor)
                          .editToolbar
                      )
                      .buttons
                  )
                  .colorPicker
              )
              .dropdown
          )
          button
      ):is(:active, :focus-visible)
  ) {
  outline: none;
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    :is(
                        :is(.annotationEditorLayer .highlightEditor)
                          .editToolbar
                      )
                      .buttons
                  )
                  .colorPicker
              )
              .dropdown
          )
          button
      )
      > .swatch
  ) {
  outline-offset: 2px;
}

[aria-selected="true"]:is(
    :is(
        :is(
            :is(
                :is(:is(.annotationEditorLayer .highlightEditor) .editToolbar)
                  .buttons
              )
              .colorPicker
          )
          .dropdown
      )
      button
  )
  > .swatch {
  outline: 2px solid var(--selected-outline-color);
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(
                :is(
                    :is(
                        :is(.annotationEditorLayer .highlightEditor)
                          .editToolbar
                      )
                      .buttons
                  )
                  .colorPicker
              )
              .dropdown
          )
          button
      ):is(:hover, :active, :focus-visible)
      > .swatch
  ) {
  outline: 2px solid var(--hover-outline-color);
}

.this__viewport
  :deep(.editorParamsToolbar:has(#highlightParamsToolbarContainer)) {
  padding: unset;
}

#highlightParamsToolbarContainer {
  height: auto;
  padding-inline: 10px;
  padding-block: 10px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

#highlightParamsToolbarContainer .editorParamsLabel {
  width: -moz-fit-content;
  width: fit-content;
  inset-inline-start: 0;
}

#highlightParamsToolbarContainer .colorPicker {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.this__viewport
  :deep(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: auto;
}

.this__viewport
  :deep(
    :is(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) button
  ) {
  width: auto;
  height: auto;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  flex: 0 0 auto;
}

.this__viewport
  :deep(
    :is(
        :is(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) button
      )
      .swatch
  ) {
  width: 24px;
  height: 24px;
}

.this__viewport
  :deep(
    :is(
        :is(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) button
      ):is(:active, :focus-visible)
  ) {
  outline: none;
}

[aria-selected="true"]:is(
    :is(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) button
  )
  > .swatch {
  outline: 2px solid var(--selected-outline-color);
}

.this__viewport
  :deep(
    :is(
        :is(:is(#highlightParamsToolbarContainer .colorPicker) .dropdown) button
      ):is(:hover, :active, :focus-visible)
      > .swatch
  ) {
  outline: 2px solid var(--hover-outline-color);
}

#highlightParamsToolbarContainer #editorHighlightThickness {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(#highlightParamsToolbarContainer #editorHighlightThickness)
      .editorParamsLabel
  ) {
  width: 100%;
  height: auto;
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(#highlightParamsToolbarContainer #editorHighlightThickness)
      .thicknessPicker
  ) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  --example-color: #bfbfc9;
}

@media (prefers-color-scheme: dark) {
  .this__viewport
    :deep(
      :is(#highlightParamsToolbarContainer #editorHighlightThickness)
        .thicknessPicker
    ) {
    --example-color: #80808e;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport
    :deep(
      :is(#highlightParamsToolbarContainer #editorHighlightThickness)
        .thicknessPicker
    ) {
    --example-color: CanvasText;
  }
}

.this__viewport
  :deep(
    :is(
        :is(
            :is(#highlightParamsToolbarContainer #editorHighlightThickness)
              .thicknessPicker
          )
          > .editorParamsSlider[disabled]
      )
  ) {
  opacity: 0.4;
}

.this__viewport
  :deep(
    :is(
        :is(#highlightParamsToolbarContainer #editorHighlightThickness)
          .thicknessPicker
      )::before,
    :is(
        :is(#highlightParamsToolbarContainer #editorHighlightThickness)
          .thicknessPicker
      )::after
  ) {
  content: "";
  width: 8px;
  aspect-ratio: 1;
  display: block;
  border-radius: 100%;
  background-color: var(--example-color);
}

.this__viewport
  :deep(
    :is(
        :is(#highlightParamsToolbarContainer #editorHighlightThickness)
          .thicknessPicker
      )::after
  ) {
  width: 24px;
}

.this__viewport
  :deep(
    :is(
        :is(#highlightParamsToolbarContainer #editorHighlightThickness)
          .thicknessPicker
      )
      .editorParamsSlider
  ) {
  width: unset;
  height: 14px;
}

#highlightParamsToolbarContainer #editorHighlightVisibility {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.this__viewport
  :deep(
    :is(#highlightParamsToolbarContainer #editorHighlightVisibility) .divider
  ) {
  --divider-color: #d7d7db;
}

@media (prefers-color-scheme: dark) {
  .this__viewport
    :deep(
      :is(#highlightParamsToolbarContainer #editorHighlightVisibility) .divider
    ) {
    --divider-color: #8f8f9d;
  }
}

@media screen and (forced-colors: active) {
  .this__viewport
    :deep(
      :is(#highlightParamsToolbarContainer #editorHighlightVisibility) .divider
    ) {
    --divider-color: CanvasText;
  }
}

.this__viewport
  :deep(
    :is(#highlightParamsToolbarContainer #editorHighlightVisibility) .divider
  ) {
  margin-block: 4px;
  width: 100%;
  height: 1px;
  background-color: var(--divider-color);
}

.this__viewport
  :deep(
    :is(#highlightParamsToolbarContainer #editorHighlightVisibility) .toggler
  ) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

#altTextSettingsDialog {
  padding: 16px;
}

#altTextSettingsDialog #altTextSettingsContainer {
  display: flex;
  width: 573px;
  flex-direction: column;
  gap: 16px;
}

.this__viewport
  :deep(:is(#altTextSettingsDialog #altTextSettingsContainer) .mainContainer) {
  gap: 16px;
}

.this__viewport
  :deep(:is(#altTextSettingsDialog #altTextSettingsContainer) .description) {
  color: var(--text-secondary-color);
}

.this__viewport
  :deep(
    :is(#altTextSettingsDialog #altTextSettingsContainer) #aiModelSettings
  ) {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.this__viewport
  :deep(
    :is(:is(#altTextSettingsDialog #altTextSettingsContainer) #aiModelSettings)
      button
  ) {
  width: -moz-fit-content;
  width: fit-content;
}

.this__viewport
  :deep(
    .download:is(
        :is(#altTextSettingsDialog #altTextSettingsContainer) #aiModelSettings
      )
      #deleteModelButton
  ) {
  display: none;
}

.this__viewport
  :deep(
    :is(
        :is(#altTextSettingsDialog #altTextSettingsContainer) #aiModelSettings
      ):not(.download)
      #downloadModelButton
  ) {
  display: none;
}

.this__viewport
  :deep(
    :is(#altTextSettingsDialog #altTextSettingsContainer) #automaticAltText,
    :is(#altTextSettingsDialog #altTextSettingsContainer) #altTextEditor
  ) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.this__viewport
  :deep(
    :is(#altTextSettingsDialog #altTextSettingsContainer)
      #createModelDescription,
    :is(#altTextSettingsDialog #altTextSettingsContainer) #aiModelSettings,
    :is(#altTextSettingsDialog #altTextSettingsContainer)
      #showAltTextDialogDescription
  ) {
  padding-inline-start: 40px;
}

.this__viewport
  :deep(
    :is(#altTextSettingsDialog #altTextSettingsContainer) #automaticSettings
  ) {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

:root {
  --viewer-container-height: 0;
  --pdfViewer-padding-bottom: 0;
  --page-margin: 1px auto -8px;
  --page-border: 9px solid transparent;
  --spreadHorizontalWrapped-margin-LR: -3.5px;
  --loading-icon-delay: 400ms;
}

@media screen and (forced-colors: active) {
  :root {
    --pdfViewer-padding-bottom: 9px;
    --page-margin: 8px auto -1px;
    --page-border: 1px solid CanvasText;
    --spreadHorizontalWrapped-margin-LR: 3.5px;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg) translateY(-100%);
}
[data-main-rotation="180"] {
  transform: rotate(180deg) translate(-100%, -100%);
}
[data-main-rotation="270"] {
  transform: rotate(270deg) translateX(-100%);
}

#hiddenCopyElement,
.this__viewport :deep(.hiddenCanvasElement) {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}

.this__viewport :deep(.pdfViewer) {
  --scale-factor: 1;

  padding-bottom: var(--pdfViewer-padding-bottom);

  --hcm-highlight-filter: none;
  --hcm-highlight-selected-filter: none;
}

@media screen and (forced-colors: active) {
  .this__viewport :deep(.pdfViewer) {
    --hcm-highlight-filter: invert(100%);
  }
}

.this__viewport :deep(.pdfViewer.copyAll) {
  cursor: wait;
}

.this__viewport :deep(.pdfViewer .canvasWrapper) {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.this__viewport :deep(:is(.pdfViewer .canvasWrapper) canvas) {
  margin: 0;
  display: block;
}

[hidden]:is(:is(.pdfViewer .canvasWrapper) canvas) {
  display: none;
}

[zooming]:is(:is(.pdfViewer .canvasWrapper) canvas) {
  width: 100%;
  height: 100%;
}

.this__viewport :deep(:is(:is(.pdfViewer .canvasWrapper) canvas) .structTree) {
  contain: strict;
}

.this__viewport :deep(.pdfViewer .page) {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: var(--page-margin);
  position: relative;
  overflow: visible;
  border: var(--page-border);
  background-clip: content-box;
  background-color: rgb(255 255 255);
}

.this__viewport :deep(.pdfViewer .dummyPage) {
  position: relative;
  width: 0;
  height: var(--viewer-container-height);
}

.this__viewport :deep(.pdfViewer.noUserSelect) {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.this__viewport :deep(.pdfViewer.removePageBorders .page) {
  margin: 0 auto 10px;
  border: none;
}

.this__viewport :deep(.pdfViewer.singlePageView) {
  display: inline-block;
}

.this__viewport :deep(.pdfViewer.singlePageView .page) {
  margin: 0;
  border: none;
}

.pdfViewer:is(.scrollHorizontal, .scrollWrapped),
.this__viewport :deep(.spread) {
  margin-inline: 3.5px;
  text-align: center;
}

.pdfViewer.scrollHorizontal,
.this__viewport :deep(.spread) {
  white-space: nowrap;
}

.pdfViewer.removePageBorders,
.this__viewport
  :deep(.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .spread) {
  margin-inline: 0;
}

.spread :is(.page, .dummyPage),
.this__viewport
  :deep(.pdfViewer:is(.scrollHorizontal, .scrollWrapped) :is(.page, .spread)) {
  display: inline-block;
  vertical-align: middle;
}

.spread .page,
.this__viewport :deep(.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .page) {
  margin-inline: var(--spreadHorizontalWrapped-margin-LR);
}

.pdfViewer.removePageBorders .spread .page,
.this__viewport
  :deep(
    .pdfViewer.removePageBorders:is(.scrollHorizontal, .scrollWrapped) .page
  ) {
  margin-inline: 5px;
}

.this__viewport :deep(.pdfViewer .page.loadingIcon::after) {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: url("images/loading-icon.gif") center no-repeat;
  display: none;
  transition-property: display;
  transition-delay: var(--loading-icon-delay);
  z-index: 5;
  contain: strict;
}

.this__viewport :deep(.pdfViewer .page.loading::after) {
  display: block;
}

.this__viewport :deep(.pdfViewer .page:not(.loading)::after) {
  transition-property: none;
  display: none;
}

.this__viewport :deep(.pdfPresentationMode .pdfViewer) {
  padding-bottom: 0;
}

.this__viewport :deep(.pdfPresentationMode .spread) {
  margin: 0;
}

.this__viewport :deep(.pdfPresentationMode .pdfViewer .page) {
  margin: 0 auto;
  border: 2px solid transparent;
}
</style>
