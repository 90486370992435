<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { FlashMessage, LoadingSpinner } from "@/components";
import DocumentInfo from "./DocumentInfo.vue";
import FileUpdate from "./FileUpdate.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  useDocumentQuery,
  type WorkspaceDocumentFragment,
} from "@/graphql/operations";
import DocumentService from "@/document";

const route = useRoute();
const docId = route.params.documentId as string;

const store = useStore();

const { fetching, data, executeQuery } = useDocumentQuery({
  variables: {
    id: docId,
  },
  requestPolicy: "network-only",
});

const document = computed(() => data.value?.document);
const documentUsageCounts = computed(() => data.value?.documentUsageCounts);

const editableDoc = ref<WorkspaceDocumentFragment | null>(null);

watch(
  document,
  (newDoc, oldDoc) => {
    //first load
    if (newDoc !== undefined && editableDoc.value === null) {
      editableDoc.value = Object.assign({}, document.value);
    }
  },
  { immediate: true }
);

const flashMessage = ref({
  isActive: false,
  state: "",
});

const displayFlashMessage = (state: string) => {
  flashMessage.value.isActive = true;
  flashMessage.value.state = state;
  window.setTimeout(() => (flashMessage.value.isActive = false), 2500);
};

const save = async () => {
  const postgresId = document.value!.id;

  const props = {
    topTitle: editableDoc.value!.topTitle,
    displayTitle: editableDoc.value!.displayTitle!,
    teaserText: editableDoc.value!.teaserText!,
    tags: editableDoc.value!.tags as Array<string>,
    doesSupportSharedspace: editableDoc.value!.doesSupportSharedspace!,
    isDownloadable: editableDoc.value!.isDownloadable,
    doesSupportFlows: editableDoc.value!.doesSupportFlows,
    externalId: editableDoc.value!.externalId,
    displayTeaserTextInSpace: editableDoc.value!.displayTeaserTextInSpace,
  };

  try {
    await DocumentService.update(postgresId!, props);
    displayFlashMessage("success");
  } catch {
    displayFlashMessage("error");
  }
};

const updateTags = (tags: Array<string>) => {
  editableDoc.value!.tags = tags;
  save();
};

const updateShareable = (shareable: boolean) => {
  editableDoc.value!.doesSupportSharedspace = shareable;
  save();
};

const updateDownloadable = (isDownloadable: boolean) => {
  editableDoc.value!.isDownloadable = isDownloadable;
  save();
};

const updateSupportsFlows = (supportsFlows: boolean) => {
  editableDoc.value!.doesSupportFlows = supportsFlows;
  save();
};

const setExternalId = (externalId: string) => {
  editableDoc.value!.externalId = externalId;
};

const updateDisplayTeaserText = (displayTeaserText: boolean) => {
  editableDoc.value!.displayTeaserTextInSpace = displayTeaserText;
  save();
};
</script>

<template>
  <div class="document_properties">
    <FlashMessage v-if="flashMessage.isActive" :state="flashMessage.state">
      {{
        flashMessage.state === "success"
          ? $t("pvDocEditor.flashMessage.success")
          : $t("pvDocEditor.flashMessage.error")
      }}
    </FlashMessage>

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <template v-else-if="document && editableDoc">
      <div class="pane">
        <div class="main_content">
          <FileUpdate :doc="document!" />

          <form @submit.prevent="save">
            <div class="top_title row">
              <label>{{ $t("pvDocEditor.topTitle.label") }}</label>
              <input
                class="input"
                type="text"
                v-model="editableDoc!.topTitle"
              />
            </div>

            <div class="card_title row">
              <label>{{ $t("pvDocEditor.displayTitle.label") }}</label>
              <input
                class="input"
                type="text"
                v-model="editableDoc!.displayTitle"
              />
            </div>

            <div class="teaser row">
              <label>{{ $t("pvDocEditor.teaser.label") }}</label>
              <textarea
                class="textarea"
                rows="5"
                v-model="editableDoc!.teaserText"
                :placeholder="$t('pvDocEditor.teaser.placeholder')"
              ></textarea>
            </div>

            <input
              type="submit"
              :value="$t('pvDocEditor.submit')"
              class="button primary"
            />
          </form>
        </div>

        <div class="sidebar">
          <DocumentInfo
            :document="document!"
            :documentUsage="documentUsageCounts!"
            @updateTags="updateTags"
            @updateShareable="updateShareable"
            @updateDownloadable="updateDownloadable"
            @updateSupportsFlows="updateSupportsFlows"
            @updateExternalId="setExternalId"
            @updateDisplayTeaserText="updateDisplayTeaserText"
          />
        </div>
      </div>
    </template>

    <div v-else>No document found</div>
  </div>
</template>

<style lang="scss" scoped>
.document_properties {
  padding: 2em 0;
  height: 100%;
}

.pane {
  display: flex;
}

.pane .main_content {
  flex: 1;
  padding-right: 1em;
}

.pane .sidebar {
  width: 290px;
  padding-left: 1em;
  border-left: 1px solid #cecece;
}

.row label {
  font-size: 0.9em;
  line-height: 1.8em;
}

.row {
  margin-bottom: 20px;
}

.input.num_input {
  font-size: 0.8em;
  width: 55px;
  margin-left: 0.5em;
}

.row .right {
  float: right;
}

.buttons .left {
  float: left;
}

.button.left {
  float: left;
}

form {
  border-top: 1px solid #cecece;
  margin-top: 1.5em;
  padding-top: 1.5em;
}

input.button.primary {
  float: right;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}
</style>
