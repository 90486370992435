<script lang="ts" setup>
import { useDocumentSpaceVisitsQuery, Interval } from "@/graphql/operations";
import { computed, watch, ref, reactive } from "vue";
import { Formatter } from "@/util";
import VisitDetail from "./VisitDetail.vue";

const props = defineProps({
  documentSpaceId: {
    required: true,
    type: String,
  },
});

const documentSpaceId = ref(props.documentSpaceId);

const { executeQuery, data } = useDocumentSpaceVisitsQuery({
  variables: {
    documentSpaceId: documentSpaceId,
  },
  requestPolicy: "network-only",
});

watch(
  () => props.documentSpaceId,
  () => {
    documentSpaceId.value = props.documentSpaceId;
    executeQuery();
  }
);

const visits = computed(
  () => data.value?.maDocumentSpaceVisitsStatistics?.nodes
);

const numVisits = computed(
  () => data.value?.maDocumentSpaceVisitsStatistics?.totalCount
);

const documentSpaceStats = computed(
  () => data.value?.maDocumentSpaceStatistics?.nodes?.[0]
);

const formatDuration = (duration: Interval) => {
  return Formatter.formatDuration({
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
  });
};

const expandedRows = ref(new Set<string>());
const toggle = (id: string) => {
  if (expandedRows.value.has(id)) {
    expandedRows.value.delete(id);
  } else {
    expandedRows.value.add(id);
  }
};

const interactions = [
  "documentHit",
  "documentDownload",
  "videoHit",
  "linkClick",
];
</script>

<template>
  <div v-if="documentSpaceStats">
    <div>
      <div class="info_container">
        <div class="link_container">
          <h3>{{ documentSpaceStats!.name }}</h3>
          <router-link
            :to="{
              name: 'DocumentSpaceAnalytics',
              params: { id: documentSpaceId },
            }"
            class="link"
          >
            Detail Analytics
            <span class="material-symbols-outlined"> arrow_right_alt </span>
          </router-link>
        </div>
        <div class="columns">
          <div class="col">
            <label>{{ $t("visitDetail.totalVisits") }}</label>
            <span>{{ numVisits }}</span>
          </div>
          <div class="col">
            <label>{{ $t("visitDetail.avgDuration") }}</label>
            <span>{{ formatDuration(documentSpaceStats!.avgDuration!) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="data_container">
      <h3>
        {{ $t("visitDetail.header") }}
      </h3>
      <table class="data_table">
        <tbody>
          <template v-for="(visit, idx) in visits" :key="idx">
            <tr>
              <td>
                <span
                  v-if="
                    interactions.some((i) => visit!.interactions!.includes(i))
                  "
                  class="material-symbols-outlined clickable"
                  @click="toggle(visit!.id!)"
                >
                  {{
                    expandedRows.has(visit!.id!) ? "expand_more" : "chevron_right"
                  }}
                </span>
              </td>
              <td>
                {{ Formatter.formatAndTranslateDate(visit!.startsAt! + "Z") }}
              </td>
              <td>
                <span
                  v-if="visit!.interactions!.includes('documentHit')"
                  class="material-symbols-outlined"
                >
                  description
                </span>
                <span
                  v-if="visit!.interactions!.includes('documentDownload')"
                  class="material-symbols-outlined"
                >
                  download
                </span>
                <span
                  v-if="visit!.interactions!.includes('videoHit')"
                  class="material-symbols-outlined"
                >
                  smart_display
                </span>
                <span
                  v-if="visit!.interactions!.includes('linkClick')"
                  class="material-symbols-outlined"
                >
                  link
                </span>
              </td>
              <td>
                {{ formatDuration(visit!.duration!) }}
              </td>
            </tr>
            <tr v-if="expandedRows.has(visit!.id!)">
              <td></td>
              <td colspan="3" class="expansion_container">
                <visit-detail :visitId="visit!.id!" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.info_container {
  padding-left: 30px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 1em;
}

.info_container .link_container {
  display: flex;
}

.info_container .link {
  margin-left: auto;
  font-weight: normal;
  margin-right: 20px;
  line-height: 45px;
  padding: 2px 0;
}

.info_container .link .material-symbols-outlined {
  font-size: 1em;
  vertical-align: middle;
}

.info_container label {
  margin-right: 5px;
}

.info_container h3 {
  line-height: 45px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0;
}

.columns {
  display: flex;
  justify-content: start;
}

.info_container label {
  color: #808080;
}

.data_container h3 {
  margin-left: 30px;
}

table.data_table {
  box-shadow: none;
}

table.data_table tr th,
table.data_table tr td {
  border-bottom: none;
}

table.data_table tr th:first-child,
table.data_table tr td:first-child {
  width: 15px;
  padding: 0;
}

table.data_table tr th:nth-child(2),
table.data_table tr td:nth-child(2) {
  padding-left: 10px;
  padding-right: 5px;
  width: 145px;
}

table.data_table tr th:last-child,
table.data_table tr td:last-child {
  width: 60px;
  text-align: right;
}

table.data_table tr td table.data_table tr td:first-child {
  width: auto;
  padding: 2px 14px 2px 0;
}

td.expansion_container {
  padding-right: 0;
}

table.data_table tr td:first-child span {
  vertical-align: middle;
}

span.clickable:hover {
  cursor: pointer;
}

td .material-symbols-outlined {
  vertical-align: text-bottom;
  font-size: 20px;
}
</style>