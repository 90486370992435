<script lang="ts" setup>
import { Modal } from "@/components";
import { db } from "@/fire";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { useFlashMessage } from "../hooks/useFlashMessage";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);

const { displayFlashMessage } = useFlashMessage();
const store = useStore();

const saveContactRequest = async () => {
  await setDoc(doc(db, "upgrade_requests", uuid()), {
    id: store.getters.user.postgresId,
    email: store.getters.user.email,
    platform: "web",
    type: "space_limit_exceeded",
    timestamp: serverTimestamp(),
  });

  displayFlashMessage("Request successfully sent");
  emit("close");
};
</script>

<template>
  <Modal :active="true" @close="emit('close')">
    <template v-slot:header>
      {{ $t("createDocumentSpaceModal.limitExceeded.title") }}
    </template>

    <template v-slot:body>
      {{ $t("createDocumentSpaceModal.limitExceeded.info") }}
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button class="button primary" @click="saveContactRequest">
          {{ $t("createDocumentSpaceModal.limitExceeded.buttonText") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
