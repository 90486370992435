import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import {
  Login,
  PageNotFound,
  Workspace as WorkspaceOverview,
  EmailVerification,
  JoinableTeams,
  OrganisationManagement,
  SettingsOverview,
  Teams,
  Team,
  TeamMembers,
  TeamWorkspaces,
  WorkspaceList,
  DocumentSpaces,
  DocumentSpace,
  Profile,
  Dashboard,
  AnalyticsOverview,
  DocumentSpaceAnalytics,
  DocumentAnalytics,
  UsageAnalytics,
  MagicPagesStudio,
  DocumentEditor,
  DocumentProperties,
  StackedProjector,
  WorkspaceDetail,
  CustomFlows,
  CustomFlow,
  ViewCustomFlowPageByPage,
  CRMContacts,
  TemplateEditor,
  Connect,
  ProjectorCast,
  ScreenSession,
  DocumentHistory,
} from "@/views";
import AppAuth from "@/AppAuth.vue";
import AppPublic from "@/AppPublic.vue";
import { user, userData, getCurrentUser, notifyWhenUserReady } from "@/init";
import { watch } from "vue";
import { store } from "@/store";
import { Workspace } from "@/graphql/operations";
import PdfJsTest from "@/views/pdfjstest.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/playground/pdfjs",
    component: PdfJsTest,
  },
  {
    path: "/space/:path*",
    redirect: (to) => {
      // trigger a true page refresh, because target page is located outside the router
      const href = to.fullPath.replace("/space", "/2");
      window.location.href = href === "/2" ? "/2/" : href;

      // additionally return a route, to be complient with api.
      const path = to.path.replace("/space", "/2");
      return {
        path: path === "/2" ? "/2/" : path,
        query: to.query,
        hash: to.hash,
      };
    },
  },
  {
    path: "/",
    redirect: "/login",
    name: "AppPublic",
    component: AppPublic,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          hasNavbar: false,
        },
      },
      {
        path: "verification",
        name: "EmailVerification",
        component: EmailVerification,
        meta: {
          hasNavbar: false,
        },
      },
      {
        path: "connect",
        name: "Connect",
        component: Connect,
        meta: {
          hasNavbar: false,
          hasHeader: false,
          hideAppPromotion: true,
        },
      },
      {
        path: "presentation/:presentationId",
        name: "ProjectorCast",
        component: ProjectorCast,
        meta: {
          hasNavbar: false,
          hasHeader: false,
          hideAppPromotion: true,
        },
      },
      {
        path: "screensession/:id?",
        name: "ScreenSession",
        component: ScreenSession,
        meta: {
          hasNavbar: false,
          hasHeader: false,
          hideAppPromotion: true,
        },
      },
    ],
  },
  {
    /* auth required */
    path: "/u",
    name: "AppAuth",
    component: AppAuth,
    meta: {
      requiresAuth: true,
      requiresWorkspace: true,
    },
    children: [
      {
        path: "workspaces",
        name: "WorkspaceList",
        component: WorkspaceList,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
        },
      },
      {
        path: ":workspaceId/detail",
        name: "WorkspaceDetail",
        component: WorkspaceDetail,
        meta: {
          hasNavbar: true,
          requiresWorkspace: true,
          displayLauncher: true,
          routeUpdateParam: "workspaceId",
        },
      },
      {
        path: ":workspaceId",
        name: "Workspace",
        component: WorkspaceOverview,
        meta: {
          hasNavbar: true,
          requiresWorkspace: true,
          displayLauncher: true,
          displayUploader: true,
          routeUpdateParam: "workspaceId",
          saveScrollPosition: true,
        },
      },
      {
        path: ":workspaceId/documents/:documentId",
        name: "DocumentEditor",
        component: DocumentEditor,
        meta: {
          hasNavbar: true,
          requiresWorkspace: true,
          displayLauncher: false,
          routeUpdateParam: "workspaceId",
        },
        children: [
          {
            path: "",
            name: "DocumentProperties",
            component: DocumentProperties,
          },
          {
            path: "magic_pages",
            name: "MagicPagesStudio",
            component: MagicPagesStudio,
          },
        ],
      },
      {
        path: ":workspaceId/stackedprojector",
        name: "StackedProjector",
        component: StackedProjector,
        meta: {
          hasNavbar: true,
          requiresWorkspace: true,
          requiresAuth: true,
          displayLauncher: true,
          displayShare: true,
          routeUpdateParam: "workspaceId",
        },
      },
      {
        path: "document_history",
        name: "DocumentHistory",
        component: DocumentHistory,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          requiresAuth: true,
          displayLauncher: false,
        },
      },
      {
        path: "settings",
        name: "SettingsOverview",
        component: SettingsOverview,
        meta: {
          hasNavbar: false,
          requiresWorkspace: false,
          displayLauncher: false,
        },
      },
      {
        path: "teams",
        name: "TeamList",
        component: Teams,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
        },
      },
      {
        path: "teams/:id",
        name: "TeamDetail",
        component: Team,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          routeUpdateParam: "id",
        },
        children: [
          {
            path: "",
            name: "TeamMembers",
            component: TeamMembers,
          },
          {
            path: "workspaces",
            name: "TeamWorkspaces",
            component: TeamWorkspaces,
          },
        ],
      },
      {
        path: "onboarding",
        name: "JoinableTeams",
        component: JoinableTeams,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
        },
      },
      {
        path: "shared_spaces",
        name: "DocumentSpaces",
        component: DocumentSpaces,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          saveScrollPosition: true,
        },
      },
      {
        path: "shared_spaces/:id",
        name: "DocumentSpace",
        component: DocumentSpace,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          displaySpaceAnalyticsLink: true,
          routeUpdateParam: "id",
        },
      },
      {
        path: "templates/:id",
        name: "TemplateEditor",
        component: TemplateEditor,
        meta: {
          requiresWorkspace: false,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
        },
      },
      {
        path: "analytics",
        name: "AnalyticsOverview",
        component: AnalyticsOverview,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasAnalytics",
        },
      },
      {
        path: "analytics/shared_spaces",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasAnalytics",
        },
      },
      {
        path: "analytics/shared_spaces/:id",
        name: "DocumentSpaceAnalytics",
        component: DocumentSpaceAnalytics,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasAnalytics",
          routeUpdateParam: "id",
        },
      },
      {
        path: "analytics/documents",
        name: "DocumentAnalytics",
        component: DocumentAnalytics,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasDocumentAnalytics",
        },
      },
      {
        path: "analytics/usage",
        name: "UsageAnalytics",
        component: UsageAnalytics,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasUsageAnalytics",
        },
      },
      {
        path: "custom_flows",
        name: "CustomFlows",
        component: CustomFlows,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasCustomFlows",
          saveScrollPosition: true,
        },
      },
      {
        path: "custom_flows/:id",
        name: "CustomFlow",
        component: CustomFlow,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: true,
          requiredFlag: "hasCustomFlows",
          routeUpdateParam: "id",
        },
      },
      {
        path: "custom_flows/:id/view",
        name: "ViewCustomFlowPageByPage",
        component: ViewCustomFlowPageByPage,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: true,
          requiredFlag: "hasCustomFlows",
          routeUpdateParam: "id",
        },
      },
      {
        path: "crm",
        name: "CRMContacts",
        component: CRMContacts,
        meta: {
          hasNavbar: true,
          requiresWorkspace: false,
          displayLauncher: false,
          requiredFlag: "hasSignConsent",
        },
      },
    ],
  },
  {
    path: "/management",
    name: "OrganisationManagement",
    component: OrganisationManagement,
    meta: {
      hasNavbar: false,
    },
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      hasNavbar: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

async function getRedirectRoute(currentRoute: RouteLocationNormalized) {
  const isAuthRoute = currentRoute.path.startsWith("/u");
  const isPublicRoute = currentRoute.path.startsWith("/") && !isAuthRoute;

  //immediatly check routes for which the user is not needed -- e.g. connect
  if (
    isPublicRoute &&
    currentRoute.name !== "Login" &&
    currentRoute.name !== "EmailVerification"
  ) {
    return null;
  }

  // fetch user
  let currentUser = user.value;
  if (!currentUser) {
    currentUser = await getCurrentUser();
  }

  // no user
  if (!currentUser) {
    if (isPublicRoute) {
      return null;
    } else {
      //redirect to login
      return "/login";
    }
  }

  if (!currentUser.emailVerified) {
    return "/verification";
  }

  let requiresWorkspace = currentRoute.meta.requiresWorkspace;
  const requiredFlag: string | undefined = currentRoute.meta.requiredFlag
    ? (currentRoute.meta.requiredFlag as string)
    : undefined;

  // logged in and verified users should not see Login or Verification page
  if (
    currentRoute.name === "Login" ||
    (currentRoute.name === "EmailVerification" && currentUser.emailVerified)
  ) {
    requiresWorkspace = true;
  }

  await notifyWhenUserReady();

  if (!requiredFlag && !requiresWorkspace) {
    return null;
  }

  store.commit("setCurrentWorkspace", { workspace: undefined });

  if (
    requiredFlag &&
    !store.getters.user[requiredFlag].apply(store.getters.user)
  ) {
    return "/404";
  }

  if (!requiresWorkspace) {
    return null;
  }

  if (currentRoute.params.workspaceId !== undefined) {
    const workspaceParam = currentRoute.params.workspaceId as string;

    if (
      store.getters.currentWorkspace === undefined ||
      store.getters.currentWorkspace.id !== workspaceParam
    ) {
      const workspaceId = workspaceParam;
      const currentWorkspace = store.getters.workspaces.find(
        (w: Workspace) => w.id == workspaceId
      );
      if (currentWorkspace) {
        store.commit("setCurrentWorkspace", { workspace: currentWorkspace });
      } else {
        store.commit("setCurrentWorkspace", { workspace: undefined });
      }
    }

    return null;
  }

  if (!userData.value) {
    return null;
  }

  ///* find a default workspace */
  if (store.getters.rootWorkspaces) {
    const defaultWorkspace = store.getters.rootWorkspaces[0];
    if (defaultWorkspace && defaultWorkspace.id) {
      store.commit("setCurrentWorkspace", { workspace: defaultWorkspace });
      return `/u/${defaultWorkspace.id}`;
    }
  }

  return "/u/workspaces";
}

async function route(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Function
) {
  const redirectRoute = await getRedirectRoute(to);
  if (redirectRoute !== null) {
    next(redirectRoute);
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  route(to, from, next);
});

watch(userData, async () => {
  const redirectRoute = await getRedirectRoute(router.currentRoute.value);
  if (redirectRoute !== null) {
    router.replace(redirectRoute);
  }
});

export default router;
