<script lang="ts" setup>
import { computed, ref, watch, onBeforeUnmount } from "vue";
import { useGetDocumentPagesByDocumentIdQuery } from "@/graphql/operations";
import { LoadingSpinner, Thumbnail } from "@/components";

const props = defineProps({
  documentId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["change"]);

const { data, fetching, executeQuery } = useGetDocumentPagesByDocumentIdQuery({
  variables: {
    documentId: props.documentId,
  },
  requestPolicy: "cache-and-network",
});

const currentRevisionNumber = computed(
  () => data.value?.document?.currentRevisionNumber
);

const displayBanner = ref(false);
watch(currentRevisionNumber, (newRevision, oldRevision) => {
  if (oldRevision && newRevision !== oldRevision) {
    displayBanner.value = true;
    setTimeout(() => {
      displayBanner.value = false;
    }, 5000);
  }
});

const pages = computed(
  () =>
    data.value?.document?.documentRevisionByIdAndCurrentRevisionNumber
      ?.documentPagesByDocumentIdAndRevisionNumber?.nodes || []
);
const selectedPages = ref<Array<string>>([]);

const selectPage = (pageId: string) => {
  if (selectedPages.value.includes(pageId)) {
    selectedPages.value = selectedPages.value.filter((page) => page !== pageId);
    emits("change", selectedPages.value);
  } else {
    selectedPages.value.push(pageId);
    emits("change", selectedPages.value);
  }
};

let intervalId: number | undefined = window.setInterval(() => {
  executeQuery();
}, 2000);

onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = undefined;
  }
});
</script>

<template>
  <div class="message info" v-if="displayBanner">
    {{ $t("documentPagePicker.newDocumentRevision.info") }}
  </div>

  <div v-if="fetching">
    <loading-spinner :color="'black'" />
  </div>

  <div v-if="!fetching" class="content">
    <div v-for="page, idx in pages" :key="page!.id">
      <thumbnail
        :pageId="page!.id"
        :class="{thumbnail: true, selected: selectedPages.includes(page!.id)}"
        @click="selectPage(page!.id)"
        :data-test="`page-${idx}`"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$pageWidth: 270px;
$selectionColor: #35b6ba;

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;

  .thumbnail {
    &:deep img {
      max-width: $pageWidth;
      max-height: $pageWidth;
      object-fit: contain;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
      }
    }

    &.selected:deep img {
      object-fit: contain;
      border: 1px solid $selectionColor;
      box-shadow: 0 0 0 3px $selectionColor;
      border-radius: 0.35em;
    }
  }
}
</style>
