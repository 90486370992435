import { captureException } from "@sentry/vue";

export class HTTP {

  static async post(
      url: string,
      headers: Map<string, string>,
      body: string): Promise<string> {
    const req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader("Content-type", "application/json");

    headers.forEach((value: string, key: string) => {
      req.setRequestHeader(key, value);
    });

    return new Promise((resolve, reject) => {
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 200 || req.status === 201) {
            resolve(req.response);
          } else {
            reject(req.responseText);
            captureException(`request returned non 200 error code: ${url} -- ${req.responseText} -- ${req.status}`)
          }
        }
      }

      req.send(body);
    });
  }

  static async get(url: string, headers: Map<string, string>) {
    const requestHeaders = new Headers();
    headers.forEach((value: string, key: string) => {
      requestHeaders.append(key, value);
    });

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeaders
    });

    return response;
  }

  static async postFormData(
      url: string,
      headers: Map<string, string>,
      formData: FormData,
      onProgress?: (p: number) => void): Promise<string> {
    const req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.timeout = 300000;

    headers.forEach((value: string, key: string) => {
      req.setRequestHeader(key, value);
    });

    req.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable && onProgress) {
        const progress = (e.loaded / e.total) * 100;
        onProgress(progress)
      }
    })

    return new Promise((resolve, reject) => {
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 200 || req.status === 201) {
            resolve(req.response);
          } else {
            reject({ status: req.status, message: req.responseText });
            captureException(`request returned non 200 error code: ${url} -- ${req.responseText} -- ${req.status}`)
          }
        }
      }

      req.send(formData);
    });

  }
}
