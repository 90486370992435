<script lang="ts" setup>
import { ref, Ref, PropType, computed, watch } from "vue";
import {
  useUpdateDocumentSpaceConfigLogoMutation,
} from "@/graphql/operations";
import { UploadService, UploadResponseData } from "@/util";
import { token } from "@/init";
import { useFlashMessage } from "@/hooks";
import { type Config } from "./Config";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["reloadData", "changeState"]);

const { displayFlashMessage } = useFlashMessage();

const fileEndings: { [key: string]: string } = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/svg+xml": "svg"
};

const fileInput: Ref<HTMLInputElement | null> = ref(null);
const imagePreview: Ref<HTMLImageElement | null> = ref(null);
const file: Ref<File | null> = ref(null);
const imageError = ref<string | undefined>();

const submitFile = () => {
  const files = fileInput?.value?.files;
  if (!files || files.length === 0 || !imagePreview.value) {
    return;
  }

  const currentFile = files[0];
  if (!fileEndings.hasOwnProperty(currentFile.type)) {
    imageError.value = "wrongType";
    return;
  }

  const maxFileSize = 1 * 1024 * 1024; // 1MB
  if (currentFile.size > maxFileSize) {
    imageError.value = "tooLarge";
    return;
  }

  imageError.value = undefined;
  file.value = currentFile;

  const reader = new FileReader();
  reader.onload = (e) => {
    imagePreview.value!.src = e!.target!.result as string;
  };

  reader.readAsDataURL(file.value);
};

const onProgress = (progress: number) => {
  console.log(progress);
};

const hasChanges = computed(() => file.value);
watch(hasChanges, () => { emits("changeState", hasChanges.value) })

const { executeMutation } = useUpdateDocumentSpaceConfigLogoMutation();

const submit = async () => {
  if (!file.value) {
    return;
  }

  try {
    const formData = new FormData();
    formData.append("file", file.value);

    const fileData = await UploadService.upload(formData, token.value!, onProgress);
    const logoImageUrl = fileData.downloadUrl;

    const res = await executeMutation({
      id: props.config.id,
      logoUrl: logoImageUrl,
    });

    if (res.error) {
      throw new Error(res.error.message);
    }

    displayFlashMessage("Success", "success");
    emits("reloadData");
  } catch (e) {
    displayFlashMessage("Error", "error");
    console.error(e);
  }
};
</script>

<template>
  <div class="toolbar_body">
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.logo.image.label") }}</label>
        <div class="controls">
          <label class="link" for="file-selector">
            {{ $t("templateEditor.toolbar.logo.image.replace") }}
          </label>
          <input
            @change="submitFile"
            id="file-selector"
            ref="fileInput"
            type="file"
            style="display: none"
            multiple="false"
          />
        </div>
      </div>
      <div class="image">
        <img ref="imagePreview" :src="config.logoUrl || ''" />
      </div>

      <p v-if="imageError" class="help">
        {{ $t(`templateEditor.toolbar.fileSelect.error.${imageError}`)}}
      </p>
    </div>

  </div>
  <div class="toolbar_footer" @click="submit">
    <div class="button primary" :class="{ disabled: !hasChanges }">
      {{ $t("templateEditor.toolbar.save") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar {
  width: 300px;
  border-right: 1px solid #e0e0e0;
}

.toolbar_header {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 1em;
  background: #fafafa;
}

.toolbar_body {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  height: calc(100vh - 60px - 60px - 82px);
  box-sizing: border-box;
  overflow-y: scroll;
}

.toolbar_footer {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.header_label {
  font-weight: 500;
  flex: 1;
  padding-top: 0.5em;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}

.label_container {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}

.label_container label {
  flex: 1;
  font-weight: 500;
}

.label_container .link {
  font-weight: normal;
}

.row {
  margin-bottom: 1.5em;
}

.image {
  background: #e0e0e0;
  height: 70px;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

p.help {
  font-size: 0.9em;
  color: #f14668;
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  height: 0.8em;
}
</style>
