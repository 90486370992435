import { cacheExchange, createClient, fetchExchange } from '@urql/vue'
import { token } from "@/init";

export const client = createClient({
  url: "/graphql",
  fetchOptions: () => {
    const headers: HeadersInit = {};

    if (token.value) {
      headers['Authorization'] = `Bearer ${token.value}`
    }

    return {
      headers,
    }
  },
  exchanges: [cacheExchange, fetchExchange],
})

export default client;
