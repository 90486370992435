<template>
  <div class="card">
    <div
      @click="redirect"
      :class="{
        clickable: true,
        disabled:
          team.myMembership !== null &&
          team.myMembership.currentState as string !== 'ACTIVE',
      }"
    >
      <div class="card_content">
        <div class="card_header">
          <UserAvatar :email="team.name" :name="team.name" />

          <div class="card_title">
            <span>{{ team.name }}</span>
            <div class="card_subtitle" v-if="team.myMembership !== null">
              {{
                $t("joinableTeams.memberCount", {
                  count: team.memberCount,
                })
              }}
            </div>
          </div>
        </div>

        <div class="bottom">
          <span v-if="team.myMembership !== null && team.myMembership.currentState as string === 'ACTIVE'">
            {{
              $t(
                `joinableTeams.membershipState.active.${team.myMembership.mayAdministrate}`
              )
            }}
          </span>
          <span v-else-if="team.myMembership !== null">
            {{
              $t(
                `joinableTeams.membershipState.${team.myMembership.currentState}`
              )
            }}
          </span>

          <div class="buttons">
            <button
              v-if="team.myMembership === null"
              class="button primary"
              @click.stop="joinTeam({ teamId: team.id })"
            >
              {{ $t("joinableTeams.join.buttonText") }}
            </button>

            <button
              v-else-if="team.myMembership.currentState as string === 'INVITED'"
              class="button secondary"
              @click.stop="acceptInvitation({ id: team!.myMembership!.id })"
            >
              Annehmen
            </button>

            <span v-else-if="team.myMembership.currentState as string === 'ACTIVE'">
              <router-link
                class="icon_link"
                :to="{ name: 'TeamMembers', params: { id: team.id } }"
              >
                <span class="material-symbols-outlined circle"> chevron_right </span>
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserAvatar } from "@/components";
import { Team, MembershipState } from "@/models";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { UserAvatar },
  props: {
    team: {
      required: true,
      type: Team,
    },
    displayMetadata: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(_props, context) {
    const router = useRouter();

    function joinTeam(data: any) {
      context.emit("join", data);
    }

    function acceptInvitation(data: any) {
      context.emit("acceptInvitation", data);
    }

    function redirect() {
      if (
        _props.team.myMembership !== null &&
        _props.team.myMembership!.currentState as string === "ACTIVE"
      ) {
        router.push({

          name: "TeamMembers",
          params: { id: String(_props.team.id) },
        });
      }
    }

    return {
      joinTeam,
      acceptInvitation,
      redirect
    };
  },
});
</script>

<style lang="scss" scoped>
$content_height: 150px;
$border-light: #ebebeb;
$border-strong: #CECECE;

.card {
  border-radius: .3em;
  border: 1px solid $border-strong;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  font-size: 0.9rem;
}
.card a {
  color: inherit;
}

.card_content {
  padding: 1.2rem 1.2rem 0.6rem 1.2rem;
  height: $content_height;
  box-sizing: border-box;
  position: relative;
}

.card_info {
  float: right;
  color: rgba(25, 23, 17, 0.6);
}

.card_header {
  line-height: 1.125;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
}

.card_title {
  font-weight: 500;
  font-size: 1.1em;
  color: #363636;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}

.card_subtitle {
  font-size: 0.8em;
  font-weight: 400;
}

.avatar {
  height: 35px;
  width: 35px;
  line-height: 35px;
  flex: 0 0 auto;
}

.bottom {
  border-top: 1px solid #d0d4d9;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.4rem 1.2rem;
  line-height: 35px;
}

.buttons {
  float: right;
}

.buttons .button {
  font-size: 1em;
}

.icon_link {
  display: inline-block;
  padding-left: 2px;
  color: inherit;
}

.icon_link .material-symbols-outlined {
  vertical-align: middle;
}

.clickable:not(.disabled) {
  cursor: pointer;
}
</style>
