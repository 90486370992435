<template>
  <form @submit.prevent="submit">
    <input
      @change="submit"
      id="file-selector"
      ref="fileInput"
      name="my-funny-file"
      type="file"
      style="display: none"
      :accept="Array.from(allowedMimeTypes).join(',')"
      :multiple="!singleFile"
    />
    <label class="button secondary" for="file-selector"> Add file </label>
  </form>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";
const ALLOWED_MIME_TYPES = new Set([
  "application/pdf",
  "video/mp4",
  ".docx",
  ".pptx",
]);

const props = defineProps({
  mimeType: {
    type: String,
    required: false,
    default: undefined,
  },
  singleFile: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["submit"]);

const fileInput: Ref<HTMLInputElement | null> = ref(null);

const allowedMimeTypes = props.mimeType
  ? new Set([props.mimeType])
  : ALLOWED_MIME_TYPES;

async function submit() {
  if (!fileInput.value) {
    console.error("not mounted correctly");
    return;
  }

  emit("submit", fileInput.value.files);
}
</script>
