<template>
  <div>
    <table class="data_table">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="membership in memberships" :key="membership!.id">
          <td>
            <UserAvatar
              :email="membership!.user!.email"
              :name="membership!.user!.displayName!"
            />

            <div class="user_name">
              <div v-if="membership!.user!.displayName">
                {{ membership!.user!.displayName }}
              </div>
              <div class="small">
                {{ membership!.user!.email }}
              </div>
            </div>
          </td>
          <td>
            <button class="button" @click="acceptRequest(membership!.id)">
              Annehmen
            </button>
            <button class="button" @click="declineRequest(membership!.id)">
              Ablehnen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { Membership } from "@/graphql/operations";
import { UserAvatar } from "@/components";

const props = defineProps({
  memberships: {
    type: Array as PropType<Array<Membership>>,
    required: true,
  },
});

const emit = defineEmits(["accept", "decline"]);

function acceptRequest(membershipId: string) {
  emit("accept", { id: membershipId });
}

function declineRequest(membershipId: string) {
  emit("decline", { id: membershipId });
}
</script>

<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  margin-right: 5px;
}

table tbody tr td:last-child {
  text-align: right;
}

.button {
  font-size: 1em;
  margin-left: 5px;
}

.user_name {
  display: inline-block;
  vertical-align: middle;
}

.user_name div {
  line-height: 1.3;
}

.user_name .small {
  font-size: 0.8em;
}
</style>
