<script lang="ts" setup>
import { Formatter, DateUtil } from "@/util";
import { Tooltip, LoadingSpinner } from "@/components";
import {
  useDocumentSpaceStatisticsQuery,
  Interval,
  Maybe,
} from "@/graphql/operations";
import { computed, onBeforeUnmount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Visits from "./Visits.vue";

const router = useRouter();
const store = useStore();

const { fetching, data, executeQuery } = useDocumentSpaceStatisticsQuery({
  variables: reactive({
    includePreview: false,
  }),
  requestPolicy: "cache-and-network",
});

const visits = computed(() => data.value?.maLatestSpaceVisits?.nodes);

const totalCount = computed(() => data.value?.maLatestSpaceVisits?.totalCount);
const numSpacesWithoutTracking = computed(
  () => data.value?.documentSpaces?.totalCount
);

const formatDuration = (duration: Interval) => {
  return Formatter.formatDuration({
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
  });
};

const selectedDocumentSpaceId = ref<string | undefined>(undefined);
const select = (documentSpaceId: Maybe<string> | undefined) => {
  if (!documentSpaceId) {
    return;
  }

  selectedDocumentSpaceId.value = documentSpaceId;
};

const unselect = () => {
  selectedDocumentSpaceId.value = undefined;
};

const canDisableSpaceTracking = computed(() =>
  store.getters.user.canDisableSpaceTracking()
);
</script>

<template>
  <div class="pane page_content">
    <div
      :class="{
        columns: selectedDocumentSpaceId !== undefined,
        title_container: true,
      }"
    >
      <div :class="{ col: selectedDocumentSpaceId !== undefined }">
        <div class="left">
          <h3>{{ $t("dashboard.title") }}</h3>

          <Tooltip
            v-if="
              canDisableSpaceTracking &&
              numSpacesWithoutTracking &&
              numSpacesWithoutTracking > 0
            "
          >
            <template v-slot>
              <span class="material-symbols-outlined"> help </span>
            </template>
            <template #tooltip>
              {{
                $t("dashboard.labelSpacesNotTracked", {
                  count: numSpacesWithoutTracking,
                })
              }}
            </template>
          </Tooltip>
        </div>
      </div>

      <div :class="{ col: selectedDocumentSpaceId !== undefined }">
        <template v-if="selectedDocumentSpaceId != undefined">
          <span
            class="material-symbols-outlined clickable icon right"
            @click="unselect"
          >
            close
          </span>
        </template>
      </div>
    </div>

    <section>
      <div v-if="fetching" class="centered_container">
        <loading-spinner :color="'#444'" />
      </div>

      <div
        v-else-if="!fetching && visits && visits.length === 0"
        class="centered_container"
      >
        <span class="material-symbols-outlined big_text"> analytics </span>
        <h3>{{ $t("dashboard.noData.title") }}</h3>
        <span>
          {{ $t("dashboard.noData.textNoPreview") }}
        </span>
      </div>

      <template v-else>
        <div
          :class="{
            columns: selectedDocumentSpaceId !== undefined,
            fullheight: true,
          }"
        >
          <div :class="{ col: selectedDocumentSpaceId !== undefined }">
            <section class="data">
              <table class="data_table clickable">
                <tbody>
                  <tr
                    v-for="(visit, index) in visits"
                    :key="index"
                    @click="select(visit!.documentSpaceId)"
                    :class="{
                      selected:
                        visit!.documentSpaceId === selectedDocumentSpaceId,
                    }"
                  >
                    <td>
                      {{ visit!.name }}
                    </td>
                    <td>
                      {{
                        Formatter.translateDate(visit!.latestVisitDay as string)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>

          <section class="col detail" v-if="selectedDocumentSpaceId">
            <visits :documentSpaceId="selectedDocumentSpaceId"></visits>
          </section>
        </div>
      </template>
    </section>
  </div>
</template>

<style scoped lang="scss">
* {
  font-size: 0.9rem;
}

.columns {
  display: flex;
  align-items: start;
  column-gap: 15px;
}

.columns.fullheight {
  height: calc(100vh - 155px);
}

.columns .col {
  width: 50%;
}

.columns.fullheight .col {
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.columns .col:first-child {
  border-right: 1px solid #ebebeb;
}

h3 {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 38px;
  margin-top: 0;
}

.section_title {
  padding: 1.5em 0 0.5em 14px;
  font-weight: 500;
}

table a:not(.link) {
  color: #444;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

table .link {
  font-weight: 400;
  color: #444;
}

.material-symbols-outlined {
  font-size: 1.4em;
}

table a:hover {
  text-decoration: underline;
}

.centered_container {
  text-align: center;
  margin-top: 5%;
}

.header {
  margin: 0 1em;
}

table.data_table {
  box-shadow: none;
}

table tr th,
table tr td {
  width: 155px;
}

table.data_table.clickable tr:hover {
  background-color: #f2f5fa;
}

table.data_table.clickable tr.selected,
table.data_table.clickable tr.selected:hover {
  background-color: #e1ebf5;
}

table tr th:first-child,
table tr td:first-child {
  width: calc(100% - 155px);
  padding-left: 0;
}

table tr th:last-child,
table tr th:first-child,
table tr td:first-child {
  width: calc(100% - 155px);
}
table tr td:last-child {
  text-align: right;
}

td .material-symbols-outlined {
  margin-left: 5px;
  vertical-align: middle;
}

td.center {
  text-align: center;
}

.icon {
  margin-right: 0.3em;
}

.tag {
  background-color: #eaeaea;
  padding: 0.5em 0.8em;
  border-radius: 2em;
  margin-left: 0.3em;
  display: inline-block;
  font-size: 0.9em;
  line-height: initial;
}

.title_container {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.title_container h3 {
  margin-bottom: 0;
}

.title_container .col {
  display: flex;
}

.title_container .left {
  flex: 1;
  display: flex;
}

.title_container .material-symbols-outlined {
  font-size: 1rem;
}

.control {
  font-size: 0.9rem;
}

.icon.clickable {
  cursor: pointer;
}

.icon.right {
  margin-left: auto;
}

.big_text {
  font-size: 5rem;
}
</style>
