<script lang="ts" setup>
import { Search, UserAvatar, Dropdown } from "@/components";
import {
  DocumentSpace,
  useSearchUsersWithinTenantQuery,
  User,
} from "@/graphql/operations";
import { PropType, ref, computed, watch, reactive, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const inputElem = ref<HTMLElement>();

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["stateChange", "change"]);

const userSearch = ref<HTMLElement>();
const filter = ref<string>("");

const { data, executeQuery } = useSearchUsersWithinTenantQuery({
  variables: reactive({
    search: filter,
    tenantId: store.getters.user.tenantId,
    spaceId: props.documentSpace.id,
  }),
  requestPolicy: "network-only",
});

const searchResult = computed(() => data.value?.usersByFlags?.nodes);

const setFilter = (search: string) => {
  filter.value = search;
};

const clear = () => {
  filter.value = "";
};

function handleKeyEvent(ev: KeyboardEvent) {
  if (ev.code === "ArrowDown") {
    console.log("arrow down"); //TODO
  } else if (ev.code === "ArrowUp") {
    console.log("arrow up"); //TODO
  } else if (ev.code === "Enter") {
    ev.preventDefault();
    console.log("enter"); //TODO
  }
}

const handleFocus = () => {
  emits("stateChange", true);
};

const handleBlur = () => {
  //window.setTimeout(() => {
  //  searchActive.value = false;
  //}, 200)
};

const selectedUser = ref<User | null>(null);
const setSelectedUser = (user: User) => {
  selectedUser.value = user;
};

watch(props, () => {
  if (!props.active) {
    filter.value = "";
    selectedUser.value = null;
  }
});

watch(selectedUser, () => {
  emits("change", selectedUser.value);
});

onMounted(() => {
  if (props.active && inputElem.value) {
    inputElem.value.focus();
  }
});
</script>

<template>
  <div class="search_container">
    <template v-if="!selectedUser">
      <div class="search_input">
        <form v-if="!selectedUser" class="search" @submit.prevent>
          <input
            class="input"
            type="text"
            ref="inputElem"
            :placeholder="$t('documentSpace.access.userSearch.placeholder')"
            v-model="filter"
            @keydown="handleKeyEvent"
            @focus="handleFocus"
            @blur="handleBlur"
          />
        </form>
      </div>

      <div class="search_results" v-if="active && !selectedUser">
        <template v-if="searchResult">
          <div
            v-for="user in searchResult"
            :key="user!.id"
            :class="{
              row: true,
              clickable: user!.documentSpaceAccesses?.nodes?.length === 0,
              disabled: user!.documentSpaceAccesses?.nodes?.length != 0
            }"
            @click="setSelectedUser(user as User)"
          >
            <div class="left">
              <user-avatar
                :appearance="'subtle'"
                :name="user!.displayName!"
                :email="user!.email!"
              />
            </div>
            <div>
              <div>{{ user!.displayName }}</div>
              <div>{{ user!.email }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          {{ $t("documentSpace.access.userSearch.empty") }}
        </template>
      </div>
    </template>

    <template v-else>
      <div class="search_input">
        <div class="outer input">
          <div class="selected">
            <user-avatar
              class="small"
              :appearance="'subtle'"
              :name="selectedUser.displayName!"
              :email="selectedUser.email!"
            />
            {{ selectedUser.displayName }}
            <span
              class="material-symbols-outlined"
              @click="selectedUser = null"
            >
              close
            </span>
          </div>
        </div>

        <slot name="settings"></slot>
      </div>

      <p class="info">
        {{ $t("documentSpace.access.invite.info") }}
      </p>
    </template>
  </div>
</template>

<style scoped lang="scss">
.search_container {
  position: relative;
}

.search_container .search_results {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  background-color: #f5f9fb;
  z-index: 100;
}

.search_results .row {
  padding: 0.6em 0.8em;
  display: flex;
  align-items: center;
}

.row .left {
  margin-right: 1em;
}

.search_results .row.clickable:hover {
  cursor: pointer;
  background-color: #e3eef3;
}

.search_results .row.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.search {
  display: block;
  overflow: visible;
  border: 1px solid #bbb;
  border-radius: 3px;
  background-color: #fff;
  height: 35px;
  width: 100%;
}

.input {
  margin-left: 3px;
  width: calc(100% - 3px);
  min-width: 300px;
  border-radius: 0px;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}

.search .input {
  border: none;
  width: calc(100% - 3px);
}

.input:active,
.input:focus {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
}

.outer.input {
  padding: 18px 10px;
  height: 36px;
  border-color: #b5b5b5;
  border-radius: 3px;
  display: flex;
}

.search_input {
  display: flex;
}

.search_input button {
  margin-left: 0.5em;
}

.selected {
  font-size: 1em;
  display: flex;
  align-items: center;
  background: rgba(206, 205, 202, 0.5);
  padding: 2px 6px;
  border-radius: 4px;
}

.selected .avatar {
  font-size: 0.7em;
  margin-right: 0.5em;
}

.selected .material-symbols-outlined {
  font-size: 0.9em;
  padding: 5px 5px 3px 8px;
  color: rgb(55, 53, 47);
  cursor: pointer;
}
</style>
