<template>
  <Modal :active="true" :scrolling="false" @close="close" class="large">
    <template v-slot;header>
      <div class="icon left" v-if="state.step !== 'select_users'">
        <span @click="goBack" class="material-symbols-outlined">chevron_left</span>
      </div>

      {{ $t("tenantUserSearch.header") }}
    </template>

    <template v-slot:body>
      <div v-if="state.step === 'select_users'" class="content">
        <div class="content_main">
          <Search
            :placeholder="$t('tenantUserSearch.search.placeholder')"
            :resetValue="state.filterReset"
            @change="setFilter"
            @submit="setFilter"
            @arrowDown="handleArrowDown"
            @arrowUp="handleArrowUp"
            @enter="handleEnter"
          />

          <div v-if="userCandidates.length === 0">
            <div v-if="state.selectedUsers.includes(search)">
              {{ $t("tenantUserSearch.userAlreadySelected.info") }}
            </div>

            <EmailResultField v-else :search="search" @submit="addUserByMail" />
          </div>

          <div v-else class="items">
            <div
              v-for="candidate, idx of userCandidates"
              :key="candidate!.id"
              :class="{
                item: true,
                disabled: candidate!.memberships!.nodes!.length > 0,
                highlighted: idx === highlightedIndex
              }"
            >
              <div class="left">
                <span class="checkbox_container">
                  <input
                    v-if="candidate!.memberships!.nodes!.length === 0"
                    type="checkbox"
                    :id="candidate!.email"
                    :value="candidate!.email"
                    v-model="state.selectedUsers"
                  />
                </span>
              </div>

              <div class="left">
                <UserAvatar
                  :email="candidate!.email!"
                  :name="candidate!.displayName!"
                />
              </div>

              <div class="left">
                <div>{{ candidate!.displayName }}</div>
                <div class="small_text">{{ candidate!.email }}</div>
              </div>

              <div v-if="candidate!.memberships!.nodes!.length > 0" class="info empty">
                {{ $t("tenantUserSearch.userAlreadyMember.info") }}
              </div>
            </div>
          </div>
        </div>

        <div class="content_right">
          <h4>{{ $t("tenantUserSearch.userList.header") }}</h4>
          <div class="items" v-if="selectedUsersExpanded.length > 0">
            <div
              v-for="user of selectedUsersExpanded"
              :key="user!.email"
              class="item deletable"
            >
              <div class="left">
                <div>{{ user!.displayName }}</div>
                <div class="small_text">{{ user!.email }}</div>
              </div>

              <span class="material-symbols-outlined" @click="removeUser(user!.email)">
                close
              </span>
            </div>
          </div>
          <div v-else class="info empty">
            {{ $t("tenantUserSearch.emptyList.info") }}
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <span class="checkbox_container">
            <input type="checkbox" v-model="state.notifyUsers" />
          </span>
          <label>{{ $t("tenantUserSearch.notify.label") }}</label>
        </div>

        <div class="row">
          <textarea
            :placeholder="$t('tenantUserSearch.customText.placeholder')"
            class="textarea"
            v-model="state.emailText"
            v-if="state.notifyUsers"
          />
          <p class="info">{{ $t("tenantUserSearch.invitationInfo") }}</p>
        </div>

        <div class="row">
          <div class="items">
            <div
              v-for="user of selectedUsersExpanded"
              :key="user.email"
              class="item"
            >
              <div class="left">
                <UserAvatar :email="user.email" :name="user.displayName" />
              </div>

              <div class="left">
                <div>{{ user.displayName }}</div>
                <div class="small_text">{{ user.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <div v-if="state.step === 'select_users'">
          <input
            type="submit"
            :value="$t('tenantUserSearch.nextStep')"
            :class="{
              button: true,
              primary: true,
              disabled: state.selectedUsers.length === 0,
            }"
            @click="state.step = 'settings'"
          />
        </div>

        <div v-else>
          <input
            type="submit"
            :value="$t('tenantUserSearch.submit')"
            class="button primary"
            @click="submit"
          />
          <input
            type="submit"
            :value="$t('tenantUserSearch.previousStep')"
            class="button secondary"
            @click="state.step = 'select_users'"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useGetTeamCandidatesQuery } from "@/graphql/operations";
import { Modal, Search, EmailResultField, UserAvatar } from "@/components";

interface ComponentState {
  selectedUsers: string[];
  filterReset: boolean;
  notifyUsers: boolean;
  emailText: string;
  step: string;
}

const props = defineProps({
  tenantId: {
    type: String,
    required: true
  },
  teamId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["submit", "close"]);

const state: ComponentState = reactive({
  selectedUsers: [],
  filterReset: false,
  notifyUsers: true,
  emailText: "",
  step: "select_users",
});

const search = ref("");
const highlightedIndex = ref<number>(-1);

const {
  data: allCandidatesData,
  executeQuery: fetchAllCandidates,
  fetching: loading,
} = useGetTeamCandidatesQuery({
  variables: reactive({
    search: "",
    teamId: props.teamId,
    tenantId: props.tenantId,
  }),
});

fetchAllCandidates();

const allCandidates = computed(() => allCandidatesData?.value?.tenant);

const { data: candidatesData, executeQuery: fetchCandidates } =
  useGetTeamCandidatesQuery({
    variables: reactive({
      search: search,
      teamId: props.teamId,
      tenantId: props.tenantId,
    }),
  });

fetchCandidates();

const candidates = computed(() => candidatesData?.value?.tenant);
const userCandidates = computed(() => candidates.value?.users.nodes || []);

function submit() {
  const data = {
    emails: state.selectedUsers,
    teamId: props.teamId,
    emailText: state.emailText,
    notifyUsers: state.notifyUsers,
  };

  emit("submit", data);
}

function close() {
  emit("close");
}

function setFilter(filter: string) {
  if (typeof filter !== "string") {
    return;
  }
  state.filterReset = false;
  search.value = filter.trim();
  highlightedIndex.value = -1;
}

function getName(email: string) {
  const user = allCandidates.value?.users.nodes.find(
    (c) => c!.email === email
  );
  return user === undefined
    ? email
    : user!.displayName
    ? user!.displayName
    : user!.email;
}

function removeUser(email: string) {
  const idx = state.selectedUsers.indexOf(email);
  state.selectedUsers.splice(idx, 1);
}

function addUserByMail() {
  state.selectedUsers.push(search.value);
  state.filterReset = true;
}

const goBack = () => (state.step = "select_users");

const selectedUsersExpanded = computed(() => {
  return state.selectedUsers.map((email) => ({
    email: email,
    displayName: getName(email),
  }));
});

function handleArrowDown() {
  highlightedIndex.value = userCandidates.value ?
    (highlightedIndex.value + 1) % userCandidates.value.length : -1;
}

function handleArrowUp() {
  highlightedIndex.value = userCandidates.value ?
    ((highlightedIndex.value - 1) + userCandidates.value.length) % userCandidates.value.length : -1;
}

function handleEnter() {
  if (userCandidates.value && highlightedIndex.value > -1) {
    const candidate = userCandidates.value[highlightedIndex.value];
    if (candidate?.memberships.nodes.length === 0 &&
        !state.selectedUsers.includes(candidate.email)) {
      state.selectedUsers.push(candidate.email);
    }
  }
}
</script>

<style lang="scss" scoped>
.left {
  float: left;
  height: 38px;
}

.item {
  padding: 5px 0;
  border-bottom: 1px solid #f4f4f4;
  height: 38px;
  overflow: auto;
}

.item.disabled {
  opacity: .5;
}

.item .checkbox_container {
  display: inline-block;
  padding: .5em;
  width: 1.4em;
  margin-right: .5em;
}

input[type="checkbox"] {
  cursor: pointer;
}

.item .info {
  float: right;
  margin-right: 2em;
  line-height: 38px;
}

.info.empty {
  font-size: .9rem;
  font-style: italic;
}

.items {
  margin-top: 10px;
}

.item.deletable .material-symbols-outlined {
  font-size: .9em;
  vertical-align: middle;
  margin-left: 3px;
  cursor: pointer;
  float: right;
  line-height: 38px;
}

.item.highlighted,
.item:hover {
  background-color: #fafafa;
}

.item.deletable .left {
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  margin: 2px 8px 0 0;
}

.small_text {
  font-size: 0.8em;
}

input[type="checkbox"] + label {
  margin-left: 5px;
}

.textarea {
  min-height: 8em;
}

.row {
  margin-bottom: 10px;
  font-size: 0.9em;
}

.content {
  display: flex;
  overflow: hidden;
}

.content_main {
  flex: 1;
  box-sizing: border-box;
  padding-right: 1em;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  overflow-y: auto;
}

.content_right {
  width: 28%;
  box-sizing: border-box;
  padding-left: 1em;
  overflow-y: auto;
}

h4 {
  margin-top: .5em;
  font-size: 1em;
  font-weight: 500;
}
</style>
