<script lang="ts" setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Tooltip, EditableInput } from "@/components";
import {
  SpaceSectionMoveType,
  useRenameSpaceSectionMutation,
  useDeleteSpaceSectionMutation,
  useMoveSpaceSectionMutation,
  Maybe
} from "@/graphql/operations";
import CreateSectionModal from "./CreateSectionModal.vue";
import DeleteSectionModal from "./DeleteSectionModal.vue";

const { t } = useI18n();

const props = defineProps({
  title: {
    type: [String, null],
  },
  id: {
    type: String,
    required: true,
  },
  spaceId: {
    type: String,
    required: true,
  },
  numSections: {
    type: Number,
    required: true,
  },
  sectionIdx: {
    type: Number,
    required: true,
  },
  numItems: {
    type: Number,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
  sectionNames: {
    type: Array<Maybe<string> | undefined>,
    required: true
  },
});

const displayTitle = computed(() =>
  props.title ? props.title : t("documentSpace.documents.title")
);

const emits = defineEmits(["success", "toggleVisibility"]);

const editMode = ref<boolean>(false);

const { executeMutation: executeRename } = useRenameSpaceSectionMutation();
const rename = async (value: string) => {
  const res = await executeRename({
    sectionId: props.id,
    title: value,
  });

  if (res.error) {
  } else {
    emits("success");
  }

  editMode.value = false;
};

const { executeMutation: executeDelete } = useDeleteSpaceSectionMutation();
const checkDelete = async () => {
  if (props.numItems === 0) {
    const res = await executeDelete({
      sectionId: props.id,
    });

    if (res.error) {
    } else {
      emits("success");
    }
  } else {
    displayDeleteModal.value = true;
  }
};

const { executeMutation: executeMove } = useMoveSpaceSectionMutation();
const moveUp = async () => {
  const res = await executeMove({
    sectionId: props.id,
    direction: SpaceSectionMoveType.Up,
  });

  if (res.error) {
  } else {
    emits("success");
  }
};

const moveDown = async () => {
  const res = await executeMove({
    sectionId: props.id,
    direction: SpaceSectionMoveType.Down,
  });

  if (res.error) {
  } else {
    emits("success");
  }
};

const displayCreateModal = ref(false);
const displayDeleteModal = ref(false);
</script>

<template>
  <div class="title_container">
    <EditableInput
      v-if="editMode"
      :value="displayTitle"
      @submit="rename"
      @cancel="editMode = false"
    />
    <h4 v-else>
      <span
        class="material-symbols-outlined"
        @click="emits('toggleVisibility')"
      >
        <template v-if="isVisible"> keyboard_arrow_down </template>
        <template v-else> keyboard_arrow_right </template>
      </span>
      {{ displayTitle }}
    </h4>

    <div class="controls right">
      <Tooltip>
        <template v-slot>
          <span class="material-symbols-outlined" @click="editMode = true">
            edit
          </span>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.sections.edit") }}
        </template>
      </Tooltip>
      <Tooltip v-if="numSections > 1">
        <template v-slot>
          <span class="material-symbols-outlined" @click="checkDelete">
            delete
          </span>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.sections.delete") }}
        </template>
      </Tooltip>
      <Tooltip v-if="numSections > 1">
        <template v-slot>
          <span
            class="material-symbols-outlined icon"
            :class="{ disabled: sectionIdx === 0 }"
            @click="moveUp"
          >
            arrow_upward
          </span>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.sections.moveUp") }}
        </template>
      </Tooltip>
      <Tooltip v-if="numSections > 1">
        <template v-slot>
          <span
            class="material-symbols-outlined icon"
            :class="{ disabled: sectionIdx === numSections - 1 }"
            @click="moveDown"
          >
            arrow_downward
          </span>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.sections.moveDown") }}
        </template>
      </Tooltip>
      <Tooltip v-if="numSections > 1">
        <template v-slot>
          <span
            class="material-symbols-outlined"
            @click="displayCreateModal = true"
          >
            playlist_add
          </span>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.sections.addBelowThis") }}
        </template>
      </Tooltip>
    </div>
  </div>
  <CreateSectionModal
    v-if="displayCreateModal"
    :afterSectionId="props.id"
    :sectionNames="sectionNames"
    @close="displayCreateModal = false"
    @success="emits('success')"
  />

  <DeleteSectionModal
    v-if="displayDeleteModal"
    :spaceId="spaceId"
    :sectionId="id"
    @close="displayDeleteModal = false"
    @success="emits('success')"
  />
</template>

<style lang="scss" scoped>
.title_container {
  display: flex;
  align-items: center;
  margin-bottom: .5em;
  height: 41px;
}

.title_container .right {
  margin-left: auto;
}

.controls .material-symbols-outlined {
  font-size: 1.3em;
  cursor: pointer;
  padding: 0.1em;
  margin: 0 0.1em;
}

input[type="text"] {
  width: 70%;
}

.editable_input {
  width: 60%;
  min-width: 250px;
}

.icon.disabled {
  cursor: initial;
  pointer-events: none;
  opacity: 0.3;
}

h4 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.1em;
  margin: 0;
}

h4 .material-symbols-outlined {
  margin-right: 0.3em;
}

h4 .material-symbols-outlined:hover {
  cursor: pointer;
}
</style>
