<script lang="ts" setup>
import { PropType, ref } from "vue";
import { Tooltip, DocumentUploadModal } from "@/components";
import { WorkspaceDocumentFragment } from "@/graphql/operations";
import { getStorageProxyUrl } from "@/util";

const props = defineProps({
  doc: {
    type: Object as PropType<WorkspaceDocumentFragment>,
    required: true
  }
});

const emit = defineEmits(["success"]);

const uploaderActive = ref<boolean>(false);

const displayUploader = () => {
  uploaderActive.value = true;
};

const closeUploader = () => {
  uploaderActive.value = false;
};
</script>

<template>
  <div class="file_update">
    <div class="container">
      <div class="thumbnail" :style="{'background-image': `url(${getStorageProxyUrl(doc.thumbnailDownloadUrl)})`}"></div>

      <div class="upload_icon">
        <Tooltip>
          <template v-slot>
            <span class="material-symbols-outlined icon" @click="displayUploader">
              upload_file
            </span>
          </template>
          <template #tooltip>{{ $t("pvDocEditor.fileUpdate.tooltip") }}</template>
        </Tooltip>
      </div>
    </div>
    <div class="img_subtitle">
      {{ doc.fileName }}
    </div>

    <DocumentUploadModal
      v-if="uploaderActive"
      :active="uploaderActive"
      :doc="doc"
      :singleFile="true"
      @success="emit('success')"
      @close="closeUploader"
    >
    </DocumentUploadModal>
  </div>
</template>

<style lang="scss" scoped>
.file_update .container {
  overflow: auto;
  position: relative;
  height: 104px;
}

.thumbnail {
  height: 100px;
  width: 150px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  float: left;
  border: 1px solid #CECECE;
  box-sizing: border-box;
  border-radius: 2px;
}

.upload_icon {
  position: absolute;
  bottom: 0;
  left: 124px;
}

.icon {
  color: #34B5BA;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  padding-top: 0.18em;
  box-sizing: border-box;
  border: 1px solid #34B5BA;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
  z-index: 99;
}

.img_subtitle {
  font-size: .9rem;
  margin-top: .6em;
  font-weight: 500;
}
</style>
