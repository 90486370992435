<script lang="ts" setup>
import { Modal } from "@/components";
import { computed, onMounted, ref, PropType } from "vue";
import {
  useCreateDocumentSpaceLinkMutation,
  useUpdateDocumentSpaceLinkMutation,
  DocumentSpaceLink
} from "@/graphql/operations";
import { OperationResult } from "@urql/core"
import { useForm, useField } from "vee-validate";
import { string } from "yup";
import { useI18n } from "vue-i18n";

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  spaceId: {
    type: String,
    required: true
  },
  link: {
    type: Object as PropType<DocumentSpaceLink>,
    required: false
  }
});

const { t } = useI18n();

const mode = computed(() => props.link !== undefined ? 'edit' : 'new');

const { validate, errors } = useForm();
const { value: sectionTitle, resetField: resetSectionTitle } = useField<string>(
  "sectionTitle",
  string()
  .required(t("documentSpaceLinkEditor.form.sectionTitle.error.missing")),
  { initialValue: props.link?.sectionTitle ?? ""});
const { value: title, resetField: resetTitle } = useField<string | undefined>(
  "title",
  string(),
  { initialValue: props.link?.title });

const normalizeUrl = (value: string) => {
  if (!value) return value;
  return value.match(/^https?:\/\//i) ? value : `https://${value}`;
};

const { value: url, resetField: resetUrl } = useField<string>(
  "url",
  string()
  .transform((value) => {
    return normalizeUrl(value);
  })
  .url(t("documentSpaceLinkEditor.form.url.error.invalid"))
  .required(t("documentSpaceLinkEditor.form.url.error.missing")),
  { initialValue: props.link?.url });

const onInvalidSubmit = () => {
  console.log("invalid submit");
};

const defaultLinkText = computed(() => {
  try {
    const u = new URL(url.value);
    return u.hostname;
  } catch (e) {
    return "";
  }
});

const emit = defineEmits(["close", "success", "error"]);

const close = () => {
  emit("close");
  resetSectionTitle();
  resetTitle();
  resetUrl();
};

const { executeMutation: createLink } = useCreateDocumentSpaceLinkMutation();
const { executeMutation: updateLink } = useUpdateDocumentSpaceLinkMutation();

const submit = async () => {
  const validationResult = await validate();
  if (!validationResult.valid) {
    console.log("invalid data");
    return;
  }

  let result: OperationResult<any, any>;
  if (mode.value === 'edit') {
    result = await updateLink({
      id: props.link!.id,
      title: title.value ? title.value : defaultLinkText.value,
      url: normalizeUrl(url.value),
      sectionTitle: sectionTitle.value || ""
    });
  } else {
    result = await createLink({
      documentSpaceId: props.spaceId,
      title: title.value ? title.value : defaultLinkText.value,
      url: normalizeUrl(url.value),
      sectionTitle: sectionTitle.value || ""
    });
  }

  if (!result.error) {
    emit("success");
    close();
  } else {
    console.error(result.error);
    emit("error");
  }
};

const input_section_title = ref<HTMLInputElement>();
onMounted(() => {
  if (!input_section_title.value) {
    return;
  }

  input_section_title.value?.focus();
});
</script>

<template>
  <Modal :active="active" @close="close">
    <template #header>
      {{ $t(`documentSpaceLinkEditor.header.${mode}`) }}
    </template>

    <template #body>
      <form ref="form" @submit.prevent="submit" @invalid-submit="onInvalidSubmit">
        <div>
          <label>{{ $t("documentSpaceLinkEditor.form.sectionTitle.label") }}</label>
          <input
            name="sectionTitle"
            :class="{ input: true, invalid: errors.sectionTitle }"
            v-model="sectionTitle"
            type="text"
            placeholder="Upcoming Event"
            ref="input_section_title"
          />

          <p :class="{ help: true, visible: errors.sectionTitle !== undefined }">
            {{ errors.sectionTitle }}
          </p>
        </div>

        <div>
          <label>{{ $t("documentSpaceLinkEditor.form.url.label") }}</label>
          <input
            name="url"
            :class="{ input: true, invalid: errors.url !== undefined }"
            v-model="url"
            type="text"
            placeholder="https://www.pitchview.io"
          />

          <p :class="{ help: true, visible: errors.url !== undefined }">
            {{ errors.url }}
          </p>
        </div>

        <div>
          <label>{{ $t("documentSpaceLinkEditor.form.title") }}</label>
          <input
            name="title"
            :class="{ input: true, invalid: errors.title !== undefined }"
            v-model="title"
            type="text"
            :placeholder="defaultLinkText || 'pitchview.de'"
          />

          <p :class="{ help: true, visible: errors.title !== undefined }">
            {{ errors.title }}
          </p>
        </div>
      </form>
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="submit">
          <span class="material-symbols-outlined">save</span>
          {{ $t(`documentSpaceLinkEditor.submit.${mode}`) }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
form {
  width: 100%;
}

form div {
  margin-bottom: 1em;
}

form label {
  font-size: .9em;
  margin-bottom: .25rem;
  display: block;
}

p.help {
  font-size: .8em;
  color: #f14668;
  visibility: hidden;
  margin: 0;
  padding: 0;
  margin-top: .25rem;
  height: .8em;
}

p.help.visible {
  visibility: visible;
}
</style>
