<script lang="ts" setup>
import { ref } from "vue";
import {
  ShortDocumentDocument,
  type ShortDocumentFragment as ShortDocument,
} from "@/graphql/operations";
import { DocumentSearch, DocumentPagePicker, Modal } from "@/components";
import graphqlClient from "@/graphql/client";
import { MimeType } from "@/util";

const showDocumentSearch = ref(true);
const showDocumentPagePicker = ref(false);
const pageIds = ref<Array<string>>([]);

const emits = defineEmits(["close", "submit", "addVideo"]);

const close = () => {
  emits("close");
};

const selectedDocument = ref<ShortDocument>();

const setSelectedDocument = async (values: Map<string, string>) => {
  const id = Array.from(values.keys())[0];
  const res = await graphqlClient
    .query(ShortDocumentDocument, { id: id })
    .toPromise();

  if (res.error) {
    //FIXME handle error
    return;
  }

  selectedDocument.value = res.data.document;
};

const setSelectedPages = (pages: Array<string>) => {
  pageIds.value = pages;
};

const nextStep = () => {
  if (!selectedDocument.value) {
    return;
  }

  if (MimeType.isVideo(selectedDocument.value.mimeType)) {
    emits("addVideo", selectedDocument.value.id);
  } else {
    showDocumentSearch.value = false;
    showDocumentPagePicker.value = true;
  }
};

const previousStep = () => {
  showDocumentSearch.value = true;
  showDocumentPagePicker.value = false;
};

const submitPages = () => {
  emits("submit", pageIds.value.sort());
};
</script>

<template>
  <Modal
    :active="true"
    :scrolling="true"
    @close="close"
    class="huge"
    data-test="document-pages-picker"
  >
    <template v-slot:header>
      {{
        showDocumentSearch
          ? $t("documentPagePicker.header.document")
          : $t("documentPagePicker.header.pages")
      }}
    </template>

    <template v-slot:body>
      <DocumentSearch
        v-if="showDocumentSearch"
        :single-select="true"
        @change="setSelectedDocument"
        :mustSupportFlows="true"
      />

      <div v-else-if="showDocumentPagePicker">
        <DocumentPagePicker
          :document-id="selectedDocument!.id"
          @change="setSelectedPages"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="showDocumentSearch" class="buttons">
        <button class="button cancel" @click="close">
          {{ $t("documentPagePicker.footer.close") }}
        </button>

        <button
          class="button submit"
          :disabled="!selectedDocument"
          @click="nextStep"
          data-test="select-document-submit"
        >
          {{ $t("documentPagePicker.footer.selectDocument") }}
        </button>
      </div>

      <div
        v-else-if="showDocumentPagePicker && selectedDocument"
        class="buttons"
      >
        <button class="button cancel" @click="previousStep">
          {{ $t("documentPagePicker.footer.goBack") }}
        </button>

        <button
          class="button submit"
          @click="submitPages"
          :disabled="pageIds.length === 0"
          data-test="select-pages-submit"
        >
          {{ $t("documentPagePicker.footer.selectPages") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
$primaryColor: #35b6ba;

.footer_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.button.submit {
  background-color: #35b6ba;
  color: white;
}
</style>
