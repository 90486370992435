<template>
  <div v-if="isProcessing" class="doc_overlay">
    <div class="info">
      {{ $t("pvDocEditor.processing") }}
    </div>
  </div>

  <div class="magic_page_studio">
    <FlashMessage
      v-if="flashMessageActive"
      :state="updateState"
      :message="$t(`magicPageStudio.flashMessage.${updateState}`)"
    />

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <template v-else>
      <div class="pane">
        <div class="main_content">
          <div class="page_cards" v-if="normalPages">
            <MagicPageCard
              v-for="page in normalPages"
              :page="page!"
              :selected="selectedPageId === page!.id"
              :key="page!.id"
              @click="selectPage(page!.id)"
            />
          </div>

          <hr
            v-if="numNormalPages > 0 && numMagicPages > 0"
            class="hr-seperating-pages"
          />

          <div class="page_cards" v-if="magicPages">
            <MagicPageCard
              v-for="page in magicPages"
              :key="page!.id"
              :selected="selectedPageId === page!.id"
              :page="page!"
              @click="selectPage(page!.id)"
            />
          </div>
        </div>

        <MagicPageWizard
          v-if="editorActive && selectedPage"
          :page="selectedPage"
          :previousIsMagic="selectedPage.pageNumber - 1 > lastNormalPageNumber"
          :nextIsMagic="selectedPage.pageNumber + 1 > lastNormalPageNumber"
          :numPages="numTotalPages"
          @selectNext="selectNextPage"
          @selectPrevious="selectPreviousPage"
          @close="editorActive = false"
          @success="onSuccess"
          @error="onError"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onBeforeUnmount } from "vue";
import MagicPageWizard from "./magic_page_wizard/MagicPageWizard.vue";
import { default as MagicPageCard } from "./MagicPageCard.vue";
import { FlashMessage, LoadingSpinner } from "@/components";
import {
  useMagicPagesQuery,
  useDocumentRevisionCheckQuery,
} from "@/graphql/operations";
import { useRoute } from "vue-router";

const route = useRoute();
const docId = route.params.documentId as string;

const { data, fetching, executeQuery } = useMagicPagesQuery({
  variables: {
    id: docId,
  },
  requestPolicy: "cache-and-network",
});

const allPages = computed(
  () =>
    data.value?.document?.documentRevisionByIdAndCurrentRevisionNumber
      ?.documentPagesByDocumentIdAndRevisionNumber.nodes
);

const normalPages = computed(() =>
  allPages.value?.filter((p) => p?.pageType === "NORMAL")
);
const magicPages = computed(() =>
  allPages.value?.filter((p) => p?.pageType !== "NORMAL")
);

const selectedPageId = ref<string | null>(null);

watch(data, () => {
  if (
    selectedPageId.value === null &&
    normalPages.value &&
    normalPages.value?.length > 0
  ) {
    selectedPageId.value = normalPages.value![0]!.id;
  }
});

const selectedPage = computed(() => {
  let page = allPages.value?.find((p) => p?.id === selectedPageId.value);
  if (page) {
    return page;
  }
});

const numNormalPages = computed(() => normalPages.value?.length || 0);
const numMagicPages = computed(() => magicPages.value?.length || 0);

const numTotalPages = computed(
  () => numNormalPages.value + numMagicPages.value
);

const lastNormalPageNumber = computed(() =>
  normalPages.value
    ? normalPages.value[numNormalPages.value - 1]?.pageNumber ?? 0
    : 0
);

const editorActive = ref(false);
const selectPage = (id: string) => {
  selectedPageId.value = id;
  editorActive.value = true;
};

const selectPageByNumber = (pageNumber: number) => {
  const targetPage = allPages.value?.find((p) => p?.pageNumber === pageNumber);

  if (targetPage) {
    selectPage(targetPage.id);
  }
};

const selectPreviousPage = () => {
  if (!selectedPage.value?.pageNumber) {
    return;
  }

  selectPageByNumber(selectedPage.value?.pageNumber - 1);
};

const selectNextPage = () => {
  if (!selectedPage.value?.pageNumber) {
    return;
  }

  selectPageByNumber(selectedPage.value?.pageNumber + 1);
};

const flashMessageActive = ref<boolean>(false);
const displayFlashMessage = () => {
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 2500);
};

const updateState = ref<string>();
const onSuccess = () => {
  updateState.value = "success";
  executeQuery();
  displayFlashMessage();
};

const onError = (error: string) => {
  updateState.value = "error";
  executeQuery();
  displayFlashMessage();
};

const { data: revisionData, executeQuery: fetchRevisionData } =
  useDocumentRevisionCheckQuery({
    variables: {
      id: docId,
    },
    requestPolicy: "network-only",
  });

const isProcessing = ref<boolean>(false);

let intervalId: null | number = window.setInterval(() => {
  checkRevision();
}, 5000);

async function checkRevision() {
  await fetchRevisionData();

  if (revisionData.value?.document?.mimeType !== "application/pdf") {
    isProcessing.value = false;

    if (intervalId) {
      window.clearInterval(intervalId);
      intervalId = null;
    }

    return;
  }

  const latestRevision = revisionData.value?.document?.latestRevision?.nodes;
  const currentRevision = revisionData.value?.document?.currentRevision;
  const curIsProcessing =
    (latestRevision !== undefined &&
      currentRevision !== undefined &&
      currentRevision !== null &&
      latestRevision.length > 0 &&
      latestRevision[0]!.revisionNumber > currentRevision.revisionNumber) ||
    currentRevision?.pages.totalCount === 0;

  if (isProcessing.value && !curIsProcessing) {
    await executeQuery();
  }

  isProcessing.value = curIsProcessing;
}

checkRevision();

onBeforeUnmount(() => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }
});
</script>

<style lang="scss" scoped>
$border-light: #ebebeb;
$card-width: 275px;

.doc_overlay {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  text-align: center;
  display: grid;
}

.doc_overlay .info {
  place-self: flex-start center;
  margin-top: calc((100vh - 70px) / 2 - 25px);
  background-color: #fff;
  border-radius: 0.5px;
  padding: 1rem;
  box-shadow: 0 2px 9px #0006;
  text-align: center;
}


.magic_page_studio {
  padding: 2em 0;
  height: calc(100vh - 100px);
}

.pane {
  display: flex;
}

.pane .main_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 3em;
}

.page_cards {
  display: grid;
  column-gap: 1em;
  row-gap: 1em;
  grid-template-columns: repeat(auto-fit, $card-width);
}

.hr-seperating-pages {
  width: 100%;
  margin: calc(2em - 10px) 0 2em 0;
  border: 1px solid $border-light;
}

.pane .sidebar {
  width: 290px;
  padding-left: 1em;
  border-left: 1px solid #cecece;
}

p.info {
  font-size: 0.9rem;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}
</style>
