<template>
  <div class="team_workspaces">
    <div v-if="loading">
      Loading
    </div>

    <div v-else>

      <flash-message
        v-if="flashMessageActive"
        :state="actionState"
        :message="$t(`teamDetail.manageWorkspaces.flashMessage.${actionState}`)"
      ></flash-message>

      <button
        class="button primary"
        v-if="myMembership!.mayAdministrate"
        @click="displaySelect = true"
      >
        {{ $t("teamDetail.manageWorkspaces.buttonText") }}
      </button>

      <section v-if="workspaceSubscriptions && workspaceSubscriptions.totalCount > 0">
        <h4>Workspaces</h4>

        <table class="data_table">
          <tbody>
            <tr
              v-for="workspace in subscriptions"
              :key="workspace.workspaceId">
              <td>{{ workspace.name }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <modal :active="displaySelect" @close="closeSelect" :scrolling="true">
        <template #header>
          {{ $t("teamDetail.manageWorkspaces.selectWorkspaces")}}
        </template>

        <template #body>
          <div class="items">
            <div v-for="workspace in candidates" :key="workspace.workspaceId" class="item">
              <div class="left">
                <span class="checkbox_container">
                  <input
                    type="checkbox"
                    :id="workspace.workspaceId as unknown as string"
                    :value="workspace.workspaceId"
                    v-model="selected"
                  />
                </span>
              </div>

              <div class="left">
                {{ workspace.name }}
              </div>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="buttons">
            <button :class="{ button: true, primary: true, disabled: submitDisabled }" @click="submit">
              {{ $t("submit")}}
            </button>
            <button class="button secondary" @click="closeSelect">
              {{ $t("cancel")}}
            </button>
          </div>
        </template>

      </modal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, ComputedRef, watch } from "vue";
import {
  useTeamDetailsByIdQuery,
  useWorkspacesForTeamQuery,
  useAddWorkspaceSubscriptionMutation,
  useDeleteWorkspaceSubscriptionMutation
} from "@/graphql/operations";
import { useRoute } from "vue-router";
import { Modal, FlashMessage } from "@/components";

const route = useRoute();

const displaySelect = ref<boolean>(false);

const teamId = route.params.id as string;
const { data: teamResults, fetching: loading } = useTeamDetailsByIdQuery({
  variables: {
    id: teamId
  }
});

const team = computed(() => teamResults?.value?.team);

const workspaceSubscriptions = computed(
  () => teamResults?.value?.workspaceSubscriptions
);

const myMembership = computed(() => team.value?.myMembership);

const { data, executeQuery: fetch } = useWorkspacesForTeamQuery({
  variables: {
    teamId: teamId
  },
  requestPolicy: "cache-and-network"
});

interface Workspace {
  membershipId?: string,
  workspaceId: string,
  name?: string
}

const subscriptions: ComputedRef<Array<Workspace>> = computed(() => {
  const s = data.value?.workspaceSubscriptions?.nodes.map(s => (
  {
    membershipId: s?.id!,
    workspaceId: s?.team?.id!,
    name: s?.team?.name
  }))
  .sort((s1, s2) => s1.name!.toUpperCase().localeCompare(s2.name!.toUpperCase()));

  return s || []
});

const currentSubscriptions = computed(() => subscriptions.value.map(s => s.workspaceId));

const selected = ref<Array<string>>([]);
watch(subscriptions, () => selected.value = subscriptions.value.map(s => s.workspaceId));

const submitDisabled = computed(() => currentSubscriptions.value.length === selected.value.length &&
  selected.value.reduce((acc, curr) => acc && currentSubscriptions.value!.includes(curr), true));

const candidates: ComputedRef<Array<Workspace>> = computed(() => {
  const c = data.value?.myMemberships?.nodes
    .map(c => (
      {
        workspaceId: c?.team?.id!,
        name: c?.team?.name
      }
    ))
    .sort((c1, c2) => c1.name!.toUpperCase().localeCompare(c2.name!.toUpperCase()))

  return c || [];
});

const closeSelect = () => {
  displaySelect.value = false;
  selected.value = subscriptions.value.map(s => s.workspaceId);
}

const flashMessageActive = ref<boolean>(false);
const displayFlashMessage = () => {
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 1500);
}

const { executeMutation: addWorkspaceSubscription } = useAddWorkspaceSubscriptionMutation();
const { executeMutation: deleteWorkspaceSubscription } = useDeleteWorkspaceSubscriptionMutation();
const actionState = ref<string>("");

const submit = async () => {
  actionState.value = "success";

  const newSubscriptions = selected.value.filter(s => !currentSubscriptions.value.includes(s));
  for (let workspaceId of newSubscriptions) {
    const r = await addWorkspaceSubscription({
      teamId: teamId,
      workspaceId: workspaceId
    });

    if (r.error) {
      actionState.value = "error";
    }
  }

  const removedSubscriptions = currentSubscriptions.value.filter(s => !selected.value.includes(s));
  for (let workspaceId of removedSubscriptions) {
    const subscriptionId = subscriptions.value.find(s => s.workspaceId === workspaceId)?.membershipId;

    const r = await deleteWorkspaceSubscription({
      id: subscriptionId!
    });

    if (r.error) {
      actionState.value = "error";
    }
  }

  displayFlashMessage();
  closeSelect();
  fetch();
}
</script>

<style lang="scss" scoped>
.team_workspaces {
  padding: 1em 0;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.8;
}

.button {
  float: right;
  font-size: 0.9em;
}

.left {
  float: left;
  height: 38px;
}

.item {
  padding: 5px 0;
  border-bottom: 1px solid #f4f4f4;
  height: 38px;
  line-height: 38px;
  overflow: auto;
}

.item .checkbox_container {
  display: inline-block;
  padding: .5em;
  width: 1.4em;
  margin-right: .5em;
}

input[type="checkbox"] {
  cursor: pointer;
}

.item.highlighted,
.item:hover {
  background-color: #fafafa;
}
</style>
