import { useFetchCurrentUserQuery, FetchCurrentUserQuery } from "@/graphql/operations";
import { InjectionKey, provide, inject, Ref, computed } from "vue";

export type User = NonNullable<FetchCurrentUserQuery['myAccount']>['nodes'][0]
export const userKey = Symbol() as InjectionKey<Ref<User>>

export function useUser() {
  // inject user from parent components
  const injectedUser = inject(userKey)
  if (injectedUser !== undefined) return injectedUser

  // if no user was injected, fetch a user from the database and provide it to child components
  const { data } = useFetchCurrentUserQuery({ requestPolicy: 'cache-and-network' })
  const fetchedUser = computed(() => data.value?.myAccount?.nodes?.[0] ?? null)
  provide(userKey, fetchedUser)
  return fetchedUser
}

export default useUser
