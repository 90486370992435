import { useStore } from "vuex";
import { ref, computed } from 'vue';
import {
  useDocumentSpacesCountQuery,
} from "@/graphql/operations";

export function useSpaceModalDisplay() {
  const store = useStore();

  const displayCreateDocumentSpaceModal = ref(false);
  const displayCopyDocumentSpaceModal = ref(false);
  const displayUpgradeModal = ref(false);

  const { data, fetching, executeQuery } = useDocumentSpacesCountQuery({
    requestPolicy: "network-only",
  });
  const numSpaces = computed(() => data.value?.documentSpaces?.totalCount);
  const reachedMaxSpaces = computed(
    () =>
      numSpaces.value &&
      numSpaces.value >= 3 &&
      !store.getters.user.hasDocumentSpaces()
  );

  const handleModalDisplay = async (modalType: string) => {
    await executeQuery();
    if (reachedMaxSpaces.value) {
      displayUpgradeModal.value = true;
    } else {
      if (modalType === 'create') {
        displayCreateDocumentSpaceModal.value = true;
      } else if (modalType === 'copy') {
        displayCopyDocumentSpaceModal.value = true;
      }
    }
  };

  return {
    displayCreateDocumentSpaceModal,
    displayCopyDocumentSpaceModal,
    displayUpgradeModal,
    handleModalDisplay,
  };
}