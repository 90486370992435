<script setup lang="ts">
import {
  useDocumentSpaceVisitDetailsQuery,
  Interval,
} from "@/graphql/operations";
import { computed } from "vue";
import { Formatter } from "@/util";

const props = defineProps({
  visitId: {
    required: true,
    type: String,
  },
});

const { data } = useDocumentSpaceVisitDetailsQuery({
  variables: {
    visitId: props.visitId,
  },
});

const visitDetails = computed(() => data.value?.maVisitDetails?.nodes);

const formatDuration = (duration: Interval) => {
  return Formatter.formatDuration({
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
  });
};
</script>

<template>
  <table class="data_table">
    <tbody>
      <tr v-for="(visit, idx) in visitDetails" :key="idx">
        <template v-if="visit!.type === 'document'">
          <td>
            <span class="material-symbols-outlined">description </span>
            {{ visit!.displayTitle }}
          </td>
          <td>{{ formatDuration(visit!.duration!) }}</td>
        </template>
        <template v-else-if="visit!.type === 'document_download'">
          <td>
            <span class="material-symbols-outlined">download</span>
            {{ visit!.displayTitle }}
          </td>
          <td></td>
        </template>
        <template v-else-if="visit!.type === 'video'">
          <td>
            <span class="material-symbols-outlined">smart_display</span>
            {{ visit!.displayTitle }}
          </td>
          <td>{{ Math.ceil(visit!.watchRate) }}%</td>
        </template>
        <template v-else-if="visit!.type === 'click'">
          <td>
            <span class="material-symbols-outlined">link</span>
            {{ visit!.url }}
          </td>
          <td></td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
table.data_table tr th,
table.data_table tr td {
  border-bottom: none;
  color: rgba(33, 35, 44, 0.72);
}

table.data_table tr th:first-child,
table.data_table tr td:first-child {
}

table.data_table tr th:last-child,
table.data_table tr td:last-child {
  width: 60px;
  text-align: right;
  padding-right: 9px;
}

td .material-symbols-outlined {
  vertical-align: text-bottom;
  font-size: 20px;
}
</style>