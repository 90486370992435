<script lang="ts" setup>
import { PropType, ref, computed, watch } from "vue";
import {
  type PageConfigFragment,
  type PageType,
  type RootWorkspaceFragment,
  useUpdatePageTypeMutation,
} from "@/graphql/operations";
import {
  Tooltip,
  Modal,
  DocumentSearch,
  DiscardChangesModal,
} from "@/components";
import PageTypeSelector from "./PageTypeSelector.vue";
import { captureException } from "@sentry/vue";
import { useStore } from "vuex";
import WizardHeader from "./Header.vue";
import WizardFooter from "./Footer.vue";

const store = useStore();
const rootWorkspace = store.getters.currentWorkspace.root;

const emit = defineEmits([
  "close",
  "success",
  "error",
  "selectNext",
  "selectPrevious",
]);

const props = defineProps({
  page: {
    type: Object as PropType<PageConfigFragment>,
    required: true,
  },
  previousIsMagic: {
    type: Boolean,
    required: true,
  },
  nextIsMagic: {
    type: Boolean,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
});

const currentStep = ref(1);
const setCurrentStep = (value: number) => {
  currentStep.value = value;
};

const selectedType = ref<string>(props.page.pageType);
const selectedDocumentId = ref<string | undefined>(
  props.page.linkedDocument?.id
);

watch(
  () => props.page,
  (newPage, oldPage) => {
    if (newPage?.id !== oldPage?.id) {
      currentStep.value = 1;
      crossLinksEnabled.value = isCrossLink.value;
    }
    selectedType.value = newPage.pageType;
    selectedDocumentId.value = newPage.linkedDocument?.id;
  }
);

const hasChanges = computed(
  () =>
    selectedType.value !== props.page.pageType ||
    selectedDocumentId.value !== props.page.linkedDocument?.id
);

const missingDocument = computed(
  () => selectedType.value === "MAGIC_LINK" && !selectedDocumentId.value
);

const isCrossLink = computed(() => {
  if (!props.page.linkedDocument) {
    return false;
  }

  return props.page.linkedDocument?.workspace?.root?.id !== rootWorkspace.id;
});

const isLinkAccessible = computed(() => {
  if (!isCrossLink.value) {
    return true;
  }

  const w = store.getters.rootWorkspaces.find(
    (w: RootWorkspaceFragment) =>
      w.id == props.page.linkedDocument?.workspace?.root?.id
  );
  return w?.myPrivileges?.mayAdministrate;
});

const crossLinksEnabled = ref(isCrossLink.value);
const setCrossLinksMode = (value: boolean) => {
  crossLinksEnabled.value = value;
};

const setSelectedType = (value: string) => {
  selectedType.value = value;
};

const updateSelectedDocument = (values: Map<string, string>) => {
  const id = Array.from(values.keys())[0];
  selectedDocumentId.value = id;
};

const { executeMutation } = useUpdatePageTypeMutation();
const submit = async () => {
  const { data, error } = await executeMutation({
    id: props.page.id,
    pageType: selectedType.value as PageType,
    linkedDocumentId: selectedDocumentId.value,
  });

  if (error) {
    emit("error", error.message);
    captureException(error.message);
  } else {
    emit("success");
  }
};

const displayDiscardChangesModal = ref(false);
const outstandingAction = ref<
  "close" | "selectNext" | "selectPrevious" | undefined
>();
const checkForUnsavedChanges = (
  action: "close" | "selectNext" | "selectPrevious"
) => {
  if (hasChanges.value) {
    displayDiscardChangesModal.value = true;
    outstandingAction.value = action;
  } else {
    emit(action);
  }
};

const closeDiscardChangesModal = () => {
  outstandingAction.value = undefined;
  displayDiscardChangesModal.value = false;
};

const discardChangesAndProceed = () => {
  if (outstandingAction.value) {
    emit(outstandingAction.value);
  }

  closeDiscardChangesModal();
};
</script>

<template>
  <Modal
    :active="true"
    @close="checkForUnsavedChanges('close')"
    :scrolling="true"
    class="large hide_close"
  >
    <template #header>
      <wizard-header
        :page="page"
        :currentStep="currentStep"
        :numPages="numPages"
        :hasChanges="hasChanges"
        @goToPreviousStep="currentStep = 1"
        @goToNextPage="checkForUnsavedChanges('selectNext')"
        @goToPreviousPage="checkForUnsavedChanges('selectPrevious')"
      />
    </template>

    <template v-slot:body>
      <page-type-selector
        v-if="currentStep === 1"
        :page="page"
        :value="selectedType"
        @change="setSelectedType"
      />

      <div v-else>
        <DocumentSearch
          :singleSelect="true"
          :rootWorkspace="crossLinksEnabled ? undefined : rootWorkspace.id"
          :singleWorkspace="!crossLinksEnabled"
          :selectedDocument="isLinkAccessible ? selectedDocumentId : undefined"
          :excluded="[page.documentId]"
          :selectExcluded="false"
          :mustBeAdmin="true"
          @change="updateSelectedDocument"
        />
      </div>
    </template>

    <template v-slot:footer>
      <wizard-footer
        :page="page"
        :currentStep="currentStep"
        :hasChanges="hasChanges"
        :missingDocument="missingDocument"
        :previousIsMagic="previousIsMagic"
        :nextIsMagic="nextIsMagic"
        :selectedType="selectedType"
        :crossLinksEnabled="crossLinksEnabled"
        @changeMode="setCrossLinksMode"
        @changeStep="setCurrentStep"
        @submit="submit"
        @close="checkForUnsavedChanges('close')"
      />
    </template>
  </Modal>

  <discard-changes-modal
    v-if="displayDiscardChangesModal"
    @close="closeDiscardChangesModal"
    @submit="discardChangesAndProceed"
  />
</template>

<style scoped lang="scss">
.modal {
  font-size: 0.9em;
}

.material-symbols-outlined.clickable {
  cursor: pointer;
}
</style>
