import { createStore } from "vuex";
import { User } from "@/user";
import { Workspace, RootWorkspaceFragment } from "@/graphql/operations";

interface State {
  presentations: Array<Document>;
  currentPresentation: Document | undefined;
  pvDocs: Array<Document>;
  user: User;
  currentWorkspace: Workspace | undefined;
  workspaces: Array<Workspace>;
  rootWorkspaces: Array<RootWorkspaceFragment>;
  activeProjectorId: string | null;
  activeProjectorCode: string | null;
  currentPVDocument: Document | undefined;
  watcher: Function | undefined,
  numSpaceConfigs: number
}

export const store = createStore({
  state: {
    presentations: [],
    currentPresentation: undefined,
    pvDocs: [],
    user: new User(),
    currentWorkspace: undefined,
    workspaces: [],
    rootWorkspaces: [],
    activeProjectorId: null,
    activeProjectorCode: null,
    currentPVDocument: undefined,
    watcher: undefined,
    numSpaceConfigs: 0
  } as State,
  getters: {
    user(state) {
      return state.user;
    },
    currentWorkspace(state) {
      return state.currentWorkspace;
    },
    workspaces(state) {
      return state.workspaces;
    },
    rootWorkspaces(state) {
      return state.rootWorkspaces;
    },
    activeProjectorId(state) {
      return state.activeProjectorId;
    },
    activeProjectorCode(state) {
      return state.activeProjectorCode;
    },
    currentPVDocument(state) {
      return state.currentPVDocument;
    },
    numSpaceConfigs(state) {
      return state.numSpaceConfigs;
    }
  },
  mutations: {
    setCurrentPresentation(state, { presentation }) {
      state.currentPresentation = presentation;
    },
    setUserId(state, id: string | null) {
      state.user.setId(id);
    },
    setUserData(state, userData) {
      state.user.setPostgresId(userData.id);
      state.user.setEmail(userData.email);
      state.user.setName(userData.displayName);
      state.user.setProfileImgUrl(userData.userContactDetail?.profileImageUrl);
      state.user.setMergedFlags(userData.mergedFlags);
      state.user.setMergedConfig(userData.mergedConfig);
      state.user.setTenantId(userData.tenantId);
    },
    setCurrentWorkspace(state, { workspace }) {
      state.currentWorkspace = workspace;
    },
    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
    setRootWorkspaces(state, workspaces) {
      state.rootWorkspaces = workspaces;
    },
    setActiveProjectorId(state, activeProjectorId: string | null) {
      state.activeProjectorId = activeProjectorId;
    },
    setActiveProjectorCode(state, activeProjectorCode: string | null) {
      state.activeProjectorCode = activeProjectorCode;
    },
    setCurrentPVDocument(state, currentPVDocument: Document | undefined) {
      state.currentPVDocument = currentPVDocument;
    },
    setNumSpaceConfigs(state, numSpaceConfigs: number | undefined) {
      state.numSpaceConfigs = numSpaceConfigs || 0;
    }
  },
  actions: {
    //bindPresentationsRef: firestoreAction(context => {
    //  // context contains all original properties like commit, state, etc
    //  // and adds `bindFirestoreRef` and `unbindFirestoreRef`
    //  // we return the promise returned by `bindFirestoreRef` that will
    //  // resolve once data is ready
    //  return context.bindFirestoreRef(
    //    "presentations",
    //    db.collection("presentations")
    //  );
    //}),
    //updatePresentation: firestoreAction(async ({ state }, { doc, newPage }) => {
    //  const response = await db
    //    .collection("presentations")
    //    .doc(doc.id)
    //    .update({ page: newPage });
    //  console.log("hat geklappt");
    //  return response;
    //}),
    //addPresentation: firestoreAction(
    //  async ({ state, commit, bindFirestoreRef }, { props }) => {
    //    const ref = await db.collection("presentations").add(props);
    //    bindFirestoreRef(
    //      "currentPresentation",
    //      db.collection("presentations").doc(ref.id)
    //    );
    //    const snapshot = await ref.get();
    //    return snapshot;
    //  }
    //)
  },
});
