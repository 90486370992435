<template>
  <div id="app" class="viewport">
    <UnsupportedBrowserBanner v-if="isIE" />
    <AppPromotionOverlay v-if="displayAppPromotion" />
    <div id="loader">
      <template v-if="pathname.startsWith('/u')">
        <div class="logo_container">
          <img src="@/assets/images/logo_pitchview_large.svg" class="logo" />
        </div>
        <div class="loading_container">
          <div class="loading_bar"></div>
        </div>
        <div class="loading_label">
          {{ $t("appLoading") }}
        </div>
      </template>
    </div>
    <router-view />

    <!-- SSO Login Overlay -->
    <teleport to="body" v-if="showSsoModal">
      <div class="modal-wrapper">
        <div class="modal">
          <p>
            {{
              $t("externalGroupImport.import.infoText", {
                tenantName: user?.tenant?.name,
              })
            }}
          </p>
          <div class="button-bar">
            <a href="/sso/login" class="button primary">
              {{ $t("externalGroupImport.import.buttonText") }}
            </a>
            <button
              @click="
                skipSsoForThisSession = 'true';
                showSsoModal = false;
              "
              class="button"
            >
              {{ $t("externalGroupImport.import.skip.buttonText") }}
            </button>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import { useUser } from "@/util/useUser";
import { UserAgent } from "@/util";
import { UnsupportedBrowserBanner, AppPromotionOverlay } from "@/components";
import * as Sentry from "@sentry/vue";
import { useSessionStorage, whenever } from "@vueuse/core";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const pathname = computed(() => window.location.pathname);

const isIE = UserAgent.isIE();
const user = useUser();
const showSsoModal = ref(false);
const skipSsoForThisSession = useSessionStorage(
  "skipSsoForThisSession",
  "false",
  { listenToStorageChanges: true }
);

if (isIE) {
  Sentry.captureMessage("Internet Explorer");
}

whenever(
  () =>
    user.value?.tenant?.hasExternalGroups &&
    user.value.externalGroupsSyncedAt === null,
  () => {
    showSsoModal.value =
      sessionStorage.getItem("skipSsoForThisSession") !== "true";
  },
  { immediate: true }
);

const displayAppPromotion = computed(() => {
  return (
    route.meta.hideAppPromotion !== true &&
    (UserAgent.isMobile() || UserAgent.isIPhone() || UserAgent.isIPad())
  );
});
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 10;
  display: grid;
}

.modal {
  place-self: center center;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.4);
  text-align: center;
}

.modal a.button {
  text-decoration: none;
  color: white;
}

.button-bar {
  display: flex;
  gap: 0.8em;
  justify-content: center;
}

.button-bar .button {
  display: block;
}

.logo_container {
  width: 250px;
  text-align: center;
}

.logo_container img {
  width: 100%;
  height: auto;
}

.loading_container {
  width: 100%;
  height: 6px;
  background-color: #e0f7f8;
  border-radius: 3px;
  overflow: hidden;
  margin: 60px auto 20px auto;
}

.loading_bar {
  height: 100%;
  width: 50%;
  background: linear-gradient(to right, #34b5ba, #b3e5e6);
  animation: loading 1.5s infinite ease-in-out;
  border-radius: 3px;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loading_label {
  width: 100%;
  text-align: center;
}
</style>
