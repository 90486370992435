<script lang="ts" setup>
import {
  useDocumentSpaceQuery,
  useUpdateDocumentSpaceLinkPositionMutation,
  type DocumentSpace,
  type DocumentSpaceConfig,
  type DocumentSpaceLink,
  type DocumentSpaceSection,
} from "@/graphql/operations";
import { useRoute } from "vue-router";
import { computed, ref, onBeforeUnmount, watch } from "vue";
import { FlashMessage, LoadingSpinner } from "@/components";
import DocumentSpaceLinkList from "./DocumentSpaceLinkList.vue";
import ConfigLinks from "./ConfigLinks.vue";
import DocumentSpaceTexts from "./DocumentSpaceTexts.vue";
import DocumentSpaceHeader from "./DocumentSpaceHeader.vue";
import DocumentSpaceContactDetails from "./DocumentSpaceContactDetails.vue";
import LatestVisit from "./LatestVisit.vue";
import DocumentSpaceSections from "./DocumentSpaceSections.vue";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();

const id = route.params.id as string;
const userId = store.getters.user.id;
const displayAnalytics = store.getters.user.hasAnalytics();

const { executeQuery, fetching, data } = useDocumentSpaceQuery({
  variables: {
    id: id,
  },
  requestPolicy: "cache-and-network",
});

let intervalId: number | undefined = window.setInterval(() => {
  executeQuery();
}, 4000);

onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = undefined;
  }
});

const documentSpace = computed(() => data?.value?.documentSpace);

const flashMessageActive = ref<boolean>(false);
const displayFlashMessage = () => {
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 1000);
};

const handleSuccess = async () => {
  displayFlashMessage();
  await executeQuery();
};

const { executeMutation: executeUpdateLinkPosition } =
  useUpdateDocumentSpaceLinkPositionMutation();

const updateLinkPosition = async (linkId: string, position: number) => {
  await executeUpdateLinkPosition({
    id: linkId,
    position: position,
  });

  handleSuccess();
};

const hasDocumentSpaceLinks = computed(() =>
  store.getters.user.hasDocumentSpaceLinks()
);
</script>

<template>
  <div v-if="!store.getters.user.hasDocumentSpaces()" class="banner">
    <h3>
      {{ $t("documentSpace.demo.banner.title") }}
    </h3>
    <p>
      {{ $t("documentSpace.demo.banner.text") }}
    </p>
  </div>

  <div v-if="fetching" class="loading_wrapper">
    <div class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>
  </div>

  <div
    v-else-if="documentSpace"
    :class="{
      page_content: true,
      with_banner: !store.getters.user.hasDocumentSpaces(),
    }"
  >
    <FlashMessage
      v-if="flashMessageActive"
      :state="'success'"
      :message="'Erfolgreich gespeichert'"
    />

    <div class="cols">
      <div class="col">
        <document-space-texts
          :documentSpace="documentSpace as DocumentSpace"
          @success="handleSuccess"
        />

        <ConfigLinks
          v-if="
            documentSpace.config &&
            documentSpace.config.documentSpaceLinksByConfigId.nodes &&
            documentSpace.config.documentSpaceLinksByConfigId.nodes.length > 0
          "
          :config="documentSpace.config as DocumentSpaceConfig"
          :documentSpaceId="id"
          @success="handleSuccess"
        />

        <DocumentSpaceLinkList
          v-if="hasDocumentSpaceLinks"
          :links="documentSpace.documentSpaceLinks.nodes as Array<DocumentSpaceLink>"
          :documentSpaceId="id"
          @success="handleSuccess"
          @updatePosition="updateLinkPosition"
        />

        <DocumentSpaceSections
          :sections="documentSpace.documentSpaceSections.nodes as Array<DocumentSpaceSection>"
          :documentSpaceId="id"
          @success="handleSuccess"
        />
      </div>

      <div class="col">
        <document-space-header
          :documentSpace="documentSpace as DocumentSpace"
          @success="handleSuccess"
          @refreshData="executeQuery()"
        />
        <document-space-contact-details
          :documentSpace="documentSpace as DocumentSpace"
          @success="handleSuccess"
          @refreshData="executeQuery()"
        />

        <latest-visit v-if="displayAnalytics" :documentSpaceId="id" />
      </div>
    </div>
  </div>

  <div v-else class="page_content">
    <section class="unknown_space_pane">
      <span class="material-symbols-outlined"> error_outline </span>
      <span class="text">
        {{ $t("documentSpace.doesNotExist") }}
      </span>
    </section>
  </div>
</template>

<style lang="scss" scoped>
$border-strong: #d0d4d9;
.page_content {
  background: transparent;
  padding: 0 0 1em 0;
  font-size: 0.9rem;
  height: 100vh;
  box-sizing: border-box;
}

.page_content.with_banner {
  margin-top: 67px;
  min-height: calc(100vh - 90px);
}

.banner {
  background: #ececec;
  padding: 1em;
  position: fixed;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  left: 225px;
  z-index: 100;
}

.banner h3 {
  margin: 0;
  font-weight: 500;
  font-size: 1em;
}

.banner p {
  margin: 0;
  line-height: 1.5em;
  font-size: 1em;
}

section {
  margin: 0 1.5em 0 1.5em;
  padding: 1em;
  border-bottom: 1px solid #e0e0e0;
}

.hero_container {
  position: relative;
}

.hero {
  height: 200px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero_background {
  height: auto;
  max-height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  top: 0;
  left: 0;
}

.title_container {
  margin-bottom: 1.2em;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0 1em;
}

.title_container h4 {
  flex: 1;
  margin: 0;
  font-weight: 500;
}

.button {
  font-size: 1em;
}

.button.right {
  float: right;
  margin-left: 5px;
}

.button .material-symbols-outlined {
  font-size: 1em;
  line-height: 1.5;
  vertical-align: middle;
  margin-right: 5px;
}

section.info_container {
  display: flex;
}

section.info_container .message_config {
  flex: 1;
  padding-right: 3rem;
}

.row {
  margin-top: 1.8em;
  margin-left: calc(75px + 1.5em);
}

input[type="checkbox"] {
  margin-right: 5px;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  margin: 0 0.5em 0.5em 0;
}

button[data-tooltip]::before {
  background: rgba(74, 74, 74, 0.9);
  border-radius: 2px;
  content: attr(data-tooltip);
  padding: 0.5rem 1rem;
  text-overflow: ellipsis;
  white-space: pre;
  right: auto;
  bottom: auto;
  left: 50%;
  top: 0;
  margin-top: -5px;
  margin-bottom: auto;
  transform: translate(-50%, -100%);
}

button[data-tooltip]::before,
button[data-tooltip]::after {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 0.75rem;
  hyphens: auto;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 1020;
}

button[data-tooltip]::after {
  content: "";
  border-style: solid;
  border-width: 6px;
  border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent;
  margin-bottom: -5px;
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-top: -5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: -5px;
  border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent;
}

button[data-tooltip] {
  overflow: visible;
  position: relative;
}

.info {
  text-align: center;
  font-style: italic;
  margin-top: 1em;
  margin-bottom: 6em;
}

.control {
  margin-left: 0.4em;
}

.control label {
  display: inline-block;
  padding: 0.35rem 0;
  margin-right: 3px;
  line-height: 1.5;
}

.control label .material-symbols-outlined {
  font-size: 1.4em;
}

.control .clickable {
  cursor: pointer;
}

.circle_hover {
  border-radius: 50%;
  padding: 0.25em;
}

.circle_hover:hover {
  background: #eee;
}

.loading_wrapper {
  height: 100%;

  .loading_status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 75px);
  }
}

.unknown_space_pane {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2em;

  .material-symbols-outlined {
    font-size: 14em;
    color: #ebebeb;
  }

  .text {
    color: #363636;
    opacity: 0.8;
    font-size: 1rem;
  }
}

.cols {
  display: flex;
  height: 100vh;
}

.page_content.with_banner .cols {
  height: calc(100vh - 80px);
}

.cols .col:first-child {
  flex: 1;
  height: 100%;
  overflow: scroll;
}

.cols .col:last-child {
  width: 350px;
  border-left: 1px solid #e0e0e0;
  height: 100%;
  overflow: scroll;
}
</style>
