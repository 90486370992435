<script lang="ts" setup>
import { computed, ref, PropType, watch } from "vue";
import { getStorageProxyUrl, Formatter, MimeType } from "@/util";
import { DocumentTags, Tooltip } from "@/components";
import { useStore } from "vuex";
import {
  type WorkspaceDocumentWithLinkingPagesFragment,
  type DocumentUsageCountsRecord,
} from "@/graphql/operations";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const props = defineProps({
  document: {
    type: Object as PropType<WorkspaceDocumentWithLinkingPagesFragment>,
    required: true,
  },
  documentUsage: {
    type: Object as PropType<DocumentUsageCountsRecord>,
    required: true,
  },
});

const lastUpdatedAt = computed(() =>
  Formatter.formatDate(new Date(props.document.displayDate), "%Y-%m-%d %H:%M")
);

const isVideo = computed(() => props.document.mimeType === "application/mp4");

const tags = computed(() =>
  props.document && props.document.tags
    ? (props.document.tags as Array<string>)
    : []
);

const type = computed(() => {
  if (props.document.originalMimeType) {
    return MimeType.getName(props.document.originalMimeType);
  }

  return MimeType.getName(props.document.mimeType);
});

const size = computed(() =>
  Formatter.formatSize(parseInt(props.document.fileSize, 10))
);
const duration = computed(() => Formatter.formatDuration(props.document.pages));

const emit = defineEmits([
  "updateTags",
  "updateShareable",
  "updateDownloadable",
  "updateShareable",
  "updateSupportsFlows",
  "updateExternalId",
  "updateDisplayTeaserText",
]);
const emitUpdateTags = (tags: Array<string>) => {
  emit("updateTags", tags);
};

const shareable = ref<boolean>(props.document.doesSupportSharedspace);
const updateShareable = () => {
  emit("updateShareable", shareable.value);
};

const isDownloadable = ref<boolean>(props.document.isDownloadable);
const updateIsDownloadable = () => {
  emit("updateDownloadable", isDownloadable.value);
};

const supportsFlows = ref<boolean>(props.document.doesSupportFlows);
const updateSupportsFlows = () => {
  emit("updateSupportsFlows", supportsFlows.value);
};

const displayTeaserTextInSpace = ref<boolean>(
  props.document.displayTeaserTextInSpace
);
const updateDisplayTeaserText = () => {
  emit("updateDisplayTeaserText", displayTeaserTextInSpace.value);
};

const externalId = ref<string | undefined>(
  props.document.externalId || undefined
);
watch(externalId, () => {
  emit("updateExternalId", externalId.value);
});

const linkings = computed(() => props.document.linkingPages?.nodes);
</script>

<template>
  <div>
    <div class="row">
      <label>{{ $t("pvDocEditor.info.updatedAt.label") }}:</label>
      <span>{{ lastUpdatedAt }}</span>
    </div>
    <div class="row">
      <template v-if="isVideo">
        <label>{{ $t("pvDocEditor.info.duration.label") }}:</label>
        <span>{{ duration }}</span>
      </template>
      <template v-else>
        <label>{{ $t("pvDocEditor.info.pages.label") }}:</label>
        <span>{{ document.pages }}</span>
      </template>
    </div>
    <div class="row">
      <label>{{ $t("pvDocEditor.info.type.label") }}:</label>
      <span>{{ type }}</span>
    </div>
    <div class="row">
      <label>{{ $t("pvDocEditor.info.size.label") }}:</label>
      <span>{{ size }}</span>
    </div>

    <div class="row separated">
      <label>{{ $t("pvDocTags.docTags.label") }}</label>
      <DocumentTags :tags="tags" @change="emitUpdateTags" />
    </div>

    <div class="row separated"></div>

    <div class="row" v-if="store.getters.user.hasCustomFlows()">
      <div class="checkbox_container">
        <input
          type="checkbox"
          v-model="supportsFlows"
          @change="updateSupportsFlows"
        />
      </div>
      <label>{{ $t("pvDocEditor.info.supportsFlows.label") }}</label>
    </div>

    <div class="row">
      <div class="checkbox_container">
        <input type="checkbox" v-model="shareable" @change="updateShareable" />
      </div>
      <label>{{ $t("pvDocEditor.info.shareable.label") }}</label>
    </div>

    <div class="row" v-if="shareable">
      <div class="checkbox_container">
        <input
          type="checkbox"
          v-model="isDownloadable"
          @change="updateIsDownloadable"
        />
      </div>
      <label>{{ $t("pvDocEditor.info.isDownloadable.label") }}</label>
    </div>

    <div v-if="isVideo" class="row">
      <div class="checkbox_container">
        <input
          type="checkbox"
          v-model="displayTeaserTextInSpace"
          @change="updateDisplayTeaserText"
        />
      </div>
      <label>{{ $t("pvDocEditor.info.displayTeaserTextInSpace.label") }}</label>
    </div>

    <div class="row separated">
      <label>
        {{ $t("pvDocEditor.externalId.label") }}
        <tooltip class="right">
          <template v-slot>
            <span class="material-symbols-outlined"> info </span>
          </template>
          <template #tooltip>
            {{ $t("pvDocEditor.externalId.info") }}
          </template>
        </tooltip>
      </label>
    </div>

    <div class="row">
      <input type="text" class="input" v-model="externalId" />
    </div>

    <div class="row separated" v-if="linkings && linkings.length > 0">
      <label>{{ $t("pvDocEditor.info.linkings.label") }}:</label>
      <div v-for="linking in linkings" :key="linking!.id" class="doc_row">
        <template v-if="linking?.document">
          <div class="thumbnail_container">
            <img
              :src="getStorageProxyUrl(linking.document.thumbnailDownloadUrl)"
              :alt="linking.document.displayTitle"
              class="thumbnail_image"
            />
          </div>
          <div class="doc_title">
            <div>
              <a
                v-if="linking.document.workspace?.id"
                :href="
                  router.resolve({
                    name: 'StackedProjector',
                    params: { workspaceId: linking.document.workspace.id },
                    query: {
                      fid: linking.document.firestoreId,
                    },
                  })?.href
                "
                target="_blank"
                class="link"
              >
                <span>
                  {{ linking.document.displayTitle }}
                </span>
                <span class="material-symbols-outlined"> open_in_new </span>
              </a>
              <span v-else>
                {{ linking.document.displayTitle }}
              </span>
            </div>
            <div class="info">
              <span>
                {{ $t("pvDocEditor.info.linkings.linkingPage") }}:
                {{ linking.pageNumber }}
              </span>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="
          documentUsage.numPresentations &&
          documentUsage.numPresentations > linkings.length
        "
        class="usage_row"
      >
        <span class="material-symbols-outlined"> info </span>
        {{
          $t("pvDocEditor.info.linkings.withNoAccess", {
            count: documentUsage.numPresentations - linkings.length,
          })
        }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div {
  font-size: 0.9rem;
}

.row {
  margin-bottom: 0.4em;
}

.row.separated {
  border-top: 1px solid #cecece;
  padding-top: 1em;
  margin-top: 1.3em;
}

label {
  color: rgba(82, 74, 62, 0.82);
  width: 150px;
  display: inline-block;
}

.checkbox_container + label {
  width: auto;
}

a.link {
  color: inherit;
  text-decoration: none;
  line-height: 1.6;
}

a.link span {
  text-decoration: underline;
}

a.link .material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: middle;
  text-decoration: none;
  margin-left: 0.2em;
}

.usage_row,
.doc_row {
  display: flex;
  align-items: center;
  margin-top: 0.3em;
  width: 100%;
}

.usage_row {
  margin-top: 1em;
}

.usage_row .material-symbols-outlined {
  margin-right: 0.3em;
}

.doc_row .thumbnail_container {
  height: 60px;
  width: 60px;
  margin-right: 0.5em;
}

.thumbnail_image {
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}
.doc_row .info {
  color: rgba(82, 74, 62, 0.82);
}

.doc_row .doc_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 60px);
}

label .material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: -3px;
}
</style>
