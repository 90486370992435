<script lang="ts" setup>
import { PropType } from "vue";
import { type DocumentSearchResultFragment } from "@/graphql/operations";
import { Tooltip } from "@/components";
import { getStorageProxyUrl, MimeType, Formatter } from "@/util";

const props = defineProps({
  document: {
    type: Object as PropType<DocumentSearchResultFragment>,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledForFlows: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledForSpaces: {
    type: Boolean,
    required: false,
    default: false,
  },
  singleSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  match: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(["select"]);
</script>

<template>
  <tr
    :class="{
      item: true,
      disabled: disabled,
    }"
    @click="emits('select')"
  >
    <td>
      <div class="checkbox_container">
        <template v-if="disabled">
          <Tooltip>
            <template v-slot>
              <input
                v-if="singleSelect"
                type="radio"
                disabled="true"
                :id="document.id"
                :value="document.id"
                :checked="selected"
              />

              <input
                v-else
                type="checkbox"
                disabled="true"
                :id="document.id"
                :value="document.id"
                :checked="selected"
              />
            </template>
            <template #tooltip>
              <span v-if="selected">{{
                $t("documentSearch.alreadySelected")
              }}</span>
              <span v-else-if="disabledForFlows">
                {{ $t("documentSearch.disabledForFlows") }}
              </span>
              <span v-else-if="disabledForSpaces">
                {{ $t("documentSearch.notShareable") }}
              </span>
            </template>
          </Tooltip>
        </template>

        <template v-else>
          <input
            v-if="singleSelect"
            type="radio"
            :id="document.id"
            :value="document.id"
            :checked="selected"
          />

          <input
            v-else
            type="checkbox"
            :id="document.id"
            :value="document.id"
            :checked="selected"
          />
        </template>
      </div>
    </td>

    <td class="thumbnail">
      <div class="thumbnail_container" v-if="document.thumbnailDownloadUrl">
        <img
          :src="getStorageProxyUrl(document.thumbnailDownloadUrl)"
          :alt="document.displayTitle"
          class="thumbnail_image"
        />
      </div>

      <span v-else class="material-symbols-outlined"> image </span>
    </td>

    <td class="delayed">
      <div>
        <Tooltip class="left">
          <template v-slot>
            <div class="tooltip_text">
              <span v-if="match" v-html="match"></span>
              <span v-else>
                {{ document.displayTitle }}
              </span>
            </div>
          </template>
          <template #tooltip>
            {{ document.displayTitle }}
          </template>
        </Tooltip>
      </div>
      <div class="info">
        <template v-if="MimeType.isVideo(document.mimeType)">
          <span>{{ Formatter.formatDuration(document.pages) }}</span>
        </template>
        <template v-else>
          <span>{{
            $t("documentSearch.document.numPages", { count: document.pages })
          }}</span>
        </template>
        <span> - </span>
        <span>
          {{
            MimeType.getName(
              document.originalMimeType
                ? document.originalMimeType
                : document.mimeType
            )
          }}
        </span>
      </div>
    </td>
  </tr>
</template>

<style scoped lang="scss">
$checkbox-width: 30px;
$color-secondary: #34b5ba;

table tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #f4f4f4;
  padding: 0.3em 0.3em 0.3em 0.3em;
  text-align: left;
  font-style: normal;
}

table tr td:first-child {
  width: $checkbox-width;
  text-align: center;
}

table tr td.thumbnail {
  width: 70px;
}

.thumbnail_container {
  height: 60px;
  width: 60px;
}

table tr td.thumbnail .thumbnail_image {
  height: 60px;
  width: 60px;
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
}

table tr td.thumbnail .material-symbols-outlined {
  color: #555555;
}

input[type="checkbox"] {
  vertical-align: middle;
}

input[type="radio"] {
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid #555555;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}

input[type="radio"]:checked {
  background-color: $color-secondary;
}

.item.disabled input,
.item.disabled :deep(input),
.item.disabled .thumbnail,
.item.disabled :deep(.tooltip_text),
.item.disabled .info {
  opacity: 0.5;
}

.item.disabled :deep(.tooltip) {
  opacity: 1;
}

.item.disabled:active {
  pointer-events: none;
}

.item.disabled:hover {
  cursor: initial;
}

.info + .info {
  margin-top: 0.5em;
}

.info {
  color: rgba(82, 74, 62, 0.82);
  font-size: 0.9em;
}

.tooltip_text {
  text-align: left; // left align title
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

td :deep(b) {
  font-weight: inherit;
  background-color: #deebff;
  display: inline-block;
}
</style>
