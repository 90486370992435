<template>
  <div id="login">
    <div class="page_content">
      <img class="login_img" src="@/assets/images/login_image.png" />

      <div class="login_container">
        <div class="login_header">{{ $t("login.header") }}</div>
        <div id="firebaseui-auth-container"></div>

        <a
          href="https://apps.apple.com/de/app/pitchview-projector/id1501699612"
        >
          <img class="badge" src="@/assets/app_store_badge.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as firebaseui from "firebaseui";
import { useRouter } from "vue-router";
import { compatApp } from "@/fire";
import firebase from "firebase/compat/app";
import { onMounted } from "vue";
import "firebaseui/dist/firebaseui.css";

const router = useRouter();

const config = {
  signInFlow: "popup",
  signInSuccessUrl: "/u",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    "apple.com",
    "microsoft.com",
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      buttonColor: "#34B5BA",
    },
  ],
  callbacks: {
    signInFailure: (error: any): Promise<void> => {
      console.log(error);
      //TODO handle error
      return Promise.resolve();
    },
    signInSuccessWithAuthResult: (
      authResult: firebase.auth.UserCredential,
      redirectUrl: string
    ): boolean => {
      const userInfo = authResult!.additionalUserInfo!;
      const user = authResult!.user!;

      // send verification mail
      if (userInfo.isNewUser && !user.emailVerified) {
        const settings = {
          url: "https://" + window.location.host + "/u",
        };

        user
          .sendEmailVerification(settings)
          .then(() => {
            router.push({ name: "EmailVerification" });
          })
          .catch((err) => {
            console.error(err);
            router.push({ name: "EmailVerification" });
          });

        return false;
      } else {
        return true;
      }
    },
  },
};

onMounted(() => {
  let ui = firebaseui.auth.AuthUI.getInstance();
  if (!ui) {
    ui = new firebaseui.auth.AuthUI(compatApp.auth());
  }
  try {
    ui.start("#firebaseui-auth-container", config);
  } catch (err) {
    console.error(err);
  }
});
</script>

<style lang="scss" scoped>
#login {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.page_content {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  height: 100vh;
  width: 100vw;
}

img.login_img {
  width: 400px;
  height: 233px;
  margin-right: 80px;
}

@media (max-width: 900px) {
  img.login_img {
    display: none;
  }
}

.login_container {
  width: 350px;
}

.login_header {
  text-align: left;
  color: #363636;
  padding: 0 24px; /* adjust to firebaseui container */
}

.badge {
  height: 40px;
  margin: 20px 24px 0 24px;
}
</style>

<style>
.firebaseui-idp-button {
  max-width: none;
}
</style>
