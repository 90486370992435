<script lang="ts" setup>
import { ref } from "vue";

const active = ref(true);
const close = () => {
  active.value = false;
};
</script>

<template>
    <div class="overlay_content" v-if="active">
      <h3>{{ $t("appPromotionOverlay.header") }}</h3>

      <div class="row">
        <img class="app_icon" src="@/assets/app_icon.jpg" />
        Pitchview App
        <a
          class="button primary"
          href="https://apps.apple.com/de/app/pitchview-projector/id1501699612"
        >
          {{ $t("appPromotionOverlay.openApp.buttonText") }}
        </a>
      </div>
      <div class="row">
        <span class="app_icon"></span>
        Browser
        <button @click="close" class="button">
          {{ $t("appPromotionOverlay.stay.buttonText") }}
        </button>
      </div>
    </div>
</template>

<style scoped lang="scss">

.overlay_content {
  background-color: white;
  padding: 20px 20px 50px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  max-width: 100%;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #363636;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
}

.row {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1em;
}

.app_icon {
  width: 40px;
  height: 40px;
  margin-right: 0.5em;
  display: inline-block;
}

.row .button {
  margin-left: auto;
}
</style>
