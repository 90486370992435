<template>
  <div class="thumbnail">
    <img v-show="isLoaded" @load="isLoaded = true" ref="img" />
    <div class="loader_container" v-if="!isLoaded">
      <LoadingSpinner :color="'grey'" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { HTTP } from "@/util";
import { token } from "@/init";
import { LoadingSpinner } from "@/components";

const props = defineProps({
  pageId: {
    type: String,
    required: false,
  },
});

const isLoaded = ref(false);

const img = ref();

const loadImg = async () => {
  const headers = new Map<string, string>();
  headers.set("Authorization", `Bearer ${token.value!}`);

  const res = await HTTP.get(
    `/backend/thumbnails/page/${props.pageId}`,
    headers
  );

  const blob = await res.blob();
  img.value.src = URL.createObjectURL(blob);
};

watch(
  props,
  () => {
    if (props.pageId) {
      loadImg();
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
}
</style>
