<script lang="ts" setup>
import { Modal, FlashMessage } from "@/components";
import { useForm, useField } from "vee-validate";
import { string } from "yup";
import { onBeforeUnmount, ref, computed, PropType } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  useCreateDocumentSpaceVoucherMutation,
  CrmContact,
} from "@/graphql/operations";

const store = useStore();
const user = store.getters.user;
const { t } = useI18n();

const props = defineProps({
  documentSpaceId: {
    type: String,
    required: true,
  },
  crmContact: {
    type: Object as PropType<CrmContact | undefined | null>,
    required: false,
  },
});

const modalOpen = ref<boolean>(true);

const { executeMutation: createVoucher } =
  useCreateDocumentSpaceVoucherMutation();

const generateLink = async () => {
  const result = await createVoucher({ id: props.documentSpaceId });
  const urlSegment =
    result.data?.pvCreateVoucherForDocumentSpace?.result?.urlSegment;

  let urlPrefix = store.getters.user.mergedConfig.shared_space_url_prefix;
  if (!urlPrefix || urlPrefix.length === 0) {
    urlPrefix = `${window.location.origin}/2/`;
  }

  const url = `${urlPrefix}${urlSegment}`;
  return url;
};

const emit = defineEmits(["close", "success", "error"]);
const emitClose = () => emit("close");
const emitSuccess = () => emit("success");
const emitError = () => emit("error");

const submit = async () => {
  displayErrorMessage.value = false;

  const validationResult = await validate();
  if (!validationResult.valid) {
    return;
  }

  const spaceLink = await generateLink();

  const url = `/backend/microsoft/signin?to=${encodeURIComponent(
    toAddress.value
  )}&subject=${encodeURIComponent(subject.value)}&body=${encodeURIComponent(
    body.value
  )}&link=${encodeURIComponent(spaceLink)}&platform=web`;
  window.open(url, "_blank");
};

const { validate, errors } = useForm();
const { value: toAddress, resetField: resetToAddress } = useField<string>(
  "toAddress",
  string().email().required(),
  { initialValue: props.crmContact?.email ?? "" }
);

const { value: subject, resetField: resetSubject } = useField<string>(
  "subject",
  string().required(),
  {
    initialValue:
      user.mergedConfig?.default_email_subject ??
      t("documentSpace.mail.subject.default"),
  }
);

const { value: body, resetField: resetBody } = useField<string>(
  "body",
  string().required(),
  {
    initialValue:
      user.mergedConfig?.default_email_body ??
      t("documentSpace.mail.body.default"),
  }
);

const flashMessageActive = ref<boolean>(false);
const displayFlashMessage = () => {
  flashMessageActive.value = true;
  window.setTimeout(() => {
    flashMessageActive.value = false;
    emitClose();
  }, 4000);
};

const displayErrorMessage = ref<boolean>(false);
const handleMessage = (event: MessageEvent) => {
  if (event.data.emailSent) {
    modalOpen.value = false;
    displayFlashMessage();
  } else {
    displayErrorMessage.value = true;
  }
};

window.addEventListener("message", handleMessage, false);
onBeforeUnmount(() => {
  window.removeEventListener("message", handleMessage, false);
});
</script>

<template>
  <div>
    <FlashMessage
      v-if="flashMessageActive"
      :state="'success'"
      :message="'Email was successfully sent'"
    />
    <modal
      :active="modalOpen"
      @close="emitClose"
      :scrolling="true"
      class="large"
    >
      <template #header> Send Mail </template>

      <template #body>
        <div class="body_container">
          <form>
            <label>
              {{ $t("documentSpace.mail.recipient.label") }}
              <span class="error" v-if="errors.toAddress">{{
                $t("documentSpace.mail.recipient.error")
              }}</span>
            </label>
            <input
              name="toAddress"
              :class="{ input: true, invalid: errors.toAddress }"
              v-model="toAddress"
              type="email"
              ref="input_to_address"
            />

            <label>
              {{ $t("documentSpace.mail.subject.label") }}
              <span class="error" v-if="errors.subject">{{
                $t("documentSpace.mail.subject.error")
              }}</span>
            </label>
            <input
              name="subject"
              :class="{ input: true, invalid: errors.subject }"
              v-model="subject"
              type="text"
              ref="input_subject"
            />

            <label>
              {{ $t("documentSpace.mail.body.label") }}
              <span class="error" v-if="errors.body">{{
                $t("documentSpace.mail.body.error")
              }}</span>
            </label>
            <textarea
              rows="5"
              name="body"
              :class="{ input: true, invalid: errors.body }"
              v-model="body"
              type="text"
              ref="input_body"
            ></textarea>
          </form>

          <div
            :class="{
              message: true,
              error: true,
              hidden: !displayErrorMessage,
            }"
          >
            {{ $t("documentSpace.mail.error") }}
          </div>
        </div>
      </template>

      <template #footer>
        <div class="buttons">
          <button :class="{ button: true, primary: true }" @click="submit">
            {{ $t("submit") }}
          </button>
          <button class="button secondary" @click="emitClose">
            {{ $t("cancel") }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style scoped>
label {
  line-height: 1.5em;
}

input {
  margin-bottom: 1em;
}

textarea {
  height: auto;
}

.message {
  margin-top: 1em;
}

.message.hidden {
  display: none;
}

.info {
  margin-top: 0.5em;
  font-size: 0.9em;
}

label .error {
  color: #cc0f35;
  margin-right: 1em;
}
</style>
