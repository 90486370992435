<script lang="ts" setup>
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  useCustomFlowsQuery,
  useDeleteCustomFlowMutation,
} from "@/graphql/operations";
import { Dropdown, FlashMessage, LoadingSpinner } from "@/components";
import DeleteCustomFlowModal from "./DeleteCustomFlowModal.vue";
import CreateCustomFlowModal from "./CreateCustomFlowModal.vue";

const router = useRouter();
const { t } = useI18n({});

const emits = defineEmits(["contentLoaded"]);

const flashMessageActive = ref<boolean>(false);
let flashMessageTimer: number;

function showFlashMessage() {
  clearTimeout(flashMessageTimer);
  flashMessageActive.value = true;

  flashMessageTimer = window.setTimeout(() => {
    flashMessageActive.value = false;
  }, 3000);
}

const { data, fetching, executeQuery } = useCustomFlowsQuery({
  requestPolicy: "cache-and-network",
});

watch(data, (newData, oldData) => {
  if (newData && !oldData) {
    emits("contentLoaded");
  }
}, { immediate: true });

const flows = computed(() => data.value?.flows?.nodes);

const contextMenuItems = [{ value: "delete", title: t("customFlows.delete") }];

const showDeleteModal = ref<boolean>(false);
const flowIdToBeDeleted = ref<string | null>(null);
const displayDeleteConfirmation = (id: string) => {
  showDeleteModal.value = true;
  flowIdToBeDeleted.value = id;
};

const handleAction = (flowId: string, action: string) => {
  if (action === "delete") {
    displayDeleteConfirmation(flowId);
  }
};

const { executeMutation: executeDelete } = useDeleteCustomFlowMutation();
const deleteFlow = async () => {
  if (!flowIdToBeDeleted.value) {
    return;
  }

  await executeDelete({ id: flowIdToBeDeleted.value! });

  showDeleteModal.value = false;
  flowIdToBeDeleted.value = null;

  showFlashMessage();
};

const showCreateModal = ref<boolean>(false);
const displayCreateModal = () => {
  showCreateModal.value = true;
};

const redirect = (id: string) => {
  router.push({ name: "CustomFlow", params: { id: id } });
};

const intervalId = window.setInterval(() => executeQuery(), 5000);
onBeforeUnmount(() => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }
});
</script>

<template>
  <!-- delete success -->
  <flash-message v-if="flashMessageActive" state="success">
    {{ $t("customFlows.deleteConfirmation.success") }}
  </flash-message>

  <div class="pane page_content">
    <div class="title_container">
      <h3>{{ $t("customFlows.title") }}</h3>

      <div class="control_container">
        <button
          v-if="flows && flows.length > 0"
          class="button primary"
          @click="displayCreateModal"
          data-test="create-flow-ctrl"
        >
          <span class="material-symbols-outlined">add</span>
          {{ $t("customFlows.createNew") }}
        </button>
      </div>
    </div>

    <div v-if="!flows && fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-else-if="!flows || flows.length === 0" class="empty_pane">
      <div>
        <span class="material-symbols-outlined"> post_add </span>

        <h2>{{ $t("customFlows.emptyState.title") }}</h2>
        <p class="info">
          {{ $t("customFlows.emptyState.info") }}
        </p>
        <button class="button primary" @click="displayCreateModal">
          {{ $t("customFlows.createNew") }}
        </button>
      </div>
    </div>

    <div v-else>
      <table class="data_table clickable">
        <thead>
          <th>{{ $t("customFlows.columns.title") }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="flow in flows" :key="flow!.id">
            <td>
              <router-link
                :to="{ name: 'CustomFlow', params: { id: flow!.id } }"
              >
                {{ flow!.title }}
              </router-link>
            </td>

            <td>
              <Dropdown
                class="right"
                :items="contextMenuItems"
                @change="(action: string) => handleAction(flow!.id, action)"
              >
                <span class="material-symbols-outlined">more_vert</span>
              </Dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-if="showDeleteModal">
      <delete-custom-flow-modal
        :active="showDeleteModal"
        :flowId="flowIdToBeDeleted!"
        :title="flows!.find(flow => flow!.id === flowIdToBeDeleted)!.title"
        @close="showDeleteModal = false"
        @success="deleteFlow"
      />
    </template>

    <template v-if="showCreateModal">
      <create-custom-flow-modal
        :active="showCreateModal"
        @close="showCreateModal = false"
        @success="redirect"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
button {
  font-size: 0.9em;
}

table.data_table thead th:first-child {
  padding-left: 0;
}

.pane {
  height: 100%;

  .page_content {
    height: 100%;
  }
}

.loading_status {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_container {
  display: flex;

  h3 {
    font-size: 1.1rem;
    font-weight: 500;
    flex: 1;
  }

  .control_container {
    display: flex;
    align-items: center;

    .button {
      margin-left: 1.5em;
    }
  }
}

table {
  &.data_table {
    box-shadow: none;
  }

  a {
    color: #444;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  thead tr th:last-child,
  tbody tr td:last-child {
    width: 30px;
  }

  td .material-symbols-outlined {
    font-size: 1.3em;
    color: #34b5ba;
    border: 1px solid #34b5ba;
    border-radius: 50%;
    padding: 0.2em;
  }
}

td a {
  display: block;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.empty_pane {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;

  .material-symbols-outlined {
    font-size: 6em;
    opacity: 0.4;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    margin: 0.6em 0 1.5em 0;
  }

  > div {
    flex: none;
    text-align: center;
    margin-top: 5em;
  }
}
</style>
