<template>
  <div>
    <div class="row">
      <div class="tags_container">
        <div v-for="tag in selectedTags" :key="tag" class="tag_item removable">
          {{ tag }}

          <button class="delete" @click="removeTag(tag)"></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="container">
        <input
          type="text"
          class="input"
          :placeholder="$t('pvDocTags.addTag.placeholder')"
          @focus.stop="displayTagSearch"
          @click.stop
          v-model="search"
        />
      </div>

      <div class="search_result" v-if="searchActive">
        <ul v-if="tagAlreadySelected">
          <li>
            <span class="info">{{ $t("pvDocTags.tagAlreadySelected") }}</span>
          </li>
        </ul>

        <ul v-else>
          <li v-for="(tag, i) in tags" :key="i">
            <div class="tag_item" @click="addTag(tag as string)">
              {{ tag }}
            </div>
          </li>

          <li v-if="displayNewTag" @click="addNewTag" class="action_row">
            <div class="inner">
              <label>{{ $t("pvDocTags.createTag.label") }}</label>
              <div class="tag_item">{{ search }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, PropType, ref } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import { useAllTagsQuery } from "@/graphql/operations";

const props = defineProps({
  tags: {
    type: Array as PropType<Array<string>>,
    required: true,
  },
});

const { data } = useAllTagsQuery({
  requestPolicy: "network-only"
});
const allTags = computed(() => data.value?.alreadyUsedTagsList);

const selectedTags = ref<Array<string>>(props.tags);
const emit = defineEmits(["change"]);

const store = useStore();

const search = ref<string>("");
const searchActive = ref<boolean>(false);

const closeSearch = () => {
  searchActive.value = false;
};

window.addEventListener("click", closeSearch);
onBeforeUnmount(() => {
  window.removeEventListener("click", closeSearch);
});

const tags = computed(() => {
  if (!allTags.value) {
    return [];
  }

  return allTags.value.filter(
    (t: string | null) =>
      t &&
      !selectedTags.value.includes(t) &&
      t.toLowerCase().indexOf(search.value.toLowerCase()) > -1
  );
});

const addTag = (tag: string) => {
  const t = tag.trim();

  if (!selectedTags.value.includes(t)) {
    selectedTags.value.push(t);
  }

  searchActive.value = false;
  emit("change", selectedTags.value);
  search.value = "";
};

const removeTag = (tag: string) => {
  selectedTags.value.splice(props.tags.indexOf(tag), 1);
  emit("change", selectedTags.value);
};

const addNewTag = async () => {
  addTag(search.value);
  search.value = "";
};

const displayTagSearch = () => {
  searchActive.value = true;
};

const tagAlreadySelected = computed(() =>
  selectedTags.value.includes(search.value)
);

const displayNewTag = computed(
  () =>
    search.value.length > 0 &&
    (!allTags.value || !allTags.value.includes(search.value))
);
</script>

<style scoped>
.row {
  margin-top: 0.5em;
}

.tags_container {
  overflow: auto;
}

.tag_item {
  border-radius: 5px;
  line-height: 2.2em;
  padding: 0 8px;
  margin: 0 5px 5px 0;
  font-size: 0.85rem;
  background: #f5f5f5;
  display: inline-block;
}

.tag_item button {
  margin-left: 0.25em;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 9999px;
  outline: 0;
  height: 1rem;
  width: 1rem;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.tag_item button::before {
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
  height: 2px;
  width: 50%;
}

.tag_item button::after {
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
  height: 50%;
  width: 2px;
}

.tags_container .tag_item button:hover {
  cursor: pointer;
  background-color: rgba(10, 10, 10, 0.3);
}

.tags_container .tag_item .material-symbols-outlined {
  font-size: 1em;
  vertical-align: middle;
  cursor: pointer;
}

.tags_container .control input {
  width: 100%;
  max-width: 100%;
  padding-right: 2em;
}

.tags_container .control .icon {
  position: absolute;
  font-size: 1.2em;
  line-height: 30px;
  padding: 0 3px;
  right: 0;
  cursor: pointer;
}

ul {
  margin: 3px;
  padding: 0.5em 0;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

ul li {
  list-style-type: none;
  cursor: pointer;
  line-height: 2em;
  padding: 0.2em 1em;
}

ul li.action_row {
  margin-top: 0.5em;
}

ul li.action_row .inner {
  border-top: 1px solid #cecece;
  padding-top: 0.5em;
}

ul li.action_row label {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 0.8em;
}

ul li .info {
  font-style: italic;
}
</style>
