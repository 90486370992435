<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Dropdown, Thumbnail, Tooltip } from "@/components";

const { t } = useI18n({});

const props = defineProps({
  pageId: {
    type: String,
    required: false,
  },
  videoId: {
    type: String,
    required: false,
  },
  videoThumbnail: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  documentTitle: {
    type: String,
    required: true,
  },
  isAppendix: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["delete", "present"]);

const dropdown_items = [
  { value: "delete", title: t("customFlows.detail.dropdown.delete") },
  { value: "present", title: t("customFlows.detail.dropdown.present") },
];

const handleAction = (pageId: string, pageNumber: number, action: string) => {
  if (action === "delete") {
    emits("delete", pageId);
  } else if (action === "present") {
    emits("present", pageNumber);
  }
};
</script>

<template>
  <div v-if="!isAppendix" class="page_wrapper">
    <Thumbnail v-if="pageId" :pageId="pageId" class="thumbnail" />

    <div v-else-if="videoId" class="thumbnail">
      <img :src="videoThumbnail" />
    </div>

    <span class="drag_icon material-symbols-outlined"> drag_handle </span>

    <Dropdown
      class="dot_menu"
      :items="dropdown_items"
      @change="(action: string) => handleAction(id, index, action)"
    >
      <span class="material-symbols-outlined">more_vert</span>
    </Dropdown>

    <span class="document_title" :title="documentTitle">
      {{ documentTitle }}
    </span>
  </div>
  <div v-else class="legal_appendix_page_wrapper">
    <Tooltip class="multiline">
      <template v-slot>
        <Thumbnail v-if="pageId" :pageId="pageId" class="thumbnail disabled" />

        <span class="material-symbols-outlined lock_icon"> lock </span>

        <span class="document_title" :title="documentTitle">
          {{ documentTitle }}
        </span>
      </template>
      <template #tooltip>
        {{ $t("customFlows.detail.legalAppendix.info") }}
      </template>
    </Tooltip>
  </div>
</template>

<style lang="scss" scoped>
$pageWidth: 335px;
$thumbnailWidth: 300px;
$primary: #35b6ba;
$accentLight: #3fe0f0;
$itemBackgroundColor: #f0f0f0;

.page_wrapper {
  position: relative;
  width: $pageWidth;
  height: $pageWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $itemBackgroundColor;

  .thumbnail {
    width: $thumbnailWidth;
    height: $thumbnailWidth;
    border-radius: 0.35em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    &:hover {
      cursor: grab;
    }

    &:deep img {
      max-width: $thumbnailWidth;
      max-height: $thumbnailWidth;
      object-fit: contain;
    }
  }

  .dot_menu {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    border-radius: 50%;
    padding: 4px;
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $primary;
      color: $primary;
      cursor: pointer;
    }
  }

  .drag_icon {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    font-size: 1.5em;
    cursor: grab;
    opacity: 0.6;
  }
}

.document_title {
  position: absolute;
  bottom: 0.5em;
  left: 20px;
  font-size: 0.9em;
  width: 90%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.legal_appendix_page_wrapper {
  position: relative;
  width: $pageWidth;
  height: $pageWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $itemBackgroundColor;
  opacity: 0.9;

  .thumbnail.disabled {
    width: $thumbnailWidth;
    height: $thumbnailWidth;
    border-radius: 0.35em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    &:deep img {
      max-width: $thumbnailWidth;
      max-height: $thumbnailWidth;
      object-fit: contain;
    }
  }

  .lock_icon {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 1.5em;
    user-select: none;
  }

  .document_title {
    width: 85%;
    &:hover {
      cursor: default;
    }
  }
}
</style>
