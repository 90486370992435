<script lang="ts" setup>
import { Modal } from "@/components";

const emits = defineEmits(["close", "submit"]);
</script>


<template>
  <modal :active="true" @close="emits('close')">
    <template #header>
      {{ $t("discardChanges.title") }}
    </template>

    <template #body>
      {{ $t("discardChanges.message") }}
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button" @click="emits('submit')">
          {{ $t("discardChanges.submit") }}
        </button>
        <button class="button primary" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </modal>
</template>