<script lang="ts" setup>
import { ref } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  aspectRatio: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["close", "submit"]);

const cropperElem = ref();

const dataURLToBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const submit = () => {
  if (!cropperElem.value) {
    console.error("no cropper ref");
    return;
  }

  const canvas = cropperElem.value?.getResult()?.canvas;
  if (canvas) {
    const croppedImage = canvas.toDataURL("image/jpeg");
    const blob = dataURLToBlob(croppedImage);
    emits("submit", blob, croppedImage);
  }
};

defineExpose({
  submit,
});
</script>

<template>
  <cropper
    :src="imageUrl"
    :stencil-props="{ aspectRatio: aspectRatio }"
    ref="cropperElem"
    class="cropper"
  />
</template>

<style scoped>
.cropper {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}
</style>
