<script lang="ts" setup>
import DocumentService from "@/document";
import { token } from "@/init";
import { HTTP } from "@/util";
import { PropType, ref } from "vue";
import { useStore } from "vuex";
import { PMDoc } from "./PromomatsDocument";
import { ProgressSpinner } from "@/components";
import { Modal } from "@/components";
import {
  useSendWorkspaceNotificationMutation,
  PvNotificationEventType,
} from "@/graphql/operations";
import Document from "./Document.vue";

const props = defineProps({
  selectedDocuments: {
    type: Object as PropType<PMDoc[]>,
    required: true,
  },
});

const selection = ref(props.selectedDocuments);

const emit = defineEmits(["close", "goBack"]);

const BASE_URL = import.meta.env.VUE_APP_BACKEND_URL || "/backend";
const store = useStore();

const state = ref<Map<Number, string>>(new Map());

const sendNotification = ref(true);

const importDoc = async (docId: Number) => {
  state.value.set(docId, "running");

  const fetchUrl = BASE_URL + "/veeva/import";
  const headers = new Map();
  headers.set("Authorization", `Bearer ${token.value}`);
  const response = await HTTP.post(
    fetchUrl,
    headers,
    JSON.stringify({ doc_id: docId })
  );

  const fileData = JSON.parse(response);

  const fileProps = {
    workspaceId: store.getters.currentWorkspace.id,
    storageId: fileData.storageId,
    thumbnailStorageId: fileData.thumbnailStorageId,
    thumbnailDownloadUrl: fileData.thumbnailDownloadUrl,
    downloadUrl: fileData.downloadUrl,
    fileName: fileData.fileName,
    topTitle: store.getters.currentWorkspace.name,
    displayTitle: fileData.displayTitle,
    pages: fileData.pageCount,
    fileSize: fileData.fileSize,
    mimeType: fileData.mimeType,
    originalMimeType: fileData.originalMimeType,
    externalLinkId: "",
    externalId: fileData.promomatsId.toString(),
    expirationDate: fileData.expirationDate,
  };

  const result = await DocumentService.create(fileProps);

  state.value.set(docId, "success");
};

const isRunning = ref(false);

const { executeMutation } = useSendWorkspaceNotificationMutation();
async function sendNotificationToWorkspaceUsers() {
  const workspace = store.getters.currentWorkspace;
  const bodyText = `Es wurden Dokumente im Workspace ${workspace.name} hinzugefügt`;

  const res = await executeMutation({
    eventType: PvNotificationEventType.DocUpdate,
    workspaceId: workspace.firebaseId,
    headerText: "Neue Dokumente",
    bodyText: bodyText,
  });
}

const importDocs = async () => {
  if (selection.value.length === 0) {
    return;
  }

  for (const doc of selection.value) {
    state.value.set(doc.id, "waiting");
  }

  isRunning.value = true;

  let tasks = [];

  for (let i = 0; i < selection.value.length; i++) {
    if (tasks.length === 5) {
      await Promise.all(tasks);
      tasks = [];
    }

    tasks.push(importDoc(selection.value[i].id));
  }

  if (tasks.length > 0) {
    await Promise.all(tasks);
  }

  if (sendNotification.value) {
    await sendNotificationToWorkspaceUsers();
  }

  emit("close");
};

const removeFromSelection = (docId: Number) => {
  selection.value = selection.value.filter((doc) => doc.id !== docId);
  if (selection.value.length === 0) {
    emit("goBack", selection.value);
  }
};
</script>

<template>
  <Modal
    :active="true"
    @close="emit('close')"
    class="huge"
    :closeDisabled="true"
    data-test="upload-modal"
  >
    <template v-slot:header>
      {{ $t("promomatsIntegration.title") }}
    </template>
    <template v-slot:body>
      <div class="content">
        <table class="data_table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <template v-if="isRunning">
                  {{ $t("promomatsIntegration.columns.status") }}
                </template>
                <template v-else>
                  {{ $t("promomatsIntegration.columns.remove") }}
                </template>
              </th>
            </tr>
          </thead>
          <tbody>
            <Document
              v-for="(doc, idx) in selection"
              :key="idx"
              :doc="doc"
              :isSelecteable="false"
            >
              <span v-if="state.get(doc.id) === 'running'">
                <progress-spinner />
              </span>
              <span v-else-if="state.get(doc.id) === 'success'">
                <span class="material-symbols-outlined success"> done </span>
              </span>
              <span v-else-if="state.get(doc.id) === 'error'">
                <span class="material-symbols-outlined error"> error </span>
              </span>
              <div v-else-if="state.get(doc.id) === 'error'" class="error">
                {{ $t(`upload.error.title`) }}:
              </div>
              <span v-else>
                <span
                  class="material-symbols-outlined clickable"
                  @click="removeFromSelection(doc.id)"
                >
                  close
                </span>
              </span>
            </Document>
          </tbody>
        </table>
      </div>
    </template>
    <template #footer>
      <div class="buttons">
        <template v-if="!isRunning">
          <div class="button left">
            <span
              class="material-symbols-outlined clickable"
              @click="emit('goBack', selection)"
            >
              arrow_back
            </span>
            {{ $t("promomatsIntegration.back") }}
          </div>
          <div class="checkbox_container right">
            <input type="checkbox" v-model="sendNotification" /><label>{{
              $t("upload.sendNotificationLabel")
            }}</label>
          </div>
          <button class="primary button" @click="importDocs">
            {{
              $t("promomatsIntegration.submit", {
                numDocs: selection.length,
              })
            }}
          </button>
        </template>

        <template v-else>
          <button class="button disabled">
            {{ $t("promomatsIntegration.isRunning.label") }}
          </button>
        </template>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
table.data_table {
  box-shadow: none;
  table-layout: fixed;
}

table tr th:last-child,
table tr td:last-child {
  text-align: center;
  width: 70px;
}

.content {
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 0.5em 1em 0 0;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.material-symbols-outlined.clickable:hover {
  cursor: pointer;
  opacity: 1;
}

.clickable.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.success {
  color: #48c78e;
}

.error {
  color: #9f3a38;
}

.header .material-symbols-outlined {
  margin-right: 0.5em;
}

.checkbox_container {
  display: flex;
  margin-right: 1em;
  align-items: center;
}

.checkbox_container label {
  margin-left: 0.5em;
}

.buttons label {
  font-size: 0.9em;
}
</style>
