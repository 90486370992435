<template>
  <flash-message state="info" v-if="isProcessing">
    {{ $t("documentPresenter.processing") }}
  </flash-message>

  <div ref="presenter" class="document-presenter" v-if="document">
    <div class="document-presenter__buttons-at-top">
      <button class="button" v-if="pageIsPopover" @click="closePopover()">
        <span class="material-symbols-outlined">close</span>
      </button>
      <slot name="buttonsAtTop" :pageIsPopover="pageIsPopover"></slot>
    </div>

    <!-- loading spinner -->
    <div
      class="document-presenter__spinner"
      v-if="!presenterLoaded || fetching"
    >
      <loading-spinner color="black" />
    </div>

    <div class="document-presenter__spinner" v-if="document.deletedAt">
      <p class="info">
        {{ $t("documentPresenter.documentDeleted") }}
      </p>
    </div>

    <!-- regular document view (one page at a time) -->
    <template v-if="!isGalleryView">
      <pdf-js-presenter
        style="grid-row: 1 / 3"
        v-if="document.mimeType === 'application/pdf'"
        class="document-presenter__pdf"
        :document="getStorageProxyUrl(document.downloadUrl)"
        :intercept-internal-link="handleMagicPages"
        v-model:page="currentPage"
        enable-annotations
        @rendered="presenterLoaded = true"
      />
      <video-presenter
        v-else
        class="document-presenter__video"
        :document="getStorageProxyUrl(document.downloadUrl)"
        :documentId="document.id"
        @rendered="presenterLoaded = true"
      />
      <div :class="{ internal_panel: true, active: isLinkPanelActive }">
        <button class="material-symbols-outlined-outlined close" @click="hideLinkPanel">
          close
        </button>
        <button
          v-for="(link, index) in internalLinksForCurrentPage"
          :key="index"
          @click="goToInternalLinkTargetPage(link.target)"
        >
          <s-v-g-image-wrapper :name="link.style" />
        </button>
      </div>
    </template>

    <!-- gallery view (all pages at once) -->
    <div v-show="isGalleryView" class="document-presenter__gallery">
      <document-gallery-view
        :visible="isGalleryView"
        :document="document"
        :currentPageNumber="currentPage"
        :scroll="galleryScrollPosition"
        @update:page="jumpToPage"
        @close-gallery="toggleShowGallery"
        @reopen-on-popover-close="manageReopenGalleryOnPopoverClose"
      />
    </div>

    <!-- controls at bottom -->
    <div class="document-presenter__pdf-controls">
      <template
        v-if="document.mimeType === 'application/pdf' && !pageIsPopover"
      >
        <button class="button" @click="previousPage()">
          <span class="material-symbols-outlined">arrow_left</span>
        </button>
        <div v-if="popOverPageStack.length > 0">
          {{ popOverPageStack[popOverPageStack.length - 1] }} /
          {{ document.numNormalPages }}
        </div>
        <div v-else>{{ currentPage }} / {{ document.numNormalPages }}</div>
        <button class="button" @click="nextPage()">
          <span class="material-symbols-outlined">arrow_right</span>
        </button>
      </template>
      <slot
        name="fullscreenButton"
        :isFullscreen="isFullscreen"
        :toggleFullscreen="toggleFullscreen"
      >
        <button class="button" @click="toggleFullscreen()">
          <tooltip v-if="isFullscreen">
            <template v-slot>
              <span class="material-symbols-outlined">close_fullscreen</span>
            </template>
            <template #tooltip>{{
              $t("documentPresenter.controls.closeFullscreen")
            }}</template>
          </tooltip>

          <tooltip v-else>
            <template v-slot>
              <span class="material-symbols-outlined">fullscreen</span>
            </template>
            <template #tooltip>{{
              $t("documentPresenter.controls.openFullscreen")
            }}</template>
          </tooltip>
        </button>
      </slot>
      <slot
        v-if="currentPage && !pageIsPopover"
        name="galleryButton"
        :toggleShowGallery="toggleShowGallery"
        :showGallery="isGalleryView"
      >
        <button class="button" @click="toggleShowGallery()">
          <tooltip v-if="isGalleryView">
            <template v-slot>
              <span class="material-symbols-outlined">crop_7_5</span>
            </template>
            <template #tooltip>{{
              $t("documentPresenter.controls.closeGallery")
            }}</template>
          </tooltip>

          <tooltip v-else>
            <template v-slot>
              <span class="material-symbols-outlined">apps</span>
            </template>
            <template #tooltip>{{
              $t("documentPresenter.controls.openGallery")
            }}</template>
          </tooltip>
        </button>
      </slot>
    </div>
  </div>
  <div v-else>{{ $t("documentPresenter.documentNotFound") }}</div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onBeforeUnmount } from "vue";
import {
  syncRef,
  useFullscreen,
  useVModel,
  whenever,
  onKeyStroke,
} from "@vueuse/core";
import {
  AnalyticsService,
  EventType,
  Events,
  getStorageProxyUrl,
  InternalLinksService,
  InternalLink,
} from "@/util";
import {
  VideoPresenter,
  LoadingSpinner,
  PdfJsPresenter,
  SVGImageWrapper,
  Tooltip,
  FlashMessage,
} from "@/components";
import {
  GetDocumentByFirestoreIdQuery,
  useGetDocumentByFirestoreIdQuery,
} from "@/graphql/operations";
import { DocumentGalleryView } from "@/components";

type Document = GetDocumentByFirestoreIdQuery["document"];
type MagicPage = NonNullable<
  NonNullable<Document>["documentRevisionByIdAndCurrentRevisionNumber"]
>["documentPagesByDocumentIdAndRevisionNumber"]["nodes"][0];

const props = withDefaults(
  defineProps<{
    documentId: string;
    fetching: boolean;
    page?: number;
  }>(),
  { page: 1 }
);

const emit = defineEmits<{
  (e: "update:page", page: number): void;
  (e: "update:document", document: Document): void;
  (e: "goToDocument", firestoreId: string): void;
}>();

let presenterLoaded = ref(false);

// handle page number
// ------------------
// initialize page from properties, if set
const pageFromParent = useVModel(props, "page");
let currentPage = ref(pageFromParent.value);

// sync current number with parent component
syncRef(currentPage, pageFromParent, { direction: "both" });

// fullscreen support
// ------------------
const presenter = ref<HTMLDivElement | null>(null);

const { isFullscreen, toggle: toggleFullscreen } = useFullscreen(presenter);

// fetch document and all derived data
// -----------------------------------
const { data: documentResult } = useGetDocumentByFirestoreIdQuery({
  variables: computed(() => ({ id: props.documentId })),
  requestPolicy: "cache-and-network",
});

const document = computed(() => documentResult.value?.document);
const unwatch = watch(document, (newDocument, oldDocument) => {
  if (newDocument) {
    AnalyticsService.logEvent(
      EventType.OpenDocument,
      `${newDocument.displayTitle} (${newDocument.fileName})`,
      JSON.stringify({
        id: newDocument.id,
        firestoreId: props.documentId,
      })
    );

    AnalyticsService.sendEvent(Events.OpenEntity, {
      document_id: newDocument.id,
    });

    internalLinks.value = InternalLinksService.parseInternalLinks(
      document.value
    );
    setInternalLinksForCurrentPage();
    unwatch();
  }
});

const allPages = computed(
  () =>
    document.value?.documentRevisionByIdAndCurrentRevisionNumber
      ?.documentPagesByDocumentIdAndRevisionNumber?.nodes
);

const magicPages = computed(
  () =>
    (allPages.value?.filter((p) => !!p) ?? []) as Array<NonNullable<MagicPage>>
);

const pageIsPopover = computed(() =>
  magicPages.value.some(
    (magicPage) =>
      magicPage.pageNumber == currentPage.value &&
      magicPage.pageType === "POPOVER"
  )
);

const popOverPageStack = ref([] as number[]);

whenever(document, (currentDocument) =>
  emit("update:document", currentDocument)
);

onKeyStroke("ArrowLeft", (e) => {
  if (document.value?.mimeType === "application/pdf" && !pageIsPopover.value) {
    previousPage();
  }
});

onKeyStroke("ArrowRight", (e) => {
  if (document.value?.mimeType === "application/pdf" && !pageIsPopover.value) {
    nextPage();
  }
});

function nextPage() {
  //numNormalPages can't be 0, thus we can check if it evaluates to true
  if (!document.value || !document.value.numNormalPages)
    throw new Error("number of legal pages is not defined (e.g. for videos)");
  currentPage.value = Math.min(
    document.value.numNormalPages,
    currentPage.value + 1
  );
}

function previousPage() {
  currentPage.value = Math.max(1, currentPage.value - 1);
}

function jumpToPage(page: number) {
  handleMagicPages(page, () => {
    currentPage.value = page;
  });
}

const internalLinks = ref<Array<InternalLink>>([]);
const internalLinksForCurrentPage = ref<Array<InternalLink>>([]);
const isLinkPanelActive = ref<boolean>(
  internalLinksForCurrentPage.value.length > 0
);

function hideLinkPanel(): void {
  isLinkPanelActive.value = false;
}

function setInternalLinksForCurrentPage(): void {
  internalLinksForCurrentPage.value = internalLinks.value.filter(
    (link) =>
      link.source === currentPage.value && link.target <= document.value!.pages
  );
  isLinkPanelActive.value = internalLinksForCurrentPage.value.length > 0;
}

function goToInternalLinkTargetPage(target: number) {
  if (!isLinkPanelActive.value) {
    throw new Error("cannot go to page without internal link");
  } else if (target > document.value!.pages) {
    throw new Error("cannot go to page that does not exist");
  }

  if (pageIsPopover.value) popOverPageStack.value.push(currentPage.value);

  handleMagicPages(target, () => {
    currentPage.value = target;
  });
}

watch(
  [currentPage, pageIsPopover],
  ([newPage, isPopover], [lastPage, wasPopover]) => {
    if (isPopover && !wasPopover) popOverPageStack.value.push(lastPage);
    setInternalLinksForCurrentPage();
  }
);

watch(
  [currentPage, allPages],
  ([newPage, newData], [oldPage, oldData]) => {
    if (!allPages.value) {
      return;
    }

    const newPageData = newData?.find((p) => p?.pageNumber == newPage);
    const oldPageData = oldData?.find((p) => p?.pageNumber == oldPage);

    if (newPageData?.id === oldPageData?.id) {
      return;
    }

    if (oldPageData) {
      if (newPageData && newPageData.pageType !== "NORMAL") {
      } else {
        AnalyticsService.sendEvent(Events.CloseEntity, {
          page_id: oldPageData.id,
          document_id: document.value!.id,
        });
      }
    }

    if (newPageData) {
      if (oldPageData && oldPageData.pageType !== "NORMAL") {
      } else {
        AnalyticsService.sendEvent(Events.OpenEntity, {
          page_id: newPageData.id,
          document_id: document.value!.id,
        });
      }
    }
  },
  { immediate: true }
);

function closePopover() {
  if (popOverPageStack.value.length === 0)
    throw new Error("no page to return to");
  currentPage.value = popOverPageStack.value.pop() as number;
  if (popOverPageStack.value.length === 0 && openGalleryOnPopoverClose.value) {
    toggleShowGallery();
    openGalleryOnPopoverClose.value = false;
  }
}

function handleMagicPages(toPage: number, proceed: () => void) {
  if (!document.value) return proceed();

  for (const magicPage of magicPages.value) {
    if (
      magicPage &&
      magicPage.pageType === "MAGIC_LINK" &&
      magicPage.linkedDocument?.firestoreId &&
      magicPage.pageNumber == toPage
    ) {
      return emit("goToDocument", magicPage.linkedDocument?.firestoreId);
    }
  }

  return proceed();
}

const isGalleryView = ref<boolean>(false);
const openGalleryOnPopoverClose = ref<boolean>(false);
const galleryScrollPosition = ref<number>(0);

const toggleShowGallery = () => {
  isGalleryView.value = !isGalleryView.value;
};

const manageReopenGalleryOnPopoverClose = (scroll: number) => {
  openGalleryOnPopoverClose.value = true;
  isGalleryView.value = false;
  galleryScrollPosition.value = scroll;
};

const isProcessing = computed(() => {
  const latestRevision = document.value?.latestRevision?.nodes;
  const currentRevision = document.value?.currentRevision;
  return (
    document.value?.mimeType === "application/pdf" &&
    ((latestRevision !== undefined &&
      currentRevision !== undefined &&
      currentRevision !== null &&
      latestRevision.length > 0 &&
      latestRevision[0]!.revisionNumber > currentRevision.revisionNumber) ||
      currentRevision?.pages.totalCount === 0)
  );
});

onBeforeUnmount(() => {
  const pageData = allPages.value?.find(
    (p) => p?.pageNumber == currentPage.value
  );

  if (document.value) {
    if (pageData) {
      AnalyticsService.sendEvent(Events.CloseEntity, {
        page_id: pageData.id,
        document_id: document.value.id,
      });
    }
    AnalyticsService.sendEvent(Events.CloseEntity, {
      document_id: document.value.id,
    });
  }
});
</script>

<style lang="scss" scoped>
.document-presenter {
  position: relative;
  height: 100%;
  display: grid;
  grid-template:
    [top-start] "top" min-content [top-end]
    [main-start] "main" 1fr [main-end]
    [bottom-start] "bottom" min-content [bottom-end]
    /
    [col-start] 1fr [col-end];
  background-color: #fafafa;
}

.document-presenter__spinner {
  grid-area: main;
  align-self: center;
  justify-self: center;
}

.document-presenter__video,
.document-presenter__pdf,
.document-presenter__gallery {
  justify-self: stretch;
  align-self: stretch;
  grid-area: top-start / col-start / main-end / col-end;
  //grid-area: main;
}

.document-presenter__pdf-controls,
.document-presenter__buttons-at-top {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  grid-area: bottom;

  button span {
    margin-right: 0px;
  }
}

.document-presenter__buttons-at-top {
  justify-self: flex-start;
  align-self: flex-start;
  display: inline-flex;
  justify-content: flex-start;
  //background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 10;
  grid-area: top;
}

.document-presenter__buttons-at-top :slotted(.button) {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.document-presenter__buttons-at-top:empty {
  background: none;
}

/* see https://css-tricks.com/inclusively-hidden/ */
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.presenter_state_switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1em;

  div {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 1em;
    z-index: 100;
    border: 1px solid #dbdbdb;
    border-radius: 0.35em;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      padding: 0;
      border: none;

      &:hover {
        background-color: #dbdbdb;
        cursor: pointer;
      }

      &:first-child {
        border-radius: 0.35em 0.35em 0 0;
        border-right: 1px solid #dbdbdb;
      }
    }
  }
}

.internal_panel {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  grid-area: main;
  justify-self: flex-start;
  align-self: center;
  margin-left: 1.2rem;
  z-index: 10;
  border: 1px solid #dbdbdb;
  border-radius: 0.35em;

  &.active {
    visibility: visible;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    padding: 0;
    border: none;
    border-bottom: 1px solid #dbdbdb;
    background-color: white;

    &:first-child {
      border-radius: 0.35em 0.35em 0 0;

      &:hover {
        border-radius: 0;
      }
    }

    &:last-child {
      border-radius: 0 0 0.35em 0.35em;
      border-bottom: none;
    }

    &:hover {
      background-color: #dbdbdb;
      cursor: pointer;
    }

    &.close:hover {
      color: #ff1975;
    }
  }
}

.presenter_wrap {
  height: 100%;

  .align_horizontally {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }
}
</style>
