<template>
  <div class="dropdown" ref="target">
    <div class="dropdown_trigger" @click.stop.prevent="toggle">
      <template v-if="displayTitle">
        <div class="dropdown_title">
          <span v-if="state.activeItem && !state.activeItem.reset">
            {{ state.activeItem.title }}
          </span>
          <span v-else>
            {{ placeholder }}
          </span>
          <span class="material-symbols-outlined">arrow_drop_down</span>
        </div>
      </template>
      <slot></slot>
    </div>

    <div class="dropdown_menu" :class="{ active: state.isActive }">
      <div class="dropdown_content">
        <a
          :class="{
            dropdown_item: true,
            active:
              state.activeItem &&
              !state.activeItem.reset &&
              state.activeItem.value === item.value,
          }"
          v-for="(item, index) of items"
          :key="index"
          @click.stop="select(item)"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onBeforeUnmount, PropType, watch, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

interface Item {
  value: string | null;
  title: string;
  reset?: boolean;
}

interface Props {
  items: Array<Item>;
  displayTitle: boolean;
  placeholder: string;
}

interface ComponentState {
  isActive: boolean;
  activeItem: Item | null;
}

const props = defineProps({
  items: {
    type: Array as PropType<Array<Item>>,
    required: true,
  },
  displayTitle: {
    type: Boolean,
    required: false,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
    default: "",
  },
  value: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(["change"]);
const target = ref();

const state: ComponentState = reactive({
  isActive: false,
  activeItem:
    props.value && props.items
      ? props.items.find((i: Item) => i.value === props.value) ?? null
      : null,
});

watch(
  () => props.value,
  (newVal, oldVal) => {
    state.activeItem =
      props.items.find((i: Item) => i.value === newVal) ?? null;
  }
);

function close() {
  state.isActive = false;
  state.activeItem =
    props.value && props.items
      ? props.items.find((i: Item) => i.value === props.value) ?? null
      : null;
}

function toggle() {
  state.isActive = !state.isActive;
}

function select(item: Item) {
  state.activeItem = item;
  close();
  emits("change", item.value, item.reset);
}

onClickOutside(target, (event) => {
  close();
});

window.addEventListener("pvdrag", close);

onBeforeUnmount(() => {
  window.removeEventListener("pvdrag", close);
});
</script>

<style lang="scss" scoped>
$border-strong: #d0d4d9;
.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  font-size: 0.9rem;
}

.dropdown_menu {
  display: none;
  left: 0;
  min-width: 7rem;
  padding-top: 4px;
  position: absolute;
  top: 80%;
  z-index: 20;
}

.dropdown.right_align .dropdown_menu {
  left: auto;
  right: 0;
}

.dropdown.right .dropdown_menu {
  left: auto;
  right: 0;
}

.dropdown.top .dropdown_menu {
  top: -180%;
}

.dropdown_trigger {
  cursor: pointer;
}

.dropdown_trigger .dropdown_title,
.dropdown_trigger::v-deep .dropdown_title {
  padding: 0.35rem;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  padding: 0.35rem 1.2em;
  background-color: #fff;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  min-height: 1.5em;
  font-size: 1em;
  border-radius: 3px;
  border: 1px solid $border-strong;
  align-items: center;
}

.dropdown.inline .dropdown_trigger .dropdown_title {
  border: none;
}

.dropdown_trigger::v-deep .material-symbols-outlined {
  font-size: 1.3em;
  vertical-align: middle;
}

.dropdown_trigger::v-deep .material-symbols-outlined.circle {
  font-size: 1.3em;
  color: #34b5ba;
  border: 1px solid #34b5ba;
  border-radius: 50%;
  padding: 0.2em;
}
.dropdown_menu.active {
  display: block;
}

.dropdown_content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.dropdown_item {
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
}

.dropdown_item.active {
  color: #34b5ba;
}

a.dropdown_item {
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
</style>
