<script lang="ts" setup>
import { ref, PropType, computed } from "vue";
import { DocumentSpace } from "@/graphql/operations";
import AccessModalEdit from "./AccessModalEdit.vue";
import AccessModalAdd from "./AccessModalAdd.vue";

const visibleUserLimit = 3;

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const visibleUserCount = computed(() => {
  return props.documentSpace.documentSpaceAccesses.nodes.reduce(
    (accumulator, access) =>
      accumulator + (access?.displayContactDetails ? 1 : 0),
    0
  );
});

const state = ref<"edit" | "add">("edit");
</script>

<template>
  <access-modal-edit
    v-if="state === 'edit'"
    :documentSpace="props.documentSpace"
    :visibleUserCount="visibleUserCount"
    :visibleUserLimit="visibleUserLimit"
    @changeState="state = 'add'"
  />

  <access-modal-add
    v-else
    :documentSpace="props.documentSpace"
    :visibleUserCount="visibleUserCount"
    :visibleUserLimit="visibleUserLimit"
    @changeState="state = 'edit'"
  />
</template>
