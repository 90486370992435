<script lang="ts" setup>
import { ref, PropType, computed, watch } from "vue";
import {
  IconButton,
  Tooltip,
  Dropdown,
  UserAvatar,
  UpgradeModal,
} from "@/components";
import {
  useUpdateDocumentSpaceNameMutation,
  useDocumentSpaceConfigsQuery,
  useUpdateDocumentSpaceConfigMutation,
  DocumentSpace,
  useCloneDocumentSpaceMutation,
} from "@/graphql/operations";
import { useStore } from "vuex";
import DisableTrackingModal from "./DisableTrackingModal.vue";
import DeleteSpaceModal from "../DeleteSpaceModal.vue";
import AccessModal from "./access_modal/AccessModal.vue";
import CRMContactInfo from "./CRMContactInfo.vue";
import ShareMenu from "./ShareMenu.vue";
import CopySpaceModal from "../CopySpaceModal.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useSpaceModalDisplay } from "@/composables";

const {
  displayCopyDocumentSpaceModal,
  displayUpgradeModal,
  handleModalDisplay,
} = useSpaceModalDisplay();

const { t } = useI18n({});
const store = useStore();
const router = useRouter();
const user = store.getters.user;

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const name = ref<string>(props.documentSpace.name || "");
const currentName = ref<string>(props.documentSpace.name || "");

watch(props, () => {
  if (!props.documentSpace.name) {
    return;
  }

  if (props.documentSpace.name !== name.value) {
    if (currentName.value === name.value) {
      currentName.value = props.documentSpace.name;
    }
    name.value = props.documentSpace.name;
  }
});

const emit = defineEmits(["success", "refreshData"]);

const { data: spaceConfigData } = useDocumentSpaceConfigsQuery({});
const spaceConfigs = computed(() => {
  return spaceConfigData.value?.documentSpaceConfigs?.nodes.map(
    (config: any) => ({ value: config?.id, title: config?.name })
  );
});

const { executeMutation: executeUpdateConfig } =
  useUpdateDocumentSpaceConfigMutation();
const updateConfig = async (configId: string) => {
  await executeUpdateConfig({
    id: props.documentSpace.id,
    configId: configId,
  });
};

const crmContact = computed(
  () => props.documentSpace.documentSpaceCrmMapping?.crmContact
);
const hasCRMMapping = computed(
  () => crmContact.value !== null && crmContact.value !== undefined
);

const validateName = (value: string, callback: Function) => {
  const isValid = value.length > 0;
  callback(isValid);
  if (!isValid) {
    currentName.value = props.documentSpace.name ?? "";
    name.value = props.documentSpace.name ?? "";
  }
};

const { executeMutation: executeUpdateName } =
  useUpdateDocumentSpaceNameMutation();
const updateName = async (newName: string) => {
  await executeUpdateName({
    id: props.documentSpace.id,
    name: newName,
  });
  emit("success");
};

const redirectDocumentSpaces = () => {
  router.push({ name: "DocumentSpaces" });
};
const deleteConfirmationActive = ref<boolean>(false);
const showDeleteConfirmation = () => (deleteConfirmationActive.value = true);
const closeDeleteConfirmation = () => (deleteConfirmationActive.value = false);

const canDisableSpaceTracking = computed(() =>
  store.getters.user.canDisableSpaceTracking()
);

const displayDisableTrackingModal = ref<boolean>(false);

const myAccess = computed(() =>
  props.documentSpace.documentSpaceAccesses.nodes.find(
    (a) => a?.user?.id === store.getters.user.postgresId
  )
);

const actionItems = computed(() => {
  const i = [];

  i.push({
    value: "copy",
    title: t("documentSpace.copy.buttonText"),
  });

  if (canDisableSpaceTracking.value) {
    i.push({
      value: "disableTracking",
      title: t("documentSpace.disableTracking.buttonText"),
    });
  }

  if (myAccess.value?.role === "owner") {
    i.push({
      value: "delete",
      title: t("documentSpace.delete.buttonText"),
    });
  }

  return i;
});

const handleAction = async (action: string) => {
  switch (action) {
    case "delete":
      showDeleteConfirmation();
      break;

    case "disableTracking":
      displayDisableTrackingModal.value = true;
      break;

    case "copy":
      await handleModalDisplay("copy");
      //if (displayCopyDocumentSpaceModal.value) {
      break;
  }
};

const accessModalActive = ref<boolean>(false);
const displayAccessModal = () => {
  accessModalActive.value = true;
};

const crmContactInfoActive = ref<boolean>(false);
const displayCRMContactInfo = () => {
  crmContactInfoActive.value = true;
};
</script>

<template>
  <div class="controls">
    <div class="row">
      <template v-if="user.hasSpaceCollab()">
        <div class="access_box">
          <Tooltip
            class="bottom"
            v-for="access in documentSpace.documentSpaceAccesses.nodes"
            :key="access!.id"
          >
            <template v-slot>
              <user-avatar
                :name="access!.user!.displayName!"
                :email="access!.user!.email!"
                :profileImgUrl="access!.user!.userContactDetail?.profileImageUrl || undefined"
                :appearance="'subtle'"
                @click="displayAccessModal"
              >
              </user-avatar>
            </template>
            <template #tooltip>
              {{ access!.user!.displayName }}
            </template>
          </Tooltip>
        </div>
      </template>

      <div class="right">
        <div v-if="documentSpace.trackingDeactivated" class="control">
          <IconButton :icon="'location_disabled'" :disabled="true">
            {{ $t("documentSpace.disableTracking.textDeactivated") }}
          </IconButton>
        </div>

        <div class="control">
          <Dropdown
            :items="actionItems"
            @change="handleAction"
            class="right_align"
          >
            <div>
              <span class="material-symbols-outlined">more_vert</span>
            </div>
          </Dropdown>
        </div>

        <share-menu
          :documentSpace="documentSpace"
          @refreshData="emit('refreshData')"
        />
      </div>
    </div>
  </div>
  <div
    class="controls"
    v-if="(spaceConfigs && spaceConfigs.length > 1) || hasCRMMapping"
  >
    <div class="control" v-if="spaceConfigs && spaceConfigs.length > 1">
      <label>Template:</label>
      <Dropdown
        :items="spaceConfigs"
        :value="documentSpace.config?.id?.toString()"
        @change="updateConfig"
        :displayTitle="true"
      >
      </Dropdown>
    </div>

    <div class="control" v-if="crmContact">
      <label>Assigned To:</label>
      <span class="clickable" @click="displayCRMContactInfo">
        {{ crmContact!.firstName }} {{ crmContact!.lastName }}
      </span>
    </div>
  </div>

  <disable-tracking-modal
    :active="displayDisableTrackingModal"
    :documentSpaceId="documentSpace.id"
    @success="emit('success')"
    @close="displayDisableTrackingModal = false"
  />

  <delete-space-modal
    :active="deleteConfirmationActive"
    :space-id="documentSpace.id"
    @close="deleteConfirmationActive = false"
    @success="redirectDocumentSpaces"
  />

  <access-modal
    v-if="accessModalActive"
    :documentSpace="documentSpace"
    @close="accessModalActive = false"
    @refreshData="emit('refreshData')"
  />

  <CRMContactInfo
    v-if="crmContactInfoActive && crmContact"
    :crmContact="crmContact!"
    @close="crmContactInfoActive = false"
  />

  <upgrade-modal
    v-if="displayUpgradeModal"
    @close="displayUpgradeModal = false"
  />

  <CopySpaceModal
    v-if="displayCopyDocumentSpaceModal"
    @close="displayCopyDocumentSpaceModal = false"
    :sourceSpaceId="documentSpace.id"
  />
</template>

<style scoped lang="scss">
.controls {
  margin: 0 1em;
  padding: 1em 0;
  border-bottom: 1px solid #dbdbdb;
}

.button.right {
  float: right;
  margin-left: 5px;
}

.row {
  display: flex;
  align-items: center;
}

.row .right {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button .material-symbols-outlined {
  font-size: 1em;
  line-height: 1.5;
  vertical-align: middle;
  margin-right: 5px;
}

textarea {
  max-width: 100%;
  min-height: 10em;
  margin-bottom: 5px;
}

.info {
  text-align: center;
  font-style: italic;
}

.control label {
  display: inline-block;
  padding: 0.35rem 0;
  margin-right: 3px;
  line-height: 1.5;
  color: rgba(82, 74, 62, 0.82);
  width: 114px;
}

.control ::v-deep .dropdown_title {
  padding-left: 0;
}

.circle_hover {
  border-radius: 50%;
  padding: 0.25em;
}

.circle_hover:hover {
  background: #eee;
}

.access_box {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 10px;
}

.access_box .avatar {
  margin-right: -10px;
  cursor: pointer;
}

.dropdown::v-deep .dropdown_trigger .dropdown_title {
  border: none;
}
</style>
