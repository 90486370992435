<template>
  <div
    @click="emit('click')"
    :class="{
      card: true,
      highlighted: selected,
      isMagic: false,
    }"
  >
    <div class="thumbnail_container large">
      <thumbnail class="thumbnail_image" :pageId="page.id" />
    </div>

    <div class="footer">
      <span>
        {{ page.pageNumber }}
      </span>
      <span
        v-if="page.pageType === 'MAGIC_LINK'"
        class="material-symbols-outlined icon"
      >
        temp_preferences_custom
      </span>
      <span
        v-if="page.pageType === 'POPOVER'"
        class="material-symbols-outlined icon"
      >
        dynamic_feed
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PageConfigFragment } from "@/graphql/operations";
import { PropType, computed } from "vue";
import { Thumbnail } from "@/components";

const props = defineProps({
  page: {
    type: Object as PropType<PageConfigFragment>,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["click"]);
</script>

<style lang="scss" scoped>
$border-light: #ebebeb;
$border-strong: #cecece;
$color-primary: #db006e;
$color-secondary: #34b5ba;
$color-blue: #3273dc;
$background-grey: #fbfbfb;
$background-light-grey: #fafafa;

$card-height: 200px;
$card-width: 275px;

.card {
  height: $card-height;
  max-width: $card-width;
  border: 1px solid $border-strong;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
}

.card.highlighted {
  background-color: $background-grey;
  border: 2px solid #3273dc;
}

.card:hover {
  cursor: pointer;
  border: 1px solid $color-secondary;
  background-color: $background-grey;
}

.card.card.highlighted:hover {
  border: 2px solid $color-blue;
}

.footer {
  background: $background-light-grey;
  border-top: 1px solid $border-light;
  font-size: 0.9em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 1.8;
  padding: 0 0.3em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.footer .icon {
  float: right;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 100, "GRAD" -25, "opsz" 40;
}

.thumbnail_container.large {
  width: 100%;
  height: calc($card-height - 30px);
  text-align: center;
}

.thumbnail_image {
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

.thumbnail_container.large .thumbnail_image {
  border-radius: 0;
}
</style>
