<script lang="ts" setup>
import { PropType, computed, ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  useUpdateAccessMutation,
  type DocumentSpace,
  type UserContactDetail,
  type DocumentSpaceAccess,
} from "@/graphql/operations";
import { ContactDetails, Tooltip, UserAvatar } from "@/components";
import AccessDropdown from "./access_modal/AccessDropdown.vue";
import AccessModal from "./access_modal/AccessModal.vue";

const store = useStore();
const { t } = useI18n();

const emit = defineEmits(["refreshData", "success"]);

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const accessModalActive = ref<boolean>(false);

const myAccess: ComputedRef<DocumentSpaceAccess | undefined | null> = computed(
  () =>
    props.documentSpace.documentSpaceAccesses.nodes.find(
      (a) => a && a?.user?.id === store.getters.user.postgresId
    )
);

const visibleUsers = computed(() =>
  props.documentSpace.documentSpaceAccesses.nodes.filter(
    (a) =>
      a?.user?.id !== store.getters.user.postgresId && a?.displayContactDetails
  )
);

const visibleUserLimit = 3;

const items = computed(() => {
  const i = [];
  i.push({
    value: "hidden",
    title: t("documentSpace.access.hideDetails.title"),
    info: t("documentSpace.access.hideDetails.info"),
    displayCurrentSelection: true,
  });

  if (
    visibleUsers.value.length < visibleUserLimit ||
    myAccess.value?.displayContactDetails
  ) {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.info"),
      displayCurrentSelection: true,
    });
  } else {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.disabled.info"),
      disabled: true,
      displayCurrentSelection: true,
    });
  }

  return i;
});

const { executeMutation } = useUpdateAccessMutation();
const updateVisibility = async (value: string) => {
  if (!myAccess.value) {
    console.error("myAccess is null or undefined");
    return;
  }

  const displayContactDetails = value === "visible";
  const result = await executeMutation({
    id: myAccess.value.id,
    displayContactDetails: displayContactDetails,
  });

  if (result.error) {
    //TODO handle error;
    console.error(result.error);
    return;
  }

  emit("refreshData");
  emit("success");
};
</script>

<template>
  <div class="user_contact_details">
    <div class="controls">
      <access-dropdown
        class="right"
        :items="items"
        :value="myAccess?.displayContactDetails === true ? 'visible' : 'hidden'"
        :minimal="true"
        @change="updateVisibility"
      />

      <!---
      <Tooltip class="right">
        <template v-slot>
          <router-link :to="{ name: 'Profile' }">
            <span class="material-symbols-outlined"> edit </span>
          </router-link>
        </template>
        <template #tooltip>
          {{ $t("documentSpace.contactDetails.editLink") }}
        </template>
      </Tooltip> -->
    </div>

    <ContactDetails
      :contactDetails="myAccess!.user!.userContactDetail! as UserContactDetail"
    />

    <div v-if="visibleUsers.length > 0" class="more_users">
      <user-avatar
        v-for="access in visibleUsers"
        :key="access!.id"
        :appearance="'subtle'"
        :name="access!.user!.displayName!"
        :email="access!.user!.email!"
        :profileImgUrl="access!.user!.userContactDetail?.profileImageUrl || undefined"
      />

      <a class="link" @click="accessModalActive = true">
        {{
          $t("documentSpace.contactDetails.moreUsers", {
            count: visibleUsers.length,
          })
        }}
        <span class="material-symbols-outlined"> chevron_right </span>
      </a>
    </div>
  </div>

  <access-modal
    v-if="accessModalActive"
    :documentSpace="documentSpace"
    @close="accessModalActive = false"
    @refreshData="emit('refreshData')"
  />
</template>

<style scoped lang="scss">
.user_contact_details {
  margin: 1em;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.4;
  margin-bottom: 1em;
  flex: 1;
  display: flex;
  align-items: center;
}

.controls {
  margin-top: 20px;
  margin-bottom: -28px;
  display: flex;
  justify-content: flex-end;
}

.more_users {
  display: flex;
  align-items: center;
  margin-top: 1.5em;
  margin-left: 119px;
}

.more_users .link {
  color: inherit;
  margin-left: 0.5em;
}

.more_users .link .material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: middle;
}
</style>
