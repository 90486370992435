<template>
  <div
    class="avatar"
    :style="{ background: color }"
    :class="{ subtle: props.appearance == 'subtle' }"
  >
    <img :src="profileImgUrl" v-if="profileImgUrl" />
    <div class="img_placeholder" v-else>
      {{ initials }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { ColorUtil } from "@/util";

function getInitials(name: string) {
  const ucName = name.toUpperCase();
  const nameParts = ucName.split(" ");

  if (nameParts.length === 0) {
    return "";
  }

  let initials = "";

  const first = nameParts[0];
  if (first.length > 0) {
    initials += first[0];
  }

  const last = nameParts[nameParts.length - 1];
  if (nameParts.length > 1 && last.length > 0) {
    initials += last[0];
  }

  return initials;
}

const props = defineProps({
  name: {
    type: String,
  },
  email: {
    type: String,
  },
  profileImgUrl: {
    type: String,
    required: false,
  },
  appearance: {
    type: String,
    default: "bold", //subtle also possible
  },
});

const text = props.name ? props.name : props.email || "";
const color = computed(() =>
  props.appearance === "subtle" ? "" : ColorUtil.getIOSSystemColorForName(text)
);
const initials = computed(() => getInitials(text));
</script>

<style lang="scss" scoped>
.avatar {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 12px;
  display: inline-block;
}

.avatar.small {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: .7em;
}

.avatar.large {
  height: 96px;
  width: 96px;
  font-size: 2.5em;
  line-height: 96px;
}

.subtle {
  color: rgb(145, 145, 142);
  border: 1px solid rgba(227, 226, 224, 0.5);
  box-shadow: rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.img_placeholder,
img {
  display: inline-block;
  height: auto;
  width: 100%;
}

.img_placeholder {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
}
</style>
