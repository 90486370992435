const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 60000;
const MINUTES_PER_HOUR = 60;

export class DateUtil {

  static toLocale(date: Date) {
    const timestamp = date.getTime();
    const offset = date.getTimezoneOffset(); //difference between utc and local timezone in minutes
    return new Date(timestamp - (offset * MILLIS_PER_MINUTE));
  }

  static timestampWithMillis(date: Date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    return timestamp;
  }

}
