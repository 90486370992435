<script lang="ts" setup>
import { Modal } from "@/components";

const emits = defineEmits(["close"]);
</script>

<template>
  <Modal :active="true" @close="emits('close')">
    <template #header>
      {{ $t("documentSpace.missingConsent.title") }}
    </template>

    <template #body>
      {{ $t("documentSpace.missingConsent.message")}}
    </template>

    <template #footer>
      <div class="buttons">
      <button class="button" @click="emits('close')">
        {{ $t("ok") }}
      </button>
    </div>
    </template>
  </Modal>
</template>