<template>
  <flow-presenter :flow-id="flowId" v-model:page="pageNumber" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import FlowPresenter from "@/components/FlowPresenter.vue";

const router = useRouter();
const route = useRoute();
const pageNumber = computed({
  get: () => Number(route.query.page || 1),
  set(value) {
    router.replace({ query: { page: value.toString() } });
  },
});
const flowId = computed(() => route.params.id as string);
</script>
