<script lang="ts" setup>
import { UserAvatar, Dropdown } from "@/components";
import {
  DocumentSpaceAccess,
  useUpdateAccessMutation,
  useRemoveAccessMutation,
} from "@/graphql/operations";
import { PropType, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import AccessDropdown from "./AccessDropdown.vue";

const { t } = useI18n();

const props = defineProps({
  access: {
    type: Object as PropType<DocumentSpaceAccess>,
    required: true,
  },
  isCurrentUser: {
    type: Boolean,
    required: true,
  },
  visibleUserCount: {
    type: Number,
    required: true,
  },
  isOwner: {
    type: Boolean,
    required: true,
  },
  visibleUserLimit: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["update"]);

const items = computed(() => {
  const i = [];
  i.push({
    value: "hidden",
    title: t("documentSpace.access.hideDetails.title"),
    info: t("documentSpace.access.hideDetails.info"),
    displayCurrentSelection: true,
  });

  if (
    props.visibleUserCount < props.visibleUserLimit ||
    props.access.displayContactDetails
  ) {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.info"),
      displayCurrentSelection: true,
    });
  } else {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.disabled.info"),
      disabled: true,
      displayCurrentSelection: true,
    });
  }

  if (props.isOwner) {
    i.push({
      value: "transfer_ownership",
      title: t("documentSpace.access.transferOwnership.title"),
      newSection: true,
      displayCurrentSelection: false,
    });
  }

  if (props.access.role !== "owner") {
    i.push({
      value: "remove",
      title: t("documentSpace.access.removeAccess.title"),
      type: "danger",
      newSection: true,
      displayCurrentSelection: false,
    });
  }

  return i;
});
</script>

<template>
  <div class="row">
    <div class="left">
      <user-avatar
        :appearance="'subtle'"
        :name="access!.user!.displayName!"
        :email="access!.user!.email!"
        :profileImgUrl="access!.user!.userContactDetail?.profileImageUrl || undefined"
      />
    </div>
    <div>
      <div class="row_title">
        <b>
          {{ access!.user!.displayName }}
          <template v-if="isCurrentUser">
            ({{ $t("documentSpace.access.isCurrentUser") }})
          </template>
        </b>

        <span v-if="access.role === 'owner'" class="badge">
          {{ $t("documentSpace.access.owner.title") }}
        </span>
      </div>
      <div class="text_subtle">{{ access!.user!.email }}</div>
    </div>

    <div class="control large">
      <access-dropdown
        class="right"
        :items="items"
        :value="access!.displayContactDetails === true ? 'visible' : 'hidden'"
        @change="(value) => emits('update', value, access!)"
      />
    </div>
  </div>
</template>

<style scoped scss>
.row {
  padding: 0.6em 0;
  display: flex;
  align-items: center;
}

.row .left {
  margin-right: 1em;
}

.row_title {
  display: flex;
}

b {
  font-weight: 500;
}

.text_subtle {
  color: #736c64;
  font-size: 0.9em;
}

.dropdown {
  font-size: 0.9em;
}

.control {
  margin-left: auto;
  width: 15%;
}

.badge {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  color: rgb(207, 136, 7);
  background: rgba(246, 192, 80, 0.26);
  font-size: 0.7em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-left: 0.5em;
}
</style>
