<script lang="ts" setup>
import { PropType } from "vue";
import { Modal } from "@/components";
import { type Config } from "./Config";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["close"]);
</script>

<template>
  <Modal :active="true" @close="emits('close')" :scrolling="true">
    <template #header>
      {{ $t("templateEditor.settings.title") }}
    </template>

    <template #body> </template>

    <template #footer>
      <div class="buttons">
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped></style>
