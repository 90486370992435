<script lang="ts" setup>
import { ref, computed, PropType, watch } from "vue";
import { useStore } from "vuex";
import {
  useCreateDocumentSpaceVoucherMutation,
  DocumentSpace,
} from "@/graphql/operations";
import { MenuOverlay, Tooltip, CRMContactSelectionModal } from "@/components";
import { fetchCRMConsentStatus, Consent } from "@/util";
import MissingConsentModal from "./MissingConsentModal.vue";
import MailModal from "./MailModal.vue";

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const emit = defineEmits(["refreshData"]);

const store = useStore();
const user = store.getters.user;

const crmContact = computed(
  () => props.documentSpace.documentSpaceCrmMapping?.crmContact
);
const hasCRMMapping = computed(
  () => crmContact.value !== null && crmContact.value !== undefined
);

const hasDefaultSpacePrefix = computed(
  () => !!user.mergedConfig?.default_space_prefix?.length
);
const canSendSpaceMail = computed(() => store.getters.user.canSendSpaceMail());

const campaignLinks = computed(
  () => crmContact.value?.crmContactPhaseMappingsByContactId?.nodes
);

const shareButton = ref<HTMLElement | null>(null);
const shareMenuActive = ref<boolean>(false);
const toggleShareMenu = () => {
  shareMenuActive.value = !shareMenuActive.value;
};

const mailModalActive = ref<boolean>(false);
const displayMailModal = () => {
  mailModalActive.value = true;
  toggleShareMenu();
};

const { executeMutation: createVoucher } =
  useCreateDocumentSpaceVoucherMutation();

const generateLink = async (
  urlPrefix: string = `${window.location.origin}/2/`,
  linkId?: string
) => {
  const result = await createVoucher({
    id: props.documentSpace.id,
    campaignPhaseLinkId: linkId,
  });
  const urlSegment =
    result.data?.pvCreateVoucherForDocumentSpace?.result?.urlSegment;

  return `${urlPrefix}${urlSegment}`;
};

const openPreview = async (urlPrefix: string) => {
  const url = await generateLink(urlPrefix);
  window.open(`${url}preview`, "_blank")!.focus();
  toggleShareMenu();
};

const copyLink = async (urlPrefix: string, linkId?: string) => {
  toggleShareMenu();
  const url = await generateLink(urlPrefix, linkId);
  navigator.clipboard.writeText(url);

  shareButton.value!.setAttribute("data-tooltip", "Link Kopiert!");
  window.setTimeout(
    () => shareButton.value?.removeAttribute("data-tooltip"),
    1000
  );
};

const CRMContactSelectionModalActive = ref<boolean>(false);
const missingConsentModalActive = ref<boolean>(false);

const closeMissingConsentModal = async () => {
  missingConsentModalActive.value = false;
  await copyLink(user.mergedConfig?.shared_space_url_prefix);
};

const consentIsMissing = ref<boolean | null>(null);
const checkConsent = async () => {
  if (!store.getters.user.hasSignConsent() || !crmContact.value) {
    consentIsMissing.value = false;
    return;
  }

  try {
    const consentStatus = await fetchCRMConsentStatus(crmContact.value.id);
    consentIsMissing.value = consentStatus.permissionStatusEmail !== "Opt-in";
  } catch (e) {
    //TODO handle
  }
};

checkConsent();

watch(props, () => {
  checkConsent();
});
</script>

<template>
  <div class="share_menu">
    <button
      class="button primary right"
      @click="toggleShareMenu"
      ref="shareButton"
    >
      <span class="material-symbols-outlined"> ios_share </span>
      {{ $t("documentSpace.share.buttonText") }}
    </button>
  </div>

  <menu-overlay
    :active="shareMenuActive"
    :refElem="shareButton"
    @close="shareMenuActive = false"
  >
    <template #header> Share </template>

    <template #body>
      <!-- links for users with business card integration -->
      <template v-if="hasDefaultSpacePrefix">
        <div class="button_container">
          <button
            class="button share_button"
            @click="openPreview(user.mergedConfig?.default_space_prefix)"
          >
            <span class="material-symbols-outlined">open_in_new</span>
            {{ $t("documentSpace.preview.buttonText") }}
          </button>
        </div>
        <div class="button_container">
          <button
            class="button share_button"
            @click="openPreview(user.mergedConfig?.shared_space_url_prefix)"
          >
            <span class="material-symbols-outlined">open_in_new</span>
            {{ $t("documentSpace.preview.buttonTextBusinessCard") }}
          </button>
        </div>
        <div class="button_container">
          <button
            class="button share_button"
            @click="copyLink(user.mergedConfig?.default_space_prefix)"
          >
            <span class="material-symbols-outlined">content_copy</span>
            {{ $t("documentSpace.copyLink.buttonText") }}
          </button>
        </div>
        <div class="button_container">
          <button
            class="button share_button"
            @click="copyLink(user.mergedConfig?.shared_space_url_prefix)"
          >
            <span class="material-symbols-outlined">content_copy</span>
            {{ $t("documentSpace.copyLink.buttonTextBusinessCard") }}
          </button>
        </div>
      </template>

      <!-- links for users without business card integration -->
      <template v-else>
        <!-- preview link -->
        <div class="button_container">
          <button
            class="button share_button"
            @click="openPreview(user.mergedConfig?.shared_space_url_prefix)"
          >
            <span class="material-symbols-outlined">open_in_new</span>
            {{ $t("documentSpace.preview.buttonText") }}
          </button>
        </div>

        <!-- share buttons for users with crm integration -->
        <!-- campaigns and mail only work with crm integration -->
        <template v-if="user.hasCRMIntegration()">
          <!-- crm contact already assigned -->
          <template v-if="crmContact">
            <div class="menu_section">
              <div class="menu_title">
                {{ $t("documentSpace.crmMapping.title") }}
              </div>
              <button class="button share_button not_clickable">
                <span class="material-symbols-outlined"> account_circle </span>
                {{
                  $t("documentSpace.crmMapping.tooltipLabel.name", crmContact)
                }}
              </button>

              <Tooltip class="right" v-if="consentIsMissing">
                <template v-slot>
                  <p class="info">
                    <span class="material-symbols-outlined"> warning</span>
                    {{ $t("documentSpace.missingConsent.title") }}
                  </p>
                </template>
                <template #tooltip>
                  {{ $t("documentSpace.missingConsent.message") }}
                </template>
              </Tooltip>
            </div>

            <div class="menu_section">
              <div class="menu_title">share</div>
              <button
                class="button share_button"
                @click="copyLink(user.mergedConfig?.shared_space_url_prefix)"
              >
                <span class="material-symbols-outlined">content_copy</span>
                {{ $t("documentSpace.copyLink.buttonText") }}
              </button>

              <template v-for="phase in campaignLinks">
                <div
                  class="button_container"
                  v-for="link in phase?.phase?.campaignPhaseLinksByPhaseId
                    ?.nodes"
                  :key="link!.id"
                >
                  <button
                    class="button share_button"
                    @click="
                      copyLink(
                        user.mergedConfig?.shared_space_url_prefix,
                        link!.id
                      )
                    "
                  >
                    <span class="material-symbols-outlined"> share </span>
                    {{ link!.name }}
                  </button>
                </div>
              </template>

              <div class="button_container" v-if="canSendSpaceMail">
                <button class="button share_button" @click="displayMailModal">
                  <span class="material-symbols-outlined">mail</span>
                  {{ $t("documentSpace.sendMail.buttonText") }}
                </button>
              </div>
            </div>
          </template>

          <!-- needs crm contact selection -->
          <template v-else>
            <div class="menu_section">
              <div class="menu_title">
                {{ $t("documentSpace.crmMapping.title") }}
              </div>
              <Tooltip class="right">
                <template v-slot>
                  <button
                    class="button share_button"
                    @click="CRMContactSelectionModalActive = true"
                  >
                    <span class="material-symbols-outlined">
                      account_circle
                    </span>
                    {{ $t("documentSpace.crmMapping.button.text") }}
                  </button>
                </template>
                <template #tooltip>
                  {{ $t("documentSpace.crmMapping.button.info") }}
                </template>
              </Tooltip>
            </div>
          </template>
        </template>

        <!-- share link for users without crm integration -->
        <template v-else>
          <div class="button_container">
            <button
              class="button share_button"
              @click="copyLink(user.mergedConfig?.shared_space_url_prefix)"
            >
              <span class="material-symbols-outlined">content_copy</span>
              {{ $t("documentSpace.copyLink.buttonText") }}
            </button>
          </div>

          <div class="button_container" v-if="canSendSpaceMail">
            <button class="button share_button" @click="displayMailModal">
              <span class="material-symbols-outlined">mail</span>
              {{ $t("documentSpace.sendMail.buttonText") }}
            </button>
          </div>
        </template>
      </template>
    </template>
  </menu-overlay>

  <missing-consent-modal
    v-if="missingConsentModalActive"
    :crmContact="crmContact!"
    @close="closeMissingConsentModal"
  />

  <mail-modal
    v-if="mailModalActive"
    :documentSpaceId="documentSpace.id"
    :crmContact="crmContact! "
    @close="mailModalActive = false"
  />

  <CRMContactSelectionModal
    v-if="CRMContactSelectionModalActive"
    :documentSpaceId="documentSpace.id"
    :documentSpaceName="documentSpace.name as string"
    @success="emit('refreshData')"
    @close="CRMContactSelectionModalActive = false"
  />
</template>

<style scoped lang="scss">
.share_menu {
  margin-left: 1em;
}

.button_container {
  margin-top: 1em;
}

div .button_container:first-child {
  margin-top: 0;
}

.share_button {
  border: 1px solid #00000024;
  padding: 0.9em;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  font-weight: 600;
  min-width: 180px;
}

.share_button:hover {
  background-color: #f7f5f2;
}

.share_button.not_clickable,
.share_button.not_clickable:hover {
  background-color: white;
  pointer-events: none;
}

button[data-tooltip]::before {
  background: rgba(74, 74, 74, 0.9);
  border-radius: 2px;
  content: attr(data-tooltip);
  padding: 0.5rem 1rem;
  text-overflow: ellipsis;
  white-space: pre;
  left: 50%;
  top: 100%;
  margin-top: 7px;
  transform: translateX(-50%);
}

button[data-tooltip]::before,
button[data-tooltip]::after {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 0.75rem;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 1020;
}

button[data-tooltip]::after {
  content: "";
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent rgba(74, 74, 74, 0.9) transparent;
  left: 50%;
  top: calc(100% + 6px);
  transform: translateX(-50%);
}

button[data-tooltip] {
  overflow: visible;
  position: relative;
}

.menu_section {
  margin-top: 1em;
}

.menu_title {
  color: #9b9b9b;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.2em;
  font-size: 0.9em;
}
</style>
