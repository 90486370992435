<script lang="ts" setup>
const emits = defineEmits(["click"]);

const props = defineProps({
  type: {
    type: String,
  },
});
</script>

<template>
  <span v-if="type === 'contact-add'" class="dropdown_button green" @click="emits('click')">
    <span class="material-symbols-outlined"> person_add </span>
  </span>

  <span v-else-if="type === 'contact'" class="dropdown_button green" @click="emits('click')">
    <span class="text">
      <slot></slot>
    </span>
  </span>

  <span v-else-if="type === 'presentation'" class="dropdown_button green" @click="emits('click', 'presentation')">
    <span class="material-symbols-outlined"> live_tv </span>
    <span class="text">
      {{ $t("documentHistory.label.presentation.title") }}
    </span>
    <slot name="right"></slot>
  </span>

  <span v-else-if="type === 'self-study'" class="dropdown_button blue" @click="emits('click', 'self-study')">
    <span class="material-symbols-outlined"> psychology </span>
    <span class="text">
      {{ $t("documentHistory.label.self-study.title") }}
    </span>
    <slot name="right"></slot>
  </span>

  <span v-else class="dropdown_button grey">
    <span class="material-symbols-outlined"> question_mark </span>
    <span class="text">
      <span>
        {{ $t("documentHistory.label.default.title") }}
      </span>
      <span class="small_text">
        {{ $t("documentHistory.label.default.select") }}
      </span>
    </span>
    <slot name="right"></slot>
  </span>
</template>

<style scoped>
.dropdown_button {
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 8px;
  font-size: 14px;
  height: 45px;
  box-sizing: border-box;
}

.dropdown_button:hover {
  cursor: pointer;
}

.dropdown_button.grey {
  background-color: #ececec;
  color: #737373;
}

.dropdown_button.blue {
  background: #d6fafb;
  color: #363636;
}

.dropdown_button.green {
  background: #c5e9d0;
  color: #363636;
}

.dropdown_button .material-symbols-outlined {
  font-size: 1.2em;
}

.text {
  margin-left: 0.3em;
  margin-right: 0.3em;
  font-weight: 500;
}

.text span {
  display: block;
}

.small_text {
  font-size: 0.85em;
  font-weight: 400;
}
</style>
