<script lang="ts" setup>
import { computed, ref, watch, onBeforeUnmount } from "vue";
import { useProjector, AnalyticsService, Events } from "@/util";

const videoElement = ref<HTMLVideoElement>();
const progressElement = ref<HTMLProgressElement>();

enum VIDEO_STATE {
  LOADING = "LOADING",
  PLAYING = "PLAYING",
  SEEK = "SEEK",
  PAUSED = "PAUSED",
}

const currentState = ref<VIDEO_STATE>(VIDEO_STATE.LOADING);
const muted = ref<boolean>(false);

const props = defineProps({
  document: {
    type: String,
    required: true,
  },
  autoplay: {
    type: Boolean,
    required: false,
    default: true,
  },
  documentId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["rendered"]);

const play = () => {
  videoElement.value!.play();
  currentState.value = VIDEO_STATE.PLAYING;
};

const pause = () => {
  videoElement.value!.pause();
  currentState.value = VIDEO_STATE.PAUSED;
};

const ended = () => {
  currentState.value = VIDEO_STATE.PAUSED;
  AnalyticsService.sendEvent(Events.StopVideo, {
    document_id: props.documentId,
    video_position: currentTime.value
  });
};

const loadedData = () => {
  currentState.value = props.autoplay
    ? VIDEO_STATE.PLAYING
    : VIDEO_STATE.PAUSED;
  emit("rendered");
};

const duration = ref<number>(0);
const setDuration = () => {
  duration.value = videoElement.value!.duration;
};

const currentTime = ref<number>(0);
const setCurrentTime = () => {
  if (!videoElement.value) {
    return 0;
  }

  currentTime.value = videoElement.value!.currentTime;
};

const skipAhead = (ev: MouseEvent) => {
  const rect = progressElement.value!.getBoundingClientRect();
  const pos = (ev.pageX - rect.left) / progressElement.value!.offsetWidth;
  videoElement.value!.currentTime = pos * duration.value;
};

const { videoState } = useProjector();
watch(
  [() => currentState.value/*, () => currentTime.value*/],
  ([newState]) => {
    videoState.value = {
      state: VIDEO_STATE[newState],
      position: currentTime.value,
    };
  },
  { immediate: true } // This ensures the initial sync happens right away
);

const mute = () => {
  muted.value = true;
};

const unmute = () => {
  muted.value = false;
};

const trackPlay = () => {
  AnalyticsService.sendEvent(Events.PlayVideo, {
    video_position: currentTime.value,
    document_id: props.documentId,
  });
};

const trackPause = () => {
  AnalyticsService.sendEvent(Events.PauseVideo, {
    video_position: currentTime.value,
    document_id: props.documentId,
  });
};

const trackSeek = () => {
  AnalyticsService.sendEvent(Events.SeekVideo, {
    video_position: currentTime.value,
    document_id: props.documentId,
  });
};

onBeforeUnmount(() => {
  AnalyticsService.sendEvent(Events.StopVideo, {
    document_id: props.documentId,
    video_position: currentTime.value
  });
});
</script>

<template>
  <div>
    <div class="video_container">
      <div class="video_overlay">
        <span
          v-if="currentState === VIDEO_STATE.PAUSED"
          class="material-symbols-outlined"
          @click="play"
          >play_arrow</span
        >
      </div>
      <video
        @loadeddata="loadedData"
        @loadedmetadata="setDuration"
        @timeupdate="setCurrentTime"
        @ended="ended"
        @pause="trackPause"
        @play="trackPlay"
        @seeked="trackSeek"
        :src="document"
        ref="videoElement"
        :autoplay="autoplay"
        :muted="muted"
        preload="auto"
        playsinline="true"
        webkit-playsinline="webkit-playsinline"
      >
        <source :src="document" type="'video/mp4'" />
      </video>
      <div class="controls">
        <span
          @click="play"
          class="icon_btn"
          v-if="currentState === VIDEO_STATE.PAUSED"
        >
          <span class="material-symbols-outlined"> play_arrow </span>
        </span>
        <span
          @click="pause"
          class="icon_btn"
          v-if="currentState === VIDEO_STATE.PLAYING"
        >
          <span class="material-symbols-outlined"> pause </span>
        </span>

        <progress
          ref="progressElement"
          class="progress"
          @click="skipAhead"
          :max="duration"
          :min="0"
          :value="currentTime"
        ></progress>

        <span @click="mute" class="icon_btn" v-if="!muted">
          <span class="material-symbols-outlined"> volume_up </span>
        </span>
        <span @click="unmute" class="icon_btn" v-else>
          <span class="material-symbols-outlined"> volume_off </span>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video_container {
  position: relative;
  height: 100%;
  width: 100%;
}

.video_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #bbb;
  padding: 0em;
  border-radius: 10%;
}

.video_overlay .material-symbols-outlined {
  font-size: 80px;
  opacity: 0.6;
}

.video_overlay .material-symbols-outlined:hover {
  cursor: pointer;
  opacity: 0.6;
}

video {
  height: 100%;
  width: 100%;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

progress::-webkit-progress-bar {
  background-color: #ddd;
  width: 100%;
}
progress {
  background-color: #ddd;
}

progress::-webkit-progress-value {
  background-color: #bbb !important;
}
progress::-moz-progress-bar {
  background-color: #bbb !important;
}

progress {
  width: calc(100% - 24px - 24px - 1em);
  color: #bbb;
  height: 0.6em;
  margin-bottom: 6px;
  margin-right: 0.5em;
}

.icon_btn {
  z-index: 10;
}

.icon_btn span {
  vertical-align: bottom;
  color: #363636;
}

.icon_btn:hover {
  cursor: pointer;
}
</style>
