<template>
  <label
    :class="{ toggle: true, toggled: state.toggled }"
    tabindex="0"
    role="checkbox"
    @keydown.space.prevent="toggle"
  >
    <input
      type="checkbox"
      class="toggle_input"
      :checked="value"
      @change.stop="toggle"
      tabindex="-1"
    />
    <div class="toggle_core">
      <div class="toggle_button"></div>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";

interface Props {
  value: boolean;
}

interface ComponentState {
  toggled: boolean;
}

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context) {
    const state: ComponentState = reactive({
      toggled: props.value,
    });

    const toggle = () => {
      state.toggled = !state.toggled;
      context.emit("change", state.toggled);
    };

    watch(
      () => props.value,
      (value) => {
        state.toggled = !!value;
      }
    );

    return {
      state,
      toggle,
    };
  },
});
</script>

<style lang="scss" scoped>
.toggle {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;

  .toggle_input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .toggle_core {
    display: block;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: border-color 0.3s, background-color 0.3s;
    user-select: none;

    .toggle_button {
      display: block;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      border-radius: 100%;
      background-color: #fff;
      z-index: 2;
      margin: 2px;
      background: #fff;
      height: 18px;
      width: 18px;
    }
  }

  .toggle_core {
    width: 40px;
    height: 22px;
    border-radius: 11px;
    background: #e6e6e8;
  }
}

.toggled {
  .toggle_core {
    background: #db006e;
  }

  .toggle_button {
    transform: translate(18px);
  }
}
</style>
