<script lang="ts" setup>
import { ExternalDocumentFragment } from "@/graphql/operations";
import { PropType, ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { HTTP } from "@/util";
import { token } from "@/init";
import { PMDoc } from "./PromomatsDocument";

const store = useStore();

const emit = defineEmits(["change"]);

const props = defineProps({
  doc: {
    type: Object as PropType<PMDoc>,
    required: true,
  },
  workspaceDoc: {
    type: Object as PropType<ExternalDocumentFragment>,
    required: false,
  },
  isSelecteable: {
    type: Boolean,
    required: false,
    default: true
  }
});

const img = ref();

const loadImg = async () => {
  const headers = new Map<string, string>();
  headers.set("Authorization", `Bearer ${token.value!}`);

  const url = `/backend/veeva/thumbnail?docId=${props.doc.id}&minorVersion=${props.doc.minor_version_number__v}&majorVersion=${props.doc.major_version_number__v}`;

  const res = await HTTP.get(url, headers);

  const blob = await res.blob();
  img.value.src = URL.createObjectURL(blob);
};

onMounted(() => {
  loadImg();
});

const isDisabled = computed(
  () =>
    props.workspaceDoc &&
    props.workspaceDoc.workspace?.root?.id ===
      store.getters.currentWorkspace.root.id
);

const displayWarning = computed(() => props.workspaceDoc && !isDisabled.value);

const isSelected = ref(false);
watch(isSelected, () => {
  emit("change", isSelected);
});
</script>

<template>
  <tr class="">
    <td>
      <div class="container">
        <img ref="img" />
        <div></div>
      </div>
    </td>
    <td>
      <div>
        <b>{{ doc.name__v }}</b>
      </div>
      <div class="info subinfo">{{ doc.material_id__v }}</div>
      <div class="info subinfo">
        {{ doc.product__v ? doc.product__v.join(", ") : "" }} |
        {{ doc.type__v }} {{ doc.subtype__v }}
      </div>
    </td>

    <td>{{ doc.status__v }}</td>

    <td class="align_right">
      <div class="align_right">
        <div class="info italic" v-if="isDisabled">
          {{ $t("promomatsIntegration.isInWorkspace") }}
        </div>
        <div class="warning" v-if="displayWarning">
          <span>{{ $t("promomatsIntegration.isInOtherWorkspace") }}</span
          >&nbsp;
          <span class="italic">{{ workspaceDoc?.workspace?.name }}</span>
        </div>
        <div class="info">
          {{ doc.expiration_date__c }}
        </div>
      </div>
    </td>
    <td>
      <input
        v-if="isSelecteable"
        type="checkbox"
        :class="{ disabled: isDisabled }"
        v-model="isSelected"
      />
      <slot></slot>
    </td>
  </tr>
</template>

<style scoped>
.item {
  font-size: 0.9rem;
  border-bottom: 1px solid #ececec;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

img {
  margin-right: 1em;
  height: 60px;
  width: 80px;
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
  padding: 0.5em;
  margin-top: 0.3em;
}

b {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.warning {
  color: #dd9a00;
}

table.data_table tbody td {
  line-height: 1.8;
  padding-top: .5em;
  padding-bottom: .5em;
}

.subinfo {
  font-size: 0.9em;
}

table.data_table tbody tr td:first-child {
  width: 94px;
}

table.data_table tbody tr td:nth-child(2) {
  width: 50%;
}

table.data_table tbody tr td:nth-child(3) {
  width: 70px;
}

table.data_table tbody tr td.align_right {
  text-align: right;
  padding-right: 1em;
}

table.data_table tbody tr td:last-child {
  width: 40px;
  text-align: center;
}
</style>
