<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch, computed } from "vue";

const dragStartEvent = new CustomEvent("pvdrag");

const props = defineProps({
  numItems: {
    type: Number,
    required: true
  }
});

const elem = ref();
const emit = defineEmits(["update", "placeAbove", "placeBelow", "placeInDropzone"]);
const dragElem = ref<HTMLElement | null>();
const dropTargetElem = ref<HTMLElement | null>();

enum DROP_POSITION {
  Above,
  Beneath
};

const dropPosition = ref<DROP_POSITION | null>();
const sourceIndex = ref<number>(-1);

const dragstart = (e: DragEvent) => {
  e.stopPropagation();

  window.dispatchEvent(dragStartEvent);

  const target: HTMLElement = e.target as HTMLElement;
  dragElem.value = target;
  const id = target.getAttribute("data-id");
  const idx = target.getAttribute("data-idx");
  if (!id || !idx) {
    console.error("missing attribute id or idx")
    return;
  }

  target.style.width = "50%"
  window.setTimeout(() => {
    target.style.width = "100%"
  }, 1)

  e.dataTransfer!.setData('text', id);
  e.dataTransfer!.effectAllowed = "move";
  sourceIndex.value = parseInt(idx, 10);
}

const drag = (ev: DragEvent) => {
  ev.stopPropagation();
  console.log("drag")

  /* dragged element does not belong to this instance */
  if (sourceIndex.value < 0) {
    return;
  }

  allowDrop(ev);
  resetStyle();

  let target: HTMLElement | null = ev.target as HTMLElement;
  while (target !== null && !(target.hasAttribute("data-draggable") || target.hasAttribute("data-dropzone"))) {
    target = target.parentElement;
  }

  if (!target) {
    console.log("no target!");
    return;
  }

  dropTargetElem.value = target;

  const targetPos = target.getBoundingClientRect();
  const middle = targetPos.top + (targetPos.height / 2);

  if (ev.clientY < middle) {
    target.classList.add("highlight_top");
    dropPosition.value = DROP_POSITION.Above;
  } else {
    target.classList.add("highlight_bottom");
    dropPosition.value = DROP_POSITION.Beneath;
  }
};

const drop = (ev: DragEvent) => {
  ev.stopPropagation();

  if (!dropTargetElem.value) {
    return;
  }

  const data = ev.dataTransfer!.getData("text");
  const targetId = dropTargetElem.value.getAttribute("data-id");
  if (dropTargetElem.value.hasAttribute("data-dropzone")) {
    emit("placeInDropzone", data, targetId);
    return;
  }

  const idxAttr = dropTargetElem.value.getAttribute("data-idx");
  if (idxAttr === null) {
    console.error("missing idx");
    return;
  }

  const targetIndex = parseInt(idxAttr, 10);
  if (dropPosition.value === DROP_POSITION.Above) { //place above target
    emit("update", targetIndex, sourceIndex.value, data, targetId);
    emit("placeAbove", data, targetId)
  } else { // place beneath target
    emit("update", targetIndex + 1, sourceIndex.value, data, targetId);
    emit("placeBelow", data, targetId);
  }

  reset();
}

const resetStyle = () => {
  const elems = elem.value!.querySelectorAll(".highlight_top, .highlight_bottom");
  for (let i = 0; i < elems.length; i++) {
    elems[i].classList.remove("highlight_top", "highlight_bottom");
  }
}

const reset = () => {
  resetStyle();
  sourceIndex.value = -1;
  dropTargetElem.value = null;
}

const allowDrop = (ev: DragEvent) => {
  const data = ev.dataTransfer!.getData("text");
  ev.preventDefault();
}

const setupElements = () => {
  if (!elem.value) {
    return;
  }

  const children = elem.value!.querySelectorAll(".row");
  for (let i = 0; i < children.length; i++) {
    const cElem = children[i];
    if (cElem.hasAttribute("data-draggable")) {
      continue;
    }

    const iconElem = document.createElement("span");
    iconElem.classList.add("material-symbols-outlined", "drag_indicator");
    iconElem.innerText = "drag_indicator";

    cElem.prepend(iconElem);

    cElem.setAttribute("draggable", true);
    cElem.setAttribute("data-draggable", true);
    cElem.addEventListener("dragstart", dragstart, false);
    cElem.addEventListener("dragenter", drag, false);
    cElem.addEventListener("dragover", drag, false);
  }

  const dropzones = elem.value!.querySelectorAll("[data-dropzone]");
  for (let i = 0; i < dropzones.length; i++) {
    const dElem = dropzones[i];
    if (dElem.hasAttribute("data-drop")) {
      continue;
    }

    dElem.addEventListener("dragenter", drag, false);
    dElem.addEventListener("dragover", drag, false);
  }
}

watch(props, () => {
  setTimeout(() => setupElements(), 200);
});

const checkIfLeave = (e: DragEvent) => {
  const elemPosition = elem.value!.getBoundingClientRect();

  if (e.clientY < (elemPosition.top + 15)) {
    resetStyle();
    return;
  }

  if (e.clientY > elemPosition.bottom) {
    resetStyle();
    return;
  }
}

let observer: MutationObserver | null = null;

onMounted(() => {
  setupElements();
  elem.value!.addEventListener("dragleave", checkIfLeave, false);
  elem.value!.addEventListener("drop", drop, false);
  elem.value.addEventListener("dragover", allowDrop, false);

  observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
        setupElements();
      }
    }
  });

  observer.observe(elem.value, { childList: true, subtree: true });
});

onBeforeUnmount(() => {
  if (elem.value) {
    elem.value.removeEventListener("dragleave", checkIfLeave, false);
    elem.value.removeEventListener("drop", drop, false);
    elem.value.removeEventListener("dragover", allowDrop, false);
  }

  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div ref="elem">
    <transition-group tag="ul" class="draggable" name="fade">
      <slot></slot>
    </transition-group>
  </div>
</template>

<style scoped>
.draggable {
  padding: 0;
  margin: 0;
}

.draggable :deep(.fade-move),
.draggable :deep(.fade-enter-active),
.draggable :deep(.fade-leave-active) {
  transition: all .5s cubic-bezier(55,0,.1,1);
}

.draggable :deep(.fade-enter-from),
.draggable :deep(.fade-leave-to) {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.draggable :deep(.fade-leave-active) {
  position: absolute;
}

.draggable :deep(.row) {
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #fff;
  background: #fff;
  width: 100%;
  display: flex;
  padding: .2em 0;
  font-size: .9rem;
  align-items: center;
}

.draggable :deep(.row:first-child) {
  border-top: 1px solid #dbdbdb;
}

.draggable :deep(.row:hover) {
  background-color: rgb(240, 240, 240, 0.5);
  border-top: 1px solid rgb(240, 240, 240, 0.5);
}

.draggable :deep(.row .drag_indicator) {
  opacity: .2;
  margin-right: .5em;
  font-size: 1.3em;
  padding: 0.1em;
}

.draggable :deep(.row:hover .drag_indicator) {
  opacity: 1;
  cursor: grab;
}

.draggable :deep(.row:active) {
  cursor: grabbing;
}

.draggable :deep(.row .cell) {
  padding: 0 .5em;
}

.draggable :deep(.cell.stretch) {
  flex: 1;
}

.draggable :deep(.cell.grow) {
  flex-grow: 1;
}

.draggable :deep(.cell.center) {
  align-self: center;
}

.draggable :deep(.cell.img_cell a) {
  display: inline-block;
  height: 45px;
  vertical-align: middle;
}

.draggable :deep(.cell img) {
  height: 45px;
  width: auto;
  max-width: 60px;
}

.draggable :deep(.row .cell.img) {
  width: 75px;
}

.draggable :deep(.drag_handler) {
  visibility: hidden;
  width: 25px;
  opacity: 0.6;
  cursor: grab;
}

.draggable :deep(*:hover .drag_handler) {
  visibility: visible;
}

.draggable :deep(.row.highlight_top) {
  border-top: 1px solid #0066cc;
  margin-top: -1px;
  padding-top: calc(1px + 0.5em);
}

.draggable :deep(.row.highlight_bottom),
.draggable :deep([data-dropzone].highlight_bottom),
.draggable :deep([data-dropzone].highlight_top) {
  border-bottom: 1px solid #0066cc;
}

.draggable :deep(.row.highlight_top *),
.draggable :deep(.row.highlight_bottom *) {
  pointer-events: none;
}
</style>
