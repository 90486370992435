<script lang="ts" setup>
import { watch, ref, PropType, onBeforeUnmount } from "vue";
import { onClickOutside } from "@vueuse/core";

interface Item {
  title: string;
  value: string;
  info?: string;
  displayCurrentSelection?: boolean;
  newSection?: boolean;
  type?: string;
  disabled?: boolean;
}

const props = defineProps({
  items: {
    type: Array as PropType<Array<Item>>,
    required: true,
  },
  value: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  minimal: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["change"]);

const target = ref<HTMLElement>();

const isActive = ref<boolean>(false);
const toggle = () => {
  isActive.value = !isActive.value;
};

const activeItem = ref<Item | undefined>(
  props.items.find((i) => i.value === props.value)
);
watch(props, () => {
  activeItem.value = props.items.find((i) => i.value === props.value);
});

const select = (item: Item) => {
  close();
  emits("change", item.value);
};

function close() {
  isActive.value = false;

  if (activeItem.value && !activeItem.value.displayCurrentSelection) {
    //TODO
  }
}

onClickOutside(target, (event) => {
  close();
});

window.addEventListener("pvdrag", close);

onBeforeUnmount(() => {
  window.removeEventListener("pvdrag", close);
});
</script>

<template>
  <div class="dropdown" ref="target">
    <div class="dropdown_trigger" @click.stop.prevent="toggle">
      <div class="dropdown_title">
        <span v-if="minimal" class="material-symbols-outlined"> more_vert </span>
        <template v-else>
          <span v-if="activeItem">
            {{ activeItem.title }}
          </span>
          <span v-else>
            {{ placeholder }}
          </span>
          <span class="material-symbols-outlined">keyboard_arrow_down</span>
        </template>
      </div>
    </div>

    <div class="dropdown_menu" :class="{ active: isActive }">
      <div class="dropdown_content">
        <a
          v-for="(item, index) of items"
          :key="index"
          :class="{
            dropdown_item: true,
            active: activeItem && activeItem.value === item.value,
            danger: item.type === 'danger',
            border: item.newSection,
            disabled: item.disabled,
          }"
          @click.stop="select(item)"
        >
          <div class="item_title">{{ item.title }}</div>
          <div class="text_subtle">{{ item.info }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$border-strong: #d0d4d9;
.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  font-size: 0.9rem;
}

.dropdown_menu {
  display: none;
  left: 0;
  min-width: 7rem;
  padding-top: 4px;
  position: absolute;
  top: 80%;
  z-index: 20;
}

.dropdown.right_align .dropdown_menu {
  left: auto;
  right: 0;
}

.dropdown.right .dropdown_menu {
  left: auto;
  right: 0;
}

.dropdown_trigger {
  cursor: pointer;
}

.dropdown_trigger .dropdown_title,
.dropdown_trigger::v-deep .dropdown_title {
  padding: 0.35rem;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  padding: 0.35rem 1.2em;
  background-color: #fff;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  min-height: 1.5em;
  font-size: 1em;
  border-radius: 2em;
  align-items: center;
}

.dropdown_trigger::v-deep .material-symbols-outlined {
  font-size: 1.3em;
  margin-left: 0.2em;
}

.dropdown_trigger::v-deep .material-symbols-outlined.circle {
  font-size: 1.3em;
  color: #34b5ba;
  border: 1px solid #34b5ba;
  border-radius: 50%;
  padding: 0.2em;
}

.dropdown_menu.active {
  display: block;
}

.dropdown_content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  border: 1px solid $border-strong;
  width: 250px;
}

.dropdown_item {
  color: #1a1918;
  display: block;
  font-size: 0.87rem;
  line-height: 1.5;
  padding: 0.8rem 1rem;
  position: relative;
  cursor: pointer;
}

.dropdown_item.active {
  background: #fafafa;
}

.dropdown_item.active .item_title {
  color: #34b5ba;
}

.dropdown_item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown_item .text_subtle {
  color: #736c64;
  font-size: 0.9em;
}

a.dropdown_item {
  padding-right: 3rem;
}

.dropdown_item:hover {
  background: #fafafa;
}

.dropdown_item.danger {
  color: #cc0f35;
}

.dropdown_item.border {
  border-top: 1px solid #ebebeb;
}
</style>
