<script lang="ts" setup>
import { useStore } from "vuex";
import Teams from "./Teams.vue";
import Templates from "./Templates.vue";

const store = useStore();
</script>

<template>
  <div class="page_content">
    <h3>{{ $t("settingsOverview.title") }}</h3>

    <section>
      <h4>{{ $t("profile.title") }}</h4>
      <section>
        <div class="info">
          {{ $t("settingsOverview.profile.info") }}
        </div>
        <router-link :to="{ name: 'Profile' }" class="button secondary">
          {{ $t("settingsOverview.profile.buttonText") }}
        </router-link>
      </section>
    </section>

    <section v-if="store.getters.user.hasDocumentTracking()">
      <div class="title_container">
        <h4>{{ $t("documentHistory.title") }}</h4>
      </div>
      <section>
        <div class="info">
          {{ $t("settingsOverview.documentHistory.info") }}
        </div>
        <router-link :to="{ name: 'DocumentHistory' }" class="button secondary">
          {{ $t("settingsOverview.documentHistory.buttonText") }}
        </router-link>
      </section>
    </section>

    <section>
      <teams />
    </section>

    <section>
      <templates />
    </section>
  </div>
</template>

<style scoped>
.title_container {
  display: flex;
  align-items: center;
}

h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0;
}

section {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1.5em;
}

.page_content section:last-child {
  border-bottom: none;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.4;
}

.info {
  font-size: 0.9em;
  margin-bottom: 0.5em;
}
</style>
