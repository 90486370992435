<script lang="ts" setup>
import { PropType } from "vue";
import TextToolbar from "./TextToolbar.vue";
import { useFlashMessage } from "@/hooks";
import { type Config } from "./Config";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["close", "reloadData", "changeState"]);

const { displayFlashMessage } = useFlashMessage();

const submit = async (text: string) => {
  const res = await props.config.updateFooter(text);

  if (res.error) {
    displayFlashMessage(res.error.message, "error");
  } else {
    displayFlashMessage("Success", "success");
    emits("reloadData");
  }
};
</script>

<template>
  <text-toolbar
    @close="emits('close')"
    @submit="submit"
    :text="config.footerText ?? ''"
    @changeState="(hasChanges: boolean) => emits('changeState', hasChanges)"
  />
</template>
