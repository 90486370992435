
export enum ErrorType {
  MissingFile = "missingFile",
  MaxSizeExceeded = "maxSizeExceeded",
  NoFileSelected = "noFileSelected",
  TooManyFilesSelected = "tooManyFilesSelected",
  UnsupportedMimeType = "unsupportedMimeType",
  InternalServerError = "internalServerError",
  Unauthorized = "unauthorized",
  WrongMimeType = "wrongMimeType",
}

export enum UploadState {
  Idle = "idle",
  Running = "running",
  Success = "success",
  Error = "error"
}

export interface FileState {
  progress: number,
  error?: ErrorType,
  uploadState: UploadState
}
