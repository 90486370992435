<template>
  <div class="progress_spinner">
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.progress_spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #34B5BA;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>