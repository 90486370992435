<script lang="ts" setup>
import { computed } from "vue";
import { Modal } from "@/components";
import { useRouter } from "vue-router";
import Document from "@/document";
import { useDocumentUsageCountsQuery } from "@/graphql/operations";

const router = useRouter();

const props = defineProps({
  docId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close"]);

const { fetching, data } = useDocumentUsageCountsQuery({
  variables: {
    documentId: props.docId,
  },
  requestPolicy: "network-only",
});

const numPresentations = computed(() => data.value?.documentUsageCounts?.numPresentations || 0);
const numSpaces = computed(() => data.value?.documentUsageCounts?.numSpaces || 0);
const numCustomFlows = computed(() => data.value?.documentUsageCounts?.numCustomFlows || 0);

const isUsed = computed(() => numPresentations.value + numSpaces.value + numCustomFlows.value > 0);

const deleteDocument = async () => {
  await Document.delete(props.docId);
  router.push({ name: "Workspace" });
};
</script>

<template>
  <Modal :active="true" @close="emits('close')" class="medium">
    <template #header>
      <div class="error">
        {{ $t("pvDocEditor.deleteDoc.header") }}
      </div>
    </template>

    <template #body>
      <template v-if="fetching"> Loading... </template>
      <template v-else-if="!isUsed">
          {{ $t("pvDocEditor.deleteDoc.confirmationQuestion") }}
      </template>
      <template v-else>
        <div>
          {{ $t("pvDocEditor.deleteDoc.isUsed.header") }}
        </div>

        <ul>
          <li v-if="numPresentations > 0">
            <b>Presentations</b>:
            {{ $t("pvDocEditor.deleteDoc.isUsed.presentationUsage", { count: numPresentations }) }}
          </li>
          <li v-if="numSpaces > 0">
            <b>Shared Spaces</b>:
            {{ $t("pvDocEditor.deleteDoc.isUsed.spaceUsage", { count: numSpaces }) }}
          </li>
          <li v-if="numCustomFlows > 0">
            <b>Custom Flows</b>:
            {{ $t("pvDocEditor.deleteDoc.isUsed.flowUsage", { count: numCustomFlows }) }}
          </li>
        </ul>
        <div>
          {{ $t("pvDocEditor.deleteDoc.isUsed.suggestion") }}
        </div>
        <div>
          {{ $t("pvDocEditor.deleteDoc.isUsed.contact") }}
          <a class="link" href="mailto:service@pitchview.de">service@pitchview.de</a>
        </div>
      </template>
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="emits('close')">
          {{ $t("pvDocEditor.deleteDoc.cancel") }}
        </button>
        <button
          class="button error"
          :class="{ disabled: fetching }"
          @click="deleteDocument"
          data-test="delete-doc-submit"
        >
          {{ $t("pvDocEditor.deleteDoc.confirm") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
div {
  font-size: 0.9rem;
}

div + div {
  margin-top: 1em;
}

ul li {
  margin-top: 0.5em;
}
</style>
