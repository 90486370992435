<template>
  <div class="card">
    <div class="card_image clickable" @click.stop="emitClick">
      <div>
        <button class="button secondary upload_link">
          {{ $t("pvCardDoc.updateDocument") }}
        </button>

        <!--<div v-if="pvdoc.updatingDoc"></div> -->
        <div class="thumbnail">
          <img :src="getStorageProxyUrl(doc.thumbnailDownloadUrl)" />
        </div>

        <div class="badge_container">
          <div class="badge" v-show="!doc.isPublished">
            {{ $t("pvCardDoc.badge.draft") }}
          </div>

          <!--
          <div class="badge" v-show="pvdoc.hasLiteratureReferences()">
            {{ $t("pvCardDoc.badge.references") }}
          </div>
          -->
        </div>
      </div>
    </div>

    <div class="card_content">
      <div class="top_title">
        <div class="card_info">
          {{ displayDate }}
        </div>
        <template v-if="doc.expirationDate">
          <span class="line">&#124;</span>
          <span
            >{{ $t("pvCardDoc.expirationDate") }}
            {{ Formatter.formatDateStr(doc.expirationDate) }}</span
          >
        </template>
        <span class="line">&#124;</span>
        <span>{{ doc.topTitle }}</span>
      </div>

      <div class="card_title">
        {{ doc.displayTitle }}
      </div>

      <div class="teaser">
        <span v-if="hasTeaserText">{{ doc.teaserText }}</span>
      </div>

      <div class="row tags" v-if="hasTags">
        {{ $t("pvCardDoc.info.tags") }}:
        <span class="tag" v-for="(tag, idx) in tags" :key="idx">
          {{ tag }}
        </span>
      </div>

      <div class="bottom">
        <span v-if="isVideo">
          {{ $t("pvCardDoc.info.duration") }}: {{ duration }}
        </span>

        <span v-else>
          {{ $t("pvCardDoc.info.pages") }}: {{ doc.numNormalPages }}
        </span>

        <div class="buttons">
          <Tooltip v-if="doc.doesSupportSharedspace">
            <template v-slot>
              <span class="material-symbols-outlined filled" @click.stop="emitAddToSpace">
                share
              </span>
            </template>
            <template #tooltip>{{ $t("pvCardDoc.tooltip.share") }}</template>
          </Tooltip>

          <Tooltip
            v-if="
              store.getters.currentWorkspace?.root?.myPrivileges
                ?.mayAdministrate
            "
          >
            <template v-slot>
              <span class="material-symbols-outlined filled" @click.stop="emitMove">
                drive_file_move
              </span>
            </template>
            <template #tooltip>{{ $t("pvCardDoc.tooltip.move") }}</template>
          </Tooltip>

          <Tooltip
            v-if="
              store.getters.currentWorkspace?.root?.myPrivileges
                ?.mayAdministrate
            "
          >
            <template v-slot>
              <router-link
                :to="editorRoute"
                @click.stop
                data-test="goto-doc-editor-ctrl"
              >
                <span class="material-symbols-outlined filled"> create </span>
              </router-link>
            </template>
            <template #tooltip>{{ $t("pvCardDoc.tooltip.edit") }}</template>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Formatter, getStorageProxyUrl } from "@/util";
import { WorkspaceDocumentFragment } from "@/graphql/operations";
import { reactive, computed, PropType } from "vue";
import { NumericInput, Tooltip } from "@/components";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const props = defineProps({
  doc: {
    type: Object as PropType<WorkspaceDocumentFragment>,
    required: true,
  },
});

const emit = defineEmits(["copy", "move", "addToSpace", "click"]);

const emitCopy = () => emit("copy");
const emitMove = () => emit("move");
const emitAddToSpace = () => emit("addToSpace");

const store = useStore();
const route = useRoute();

const hasTeaserText = computed(() => "teaserText" in props.doc);
const hasTags = computed(
  () =>
    "tags" in props.doc &&
    props.doc.tags !== undefined &&
    props.doc.tags.length > 0
);
const tags = computed(() => props.doc.tags);
const pagesTotal = props.doc.pages;
const displayDate = computed(() =>
  Formatter.formatDate(new Date(props.doc.displayDate), "%Y-%m-%d")
);
const duration = computed(() => Formatter.formatDuration(props.doc.pages));
const editorRoute = computed(() => ({
  name: "DocumentProperties",
  params: {
    workspaceId: route.params.workspaceId,
    documentId: props.doc.id,
  },
}));
const isVideo = computed(() => props.doc.mimeType === "application/mp4");

function emitClick() {
  emit("click");
}
</script>

<style lang="scss" scoped>
$img_height: 250px;
$content_height: 250px;
$border-light: #ebebeb;
$border-strong: #cecece;

.card {
  border-radius: 0.3em;
  border: 1px solid $border-strong;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.card_image {
  display: block;
  height: $img_height;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  pointer-events: none;
  border-bottom: 1px solid $border-strong;
}

.card_image.clickable {
  cursor: pointer;
  pointer-events: auto;
}

.card_image .upload_link {
  display: none;
}

.thumbnail {
  width: 100%;
  height: $img_height;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.badge_container {
  position: absolute;
  top: 15px;
  right: 0;
}

.badge {
  background-color: #f9f9f9;
  font-size: 0.85em;
  padding: 0 10px;
  line-height: 1.8em;
  font-weight: 500;
  margin-bottom: 5px;
}

.card_content {
  padding: 0.8rem 1.2rem 0.6rem 1.2rem;
  height: $content_height;
  box-sizing: border-box;
  position: relative;
}

.card_info {
  float: left;
}

.card_title {
  font-weight: 600;
  font-size: 1.1em;
  line-height: 1.125;
  color: #363636;
  margin-bottom: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top_title {
  font-size: 0.9em;
  line-height: 1.4em;
}

.top_title span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 215px;
  overflow: hidden;
}

.top_title .line {
  padding: 0 0.5em;
}

.teaser {
  font-size: 0.9em;
  color: rgba(25, 23, 17, 0.6);
  height: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.row {
  margin-top: 8px;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 1.2rem 0.6rem 1.2rem;
  font-size: 0.9em;
  line-height: 38px;
  height: 38px;
}

.buttons {
  float: right;
}

.buttons .material-symbols-outlined {
  font-size: 1.1em;
  border: 1px solid $border-light;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
}

.buttons a {
  color: inherit;
}

.buttons > div {
  display: inline-block;
  margin-left: 0.3em;
}

.buttons .material-symbols-outlined:hover {
  cursor: pointer;
  background: #f4f4f4;
}

label {
  font-size: 0.9em;
}

.row.tags {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.9em;
}

.tag {
  background-color: #eaeaea;
  padding: 0.5em 0.8em;
  border-radius: 2em;
  margin-left: 0.3em;
  display: inline-block;
  font-size: 0.9em;
}
</style>
