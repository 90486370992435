<script lang="ts" setup>
import { Modal, Tooltip } from "@/components";
import { useSpaceSectionsQuery, useDeleteSpaceSectionMutation } from "@/graphql/operations";
import { computed } from "vue";

const props = defineProps({
  sectionId: {
    type: String,
    required: true,
  },
  spaceId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close", "success"]);

const { data, fetching } = useSpaceSectionsQuery({
  variables: {
    spaceId: props.spaceId,
  },
});

const sections = computed(() => data.value?.documentSpaceSections?.nodes);

const { executeMutation } = useDeleteSpaceSectionMutation();
const deleteWithDocuments = async () => {
  const res = await executeMutation({
    sectionId: props.sectionId
  });

  if (res.error) {

  } else {
    emits("success");
  }

  emits("close");
};

const moveToSection = async (id: string) => {
  const res = await executeMutation({
    sectionId: props.sectionId,
    moveDocumentsToSectionId: id
  })

  if (res.error) {

  } else {
    emits("success");
  }

  emits("close");
}
</script>

<template>
  <Modal :active="true" @close="emits('close')">
    <template #header>
      {{ $t("documentSpace.sections.deleteWithDocuments.title") }}
    </template>
    <template #body>
      <h4>
        {{
          $t("documentSpace.sections.deleteWithDocuments.moveToOtherSection")
        }}:
      </h4>
      <div>
        <div v-for="section in sections" :key="section!.id" class="row">
          {{
            section!.title
              ? section!.title
              : $t("documentSpace.documents.title")
          }}

          <Tooltip class="flex_right right">
            <template v-slot>
              <button
                class="button primary"
                :class="{ disabled: section!.id === sectionId }"
                @click="moveToSection(section!.id)"
              >
                <span class="material-symbols-outlined"> place_item </span>
              </button>
            </template>
            <template #tooltip v-if="section!.id !== sectionId">
              {{ $t("documentSpace.sections.deleteWithDocuments.moveTo") }}
            </template>
          </Tooltip>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
        <button class="button dangerous" @click="deleteWithDocuments">
          <span class="material-symbols-outlined"> delete </span>
          {{ $t("documentSpace.sections.deleteWithDocuments.deleteAll") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 0.6em 0;
}

.row .flex_right {
  margin-left: auto;
}

.row .button .material-symbols-outlined {
  margin-right: 0;
}
</style>
