<script lang="ts" setup>
import { onMounted, ref } from "vue";

const elem = ref();
const emit = defineEmits(["placeAbove", "placeBelow"]);

const sourceIndex = ref<number>(0);
const sourceId = ref<string>("");

const dragElem = ref<HTMLElement | undefined>(undefined);

const dragItem = ref<HTMLElement | undefined>(undefined);

let lastDropItem: HTMLElement | null = null;

interface DropLocationAndId {
  location: "before" | "after";
  id: string;
  [key: string]: string;
}
const dropLocationAndId = ref<DropLocationAndId | null>(null);

const dragstart = (e: DragEvent) => {
  let target: HTMLElement | null = e.target as HTMLElement;

  while (target !== null && !target.hasAttribute("data-dragitem")) {
    target = target.parentElement;
  }

  if (!target || !target.hasAttribute("data-dragitem")) {
    console.log("no target!");
    return;
  }

  const id = target.getAttribute("data-id");
  const idx = target.getAttribute("data-idx");

  if (!id || !idx) {
    console.error("missing attribute id or idx");
    return;
  }

  e.dataTransfer!.setData("text/plain", id);
  e.dataTransfer!.effectAllowed = "move";
  sourceIndex.value = parseInt(idx, 10);
  sourceId.value = id;

  dragElem.value = target;
  dragItem.value = target;

  const dropItem = document.createElement("div");
  dropItem.style.width = target.offsetWidth + "px";
  dropItem.style.height = target.offsetHeight + "px";
  dropItem.style.display = "block";
  dropItem.classList.add("dropItem");
  dropItem.setAttribute("data-id", "dropitem");
  dropItem.setAttribute("data-dragitem", "true");
  dropItem.setAttribute("data-idx", dragElem.value?.getAttribute("data-idx")!);
  dropItem.addEventListener("drop", drop, false);
  dropItem.addEventListener("dragover", dragover, false);

  window.setTimeout(() => {
    target!.classList.add("dragged");
    elem.value.insertBefore(dropItem, target);
    target!.style.display = "none";
    lastDropItem = dropItem;
  }, 10);
}

const removeDropItem = () => {
  const dropItem = elem.value!.querySelector("[data-id='dropitem']");

  if (dropItem) {
    dropItem.remove();
  }
}

const dragover = (ev: DragEvent) => {
  ev.preventDefault();

  const data = ev.dataTransfer!.getData("text");
  let target: HTMLElement | null = ev.target as HTMLElement;

  while (target !== null && !target.hasAttribute("data-dragitem")) {
    target = target.parentElement;
  }

  if (!target) {
    console.log("no target!");
    return;
  }

  if (target.getAttribute("data-idx") === dragItem.value?.getAttribute("data-idx")) {
    return;
  }

  const dropItem = document.createElement("div");
  dropItem.style.width = target.offsetWidth + "px";
  dropItem.style.height = target.offsetHeight + "px";
  dropItem.style.display = "block";
  dropItem.classList.add("dropItem");
  dropItem.setAttribute("data-id", "dropitem");
  dropItem.setAttribute("data-dragitem", "true");
  dropItem.setAttribute("data-idx", dragElem.value?.getAttribute("data-idx")!);
  dropItem.addEventListener("drop", drop, false);
  dropItem.addEventListener("dragover", dragover, false);

  if (lastDropItem) {
    removeDropItem();
    lastDropItem.remove();
    lastDropItem = null;
  }
  lastDropItem = dropItem;

  const targetPos = target.getBoundingClientRect();
  if (ev.clientX > targetPos.left && ev.clientX < targetPos.right) {
    dropLocationAndId.value = { location: "before", id: target.getAttribute("data-id")! };
    elem.value.insertBefore(dropItem, target);
  } else {
    dropLocationAndId.value = { location: "after", id: target.getAttribute("data-id")! };
    elem.value.insertBefore(dropItem, target.nextSibling);
  }
}

const drop = (ev: DragEvent) => {

  const data = ev.dataTransfer!.getData("text");
  let target: HTMLElement | null = ev.target as HTMLElement;

  while (target !== null && !target.hasAttribute("data-dragitem")) {
    target = target.parentElement;
  }

  if (!target) {
    console.log("no target!");
    return;
  }

  if (!dropLocationAndId.value) {
    console.error("error dropping dragged item");
    return;
  }

  if (dropLocationAndId.value.location === "before") {
    elem.value.insertBefore(dragItem.value, target);
    emit("placeAbove", sourceId.value, dropLocationAndId.value.id);
  } else {
    elem.value.insertBefore(dragItem.value, target.nextSibling);
    emit("placeBelow", sourceId.value, dropLocationAndId.value.id);
  }

  dragItem.value!.style.display = "";
  dragElem.value!.classList.remove("dragged");
  removeDropItem();
}

const dragenter = (ev: Event) => {
  ev.stopPropagation();
  // const target: HTMLElement = ev.target as HTMLElement;
};

const dragout = (ev: Event) => {
  //const target: HTMLElement = ev.target as HTMLElement;
};

const dragend = () => {
  // cancelling drag
  if (dragElem.value) {
    dragElem.value.style.display = "";
  }
  removeDropItem();

  if (dragElem.value) {
    dragElem.value.classList.remove("dragged");
  }
}

onMounted(() => {
  if (!elem.value) {
    return;
  }

  const children = elem.value!.children;

  for (let i = 0; i < children.length; i++) {
    const cElem = children[i];
    if (cElem.getAttribute("data-ignored")){
      continue;
    }
    cElem.setAttribute("data-dragitem", true);
    cElem.setAttribute("draggable", true);
    cElem.addEventListener("dragstart", dragstart, false);
    cElem.addEventListener("dragenter", dragenter, false);
    cElem.addEventListener("dragleave", dragout, false);
    cElem.addEventListener("mouseout", dragout, false);
    cElem.addEventListener("drop", drop, false);
    cElem.addEventListener("dragover", dragover, false);
    cElem.addEventListener("dragend", dragend, false);
  }
});
</script>

<template>
  <div ref="elem" class="draggable">
    <slot></slot>
  </div>
</template>

<style scoped>
.draggable {
  cursor: grab;
}

.draggable :deep(.drag_handler) {
  visibility: hidden;
  width: 25px;
  opacity: 0.6;
  cursor: grab;
}

.draggable :deep(*:hover .drag_handler) {
  visibility: visible;
}
</style>
