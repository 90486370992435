<script lang="ts" setup>
import { ref, Ref, PropType, computed, watch } from "vue";
import { UploadService, UploadResponseData } from "@/util";
import { token } from "@/init";
import { useFlashMessage } from "@/hooks";
import { type Config } from "./Config";
import BannerCropModal from "./BannerCropModal.vue";
import { v4 as uuid } from "uuid";
import { useForm, useField } from "vee-validate";
import { string } from "yup";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["reloadData", "changeState"]);

const { displayFlashMessage } = useFlashMessage();

const fileEndings: { [key: string]: string } = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "image/gif": "gif",
};

const { validate, errors } = useForm();

const { value: url } = useField<string | undefined | null>(
  "url",
  string().url().nullable().notRequired(),
  {
    initialValue: props.config.heroLinkUrl,
  }
);

watch(
  () => props.config,
  (newConfig, oldConfig) => {
    if (newConfig.heroLinkUrl !== oldConfig.heroLinkUrl) {
      url.value = newConfig.heroLinkUrl;
    }
  }
);

const fileInput: Ref<HTMLInputElement | null> = ref(null);
const imagePreview: Ref<HTMLImageElement | null> = ref(null);
const helpImage: Ref<HTMLImageElement | null> = ref(null);
const file: Ref<File | null> = ref(null);
const imageError = ref<string | undefined>();

const imageUrl = ref<string | undefined>();
const displayImageCrop = ref<boolean>(false);

const currentRatio = ref<number | undefined>();
const processing = ref<boolean>(false);

const blob = ref<Blob | undefined>();

const calculateRatio = () => {
  if (!helpImage.value || !imageUrl.value) {
    console.log("no image ref");
    return;
  }

  const width = helpImage.value.width;
  const height = helpImage.value.height;

  currentRatio.value = parseFloat((width / height).toFixed(2));

  //check the aspect ratio of the selected image
  if (currentRatio.value < 4 || currentRatio.value > 4.5) {
    displayImageCrop.value = true;
  }

  imagePreview.value!.src = imageUrl.value!;
  processing.value = false;
};

const submitFile = () => {
  const files = fileInput?.value?.files;
  if (!files || files.length === 0 || !imagePreview.value) {
    return;
  }

  const currentFile = files[0];
  if (!fileEndings.hasOwnProperty(currentFile.type)) {
    imageError.value = "wrongTypeImageGif";
    return;
  }

  const maxFileSize = 3 * 1024 * 1024; // 3MB
  if (currentFile.size > maxFileSize) {
    imageError.value = "tooLargeHuge";
    return;
  }

  imageUrl.value = undefined;
  processing.value = true;
  imageError.value = undefined;
  file.value = currentFile;
  blob.value = undefined;

  const reader = new FileReader();
  reader.onload = (e) => {
    imageUrl.value = e.target!.result as string;
    fileInput.value = null;
  };

  reader.readAsDataURL(file.value);
};

const submitCrop = (blobData: Blob, dataUrl: string) => {
  imagePreview.value!.src = dataUrl;
  displayImageCrop.value = false;
  blob.value = blobData;
};

const onProgress = (progress: number) => {
  console.log(progress);
};

const hasChanges = computed(
  () => (file.value !== undefined && file.value !== null) || url.value !== props.config.heroLinkUrl
);

watch(hasChanges, () => { emits("changeState", hasChanges.value) })

const submit = async () => {
  const validationResult = await validate();
  if (!validationResult.valid) {
    return;
  }

  let heroImageUrl = props.config.heroImageUrl;
  try {
    if (file.value) {
      const formData = new FormData();
      const id = uuid();
      const fileName = `${id}.${fileEndings[file.value.type]}`;

      if (blob.value) {
        formData.append("file", blob.value, fileName);
      } else {
        formData.append("file", file.value, fileName);
      }

      let fileData: UploadResponseData;
      fileData = await UploadService.upload(formData, token.value!, onProgress);
      heroImageUrl = fileData.originalFileDownloadUrl;
    }

    const res = await props.config.updateBanner(heroImageUrl, url.value);
    if (res.error) {
      throw new Error(res.error.message);
    }

    displayFlashMessage("Success", "success");
    emits("reloadData");

    file.value = null;
    blob.value = undefined;
  } catch (e) {
    displayFlashMessage("Error", "error");
    console.error(e);
  }
};
</script>

<template>
  <div class="toolbar_body">
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.banner.image.label") }}</label>
        <div class="controls">
          <label class="link" for="file-selector">
            {{ $t("templateEditor.toolbar.banner.image.replace") }}
          </label>
          <input
            @change="submitFile"
            id="file-selector"
            ref="fileInput"
            type="file"
            style="display: none"
            multiple="false"
          />
        </div>
      </div>
      <div class="image">
        <img ref="imagePreview" :src="config.heroImageUrl || ''" />
        <span v-if="processing"> Processing</span>
      </div>

      <p v-if="imageError" class="help">
        {{ $t(`templateEditor.toolbar.fileSelect.error.${imageError}`) }}
      </p>
    </div>

    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.banner.url.label") }}</label>
      </div>
      <div>
        <input
          type="text"
          class="input"
          v-model="url"
          :class="{ invalid: errors.url !== undefined }"
          placeholder="https://www.pitchview.io"
        />
        <p class="info">{{ $t("templateEditor.toolbar.banner.url.info") }}</p>
      </div>
    </div>
  </div>
  <div class="toolbar_footer" @click="submit">
    <div
      class="button primary"
      :class="{ disabled: !hasChanges || errors.url !== undefined }"
    >
      {{ $t("templateEditor.toolbar.save") }}
    </div>
  </div>

  <banner-crop-modal
    v-if="displayImageCrop && imageUrl"
    :imageUrl="imageUrl"
    @close="displayImageCrop = false"
    @submit="submitCrop"
  />

  <img
    v-if="imageUrl !== undefined"
    @load="calculateRatio"
    :src="imageUrl"
    ref="helpImage"
    style="display: none"
  />
</template>

<style lang="scss" scoped>
.toolbar {
  width: 300px;
  border-right: 1px solid #e0e0e0;
}

.toolbar_header {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 1em;
  background: #fafafa;
}

.toolbar_body {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  height: calc(100vh - 60px - 60px - 82px);
  box-sizing: border-box;
  overflow-y: scroll;
}

.toolbar_footer {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.header_label {
  font-weight: 500;
  flex: 1;
  padding-top: 0.5em;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}

.label_container {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}

.label_container label {
  flex: 1;
  font-weight: 500;
}

.label_container .link {
  font-weight: normal;
}

.row {
  margin-bottom: 1.5em;
}

.image {
  background: #e0e0e0;
  width: 271px;
  height: 63px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.image img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  top: 0;
  left: 0;
}

p.help {
  font-size: 0.9em;
  color: #f14668;
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  height: 0.8em;
}

input + p.info {
  margin-top: 0.5em;
}
</style>
