<script lang="ts" setup>
import { Modal } from "@/components";
import { useDeleteWorkspaceMutation } from "@/graphql/operations";
import { reloadWorkspaces } from "@/init";

const emit = defineEmits(["close", "success"]);
const emitClose = () => {
  emit("close");
};

const emitSuccess = () => {
  emit("success");
};

const props = defineProps({
  active: {
    type: Boolean,
  },
  id: {
    type: String,
    required: true,
  },
  isSubfolder: {
    type: Boolean,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const { executeMutation } = useDeleteWorkspaceMutation();
const submit = async () => {
  const result = await executeMutation({
    id: props.id,
  });

  await reloadWorkspaces();
  emitClose();
  emitSuccess();
};
</script>

<template>
  <Modal @close="emitClose" :active="active">
    <template #header>
      {{
        isSubfolder
          ? $t("workspaceDetail.delete.subfolderModalHeader")
          : $t("workspaceDetail.delete.modalHeader")
      }}
    </template>
    <template #body>
      <p class="info">
        {{
          isSubfolder
            ? $t("workspaceDetail.delete.subfolderConfirmation", {
                subfolderName: name,
              })
            : $t("workspaceDetail.delete.confirmation", { workspaceName: name })
        }}
      </p>
    </template>
    <template #footer>
      <div class="buttons">
        <button
          class="button primary"
          @click="submit"
          data-test="delete-workspace-submit"
        >
          {{ $t("workspaceDetail.delete.confirmationButtonText") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
