<template>
  <Presentation :presentationId="presentationId" />
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Presentation } from "@/components";

const route = useRoute();

const presentationId = ref(route.params.presentationId as string);

watch((route.params), (newParams, oldParams) => {
  if (newParams?.presentationId !== oldParams?.presentationId) {
    presentationId.value = newParams.presentationId as string;
  }
})
</script>
