<template>
  <div class="projector_launcher">
    <div :class="{ popup: true, active: showModal }" @click.stop>
      <div class="header">
        <div class="close_button">
          <span class="material-symbols-outlined" @click="closePopup">close</span>
        </div>

        {{
          $t(
            `projectorLauncher.header.projector${
              projectorActive ? "Active" : "Inactive"
            }`
          )
        }}
      </div>

      <div class="title">
        {{ $t("projectorLauncher.shareLink") }}
      </div>
      <div class="link_field">{{ shareLink }}</div>

      <div class="row">
        <b>{{ $t("projectorLauncher.toggleLabel") }}</b>
        <ToggleButton :value="projectorActive" @change="toggleProjector" />
      </div>

      <div v-if="projectorActive">
        <div class="title">{{ $t("projectorLauncher.shareCode") }}</div>
        <div class="link_field">
          {{ code }}
          <button
            class="button"
            title="Copy Link to Pasteboard"
            @click="copyCode()"
          >
            <span class="material-symbols-outlined"> content_copy </span>
          </button>
        </div>
      </div>
    </div>

    <img
      :class="{ icon_button: true, active: projectorActive }"
      @click.stop="togglePopup"
      src="@/assets/images/icon_small.png"
    />

    <div class="line" v-if="projectorActive"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useClipboard } from "@vueuse/core";
import { useProjector } from "@/util/presentation";
import ToggleButton from "@/components/common/ToggleButton.vue";

const { copy } = useClipboard();
let showModal = ref(false);

const {
  isEnabled: projectorActive,
  code,
  presentation,
  shareLink,
} = useProjector();
const togglePopup = () => (showModal.value = !showModal.value);
const closePopup = () => (showModal.value = false);
const toggleProjector = () => (projectorActive.value = !projectorActive.value);
const copyCode = () => {
  if (code.value) copy(code.value);
};
</script>

<style lang="scss" scoped>
$padding: 0 15px;

.projector_launcher {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
}

.icon_button {
  height: 30px;
  width: auto;
  background-color: #aeaeb2;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  float: right;
}

.icon_button.active {
  background-color: #db006e;
}

.popup {
  display: none;
  margin-bottom: 5px;
  height: 400px;
  width: 280px;
  background-color: #fff;
  border: 1px solid #d0d4d9;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.popup.active {
  display: block;
}

.popup .header {
  border-bottom: 1px solid #d0d4d9;
  line-height: 2.5em;
  height: 2.5em;
  font-weight: 500;
  text-align: center;
  position: relative;
}

.popup .header .close_button {
  box-sizing: border-box;
  height: inherit;
  position: absolute;
  left: 0;
}

.popup .header .close_button span {
  box-sizing: border-box;
  line-height: inherit;
  padding: 0 8px;
  font-size: 1em;
}

.popup .header .close_button span:hover {
  cursor: pointer;
}

.popup .title {
  margin-top: 20px;
  line-height: 1.8em;
  font-size: 0.7em;
  padding: $padding;
  text-transform: uppercase;
  color: rgba(25, 23, 17, 0.6);
}

.popup .link_field {
  border-bottom: 1px solid #d0d4d9;
  border-top: 1px solid #d0d4d9;
  text-align: center;
  line-height: 2.5em;
  position: relative;
}

.popup .link_field .button {
  border: none;
  position: absolute;
  right: 0px;
  color: rgba(25, 23, 17, 0.6);
}

.popup .row {
  padding: $padding;
  margin: 30px 0;
}

.popup .row b {
  font-size: 0.9em;
}

.popup .row .toggle {
  float: right;
}

.line {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #db006e;
}
</style>
