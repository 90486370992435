<template>
  <div v-if="loading" class="loading_status">
    <LoadingSpinner :color="'black'" />
  </div>

  <div v-else>
    <section v-if="invitations && invitations.length > 0">
      <h4>{{ $t("teamList.invitations.title") }}</h4>
      <div class="columns">
        <TeamCard
          v-for="team in invitations"
          :key="team.id"
          :displayMetadata="true"
          @acceptInvitation="acceptInvitation"
          :team="team"
        />
      </div>
    </section>

    <section v-if="pendingTeams && pendingTeams.length > 0">
      <h4>{{ $t("teamList.pendingTeams.title") }}</h4>
      <div class="columns">
        <TeamCard
          v-for="team in pendingTeams"
          :key="team.id"
          :displayMetadata="true"
          :team="team"
        />
      </div>
    </section>

    <section v-if="activeTeams && activeTeams.length > 0">
      <h4>{{ $t("teamList.activeTeams.title") }}</h4>
      <div class="columns">
        <TeamCard
          v-for="team in activeTeams"
          :key="team.id"
          :displayMetadata="true"
          :team="team"
        />
      </div>
    </section>

    <section v-else>
      <p>{{ $t("teamList.noMemberships.info") }}</p>
    </section>

    <section v-if="tenantHasExternalGroups">
      <ExternalGroupImport />
    </section>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import {
  useAllTeamsQuery,
  useAcceptTeamInvitationMutation,
  useMyAccountQuery,
  useMyJoinableTeamsQuery
} from "@/graphql/operations";
import { TeamCard, ExternalGroupImport, LoadingSpinner } from "@/components";
import { Team } from "@/models";

const { data: myAccount } = useMyAccountQuery();
const tenantHasExternalGroups = computed(() => {
  return (
    myAccount.value &&
    myAccount.value.myAccount?.nodes &&
    myAccount.value.myAccount?.nodes.length > 0 &&
    myAccount.value.myAccount?.nodes[0] &&
    myAccount.value.myAccount?.nodes[0].tenant &&
    myAccount.value.myAccount?.nodes[0].tenant.hasExternalGroups
  );
});

const { data: teamResults, fetching: loading, executeQuery: fetch } = useAllTeamsQuery();
const activeTeams = computed(() =>
  teamResults.value?.myActiveTeams?.nodes.map((t: any) =>
    Team.fromResult(t)
  )
);

const pendingTeams = computed(() =>
  teamResults?.value?.myPendingTeams?.nodes.map((t: any) =>
    Team.fromResult(t)
  )
);

const invitations = computed(() =>
  teamResults?.value?.myInvitations?.nodes.map((t: any) =>
    Team.fromResult(t)
  )
);

const { data: joinableTeamsData } = useMyJoinableTeamsQuery();
const myJoinableTeamsCount = computed(() => joinableTeamsData?.value?.myJoinableTeams?.nodes.length);

const { executeMutation } =useAcceptTeamInvitationMutation();
const acceptInvitation = async (data: { id: string }) => {
  await executeMutation(data);
  fetch();
};
</script>

<style lang="scss" scoped>
$content_height: 150px;

.columns {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: $content-height;
  column-gap: 20px;
  row-gap: 20px;
  align-items: center;
  justify-content: flex-start;
}

h3 {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 38px;
  margin-top: 0;
}

h3 .button {
  float: right;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.4;
}

section {
  margin-bottom: 40px;
}

.page_content {
  height: 100%;
}
.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}
</style>
