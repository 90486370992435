<script lang="ts" setup>
import { CrmContact } from "@/graphql/operations";
import { fetchCRMConsentStatus, Consent } from "@/util";
import { PropType, ref } from "vue";

const props = defineProps({
  contact: {
    required: true,
    type: Object as PropType<CrmContact>
  }
});

const consentInfoVisible = ref<boolean>(false);
const loading = ref<boolean>(false);

const consentInfo = ref<Consent>();
const error = ref<string | null>();

const toggle = () => {
  if (consentInfoVisible.value === true) {
    consentInfoVisible.value = false;
  } else {
    displayConsentInfo();
  }
}

const displayConsentInfo = async () => {
  consentInfoVisible.value = true;

  if (!consentInfo.value) {
    loading.value = true;
    try {
      const data = await fetchCRMConsentStatus(props.contact.id);
      consentInfo.value = data;
    } catch (e: any) {
      error.value = e?.message ? e.message : "Could not fetch consent information";
    }

    loading.value = false;
  }
}
</script>

<template>
  <div class="row">
    <div class="contact_info">
      <div>
        <span class="material-symbols-outlined clickable" @click="toggle">
          <template v-if="consentInfoVisible">
            keyboard_arrow_down
          </template>
          <template v-else>
            chevron_right
          </template>
        </span>
      </div>
      <div>{{ contact.firstName }} {{ contact.lastName }}</div>
      <div>{{ contact.email }}</div>
      <div>{{ contact.mobile }}</div>
      <div>{{ contact.territory }}</div>
    </div>
    <div class="consent_info" v-show="consentInfoVisible">
      <div v-if="loading">
        Loading...
      </div>
      <div v-else-if="consentInfo">
        <div v-if="consentInfo.permissionStatusEmail === 'Opt-in'">
          Email Opt-In: {{ consentInfo.permissionEmail }}
        </div>
        <div v-else>
          No Email Opt-in given
        </div>
        <div v-if="consentInfo.permissionStatusMobileMessages == 'Opt-in'">
          Mobile Messages Opt-In: {{ consentInfo.permissionMobileNumber }}
        </div>
        <div v-else>
          No Mobile Messages Opt-in given
        </div>
      </div>
      <div v-else-if="error">
        <div>{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row {
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  font-size: .9em;
}

.consent_info,
.contact_info {
  display: flex;
  flex-direction: row;
}

.contact_info div {
  width: calc((100% - 30px) / 4);
  line-height: 2.8;
  overflow: hidden;
  text-overflow: ellipsis;
}

.consent_info {
  padding-left: 30px;
}

.consent_info div {
  line-height: 2.8;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.contact_info div:first-child {
  width: 30px;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

</style>