import {
  type DocumentSpaceConfig,
  UpdateDocumentSpaceConfigFooterDocument,
  UpdateDocumentSpaceConfigTranslationFooterDocument,
  UpdateDocumentSpaceConfigFooterLinksDocument,
  UpdateDocumentSpaceConfigTranslationFooterLinksDocument,
  UpdateDocumentSpaceConfigBannerDocument,
  UpdateDocumentSpaceConfigTranslationBannerDocument,
  CreateDocumentSpaceConfigLinkDocument,
  UpdateDocumentSpaceConfigLinkDocument,
  CreateDocumentSpaceConfigLinkTranslationDocument,
  UpdateDocumentSpaceConfigLinkTranslationDocument,
  UpdateDocumentSpaceConfigLegalNoteDocument,
  UpdateDocumentSpaceConfigTranslationLegalNoteDocument,
} from "@/graphql/operations";
import graphqlClient from "@/graphql/client";

export class Config {
  id: string;
  isTranslation: boolean;
  heroImageUrl: string | undefined | null;
  heroTitle: string | undefined | null;
  heroLinkUrl: string | undefined | null;
  footerText: string | undefined | null;
  logoUrl: string | undefined | null;
  legalNote: string | undefined | null;
  currentLanguage: string;
  currentLanguageCode: string;
  defaultLanguage: string;
  languages: Array<string>;
  name: string;
  teamId: string;
  footerLinks: { [key: string]: any }[] | undefined | null;

  constructor(data: DocumentSpaceConfig, currentLanguage: string) {
    const translations = data.documentSpaceConfigTranslationsByConfigId.nodes;
    const translatedConfig = translations?.find(
      (t) => t!.languageCode === currentLanguage
    );

    this.id = translatedConfig ? translatedConfig.id : data.id;
    this.isTranslation = translatedConfig ? true : false;
    this.heroImageUrl = translatedConfig?.heroImageUrl || data.heroImageUrl;
    this.heroTitle = translatedConfig
      ? translatedConfig.heroTitle
      : data.heroTitle;
    this.heroLinkUrl = translatedConfig
      ? translatedConfig.heroLinkUrl
      : data.heroLinkUrl;
    this.footerText = translatedConfig
      ? translatedConfig.footerText
      : data.footerText;
    this.logoUrl = data.logoUrl;
    this.legalNote = translatedConfig
      ? translatedConfig.legalNote
      : data.legalNote;
    this.currentLanguage = currentLanguage;
    this.currentLanguageCode =
      currentLanguage === "default" ? data.defaultLanguage : currentLanguage;
    this.defaultLanguage = data.defaultLanguage;
    this.name = data.name!;
    this.teamId = data.teamId!;
    this.footerLinks = translatedConfig
      ? (translatedConfig.footerLinks as { [key: string]: any }[])
      : (data.footerLinks as { [key: string]: any }[]);

    this.languages = [this.defaultLanguage].concat(
      translations.map((t) => t!.languageCode)
    );
  }

  async updateFooter(text: string) {
    const mutation = this.isTranslation
      ? UpdateDocumentSpaceConfigTranslationFooterDocument
      : UpdateDocumentSpaceConfigFooterDocument;

    const result = await graphqlClient
      .mutation(mutation, { id: this.id, footerText: text })
      .toPromise();

    return result;
  }

  async updateFooterLinks(footerLinks: { [key: string]: any }) {
    const mutation = this.isTranslation
      ? UpdateDocumentSpaceConfigTranslationFooterLinksDocument
      : UpdateDocumentSpaceConfigFooterLinksDocument;

    const result = await graphqlClient
      .mutation(mutation, { id: this.id, footerLinks: footerLinks })
      .toPromise();

    return result;
  }

  async updateBanner(
    imageUrl: string | null | undefined,
    linkUrl: string | null | undefined
  ) {
    const mutation = this.isTranslation
      ? UpdateDocumentSpaceConfigTranslationBannerDocument
      : UpdateDocumentSpaceConfigBannerDocument;

    const result = await graphqlClient
      .mutation(mutation, {
        id: this.id,
        heroImageUrl: imageUrl,
        heroLinkUrl: linkUrl,
      })
      .toPromise();

    return result;
  }

  async updateLegalNote(content: string | null) {
    const mutation = this.isTranslation
      ? UpdateDocumentSpaceConfigTranslationLegalNoteDocument
      : UpdateDocumentSpaceConfigLegalNoteDocument;

    const result = await graphqlClient
      .mutation(mutation, { id: this.id, legalNote: content })
      .toPromise();

    return result;
  }

  async createOrUpdateLink(
    linkId: string | undefined,
    translationId: string | undefined,
    sectionTitle: string | undefined | null,
    title: string,
    url: string,
    isMandatory: boolean
  ) {
    // create a new link
    if (!linkId) {
      const res = await graphqlClient
        .mutation(CreateDocumentSpaceConfigLinkDocument, {
          configId: this.id,
          sectionTitle: sectionTitle,
          title: title,
          url: url,
          isMandatory: isMandatory,
        })
        .toPromise();

      if (res.error) {
        throw new Error(res.error.message);
      }

      return res.data?.createDocumentSpaceLink?.documentSpaceLink?.id;
    }

    // create a new link translation
    if (this.isTranslation && !translationId) {
      const res = await graphqlClient
        .mutation(CreateDocumentSpaceConfigLinkTranslationDocument, {
          linkId: linkId,
          sectionTitle: sectionTitle,
          title: title,
          url: url,
          languageCode: this.currentLanguage,
        })
        .toPromise();

      if (res.error) {
        throw new Error(res.error.message);
      }

      return null;
    }

    // update an existing link translation
    if (this.isTranslation && translationId) {
      const res = await graphqlClient
        .mutation(UpdateDocumentSpaceConfigLinkTranslationDocument, {
          id: translationId,
          sectionTitle: sectionTitle,
          title: title,
          url: url,
        })
        .toPromise();

      if (res.error) {
        throw new Error(res.error.message);
      }

      return null;
    }

    const res = await graphqlClient
      .mutation(UpdateDocumentSpaceConfigLinkDocument, {
        id: linkId,
        sectionTitle: sectionTitle,
        title: title,
        url: url,
        isMandatory: isMandatory,
      })
      .toPromise();

    if (res.error) {
      throw new Error(res.error.message);
    }

    return null;
  }
}
