<template>
  <div>
    <Modal
      :active="active"
      @close="closePopup"
      class="large without_footer"
      data-test="upload-modal"
    >
      <template v-slot:header>
        {{ $t("upload.header") }}
      </template>
      <template v-slot:body>
        <div class="inner">
          <div class="uploader">
            <FileInput
              v-if="files.length === 0"
              @submit="setFiles"
              :singleFile="singleFile"
              :mimeTypes="replacementTypes"
            />

            <SelectedFiles
              v-else
              v-model="files"
              @removeFile="removeFile"
              @addFiles="addFiles"
              @startUpload="startUpload"
              :fileStates="fileStates"
              :singleFile="singleFile"
              :mimeType="mimeType"
              :isRunning="isRunning"
            />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { Modal } from "@/components";
import { reactive, ref, Ref, PropType } from "vue";
import {
  UploadService,
  AnalyticsService,
  EventType,
  UploadResponseData,
  MimeType,
} from "@/util";
import { auth } from "@/fire";
import {
  WorkspaceDocumentFragment,
  useSendWorkspaceNotificationMutation,
  PvNotificationEventType,
} from "@/graphql/operations";
import DocumentService from "@/document";
import { useStore } from "vuex";
import FileInput from "./FileInput.vue";
import SelectedFiles from "./SelectedFiles.vue";
import AddFileButton from "./AddFileButton.vue";
import { ErrorType, FileState, UploadState } from "./state";
import { captureException } from "@sentry/vue";

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  doc: {
    type: Object as PropType<WorkspaceDocumentFragment>,
    required: false,
  },
  singleFile: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["close", "success"]);
const closePopup = () => {
  emit("close");
};

const handleSuccess = () => {
  emit("success");
  closePopup();
};

const store = useStore();

const mimeType = props.doc
  ? MimeType.getMimeType(
      props.doc.originalMimeType
        ? props.doc.originalMimeType
        : props.doc.mimeType
    )
  : undefined;

const replacementTypes = props.doc
  ? MimeType.allowedReplacementTypes(
      props.doc.originalMimeType
        ? props.doc.originalMimeType
        : props.doc.mimeType
    )
  : undefined;

const files = ref<Array<File>>([]);
const fileStates = ref<Array<FileState>>([]);
const setFiles = (fileList: FileList) => {
  files.value = Array.from(fileList);
  for (let i = 0; i < files.value.length; i++) {
    fileStates.value[i] = {
      progress: 0,
      uploadState: UploadState.Idle,
    };
  }
};

const addFiles = (fileList: FileList) => {
  const newFiles = Array.from(fileList);
  const newIdx = fileStates.value.length;

  files.value = [...files.value, ...newFiles];
  for (let i = newIdx; i < files.value.length; i++) {
    fileStates.value[i] = {
      progress: 0,
      uploadState: UploadState.Idle,
    };
  }
};

const removeFile = (idx: number) => {
  files.value!.splice(idx, 1);
  fileStates.value!.splice(idx, 1);
};

const isRunning = ref<boolean>(false);
const errorOccurred = ref<boolean>(false);
const startUpload = async (notify: boolean) => {
  const token = await auth.currentUser!.getIdToken();
  isRunning.value = true;

  if (!files.value) {
    throw ErrorType.NoFileSelected;
  }

  if (files.value.length === 0) {
    throw ErrorType.NoFileSelected;
  }

  for (let i = 0; i < files.value.length; i++) {
    try {
      const postgresId = await upload(token, files.value[i], i);
      if (notify) {
        try {
          await sendNotification(files.value[i], postgresId);
        } catch (e) {
          console.error(e);
          /* fallthrough */
        }
      }
    } catch (e) {
      fileStates.value[i].uploadState = UploadState.Error;
      fileStates.value[i].error = e as ErrorType;
      errorOccurred.value = true;
      captureException(e);
    }
  }

  if (!errorOccurred.value) {
    handleSuccess();
  }
};

const { executeMutation } = useSendWorkspaceNotificationMutation();
async function sendNotification(file: File, postgresId: string) {
  const workspace = store.getters.currentWorkspace;
  const bodyText = props.doc
    ? `Das Dokument ${props.doc.displayTitle} im Workspace ${workspace.name} wurde geändert`
    : `Das Dokument ${file.name} im Workspace ${workspace.name} wurde hochgeladen`;

  const res = await executeMutation({
    eventType: PvNotificationEventType.DocUpdate,
    workspaceId: workspace.firebaseId,
    headerText: "Neues Dokument",
    bodyText: bodyText,
    documentId: postgresId
  });
}

async function upload(token: string, file: File, i: number): Promise<string> {
  if (replacementTypes && !MimeType.contains(replacementTypes, file.type)) {
    throw ErrorType.WrongMimeType;
  }

  fileStates.value[i].progress = 0;
  fileStates.value[i].uploadState = UploadState.Running;

  let intervalId: number | undefined = undefined;

  const onProgress = (progress: number) => {
    fileStates.value[i].progress = Math.round(progress * 0.8);

    if (progress === 100 && intervalId === undefined) {
      /* start synthetic progress */
      intervalId = window.setInterval(() => {
        if (fileStates.value[i].progress < 99) {
          fileStates.value[i].progress++;
        }
      }, 800);
    }
  };

  const formData = new FormData();
  formData.append("file", file);
  let fileData: UploadResponseData;
  try {
    fileData = await UploadService.upload(formData, token, onProgress);
  } catch (e) {
    console.error(e);
    switch ((e as { status: number; message: string }).status) {
      case 400:
        throw ErrorType.MissingFile;
      case 401:
        throw ErrorType.Unauthorized;
      case 415:
        throw ErrorType.UnsupportedMimeType;
      case 413:
        throw ErrorType.MaxSizeExceeded;
      default:
        throw ErrorType.InternalServerError;
    }
  }

  let postgresId;
  let firestoreId;

  /* new document */
  if (props.doc === undefined) {
    const fileProps = {
      workspaceId: store.getters.currentWorkspace.id,
      storageId: fileData.storageId,
      thumbnailStorageId: fileData.thumbnailStorageId,
      thumbnailDownloadUrl: fileData.thumbnailDownloadUrl,
      downloadUrl: fileData.downloadUrl,
      fileName: file.name,
      topTitle: store.getters.currentWorkspace.name,
      displayTitle: file.name,
      pages: fileData.pageCount ? fileData.pageCount! : fileData.duration!,
      fileSize: file.size.toString(),
      mimeType: fileData.mimeType,
      originalMimeType: fileData.originalMimeType,
      externalLinkId: "",
    };

    if (fileStates.value[i].progress < 90) {
      fileStates.value[i].progress = 90;
    }

    try {
      const result = await DocumentService.create(fileProps);
      postgresId = result.id;
      firestoreId = result.firestoreId;
    } catch {
      throw ErrorType.InternalServerError;
    }

    /* document already exists */
  } else {
    postgresId = props.doc!.id;
    firestoreId = props.doc!.firestoreId;

    const fileProps = {
      storageId: fileData.storageId,
      thumbnailStorageId: fileData.thumbnailStorageId,
      thumbnailDownloadUrl: fileData.thumbnailDownloadUrl,
      downloadUrl: fileData.downloadUrl,
      fileSize: file.size.toString(),
      mimeType: fileData.mimeType,
      originalMimeType: fileData.originalMimeType,
      pages: fileData.pageCount ? fileData.pageCount! : fileData.duration!,
      fileName: file.name,
    };

    if (fileStates.value[i].progress < 90) {
      fileStates.value[i].progress = 90;
    }

    try {
      await DocumentService.updateFile(postgresId, fileProps);
    } catch {
      throw ErrorType.InternalServerError;
    }

    if (fileStates.value[i].progress < 95) {
      fileStates.value[i].progress = 95;
    }
  }

  clearInterval(intervalId);
  fileStates.value[i].progress = 100;
  fileStates.value[i].uploadState = UploadState.Success;

  AnalyticsService.logEvent(
    EventType.DocumentUploaded,
    `${file.name} (${file.name})`,
    JSON.stringify({
      id: postgresId,
      firestoreId: firestoreId,
    })
  );

  return postgresId;
}
</script>

<style scoped>
.inner {
  height: 100%;
}

.uploader {
  text-align: center;
  line-height: 1.1em;
  height: 100%;
  position: relative;
}

h3 {
  font-size: 1em;
}

.drop_icon span {
  font-size: 3.3em;
  padding: 15px 0;
  color: rgba(25, 23, 17, 0.6);
}

.button {
  font-size: 1em;
  margin: 25px 0 5px 0;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.info {
  display: block;
  font-size: 0.8em;
}
</style>
