export const translations = {
  en: {
    cancel: "Cancel",
    back: "Back",
    submit: "Submit",
    skip: "Skip",
    error: "An error occurred",
    ok: "OK",
    new: "New",
    undo: "Undo",
    appLoading: "Initializing your workspace...",
    codeInput: {
      header: 'Please enter your code',
      connect: 'Connect'
    },
    timeFormat: {
      todayAt: "Today at {time}",
      yesterdayAt: "Yesterday at {time}",
      today: "Today",
      yesterday: "Yesterday",
      weekday: {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday"
      }
    },
    upload: {
      header: "Upload files",
      title: {
        bold: "Choose a PDF file",
        normal: "or drop it here"
      },
      dropTitle: "or drag and drop files into this box",
      browseFiles: {
        title: "Add Files"
      },
      totalFileSize: "Total file size",
      progress: "{percentage}% uploaded",
      progressLabel: {
        Uploading: "Uploading",
        Processing: "Processing",
        UploadingMetadata: "Uploading Metadata"
      },
      error: {
        title: "An error occured",
        missingFile: "No file sent",
        unsupportedMimeType: "File type not supported. Please select a .pdf, .xlsx, .docx or .mp4 file.",
        maxSizeExceeded:
          "File too large. Please select a file that does not exceed 500MB.",
        noFileSelected: "No file selected. Please select a file.",
        tooManyFilesSelected:
          "Too many files selected. Please select one file only.",
        internalServerError: "Internal Server Error",
        unauthorized: "Unauthorized",
        wrongMimeType: "Please select a file with the same file type as the existing one",
        retry: "Retry"
      },
      loading: "Upload running. Please wait, this may take a moment",
      selectedFile: {
        name: "Filename",
        fileSize: "Size",
        state: "Status"
      },
      sendNotificationLabel: "Send Notification"
    },
    presentation: {
      paused: {
        title: "Presentation paused",
      },
      stopped: {
        title: "Presentation finished",
        newCode: 'Enter new code'
      },
      notFound: {
        title: "Sorry, the presentation you have requested does not exist",
        info:
          "Please make sure that you have the correct URL or contact support."
      },
      copied: "Copied to Clipboard",
      fullscreen: "Fullscreen",
      enableVideo: {
        label: 'Allow Video'
      }
    },
    notFound: {
      title: "Page Not Found",
      info: "We can't find the page you are looking for."
    },
    login: {
      header: "Sign In / Sign Up"
    },
    navbar: {
      uploadFile: {
        label: "Upload file"
      },
      shareDocument: {
        label: "Share document",
        notShareable: "Document not shareable"
      },
      menu: {
        pvdocuments: "Documents",
        upload: "Upload",
        presentation: "Presentation"
      }
    },
    sidebar: {
      workspaces: {
        private: "Private",
        shared: "Shared",
        title: "Workspaces"
      },
      ssoLogin: {
        linkText: "Login with { tenantName } SSO"
      },
      settings: {
        title: "Settings"
      },
      documentSpaces: {
        title: "Shared Spaces"
      },
      analytics: {
        title: "Analytics"
      },
      documentAnalytics: {
        title: "Document Analytics"
      },
      spaceAnalytics: {
        title: "Shared Space Analytics"
      },
      usageAnalytics: {
        title: "Usage Analytics"
      },
      createWorkspace: "Create Workspace",
      createSharedSpace: "Create Shared Space",
      customFlows: {
        title: "Custom Flows"
      },
      crmContacts: {
        title: "CRM Integration"
      }
    },
    workspace: {
      notFound: "This workspace could not be found",
      subfolder: {
        label: "Folder",
        numItems: "Empty | 1 item | {count} items",
      }
    },
    pvdocuments: {
      empty: "The workspace is empty.",
    },
    workspacesOverview: {
      title: "Your Workspaces",
      createNew: "Create Workspace",
      emptyState: {
        title: "You don't have any workspaces yet",
        info: "Create a new workspace to get started"
      },
      columns: {
        name: "Name"
      }
    },
    workspaceDetail: {
      title: "Detail",
      teamSubscriptions: {
        title: "Teams",
        empty: "There aren't subscribing teams yet",
        modalHeader: "Manage subscriptions",
        info: "This list may not be complete as you can only see teams you belong to",
        modalInfo: "You can only select teams whose admin you are",
        noAdminTeams: "You aren't admin in any team yet"
      },
      rename: {
        buttonText: "Rename",
        modalHeader: "Rename"
      },
      delete: {
        buttonText: "Delete",
        subfolderModalHeader: "Delete subfolder",
        modalHeader: "Delete workspace",
        subfolderConfirmation: "Do you really want to delete the subfolder { subfolderName }?",
        confirmation: "Do you really want to delete the workspace { workspaceName }?",
        confirmationButtonText: "Yes, delete",
        notAllowed: "Workspaces containing members, documents or subfolders can't be deleted"
      },
      move: {
        buttonText: "Move",
        success: "Successfully moved",
        goToWorkspace: "Go to Workspace"
      },
      manageTeams: {
        buttonText: "Manage"
      },
      actionMessage: {
        success: "Changes successfully saved"
      },
      createSubfolder: {
        buttonText: "New Subfolder",
        modalHeader: "Create new subfolder"
      }
    },
    pvCardDoc: {
      badge: {
        references: "References",
        draft: "Draft"
      },
      info: {
        pages: "Pages",
        duration: "Duration",
        tags: "Tags"
      },
      published: "Published",
      ignoredPages: "Ignored Pages",
      updateDocument: "Replace document",
      tooltip: {
        share: "Share",
        copy: "Copy",
        move: "Move",
        edit: "Edit"
      },
      expirationDate: "Expiring"
    },
    pvDocEditor: {
      header: "Edit Document",
      fileInfo: {
        pages: "Pages"
      },
      topTitle: {
        label: "Category"
      },
      displayTitle: {
        label: "Title"
      },
      teaser: {
        label: "Description",
        placeholder: "Add a short description (or something similar) here"
      },
      tags: {
        label: "Tags"
      },
      deleteDoc: {
        header: "Delete Document?",
        confirmationQuestion: "Are you sure you want to delete this document?",
        confirm: "Yes, delete",
        cancel: "Cancel",
        isUsed: {
          header: "You are about to delete a document that is currently in use. Deleting this document will result in several consequences:",
          presentationUsage: "Nothing | A total of one presentation linked to this document will be broken and will no longer function correctly. | A total of {count} presentations linked to this document will be broken and will no longer function correctly.",
          spaceUsage: "Nothing | This document will be removed from one Shared Space, and users will no longer have access to it. | This document will be removed from {count} Shared Spaces, and users will no longer have access to it.",
          flowUsage: "Nothing | Pages from this document will be removed from one Custom Flow, which could cause issues with workflows relying on these pages. | Pages from this document will be removed from {count} Custom Flow, which could cause issues with workflows relying on these pages.",
          suggestion: "If you are only trying to update the document version, we recommend that you do not delete the document. Instead, you can easily upload a new version by clicking the upload icon in the editor.",
          contact: "If you have any questions or need assistance, please feel free to contact us:"
        },
      },
      externalId: {
        label: "PromoMats ID",
        info: "Vault PromoMats is a registered Trademark of Veeva Systems"
      },
      cancel: "Cancel",
      submit: "Save",
      delete: "Delete",
      back: "Back",
      download: "Download",
      move: "Move",
      copy: "Copy",
      preview: "Preview",
      updateDoc: {
        success: "Document successfully updated"
      },
      processing: "Your document has been successfully uploaded. We're now processing the file. Please bear with us, as this may take a moment.",
      info: {
        pages: {
          label: "Pages"
        },
        duration: {
          label: "Duration"
        },
        createdAt: {
          label: "Created"
        },
        updatedAt: {
          label: "Modified"
        },
        type: {
          label: "Type"
        },
        size: {
          label: "Size"
        },
        shareable: {
          label: "Document may be shared"
        },
        isDownloadable: {
          label: "Document may be downloaded"
        },
        supportsFlows: {
          label: "May be added to custom flows"
        },
        displayTeaserTextInSpace: {
          label: "Display description in Shared Spaces"
        },
        linkings: {
          label: "Linked from",
          linkingPage: "Page",
          withNoAccess: "Linked from one more document you don't have access to | Linked from {count} more documents you don't have access to"
        }
      },
      fileUpdate: {
        tooltip: "Upload new document"
      },
      flashMessage: {
        success: "Successfully saved",
        error: "An error occurred and the changes could not be saved"
      }
    },
    magicPageStudio: {
      title: "Magic Page Studio",
      configurator: "Configurator",
      page: "Page",
      type: "Type",
      magicPageTypes: {
        NORMAL: "Normal Page",
        POPOVER: "Popover",
        MAGIC_LINK: "Magic Link"
      },
      editor: {
        previousPage: "Previous Page",
        nextPage: "Next Page",
        linkDocument: {
          info: "Please select a document",
          button: "Select document",
          label: "Linked document",
          change: "To link another document, please select one from the list below"
        },
      },
      wizard: {
        header: {
          firstStep: "Configure Page {pageNumber}: Select a type",
          secondStep: "Configure Page {pageNumber}: Select a linked document",
          firstPage: "Page 1"
        },
        nextPage: "Next Page",
        previousPage: "Previous Page",
        lastPage: "Last Page",
        firstPage: "First Page",
        normalPage: {
          title: "Normal Page",
          info: "The page will be displayed normally in the PDF."
        },
        popover: {
          title: "Popover",
          info: "The page must be linked from another page in the PDF (standard internal PDF link). The page will then open as a popover when you click a link to it in the PDF."
        },
        magicLink: {
          title: "Magic Link",
          info: "The page must be linked from another page via an internal PDF link. When clicked, the linked document will open instead of the page.",
          linkedDocument: {
            label: "Linked to:",
            noAccess: "You can see the document but you don't have access to the workspace of the document."
          }
        },
        advanced: {
          label: "Advanced",
          info: "If you’d like, you can link documents from other workspaces. However, be cautious! Linking documents will grant users access to them, even if they are not members of the original workspace.",
          warning: "Linking documents grants users access, even if they aren’t members of the original workspace. Do you want to proceed?",
          resetButton: "No, use normal mode",
          acceptButton: "Yes, switch to advanced mode"
        },
        nextButton: {
          label: "Next"
        }
      },
      cancelEdit: "Close",
      saveButton: {
        label: "Save",
        disabled: "Please select a document"
      },
      editButton: {
        label: "Edit",
        disabled: "You cannot configure the first page of a document",
      },
      saveInfoText: "Please note, changes are not saved before you click the Save button",
      convertPrevious: {
        warning: "Please note, all previous pages will be converted to Normal Pages"
      },
      convertSubsequent: {
        warning: "Please note, all subsequent Normal Pages will be converted to Popover Pages"
      },
      flashMessage: {
        success: "Successfully saved",
        error: "An error occurred and the changes could not be saved."
      },
      description: `You can link to other documents or videos or configure a page as a popover.
      The page must be linked from another page in the PDF (normal internal PDF link).
      If you configure the page as Magic Link, the linked document or video will be opened instead. If you configure the page as Popover, it will open as a popover when you click on a link in the PDF to the page. `
    },
    magicPagesSetup: {
      enable: {
        info: "Magic Pages give you the ability to link pages and documents within a presentation. Click on the enable button to test it out.",
        button: "Enable Magic Pages"
      },
      loading: {
        info: "Please wait while we're setting up the magic pages. This may take a moment."
      }
    },
    pvDocTags: {
      docTags: {
        label: "Tags"
      },
      workspaceTags: {
        label: "Workspace Tags"
      },
      addTag: {
        placeholder: "Add Tag"
      },
      createTag: {
        label: "Create New"
      },
      tagAlreadySelected: "Tag already selected"
    },
    folderPicker: {
      header: {
        COPY: "Copy {name} to another workspace",
        MOVE: "Move {name}"
      },
      currentLocation: "Current Location",
      cancel: "Cancel",
      submit: {
        COPY: "Copy",
        MOVE: "Move"
      },
      noWorkspaces: "No (sub)workspaces",
    },
    docCopyDialog: {
      actionComplete: {
        goToWorkspace: "Go to workspace",
        error: {
          COPY: "An error occurred and the document could not be copied",
          MOVE: "An error occurred and the document could not be moved"
        },
        success: {
          COPY: "Document copied.",
          MOVE: "Document moved."
        }
      }
    },
    pvDocReferences: {
      link: {
        display: "Display references"
      }
    },
    emailVerification: {
      emailSentHeader: "An email has been sent...",
      checkInbox: "Please check your inbox",
      resendHeader: "No email received?",
      resendButton: "Resend verification email",
      emailSent: {
        success: "An email has been sent successfully",
        error: "An error occurred while sending"
      },
      cancel: "Cancel"
    },
    projectorLauncher: {
      shareLink: "Share this link",
      toggleLabel: "Projector On/Off",
      shareCode: "Please use this code",
      header: {
        projectorActive: "Projector Active",
        projectorInactive: "Start Projector"
      }
    },
    joinableTeams: {
      join: {
        buttonText: "Join"
      },
      leave: {
        buttonText: "Leave"
      },
      header: "Team Onboarding",
      memberCount: "No members | 1 member | {count} members",
      membershipState: {
        ACTIVE: "Member",
        INVITED: "Invited",
        WAITS_FOR_ACCESS: "Requested",
        active: {
          true: "Admin",
          false: "Member"
        }
      },
      closedTeam: "Private"
    },
    teamDetail: {
      addUser: {
        buttonText: "Add User To Team"
      },
      manageWorkspaces: {
        buttonText: "Manage",
        selectWorkspaces: "Select Workspaces",
        flashMessage: {
          success: "All changes were successfully saved",
          error: "Not all changes could be saved"
        }
      },
      leaveTeam: {
        buttonText: "Leave Team",
        modalHeader: "Leave Team",
        confirmationText: "Are you sure you want to leave the team?",
        submit: {
          buttonText: "Yes"
        },
        cancel: {
          buttonText: "Cancel"
        }
      },
      invitedUsers: {
        title: "Invitations"
      },
      requests: {
        title: "Requests"
      },
      members: {
        title: "Members",
        removeFromTeam: {
          title: "Remove User"
        },
        changeRole: {
          title: "Change Role",
          header: "Change the role of {name}?",
          label: "Select a new role",
          admin: {
            label: "Admin",
            info: "Has full administrative access to the entire workspace."
          },
          member: {
            label: "Member",
            info: "Can see team members, workspaces and documents."
          },
          submit: "Change role",
          error: "An error occurred and the changes could not be saved",
          success: "Changes successfully saved"
        }
      }
    },
    teamList: {
      onboarding: {
        buttonText: "Join more teams"
      },
      noMemberships: {
        info: "You aren't member of any team yet"
      },
      invitations: {
        title: "My Invitations"
      },
      pendingTeams: {
        title: "My requests"
      },
      activeTeams: {
        title: "My Teams"
      }
    },
    tenantUserSearch: {
      emptyResult: {
        header: "No users found",
        info: "Try using a valid email address instead"
      },
      userAlreadyMember: {
        info: "Already member"
      },
      userAlreadySelected: {
        info: "User already selected"
      },
      invalidEmail: {
        info: "Please insert a valid email address"
      },
      header: "Add User To Team",
      nextStep: "Next",
      previousStep: "Back",
      search: {
        placeholder: "Search user"
      },
      submit: "Submit",
      notify: {
        label: "Notify by mail"
      },
      customText: {
        placeholder: "Additional message (optional)"
      },
      invitationInfo: "Invited users will be automatically added to the team once they signed up",
      userList: {
        header: "Selected Users"
      },
      emptyList: {
        info: "No users selected"
      }
    },
    documentSearch: {
      header: "Add document",
      search: {
        placeholder: "Search Document"
      },
      submit: "No Documents selected | Add 1 document | Add { count } documents",
      emptyResult: "No documents found",
      notShareable: "Sharing in Shared Spaces disabled",
      disabledForFlows: "Document disabled for Custom Flows",
      alreadySelected: "Already Selected",
      document: {
        numPages: "- | 1 page | { count } pages"
      }
    },
    externalGroupImport: {
      import: {
        buttonText: "Import Groups",
        infoText: "Please log in with { tenantName } SSO so that we can assign you to your teams",
        skip: {
          buttonText: "Skip"
        }
      },
      description:
        "You can import your groups to be added to more teams where applicable",
      memberships: {
        info: "The following memberships are managed by groups"
      },
      noMemberships: {
        info: "There are no memberships that are managed by groups"
      },
      success: "The import was successful"
    },
    unsupportedBrowser: {
      header: "Upgrade browser for full experience",
      info: "It looks like you may be using a browser version that we don't support. Please try using one of the supported browsers to get the full experience."
    },
    documentSpaces: {
      title: "Shared Spaces",
      columns: {
        name: "Name",
        lastModified: "Modified",
        content: "Content"
      },
      delete: "Delete",
      copy: "Copy",
      copyName: "Copy of {name}",
      numDocuments: "No Documents | One Document | {count} Documents",
      sharedDoc: "Shared",
      createNew: "Create Space",
      errorCreateNew: "An error occured, please try again",
      successCreateNew: "Shared Space successfully created",
      deleteConfirmation: {
        title: "Delete Shared Space",
        message: "Are you sure you want to delete the space {name}?",
        submit: "Yes, delete",
        success: "Shared Space successfully deleted"
      },
      emptyState: {
        title: "No Shared Spaces yet",
        info: "If you want to create a new one, please click on the button below"
      },
      search: {
        totalCount: "No results | 1 result | {count} results",
        placeholder: "Search for Shared Spaces",
        controls: {
          sortBy: {
            label: "Sorted by",
            mostRelevant: {
              label: "Most relevant"
            },
            modified: {
              label: "Modified"
            }
          }
        }
      }
    },
    documentSpace: {
      share: {
        buttonText: "Share"
      },
      rename: {
        buttonText: "Rename"
      },
      preview: {
        buttonText: "Preview",
        buttonTextBusinessCard: "Preview with business card"
      },
      copyLink: {
        buttonText: "Copy Link",
        buttonTextBusinessCard: "Copy Link with business card"
      },
      copyCampaignLink: {
        buttonText: "Copy Campaign Link"
      },
      sendMail: {
        buttonText: "Send Mail"
      },
      delete: {
        buttonText: "Delete"
      },
      personalMessage: {
        title: "Personal Message",
        save: "Save",
        unsavedChanges: "You have unsaved changes in your personal message. Would you like to save them?",
        discard: "Discard",
        placeholder: "Write a personal message"
      },
      documents: {
        title: "Documents"
      },
      contactDetails: {
        title: "Contact Details",
        displaySetting: "Display",
        editLink: "Edit Contact Details",
        moreUsers: "'' | One more user visible | {count} more users visible"
      },
      addDocument: {
        buttonText: "Add Document"
      },
      links: {
        title: "Links"
      },
      addLink: {
        buttonText: "Add Link"
      },
      emptyDocumentList: "Click the 'Add Document' button to start adding content to your space.",
      emptyLinkList: "Did you know? You can personalize your space with your own links. Simply click the 'Add Link' button to add one now.",
      crmMapping: {
        title: "CRM Contact",
        button: {
          text: "Assign to contact",
          info: "The space can be shared after a contact has been assigned."
        },
        tooltipLabel: {
          nameWithTerritory: "Linked to {firstName} {lastName} ({territory})",
          name: "Assigned to {firstName} {lastName}"
        }
      },
      configLinks: {
        title: "Template Links",
        mandatoryLink: {
          info: "This link is mandatory and can't be removed."
        }
      },
      disableTracking: {
        buttonText: "Disable Tracking",
        title: "Disable Tracking",
        warning: "Please note, you won't have any analytics data for this Shared Space after disabling tracking. This action can't be undone.",
        question: "Are you sure you want to disable tracking for this Shared Space?",
        submit: "Yes, disable tracking",
        textDeactivated: "Tracking disabled"
      },
      chooseTemplate: {
        buttonText: "Select Template"
      },
      doesNotExist: "This Shared Space does not exist.",
      mail: {
        recipient: {
          label: "Recipient Email",
          error: "Invalid email"
        },
        subject: {
          default: "Your documents",
          label: "Email Subject",
          error: "Subject may not be empty"
        },
        body: {
          default: "The link will provide you to the documents we talked about",
          label: "Email Body",
          hint: "Please note, <link> will be replace by the link to the Shared Space.",
          error: "<link> may not be removed"
        },
        error: "An error occurred and the mail could not be sent. Please try again or contact support if the problem persists."
      },
      missingConsent: {
        title: "No Consent",
        message: "Please be aware that this contact has not provided consent for email communications. Consider requesting their opt-in through the iOS app."
      },
      access: {
        title: "People with access",
        userSearch: {
          placeholder: "Add people",
          empty: "No users found"
        },
        invite: {
          title: "Invite",
          info: "Click the 'Invite' Button to add the user.",
          buttonText: "Invite"
        },
        displayDetails: {
          title: "Visible",
          info: "Contact details will be displayed in the Shared Space",
          disabled: {
            info: "No more than three users can be visible"
          }
        },
        hideDetails: {
          title: "Hidden",
          info: "Contact details won't be displayed in the Shared Space"
        },
        removeAccess: {
          title: "Remove access"
        },
        transferOwnership: {
          title: "Transfer ownership",
          info: "Please note: By proceeding with this action, you will loose your ownership of the space.",
          submit: "Submit"
        },
        owner: {
          title: "Owner"
        },
        isCurrentUser: "you"
      },
      copy: {
        buttonText: "Copy Space"
      },
      copyName: "Copy of {name}",
      demo: {
        banner: {
          title: "Demo Shared Space",
          text: "This shared space is intended for demo purposes only"
        }
      },
      sections: {
        add: "Add new section",
        createNew: {
          title: "Create a new section",
          placeholderName: "Documents"
        },
        delete: "Delete",
        edit: "Rename",
        moveUp: "Move up",
        moveDown: "Move down",
        addBelowThis: "Add new section below",
        deleteWithDocuments: {
          title: "What should be done with the documents of the section?",
          deleteAll: "Delete from Space",
          moveToOtherSection: "Move to another section",
          moveTo: "Move to this section"
        }
      }
    },
    copySpaceModal: {
      header: "Copy Shared Space",
      skip: "Skip",
      submit: "Copy",
      name: "Please enter a name",
      previousStep: "Go Back"
    },
    documentSpacePicker: {
      header: "Add to Shared Spaces",
      search: {
        placeholder: "Search Shared Space"
      },
      submitLabel: "'' | Share to 1 Space | Share to {count} Spaces",
      createNew: {
        buttonText: "New Space",
        label: "Please insert a name",
        submit: "Submit"
      },
      back: "Back",
      undoBar: {
        resultLabel: {
          single: "Added document to Shared Space",
          multiple: "Added to {count} Shared Spaces"
        },
        goToSpace: "Open Shared Space"
      },
      emptyList: "You don't have any Shared Spaces yet.",
      alreadySelected: "Document already in Shared Space"
    },
    documentSpaceLinkEditor: {
      header: {
        edit: "Edit Link",
        new: "Create new link"
      },
      submit: {
        new: "Submit",
        edit: "Submit"
      },
      form: {
        sectionTitle: {
          label: "Title",
          error: {
            missing: "Title is required"
          }
        },
        title: "Link Text",
        url: {
          label: "Link URL",
          error: {
            invalid: "Please enter a valid URL",
            missing: "URL is required"
          }
        }
      }
    },
    profile: {
      title: "My Profile",
      photo: "Photo",
      email: "Email",
      name: "Name and Surname",
      jobTitle: "Job Title",
      phone: "Phone",
      stateMessage: {
        success: "Changes have been save",
        error: "An error occured and the changes could not bet saved"
      },
      imageCrop: {
        title: "Crop your new profile picture"
      },
      fileSelect: {
        title: "Upload a picture", 
        error: {
          tooLarge: "Please choose a file smaller than 1MB.",
          wrongType: "We only support PNG or JPG pictures.",
          wrongNumber: "Please select a file."
        }
      },
      submit: "Update"
    },
    dashboard: {
      title: "Latest Visits",
      displayPreview: "Display Preview Visits",
      labelSpacesNotTracked: "''| Tracking is deactivated for one space | Tracking is deactivated for { count } spaces",
      columns: {
        name: "Name",
        interactions: "Interactions",
        numVisits: "Visits",
        latestVisit: "Latest",
      },
      interactions: {
        documentHit: "Document viewed",
        videoHit: "Video watched",
        documentDownload: "Document downloaded",
        linkClick: "Link clicked"
      },
      displayMore: "Display more visits",
      noData: {
        title: "No visits yet",
        text: "There is no data yet. Try again later.",
        textNoPreview: "There is no data yet. Please note, preview visits aren't included."
      }
    },
    visitDetail: {
      header: "Visits",
      totalVisits: "Visits",
      avgDuration: "Average Duration"
    },
    documentSpaceAnalytics: {
      title: "Shared Space Analytics",
      infoNoPreview: "Preview visits aren't included",
      noData: "There isn't any data for this space yet. Please note, preview visits aren't included.",
      totalVisits: {
        title: "Total Visits"
      },
      downloads: {
        title: "Downloads"
      },
      linkClicks: {
        title: "Link Clicks"
      },
      avgDuration: {
        title: "Average Duration"
      },
      topDocuments: {
        title: "Top Documents",
        metrics: {
          visits: "Views",
          downloads: "Downloads",
          duration: "Duration",
          maxDuration: "Max. Duration",
          topPage: "Top Page",
          numVisits: "Page Views",
          shares: "Number of Shares"
        },
        noData: "No documents have been viewed yet"
      },
      topVideos: {
        title: "Top Videos",
        metrics: {
          visits: "Views",
          downloads: "Downloads",
          numVisits: "Page Views",
          maxWatchRate: "Watched"
        },
        noData: "No videos have been viewed yet"
      },
      topPages: {
        title: "Top Page",
        metrics: {
          pageNumber: "Page Number",
          visits: "Views",
          averageDuration: "Duration",
          maxDuration: "Max. Duration",
          numVisits: "Views"
        }
      },
      topLinks: {
        title: "Top Links",
        noData: "No links have been clicked yet",
        metrics: {
          link: "Link",
          numberClicks: "Number of Clicks"
        }
      },
      visits: {
        title: "Space Visits",
        metrics: {
          date: "Date",
          duration: "Duration",
          interaction: "Interactions",
          openDocument: "Document viewed",
          clickLink: "Link clicked",
          downloadDocument: "Document downloaded"
        }
      }
    },
    documentAnalytics: {
      title: "Document Analytics",
      orderBy: {
        label: "Order By",
        numVisits: "Views",
        numShares: "Shares"
      },
      displayMore: "Load more",
      search: "Search Documents",
      emptySearch: {
        title: "Sorry, we couldn't find what you're looking for",
        info: "Please try another search term or contact support."
      },
      empty: {
        title: "We couldn't find any documents",
        info: "You probably don't have the permissions to see any documents yet. Please contact support."
      },
      noShares: "This document hasn't been shared yet",
    },
    analyticsOverview: {
      title: "Analytics Overview",
      info: "Here you find analytics of the performance of your Shared Spaces and the documents of your team",
      spaceAnalytics: {
        info: "The Shared Space analytics give you insights about which spaces were visited and how the documents within your spaces perform.",
        linkText: "Shared Space Analytics"
      },
      documentAnalytics: {
        info: "The document analytics give an insight of how the documents of your teams are performing",
        linkText: "Document Analytics"
      },
      usageAnalytics: {
        info: "Usage analytics provide an overview of how different features are utilized.",
        linkText: "Usage Analytics"
      }
    },
    usageAnalytics: {
      title: "Usage Analytics",
      byYear: {
        title: "Usage By Year"
      },
      users: {
        title: "Users",
        total: "Total",
        registered: "Registered",
        info: "Total includes invited users that haven't registered yet"
      },
      presentations: {
        title: "Remote Presentations"
      },
      documents: {
        title: "Documents"
      },
      app: {
        title: "App"
      },
      documentSpaces: {
        title: "Shared Spaces"
      },
      nUsersThatOpenedTheAppAtLeastOnce: {
        title: "Users That Opened the App at least once",
        info: "How many users did open the app at least once within the selected time range"
      },
      nNewUsersInLastSixMonths: {
        title: "New Users in Last Six Months"
      },
      nPresentations: {
        title: "Presentations",
        info: "How many Projector sessions (remote presentations) took place? The usage of this feature decreased after the lockdowns, as in-person meetings became possible again."
      },
      nNewUsersInLastYear: {
        title: "New Users in Last Year"
      },
      nTimesOpenedADocument: {
        title: "Document Openings",
        info: "How often was a document opened (e.g. for presenting, self-study etc.) within the selected time range",
      },
      nTimesOpenedTheApp: {
        title: "App Openings",
        info: "How often was the app opened within the selected time range"
      },
      nUploads: {
        title: "Uploads"
      },
      nUsersThatPresentedAtLeastOnce: {
        title: "Users that presented at least once"
      },
      nUsersTotal: {
        title: "Users",
        info: "Number of users who have ever been member of this team (not just for the selected time range)"
      },
      nSpaces: {
        title: "Spaces Created",
        info: "How many Shared Spaces were created"
      },
      nSpaceVisits: {
        title: "Space Visits",
        info: "How often were spaces, created by users of this team, visited"
      },
      nDocumentVisits: {
        title: "Document Visits",
        info: "How often were documents and videos within spaces created by this team's users viewed?”"
      },
      nDownloads: {
        title: "Document Downloads",
        info: "How often were documents and videos within spaces created by this team's users viewed?"
      },
      nLinkClicks: {
        title: "Link Clicks",
        info: "How often were (default or user added) links within spaces created by this team's users clicked?"
      },
      notFound: "No Analytics Found. Please select another team alias",
      noData: {
        title: "No Data in this Timespan",
        text: "There is no data for the selected timespan, please select another one."
      }
    },
    createWorkspaceModal: {
      header: "Create Workspace",
      submit: "Submit",
      inputLabel: "Please enter a name",
      created: {
        success: "Workspace successfully created",
        error: "An error occurred"
      }
    },
    crmContactSearch: {
      search: {
        placeholder: "Search a CRM contact"
      },
      emptyResult: "No matching contact found",
      updateName: {
        label: "Adjust the name of the Shared Space",
        nameWithTerritory: "{lastName}, {firstName} ({territory})",
        name: "{lastName}, {firstName}"
      }
    },
    crmContacts: {
      title: "CRM Contacts",
      pagination: {
        previous: "Previous",
        next: "Next"
      },
      warning: {
        spaces: "You already have a Shared Space for this contact"
      },
      info: {
        spaces: {
          totalCount: '0 | 1 Shared Space | {count} Shared Spaces'
        }
      },
      openSpace: "Open Space"
    },
    documentPresenter: {
      documentDeleted: "The linked document was deleted",
      controls: {
        closeFullscreen: "Close fullscreen",
        openFullscreen: "Open fullscreen",
        closeGallery: "Close gallery",
        openGallery: "Open gallery"
      },
      documentNotFound: "Loading document",
      processing: "The document is currently being processed and is not yet fully available."
    },
    customFlows: {
      title: "Custom Flows",
      createNew: "Create Flow",
      delete: "Delete Flow",
      emptyState: {
        title: "You don't have any custom flows yet",
        info: "Create a new flow to get started"
      },
      columns: {
        title: "Name"
      },
      deleteConfirmation: {
        title: "Delete Flow",
        message: "Are you sure you want to delete this flow?",
        submit: "Yes, delete",
        success: "Flow successfully deleted",
      },
      newFlowCreated: {
        success: "Flow successfully created",
        error: "An error occurred",
        header: "Create new Flow",
        inputLabel: "Please enter a flow title",
        submit: "Create Flow"
      },
      detail: {
        doesNotExist: "This flow does not exist.",
        addPagesLabel: "Append Pages",
        present: "Present",
        addPages: "Append Pages",
        rename: "Rename",
        delete: "Delete",
        share: "Share",
        dropdown: {
          delete: "Delete page",
          present: "Present from this page",
        },
        legalAppendix: {
          info: "This slide contains mandatory information. It was automatically added to your custom flow due to the underlying documents/presentations used and cannot be reordered or removed. This ensures compliance requirements are met"
        }
      },
      flashMessages: {
        addingPages: {
          success: "Pages successfully added",
          error: "Error adding pages, please try again"
        },
        deletingPage: {
          success: "Page successfully deleted",
          error: "Error deleting page, please try again"
        },
        renamingFlow: {
          success: "Flow successfully renamed",
          error: "Error renaming flow, please try again or contact our support"
        },
        deleteFlow: {
          success: "Flow successfully deleted",
          error: "Error deleting flow, please try again or contact our support"
        },
        share: {
          success: "Flow successfully shared",
          error: "Error sharing flow, please try again or contact our support"
        },
      },
      renameModal: {
        title: "Rename Flow",
        message: "Please enter a new title for the flow",
        submit: "Rename",
        placeholder: "Enter title"
      },
      removePageModal: {
        title: "Remove Page",
        message: "Are you sure you want to remove this page?",
        submit: "Yes, remove"
      },
      shareModal: {
        addedToSpace: {
          success: "Documents have been successfully added.",
          error: "An error occurred - documents could not be added.",
          goToSpace: "Visit Shared Space.",
        },
        submit: "Add Documents",
        title: {
          selectSpace: "Select Shared Space",
          selectDocuments: "Select Documents"
        },
        notShareable: "Document not shareable",
        alreadyShared: "Document already in space",
        noDocuments: "This flow contains no documents to share."
      }
    },
    flowPresenter: {
      controls: {
        openFullscreen: "Open fullscreen",
        closeFullscreen: "Close fullscreen",
        openGallery: "Open gallery",
        closeGallery: "Close gallery",
      }
    },
    documentPagePicker: {
      header: {
        document: "Select Document",
        pages: "Select Pages"
      },
      footer: {
        selectDocument: "Select Document",
        close: "Close",
        selectPages: "Add Selected Pages",
        goBack: "Previous Step"
      },
      newDocumentRevision: {
        info: "The selected document has been updated. Please re-select the pages."
      }
    },
    createDocumentSpaceModal: {
      header: {
        selectContact: "Create a Shared Space: Select a CRM Contact",
        insertName: "Create a Shared Space: Enter a name"
      },
      name: "Name",
      assignedContact: "Assigned Contact",
      limitExceeded: {
        title: "You reached the limit of free Shared Spaces",
        info: "Become a pro user and profit from unlimited Shared Spaces and many more features",
        buttonText: "Contact us"
      },
      skip: "Skip",
      continueWithSelection: "Continue with selection",
      previousStep: "Previous Step",
      submit: "Create Shared Space"
    },
    shareDocumentsModal: {
      header: "Share Documents",
      footer:{
        back: "Back",
        submit: "Add Document",
      },
      addedToSpace: {
        success: "Document successfully added.",
        alreadyExists: "Document already exsits in space.",
        error: "An error occurred - document could not be added.",
        goToSpace: "Visit Shared Space."
      },
    },
    appPromotionOverlay: {
      header: "Get a better experience with our app!",
      openApp: {
        buttonText: "Open App"
      },
      stay: {
        buttonText: "Continue"
      }
    },
    templateEditor: {
      personalMessage: {
        placeholderText: "A personal message will be displayed here."
      },
      languages: {
        de: {
          label: "German",
          defaultLabel: "German (default)"
        },
        en: {
          label: "English",
          defaultLabel: "English (default)",
        },
        fr: {
          label: "French",
          defaultLabel: "French (default)",
        },
        it: {
          label: "Italian",
          defaultLabel: "Italian (default)"
        },
        nl: {
          label: "Dutch",
          defaultLabel: "Dutch (default)"
        }
      },
      contactDetails: {
        placeholderText: "Contact details will be displayed here"
      },
      toolbar: {
        banner: {
          title: "Banner",
          image: {
            label: "Image",
            replace: "Replace",
            crop: {
              title: "Do you want to crop your image for better fit?",
              info: "The aspect ratio of your selected image doesn't match the ideal format. Some parts of the image might not be displayed correctly. You can crop it to ensure it fits better.",
              submit: "Yes, crop the image",
              cancel: "No, use the original"
            }
          },
          url: {
            label: "URL",
            info: "Optional: Enter the URL where users will be redirected when they click the banner."
          },
        },
        link: {
          title: "Link",
          sectionTitle: {
            label: "Section Title"
          },
          linkTitle: {
            label: "Title"
          },
          url: {
            label: "URL"
          },
          isMandatory: {
            label: "Mandatory"
          },
          delete: {
            title: "Delete Link",
            message: "Are you sure you want to delete this link?"
          }
        },
        footer: {
          title: "Footer Text"
        },
        footerLink: {
          title: "Footer Link",
          delete: {
            title: "Delete Footer Link",
            message: "Are you sure you want to delete this link?"
          }
        },
        logo: {
          title: "Logo",
          image: {
            label: "Image",
            replace: "Replace"
          },
        },
        legalNote: {
          title: "Legal Note"
        },
        settings: {
          title: "Settings"
        },
        fileSelect: {
          error: {
            tooLarge: "Please choose a file smaller than 1MB.",
            wrongType: "We only support PNG, JPG or SVG pictures.",
            wrongTypeImageGif: "We only support PNG, JPG and GIF pictures.",
            wrongNumber: "Please select a file."
          }
        },
        copy: "Copy",
        save: "Apply Changes",
        delete: "Delete Item",
      },
      addContent: {
        buttonText: "Add Content",
        disabledText: "Links can only be added in the default language"
      },
      notEditable: "This element is editable only in the default language",
      copy: {
        buttonText: "Copy"
      },
      copyModal: {
        title: "Copy Template",
        copyName: "Copy of {name}",
        teamSelection: {
          label: "Select a team"
        },
        submit: "Make a copy"
      }
    },
    templates: {
      title: "Shared Space Templates",
      emptyState: {
        title: "No Shared Space Templates",
        info: "We could not find any Shared Space Template you can edit"
      },
      columns: {
        name: "Name",
        team: "Team",
        lastModified: "Last Modified At"
      }
    },
    deleteConfirmation: {
      submit: "Delete"
    },
    discardChanges: {
      title: "Discard changes?",
      message: "You have made changes that haven't been saved yet. Are you sure you want to discard changes?",
      submit: "Yes"
    },
    documentHistory: {
      title: "Document History",
      empty: "You don't have any document views yet",
      label: {
        presentation: {
          title: "Presentation",
        },
        "self-study": {
          title: "Self Study"
        },
        default: {
          title: "Presentation",
          select: "Please select"
        }
      },
      multiselect: {
        label: "'' | Change one Document View to | Change {count} Document Views to"
      },
      todo: {
        tooltip: "You have unlabeled Document Views"
      }
    },
    settingsOverview: {
      title: "Settings",
      documentHistory: {
        buttonText: "See Your Document History",
        info: "Keep track of your document activities with our Document History feature. View all the documents you’ve locked or presented "
      },
      profile: {
        buttonText: "Edit your Profile",
        info: "Manage your displayed name and contact details here. Update your information to ensure it looks correct on Shared Spaces."
      }
    },
    promomatsIntegration: {
      title: "Import from Vault Promomats",
      isInWorkspace: "Already in Workspace",
      isInOtherWorkspace: "Already in Workspace",
      submit: "Import {numDocs} documents",
      next: "Proceed to Import",
      back: "Back",
      columns: {
        status: "Status",
        expirationDate: "Expiration",
        remove: "Remove"
      },
      search: {
        placeholder: "Enter name or id of document or product to filter list"
      },
      sort: {
        label: "Sort by",
        nameAsc: "Name ↓",
        nameDesc: "Name ↑",
        versionCreationAsc: "Version Created ↓",
        versionCreationDesc: "Version Created ↑",
        versionModificationAsc: "Version Modified ↓",
        versionModificationDesc: "Version Modified ↑"
      },
      isRunning: {
        label: "Importing documents..."
      },
      cancelImport: "Cancel import"
    }
  },
  de: {
    cancel: "Abbrechen",
    back: "Zurück",
    submit: "Speichern",
    skip: " Überspringen",
    error: "Ein Fehler ist aufgetreten",
    ok: "OK",
    new: "Neu",
    undo: "Rückgängig",
    appLoading: "Dein Workspace wird vorbereitet...",
    codeInput: {
      header: 'Bitte geben Sie Ihren Code ein',
      connect: 'Verbinden'
    },
    timeFormat: {
      todayAt: "Heute um {time}",
      yesterdayAt: "Gestern um {time}",
      today: "Heute",
      yesterday: "Gestern",
      weekday: {
        0: "Sonntag",
        1: "Montag",
        2: "Dienstag",
        3: "Mittwoch",
        4: "Donnerstag",
        5: "Freitag",
        6: "Samstag"
      }
    },
    upload: {
      header: "Dateien hochladen",
      title: {
        bold: "Bitte ein PDF auswählen",
        normal: "oder per Drag&Drop hierher ziehen"
      },
      dropTitle: "or ziehe Dateien per Drag & Drop in dieses Feld",
      browseFiles: {
        title: "Füge Dateien hinzu"
      },
      totalFileSize: "Gesamtdateiengröße",
      progress: "{percentage}% hochgeladen",
      progressLabel: {
        Uploading: "Hochladen",
        Processing: "Verarbeiten",
        UploadingMetadata: "Metadaten hochladen"
      },
      error: {
        title: "Ein Fehler ist aufgetreten",
        missingFile: "Es wurde keine Datei übertragen",
        unsupportedMimeType: "Dateityp nicht unterstützt. Bitte eine .pdf, .mp4, .xlsx oder .docx Datei auswählen.",
        maxSizeExceeded:
          "Datei zu groß. Bitte eine Datei auswählen die nicht größer ist als 500MB.",
        noFileSelected:
          "Keine Datei ausgewählt. Bitte wähle eine Datei aus.",
        tooManyFilesSelected:
          "Zu viele Dateien ausgewählt Bitte nur eine Datei auswählen",
        internalServerError: "Internal Server Error",
        unauthorized: "Nicht authentifiziert",
        wrongMimeType: "Bitte wähle eine Datei mit dem gleichen Typ wie die das existierende Dokument",
        retry: "Erneut versuchen"
      },
      loading: "Das Dokument wird hochgeladen. Dies kann einen Moment dauern",
      selectedFile: {
        name: "Name",
        fileSize: "Größe",
        state: "Status"
      },
      sendNotificationLabel: "Benachrichtigung schicken"
    },
    presentation: {
      notFound: {
        title: "Diese Präsentation existiert nicht.",
        info: "Bitte überpröfe die URL oder kontaktiere unser Support Team."
      },
      copied: "Copied to Clipboard",
      fullscreen: "Fullscreen",
      stopped: {
        title: 'Präsentation beendet',
        newCode: 'Neuen Code eingeben'
      },
      paused: {
        title: 'Präsentation pausiert'
      },
      enableVideo: {
        label: 'Video erlauben'
      }
    },
    notFound: {
      title: "Page Not Found",
      info: "Die Seite konnte nicht gefunden werden"
    },
    login: {
      header: "Sign In / Sign Up"
    },
    navbar: {
      menu: {
        pvdocuments: "Dokumente",
        upload: "Upload",
        presentation: "Präsentation"
      },
      shareDocument: {
        label: "Dokument teilen",
        notShareable: "Dokument nicht teilbar"
      },
      uploadFile: {
        label: "Dokument hochladen"
      }
    },
    sidebar: {
      workspaces: {
        private: "Persönliche Workspaces",
        shared: "Geteilte Workspaces",
        title: "Workspaces"
      },
      ssoLogin: {
        linkText: "Mit { tenantName } SSO einloggen"
      },
      settings: {
        title: "Einstellungen"
      },
      documentSpaces: {
        title: "Shared Spaces"
      },
      analytics: {
        title: "Analytics"
      },
      documentAnalytics: {
        title: "Document Analytics"
      },
      spaceAnalytics: {
        title: "Shared Space Analytics"
      },
      usageAnalytics: {
        title: "Usage Analytics"
      },
      createWorkspace: "Workspace erstellen",
      createSharedSpace: "Shared Space erstellen",
      customFlows: {
        title: "Custom Flows"
      },
      crmContacts: {
        title: "CRM Integration"
      }
    },
    workspace: {
      notFound: "Der Workspace konnte nicht gefunden werden",
      subfolder: {
        label: "Ordner",
        numItems: "Leer | 1 Element | {count} Elemente"
      }
    },
    pvdocuments: {
      empty: "Der Workspace enthält keine Dokumente"
    },
    workspacesOverview: {
      title: "Deine Workspaces",
      createNew: "Workspace erstellen",
      emptyState: {
        title: "Du hast noch keine Workspaces erstellt",
        info: "Erstelle einen Workspace um loszulegen"
      },
      columns: {
        name: "Name"
      }
    },
    workspaceDetail: {
      title: "Detail",
      teamSubscriptions: {
        title: "Teams",
        empty: "Es sind noch keine Teams diesem Workspace zugeordnet.",
        modalHeader: "Teams Verwalten",
        info: "Du kannst hier nur Teams sehen deren Mitglied du bist",
        modalInfo: "Du kannst nur Teams auswählen deren Admin du bist",
        noAdminTeams: "Du bist noch kein Admin in einem Team"
      },
      rename: {
        buttonText: "Umbenennen",
        modalHeader: "Umbenennen"
      },
      delete: {
        buttonText: "Löschen",
        subfolderModalHeader: "Subfolder löschen",
        modalHeader: "Workspace löschen",
        subfolderConfirmation: "Willst du den Subfolder { subfolderName } wirklich löschen?",
        confirmation: "Willst du den Workspace { workspaceName } wirklich löschen?",
        confirmationButtonText: "Ja, löschen",
        notAllowed: "Workspaces mit Mitgliedern, Dokumenten oder Unterordnern können nicht gelöscht werden."
      },
      move: {
        buttonText: "Verschieben",
        success: "Erfolgreich verschoben",
        goToWorkspace: "Zum Workspace gehen"
      },
      manageTeams: {
        buttonText: "Verwalten"
      },
      actionMessage: {
        success: "Änderungen erfolgreich gespeichert"
      },
      createSubfolder: {
        buttonText: "Unterordner erstellen",
        modalHeader: "Unterordner erstellen"
      }
    },
    pvCardDoc: {
      badge: {
        references: "Referenzen",
        draft: "Entwurf"
      },
      info: {
        pages: "Seiten",
        duration: "Länge",
        tags: "Tags"
      },
      published: "Veröffentlicht",
      ignoredPages: "Ignorierte Seiten",
      updateDocument: "Dokument austauschen",
      tooltip: {
        share: "Teilen",
        copy: "Kopieren",
        move: "Verschieben",
        edit: "Bearbeiten"
      },
      expirationDate: "Ablaufdatum"
    },
    pvDocEditor: {
      header: "Dokument bearbeiten",
      fileInfo: {
        pages: "Seiten"
      },
      topTitle: {
        label: "Mini-Titel / Kategorie"
      },
      displayTitle: {
        label: "Titel"
      },
      teaser: {
        label: "Beschreibung",
        placeholder: "Füge hier eine Kurzbeschreibung (oder ähnliches) hinzu"
      },
      tags: {
        label: "Tags"
      },
      deleteDoc: {
        header: "Dokument löschen?",
        confirmationQuestion: "Bist Du sicher, dass Du das Dokument löschen möchten?",
        confirm: "Ja, löschen",
        cancel: "Abbrechen",
        isUsed: {
          header: "Du bist dabei, ein Dokument zu löschen, das derzeit in Verwendung ist. Das Löschen dieses Dokuments wird mehrere Konsequenzen nach sich ziehen:",
          presentationUsage: "Nichts | Eine mit diesem Dokument verknüpfte Präsentation wird beschädigt und wird nicht mehr richtig funktionieren. | Insgesamt {count} Präsentationen, die mit diesem Dokument verknüpft sind, werden beschädigt und werden nicht mehr richtig funktionieren.",
          spaceUsage: "Nichts | Dieses Dokument wird aus einem Shared Space entfernt, und Nutzer werden keinen Zugriff mehr darauf haben. | Dieses Dokument wird aus {count} Shared Spaces entfernt, und Nutzer werden keinen Zugriff mehr darauf haben.",
          flowUsage: "Nichts | Seiten aus diesem Dokument werden aus einem Custom Flow entfernt, was Probleme mit Workflows verursachen kann, die auf diesen Seiten basieren. | Seiten aus diesem Dokument werden aus {count} Custom Flows entfernt, was Probleme mit Workflows verursachen kann, die auf diesen Seiten basieren.",
          suggestion: "Wenn Du das Dokument austauschen möchtest, empfehlen wir, das Dokument nicht zu löschen. Stattdessen kannst Du ganz einfach eine neue Version hochladen, indem Du im Editor auf das Upload-Symbol klickst.",
          contact: "Wenn Du Fragen hast oder Unterstützung benötigst, kontaktiere uns gerne:"
        },
      },
      externalId: {
        label: "PromoMats ID",
        info: "Vault PromoMats ist eine eingetragene Marke von Veeva Systems."
      },
      cancel: "Abbrechen",
      submit: "Speichern",
      delete: "Löschen",
      back: "Zurück",
      move: "Verschieben",
      copy: "Kopieren",
      download: "Herunterladen",
      preview: "Dokument ansehen",
      updateDoc: {
        success: "Dokument erfolgreich ausgetauscht"
      },
      processing: "Ihr Dokument wurde erfolgreich hochgeladen. Wir verarbeiten die Datei jetzt. Bitte haben Sie einen Moment Geduld, dies kann einige Augenblicke dauern.",
      info: {
        pages: {
          label: "Anzahl Seiten"
        },
        duration: {
          label: "Länge"
        },
        createdAt: {
          label: "Erstellt"
        },
        updatedAt: {
          label: "Zuletzt geändert"
        },
        type: {
          label: "Typ"
        },
        size: {
          label: "Größe"
        },
        shareable: {
          label: "Teilen in Shared Spaces erlauben"
        },
        isDownloadable: {
          label: "Herunterladen im Shared Space erlaubt"
        },
        supportsFlows: {
          label: "Teilen in Custom Flows erlaubt"
        },
        displayTeaserTextInSpace: {
          label: "Beschreibung im Shared Space anzeigen"
        },
        linkings: {
          label: "Verlinkt in",
          linkingPage: "Seite",
          withNoAccess: "Ein weiteres Dokument auf das du keinen Zugriff hast | { count } weitere Dokumente auf die du keinen Zugriff hast"
        }
      },
      fileUpdate: {
        tooltip: "Neues Dokument hochladen"
      },
      flashMessage: {
        success: "Erfolgreich gespeichert",
        error: "Ein Fehler ist aufgetreten und die Änderungen konnten nicht gespeichert werden"
      }
    },
    magicPageStudio: {
      title: "Magic Page Studio",
      configurator: "Konfigurator",
      page: "Seite",
      type: "Typ",
      magicPageTypes: {
        NORMAL: "Normale Seite",
        POPOVER: "Popover",
        MAGIC_LINK: "Magic Link",
      },
      editor: {
        previousPage: "Vorherige Seite",
        nextPage: "Nächste Seite",
        linkDocument: {
          info: "Bitte wählen sie ein Dokument aus.",
          button: "Dokument verlinken",
          label: "Verlinktes Dokument",
          change: "Um ein anderes Dokument zu verlinken, wähle ein Dokument aus der Liste aus"
        },
      },
      wizard: {
        header: {
          firstStep: "Konfiguration für Seite {pageNumber}: Wähle einen Typ",
          secondStep: "Konfiguration für Seite {pageNumber}: Wähle das verlinkte Dokument",
          firstPage: "Seite 1"
        },
        nextPage: "Nächste Seite",
        previousPage: "Vorherige Seite",
        lastPage: "Letzte Seite",
        firstPage: "Erste Seite",
        normalPage: {
          title: "Normale Seite",
          info: "Die Seite wird ganz normal im PDF angezeigt."
        },
        popover: {
          title: "Popover",
          info: "Die Seite muss dafür im PDF von einer anderen Seite aus verlinkt sein (normaler interner PDF-Link). Die Seite wird dann als Popover geöffnet, wenn man auf einen Link im PDF zur Seite klickt."
        },
        magicLink: {
          title: "Magic Link",
          info: "Die Seite muss über einen internen PDF-Link mit einer anderen Seite verknüpft sein. Beim Klicken wird statt der Seite das verlinkte Dokument geöffnet.",
          linkedDocument: {
            label: "Verlinkt mit:"
          }
        },
        advanced: {
          label: "Erweitert",
          info: "Wenn du möchtest, kannst du Dokumente aus anderen Workspaces verlinken. Sei jedoch vorsichtig! Durch das Verlinken erhalten Benutzer Zugriff auf diese Dokumente, auch wenn sie keine Mitglieder des ursprünglichen Workspaces sind.",
          warning: "Durch das Verknüpfen von Dokumenten erhalten Nutzer Zugriff, auch wenn sie keine Mitglieder des ursprünglichen Workspaces sind. Möchtest du fortfahren?",
          resetButton: "Nein, normaler Modus",
          acceptButton: "Ja, erweiterter Modus"
        },
        nextButton: {
          label: "Weiter"
        }
      },
      cancelEdit: "Schließen",
      saveButton: {
        label: "Speichern",
        disabled: "Bitte wähle ein Dokument aus"
      },
      editButton: {
        label: "Bearbeiten",
        disabled: "Die erste Seite eines Dokuments kann nicht konfiguriert werden"
      },
      saveInfoText: "Bitte beachte, die Änderungen werden erst gespeichert wenn du auf den Speichern Button klicken.",
      convertPrevious: {
        warning: "Bitte beachte, alle vorangehenden Seiten werden zu normalen Seiten konvertiert!"
      },
      convertSubsequent: {
        warning: "Bitte beachte, alle nachfolgenden normalen Seiten werden zu Popover Seiten konvertiert!"
      },
      flashMessage: {
        success: "Erfolgreich gespeichert",
        error: "Ein Fehler ist aufgetreten und die Änderungen konnten nicht gespeichert werden"
      },
      description: `Hier kannst Du auf andere Dokumente oder Videos verlinken oder eine Seite als Popover konfigurieren.
      Die Seite muss dafür im PDF von einer anderen Seite aus verlinkt sein (normaler interner PDF-Link).
      Wenn Du die Seite als Magic Link konfigurierst, dann wird stattdessen das verlinkte
      Dokument oder das verlinkte Video geöffnet. Wenn Du die Seite als Popover konfigurierst, wird sie als Popover geöffnet, wenn man auf einen Link im PDF zur Seite klickt`
    },
    magicPagesSetup: {
      enable: {
        info: "Magic Pages bieten die Möglichkeit Seiten und Dokumente innnerhalb einer Präsentation zu verlinken. Klicke auf den Aktivieren Button um es selbst auszuprobieren.",
        button: "Magic Pages Aktivieren"
      },
      loading: {
        info: "Die Magic Pages werden aktiviert, dies kann einen Moment dauern."
      }
    },
    pvDocTags: {
      docTags: {
        label: "Tags"
      },
      workspaceTags: {
        label: "Weitere Im Workspace verwendete Tags"
      },
      addTag: {
        placeholder: "Tag hinzufügen"
      },
      createTag: {
        label: "Tag Anlegen"
      },
      tagAlreadySelected: "Tag bereits ausgewählt"
    },
    folderPicker: {
      header: {
        COPY: "Kopiere {name} in einen anderen Workspace",
        MOVE: "{name} verschieben"
      },
      currentLocation: "Aktueller Workspace",
      cancel: "Abbrechen",
      submit: {
        COPY: "Kopieren",
        MOVE: "Verschieben"
      },
      noWorkspaces: "Keine Ordner gefunden",
    },
    docCopyDialog: {
      actionComplete: {
        goToWorkspace: "Zum Workspace gehen",
        error: {
          COPY: "Ein Fehler ist aufgetreten und das Dokument wurde nicht kopiert",
          MOVE: "Ein Fehler ist aufgetreten und das Dokument wurde nicht verschoben"
        }, success: {
          COPY: "Das Dokument wurde erfolgreich kopiert.",
          MOVE: "Das Dokument wurde erflogreich verschoben"
        }
      }
    },
    pvDocReferences: {
      link: {
        display: "Referenzen anzeigen"
      }
    },
    emailVerification: {
      emailSentHeader: "Eine E-Mail ist unterwegs...",
      checkInbox: "Bitte überprüfe Dein Postfach",
      resendHeader: "Keine E-Mail erhalten?",
      resendButton: "Nochmals senden",
      emailSent: {
        success: "Eine E-Mail wurde gesendet",
        error:
          "Ein Fehler ist aufgetreten und die E-Mail konnte nicht gesendet werden"
      },
      cancel: "Abbrechen"
    },
    projectorLauncher: {
      header: {
        projectorActive: "Projektor läuft",
        projectorInactive: "Projektor starten"
      },
      shareLink: "Teile den Link",
      toggleLabel: "Projektor Ein/Aus",
      shareCode: "Nutze den Code"
    },
    joinableTeams: {
      join: {
        buttonText: "Beitreten"
      },
      leave: {
        buttonText: "Verlassen"
      },
      header: "Team Onboarding",
      memberCount: "Noch keine Mitglieder | 1 Mitglied | {count} Mitglieder",
      membershipState: {
        ACTIVE: "Mitglied",
        INVITED: "Eingeladen",
        WAITS_FOR_ACCESS: "Angefragt",
        active: {
          true: "Admin",
          false: "Mitglied"
        }
      },
      closedTeam: "Privat"
    },
    teamDetail: {
      addUser: {
        buttonText: "Nutzer hinzufügen"
      },
      manageWorkspaces: {
        buttonText: "Verwalten",
        selectWorkspaces: "Workspaces auswählen",
        flashMessage: {
          success: "Alle Änderungen wurden erfolgreich gespeichert",
          error: "Nicht alle Änderungen konnten gespeichert werden"
        }
      },
      leaveTeam: {
        buttonText: "Team verlassen",
        modalHeader: "Team verlassen",
        confirmationText: "Willst Du sicher das Team verlassen?",
        submit: {
          buttonText: "Ja"
        },
        cancel: {
          buttonText: "Abbrechen"
        }
      },
      invitedUsers: {
        title: "Offene Einladungen"
      },
      requests: {
        title: "Offene Anfragen"
      },
      members: {
        title: "Mitglieder",
        removeFromTeam: {
          title: "Nutzer entfernen"
        },
        changeRole: {
          title: "Rolle ändern",
          header: "Rolle von {name} ändern?",
          label: "Wähle eine neue Rolle aus",
          admin: {
            label: "Admin",
            info: "Hat administrativen Zugriff auf den gesamten Workspace."
          },
          member: {
            label: "Mitglied",
            info: "Kann Team-Mitglieder, Workspaces und Dokumente sehen."
          },
          submit: "Rolle ändern",
          error: "Ein Fehler ist aufgetreten und die Änderungen konnten nicht gespeichert werden",
          success: "Änderungen erfolgreich gespeichert"
        }
      }
    },
    teamList: {
      onboarding: {
        buttonText: "Weiteren Teams beitreten"
      },
      noMemberships: {
        info: "Du bist noch nicht Mitglied in einem Team"
      },
      invitations: {
        title: "Meine Einladungen"
      },
      pendingTeams: {
        title: "Meine Anfragen"
      },
      activeTeams: {
        title: "Meine Teams"
      }
    },
    tenantUserSearch: {
      emptyResult: {
        header: "Keine Nutzer gefunden",
        info: "Probiere es stattdessen mit einer E-Mail-Adresse"
      },
      userAlreadyMember: {
        info: "Bereits Mitglied"
      },
      userAlreadySelected: {
        info: "Nutzer bereits ausgewählt"
      },
      invalidEmail: {
        info: "Gib eine vollständige E-Mail-Adresse ein"
      },
      header: "Nutzer zum Team hinzufügen",
      nextStep: "Weiter",
      previousStep: "Zurück zur Suche",
      search: {
        placeholder: "Nutzer suchen"
      },
      submit: "Einladen",
      notify: {
        label: "Nutzer per E-Mail benachrichtigen"
      },
      customText: {
        placeholder: "Zusätzliche Nachricht (optional)"
      },
      invitationInfo: "Eingeladene Nutzer werden nach der Registrierung dem Team automatisch hinzugefügt",
      userList: {
        header: "Ausgewählte Nutzer"
      },
      emptyList: {
        info: "Noch keine Nutzer ausgewählt"
      }
    },
    documentSearch: {
      header: "Dokument hinzufügen",
      search: {
        placeholder: "Dokument suchen"
      },
      submit: "Kein Dokument ausgewählt | 1 Dokument hinzufügen | {count} Dokumente hinzufügen",
      emptyResult: "Keine Dokumente gefunden",
      notShareable: "Teilen im Shared Space deaktiviert",
      disabledForFlows: "Dokument gesperrt für Custom Flows",
      alreadySelected: "Bereits ausgewählt",
      document: {
        numPages: "- | 1 Seite | { count } Seiten"
      }
    },
    externalGroupImport: {
      import: {
        buttonText: "Anmelden",
        infoText: "Bitte melde Dich mit { tenantName } SSO an, damit wir Dich Deinen Teams zuordnen können.",
        skip: {
          buttonText: "Überspringen"
        }
      },
      description:
        "Du kannst Gruppen importieren und so ggf. weiteren Teams hinzugefügt werden",
      memberships: {
        info:
          "Diese Mitgliedschaften wurden durch den Gruppenimport hinzugefügt:"
      },
      noMemberships: {
        info:
          "Es gibt noch keine Mitgliedschaften, die durch den Gruppenimport hinzugefügt wurden"
      },
      success: "Der Import war erfolgreich"
    },
    unsupportedBrowser: {
      header: "Ihr Browser wird nicht unterstüzt",
      info: "Ihr Browser wird nicht unterstützt. Bitte nutzen Sie einen unterstüzen Browser."
    },
    documentSpaces: {
      title: "Shared Spaces",
      columns: {
        name: "Name",
        lastModified: "Bearbeitet",
        content: "Inhalt"
      },
      delete: "Löschen",
      copy: "Kopieren",
      copyName: "Kopie von {name}",
      numDocuments: "Keine Dokumente | 1 Dokument | {count} Dokumente",
      sharedDoc: "Geteilt",
      createNew: "Space Erstellen",
      errorCreateNew: "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut",
      successCreateNew: "Shared Space erfolgreich erstellt",
      deleteConfirmation: {
        title: "Shared Space löschen",
        message: "Bist du sicher, dass du den Shared Space '{name}' löschen möchten?",
        submit: "Ja, löschen",
        success: "Shared Space erfolgreich gelöscht"
      },
      emptyState: {
        title: "Noch keine Shared Spaces angelegt",
        info: "Klicke auf den Button um Deinen ersten Shared Space zu erstellen"
      },
      search: {
        totalCount: "Keine Treffer | {count} Treffer",
        placeholder: "Nach Shared Spaces suchen",
        controls: {
          sortBy: {
            label: "Sortieren nach",
            mostRelevant: {
              label: "Am relevantesten"
            },
            modified: {
              label: "Zuletzt geändert"
            }
          }
        }
      }
    },
    documentSpace: {
      share: {
        buttonText: "Teilen"
      },
      rename: {
        buttonText: "Umbenennen"
      },
      preview: {
        buttonText: "Vorschau",
        buttonTextBusinessCard: "Vorschau mit Visitenkarte"
      },
      copyLink: {
        buttonText: "Link Kopieren",
        buttonTextBusinessCard: "Link mit Visitenkarte kopieren"
      },
      copyCampaignLink: {
        buttonText: "Kampagnen-Link Kopieren"
      },
      sendMail: {
        buttonText: "Mail schicken"
      },
      delete: {
        buttonText: "Löschen"
      },
      personalMessage: {
        title: "Persönliche Nachricht",
        save: "Speichern",
        placeholder: "Füge hier ein persönliche Nachricht ein",
        unsavedChanges: "Du hast Änderungen in deiner persönlichen Nachricht gemacht, die noch nicht gespeichert wurden. Möchtest du sie speichern?",
        discard: "Verwerfen"
      },
      documents: {
        title: "Dokumente"
      },
      contactDetails: {
        title: "Ihre Kontaktdaten",
        displaySetting: "Meine Kontaktdaten anzeigen",
        editLink: "Kontaktdaten bearbeiten",
        moreUsers: "'' | 1 weiterer Nutzer sichtbar | {count} weitere Nutzer sichtbar"
      },
      addDocument: {
        buttonText: "Dokument hinzufügen"
      },
      links: {
        title: "Eigene Links"
      },
      addLink: {
        buttonText: "Link hinzufügen"
      },
      emptyDocumentList: "Klicke auf 'Dokument hinzufügen', um Inhalte zum Shared Space hinzuzufügen.",
      emptyLinkList: "Klicke auf 'Link hinzufügen', um Deine eigenen Links zum Shared Space hinzuzufügen.",
      crmMapping: {
        title: "CRM Kontakt",
        button: {
          text: "Zuweisen",
          info: "Der Space kann geteilt werden nachdem ein Kontakt zugewiesen wurde"
        },
        tooltipLabel: {
          nameWithTerritory: "Verbunden mit {firstName} {lastName} ({territory})",
          name: "Verbunden mit {firstName} {lastName}"
        }
      },
      configLinks: {
        title: "Template Links",
        mandatoryLink: {
          info: "Dieser Link ist obligatorisch und kann nicht entfernt werden."
        }
      },
      disableTracking: {
        buttonText: "Tracking deaktivieren",
        title: "Tracking deaktivieren",
        warning: "Wenn du das Tracking deaktivierst, werden dir keine Analytics für diesen Space mehr angezeigt. Das Tracking kann danach nicht wieder eingeschaltet werden.",
        question: "Bist du sicher, dass du das Tracking für diesen Shared Space deaktivieren möchtest?",
        submit: "Ja, Tracking deaktivieren",
        textDeactivated: "Tracking deaktiviert"
      },
      chooseTemplate: {
        buttonText: "Ein Template auswählen"
      },
      doesNotExist: "Dieser Shared Space existiert nicht.",
      mail: {
        recipient: {
          label: "Empfänger Email",
          error: "Ungültige Email"
        },
        subject: {
          default: "Ihre Dokumente",
          label: "Betreff",
          error: "Betreff darf nicht leer sein"
        },
        body: {
          default:"Guten Tag, \n mit unterstehendem Link gelangen Sie zu den Dokumenten über die wir gesprochen haben",
          label: "Text",
          hint: "Bitte beachte, <link> wird durch den Link zum Shared Space ersetzt.",
          error: "<link> darf nicht entfernt werden"
        },
        error: "Ein Fehler ist aufgetreten und die Mail konnte nicht versendet werden. Bitte probiere es erneut oder kontaktiere den Support, falls das Problem erneut auftritt.",
      },
      missingConsent: {
        title: "Kein Opt-in",
        message: "Bitte beachte, dass dieser Kontakt noch keine Zustimmung für E-Mail-Kommunikation gegeben hat. Eine Einwillung kann über die iOS-App eingeholt werden."
      },
      access: {
        title: "Nutzer mit Zugriff",
        userSearch: {
          placeholder: "Nutzer hinzufügen",
          empty: "Keine Nutzer gefunden"
        },
        invite: {
          title: "Einladen",
          info: "Klicke auf den 'Einladen' Button um den Nutzer hinzuzufügen",
          buttonText: "Einladen"
        },
        displayDetails: {
          title: "Sichtbar",
          info: "Kontaktdetails werden im Shared Space angezeigt",
          disabled: {
            info: "Nicht mehr als drei Benutzer können sichtbar sein"
          }
        },
        hideDetails: {
          title: "Verborgen",
          info: "Kontaktdetails werden nicht im Shared Space angezeigt"
        },
        removeAccess: {
          title: "Zugriff entfernen"
        },
        owner: {
          title: "Eigentümer"
        },
        transferOwnership: {
          title: "Eigentümerschaft übertragen",
          info: "Bitte beachte, dass du nicht mehr Eigentümer des Spaces sein wirst.",
          submit: "Ja, Eigentümerschaft übertragen"
        },
        isCurrentUser: "du"
      },
      copy: {
        buttonText: "Space kopieren",
      },
      copyName: "Kopie von {name}",
      demo: {
        banner: {
          title: "Demo Shared Space",
          text: "Dieser Shared Space ist nur für Demonstrationszwecke gedacht."
        }
      },
      sections: {
        add: "Neuen Abschnitt hinzufügen",
        createNew: {
          title: "Neuen Abschnitt hinzufügen",
          placeholderName: "Dokumente"
        },
        delete: "Löschen",
        edit: "Umbenennen",
        moveUp: "Nach oben verschieben",
        moveDown: "Nach unten verschieben",
        addBelowThis: "Neuen Abschnitt unter diesem hinzufügen",
        deleteWithDocuments: {
          title: "Wie soll mit den Dokumenten des Abschnitss verfahren werden?",
          deleteAll: "Aus Space entfernen",
          moveToOtherSection: "In anderen Abschnitt verschieben",
          moveTo: "In diesen Abschnitt verschieben"
        }
      }
    },
    copySpaceModal: {
      header: "Shared Space Kopieren",
      skip: "Überspringen",
      submit: "Kopieren",
      name: "Bitte trage einen Namen ein",
      previousStep: "Zurück"
    },
    documentSpacePicker: {
      header: "Zu Shared Spaces hinzufügen",
      search: {
        placeholder: "Shared Space suchen"
      },
      submitLabel: "'' | Teile mit 1 Space | Teile mit {count} Spaces",
      createNew: {
        buttonText: "Neuer Space",
        label: "Shared Space Name",
        submit: "Speichern"
      },
      back: "Zurück",
      undoBar: {
        resultLabel: {
          single: "Dokument zum Shared Space hinzugefügt",
          multiple: "Dokument zu {count} Shared Spaces hinzugefügt"
        },
        goToSpace: "Shared Space öffnen"
      },
      emptyList: "Du hast noch keine Shared Spaces erstellt",
      alreadySelected: "Dokument bereits im Shared Space"
    },
    documentSpaceLinkEditor: {
      header: {
        edit: "Link bearbeiten",
        new: "Neuen Link erstellen"
      },
      submit: {
        new: "Hinzufügen",
        edit: "Speichern"
      },
      form: {
        sectionTitle: {
          label: "Titel",
          error: {
            missing: "Titel ist erforderlich"
          }
        },
        title: "Link Text",
        url: {
          label: "Link URL",
          error: {
            invalid: "Bitte gebe eine valide URL ein",
            missing: "URL ist erforderlich"
          }
        }
      }
    },
    profile: {
      title: "Mein Profil",
      photo: "Foto",
      email: "Email",
      name: "Vor- und Nachname",
      jobTitle: "Job Titel",
      phone: "Telefon",
      stateMessage: {
        success: "Änderungen wurden erfolgreich gespeichert",
        error: "Ein Fehler ist aufgetreten und die Änderungen konnten nicht gespeichert werden"
      },
      imageCrop: {
        title: "Schneide dein neues Profilbild zu"
      },
      fileSelect: {
        title: "Lade ein Bild hoch",
        error: {
          tooLarge: "Bitte wähle eine Datei, die kleiner als 1 MB ist.",
          wrongType: "Wir unterstützen nur Bilder im PNG- oder JPG-Format.",
          wrongNumber: "Bitte wähle eine Datei aus."
        }
      },
      submit: "Speichern"
    },
    dashboard: {
      title: "Letzte Besuche",
      displayPreview: "Preview-Besuche anzeigen",
      labelSpacesNotTracked: "'' | Bei einem Space ist das Tracking deaktiviert | Bei { count } Spaces ist das Tracking deaktiviert",
      columns: {
        name: "Name",
        numVisits: "Besuche",
        latestVisit: "Letzter",
        interactions: "Interaktionen"
      },
      interactions: {
        documentHit: "Dokument angesehen",
        videoHit: "Video angesehen",
        documentDownload: "Dokument heruntergeladen",
        linkClick: "Link geklickt"
      },
      displayMore: "Mehr Besuche anzeigen",
      noData: {
        title: "Keine Besuche",
        text: "Es wurden noch keine Besuche getrackt.",
        textNoPreview: "Es wurden noch keine Besuche getrackt. Preview-Besuche werden hierfür nicht miteinbezogen."
      }
    },
    visitDetail: {
      header: "Besuche",
      totalVisits: "Besuche",
      avgDuration: "Durchschnittliche Dauer"
    },
    documentSpaceAnalytics: {
      title: "Shared Space Analytics",
      infoNoPreview: "Preview-Besuche werden nicht miteinbezogen",
      noData: "Für diesen Shared Space liegen noch keine Daten vor. Preview Besuche werden hierfür nicht miteinbezogen",
      totalVisits: {
        title: "Space Besuche"
      },
      downloads: {
        title: "Downloads"
      },
      linkClicks: {
        title: "Link Clicks"
      },
      avgDuration: {
        title: "Durchschnittliche Dauer"
      },
      topDocuments: {
        title: "Top Dokumente",
        noData: "Es wurden bisher keine Dokumente geöffnet",
        metrics: {
          visits: "Anzahl an Ansichten",
          downloads: "Downloads",
          duration: "Dauer",
          maxDuration: "Max. Dauer",
          topPage: "Top Seite",
          numVisits: "Anzahl an Besuchen",
          shares: "Anzahl an Shares"
        }
      },
      topVideos: {
        title: "Top Videos",
        noData: "Es wurden bisher keine Videos geöffnet",
        metrics: {
          visits: "Anzahl an Ansichten",
          downloads: "Downloads",
          numVisits: "Anzahl an Besuchen",
          maxWatchRate: "Angesehen"
        }
      },
      topPages: {
        title: "Top Seite",
        metrics: {
          pageNumber: "Seitenzahl",
          visits: "Besuche",
          averageDuration: "Dauer",
          maxDuration: "Max. Dauer",
          numVisits: "Anzahl an Besuchen"
        }
      },
      topLinks: {
        title: "Top Links",
        noData: "Es wurden bisher keine Links geklickt",
        metrics: {
          link: "Link",
          numberClicks: "Anzahl an Clicks"
        }
      },
      visits: {
        title: "Besuche",
        metrics: {
          date: "Datum",
          duration: "Dauer",
          interaction: "Interaktion",
          openDocument: "Dokument geöffnet",
          clickLink: "Link geklickt",
          downloadDocument: "Dokument heruntergeladen"
        }
      }
    },
    documentAnalytics: {
      title: "Dokumenten Analytics",
      orderBy: {
        label: "Sortieren nach",
        numVisits: "Ansichten",
        numShares: "Shares"
      },
      displayMore: "Mehr anzeigen",
      search: "Dokumente Suchen",
      emptySearch: {
        title: "Wir konnten nicht finden wonach du suchst",
        info: "Bitte versuche es mit einer anderen Suche oder kontakiere unseren Support."
      },
      empty: {
        title: "Wir konnten keine Dokumenten-Analytics finden",
        info: "Es kann sein, dass du hierfür noch keine Rechte hast. Bitte kontaktiere unseren Support."
      },
      noShares: "Dieses Dokument wurde noch nicht geteilt",
    },
    analyticsOverview: {
      title: "Analytics Übersicht",
      info: "Hier findest du Analytics zu deinen eigenen Shared Spaces sowie zu den Dokumenten deiner Teams",
      spaceAnalytics: {
        info: "The Shared Space Analytics geben dir einen Einblick in die Performance deiner Shared Spaces und der Dokumente darin",
        linkText: "Shared Space Analytics"
      },
      documentAnalytics: {
        info: "Die Dokumenten Analytics geben dir einen Einblick in die Performance der Dokumente aus deinen Teams",
        linkText: "Dokumenten Analytics"
      },
      usageAnalytics: {
        info: "Die Nutzungsanalyse bietet dir einen Überblick darüber, wie die verschiedenen Funktionen verwendet werden.",
        linkText: "Nutzungsanalyse"
      }
    },
    usageAnalytics: {
      title: "Usage Analytics",
      byYear: {
        title: "Nutzung nach Jahr"
      },
      users: {
        title: "Nutzer",
        total: "Gesamt",
        registered: "Registriert",
        info: "Gesamtanzahl beinhaltet eingeladene Nutzer, die noch nicht registriert sind"
      },
      presentations: {
        title: "Remote Präsentationen"
      },
      documents: {
        title: "Dokumente"
      },
      app: {
        title: "App"
      },
      documentSpaces: {
        title: "Shared Spaces"
      },
      nUsersThatOpenedTheAppAtLeastOnce: {
        title: "Nutzer, die die App mind. 1x öffneten",
        info: "Wieviele Nutzer haben die App mind. 1x geöffnet"
      },
      nNewUsersInLastSixMonths: {
        title: "Anzahl neuer Nutzer im letzten Halbjahr"
      },
      nPresentations: {
        title: "Präsentationen",
        info: "Wieviele Projector-Sessions (Remote-Präsentationen) wurden durchgeführt. (Die Nutzung ist nach dem Lockdown aufgrund von Präsenzterminen zurückgegangen"
      },
      nNewUsersInLastYear: {
        title: "Anzahl neuer Nutzer im letzten Jahr"
      },
      nTimesOpenedADocument: {
        title: "Dokumenten-Öffnungen",
        info: "Wie oft wurden Dokumente (z.B. zur Präsentation, Selbststudium etc.) geöffnet"
      },
      nTimesOpenedTheApp: {
        title: "App-Öffnungen",
        info: "Wie oft wurde die App geöffnet"
      },
      nUploads: {
        title: "Uploads"
      },
      nUsersThatPresentedAtLeastOnce: {
        title: "Users that presented at least once"
      },
      nUsersTotal: {
        title: "Gesamtanzahl Nutzer",
        info: "Alle Nutzer, die jemals im Team waren"
      },
      nSpaces: {
        title: "Erstellte Shared Spaces",
        info: "Wieviele Shared Spaces wurden neu erstellt"
      },
      nSpaceVisits: {
        title: "Space-Besuche",
        info: "Wie oft wurden Shared Spaces, die von Mitgliedern des Team erstellt wurden, besucht"
      },
      nDocumentVisits: {
        title: "Dokumenten-Ansichten",
        info: "Wie oft wurden Dokumente, die in Spaces liegen, die von Mitglieder des Teams erstellt wurden, angesehen."
      },
      nDownloads: {
        title: "Dokumenten-Downloads",
        info: "Wie oft wurden Dokumente, die in Spaces liegen, die von Mitglieder des Teams erstellt wurden, heruntergeladen."
      },
      nLinkClicks: {
        title: "Link Clicks",
        info: "Wie oft wurden (template oder vom Nutzer hinzugefügte) Links, die in Spaces liegen, die von Mitglieder des Teams erstellt wurden, angesehen."
      },
      notFound: "Keine Analytics gefunden. Bitte wähle ein anderes Team-Alias aus.",
      noData: {
        title: "Keine Daten in diesem Zeitraum",
        text: "Es liegen keine Daten für den ausgewählten Zeitraum vor. Bitte wähle einen anderen Zeitraum aus."
      }
    },
    createWorkspaceModal: {
      header: "Workspace erstellen",
      submit: "Speichern",
      inputLabel: "Bitte gib einen Namen an",
      created: {
        success: "Workspace erfolgreich erstellt",
        error: "Ein Fehler ist aufgetreten"
      }
    },
    crmContactSearch: {
      search: {
        placeholder: "CRM-Kontakt suchen"
      },
      emptyResult: "Es konnte kein passender Kontakt gefunden werden",
      updateName: {
        label: "Name des Shared Spaces anpassen",
        name: "{lastName}, {firstName}",
        nameWithTerritory: "{lastName}, {firstName} ({territory})"
      }
    },
    crmContacts: {
      title: "CRM Kontakte",
      pagination: {
        previous: "Vorherige Seite",
        next: "Nächste Seite"
      },
      warning: {
        spaces: "Du hast bereits einen Shared Space für diesen Kontakt"
      },
      info: {
        spaces: {
          totalCount: "0 | 1 Shared Space | {count} Shared Spaces"
        }
      },
      openSpace: "Space öffnen"
    },
    documentPresenter: {
      documentDeleted: "Das verlinkte Dokumente wurde gelöscht",
      controls: {
        closeFullscreen: "Vollbild schließen",
        openFullscreen: "Vollbild öffnen",
        closeGallery: "Galerie schließen",
        openGallery: "Galerie öffnen"
      },
      documentNotFound: "Dokument wird geladen",
      processing: "Das Dokument wird gerade verarbeitet und ist noch nicht vollständig verfügbar"
    },
    customFlows: {
      title: "Custom Flows",
      createNew: "Flow Erstellen",
      delete: "Flow löschen",
      emptyState: {
        title: "Du hast noch keine Custom Flows angelegt",
        info: "Erstelle einen neuen Flow um loszulegen"
      },
      columns: {
        title: "Name"
      },
      deleteConfirmation: {
        title: "Flow löschen",
        message: "Bist du sicher, dass du diesen Flow löschen möchtest?",
        submit: "Ja, löschen",
        success: "Flow erfolgreich gelöscht",
      },
      newFlowCreated: {
        success: "Flow erfolgreich erstellt",
        error: "Ein Fehler ist aufgetreten",
        header: "Flow erstellen",
        inputLabel: "Bitte gib einen Flow-Titel an",
        submit: "Flow erstellen"
      },
      detail: {
        doesNotExist: "Dieser Flow existiert nicht",
        addPagesLabel: "Seiten hinzufügen",
        present: "Präsentieren",
        addPages: "Seiten hinzufügen",
        rename: "Umbenennen",
        delete: "Löschen",
        share: "Teilen",
        dropdown: {
          delete: "Seite löschen",
          present: "Ab dieser Seite präsentieren",
        },
        legalAppendix: {
          info: "Diese Folie enthält Pflichtangaben. Sie wurde aufgrund der verwendeten zugrundeliegenden Dokumente / Präsentationen automatisch an Deinen Custom Flow angehängt und kann weder in der Reihenfolge verschoben noch entfernt werden. Damit werden Compliance-Anforderungen erfüllt."
        }
      },
      flashMessages: {
        addingPages: {
          success: "Ausgewählte Seiten wurden hinzugefügt",
          error: "Fehler beim Hinzufügen der Seiten, bitte versuchen Sie es erneut",
        },
        deletingPages: {
          success: "Seite erfolgreich gelöscht",
          error: "Fehler beim Löschen der Seite, bitte versuchen Sie es erneut",
        },
        rename: {
          success: "Flow erfolgreich umbenannt",
          error: "Fehler beim Umbenennen des Flows, bitte versuche es erneut oder kontaktiere unseren Support",
        },
        deleteFlow: {
          success: "Flow erfolgreich gelöscht",
          error: "Fehler beim Löschen des Flows, bitte versuche es erneut oder kontaktiere unseren Support",
        },
        share: {
          success: "Flow erfolgreich geteilt",
          error: "Fehler beim Teilen des Flows, bitte versuche es erneut",
        },
      },
      renameModal: {
        title: "Flow umbenennen",
        message: "Bitte gib einen neuen Titel für den Flow ein",
        submit: "Umbenennen",
        placeholder: "Titel eingeben"
      },
      removePageModal: {
        title: "Seite löschen",
        message: "Bist du sicher, dass du diese Seite löschen möchtest?",
        submit: "Ja, löschen"
      },
      shareModal: {
        addedToSpace: {
          success: "Dokumente wurden erfolgreich hinzugefügt.",
          error: "Ein Fehler ist aufgetreten und die Dokumente konnten nicht hinzugefügt werden.",
          goToSpace: "Zum Shared Space",
        },
        submit: "Dokumente hinzufügen",
        title: {
          selectSpace: "Shared Space auswählen",
          selectDocuments: "Dokumente auswählen"
        },
        notShareable: "Teilen deaktiviert",
        alreadyShared: "Bereits geteilt",
        noDocuments: "Der Flow enthält keine Dokumente zum teilen"
      }
    },
    flowPresenter: {
      controls: {
        openFullscreen: "Vollbild öffnen",
        closeFullscreen: "Vollbild schließen",
        openGallery: "Galerie öffnen",
        closeGallery: "Galerie schließen",
      }
    },
    documentPagePicker: {
      header: {
        document: "Dokument auswählen",
        pages: "Seiten auswählen"
      },
      footer: {
        selectDocument: "Dokument auswählen",
        close: "Schließen",
        selectPages: "Ausgewählte Seiten hinzufügen",
        goBack: "Zurück"
      },
      newDocumentRevision: {
        info: "Das ausgewählte Dokument wurde aktualisiert. Bitte wähle erneut Seiten aus."
      }
    },
    createDocumentSpaceModal: {
      header: {
        selectContact: "Shared Space erstellen: CRM Kontakt auswählen",
        insertName: "Shared Space erstellen: Namen eingeben"
      },
      name: "Name",
      assignedContact: "Zugewiesener Kontakt",
      limitExceeded: {
        title: "Du hast das Limit an kostenlosen Shared Spaces erreicht",
        info: "Werde ein Pro-Nutzer und profitiere von unlimitiert vielen Shared Spaces und vielen anderen Features",
        buttonText: "Kontaktiere uns"
      },
      skip: "Überspringen",
      continueWithSelection: "Weiter mit Auswahl",
      previousStep: "Vorheriger Schritt",
      submit: "Shared Space erstellen"
    },
    shareDocumentsModal: {
      header: "Dokumente teilen",
      footer:{
        back: "Zurück",
      },
      addedToSpace: {
        success: "Dokument wurde erfolgreich hinzugefügt.",
        alreadyExists: "Das Dokument existiert bereits im Space.",
        error: "Ein Fehler ist aufgetreten und das Dokument konnte nicht hinzugefügt werden.",
        goToSpace: "Shared Space ansehen"
      }
    },
    appPromotionOverlay: {
      header: "Nutze unsere iOS App!",
      openApp: {
        buttonText: "App öffnen"
      },
      stay: {
        buttonText: "Fortfahren"
      }
    },
    templateEditor: {
      personalMessage: {
        placeholderText: "Hier wird die persönliche Nachricht angezeigt"
      },
      languages: {
        de: {
          label: "Deutsch",
          defaultLabel: "Deutsch (Standard)"
        },
        en: {
          label: "Englisch",
          defaultLabel: "Englisch (Standard)"
        },
        fr: {
          label: "Französisch",
          defaultLabel: "Französisch (Standard)"
        },
        it: {
          label: "Italienisch",
          defaultLabel: "Italienisch (Standard)"
        },
        nl: {
          label: "Niederländisch",
          defaultLabel: "Niederländisch (Standard)"
        }
      },
      contactDetails: {
        placeholderText: "Hier werden die Kontaktdetails angezeigt"
      },
      toolbar: {
        banner: {
          title: "Banner",
          image: {
            label: "Bild",
            replace: "Neues Bild hochladen",
            crop: {
              title: "Möchtest du dein Bild zuschneiden, damit es besser passt?",
              info: "Das Seitenverhältnis deines ausgewählten Bildes entspricht nicht dem idealen Format. Einige Teile des Bildes könnten nicht richtig angezeigt werden. Du kannst es zuschneiden, um eine bessere Passform zu gewährleisten.",
              cancel: "Nein, Original verwenden",
              submit: "Ja, Bild zuschneiden"
            }
          },
          url: {
            label: "URL",
            info: "Optional: Gib eine URL ein, zu der die Nutzer weitergeleitet werden, wenn sie auf das Banner klicken."
          },
        },
        link: {
          title: "Link",
          sectionTitle: {
            label: "Section Titel"
          },
          linkTitle: {
            label: "Titel"
          },
          url: {
            label: "URL"
          },
          isMandatory: {
            label: "Obligatorisch"
          },
          delete: {
            title: "Link löschen",
            message: "Bist du sicher, dass du den Link löschen möchtest?"
          }
        },
        footer: {
          title: "Fußnote"
        },
        footerLink: {
          title: "Fußzeilenlink",
          delete: {
            title: "Fußzeilenlink löschen",
            message: "Bist du sicher, dass du den Fußzeilenlink löschen möchtest?"
          }
        },
        logo: {
          title: "Logo",
          image: {
            label: "Bild",
            replace: "Ersetzen"
          },
        },
        legalNote: {
          title: "Pflichttext"
        },
        settings: {
          title: "Settings"
        },
        fileSelect: {
          error: {
            tooLarge: "Bitte wähle eine Datei, die kleiner als 1 MB ist.",
            wrongType: "Wir unterstützen nur Bilder im PNG-, SVG- oder JPG-Format.",
            wrongTypeImageGif: "Wir unterstützen nur Bilder im PNG-, GIF- oder JPG-Format.",
            wrongNumber: "Bitte wähle eine Datei aus."
          }
        },
        copy: "Kopieren",
        save: "Änderungen übernehmen",
        delete: "Element löschen",
      },
      addContent: {
        buttonText: "Inhalt hinzufügen",
        disabledText: "Links können nur in der default Sprache hinzugefügt werden."
      },
      notEditable: "Dieses Element kann nur in der Standard-Sprache bearbeitet werden",
      copy: {
        buttonText: "Kopieren"
      },
      copyModal: {
        title: "Template kopieren",
        copyName: "Kopie von {name}",
        teamSelection: {
          label: "Wähle ein Team aus"
        },
        submit: "Kopie erstellen"
      }
    },
    templates: {
      title: "Shared Space Templates",
      emptyState: {
        title: "No Shared Space Templates",
        info: "We could not find any Shared Space Template you can edit"
      },
      columns: {
        name: "Name",
        team: "Team",
        lastModified: "Zuletzt geändert"
      }
    },
    deleteConfirmation: {
      submit: "Löschen"
    },
    discardChanges: {
      title: "Änderungen verwerfen?",
      message: "Du hast Änderungen vorgenommen, die noch nicht gespeichert wurden. Bist Du sicher, dass Du die Änderungen verwerfen möchtest?",
      submit: "Ja"
    },
    documentHistory: {
      title: "Dokumenten Historie",
      empty: "Du hast noch keine Dokumenten-Ansichten",
      label: {
        presentation: {
          title: "Präsentation",
        },
        "self-study": {
          title: "Selbststudium"
        },
        default: {
          title: "Präsentation",
          select: "Bitte auswählen"
        }
      },
      multiselect: {
        label: "'' | Ein ausgewähltes Dokument ändern zu | {count} ausgewählte Dokumente ändern zu"
      },
      todo: {
        tooltip: "Du hast Dokumenten Views ohne Bezeichnung"
      }
    },
    settingsOverview: {
      title: "Einstellungen",
      documentHistory: {
        buttonText: "Dokumentenverlauf anzeigen",
        info: "Behalten Sie Ihre Dokumentenaktivitäten mit unserer Dokumentenverlauf-Funktion im Blick. Sehen Sie alle Dokumente an, die Sie gesperrt oder präsentiert haben."
      },
      profile: {
        buttonText: "Profil bearbeiten",
        info: "Verwalten Sie hier Ihren angezeigten Namen und Ihre Kontaktdaten. Aktualisieren Sie Ihre Informationen, damit sie in den geteilten Bereichen korrekt angezeigt werden."
      }
    },
    promomatsIntegration: {
      title: "Veeva PromoMats-Integration",
      isInWorkspace: "Bereits im Arbeitsbereich",
      isInOtherWorkspace: "Bereits in einem anderen Arbeitsbereich",
      submit: "{numDocs} Dokumente importieren",
      next: "Weiter",
      sort: {
        nameAsc: "Name ↓",
        nameDesc: "Name ↑",
        versionCreationAsc: "Version erstellt ↓",
        versionCreationDesc: "Version erstellt ↑",
        versionModificationAsc: "Version geändert ↓",
        versionModificationDesc: "Version geändert ↑"
      }
    }
  },
};
