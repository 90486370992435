import { useEventBus } from '@vueuse/core';
import { onMounted, ref } from 'vue';

type DeleteConfirmationPayload = {
  translationKey: string;
  resolve: (value: boolean) => void;
};

export function useDeleteConfirmation() {
  const deleteConfirmationBus = useEventBus<DeleteConfirmationPayload>('deleteConfirmation');
  const deleteConfirmationRef = ref<{
    show: (translationKey: string) => Promise<boolean>;
  } | null>(null);

  onMounted(() => {
    deleteConfirmationBus.on(async ({ translationKey, resolve }) => {
      if (deleteConfirmationRef.value) {
        const confirmed = await deleteConfirmationRef.value.show(translationKey);
        resolve(confirmed);
      }
    });
  });

  function displayDeleteConfirmation(translationKey: string) {
    return new Promise<boolean>((resolve) => {
      deleteConfirmationBus.emit({ translationKey, resolve });
    });
  }

  return { displayDeleteConfirmation, deleteConfirmationRef };
}
