<template>
  <PromomatsDocumentSelector
    v-show="state === 'SELECT'"
    @change="setSelectedDocuments"
    @next="state = 'IMPORT'"
    @close="emit('close')"
  />
  <PromomatsImport
    v-if="state === 'IMPORT'"
    :selectedDocuments="selectedDocuments"
    @close="emit('close')"
    @goBack="handleGoBack"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { PMDoc } from "./PromomatsDocument";
import PromomatsDocumentSelector from "./PromomatsDocumentSelector.vue";
import PromomatsImport from "./PromomatsImport.vue";

const emit = defineEmits(["close"]);

const state = ref<"SELECT" | "IMPORT">("SELECT");

const selectedDocuments = ref<PMDoc[]>([]);
const setSelectedDocuments = (docs: PMDoc[]) => {
  selectedDocuments.value = docs;
};

const handleGoBack = (docs: PMDoc[]) => {
  console.log(docs)
  selectedDocuments.value = docs;
  state.value = "SELECT";
}
</script>
