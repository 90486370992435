<script lang="ts" setup>
import { Modal, DocumentSearch } from "@/components";
import { ref, PropType } from "vue";

const props = defineProps({
  workspace: {
    type: String,
    required: false,
  },
  hasWorkspaceFilter: {
    type: Boolean,
    required: false,
    default: true,
  },
  hasTypeFilter: {
    type: Boolean,
    required: false,
    default: true,
  },
  onlyShareable: {
    type: Boolean,
    required: false,
    default: false,
  },
  singleSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  excluded: {
    type: Array as PropType<Array<string>>,
    required: false,
    default: [],
  },
});

const selection = ref<Map<string, string>>(new Map());
const setSelection = (values: Map<string, string>) => {
  selection.value = values;
};

const emit = defineEmits(["close", "submit"]);
const close = () => {
  emit("close");
};

const submit = () => {
  const docIds = Array.from(selection.value.keys());
  const details = docIds.map((id) => ({
    id: id,
    displayTitle: selection.value.get(id),
  }));
  emit("submit", docIds, details);
};
</script>

<template>
  <Modal :active="true" :scrolling="true" @close="close" class="huge">
    <template v-slot:header>
      {{ $t("documentSearch.header") }}
    </template>
    <template v-slot:body>
      <DocumentSearch
        :workspace="workspace"
        :hasWorkspaceFilter="hasWorkspaceFilter"
        :onlyShareable="onlyShareable"
        :singleSelect="singleSelect"
        :excluded="excluded"
        @change="setSelection"
      />
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button
          :class="{
            button: true,
            primary: true,
            disabled: selection.size === 0,
          }"
          @click="submit"
        >
            <span class="material-symbols-outlined">save</span>
            {{ $t("documentSearch.submit", { count: selection.size }) }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.button {
  min-width: 250px;
}
</style>