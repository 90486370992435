<template>
  <Modal :active="active" :scrolling="true" class="medium_large" @close="emits('close')">
    <template #header>
      {{
        flowState === FlowState.SELECT_SPACE
        ? $t("customFlows.shareModal.title.selectSpace")
        : $t("customFlows.shareModal.title.selectDocuments")
      }}
    </template>

    <template #body>
      <DocumentSpacePicker
        v-if="flowState === FlowState.SELECT_SPACE"
        @submit="selectSpaces"
        @close="emitsClose"
      />
      <ShareFlowDocuments
        v-else-if="flowState === FlowState.SHARE_DOCUMENTS"
        :flowId="flowId"
        @change="updateDocumentIds"
      />
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button" @click="emitsClose">
          {{ $t("cancel") }}
        </button>

        <button class="button" @click="goBack">
          {{ $t("back") }}
        </button>

        <button
          v-if="selectedSpaceIds.length > 0"
          class="button primary"
          :disabled="selectedDocumentIds.length === 0"
          @click="shareFlowDocuments"
        >
          {{ $t("customFlows.shareModal.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { DocumentSpacePicker, Modal } from "@/components";
import ShareFlowDocuments from "@/views/custom_flows/share_flow_modal/ShareFlowDocuments.vue";
import { ref } from "vue";
import { useCreateSpaceItemsMutation } from "@/graphql/operations";

const emits = defineEmits(["close", "success"]);
const emitsClose = () => emits("close");

const resultState = ref<string>("");

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  flowId: {
    type: String,
    required: true
  },
});

enum FlowState {
  SELECT_SPACE,
  SHARE_DOCUMENTS
}

const flowState = ref<FlowState>(FlowState.SELECT_SPACE);

const selectedSpaceIds = ref<Array<string>>([]);
const selectSpaces = (spaceIds: Array<string>) => {
  selectedSpaceIds.value = spaceIds;
  flowState.value = FlowState.SHARE_DOCUMENTS;
};

const goBack = () => {
  selectedSpaceIds.value = [];
  flowState.value = FlowState.SELECT_SPACE;
};

const selectedDocumentIds = ref<string[]>([]);
const updateDocumentIds = (docIds: string[]) => {
  selectedDocumentIds.value = docIds;
};

const { executeMutation: createDocumentSpaceItem } =
  useCreateSpaceItemsMutation();

const shareFlowDocuments = async () => {
  let success = true;
  try {
    // for now execute mutations sequentially instead of parallel
    const targets = selectedSpaceIds.value.map((id: string) => ({ spaceId: id }));
    for (const docId of selectedDocumentIds.value) {
      const res = await createDocumentSpaceItem({
        targets: targets,
        documentId: docId,
      });
      console.log(res)
    }
  } catch {
    success = false;
  }

  resultState.value = success ? "success" : "error";
  emits("success", resultState.value, selectedSpaceIds.value);
};
</script>

<style scoped>
</style>
