<script setup lang="ts">
import {
  useDocumentHistoryQuery,
  type DocumentHistoryEntry,
} from "@/graphql/operations";
import { computed, ref, watch } from "vue";
import { getStorageProxyUrl, Formatter } from "@/util";
import { LoadingSpinner, BottomActionBar } from "@/components";
import { useFlashMessage } from "@/hooks";
import DocumentViewLabel from "./Label.vue";

const { displayFlashMessage } = useFlashMessage();

const { data, fetching, executeQuery } = useDocumentHistoryQuery({
  requestPolicy: "cache-and-network",
});

interface GroupedByDay {
  day: Date;
  items: DocumentHistoryEntry[];
}

function groupByDay(
  entries: (DocumentHistoryEntry | null | undefined)[]
): GroupedByDay[] {
  const groupedByDay = entries
    .filter(e => e && e.document != null)
    .reduce<Record<string, DocumentHistoryEntry[]>>(
      (acc, item) => {
        if (!item) return acc;

        const day = item.date.split("T")[0];

        if (!acc[day]) {
          acc[day] = [];
        }

        acc[day].push(item);
        return acc;
      },
      {}
    );

  return Object.entries(groupedByDay).map(([day, items]) => ({
    day: new Date(day),
    items: items,
  }));
}

const entries = computed(() => {
  const d = data.value?.documentHistoryEntries?.nodes;
  if (!d) {
    return;
  }

  const result = groupByDay(d as (DocumentHistoryEntry | null | undefined)[]);
  return result;
});

const selectedViews = ref<Array<string>>([]);
const displayBottomActionBar = computed(() => selectedViews.value.length > 0);

const toggleViewSelection = (id: string) => {
  if (selectedViews.value.includes(id)) {
    selectedViews.value.splice(selectedViews.value.indexOf(id), 1);
  } else {
    selectedViews.value.push(id);
  }

  checkGroupCheckboxStates();
};

const toggleGroupSelection = (items: DocumentHistoryEntry[]) => {
  const itemIds = items.map((i) => i.id);
  if (groupIsSelected(items)) {
    selectedViews.value = selectedViews.value.filter(
      (id) => !itemIds.includes(id)
    );
  } else {
    selectedViews.value = [...selectedViews.value, ...itemIds];
  }

  checkGroupCheckboxStates();
};

const groupIsSelected = (items: DocumentHistoryEntry[]) => {
  return items.every((i) => selectedViews.value.includes(i.id));
};

const resetSelectedViews = () => {
  selectedViews.value = [];
  checkGroupCheckboxStates();
}

const checkboxRefs = ref();
const checkGroupCheckboxStates = () => {
  if (!entries.value || !checkboxRefs.value) {
    return;
  }

  for (let i = 0; i < entries.value.length; i++) {
    if (groupIsIndeterminate(entries.value[i].items)) {
      checkboxRefs.value[i].indeterminate = true;
    } else {
      checkboxRefs.value[i].indeterminate = false;
    }
  }
}

const groupIsIndeterminate = (items: DocumentHistoryEntry[]) => {
  const selectedInGroup = items.filter((i) =>
    selectedViews.value.includes(i.id)
  );
  return selectedInGroup.length > 0 && selectedInGroup.length < items.length;
};

const handleSuccess = () => {
  selectedViews.value = [];
  executeQuery();
  displayFlashMessage("Success", "success");
};

const handleError = () => {
  selectedViews.value = [];
  displayFlashMessage("Error", "error");
};
</script>

<template>
  <div class="pane page_content">
    <div class="title_container">
      <h3>{{ $t("documentHistory.title") }}</h3>
    </div>

    <div v-if="!entries && fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-else-if="!entries || entries.length === 0">
      <div>{{ $t("documentHistory.empty") }}</div> 
    </div>

    <div v-else>
      <div v-for="(group, idx) in entries" :key="idx" class="section">
        <div class="section_title_container">
          <div class="section_title">
            {{ Formatter.translateDate(group.day.toISOString()) }}
          </div>
          <input
            type="checkbox"
            class="right"
            @change="toggleGroupSelection(group.items)"
            :checked="groupIsSelected(group.items)"
            ref="checkboxRefs"
          />
        </div>

        <div v-for="(entry, i) in group.items" :key="i" class="row">
          <template v-if="entry!.document">
            <div>
              <div class="container">
                <div class="thumbnail_container">
                  <img
                    class="thumbnail_image"
                    :src="getStorageProxyUrl(entry!.document.thumbnailDownloadUrl)"
                  />
                </div>
                <div>
                  <div class="doc_title">
                    {{ entry!.document.displayTitle }}
                  </div>
                  <div class="additional_info">
                    {{ Formatter.formatAndTranslateDate(entry!.startsAt) }}
                    &mdash;
                    {{
                      Formatter.formatDuration({
                        hours: entry!.activeDuration?.hours || 0,
                        minutes: entry!.activeDuration?.minutes || 0,
                        seconds: entry!.activeDuration?.seconds || 0,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <DocumentViewLabel
                :viewIds="[entry.id]"
                :label="entry.label"
                @success="handleSuccess"
                @error="handleError"
              />
              <input
                type="checkbox"
                :checked="selectedViews.includes(entry.id)"
                @change="toggleViewSelection(entry.id)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <BottomActionBar v-if="displayBottomActionBar" @close="resetSelectedViews">
      <template #action>
        <span class="label">
          {{
            $t("documentHistory.multiselect.label", {
              count: selectedViews.length,
            })
          }}
        </span>
        <DocumentViewLabel
          :viewIds="selectedViews"
          :singleButton="true"
          :type="'presentation'"
          @success="handleSuccess"
          @error="handleError"
        />
        <DocumentViewLabel
          :viewIds="selectedViews"
          :singleButton="true"
          :type="'self-study'"
          @success="handleSuccess"
          @error="handleError"
        />
      </template>
    </BottomActionBar>
  </div>
</template>

<style lang="scss" scoped>
.pane {
  font-size: 14px;
}

.section {
  margin-bottom: 1em;
}

.section_title_container {
  padding: 1em 1em 1em 0;
  display: flex;
  align-items: center;
}

.section_title {
  color: #737373;
  font-weight: 500;
}

.section_title_container .right {
  margin-left: auto;
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
  padding: 0.5em;
}

.row {
  border-top: 1px solid #ececec;
  padding: 1em 1em 1em 0;
  display: flex;
}

.container {
  display: flex;
  align-items: center;
}

.thumbnail_container {
  height: 60px;
  width: 80px;
  margin-right: 1em;
}

.thumbnail_image {
  height: 60px;
  width: 80px;
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
}

.additional_info {
  color: #737373;
  font-size: 14px;
  line-height: 1.5;
}

.doc_title {
  color: #000000;
  font-weight: 500;
  line-height: 1.5;
}

.row .right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.document_view_label {
  margin-right: 1em;
}

span.label {
  margin-right: 1em;
}
</style>
