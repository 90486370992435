<script lang="ts" setup>
import { ref, PropType, computed, watch, toRefs } from "vue";
import {
  type DocumentSpaceLink,
  useDeleteDocumentSpaceConfigLinkMutation,
} from "@/graphql/operations";
import { useFlashMessage, useDeleteConfirmation } from "@/hooks";
import { useForm, useField } from "vee-validate";
import { string } from "yup";
import { type Config } from "./Config";

const props = defineProps({
  link: {
    type: Object as PropType<DocumentSpaceLink>,
    default: () => ({
      url: null,
      title: null,
      sectionTitle: null,
      isMandatory: true,
    }),
  },
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
  currentLanguage: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close", "reloadData", "newItem", "changeState"]);
const close = () => {
  emits("close");
};

const { validate, errors } = useForm();

const translation = computed(() =>
  props.link.documentSpaceLinkTranslationsByLinkId?.nodes?.find(
    (t) => t!.languageCode === props.currentLanguage
  )
);

const linkData = computed(() => ({
  linkId: props.link.id,
  translationId: translation.value?.id,
  url: translation.value ? translation.value.url : props.link.url,
  sectionTitle: translation.value
    ? translation.value.sectionTitle
    : props.link.sectionTitle,
  title: translation.value ? translation.value.title : props.link.title,
}));

const { value: url } = useField<string>("url", string().url().required(), {
  initialValue: linkData.value.url,
});

const { value: title } = useField<string>("title", string().required(), {
  initialValue: linkData.value.title,
});

const sectionTitle = ref<string | undefined | null>(
  linkData.value.sectionTitle
);
const isMandatory = ref<boolean>(props.link?.isMandatory ?? true);

watch(() => props.link, (newLink, oldLink) => {
  if (oldLink?.id !== newLink?.id && newLink) {
    sectionTitle.value = linkData.value.sectionTitle;
    title.value = linkData.value.title;
    url.value = linkData.value.url;
    isMandatory.value = newLink.isMandatory;
  }
});

watch(() => props.currentLanguage, (newVal, oldVal) => {
  url.value = linkData.value.url;
  sectionTitle.value = linkData.value.sectionTitle;
  title.value = linkData.value.title;
});

const { displayFlashMessage } = useFlashMessage();
const { displayDeleteConfirmation } = useDeleteConfirmation();

const hasChanges = computed(
  () => 
    sectionTitle.value !== linkData.value?.sectionTitle ||
    title.value !== linkData.value?.title ||
    url.value !== linkData.value?.url ||
    isMandatory.value !== props.link?.isMandatory
);

watch(hasChanges, () => { emits("changeState", hasChanges.value) })

const submit = async () => {
  const validationResult = await validate();
  if (!validationResult.valid) {
    console.log("invalid data");
    return;
  }

  try {
    const linkId = await props.config.createOrUpdateLink(
      linkData.value.linkId,
      linkData.value.translationId,
      sectionTitle.value,
      title.value,
      url.value,
      isMandatory.value
    );

    displayFlashMessage("Success", "success");
    if (!linkData.value.linkId) {
      emits("newItem", linkId)
    } else {
      emits("reloadData");
    }

  } catch (err) {
    displayFlashMessage(err as string, "error");
  }
};

const { executeMutation: deleteLink } =
  useDeleteDocumentSpaceConfigLinkMutation();
const displayConfirmation = async () => {
  if (!props.link?.id) {
    return;
  }

  const confirmed = await displayDeleteConfirmation(
    "templateEditor.toolbar.link.delete"
  );

  if (confirmed) {
    const res = await deleteLink({ linkId: props.link.id });
    if (res.error) {
      displayFlashMessage(res.error.message, "error");
    } else {
      displayFlashMessage("Success", "success");
    }
  }

  emits("reloadData");
  emits("close");
};
</script>

<template>
  <div class="toolbar_body">
    <div class="row">
      <div class="label_container">
        <label>{{
          $t("templateEditor.toolbar.link.sectionTitle.label")
        }}</label>
      </div>
      <div>
        <input type="text" class="input" v-model="sectionTitle" />
      </div>
    </div>
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.link.linkTitle.label") }}</label>
      </div>
      <div>
        <input
          type="text"
          class="input"
          :class="{ invalid: errors.title !== undefined }"
          v-model="title"
        />
        <p v-if="errors.title !== undefined" class="help">
          {{ errors.title }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.link.url.label") }}</label>
      </div>
      <div>
        <input
          type="text"
          class="input"
          v-model="url"
          :class="{ invalid: errors.url !== undefined }"
          placeholder="https://www.pitchview.io"
        />
        <p v-if="errors.url !== undefined" class="help">
          {{ errors.url }}
        </p>
      </div>
    </div>
    <div class="row" v-if="currentLanguage === 'default'">
      <div class="label_container">
        <input type="checkbox" v-model="isMandatory" />
        <label>{{ $t("templateEditor.toolbar.link.isMandatory.label") }}</label>
      </div>
    </div>
    <div class="row bottom" v-if="link.id && currentLanguage === 'default'">
      <button class="button" @click="displayConfirmation">
        <span class="material-symbols-outlined">delete</span>
        {{ $t("templateEditor.toolbar.delete") }}
      </button>
    </div>
  </div>
  <div class="toolbar_footer" @click="submit">
    <div class="button primary" :class="{ disabled: !hasChanges }">
      {{ $t("templateEditor.toolbar.save") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar_body {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  height: calc(100vh - 60px - 60px - 82px);
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.toolbar_footer {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}

.label_container {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}

.label_container label {
  flex: 1;
  font-weight: 500;
}

.label_container .link {
  font-weight: normal;
}

.row {
  margin-bottom: 1.5em;
}

.row.bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.image {
  background: #e0e0e0;
  height: 70px;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

input[type="checkbox"] {
  margin-right: 0.5em;
}

p.help {
  font-size: 0.8em;
  color: #f14668;
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  height: 0.8em;
}
</style>
