<script lang="ts" setup>
import { computed } from "vue";
import { useDocumentSpacesForContactQuery } from "@/graphql/operations";

const props = defineProps({
  contactId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close"]);

const { data, fetching } = useDocumentSpacesForContactQuery({
  variables: {
    contactId: props.contactId,
  },
  requestPolicy: "cache-and-network"
});
const spaces = computed(() => data.value?.documentSpaceCrmMappings?.nodes);
</script>

<template>
  <div class="expanded_row">
    <div v-for="space in spaces" :key="space!.documentSpace!.id">
      <router-link
        @click="emits('close')"
        class="link"
        :to="{
          name: 'DocumentSpace',
          params: {
            id: space!.documentSpace!.id,
          },
        }"
      >
        <span class="material-symbols-outlined icon"> attach_file </span>
        {{ space!.documentSpace!.name }} ({{
          space!.documentSpace!.config?.name
        }})
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.expanded_row {
  padding: 0.2em 1em 0.8em 37px;
}

.expanded_row .link {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 2.2;
}

.icon {
  font-size: 1.4em;
}
</style>
