<script lang="ts" setup>
import { Modal } from "@/components";
import { useDeactivateSpaceTrackingMutation } from "@/graphql/operations";

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  documentSpaceId: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["close", "success", "error"]);
const emitClose = () => emit("close");

const { executeMutation } = useDeactivateSpaceTrackingMutation();
const submit = async () => {
  const result = await executeMutation({ id: props.documentSpaceId });
  if (result.error) {
    emit("error");
  } else {
    emit("success");
    emitClose();
  }
}
</script>

<template>
  <Modal :active="active" @close="emitClose">
    <template #header>
      {{ $t("documentSpace.disableTracking.title") }}
    </template>

    <template #body>
      <p class="info">{{ $t("documentSpace.disableTracking.warning") }}</p>
      <p class="info">{{ $t("documentSpace.disableTracking.question") }}</p>
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="submit">
          {{ $t("documentSpace.disableTracking.submit") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>

  </Modal>
</template>
