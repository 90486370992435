export class MimeType {

  static ALLOWED_TYPES = [
    ".pdf",
    ".mp4",
    ".docx",
    ".pptx",
    ".xlsx"
  ];

  static __data: Record<string, Record<string, string>> = {
    "application/mp4": {
      "title": "Video",
      "icon": "Audio-mp4.svg",
    },
    "video/mp4": {
      "title": "Video",
      "icon": "Audio-mp4.svg",
      "fileEnding": ".mp4"
    },
    "application/pdf": {
      "title": "PDF",
      "icon": "PDF_file_icon.svg",
      "fileEnding": ".pdf"
    },
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
      "title": "Microsoft Word",
      "icon": "docx_icon.svg",
      "fileEnding": ".docx"
    },
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
      "title": "Microsoft PowerPoint",
      "icon": "pptx_icon.svg",
      "fileEnding": ".pptx"
    },
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
      "title": "Microsoft Excel",
      "fileEnding": ".xlsx"
    },
    "image/png": {
      "title": "Image",
      "fileEnding": ".png"
    },
    "image/jpeg": {
      "title": "Image",
      "fileEnding": ".jpeg"
    }
  }

  static isVideo(mimeType: string) {
    return mimeType === "application/mp4" || mimeType === "video/mp4";
  }
  
  /* */
  static getMimeType(mimeType: string | undefined) {
    if (!mimeType) {
      return undefined;
    }

    if (mimeType === "application/mp4") {
      return "video/mp4";
    }

    return mimeType;
  }

  static getName(mimeType: string) {
    return MimeType.__data[mimeType]["title"];
  }

  static getIconPath(mimeType: string) {
    return `/src/assets/images/${MimeType.__data[mimeType]["icon"]}`;
  }

  static allowedReplacementTypes(mimeType: string) {
    if (mimeType === "application/mp4") {
      return [".mp4"];
    }

    return [".pdf", ".docx", ".pptx", ".xlsx", ".png", ".jpeg"];
  }

  static contains(allowedEndings: Array<string>, fileType: string) {
    const ending = MimeType.__data[fileType]?.fileEnding;
    return ending ? allowedEndings.indexOf(ending) !== -1 : false;
  }

}