<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useWorkspaceTreeQuery } from "@/graphql/operations";

const emits = defineEmits(["change"]);

const props = defineProps({
  parentId: {
    type: String,
    required: false,
    default: null,
  },
  selected: {
    type: String,
    required: false,
  },
  depth: {
    type: Number,
    required: false,
    default: 0,
  },
  singleWorkspace: {
    type: Boolean,
    required: false,
    default: false,
  },
  mustBeAdmin: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { data, fetching } = useWorkspaceTreeQuery({
  variables: {
    parentId: props.parentId,
  },
  requestPolicy: "network-only",
});

const allWorkspaces = computed(() => data.value?.workspaces?.nodes);
const workspaces = computed(() => {
  if (!allWorkspaces.value) {
    return;
  }

  let ws = allWorkspaces.value;
  if (props.mustBeAdmin && props.depth === 0) {
    ws = ws.filter((w) => w!.myPrivileges?.mayAdministrate);
  }

  if (props.singleWorkspace && props.selected && props.depth === 0) {
    const workspace = ws.find(
      (w) =>
        w!.id == props.selected ||
        w!.descendants?.nodes?.find((d) => d!.id == props.selected)
    );

    if (workspace && workspace.childWorkspaces?.totalCount > 0) {
      expand(workspace.id);
    }
    return workspace ? [workspace] : [];
  } else {
    return ws;
  }
});

const expandedWorkspaces = ref<Array<string>>([]);

const select = (childWorkspacesCount: number, id: string) => {
  if (childWorkspacesCount > 0) {
    expand(id);
  }

  emits("change", id);
};

const handleChange = (id: string) => {
  emits("change", id);
};

const expand = (id: string) => {
  if (expandedWorkspaces.value.includes(id)) {
    return;
  }

  expandedWorkspaces.value.push(id);
};

const unexpand = (id: string) => {
  expandedWorkspaces.value.splice(expandedWorkspaces.value.indexOf(id), 1);
};

watch(
  [fetching, workspaces],
  () => {
    if (fetching.value || !workspaces.value || workspaces.value.length === 0) {
      return;
    }

    if (props.selected) {
      const workspace = workspaces.value.find((w) => w!.id == props.selected);
      if (workspace && workspace.childWorkspaces.totalCount > 0) {
        expand(workspace!.id);
      }
    } else {
      const firstWorkspace = workspaces.value![0];
      select(firstWorkspace!.childWorkspaces.totalCount, firstWorkspace!.id);
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-for="workspace in workspaces" :key="workspace!.id">
    <div
      class="tree_item"
      :class="{ selected: selected == workspace!.id }"
      @click="select(workspace!.childWorkspaces?.totalCount, workspace!.id)"
      :style="`padding-left: ${depth * 20}px;`"
    >
      <template v-if="workspace!.childWorkspaces?.totalCount > 0">
        <span
          v-if="!expandedWorkspaces.includes(workspace!.id)"
          @click="expand(workspace!.id)"
          class="material-symbols-outlined"
        >
          chevron_right
        </span>
        <span
          v-else
          @click.stop="unexpand(workspace!.id)"
          class="material-symbols-outlined"
        >
          keyboard_arrow_down
        </span>
      </template>
      <span v-else class="spacer"></span>

      <span class="material-symbols-outlined filled folder">
        <template v-if="expandedWorkspaces.includes(workspace!.id)">
          folder_open
        </template>
        <template v-else>folder</template>
      </span>
      <span class="text">
        {{ workspace!.name }}
      </span>
    </div>
    <div v-if="expandedWorkspaces.includes(workspace!.id)">
      <workspace-tree
        :parentId="workspace!.id"
        @change="handleChange"
        :selected="selected"
        :depth="depth + 1"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$primary: #34b5ba;

.tree_item {
  display: flex;
  align-items: center;
  line-height: 2.1em;
  border-radius: 3px;
  border: 3px solid transparent;
}

.tree_item:hover {
  cursor: pointer;
  background-color: #d0d7de33;
}

.tree_item.selected {
  background-color: #d0d7de33;
  border-left: 3px solid $primary;
  border-radius: 0 3px 3px 0;
}

.material-symbols-outlined.folder {
  color: $folder-blue;
  font-size: 20px;
  margin-right: 0.3em;
}

.spacer {
  width: 24px;
  display: inline-block;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
