import * as Sentry from "@sentry/vue";
import { GetDocumentByFirestoreIdQuery } from "@/graphql/operations";
type Document = GetDocumentByFirestoreIdQuery["document"];

export interface InternalLink {
  source: number;
  target: number;
  style: string;
  linkType: string;
}

export class InternalLinksService {
  static parseInternalLinks(document: Document): InternalLink[] {
    const internalLinks: Array<InternalLink> = [];

    try {
      for (const link of JSON.parse(JSON.stringify(document?.internalLinks))) {
        if (link.linkType === "internal" && !isNaN(link.source) && !isNaN(link.target)) {
          internalLinks.push(link);
        }
      }
      return internalLinks;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }
}
