<template>
  <div class="container">
    <div class="table_container">
      <table class="data_table">
        <thead>
          <tr>
            <th>{{ $t("upload.selectedFile.name") }}</th>
            <th>{{ $t("upload.selectedFile.fileSize") }}</th>
            <th>{{ $t("upload.selectedFile.state") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, idx) in files" :key="idx">
            <td>
              {{ file.name }}
              <div v-if="fileStates[idx].uploadState === 'error'" class="error">
                {{ $t(`upload.error.title`) }}:
                {{ $t(`upload.error.${fileStates[idx].error}`) }}
              </div>
            </td>
            <td>{{ Formatter.formatSize(file.size) }}</td>
            <td>
              <span v-if="fileStates[idx].uploadState === 'running'">
                <progress-spinner />
                {{ fileStates[idx].progress }}%
              </span>
              <span v-else-if="fileStates[idx].uploadState === 'success'">
                <span class="material-symbols-outlined success"> done </span>
              </span>
              <span v-else-if="fileStates[idx].uploadState === 'error'">
                <span class="material-symbols-outlined error"> error </span>
              </span>
            </td>
            <td>
              <span
                v-if="!isRunning"
                class="material-symbols-outlined clickable"
                @click="remove(idx)"
              >
                close
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="info_box" v-if="files">
      <span class="text"
        >{{ $t("upload.totalFileSize") }}:
        {{ Formatter.formatSize(totalFileSize!) }}</span
      >

      <div class="checkbox_container" v-if="!isRunning">
        <input type="checkbox" v-model="sendNotification" /><label>{{
          $t("upload.sendNotificationLabel")
        }}</label>
      </div>

      <template v-if="files.length > 0 && !isRunning">
        <AddFileButton
          v-if="!singleFile"
          @submit="addFiles"
          :singleFile="singleFile"
          :mimeType="mimeType"
        />
        <button
          class="button primary"
          @click="startUpload"
          data-test="start-upload-ctrl"
        >
          Upload
        </button>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Formatter } from "@/util/format";
import { computed, PropType, ref } from "vue";
import { ProgressSpinner, Tooltip } from "@/components";
import { ErrorType, FileState } from "./state";
import AddFileButton from "./AddFileButton.vue";

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<File>>,
  },
  fileStates: {
    type: Array as PropType<Array<FileState>>,
    required: true,
  },
  mimeType: {
    type: String,
    required: false,
    default: undefined,
  },
  singleFile: {
    type: Boolean,
    required: true,
  },
  isRunning: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "removeFile",
  "addFiles",
  "startUpload",
]);

const files = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const totalFileSize = computed(() =>
  files.value?.reduce((acc, f) => acc + f.size, 0)
);

const sendNotification = ref(true);

const remove = (idx: number) => {
  emit("removeFile", idx);
};

const addFiles = (fileList: FileList) => {
  emit("addFiles", fileList);
};

const startUpload = () => {
  emit("startUpload", sendNotification.value);
};
</script>

<style scoped>
.container {
  height: 100%;
}

.table_container {
  height: calc(100% - 62px);
  overflow: scroll;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 1em;
}

.table_container table.data_table {
  box-shadow: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.material-symbols-outlined.clickable {
  opacity: 0.5;
  font-size: 1.2em;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.material-symbols-outlined.clickable:hover {
  cursor: pointer;
  opacity: 1;
}

.success {
  color: #48c78e;
}

.error {
  color: #9f3a38;
}

table {
  table-layout: fixed;
}

tr th:nth-child(1),
tr td:nth-child(1) {
  width: calc(100% - 70px - 70px - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr th:last-child,
tr td:last-child {
  text-align: center;
  width: 30px;
}

tr th:nth-child(2),
tr td:nth-child(2),
tr th:nth-child(3),
tr td:nth-child(3) {
  width: 70px;
  text-align: center;
}

.info_box {
  background: #fafafa;
  text-align: left;
  padding: 1em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
}

.info_box .text {
  flex: 1;
}

.info_box .button {
  margin: 0 0 0 5px;
}

.container :deep(.tooltip_container.multiline .tooltip) {
  width: 200px;
}

.checkbox_container {
  display: flex;
  margin-right: 1em;
  align-items: center;
}

.checkbox_container label {
  margin-left: 0.5em;
}

div.error {
  line-height: 1;
  margin-bottom: 1em;
}
</style>
