<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { FlowDocumentFragment, useCustomFlowQuery, useGetDocumentsOfSpaceQuery } from "@/graphql/operations";
import { getStorageProxyUrl } from "@/util";
import { Tooltip } from "@/components";

const props = defineProps({
  flowId: {
    type: String,
    required: true
  },
});

const emits = defineEmits(["change"])

const { data: flowData, fetching: fetchFlow } = useCustomFlowQuery({
  variables: { id: props.flowId },
  requestPolicy: "cache-and-network",
});

//const { data: spaceDocumentData,   fetching: spaceFetching } = useGetDocumentsOfSpaceQuery({
//  variables: { spaceId: props.spaceId },
//  requestPolicy: "cache-and-network",
//});

//const isDocInSpace = (doc: FlowDocumentFragment): boolean => {
//  return spaceDocumentData.value?.documentSpace?.documentSpaceItems.nodes.some(item => item?.documentId === doc?.id) ?? false;
//};

const uniqueDocuments = computed(() => {
  // returns a set of documents that are part of the flow
  return flowData.value?.flow?.distinctDocuments.nodes.map(doc => doc)!;
});

// select all shareable docs at start
const selectedDocuments = ref<Map<string, FlowDocumentFragment>>(
  uniqueDocuments.value
    ? new Map(uniqueDocuments.value.filter(doc => doc?.doesSupportSharedspace /*&& !isDocInSpace(doc!)*/).map(doc => [doc!.id, doc!]))
    : new Map()
);

const changeSelection = (doc: FlowDocumentFragment) => {
  if (/*isDocInSpace(doc) ||*/ !doc.doesSupportSharedspace) return;
  if (selectedDocuments.value.has(doc.id)) {
    selectedDocuments.value.delete(doc.id);
  } else {
    selectedDocuments.value.set(doc.id, doc);
  }
  emits("change", Array.from(selectedDocuments.value.keys()));
};

onMounted(() => {
  emits("change", Array.from(selectedDocuments.value.keys()));
})
</script>

<template>
  <div v-if="fetchFlow">
    Loading...
  </div>
  <div v-else-if="!!uniqueDocuments.length">
    <table class="clickable">
      <tbody>
        <tr
          v-for="doc in uniqueDocuments"
          :key="doc!.id"
          :class="{
            item: true, clickable_row: true, disabled: !doc!.doesSupportSharedspace /*|| isDocInSpace(doc!)*/
          }"
          @click="changeSelection(doc!)"
        >
          <td>
            <div class="checkbox_container">
              <input type="checkbox" :checked="selectedDocuments.has(doc!.id)" />
            </div>
          </td>

          <td class="thumbnail">
            <img
              v-if="doc!.thumbnailDownloadUrl"
              :src="getStorageProxyUrl(doc!.thumbnailDownloadUrl)"
              :alt="doc!.displayTitle"
              class="thumbnail_image"
            />

            <span v-else class="material-symbols-outlined">
              image
            </span>
          </td>

          <td class="main_column delayed">
            <Tooltip class="left">
              <template v-slot>
                <div class="tooltip_text">{{ doc!.displayTitle }}</div>
              </template>
              <template #tooltip>
                {{ doc!.displayTitle }}
              </template>
            </Tooltip>
          </td>

          <td class="doc_info">
            <span v-if="!doc!.doesSupportSharedspace">
              {{ $t("customFlows.shareModal.notShareable") }}
            </span>
            <!--
            <span v-else-if="isDocInSpace(doc!)">
              {{ $t("customFlows.shareModal.alreadyShared") }}
            </span>
            -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-else class="info">
    {{ $t("customFlows.shareModal.noDocuments") }}
  </div>
</template>

<style lang="scss" scoped>
$checkbox-width: 40px;
$color-secondary: #34b5ba;

.checkbox_container {
  vertical-align: middle;
  padding: .8em .8em .8em .5em;
  cursor: pointer;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

table tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom:  1px solid #f4f4f4;
  padding: .3em .3em 0 .3em;
  width: 45%;
}

table tr td:first-child {
  width: $checkbox-width;
}

table tr td:nth-child(4) {
  width: 80px;
  text-align: center;
  padding-right: .5em;
}

table tr td.thumbnail {
  width: 60px;
}

table tr td img.thumbnail_image {
  height: 45px;
  width: auto;
  max-width: 60px;
  border-radius: .25em;
}

table tr td.thumbnail_image .material-symbols-outlined {
  color: #555555;
}

input[type="checkbox"] {
  cursor: pointer;
  vertical-align: middle;
}

input[type="checkbox"].active {
  background-color: #34b5ba;
  outline: none;
  border: 1px solid #34b5ba;
  box-shadow: none;
}

input[type="checkbox"] + label {
  margin-left: 5px;
}

.item.disabled {
  opacity: .6;
}

.item.disabled input {
  visibility: hidden;
}

.item.disabled:active {
  pointer-events: none;
}

.item.disabled:hover {
  cursor: initial;
}

.doc_info {
  width: 150px;
  font-style: italic;
}

.clickable_row {
  cursor: pointer;
}

.tooltip_container {
  width: 100%;
}

.tooltip_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.info {
  padding: 3em 0;
  font-style: italic;
  text-align: center;
}
</style>
