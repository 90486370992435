<script lang="ts" setup>
import { Modal } from "@/components";
import { computed, ref, watch } from "vue";
import {
  useTeamCandidatesForWorkspaceQuery,
  useAddWorkspaceSubscriptionMutation,
  useDeleteWorkspaceSubscriptionMutation
} from "@/graphql/operations";

const emit = defineEmits(["close", "success"]);
const emitClose = () => {
  emit("close");
}

const emitSuccess = () => {
  emit("success");
}

const props = defineProps({
  active: {
    type: Boolean
  },
  id: {
    type: String,
    required: true
  }
});

const { data, fetching } = useTeamCandidatesForWorkspaceQuery({
  variables: {
    id: props.id
  },
  requestPolicy: "cache-and-network"
});

const teamCandidates = computed(() => data.value?.myMemberships?.nodes);
const selected = ref<Array<string>>([]);
const initialSelection = ref<Array<string>>([]);
watch(teamCandidates, () => {
  const selection = teamCandidates
    .value?.filter(c => c!.team!.workspaceSubscriptionsBySubteamId.nodes.length > 0)
    .map(c => c!.team?.id!);

  selected.value = selection || [];
  initialSelection.value = selection || [];
});

const submitDisabled = computed(() => initialSelection.value.length === selected.value.length &&
  selected.value.reduce((acc, curr) => acc && initialSelection.value!.includes(curr), true));

const { executeMutation: addWorkspaceSubscription } = useAddWorkspaceSubscriptionMutation();
const { executeMutation: deleteWorkspaceSubscription } = useDeleteWorkspaceSubscriptionMutation();
const actionState = ref<string>("");

const submit = async () => {
  actionState.value = "success"

  const newSubscriptions = selected.value.filter(s => !initialSelection.value.includes(s));
  for (let teamId of newSubscriptions) {
    const r = await addWorkspaceSubscription({
      teamId: teamId,
      workspaceId: props.id
    });

    if (r.error) {
      actionState.value = "error";
    }
  }

  const removedSubscriptions = initialSelection.value.filter(s => !selected.value.includes(s));
  for (let teamId of removedSubscriptions) {
    const subscriptionId = teamCandidates.value?.find(s => s!.team!.id === teamId)?.team?.workspaceSubscriptionsBySubteamId?.nodes[0]?.id;

    const r = await deleteWorkspaceSubscription({
      id: subscriptionId!
    });

    if (r.error) {
      actionState.value = "error";
    }
  }

  if (actionState.value === "success") {
    emitSuccess();
  }
  emitClose();
}
</script>

<template>
  <Modal @close="emitClose" :active="active">
    <template #header>
      {{ $t("workspaceDetail.teamSubscriptions.modalHeader")}}

    </template>
    <template #body>
      <template v-if="teamCandidates && teamCandidates.length > 0">
        <div class="info">
          {{ $t("workspaceDetail.teamSubscriptions.modalInfo") }}
        </div>
        <div class="items" v-if="!fetching">
          <div v-for="team in teamCandidates" :key="team!.team!.id" class="item">
            <div class="left">
              <span class="checkbox_container">
                <input
                  type="checkbox"
                  :id="team!.team!.id"
                  :value="team!.team!.id"
                  v-model="selected"
                />
              </span>
            </div>

            <div class="left">
              {{ team!.team!.name }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="info">
          {{ $t("workspaceDetail.teamSubscriptions.noAdminTeams") }}
        </div>
      </template>
    </template>
    <template #footer>
      <div class="buttons">
        <button :class="{button: true, primary: true, disabled: submitDisabled}" @click="submit">
          {{ $t("submit") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.left {
  float: left;
  height: 38px;
}

.item {
  padding: 5px 0;
  border-bottom: 1px solid #f4f4f4;
  height: 38px;
  line-height: 38px;
  overflow: auto;
}

.item .checkbox_container {
  display: inline-block;
  padding: .5em;
  width: 1.4em;
  margin-right: .5em;
}

input[type="checkbox"] {
  cursor: pointer;
}

.item.highlighted,
.item:hover {
  background-color: #fafafa;
}

.info {
  font-size: .9em;
  margin-bottom: .2em;
  font-style: italic;
}
</style>
