<script lang="ts" setup>
import {
  useDocumentSpaceDetailStatisticsQuery,
  Interval,
  MaDocumentSpaceDocumentStatistic,
  MaDocumentSpaceVideoStatistic,
} from "@/graphql/operations";
import { useRoute } from "vue-router";
import { Formatter } from "@/util";
import { Tooltip, Breadcrumbs, LoadingSpinner } from "@/components";
import { computed, onBeforeUnmount, onMounted } from "vue";
import DocStats from "./DocumentStats.vue";
import VideoStats from "./VideoStats.vue";

const route = useRoute();
const spaceId = route.params.id as string;

const previewFilter = [false];

const {
  data: detailStatsData,
  executeQuery: fetchStats,
  fetching,
} = useDocumentSpaceDetailStatisticsQuery({
  variables: {
    id: spaceId,
  },
  requestPolicy: "cache-and-network",
});

const formatDuration = (duration: Interval) => {
  return Formatter.formatDuration({
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
  });
};

const detailStats = computed(
  () => detailStatsData.value?.maDocumentSpaceStatistics?.nodes?.[0]
);
const docStats = computed(
  () => detailStatsData.value?.maDocumentSpaceDocumentStatistics?.nodes ?? []
);
const videoStats = computed(
  () => detailStatsData.value?.maDocumentSpaceVideoStatistics?.nodes ?? []
);
const linkStats = computed(
  () => detailStatsData.value?.maDocumentSpaceLinkStatistics?.nodes ?? []
);
const visitStats = computed(
  () => detailStatsData.value?.maDocumentSpaceVisitsStatistics?.nodes ?? []
);

const navItems = computed(() => [
  {
    text: "Shared Space Analytics",
    href: "/u/analytics/shared_spaces",
  },
  {
    text: detailStats?.value?.name,
  },
]);

let intervalId: number | null = null;
onMounted(() => {
  intervalId = window.setInterval(() => {
    fetchStats();
  }, 10000);
});

onBeforeUnmount(() => {
  if (intervalId !== null) {
    window.clearInterval(intervalId);
  }
});
</script>

<template>
  <div class="pane page_content">
    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-else-if="!fetching && visitStats && visitStats.length === 0">
      {{ $t("documentSpaceAnalytics.noData") }}
    </div>

    <template v-else>
      <div class="title_container">
        <Tooltip class="right">
          <template v-slot>
            <span class="material-symbols-outlined"> help </span>
          </template>
          <template #tooltip>{{
            $t("documentSpaceAnalytics.infoNoPreview")
          }}</template>
        </Tooltip>
        <breadcrumbs :items="navItems"></breadcrumbs>
      </div>

      <section class="cards" v-if="detailStats">
        <div class="card">
          <h3>{{ $t("documentSpaceAnalytics.totalVisits.title") }}</h3>
          <div class="value">
            {{ detailStats.totalVisits || 0 }}
          </div>
        </div>
        <div class="card">
          <h3>{{ $t("documentSpaceAnalytics.downloads.title") }}</h3>
          <div class="value">
            {{ detailStats.numDownloads || 0 }}
          </div>
        </div>
        <div class="card">
          <h3>{{ $t("documentSpaceAnalytics.linkClicks.title") }}</h3>
          <div class="value">
            {{ detailStats.numLinkClicks || 0 }}
          </div>
        </div>
        <div class="card">
          <h3>{{ $t("documentSpaceAnalytics.avgDuration.title") }}</h3>
          <div class="value">
            {{ formatDuration(detailStats!.avgDuration!) }}
          </div>
        </div>
      </section>

      <section>
        <h4>{{ $t("documentSpaceAnalytics.topDocuments.title") }}</h4>
        <div v-if="docStats.length === 0">
          {{ $t("documentSpaceAnalytics.topDocuments.noData") }}
        </div>

        <DocStats
          v-else
          v-for="(doc, idx) in docStats"
          :key="idx"
          :doc="doc! as MaDocumentSpaceDocumentStatistic"
          :spaceId="spaceId"
        />
      </section>

      <section>
        <h4>{{ $t("documentSpaceAnalytics.topVideos.title") }}</h4>
        <div v-if="videoStats.length === 0">
          {{ $t("documentSpaceAnalytics.topVideos.noData") }}
        </div>

        <VideoStats
          v-else
          v-for="(doc, idx) in videoStats"
          :key="idx"
          :videoStats="doc! as MaDocumentSpaceVideoStatistic"
          :spaceId="spaceId"
        />
      </section>

      <section>
        <h4>{{ $t("documentSpaceAnalytics.topLinks.title") }}</h4>
        <div v-if="linkStats.length === 0">
          {{ $t("documentSpaceAnalytics.topLinks.noData") }}
        </div>

        <table v-else class="data_table">
          <thead>
            <tr>
              <th>{{ $t("documentSpaceAnalytics.topLinks.metrics.link") }}</th>
              <th>
                {{ $t("documentSpaceAnalytics.topLinks.metrics.numberClicks") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(link, idx) in linkStats" :key="idx">
              <td>
                <a class="link" :href="link!.url!" __target="blank">
                  {{ link!.url }}
                </a>
              </td>
              <td>{{ link!.numClicks }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h4>{{ $t("documentSpaceAnalytics.visits.title") }}</h4>
        <table class="data_table">
          <thead>
            <tr>
              <th>{{ $t("documentSpaceAnalytics.visits.metrics.date") }}</th>
              <th>
                {{ $t("documentSpaceAnalytics.visits.metrics.duration") }}
              </th>
              <th>
                {{ $t("documentSpaceAnalytics.visits.metrics.interaction") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(visit, idx) in visitStats" :key="idx">
              <td>
                {{ Formatter.formatAndTranslateDate(visit!.startsAt! + "Z") }}
              </td>
              <td>
                {{ formatDuration(visit!.duration!) }}
              </td>
              <td>
                <span
                  v-if="visit!.interactions!.includes('documentHit')"
                  class="icon"
                >
                  <Tooltip>
                    <template v-slot>
                      <span class="material-symbols-outlined">
                        description
                      </span>
                    </template>
                    <template #tooltip>{{
                      $t("documentSpaceAnalytics.visits.metrics.openDocument")
                    }}</template>
                  </Tooltip>
                </span>

                <span
                  v-if="visit!.interactions!.includes('documentDownload')"
                  class="icon"
                >
                  <Tooltip>
                    <template v-slot>
                      <span class="material-symbols-outlined"> download </span>
                    </template>
                    <template #tooltip>{{
                      $t(
                        "documentSpaceAnalytics.visits.metrics.downloadDocument"
                      )
                    }}</template>
                  </Tooltip>
                </span>

                <span
                  v-if="visit!.interactions!.includes('linkClick')"
                  class="icon"
                >
                  <Tooltip>
                    <template v-slot>
                      <span class="material-symbols-outlined"> link </span>
                    </template>
                    <template #tooltip>{{
                      $t("documentSpaceAnalytics.visits.metrics.clickLink")
                    }}</template>
                  </Tooltip>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </template>
  </div>
</template>

<style scoped lang="scss">
$border-light: #ebebeb;
$border-strong: #cecece;

.page_content {
  background: transparent;
  font-size: 0.9rem;
  height: 100%;
  padding: 1em 1.5em;
}

.breadcrumbs {
  margin-bottom: 1em;
}

section {
  margin-bottom: 3em;
}

section h4 {
  margin-top: 0;
  margin-bottom: 0.7em;
}

.cards {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  column-gap: 2.6%;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1em;
}

.card {
  border-radius: 0.3em;
  border: 1px solid $border-strong;
  box-sizing: border-box;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  font-size: 0.9rem;
  background: #fff;
  padding: 1rem 1.2rem 1rem 1.2rem;
}

.card h3 {
  font-size: 1.1em;
  color: #acacac;
  font-weight: 400;
  margin-top: 0;
}

.card .value {
  font-size: 1.3em;
  font-weight: 500;
}

.title_container .tooltip_container {
  float: right;
  top: 50%;
  transform: translate(0, 50%);
}

.title_container .material-symbols-outlined {
  font-size: 1.4em;
}

.loading_status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;
}
</style>
