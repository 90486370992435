import { i18n } from "@/translations/index";
const { t, locale } = i18n.global;

function appendLeadingZero(num: number): string {
  return num.toString().padStart(2, "0");
}

function isToday(date: Date): boolean {
  return date.toDateString() === new Date(Date.now()).toDateString();
}

function isYesterday(date: Date): boolean {
  return new Date(date.setDate(date.getDate() + 1)).toDateString() === new Date(Date.now()).toDateString();
}

function toFormattedLocaleTimeString(date: Date, locale: string): string {
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
  };

  return date.toLocaleTimeString(locale, timeOptions);
}

function toFormattedLocaleDateTimeString(date: Date, locale: string): string {
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  return date.toLocaleTimeString(locale, dateTimeOptions);
}

function toFormattedLocaleDateString(date: Date, locale: string): string {
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  };

  return date.toLocaleDateString(locale, dateTimeOptions);
}

interface Duration {
  hours: number,
  minutes: number,
  seconds: number
}

export class Formatter {
  static formatDate(date: Date, formatStr: string): string {
    let dateStr = formatStr;
    dateStr = dateStr.replace(/%Y/g, date.getFullYear().toString());
    dateStr = dateStr.replace(/%m/g, appendLeadingZero(date.getMonth() + 1));
    dateStr = dateStr.replace(/%d/g, appendLeadingZero(date.getDate()));
    dateStr = dateStr.replace(/%H/g, appendLeadingZero(date.getHours()));
    dateStr = dateStr.replace(/%M/g, appendLeadingZero(date.getMinutes()));
    dateStr = dateStr.replace(/%S/g, appendLeadingZero(date.getSeconds()));

    return dateStr;
  }

  static translateTime(dateString: string): string {
    const date = new Date(dateString);
    return toFormattedLocaleTimeString(date, locale.value);
  }

  static translateDate(dateString: string): string {
    const date = new Date(dateString);

    if (isToday(date)) {
      return t("timeFormat.today");
    }

    if (isYesterday(date)) {
      return t("timeFormat.yesterday");
    }

    const weekday = t(`timeFormat.weekday.${date.getDay()}`)
    return `${weekday} ${toFormattedLocaleDateString(date, locale.value)}`
  }

  static formatAndTranslateDate(dateString: string): string {
    const date = new Date(dateString);

    if (isToday(date)) {
      return t("timeFormat.todayAt", { time: toFormattedLocaleTimeString(date, locale.value) });
    }
    else if (isYesterday(date)) {
      return t("timeFormat.yesterdayAt", { time: toFormattedLocaleTimeString(date, locale.value) });
    }

    return toFormattedLocaleDateTimeString(date, locale.value);
  }

  static formatDateStr(dateString: string): string {
    const date = new Date(dateString);
    return toFormattedLocaleDateString(date, locale.value);
  }

  /* duration in seconds */
  static formatDuration(duration: number | Duration): string {
    const parts = [];

    const hours = typeof duration === "number" ?
      Math.floor(duration / 3600) :
      duration.hours;
    const minutes = typeof duration === "number" ?
      Math.floor((duration - (hours * 3600)) / 60) :
      duration.minutes;
    const seconds = typeof duration === "number" ?
      duration - (hours * 3600) - (minutes * 60) :
      Math.floor(duration.seconds);

    if (hours > 0) {
      parts.push(appendLeadingZero(hours), "h");
    }

    parts.push(appendLeadingZero(minutes), "m");
    parts.push(appendLeadingZero(seconds), "s");

    return parts.join("");
  }

  static formatSize(size: number): string {
    /* hack to make sure size is a number as toFixed crashes otherwise */
    size = parseFloat(size.toString());

    let unitIdx = 0;
    while (size > 1000) {
      size = size / 1000;
      unitIdx++;
    }

    const units = ["B", "KB", "MB", "GB"];
    return `${size.toFixed(1)} ${units[unitIdx]}`;
  }
}
