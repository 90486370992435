<script lang="ts" setup>
import { Modal } from "@/components";
import { ref, watch } from "vue";
import { useUpdateWorkspaceNameMutation } from "@/graphql/operations";

const name = ref<string>("");
const emit = defineEmits(["close", "success"]);
const emitClose = () => {
  name.value = "";
  emit("close");
}

const emitSuccess = () => {
  emit("success");
}

const props = defineProps({
  active: {
    type: Boolean
  },
  name: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
});

const input = ref();
watch(() => props.active, (newVal, oldVal) => {
  if (!oldVal && newVal) {
    window.setTimeout(() => {
      input.value.focus();
      name.value = props.name;
    }, 3);
  }
});

const { executeMutation } = useUpdateWorkspaceNameMutation();
const submit = async () => {
  await executeMutation({
    id: props.id,
    name: name.value
  });

  emitClose();
  emitSuccess(); //TODO handle error
}
</script>

<template>
  <Modal @close="emitClose" :active="active">
    <template #header>
      {{ $t("workspaceDetail.rename.modalHeader")}}
    </template>
    <template #body>
      <input type="text" class="input" ref="input" v-model="name" />
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="submit">
          {{ $t("submit") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
