<template>
  <div class="outer page_content">
    <div class="center">
      <span class="material-symbols-outlined icon"> send </span>

      <h1 class="title">{{ $t("emailVerification.emailSentHeader") }}</h1>
      <p class="info">{{ $t("emailVerification.checkInbox") }}</p>

      <p class="info">{{ $t("emailVerification.resendHeader") }}</p>
      <button class="button secondary" @click="sendVerification">
        {{ $t("emailVerification.resendButton") }}
      </button>

      <div class="cancel_link" @click="logout">
        {{ $t("emailVerification.cancel") }}
      </div>

      <template v-if="state.state !== 'idle'">
        <FlashMessage
          :state="state.state"
          :message="$t(`emailVerification.emailSent.${state.state}`)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { auth } from "@/fire";
import { sendEmailVerification, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { FlashMessage } from "@/components";
import * as Sentry from "@sentry/vue";

const router = useRouter();

enum State {
  IDLE = "idle",
  SUCCESS = "success",
  ERROR = "error",
}

interface ComponentData {
  state: State;
}

const state: ComponentData = reactive({
  state: State.IDLE,
});

async function sendVerification() {
  const settings = {
    url: "http://" + window.location.host + "/u",
  };

  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("no user logged in");
    }

    await sendEmailVerification(user, settings);
    state.state = State.SUCCESS;
  } catch (err) {
    state.state = State.ERROR;
    Sentry.captureException(err);
  }

  window.setTimeout(() => {
    state.state = State.IDLE;
  }, 2000);
}

async function logout() {
  await signOut(auth);
  const path = router.resolve("/login");
  window.location.replace(path.href);
}
</script>

<style lang="scss" scoped>
.outer {
  height: 100%;
  width: 100%;
  display: flex;
}

.center {
  flex-direction: column;
  margin: auto;
  width: 60%;
  height: 50%;
  max-width: 600px;
  text-align: center;
}

.icon {
  font-size: 70px;
  margin-bottom: 20px;
}

p.info {
  color: #919191;
  font-weight: 500;
}

p.info + p.info {
  margin-top: 35px;
}

.cancel_link {
  margin-top: 15px;
  text-decoration: underline;
  color: #919191;
  cursor: pointer;
}
</style>
