<template>
  <div class="gallery_container" ref="galleryContainerElem">
    <div class="gallery" ref="galleryElem">
      <template v-if="flow && pages">
        <div
          v-for="(page, index) in pages"
          :key="page?.id"
          class="gallery_item"
        >
          <div class="img_pagination_wrapper">
            <Thumbnail
              v-if="page && page.pageId"
              :pageId="page.pageId!"
              :class="{ active: currentPageNumber === index + 1 }"
              @click="selectPage(index + 1)"
              @dblclick="selectAndClose(index + 1)"
            />

            <div
              v-else-if="page && page.video?.id"
              class="thumbnail"
              @click="selectPage(index + 1)"
              :class="{ active: currentPageNumber === index + 1 }"
            >
              <img :src="page.video!.thumbnailDownloadUrl" />
            </div>
            <span>{{ index + 1 }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCustomFlowQuery } from "@/graphql/operations";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { Thumbnail } from "@/components";

const THUMBNAIL_WIDTH = 335;

const props = defineProps({
  flowId: {
    type: String,
    required: true,
  },
  currentPageNumber: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["update:page", "close"]);

const { data: flowData } = useCustomFlowQuery({
  variables: {
    id: props.flowId,
  },
  requestPolicy: "cache-and-network",
});

const flow = computed(() => flowData.value?.flow);
const pages = computed(() => flow.value?.flowItems.nodes);

const selectPage = (pageNumber: number) => {
  emits("update:page", pageNumber);
};

const selectAndClose = (pageNumber: number) => {
  selectPage(pageNumber);
  emits("close");
};

const galleryContainerElem = ref<HTMLDivElement | null>(null);
const galleryElem = ref<HTMLDivElement | null>(null);

let numThumbnailsPerRow = 0;

const resizeGalleryWidth = (entries: ResizeObserverEntry[]) => {
  if (galleryContainerElem.value && galleryElem.value) {
    galleryElem.value.style.maxWidth = calculateGalleryWidth(
      entries[0].contentRect.width
    );
  }
};

const calculateGalleryWidth = (width: number): string => {
  // thumbnails per row = total width + 20 (last thumbnail right gap) / thumbnail width + 20 (border) + 20 (gap)
  numThumbnailsPerRow = Math.floor((width + 20) / (THUMBNAIL_WIDTH + 20 + 20));

  // gallerywidth = thumbnails per row * thumbnail width + 20 (border) + thumbnails per row - 1 (last in row) * 20 (gap)
  const galleryWidth =
    Math.floor(numThumbnailsPerRow * (THUMBNAIL_WIDTH + 20)) +
    (numThumbnailsPerRow - 1) * 20;
  return `${galleryWidth}px`;
};

const resizeObserver = new ResizeObserver(resizeGalleryWidth);

onMounted(() => {
  if (galleryContainerElem.value) {
    resizeObserver.observe(galleryContainerElem.value);
  }
});
onBeforeUnmount(() => {
  resizeObserver.disconnect();
});
</script>

<style lang="scss" scoped>
$thumbnail-size: 335px;
$primary: #35b6ba;
$hover: #dbdbdb;

.gallery_container {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  margin-top: 0.3em;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  .gallery_item {
    width: $thumbnail-size;
    height: $thumbnail-size;
    cursor: pointer;

    .img_pagination_wrapper {
      position: relative;

      .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        width: $thumbnail-size;
        height: $thumbnail-size;
        border: 5px solid transparent;

        &:hover {
          border: 5px solid $hover;
        }

        &.active {
          border: 5px solid $primary;
        }

        &:deep img {
          max-width: $thumbnail-size;
          max-height: $thumbnail-size;
          object-fit: contain;
        }
      }

      span {
        position: absolute;
        bottom: 1em;
        left: 1em;
        background-color: $primary;
        color: white;
        padding: 0.35em 0.65em;
        border-radius: 0.35em;
        opacity: 0.85;
      }
    }
  }
}
</style>
