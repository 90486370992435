import { db } from "@/fire";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { store } from "@/store";
import { UserAgent, DateUtil } from "@/util";
import { v4 as uuid } from "uuid";
import { runId } from "@/init";

export enum EventType {
  AppOpen = "event_app_open",
  ProjectorLaunch = "event_projector_launch",
  ProjectorTerminate = "event_projector_terminate",
  DocumentUploaded = "event_document_uploaded",
  OpenDocument = "event_open_document",
  CodeInputFailure = "event_code_input_failure",
  CodeInputSuccess = "event_code_input_success"
}

export enum Events {
  StartApp = "start_app",
  CloseApp = "close_app",
  OpenEntity = "open_entity",
  CloseEntity = "close_entity",
  PlayVideo = "play_video",
  SeekVideo = "seek_video",
  PauseVideo = "pause_video",
  ResumeVideo = "resume_video",  
  StopVideo = "stop_video",
  PauseApp = "pause_app",
  ResumeApp = "resume_app",
  UserIsIdle = "user_is_idle",
  UserIsActive = "user_is_active",
  Alive = "alive"
}

export class AnalyticsService {

  static logEvent(eventType: EventType, payload = "", jsonPayload = "") {
    setDoc(doc(db, "events", uuid()), {
      versionNumber: "1.00",
      o: store.getters.user?.id || "",
      platform: "web",
      type: eventType,
      timestamp: serverTimestamp(),
      browser: UserAgent.getBrowser(),
      userAgent: UserAgent.get(),
      deviceType: UserAgent.getDeviceType(),
      payload: payload,
      jsonPayload: jsonPayload
    });
  }

  static sendEvent(eventType: Events, payload: { [key: string]: string | number } = {}) {
    if (!store.getters.user?.hasDocumentTracking()) {
      return;
    }

    const data = {
      run: {
        id: runId.value,
        user_agent: UserAgent.get(),
        firebase_auth_id: store.getters.user?.id,
        deployment: window.origin,
      },
      type: eventType,
      tracked_on_the_client_at: DateUtil.timestampWithMillis(new Date()),
      tracked_on_firestore_at: serverTimestamp(),
    };

    const trackingData = {
      ...data,
      ...payload
    };

    setDoc(doc(db, "tracking", uuid()), trackingData );
  }

}