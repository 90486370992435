<script lang="ts" setup>
import { useLatestSpaceVisitsQuery } from "@/graphql/operations";
import { Formatter } from "@/util";

const props = defineProps({
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const { data, fetching, error } = useLatestSpaceVisitsQuery({
  variables: {
    documentSpaceId: props.documentSpaceId,
  },
});
</script>

<template>
  <div class="visits">
    <div class="container">
      <h4>Latest Visits:</h4>
      <router-link
        :to="{
          name: 'DocumentSpaceAnalytics',
          params: { id: documentSpaceId },
        }"
        class="link"
      >
        Detail Analytics
        <span class="material-symbols-outlined"> arrow_right_alt </span>
      </router-link>
    </div>
    <div
      v-for="visit in data?.maDocumentSpaceVisitsStatistics?.nodes.slice(0, 10)"
      :key="visit!.id!"
      class="row"
    >
      <span class="cell">
        {{ Formatter.formatAndTranslateDate(visit!.startsAt! + "Z") }}
      </span>
      <span
        v-if="visit!.interactions!.includes('documentHit')"
        class="material-symbols-outlined"
      >
        description
      </span>
      <span
        v-if="visit!.interactions!.includes('documentDownload')"
        class="material-symbols-outlined"
      >
        download
      </span>
      <span
        v-if="visit!.interactions!.includes('videoHit')"
        class="material-symbols-outlined"
      >
        smart_display
      </span>
      <span
        v-if="visit!.interactions!.includes('linkClick')"
        class="material-symbols-outlined"
      >
        link
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.visits {
  margin: 0 1em;
  padding: 1em 0;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  font-weight: normal;
  margin-left: auto;
  color: inherit;
}

a .material-symbols-outlined {
  vertical-align: middle;
}

.row {
  display: flex;
  align-items: center;
  line-height: 2;
}

.cell {
  margin-right: 1.8em;
}

.material-symbols-outlined {
  font-size: 20px;
}
</style>
