<script lang="ts" setup>
import { ref, Ref, PropType, computed } from "vue";
import {
  type DocumentSpaceLink,
  useUpdateDocumentSpaceConfigBannerMutation,
} from "@/graphql/operations";
import { UploadService, UploadResponseData } from "@/util";
import { token } from "@/init";
import { Breadcrumbs, DiscardChangesModal } from "@/components";
import { useFlashMessage } from "@/hooks";
import BannerToolbar from "./BannerToolbar.vue";
import LinkToolbar from "./LinkToolbar.vue";
import FooterToolbar from "./FooterToolbar.vue";
import LogoToolbar from "./LogoToolbar.vue";
import LegalNoteToolbar from "./LegalNoteToolbar.vue";
import FooterLinkToolbar from "./FooterLinkToolbar.vue";
import { type Config } from "./Config";

type ToolbarType = "link" | "banner" | "footer" | "logo" | "legalNote" | "footerLink";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
  link: {
    type: Object as PropType<DocumentSpaceLink | null>,
    required: false,
  },
  currentLanguage: {
    type: String,
    required: true,
  },
  toolbarType: {
    type: String as PropType<ToolbarType>,
    required: true,
  },
  arg: {
    type: null as unknown as PropType<any>,
    required: false
  }
});

const emits = defineEmits(["close", "reloadData", "newItem"]);
const close = () => {
  emits("close");
};

const displayDiscardChangesModal = ref<boolean>(false);
const hasUnsavedChanges = ref<boolean>(false);
const updateHasUnsavedChanges = (value: boolean) => {
  hasUnsavedChanges.value = value;
};

const handleClose = () => {
  if (hasUnsavedChanges.value) {
    displayDiscardChangesModal.value = true;
  } else {
    close();
  }
};

const { displayFlashMessage } = useFlashMessage();
const navItems = computed(() => [
  {
    text: "Contents",
  },
  {
    text: props.toolbarType,
  },
]);

const handleNewItem = (id: number) => {
  emits("newItem", id);
};
</script>

<template>
  <div class="toolbar">
    <div class="toolbar_header">
      <div class="breadcrumbs_container">
        <breadcrumbs :items="navItems" />
        <span class="material-symbols-outlined" @click="handleClose"
          >close</span
        >
      </div>
      <div class="header_label">
        {{ $t(`templateEditor.toolbar.${toolbarType}.title`) }}
      </div>
    </div>

    <banner-toolbar
      v-if="toolbarType === 'banner'"
      :config="config"
      @reloadData="emits('reloadData')"
      @changeState="updateHasUnsavedChanges"
    />

    <link-toolbar
      v-else-if="toolbarType === 'link'"
      :link="link ? link : undefined"
      :config="config"
      :currentLanguage="currentLanguage"
      @newItem="handleNewItem"
      @reloadData="emits('reloadData')"
      @close="emits('close')"
      @changeState="updateHasUnsavedChanges"
    />

    <footer-toolbar
      v-else-if="toolbarType === 'footer'"
      :config="config"
      @reloadData="emits('reloadData')"
      @changeState="updateHasUnsavedChanges"
    />

    <logo-toolbar
      v-else-if="toolbarType === 'logo'"
      :config="config"
      @reloadData="emits('reloadData')"
      @changeState="updateHasUnsavedChanges"
    />

    <legal-note-toolbar
      v-else-if="toolbarType === 'legalNote'"
      :config="config"
      @reloadData="emits('reloadData')"
      @changeState="updateHasUnsavedChanges"
    />

    <footer-link-toolbar
      v-else-if="toolbarType === 'footerLink'"
      :config="config"
      @reloadData="emits('reloadData')"
      @changeState="updateHasUnsavedChanges"
      @newItem="handleNewItem"
      @close="emits('close')"
      :idx="arg"
    />
  </div>

  <discard-changes-modal
    v-if="displayDiscardChangesModal"
    @submit="close"
    @close="displayDiscardChangesModal = false"
  />
</template>

<style scoped lang="scss">
.toolbar {
  width: 300px;
  border-right: 1px solid #e0e0e0;
}

.breadcrumbs_container {
  display: flex;
  align-items: center;
}

.breadcrumbs {
  font-size: 0.8em;
  flex: 1;
}

.toolbar_header {
  border-bottom: 1px solid #e0e0e0;
  padding: 1em;
  padding-top: 0;
  background: #fafafa;
}

.toolbar .header_label {
  font-weight: 500;
  padding-top: 0.5em;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}
</style>
