<template>
  <div class="page_content">
    <div>
      <div class="title_container">
        <h3>{{ $t("workspacesOverview.title") }}</h3>

        <button
          v-if="canCreateWorkspace && workspaces.length > 0"
          class="button primary"
          @click="displayCreateWorkspaceModal = true"
        >
          <span class="material-symbols-outlined">add</span>
          {{ $t("workspacesOverview.createNew") }}
        </button>
      </div>

      <div v-if="workspaces.length === 0" class="empty_pane">
        <div v-if="canCreateWorkspace">
          <span class="material-symbols-outlined">
            post_add
          </span>

          <h2>{{ $t("workspacesOverview.emptyState.title") }}</h2>
          <p class="info">
            {{ $t("workspacesOverview.emptyState.info") }}
          </p>
          <button class="button primary" @click="displayCreateWorkspaceModal = true">
            {{ $t("workspacesOverview.createNew") }}
          </button>
        </div>
        <div v-else>
          <h2>{{ $t("workspacesOverview.emptyState.title") }}</h2>
        </div>
      </div>

      <div v-else>
        <table class="data_table clickable">
          <thead>
            <th>{{ $t("workspacesOverview.columns.name") }}</th>
          </thead>

          <tbody>
            <tr
              v-for="workspace in workspaces"
              :key="workspace.id"
            >
              <td>
                <router-link
                  :to="{ name: 'Workspace', params: { workspaceId: workspace.id } }"
                >
                  {{ workspace.name }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <create-workspace-modal
    v-if="displayCreateWorkspaceModal"
    @close="displayCreateWorkspaceModal = false"
    @success="redirectToWorkspace" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CreateWorkspaceModal from "@/components/CreateWorkspaceModal.vue";

const store = useStore();
const router = useRouter();

const workspaces = computed(() => store.getters.rootWorkspaces);

const canCreateWorkspace = computed(() =>
  store.getters.user.canCreateWorkspace()
);

const displayCreateWorkspaceModal = ref<boolean>(false);
const redirectToWorkspace = (id: number) => {
  router.push({
    name: "Workspace",
    params: { workspaceId: id }
  });
}
</script>

<style lang="scss" scoped>
.page_content {
  height: 100%;
}

.title_container {
  display: flex;
  align-items: center;

  h3 {
    flex: 1;
    margin-top: 0;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 38px;
  }
}

table {
  &.data_table {
    box-shadow: none;
  }

  a {
    color: #444;
    font-weight: 500;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  thead tr th:last_child,
  tbody tr td:last_child {
    width: 30px;
  }

  td .material-symbols-outlined {
    font-size: 1.3em;
    color: #34B5BA;
    border: 1px solid #34B5BA;
    border-radius: 50%;
    padding: 0.2em;
  }
}

td a {
  display: block;
}

.empty_pane {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;

  > div {
    flex: none;
    text-align: center;
    margin-top: 5em;
  }

  .material-symbols-outlined {
    font-size: 6em;
    opacity: .4;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    margin: 0.6em 0 1.5em 0;
  }
}
</style>
