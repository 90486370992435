<script lang="ts" setup>
import { PropType, ref, computed, watch } from "vue";
import { type PageConfigFragment } from "@/graphql/operations";
import { Thumbnail, Tooltip } from "@/components";
import { getStorageProxyUrl, MimeType, Formatter } from "@/util";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  page: {
    type: Object as PropType<PageConfigFragment>,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["change"]);

const selectedType = ref<string>(props.value);

watch(
  () => props.value,
  (newValue, oldValue) => {
    selectedType.value = newValue;
  }
);

const select = (value: string) => {
  if (value !== selectedType.value) {
    emits("change", value);
    selectedType.value = value;
  }
};

const openLinkedDocument = () => {
  if (!props.page.linkedDocument || !props.page.linkedDocument.workspace?.id) {
    return;
  }

  const url = router.resolve({
    name: "StackedProjector",
    params: { workspaceId: props.page.linkedDocument.workspace.id },
    query: {
      fid: props.page.linkedDocument.firestoreId,
    },
  });

  window.open(url.href, "_blank")?.focus();
};
</script>

<template>
  <div class="container">
    <div class="thumbnail_container large">
      <thumbnail class="thumbnail_image" :pageId="page.id" />
    </div>
  </div>
  <div class="container">
    <div
      class="page_card"
      :class="{ highlighted: selectedType === 'NORMAL' }"
      @click="select('NORMAL')"
    >
      <div class="info_block">
        <b>{{ $t(`magicPageStudio.wizard.normalPage.title`) }}</b>
        <div>{{ $t(`magicPageStudio.wizard.normalPage.info`) }}</div>
      </div>
    </div>
    <div
      class="page_card"
      :class="{
        highlighted: selectedType === 'POPOVER',
        disabled: page.pageNumber === 1,
      }"
      @click="select('POPOVER')"
    >
      <div class="info_block">
        <b>{{ $t(`magicPageStudio.wizard.popover.title`) }}</b>
        <div>{{ $t(`magicPageStudio.wizard.popover.info`) }}</div>
      </div>
    </div>
    <div
      class="page_card"
      :class="{
        highlighted: selectedType === 'MAGIC_LINK',
        disabled: page.pageNumber === 1,
      }"
      @click="select('MAGIC_LINK')"
    >
      <div class="info_block">
        <b>{{ $t(`magicPageStudio.wizard.magicLink.title`) }}</b>
        <div>{{ $t(`magicPageStudio.wizard.magicLink.info`) }}</div>
      </div>
      <div class="border bottom" v-if="page.linkedDocument">
        <div class="label">
          {{ $t("magicPageStudio.wizard.magicLink.linkedDocument.label") }}
          <tooltip v-if="!page.linkedDocument.workspace?.id" class="multiline">
            <template v-slot>
              <span class="material-symbols-outlined"> info </span>
            </template>
            <template #tooltip>
              {{
                $t("magicPageStudio.wizard.magicLink.linkedDocument.noAccess")
              }}
            </template>
          </tooltip>
        </div>

        <div class="doc_row">
          <div class="thumbnail_container">
            <img
              :src="
                getStorageProxyUrl(page.linkedDocument.thumbnailDownloadUrl)
              "
              :alt="page.linkedDocument.displayTitle"
              class="thumbnail_image"
            />
          </div>

          <div>
            <div>
              <Tooltip class="right">
                <template v-slot>
                  <div class="tooltip_text">
                    <a
                      v-if="page.linkedDocument.workspace?.id"
                      class="link"
                      @click="openLinkedDocument"
                    >
                      {{ page.linkedDocument.displayTitle }}
                      <span class="material-symbols-outlined">
                        open_in_new
                      </span>
                    </a>
                    <template v-else>
                      {{ page.linkedDocument.displayTitle }}
                    </template>
                  </div>
                </template>
                <template #tooltip>
                  {{ page.linkedDocument.displayTitle }}
                </template>
              </Tooltip>
            </div>
            <div class="info">
              <template v-if="MimeType.isVideo(page.linkedDocument.mimeType)">
                <span>{{
                  Formatter.formatDuration(page.linkedDocument.pages)
                }}</span>
              </template>
              <template v-else>
                <span>{{
                  $t("documentSearch.document.numPages", {
                    count: page.linkedDocument.pages,
                  })
                }}</span>
              </template>
              <span> - </span>
              <span>
                {{ MimeType.getName(page.linkedDocument.mimeType) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$card-height: 220px;
$card-width: 285px;

.container {
  display: flex;
}

.page_card {
  display: flex;
  flex-direction: column;
  height: $card-height;
  max-width: $card-width;
  border: 1px solid $border-strong;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 1.5em;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.page_card.highlighted {
  border-color: #3273dc;
}

.page_card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.page_card.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.container .page_card:last-child {
  margin-right: 0;
}

.page_card .thumbnail img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  max-height: 180px;
  margin: 0;
}

.info_block {
  padding: 0.5em 1em;
}

.info_block b {
  margin-bottom: 0.5em;
  display: block;
}

.border {
  border-top: 1px solid $border-light;
}

.bottom {
  margin: 0 1em;
  margin-top: auto;
  padding: 0.8em 0 1em 0;
}

.doc_row {
  display: flex;
  align-items: center;
}

.thumbnail_container.large {
  width: 100%;
  height: 238px;
  text-align: center;
  margin-bottom: 1em;
}

.doc_row .thumbnail_container {
  height: 60px;
  width: 60px;
  margin-right: 0.5em;
}

.thumbnail_image {
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

.thumbnail_container.large .thumbnail_image {
  border-radius: 0;
}

.doc_row .info {
  color: rgba(82, 74, 62, 0.82);
}

.tooltip_text {
  text-align: left; // left align title
  white-space: nowrap;
  width: calc(240px - 60px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  margin-bottom: 0.3em;
}

.link {
  color: inherit;
}

.label .material-symbols-outlined,
.link .material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: middle;
}
</style>
