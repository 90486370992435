<script lang="ts" setup>
import { Search, WorkspaceTree } from "@/components";
import DocumentsByWorkspace from "./DocumentsByWorkspace.vue";
import DocumentSearchResult from "./DocumentSearchResult.vue";
import { computed, ref, PropType, watch } from "vue";
import {
  useWorkspaceForDocumentQuery,
  type DocumentSearchResultFragment,
} from "@/graphql/operations";

const props = defineProps({
  onlyShareable: {
    type: Boolean,
    required: false,
    default: false,
  },
  mustSupportFlows: {
    type: Boolean,
    required: false,
    default: false,
  },
  singleSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  selectedDocument: {
    type: String,
    required: false,
    default: null,
  },
  excluded: {
    type: Array as PropType<Array<string>>,
    required: false,
    default: [],
  },
  selectExcluded: {
    type: Boolean,
    required: false,
    default: true,
  },
  disallowVideo: {
    type: Boolean,
    required: false,
    default: false,
  },
  rootWorkspace: {
    type: String,
    required: false,
  },
  singleWorkspace: {
    type: Boolean,
    required: false,
    default: false,
  },
  mustBeAdmin: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const mimeTypes = [
  {
    value: null,
    title: "All",
    reset: true,
  },
  {
    value: "application/mp4",
    title: "Video",
  },
  {
    value: "application/pdf",
    title: "PDF",
  },
];

const selectedWorkspace = ref<string | undefined>(props.rootWorkspace);
const loading = ref(true);

//if a document is selected fetch the workspace id of the document
if (props.selectedDocument) {
  const { data, fetching } = useWorkspaceForDocumentQuery({
    variables: {
      documentId: props.selectedDocument,
    },
  });

  watch(
    data,
    () => {
      if (!data.value?.document) {
        return;
      }

      const doc = data.value.document;
      if (doc.workspaceId != selectedWorkspace.value) {
        selectedWorkspace.value = doc.workspaceId;
      }

      loading.value = false;
    },
    { immediate: true }
  );
} else {
  loading.value = false;
}

const selection = ref<Map<string, string>>(new Map());
if (props.selectedDocument) {
  selection.value.set(props.selectedDocument, props.selectedDocument);
}

const filter = ref<string>("");

const selectWorkspace = (workspaceId: string) => {
  selectedWorkspace.value = workspaceId;
};

const emit = defineEmits(["change"]);

const mimeTypeFilter = ref<string | null>(
  props.disallowVideo ? "application/pdf" : null
);

const setFilter = (value: string) => {
  filter.value = value;
};

const applyMimeTypeFilter = (mimeType: string) => {
  mimeTypeFilter.value = mimeType;
};

const unselect = (id: string) => {
  selection.value.delete(id);
};

const changeSelection = (doc: DocumentSearchResultFragment) => {
  if (selection.value.has(doc.id) && !props.singleSelect) {
    unselect(doc.id);
  } else {
    if (props.singleSelect) {
      selection.value.clear();
    }
    selection.value.set(doc.id, doc.id);
  }

  emit("change", selection.value);
};

const selectedDocumentIds = computed(() => {
  return Array.from(selection.value.keys());
});
</script>

<template>
  <div v-if="loading">Loading...</div>

  <div v-else class="modal-body">
    <Search
      :placeholder="$t('documentSearch.search.placeholder')"
      @change="setFilter"
      @submit="setFilter"
      data-test="document-search"
    />

    <template v-if="!!filter.length">
      <DocumentSearchResult
        :filter="filter"
        :single-select="singleSelect"
        :only-shareable="onlyShareable"
        :must-support-flows="mustSupportFlows"
        :selection="selectedDocumentIds"
        :must-be-admin="mustBeAdmin"
        :excluded="excluded"
        :selectExcluded="selectExcluded"
        :rootId="rootWorkspace"
        @change="changeSelection"
      />
    </template>

    <div v-show="!filter.length" class="items">
      <div class="columns">
        <div class="col menu">
          <workspace-tree
            :selected="selectedWorkspace"
            :singleWorkspace="singleWorkspace"
            :mustBeAdmin="mustBeAdmin"
            @change="selectWorkspace"
          />
        </div>

        <div class="col">
          <DocumentsByWorkspace
            v-if="selectedWorkspace"
            :workspaceId="selectedWorkspace"
            :single-select="singleSelect"
            :onlyShareable="onlyShareable"
            :must-support-flows="mustSupportFlows"
            :selection="selectedDocumentIds"
            :excluded="excluded"
            :selectExcluded="selectExcluded"
            @change="changeSelection"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$checkbox-width: 40px;
$color-secondary: #34b5ba;

div {
  font-size: 0.9rem;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.items {
  height: 100%;
}

.controls {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 1em;
}

.control {
  margin-right: 1em;
}

.checkbox_container {
  vertical-align: middle;
  padding: 0.8em 0.8em 0.8em 0.5em;
  cursor: pointer;
}

.info {
  padding: 3em 0;
  font-style: italic;
  text-align: center;
}

.item.disabled {
  opacity: 0.6;
}

.item.disabled:active {
  pointer-events: none;
}

.item.disabled:hover {
  cursor: initial;
}

.item.disabled input {
  visibility: hidden;
}

.doc_info {
  width: 150px;
  font-style: italic;
}

.clickable_row {
  cursor: pointer;
}

.tooltip_container {
  width: 100%;
}

.tooltip_text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.breadcrumbs {
  font-size: 1em;
  margin-bottom: 1em;
}

.workspaces tr td {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.search {
  margin-bottom: 1em;
}

.modal-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.items {
  overflow: hidden;
}

.columns {
  display: grid;
  grid-template-columns: 20% calc(80% - 20px);
  column-gap: 20px;
  row-gap: 15px;
  align-items: start;
  height: 100%;
  overflow: hidden;
}

.col {
  overflow: hidden;
  height: 100%;
}

.col.menu {
  border-right: 1px solid #dbdbdb;
  overflow: scroll;
}
</style>
