<script lang="ts" setup>
import { computed, ref } from "vue";
import { Modal, ContactPicker } from "@/components";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  useCloneDocumentSpaceMutation,
  CrmContact,
} from "@/graphql/operations";

const store = useStore();
const router = useRouter();

const props = defineProps({
  sourceSpaceId: {
    type: String,
    required: true
  }
});

const emits = defineEmits(["close"]);

const withCRMIntegration = computed(() =>
  store.getters.user.hasCRMIntegration()
);

enum STEPS {
  SELECT_CONTACT = "selectContact",
  INSERT_NAME = "insertName",
}

const currentStep = ref<STEPS>(
  withCRMIntegration.value ? STEPS.SELECT_CONTACT : STEPS.INSERT_NAME
);

const name = ref("");
    //newName: t("documentSpace.copyName", { name: props.documentSpace.name }),

const selectedContact = ref<CrmContact>();
const setSelectedContact = (contact: CrmContact, defaultName: string) => {
  selectedContact.value = contact;
  name.value = defaultName;
};

const { executeMutation: executeCopy } = useCloneDocumentSpaceMutation();
const submit = async () => {
  if (name.value.length === 0) {
    return;
  }

  const result = await executeCopy({
    id: props.sourceSpaceId,
    newName: name.value,
    crmContactId: selectedContact.value?.id
  });
  
  if (!result.data?.cloneDocumentSpace?.clonedSpace?.id) {
    return;
  }
  
  const url = router.resolve({
    name: "DocumentSpace",
    params: { id: result.data?.cloneDocumentSpace?.clonedSpace.id },
  });
  
  window.open(url.href, "_blank")?.focus();
  emits("close");
};

const skip = () => {
  selectedContact.value = undefined;
  name.value = "";
  currentStep.value = STEPS.INSERT_NAME;
};

const goBack = () => {
  currentStep.value = STEPS.SELECT_CONTACT;
};
</script>

<template>
  <Modal
    v-if="currentStep === STEPS.INSERT_NAME"
    :active="true"
    @close="emits('close')"
  >
    <template #header>
      {{ $t("copySpaceModal.header") }}
    </template>
    <template v-slot:body>
      <div>
        <label class="label">
          {{ $t("copySpaceModal.name") }}
        </label>
        <form @submit.prevent="submit">
          <input class="input" type="text" v-model="name" ref="inputName" />
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <div class="buttons">
        <button
          v-if="withCRMIntegration"
          class="button secondary"
          @click="goBack"
        >
          {{ $t("copySpaceModal.previousStep") }}
        </button>
        <button v-else @click="emits('close')" class="button secondary">
          {{ $t("cancel") }}
        </button>
        <button
          :class="{
            button: true,
            primary: true,
            disabled: name.length === 0,
          }"
          @click="submit"
        >
          <span class="material-symbols-outlined">save</span>
          {{ $t("copySpaceModal.submit") }}
        </button>
      </div>
    </template>
  </Modal>

  <Modal
    v-else
    :active="true"
    @close="emits('close')"
    class="medium_large"
    :scrolling="true"
  >
    <template #header>
      {{ $t("copySpaceModal.header") }}
    </template>
    <template v-slot:body>
      <contact-picker @change="setSelectedContact" @submit="submit" />
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button class="button secondary" @click="skip">
          <span class="material-symbols-outlined">save</span>
          {{ $t("copySpaceModal.skip") }}
        </button>
        <button
          :class="{
            button: true,
            primary: true,
            disabled: !selectedContact,
          }"
          @click="submit"
        >
          <span class="material-symbols-outlined">save</span>
          {{ $t("copySpaceModal.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
