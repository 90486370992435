<template>
  <div class="logo brand">
    <img v-if="brandURL" :src="brandURL" class="logo" />
    <a v-else-if="!state.loadingBrand" href="https://pitchview.de">
      <img src="@/assets/images/pitchview_logo.png" class="logo" />
    </a>
  </div>
</template>

<script lang="ts" setup>
import { reactive, watch, computed } from "vue";
import { db } from "@/fire";
import { getDoc, doc } from "firebase/firestore";
import { useRoute } from "vue-router";
const route = useRoute();

interface Brand {
  brandURL?: string;
}

interface ComponentData {
  brand: null | Brand;
  loadingBrand: boolean;
}

const state: ComponentData = reactive({
  brand: null,
  loadingBrand: route.params.hash !== undefined,
});

async function fetchBrand() {
  const brandID = route.params.hash as string;
  const docSnap = await getDoc(doc(db, "brands", brandID.substring(1)));
  if (docSnap.exists()) {
    state.loadingBrand = false;
    state.brand = {
      brandURL: docSnap.data()?.brandURL,
    };
  }
}

watch(
  () => route.params.hash,
  (newValue) => {
    if (!newValue || newValue.length === 0) {
      state.loadingBrand = false;
      return;
    }

    fetchBrand();
  }
);

const brandURL = computed(() => state.brand?.brandURL || null);
</script>

<style lang="scss" scoped>
$logo-height: 30px;

.logo {
  height: $logo-height;
}
</style>
