<template>
  <div class="breadcrumbs">
    <div
      v-for="(item, i) of breadcrumbItems"
      :key="i"
      class="item"
      :class="{ editable: item.isEditable }"
    >
      <router-link
        v-if="item.href && item.href !== '#'"
        :to="item.href"
        class="link"
      >
        {{ item.text }}
      </router-link>

      <span v-else-if="item.href" class="link" @click="emit('click', item.id)">
        {{ item.text }}
      </span>

      <span v-else-if="item.isEditable">
        <EditableInput
          v-if="editMode"
          :value="item.text || ''"
          @submit="emitUpdate"
          @cancel="editMode = false"
        />
        <span v-else class="container clickable" @click="editMode = true">
          <span>
            {{ item.text }}
          </span>
          <span class="material-symbols-outlined"> edit </span>
        </span>
      </span>

      <span v-else>
        {{ item.text }}
      </span>

      <span
        class="material-symbols-outlined divider"
        v-if="i < breadcrumbItems.length - 1"
      >
        chevron_right
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from "vue";
import { EditableInput } from "@/components";

const props = defineProps({
  items: {
    type: Array as PropType<
      (
        | { text: string; href: string; id?: string; isEditable?: boolean }
        | {
            text: string | undefined | null;
            href?: undefined;
            id?: string;
            isEditable?: boolean;
          }
      )[]
    >,
    default: [],
  },
  collapse: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits(["click", "update"]);

const breadcrumbItems = computed(() => {
  if (props.collapse && props.items.length > 2) {
    return props.items
      .slice(0, 1)
      .concat(
        { text: "..." },
        props.items.slice(props.items.length - 1, props.items.length)
      );
  } else {
    return props.items;
  }
});

const editMode = ref(false);

const emitUpdate = (value: string) => {
  emit("update", value);
  editMode.value = false;
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  font-size: 1.1rem;
  font-weight: 500;
}

.small {
  font-size: 0.9rem;
  font-weight: normal;
}

.breadcrumbs .item {
  display: inline-block;
  line-height: 38px;
}

.link {
  text-decoration: none;
  color: #736c64;
}

.link:hover {
  text-decoration: underline;
  color: inherit;
}

.divider {
  vertical-align: middle;
  font-size: 1rem;
}

.container {
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  font-size: 1rem;
  padding: 0.5em;
}
</style>
