<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useWorkspaceDetailsQuery } from "@/graphql/operations";
import {
  Breadcrumbs,
  FlashMessage,
  LoadingSpinner,
  Tooltip,
} from "@/components";
import { useI18n } from "vue-i18n";
import RenameModal from "./RenameModal.vue";
import SubscriptionManagementModal from "./SubscriptionManagementModal.vue";
import DeleteModal from "./DeleteModal.vue";
import CreateSubfolderModal from "./CreateSubfolderModal.vue";
import MoveFolderModal from "./MoveFolderModal.vue";

const { t } = useI18n({});
const router = useRouter();
const route = useRoute();
const store = useStore();

const workspaceId = route.params.workspaceId as string;

const { fetching, data, executeQuery } = useWorkspaceDetailsQuery({
  variables: {
    id: workspaceId,
  },
  requestPolicy: "cache-and-network",
});

const intervalId = window.setInterval(() => executeQuery(), 3000);

onBeforeUnmount(() => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }
});

const workspace = computed(() => data.value?.workspace);
const name = computed(() => workspace.value?.name || "");
const id = computed(() => workspace.value?.id);
const teams = computed(
  () => workspace.value?.workspaceSubscriptionsByTeamId.nodes
);
const isSubfolder = computed(
  () =>
    !!workspace.value?.ancestors?.totalCount &&
    workspace.value!.ancestors!.totalCount > 0
);

const navItems = computed(() => {
  if (!workspace.value) {
    return [];
  }

  const ancestors = workspace.value?.ancestors?.nodes || [];
  const items: Array<{ text: string; href?: string }> = ancestors.map((a) => ({
    text: a!.name,
    href: router.resolve({
      name: "Workspace",
      params: { workspaceId: a!.id },
    }).fullPath,
  }));

  items.push({
    text: workspace.value.name,
  });

  return items;
});

const renameModalActive = ref<boolean>(false);
const subscriptionModalActive = ref<boolean>(false);
const deleteModalActive = ref<boolean>(false);
const newSubfolderModalActive = ref<boolean>(false);
const moveFolderModalActive = ref<boolean>(false);

const actionState = ref<string>();
const flashMessageActive = ref<boolean>(false);

const onUpdateSuccess = () => {
  executeQuery();
  actionState.value = "success";
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 1500);
};

const redirectAfterDelete = async () => {
  if (isSubfolder.value) {
    router.push({
      name: "Workspace",
      params: { workspaceId: data.value!.workspace!.parentId! },
    });
  } else {
    router.push({ path: "/u" });
  }
};

const redirectToNewSubfolder = (newSubfolderId: string | undefined) => {
  if (newSubfolderId) {
    const url = router.resolve({
      name: "Workspace",
      params: { workspaceId: newSubfolderId },
    }).fullPath;

    window.open(url, "_blank")!.focus();
  }
};
</script>

<template>
  <div class="page_content">
    <flash-message
      v-if="flashMessageActive"
      :message="$t(`workspaceDetail.actionMessage.${actionState}`)"
      :state="actionState"
    />

    <div v-if="fetching" class="loading_status">
      <loading-spinner :color="'#444'" />
    </div>

    <template v-else-if="workspace">
      <button
        class="button secondary item_right"
        @click="newSubfolderModalActive = true"
      >
        {{ $t("workspaceDetail.createSubfolder.buttonText") }}
      </button>
      <button
        class="button secondary item_right"
        @click="renameModalActive = true"
      >
        <span class="material-symbols-outlined">edit</span>
        {{ $t("workspaceDetail.rename.buttonText") }}
      </button>
      <button
        class="button secondary item_right"
        @click="moveFolderModalActive = true"
      >
        <span class="material-symbols-outlined">drive_file_move</span>
        {{ $t("workspaceDetail.move.buttonText") }}
      </button>

      <tooltip class="item_right">
        <template v-slot>
          <button
            :class="{
              button: true,
              secondary: true,
              disabled: !workspace.canBeDeleted,
            }"
            @click="deleteModalActive = true"
            data-test="delete-workspace-ctrl"
          >
            <span class="material-symbols-outlined">delete</span>
            {{ $t("workspaceDetail.delete.buttonText") }}
          </button>
        </template>
        <template #tooltip v-if="!workspace.canBeDeleted">
          {{ $t("workspaceDetail.delete.notAllowed") }}
        </template>
      </tooltip>

      <breadcrumbs :items="navItems"></breadcrumbs>

      <template v-if="!isSubfolder">
        <button
          class="button primary item_right"
          @click="subscriptionModalActive = true"
        >
          {{ $t("workspaceDetail.manageTeams.buttonText") }}
        </button>

        <h4>
          {{ $t("workspaceDetail.teamSubscriptions.title") }}

          <tooltip>
            <template v-slot>
              <span class="material-symbols-outlined"> help_outline </span>
            </template>
            <template #tooltip>
              {{ $t("workspaceDetail.teamSubscriptions.info") }}
            </template>
          </tooltip>
        </h4>

        <table class="data_table" v-if="teams && teams.length > 0">
          <tbody>
            <tr v-for="team in teams" :key="team!.subteam!.id">
              <td>{{ team!.subteam!.name }}</td>
            </tr>
          </tbody>
        </table>

        <p v-else class="info">
          <span>{{ $t("workspaceDetail.teamSubscriptions.empty") }}</span>
        </p>
      </template>

      <rename-modal
        :id="id!"
        :name="name"
        :active="renameModalActive"
        @close="renameModalActive = false"
        @success="onUpdateSuccess"
      ></rename-modal>

      <delete-modal
        :id="id!"
        :active="deleteModalActive"
        :isSubfolder="isSubfolder"
        :name="name"
        @close="deleteModalActive = false"
        @success="redirectAfterDelete"
      ></delete-modal>

      <subscription-management-modal
        v-if="subscriptionModalActive"
        :id="id!"
        :active="subscriptionModalActive"
        @close="subscriptionModalActive = false"
        @success="onUpdateSuccess"
      ></subscription-management-modal>

      <create-subfolder-modal
        v-if="newSubfolderModalActive"
        :id="id!"
        :active="newSubfolderModalActive"
        @close="newSubfolderModalActive = false"
        @success="redirectToNewSubfolder"
      ></create-subfolder-modal>

      <move-folder-modal
        v-if="moveFolderModalActive"
        :active="moveFolderModalActive"
        :workspace="store.getters.currentWorkspace"
        @close="moveFolderModalActive = false"
      />
    </template>

    <template v-else> Could not fetch workspace </template>
  </div>
</template>

<style lang="scss" scoped>
.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.button {
  font-size: 0.9em;
  margin-left: 5px;
}

.item_right {
  float: right;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.8;
}

.breadcrumbs {
  margin-bottom: 1em;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
  margin-top: 1em;
  font-style: italic;
}

.tooltip_container {
  line-height: 1.2;
}

.tooltip_container .material-symbols-outlined {
  vertical-align: super;
  font-size: 1em;
}
</style>
