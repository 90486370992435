<script lang="ts" setup>
import { computed, ref, watch, Ref, ComputedRef } from "vue";
import { useElementSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import {
  useDocumentSpaceConfigQuery,
  type DocumentSpaceConfig,
  type DocumentSpaceLink,
} from "@/graphql/operations";
import { Tooltip } from "@/components";
import LinkCard from "./LinkCard.vue";
import EditorNavbar from "./EditorNavbar.vue";
import ToolbarWrapper from "./ToolbarWrapper.vue";
import { Config } from "./Config";
import { getTranslator } from "./SpaceTranslator";

const route = useRoute();

const id = route.params.id as string;

const { data, fetching, error, executeQuery } = useDocumentSpaceConfigQuery({
  variables: {
    id: id,
  },
  requestPolicy: "cache-and-network",
});

const config = computed(() => data.value?.documentSpaceConfig);

const banner: Ref<HTMLElement | null> = ref(null);
const { width } = useElementSize(banner);
watch(width, () => {
  if (width.value < 860) {
    banner.value!.style.height = "150px";
  } else if (width.value < 1023) {
    banner.value!.style.height = "200px";
  }
});

const toolbarActive = ref(false);
const toolbarType = ref<
  "link" | "banner" | "footer" | "logo" | "legalNote" | "footerLink" | null
>(null);
const activeLink = ref<DocumentSpaceLink | null>(null);
const toolbarArg = ref<any>();

function displayToolbar(
  ttype: "link" | "banner" | "footer" | "logo" | "legalNote",
  link?: DocumentSpaceLink | null
): void;

function displayToolbar(ttype: "footerLink", idx: number): void;

function displayToolbar(
  ttype: "link" | "banner" | "footer" | "logo" | "legalNote" | "footerLink",
  secondArg?: any
): void {
  toolbarType.value = ttype;
  toolbarActive.value = true;
  toolbarArg.value = secondArg;

  if (ttype === "link") {
    activeLink.value = secondArg as DocumentSpaceLink;
  }
}

const closeToolbar = () => {
  toolbarActive.value = false;
  toolbarType.value = null;
  activeLink.value = null;
};

const links = computed(() => config.value?.documentSpaceLinksByConfigId?.nodes);

const hasLinks = computed(() => {
  return links.value && links.value.length > 0;
});

const currentLanguage = ref("default");
const switchLanguage = (language: string) => {
  currentLanguage.value = language;
  if (toolbarType.value === "logo") {
    closeToolbar();
  }
};

const mergedConfig: ComputedRef<Config | null> = computed(() => {
  if (!config.value) {
    return null;
  }

  return new Config(config.value as DocumentSpaceConfig, currentLanguage.value);
});

const translator = computed(() => {
  const i18n = getTranslator(
    mergedConfig.value ? mergedConfig.value.currentLanguageCode : ""
  );
  const { t } = i18n.global;
  return t;
});

const handleNewItem = async (arg: number | string) => {
  await executeQuery();
  if (toolbarType.value === "link") {
    const link = links.value?.find((l) => l!.id === (arg as string));
    if (link) {
      activeLink.value = link as DocumentSpaceLink;
    }
  } else if (toolbarType.value === "footerLink") {
    toolbarArg.value = arg as number;
  }
};
</script>

<template>
  <div class="page_content template_editor">
    <template v-if="config && mergedConfig">
      <editor-navbar
        :currentConfig="mergedConfig"
        :currentLanguage="currentLanguage"
        :name="config.name!"
        @newContent="displayToolbar"
        @switchLanguage="switchLanguage"
        @reloadData="executeQuery"
      />

      <div class="inner_content">
        <toolbar-wrapper
          v-if="toolbarActive && toolbarType"
          @close="closeToolbar"
          @reloadData="executeQuery"
          @newItem="handleNewItem"
          :config="mergedConfig"
          :link="activeLink"
          :currentLanguage="currentLanguage"
          :toolbarType="toolbarType"
          :arg="toolbarArg"
        />

        <div class="content_preview">
          <div class="navbar inner" v-if="mergedConfig.logoUrl">
            <div class="navbar-brand">
              <div
                v-if="!mergedConfig.isTranslation"
                class="field"
                :class="{ active: toolbarType === 'logo' }"
                @click="displayToolbar('logo')"
              >
                <a class="navbar-item">
                  <img :src="mergedConfig.logoUrl" />
                </a>
              </div>

              <div v-else class="field disabled">
                <tooltip>
                  <template v-slot>
                    <a class="navbar-item">
                      <img :src="mergedConfig.logoUrl" />
                    </a>
                  </template>
                  <template #tooltip>
                    {{ $t("templateEditor.notEditable") }}
                  </template>
                </tooltip>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="hero_container inner">
              <div
                class="field"
                :class="{ active: toolbarType === 'banner' }"
                @click="displayToolbar('banner')"
              >
                <div class="banner" ref="banner">
                  <img :src="mergedConfig.heroImageUrl || ''" />
                </div>
              </div>
            </div>

            <div class="background"></div>

            <div class="message-container inner">
              <div class="cols">
                <div class="col">
                  <div class="content_placeholder">
                    {{ $t("templateEditor.personalMessage.placeholderText") }}
                  </div>
                </div>
                <div class="col">
                  <div class="content_placeholder">
                    {{ $t("templateEditor.contactDetails.placeholderText") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="main-container inner">
              <div class="cols">
                <div
                  class="custom_content col"
                  :class="{ 'is-two-thirds': hasLinks }"
                >
                  <div class="section-title">
                    <b>
                      {{ translator("documents") }}
                    </b>
                  </div>
                  <div class="cols">
                    <div class="col">
                      <div class="cols">
                        <div class="col is-half">
                          <div class="document_placeholder">
                            <span class="material-symbols-outlined">
                              description
                            </span>
                          </div>
                        </div>
                        <div class="col is-half">
                          <div class="document_placeholder">
                            <span class="material-symbols-outlined">
                              description
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="hasLinks" class="links col is-one-third">
                  <div class="section-title">
                    <b>{{ translator("links") }}</b>
                  </div>

                  <div
                    class="field"
                    :class="{ active: activeLink && activeLink.id === link!.id }"
                    @click.stop="
                      displayToolbar('link', link as DocumentSpaceLink)
                    "
                    v-for="link in config.documentSpaceLinksByConfigId?.nodes"
                    :key="link!.id"
                  >
                    <link-card
                      :link="link as DocumentSpaceLink"
                      :currentLanguage="currentLanguage"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="footer" v-if="mergedConfig.legalNote">
              <div
                class="field"
                :class="{ active: toolbarType === 'legalNote' }"
                @click="displayToolbar('legalNote')"
              >
                <div
                  class="content inner"
                  v-html="mergedConfig.legalNote"
                ></div>
              </div>
            </div>

            <div class="footer inner">
              <div class="cols">
                <ul class="col is-two-thirds links">
                  <li
                    v-for="(link, idx) in mergedConfig.footerLinks"
                    class="field"
                    :class="{
                      active:
                        toolbarType === 'footerLink' && toolbarArg === idx,
                    }"
                    :key="idx"
                    @click="displayToolbar('footerLink', idx as number)"
                  >
                    <a>{{ link.text }}</a>
                  </li>
                </ul>
                <div class="col is-one-third text_right">
                  <div
                    class="field"
                    :class="{ active: toolbarType === 'footer' }"
                    @click="displayToolbar('footer')"
                  >
                    {{ mergedConfig.footerText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.page_content {
  background: #fafafa;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  height: 100vh;
  color: #4a4a4a;
}

.inner_content {
  height: calc(100vh - 30px);
  display: flex;
  background: #f0f0f0;
}

.content_preview {
  background: #fff;
  box-shadow: -1px -1px 14px 1px hsla(0, 2%, 80%, 0.68);
  flex: 1;
  min-width: 0;
  overflow: hidden;
  margin: 1em 1.5em;
  padding: 0.5em 0 1.5em 0;
  overflow-y: scroll;
}

.container {
  position: relative;
  max-width: 100% !important;
  margin: 0 auto;
  width: auto;
}

.hero_container {
  position: relative;
  z-index: 2;
  margin: auto;
}

.inner {
  max-width: 85%;
  margin: auto;
}

.background {
  background: #f5f9fb;
  width: 100%;
  position: absolute;
  top: 50px;
  height: 410px;
}

.message-container {
  padding: 2rem 0 0.5rem 0;
  position: relative;
  background: transparent;
  box-sizing: border-box;
}

.main-container {
  background: transparent;
  position: relative;
}

.section-title {
  width: 100%;
  border-bottom: 1px solid #b5b5b5;
  line-height: 0.1em;
  margin: 1em 0 1.5em;
}

.section-title b {
  background: #f5f9fb;
  padding: 0 0.8em 0 0;
}

.field {
  padding: 1em 0;
  border: 2px solid transparent;
}

.field:not(.disabled):hover {
  border-color: $color-secondary;
  cursor: pointer;
}

.field.active {
  border-color: $color-secondary;
}

.navbar {
  align-items: stretch;
  display: flex;
  position: relative;
  z-index: 30;
  min-height: 3.25rem;
  margin: auto;
  margin-bottom: -1em;
}

.navbar-brand {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
}

.navbar-item {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.navbar-item img {
  height: 39px !important;
  max-height: none;
  max-width: 100%;
}

.banner {
  height: 250px;
  max-width: 1070px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.banner img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  top: 0;
  left: 0;
}

.cols {
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
  box-sizing: border-box;
}

.cols .col.is-two-thirds {
  width: 66%;
  flex: none;
}

.cols .col.is-one-third {
  width: 33%;
  flex: none;
}

.cols .col.is-half {
  flex: none;
  width: 50%;
}

.cols .col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  box-sizing: border-box;
}

.text_right {
  text-align: right;
}

.content_placeholder {
  padding: 1em 0 2em 0;
  font-style: italic;
}

.document_placeholder {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document_placeholder .material-symbols-outlined {
  opacity: 0.3;
  font-size: 110px;
}

.footer {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 1rem 0;
}

.footer ul.links {
  list-style: none;
  padding: 0.75rem;
  margin-left: 0;
}

.footer ul.links li {
  display: inline;
  margin-right: 1em;
}

.footer ul.links li a {
  color: #485fc7;
  cursor: pointer;
  text-decoration: none;
}
</style>
