<template>
  <Tooltip :class="{'disabled': disabled}">
    <template v-slot:default>
      <span
        :class="{'material-symbols-outlined': true, 'disabled': disabled }"
        @click="emits('submit')"
      >
        {{ icon }}
      </span>
    </template>
    <template v-slot:tooltip>
      <slot></slot>
    </template>
  </Tooltip>
</template>

<script lang="ts" setup>
import { Tooltip } from "@/components";

const props = defineProps({
  icon: {
    required: true,
    type: String
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["submit"])
</script>

<style lang="scss" scoped>
$border-light: #ebebeb;

.material-symbols-outlined {
  font-size: 1.1rem;
  border: 1px solid $border-light;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
  margin-left: 0.5em;
}

.material-symbols-outlined:hover {
  cursor: pointer;
  background: #f4f4f4;
}

.material-symbols-outlined.disabled {
  pointer-events: none;
}

.disabled:hover {
  cursor: not-allowed;
}
</style>