<script lang="ts" setup>
import { PropType, computed } from "vue";
import { type DocumentSpaceLink } from "@/graphql/operations";

const props = defineProps({
  link: {
    type: Object as PropType<DocumentSpaceLink>,
    required: true,
  },
  currentLanguage: {
    type: String,
    required: true
  }
});

const translation = computed(() =>
    props.link.documentSpaceLinkTranslationsByLinkId.nodes.find(t => t!.languageCode === props.currentLanguage));
</script>

<template>
  <div class="link_card">
    <div class="card_header">
      <div class="icon">
        <span class="material-symbols-outlined">link</span>
      </div>
    </div>
    <div class="card_content">
      <div>{{ translation ? translation.sectionTitle : link.sectionTitle }}</div>
      <a>
        {{ translation ? translation.title : link.title }}
        <span class="material-symbols-outlined">east</span>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.link_card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  line-height: 1.5;
  margin-bottom: 0.3em;
}

.card_header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 3.2em;
  background-color: #f0f0f0;
  align-items: stretch;
  box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, 0.1);
  display: flex;
}

.icon {
  margin-left: 1.3em;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  line-height: 3em;
  padding: 0;
  text-align: center;
  position: relative;
  bottom: -1.2em;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
}

.material-symbols-outlined {
  line-height: inherit;
  font-size: 1.7em;
}

.card_content {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: transparent;
  padding: 1.5rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.link_card a span {
  float: right;
  font-size: 24px;
  line-height: 1;
}
</style>
