
export class UserAgent {

  static get() {
    return window.navigator.userAgent;
  }

  static isIE(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf("msie") > 0 || ua.indexOf("trident") > 0
  }

  static isFirefox(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf("firefox") > 0;
  }

  static isChrome(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0;
  }

  static isSafari(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf("safari") > 0;
  }

  static isEdge(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf("edg") > 0;
  }

  static isOpera(): boolean {
    return window.navigator.userAgent.toLowerCase().indexOf("opr") > 0;
  }

  static getBrowser(): string {
    if (UserAgent.isIE()) {
      return "ie";
    }

    if (UserAgent.isFirefox()) {
      return "firefox";
    }

    if (UserAgent.isChrome()) {
      return "chrome";
    }

    if (UserAgent.isSafari()) {
      return "safari";
    }

    if (UserAgent.isEdge()) {
      return "edge";
    }

    if (UserAgent.isOpera()) {
      return "opera";
    }

    return "unknown";
  }

  static isAndroid() {
    return window.navigator.userAgent.toLowerCase().indexOf("android") > 0;
  }

  static isIPhone() {
    return window.navigator.userAgent.toLowerCase().indexOf("iphone") > 0;
  }

  static isIPad() {
    return window.navigator.userAgent.toLowerCase().indexOf("ipad") > 0;
  }

  static isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  }

  static getDeviceType() {
    if (UserAgent.isAndroid()) {
      return "android";
    }

    if (UserAgent.isIPhone()) {
      return "Iphone";
    }

    if (UserAgent.isIPad()) {
      return "Ipad";
    }

    if (UserAgent.isMobile()) {
      return "mobile";
    }

    return "desktop";
  }

}
