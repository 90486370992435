import { createApp, watch } from 'vue';
import App from "@/App.vue";
import router from "@/router";
import { store } from "@/store";
import { createPinia } from 'pinia'
import graphqlClient from '@/graphql/client';
import urql from '@urql/vue';
import { auth } from "@/fire";
import { onAuthStateChanged } from 'firebase/auth';
import { AnalyticsService, EventType, Events } from "@/util";
import { i18n } from "@/translations";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import clickOutside from "@/directives/clickOutside";
import "@/fonts.css";
import "@/main.scss";
import { useIdle } from '@vueuse/core'
import { notifyWhenUserReady } from "./init";

const vueApp = createApp(App);
const pinia = createPinia();

vueApp.use(store);
vueApp.use(pinia);
vueApp.use(urql, graphqlClient);
vueApp.use(clickOutside);
vueApp.use(i18n);

try {
  Sentry.init({
    app: vueApp,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    tunnel: "/backend/bugs",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "app.pitchview.io", /^\//]
      })
    ],
    tracesSampleRate: 1.0
  });
} catch (e) {
  console.error(e);
}

vueApp.use(router);
vueApp.mount('#app');

let intervalId: number | null = null;

const startInterval = () => {
  clearIntervalIfExists();
  intervalId = window.setInterval(() => {
    AnalyticsService.sendEvent(Events.Alive);
  }, 8000);
};

const clearIntervalIfExists = () => {
  if (intervalId) {
    window.clearInterval(intervalId);
    intervalId = null;
  }
};

const { idle, lastActive } = useIdle(3 * 60 * 1000) // 3 min
watch(idle, () => {
  if (idle.value) {
    AnalyticsService.sendEvent(Events.UserIsIdle);
    clearIntervalIfExists();
  } else {
    AnalyticsService.sendEvent(Events.UserIsActive);
    startInterval();
  }
});

onAuthStateChanged(auth, async user => {
  if (user) {
    store.commit("setUserId", user.uid);
    AnalyticsService.logEvent(EventType.AppOpen);
  } else {
    store.commit("setUserId", null);
  }
});

const visibilityChangeHandler = () => {
  if (document.visibilityState === "hidden") {
    AnalyticsService.sendEvent(Events.PauseApp);
    clearIntervalIfExists();
  } else {
    AnalyticsService.sendEvent(Events.ResumeApp);
    startInterval();
  }
};

const beforeUnloadHandler = () => {
  AnalyticsService.sendEvent(Events.CloseApp);
  document.removeEventListener("visibilitychange", visibilityChangeHandler);
  window.removeEventListener("beforeunload", beforeUnloadHandler);
  clearIntervalIfExists();
};

document.addEventListener("visibilitychange", visibilityChangeHandler);
window.addEventListener("beforeunload", beforeUnloadHandler);

notifyWhenUserReady().then(() => {
  AnalyticsService.sendEvent(Events.StartApp);
  startInterval();
})