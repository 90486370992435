export class User {
  id: string | null;
  postgresId: number | null;
  email: string | null;
  name: string | null;
  profileImgUrl: string | null;
  defaultWorkspaceId: string | null;
  mergedFlags: Array<string>;
  mergedConfig: any;
  tenantId: string | null;

  constructor() {
    this.id = null;
    this.postgresId = null;
    this.name = null;
    this.email = null;
    this.profileImgUrl = null;
    this.defaultWorkspaceId = null;
    this.mergedFlags = [];
    this.mergedConfig = {};
    this.tenantId = null;
  }

  setId(id: string | null): void {
    this.id = id;
  }

  setPostgresId(id: number): void {
    this.postgresId = id;
  }

  setEmail(email: string | null): void {
    this.email = email;
  }

  setName(name: string | null): void {
    this.name = name;
  }

  setProfileImgUrl(url: string | null): void {
    this.profileImgUrl = url;
  }

  setDefaultWorkspaceId(id: string | null): void {
    this.defaultWorkspaceId = id;
  }

  setMergedFlags(mergedFlags: Array<string>): void {
    this.mergedFlags = mergedFlags;
  }

  setMergedConfig(mergedConfig: any): void {
    this.mergedConfig = mergedConfig;
  }

  setTenantId(tenantId: string): void {
    this.tenantId = tenantId;
  }

  hasDocumentSpaces(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("shared_spaces");
  }

  hasDocumentSpaceLinks(): boolean {
    return (
      this.mergedFlags &&
      this.mergedFlags.includes("shared_spaces_custom_links")
    );
  }

  hasAnalytics(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("analytics");
  }

  hasDocumentAnalytics(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("document_analytics")
  }

  hasUsageAnalytics(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("usage_analytics");
  }

  hasAllTimeMembershipUsageAnalytics(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("usage_analytics_all_time_membership");
  }

  hasCRMIntegration(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("crm_integration");
  }

  canCreateWorkspace(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("can_create_workspace");
  }

  canDisableSpaceTracking(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("can_disable_space_tracking");
  }

  hideNewUsersColumnInUsageAnalytics(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("hide_new_users_columns_in_usage_analytics");
  }

  hasCustomFlows(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("custom_flows");
  }

  canSendSpaceMail(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("space_mail");
  }

  hasSignConsent(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("sign_consent");
  }

  hasSpaceCollab(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("space_collab");
  }

  hasDocumentTracking(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("document_tracking");
  }

  hasBetaAccess(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("beta_access");
  }

  hasPromomatsImport(): boolean {
    return this.mergedFlags && this.mergedFlags.includes("promomats_import");
  }

}
