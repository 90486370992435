<template>
  <div class="sidebar">
    <div class="sidebar_top">
      <div class="logo_container">
        <Brand />
      </div>
    </div>

    <div class="sidebar_middle">
      <template v-if="workspaces && workspaces.length > 0">
        <div class="menu_item">
          <h3
            :class="{
              title: true,
              active: isHighlightedWorkspace(
                store.getters.currentWorkspace,
                true
              ),
            }"
          >
            <div class="hover_container">
              <span class="material-symbols-outlined hide_hover left">
                folder
              </span>

              <span
                v-if="!workspacesExpanded"
                class="material-syombols-outlined display_hover left"
                @click.stop="toggleWorkspaces"
              >
                chevron_right
              </span>
              <span
                v-else
                class="material-symbols-outlined display_hover left"
                @click.stop="toggleWorkspaces"
              >
                keyboard_arrow_down
              </span>

              <router-link to="/u/workspaces">
                {{ $t("sidebar.workspaces.title") }}
              </router-link>
            </div>

            <tooltip v-if="canCreateWorkspace" class="right bottom">
              <template v-slot>
                <span
                  class="material-symbols-outlined"
                  @click.stop="displayCreateWorkspaceModal = true"
                  data-test="create-workspace-ctrl"
                >
                  add
                </span>
              </template>
              <template #tooltip>{{ $t("sidebar.createWorkspace") }}</template>
            </tooltip>
          </h3>

          <div class="items" v-if="workspacesExpanded">
            <router-link
              :class="{
                item: true,
                active: isHighlightedWorkspace(workspace.id),
              }"
              v-for="workspace in workspaces"
              :key="workspace.id"
              :to="{ name: 'Workspace', params: { workspaceId: workspace.id } }"
              :data-test="`workspace-item-${workspace.name}`"
            >
              {{ workspace.name }}
            </router-link>
          </div>
        </div>
      </template>

      <div class="menu_item">
        <h3
          :class="{
            title: true,
            active: spacesIsActive,
          }"
        >
          <span class="material-symbols-outlined left"> attach_file </span>

          <router-link to="/u/shared_spaces">
            {{ $t("sidebar.documentSpaces.title") }}
          </router-link>

          <tooltip class="right">
            <template v-slot>
              <span
                class="material-symbols-outlined"
                @click.stop="handleModalDisplay('create')"
              >
                add
              </span>
            </template>
            <template #tooltip>{{ $t("sidebar.createSharedSpace") }}</template>
          </tooltip>
        </h3>
      </div>

      <template v-if="hasCustomFlows">
        <div class="menu_item">
          <h3
            :class="{
              title: true,
              active: flowIsActive,
            }"
          >
            <span class="material-symbols-outlined left"> layers </span>
            <router-link :to="{ name: 'CustomFlows' }">
              {{ $t("sidebar.customFlows.title") }}
            </router-link>
          </h3>
        </div>
      </template>

      <template v-if="hasAnalytics">
        <div
          class="menu_item"
          v-if="!hasDocumentAnalytics && !hasUsageAnalytics"
        >
          <h3
            :class="{
              title: true,
              active:
                route.name === 'Dashboard' ||
                route.name === 'DocumentSpaceAnalytics',
            }"
          >
            <span class="material-symbols-outlined left"> insert_chart </span>
            <router-link :to="{ name: 'Dashboard' }">
              {{ $t("sidebar.analytics.title") }}
            </router-link>
          </h3>
        </div>

        <div v-else class="menu_item">
          <h3
            :class="{
              title: true,
              active: route.name === 'AnalyticsOverview',
              hover_container: true,
            }"
            @click="analyticsExpanded = true"
          >
            <span
              v-if="!analyticsExpanded"
              class="material-symbols-outlined display_hover left"
              @click.stop="toggleAnalytics"
            >
              chevron_right
            </span>
            <span
              v-else
              class="material-symbols-outlined display_hover left"
              @click.stop="toggleAnalytics"
            >
              keyboard_arrow_down
            </span>

            <span class="material-symbols-outlined left hide_hover">
              insert_chart
            </span>
            <router-link :to="{ name: 'AnalyticsOverview' }">
              {{ $t("sidebar.analytics.title") }}
            </router-link>
          </h3>

          <div class="items" v-if="analyticsExpanded">
            <router-link
              :class="{
                item: true,
                active:
                  route.name === 'Dashboard' ||
                  route.name === 'DocumentSpaceAnalytics',
              }"
              :to="{ name: 'Dashboard' }"
            >
              {{ $t("sidebar.spaceAnalytics.title") }}
            </router-link>
            <router-link
              v-if="hasDocumentAnalytics"
              :class="{
                item: true,
                active: route.name === 'DocumentAnalytics',
              }"
              :to="{ name: 'DocumentAnalytics' }"
            >
              {{ $t("sidebar.documentAnalytics.title") }}
            </router-link>
            <router-link
              v-if="hasUsageAnalytics"
              :class="{ item: true, active: route.name === 'UsageAnalytics' }"
              :to="{ name: 'UsageAnalytics' }"
            >
              {{ $t("sidebar.usageAnalytics.title") }}
            </router-link>
          </div>
        </div>
      </template>

      <template v-if="hasSignConsent">
        <div class="menu_item">
          <h3
            :class="{
              title: true,
              active: crmIsActive,
            }"
          >
            <span class="material-symbols-outlined left">
              import_contacts
            </span>
            <router-link :to="{ name: 'CRMContacts' }">
              {{ $t("sidebar.crmContacts.title") }}
            </router-link>
          </h3>
        </div>
      </template>
    </div>

    <div class="sidebar_bottom">
      <div class="menu_item">
        <h3 :class="{ title: true, active: settingsIsActive }">
          <span class="material-symbols-outlined left"> settings </span>
          <router-link to="/u/settings">
            {{ $t("sidebar.settings.title") }}
          </router-link>
        </h3>
      </div>

      <div class="menu_item">
        <h3 class="title">
          <dropdown class="top" :items="userItems" @change="handleAction">
            <template v-slot>
              <div class="avatar_container">
                <user-avatar
                  class="small"
                  :email="store.getters.user.email"
                  :name="store.getters.user.name"
                  :profileImgUrl="
                    getStorageProxyUrl(store.getters.user.profileImgUrl)
                  "
                />
                <span>{{ userName }}</span>
              </div>
            </template>
          </dropdown>
        </h3>
      </div>
    </div>
  </div>

  <create-workspace-modal
    v-if="displayCreateWorkspaceModal"
    @close="displayCreateWorkspaceModal = false"
    @success="redirectToWorkspace"
  />

  <create-document-space-modal
    v-if="displayCreateDocumentSpaceModal"
    @close="displayCreateDocumentSpaceModal = false"
  />

  <upgrade-modal
    v-if="displayUpgradeModal"
    @close="displayUpgradeModal = false"
  />
</template>

<script lang="ts" setup>
import {
  Dropdown,
  CreateWorkspaceModal,
  Tooltip,
  Brand,
  UserAvatar,
  CreateDocumentSpaceModal,
  UpgradeModal,
} from "@/components";
import { Workspace } from "@/graphql/operations";
import { computed, ComputedRef, ref } from "vue";
import { auth } from "@/fire";
import { signOut } from "firebase/auth";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { dropUserIdFromSession } from "@/init";
import { useUser } from "@/util/useUser";
import { useSessionStorage } from "@vueuse/core";
import { useSpaceModalDisplay } from "@/composables";
import { getStorageProxyUrl } from "@/util";

const {
  displayCreateDocumentSpaceModal,
  displayUpgradeModal,
  handleModalDisplay,
} = useSpaceModalDisplay();

const user = useUser();
const userShouldSignInWithSso = computed(
  () =>
    user.value?.tenant?.hasExternalGroups &&
    user.value.externalGroupsSyncedAt === null
);
const skipSsoForThisSession = useSessionStorage(
  "skipSsoForThisSession",
  "false",
  { listenToStorageChanges: true }
);

const store = useStore();
const router = useRouter();
const route = useRoute();

const workspaces: ComputedRef<Array<Workspace>> = computed(
  () => store.getters.rootWorkspaces
);

const isHighlightedWorkspace = (
  workspaceId: string | null,
  header: boolean = false
) => {
  if (header) {
    return (
      (workspacesIsActive.value && !store.getters.currentWorkspace) ||
      (store.getters.currentWorkspace && !workspacesExpanded.value)
    );
  }

  if (!store.getters.currentWorkspace) {
    return false;
  }

  return (
    workspaceId === store.getters.currentWorkspace.id ||
    workspaceId === store.getters.currentWorkspace?.root.id
  );
};

const workspacesExpanded = ref(
  localStorage.getItem("workspacesExpanded") === "false" ? false : true
);
const toggleWorkspaces = () => {
  workspacesExpanded.value = !workspacesExpanded.value;
  localStorage.setItem(
    "workspacesExpanded",
    workspacesExpanded.value.toString()
  );
};

const userName = computed(
  () => store.getters.user.name || store.getters.user.email
);
const userItems = [
  {
    value: "Profile",
    title: "Profile",
  },
  {
    value: "logout",
    title: "Logout",
  },
];

const workspacesIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) =>
    /^\/u\/workspaces/i.test(path)
  )
);

const settingsIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) =>
    /^\/u\/settings/i.test(path)
  )
);

const spacesIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) =>
    /^\/u\/shared_spaces/i.test(path)
  )
);

const analyticsIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) =>
    /^\/u\/analytics/i.test(path)
  )
);

const flowIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) =>
    /^\/u\/custom_flows/i.test(path)
  )
);

const crmIsActive = computed(() =>
  route.matched.some(({ path }: { path: string }) => /^\/u\/crm/i.test(path))
);

const hasCustomFlows = computed(() => store.getters.user.hasCustomFlows());

const canCreateWorkspace = computed(() =>
  store.getters.user.canCreateWorkspace()
);

const hasAnalytics = computed(() => store.getters.user.hasAnalytics());
const hasDocumentAnalytics = computed(() =>
  store.getters.user.hasDocumentAnalytics()
);
const hasUsageAnalytics = computed(() =>
  store.getters.user.hasUsageAnalytics()
);

const analyticsExpanded = ref(false);
const toggleAnalytics = () => {
  analyticsExpanded.value = !analyticsExpanded.value;
};

const hasSignConsent = computed(() => store.getters.user.hasSignConsent());

async function logout() {
  try {
    await Promise.all([signOut(auth), dropUserIdFromSession()]);
  } catch (e) {
    throw e;
  } finally {
    const path = router.resolve("/login");
    window.location.replace(path.href);
  }
}

async function handleAction(action: string) {
  switch (action) {
    case "logout":
      await logout();
      break;

    default:
      router.push({ name: action });
      break;
  }
}

const redirectToWorkspace = (id: number) => {
  router.push({
    name: "Workspace",
    params: { workspaceId: id },
  });
};

const displayCreateWorkspaceModal = ref<boolean>(false);
</script>

<style lang="scss" scoped>
.sidebar {
  width: $sidebar-width;
  flex-shrink: 0;
  height: 100vh;
  background: $background-grey;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sidebar_middle {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar_bottom {
  margin-top: auto;
  padding-bottom: 10px;
}

.logo_container {
  padding: 1em 1.2em;
  margin-bottom: 1em;
}

.sidebar .title {
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  line-height: 3.1em;
  padding: 0 1.2em;
  align-items: center;
  margin: 0.1em 0;

  a {
    text-decoration: none;
    color: #363636;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.hover_container {
  display: flex;
  align-items: center;
}

.hover_container .display_hover {
  display: none;
}

.hover_container:hover .hide_hover {
  display: none;
}

.hover_container:hover .display_hover {
  display: inline-block;
}

section .menu_item:first-child {
  padding-top: 0;
}

.items {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.1em 0;
}

.item,
a.item {
  display: block;
  line-height: 2.3em;
  text-decoration: none;
  color: #363636;
  padding-left: 45px;
  padding-right: 5px;
}

a.item.underline {
  text-decoration: underline;
}

.sidebar .title:hover,
.item:hover {
  cursor: pointer;
  background-color: #ececec;
}

.sidebar .title.active,
.item.active {
  background-color: #ececec;
}

.link {
  font-size: 0.9em;
  color: initial;
}

.material-symbols-outlined {
  font-size: 1.4em;
}

.material-symbols-outlined.left {
  margin-right: 0.3em;
}

.tooltip_container.right {
  margin-left: auto;
}

.tooltip_container .material-symbols-outlined {
  font-size: 1.3em;
}

.tooltip_container .material-symbols-outlined:hover {
  cursor: pointer;
}

.avatar {
  margin-right: 0.3em;
}

.avatar_container {
  display: flex;
  align-items: center;
}

section.user_info {
  font-size: 0.9em;
  line-height: 2.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_name {
  width: calc(100% - 31px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_info .material-symbols-outlined {
  font-size: 1.3em;
  color: $color-secondary;
  border: 1px solid $color-secondary;
  border-radius: 50%;
  padding: 0.2em;
}

.icon.right {
  margin-left: auto;
}
</style>
