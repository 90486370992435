<script lang="ts" setup>
import { PropType, ref, computed } from "vue";
import { useUpdateDocumentSpaceConfigNameMutation } from "@/graphql/operations";
import { Dropdown, Breadcrumbs } from "@/components";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import SettingsModal from "./SettingsModal.vue";
import AddContent from "./AddContent.vue";
import CopyModal from "./CopyModal.vue";
import { type Config } from "./Config";
import { useFlashMessage } from "@/hooks";

const { t } = useI18n();
const router = useRouter();
const { displayFlashMessage } = useFlashMessage();

const props = defineProps({
  currentConfig: {
    type: Object as PropType<Config>,
    required: true,
  },
  currentLanguage: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["newContent", "switchLanguage", "reloadData"]);

const actionItems = [
  {
    value: "copy",
    title: t("templateEditor.copy.buttonText"),
  },
];

const handleAction = (action: string) => {
  displayCopyModal.value = true;
};

const displaySettingsModal = ref(false);
const displayCopyModal = ref(false);

const navItems = computed(() => [
  {
    text: "Shared Space Templates",
    href: router.resolve({ name: "SettingsOverview" }).fullPath,
  },
  {
    text: props.name,
    isEditable: true,
  },
]);

const emitNewContent = (contentType: string) => {
  emits("newContent", contentType);
};

const languages = computed(() => {
  return props.currentConfig.languages.map((l) => ({
    title:
      props.currentConfig.defaultLanguage === l
        ? t(`templateEditor.languages.${l}.defaultLabel`)
        : t(`templateEditor.languages.${l}.label`),
    value: props.currentConfig.defaultLanguage === l ? "default" : l,
  }));
});

const switchLanguage = (value: string) => {
  emits("switchLanguage", value);
};

const { executeMutation } = useUpdateDocumentSpaceConfigNameMutation();
const validateName = async (value: string) => {
  if (value.length === 0) {
    return; //FIXME display user error
  }

  const res = await executeMutation({
    id: props.currentConfig.id,
    name: value,
  });

  if (!res.error) {
    displayFlashMessage("Success", "success");
  }

  emits("reloadData");
};
</script>

<template>
  <div class="editor_navbar">
    <breadcrumbs :items="navItems" @update="validateName" />

    <div class="right controls">
      <Dropdown
        v-if="languages.length > 1"
        :items="languages"
        @change="switchLanguage"
        :value="currentLanguage"
        :displayTitle="true"
      />

      <add-content
        :config="currentConfig"
        :currentLanguage="currentLanguage"
        @submit="emitNewContent"
      />

      <div
        style="display: none"
        class="control"
        @click="displaySettingsModal = true"
      >
        <span class="material-symbols-outlined">settings</span>
      </div>
      <div class="control">
        <Dropdown
          :items="actionItems"
          @change="handleAction"
          class="right_align"
        >
          <div>
            <span class="material-symbols-outlined">more_vert</span>
          </div>
        </Dropdown>
      </div>
    </div>
  </div>

  <settings-modal
    v-if="displaySettingsModal"
    :config="currentConfig"
    @close="displaySettingsModal = false"
  />

  <copy-modal
    v-if="displayCopyModal"
    :config="currentConfig"
    @close="displayCopyModal = false"
  />
</template>

<style scoped lang="scss">
.editor_navbar {
  height: 55px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 1.5em;
  display: flex;
  align-items: center;
  background: #fff;
}

.editor_navbar .breadcrumbs {
  flex: 1;
}

.controls {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.control .material-symbols-outlined {
  font-size: 1.3em;
  cursor: pointer;
}

.controls .dropdown {
  z-index: 100;
  margin-left: 0.8em;
  margin-right: 0.5em;
}

.controls .dropdown :deep(.dropdown_title) {
  border-radius: 8px;
}

.breadcrumbs :deep(.item.editable) {
  width: 50%;
}
</style>
