<template>
  <div class="external_group_import message">
    <p class="info">
      {{ $t("externalGroupImport.description") }}
    </p>

    <button class="button primary">
      <a href="/sso/login">
        {{ $t("externalGroupImport.import.buttonText") }}
      </a>
    </button>

    <span v-if="displaySuccess" class="success">
      {{ $t("externalGroupImport.success") }}
    </span>

    <div v-if="managedMemberships.length > 0">
      <p class="info bottom">
        {{ $t("externalGroupImport.memberships.info") }}
      </p>

      <table class="data_table">
        <tbody>
          <tr v-for="membership in managedMemberships" :key="membership!.id">
            <td>{{ membership!.team!.name }}</td>
            <td>{{ formatDate(membership!.updatedAt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      <p class="info bottom">
        {{ $t("externalGroupImport.noMemberships.info") }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useMyManagedMembershipsQuery } from "@/graphql/operations";
import { Formatter } from "@/util";
import { useRoute } from "vue-router";

const route = useRoute();

const { data: membershipsResult, fetching: loading } =
  useMyManagedMembershipsQuery();
const managedMemberships = computed(
  () => membershipsResult?.value?.memberships?.nodes || []
);

const formatDate = (date: string) => {
  return Formatter.formatDate(new Date(date), "%Y-%m-%d %H:%M");
};

const displaySuccess = computed(() => route.query.import_success === "true");
</script>

<style scoped lang="scss">
.message {
  max-width: 600px;
  font-size: 0.9em;
}

.message .button {
  font-size: 1em;
}

button a {
  color: #fff;
  text-decoration: none;
}

.success {
}

info.bottom {
  margin-top: 10px;
}
</style>
