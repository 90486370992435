<script lang="ts" setup>
import { Modal, UserAvatar, Dropdown } from "@/components";
import {
  DocumentSpace,
  User,
  useAddCollaboratorMutation,
} from "@/graphql/operations";
import { PropType, ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import AccessDropdown from "./AccessDropdown.vue";
import AccessUserSearch from "./AccessUserSearch.vue";

const { t } = useI18n();

const store = useStore();

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
  visibleUserCount: {
    type: Number,
    required: true,
  },
  visibleUserLimit: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["close", "refreshData", "changeState"]);
const emitClose = () => emits("close");

const searchActive = ref<boolean>(true);
const handleSearchActive = (isActive: boolean) => {
  searchActive.value = isActive;
};

const selectedUser = ref<User | null>(null);
const setSelectedUser = (user: User | null) => {
  selectedUser.value = user;
};

const defaultVisibility = "hidden";

const items = computed(() => {
  const i = [];
  i.push({
    value: "hidden",
    title: t("documentSpace.access.hideDetails.title"),
    info: "Contact details won't be displayed in the shared space",
    displayCurrentSelection: true,
  });

  if (props.visibleUserCount < props.visibleUserLimit) {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.info"),
      displayCurrentSelection: true,
    });
  } else {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.disabled.info"),
      disabled: true,
      displayCurrentSelection: true,
    });
  }

  return i;
});

const visibility = ref<string>(defaultVisibility);
const setVisibility = (value: string) => {
  visibility.value = value;
};

const { executeMutation } = useAddCollaboratorMutation();
const addCollaborator = async () => {
  if (!selectedUser.value) {
    //FIXME handle error
    return;
  }

  const result = await executeMutation({
    spaceId: props.documentSpace.id,
    userId: selectedUser.value.id,
    displayContactDetails: visibility.value === "visible",
  });

  if (result.error) {
    console.error(result.error);
    //TODO handle error;
  }

  emits("refreshData");
  emits("changeState");
  //display flash message?
  //TODO handle error & success, display flash message
};
</script>

<template>
  <modal :active="true" @close="emitClose" :scrolling="true" class="medium">
    <template #header>
      <span
        class="material-symbols-outlined left clickable"
        @click="emits('changeState')"
      >
        keyboard_backspace
      </span>
      {{ $t("documentSpace.access.invite.title") }}
    </template>

    <template #body>
      <access-user-search
        :documentSpace="documentSpace"
        @refreshData="emits('refreshData')"
        :active="searchActive"
        @stateChange="handleSearchActive"
        @change="setSelectedUser"
      >
        <template #settings v-if="selectedUser">
          <access-dropdown
            class="settings right"
            :items="items"
            :value="visibility"
            @change="setVisibility"
          />
        </template>
      </access-user-search>
    </template>

    <template #footer>
      <div class="buttons">
        <button
          :class="{ primary: true, button: true, disabled: !selectedUser }"
          @click="addCollaborator()"
        >
          {{ $t("documentSpace.access.invite.buttonText") }}
        </button>
      </div>
    </template>
  </modal>
</template>

<style scoped lang="scss">
.dropdown.settings {
  margin-left: 0.3em;
}

.material-symbols-outlined.left {
  margin-right: 0.3em;
}

.material-symbols-outlined.clickable:hover {
  cursor: pointer;
}
</style>
