<template>
  <div class="modal" @click="close" :class="css">
    <div class="window" @click.stop="click">
      <div class="header">
        <slot name="header"></slot>
        <div class="icon" @click="close">
          <span class="material-symbols-outlined"> close </span>
        </div>
      </div>

      <div class="body">
        <slot name="body"></slot>
      </div>

      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ref,
  reactive,
  computed,
  onUnmounted,
  Ref,
  defineComponent,
  watch,
} from "vue";

interface Props {
  active: boolean;
  scrolling?: boolean;
  closeDisabled?: boolean;
}

interface CSSProps {
  active: boolean;
  scrolling: boolean;
  disable_close: boolean;
}

function resetOverflow() {
  document.documentElement.style.overflow = "auto";
}

function setOverflow() {
  document.documentElement.style.overflow = "hidden";
}

export default defineComponent({
  props: {
    active: { required: true, type: Boolean },
    scrolling: { required: false, type: Boolean },
    closeDisabled: { required: false, type: Boolean }
  },
  setup(props: Props, context) {
    if (props.active) {
      setOverflow();
    }

    onUnmounted(() => {
      resetOverflow();
    });

    watch(
      () => props.active,
      (active, prevActive) => {
        if (active) {
          setOverflow();
        } else {
          resetOverflow();
        }
      }
    );

    const css = computed(() => {
      const cssClass: CSSProps = {
        active: props.active,
        scrolling: props.scrolling ? true : false,
        disable_close: props.closeDisabled ? true : false
      };

      return cssClass;
    });

    function close(event: MouseEvent) {
      event.stopPropagation();

      if (props.closeDisabled) {
        return;
      }

      context.emit("close");
    }

    function click() {
      document.body.dispatchEvent(new Event("close-ctrls"));
    }

    return {
      close,
      click,
      css,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  z-index: 999;
}

.modal.active {
  display: block;
}

.window {
  position: relative;
  left: 50%;
  top: 50%;
  width: 470px;
  background: #fff;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transform: translate(-50%, -50%);
  max-height: calc(80vh);
}

.modal.transparent {
  background-color: transparent;
}

.modal.hide_close .icon {
  display: none;
}

.modal.disable_close .icon {
  opacity: 0.2;
  cursor: initial;
  pointer-events: none;
}

.modal.medium .window {
  width: 550px;
}

.modal.medium_large .window {
  width: 750px;
}

.modal.large .window {
  width: 70%;
  max-width: 950px;
  height: calc(80vh);
}

.modal.huge .window {
  width: 90%;
  max-width: 1200px;
  height: calc(80vh);
}

.modal.content_scroll .window {
  height: 80vh;
}

.header {
  font-weight: 600;
  font-size: 16px;
  padding: .8em 22px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modal.no_header .header {
  display: none;
}

.header .icon {
  margin-left: auto;
  cursor: pointer;
}

.header .icon.left {
  float: left;
  margin-right: 5px;
}

.body {
  display: block;
  box-sizing: border-box;
  padding: 15px 22px;
  width: 100%;
  margin: 0;
  height: calc(100% - 100px);
  overflow: hidden;
}

.modal.scrolling .body {
  height: calc(80vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  padding: 0 22px;
  line-height: 45px;
  height: 50px;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  background: #f9fafb;
  display: flex;
  align-items: center;
}

.modal.without_footer .footer {
  display: none;
}

.modal.without_footer .body {
  height: calc(100% - 50px);
}
</style>

<style>
.modal .footer .buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 100%;
}

.modal .footer .buttons .right {
  margin-left: auto;
}

.modal .footer .button {
  margin-left: auto;
}

.modal .footer .buttons .right + .button,
.modal .footer .buttons .button + .button {
  margin-left: 1em;
}

.modal .footer .button.left {
  margin-left: 0;
}

.modal .body > div {
  width: 100%;
}

.modal .header .material-symbols-outlined {
  vertical-align: middle;
}
</style>
