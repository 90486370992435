<script lang="ts" setup>
import { PropType } from "vue";
import HTMLToolbar from "./HTMLToolbar.vue";
import { useFlashMessage } from "@/hooks";
import { type Config } from "./Config";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["reloadData", "changeState"]);

const { displayFlashMessage } = useFlashMessage();

const submit = async (text: string | null) => {
  if (text === "<p><br></p>") {
    text = null;
  }

  const res = await props.config.updateLegalNote(text);

  if (res.error) {
    displayFlashMessage(res.error.message, "error");
  } else {
    displayFlashMessage("Success", "success");
    emits("reloadData");
  }
};
</script>

<template>
  <HTMLToolbar
    @submit="submit"
    :content="config.legalNote ?? ''"
    @changeState="(hasChanges: boolean) => emits('changeState', hasChanges)"
  />
</template>
