<script lang="ts" setup>
import { PropType, ref, onMounted, computed } from "vue";
import { Modal, Dropdown } from "@/components";
import { type Config } from "./Config";
import { useI18n } from "vue-i18n";
import {
  useMyAdminTeamsShortQuery,
  useCopyDocumentSpaceConfigMutation,
} from "@/graphql/operations";
import { useFlashMessage } from "@/hooks/useFlashMessage";
import { useRouter } from "vue-router";
import { useForm, useField } from "vee-validate";
import { string } from "yup";

const { t } = useI18n();
const router = useRouter();
const { displayFlashMessage } = useFlashMessage();

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
});

const emits = defineEmits(["close"]);

const { data } = useMyAdminTeamsShortQuery();
const teams = computed(() =>
  data.value?.myAdminTeams?.nodes?.map((t) => ({
    value: t!.id.toString(),
    title: t!.name,
  }))
);

const { validate, errors } = useForm();

const { value: name } = useField<string>("name", string().required(), {
  initialValue: t("templateEditor.copyModal.copyName", {
    name: props.config.name,
  }),
});

const input_name = ref<HTMLInputElement>();
onMounted(() => {
  if (!input_name.value) {
    return;
  }

  input_name.value!.select();
});

const teamId = ref(props.config.teamId.toString());
const setTeamId = (id: string) => {
  teamId.value = id;
};

const { executeMutation } = useCopyDocumentSpaceConfigMutation();
const submit = async () => {
  const validationResult = await validate();
  if (!validationResult.valid) {
    console.log("invalid data");
    return;
  }

  const res = await executeMutation({
    id: props.config.id,
    teamId: teamId.value,
    name: name.value,
    copyTranslations: true,
  });

  const newConfigId = res.data?.copyDocumentSpaceConfig?.newConfig?.id;
  if (newConfigId) {
    const url = router.resolve({
      name: "TemplateEditor",
      params: { id: newConfigId },
    }).href;
    window.open(url, "_blank");
    return;
  }

  displayFlashMessage(t("error"), "error");
};
</script>

<template>
  <Modal :active="true" @close="emits('close')" :scrolling="true">
    <template #header>
      {{ $t("templateEditor.copyModal.title") }}
    </template>

    <template #body>
      <div class="row">
        <input
          type="text"
          class="input"
          v-model="name"
          ref="input_name"
          :class="{ invalid: errors.name !== undefined }"
        />
      </div>

      <div class="row" v-if="teams && teams.length > 1">
        <label>{{ $t("templateEditor.copyModal.teamSelection.label") }}</label>
        <dropdown
          :items="teams"
          :value="teamId"
          :displayTitle="true"
          @change="setTeamId"
        />
      </div>
    </template>

    <template #footer>
      <div class="buttons">
        <button
          class="button primary"
          @click="submit"
          :class="{ disabled: errors.name !== undefined }"
        >
          {{ $t("templateEditor.copyModal.submit") }}
        </button>
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
  font-size: 0.9em;
}

label {
  margin-right: 0.5em;
}
</style>
