import { auth } from "@/fire";
import { onAuthStateChanged, type IdTokenResult, type User as FirebaseUser } from "firebase/auth";
import { watch, ref, readonly, computed } from "vue";
import {
  CreateUserFromFirebaseDocument,
  InitDocument,
} from "@/graphql/operations";
import graphqlClient from "./graphql/client";
import { User } from "@/user";
import { store } from "@/store";
import { v4 as uuid } from 'uuid';

document?.getElementById("loader")?.classList?.add("hidden");

const userRef = ref<FirebaseUser | null>(null);
const idTokenRef = ref<IdTokenResult | null>(null);

const userDataRef = ref<User | null>(null);

const idToken = readonly(idTokenRef);
export const token = computed(() => idToken.value?.token);
export const user = readonly(userRef);
export const userData = readonly(userDataRef);

const runIdVal = ref(sessionStorage.getItem("runId"));

function setNewRunId() {
  runIdVal.value = uuid();
  sessionStorage.setItem("runId", runIdVal.value);
}

if (!runIdVal.value) {
  setNewRunId();
}

export const runId = readonly(runIdVal);

onAuthStateChanged(auth, (user) => {
  userRef.value = user;
  console.log("set user")

  if (user && !user.emailVerified) {
    startEmailVerificationWatcher();
  } else {
    stopEmailVerificationWatcher();
  }
});

let emailVerifiedWatcher: number | null;
function startEmailVerificationWatcher() {
  stopEmailVerificationWatcher();

  emailVerifiedWatcher = window.setInterval(async () => {
    await userRef.value?.reload();

    if (userRef.value?.emailVerified) {
      idTokenRef.value = await userRef.value?.getIdTokenResult(true);
      if (idTokenRef.value?.claims.email_verified) {
        await initUser();
        await initAll();
        stopEmailVerificationWatcher();
      }
    }
  }, 5000)
}

function stopEmailVerificationWatcher() {
  if (emailVerifiedWatcher) {
    clearInterval(emailVerifiedWatcher);
    emailVerifiedWatcher = null;
  }
}

export function getCurrentUser(): Promise<FirebaseUser | null> {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

let userIsSetup = false;

export async function notifyWhenUserReady(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (userIsSetup) {
      resolve();
      return;
    }

    const intervalId = setInterval(() => {
      if (userIsSetup) {
        clearInterval(intervalId);
        resolve();
      }
    });
  });
}

async function initUser() {
  const result = await graphqlClient
    .mutation(CreateUserFromFirebaseDocument, {})
    .toPromise();
  if (result.error) {
    //TODO handle error
    console.error(result.error);
    return;
  }

  const userData = result.data?.createUserFromFirebase?.result?.me;
  if (!userData) {
    //TODO handle error
    return;
  }
}

async function initAll() {
  const result = await graphqlClient
    .query(InitDocument, {}, { requestPolicy: "cache-and-network" })
    .toPromise();

  if (result.error) {
    console.error("Error while fetching root workspaces");
    return;
  }

  store.commit("setWorkspaces", result.data?.myWorkspaces.nodes);
  store.commit("setRootWorkspaces", result.data?.myRootWorkspaces.nodes);
  store.commit("setNumSpaceConfigs", result.data?.documentSpaceConfigs?.totalCount);

  const userData = result.data?.myAccount.nodes[0];
  if (!userData) {
    console.error("could not fetch user");
    return;
  }

  store.commit("setUserData", userData);

  const user = new User();
  user.id = userData.firebaseAuthId;
  user.postgresId = userData.id;
  user.email = userData.email;
  user.name = userData.displayName;
  user.profileImgUrl = userData.userContactDetail?.profileImageUrl;
  user.mergedFlags = userData.mergedFlags;
  user.mergedConfig = userData.mergedConfig;
  user.tenantId = userData.tenantId;
  userDataRef.value = user;

  userIsSetup = true;

  document?.getElementById("loader")?.classList?.add("hidden");
}

export const reloadWorkspaces = initAll;

watch(userRef, async (user) => {
  if (user) {
    idTokenRef.value = await user.getIdTokenResult(true);

    await initUser();
    await initAll();

  } else {
    idTokenRef.value = null;
  }
});

setInterval(async () => {
  // refresh the token reference every 2 minutes
  idTokenRef.value = (await userRef.value?.getIdTokenResult()) ?? null;
}, 2 * 60 * 1000);

setInterval(async () => {
  await initAll();
}, 20 * 1000);

export async function dropUserIdFromSession() {
  setNewRunId();

  const response = await fetch("/backend/user-id-in-session-cookie", {
    method: "DELETE",
  });
  if (response.status === 200) {
    const result = await response.json();
    return result.ok;
  } else {
    throw new Error(await response.text());
  }
}

watch(token, async (currentToken) => {
  // We store the current user to an encrypted session cookie.
  // By doing so, we can authenticate our thumbnail endpoints without explicitely delivering a JWT.
  // In consequence, we can use our thumbnail endpoints directly in <img> tags and profit from the srcset="..." attribute.
  // This is in particular useful for devices with a high pixel density, like Apples' Retina displays.
  const response = await fetch("/backend/user-id-in-session-cookie", {
    method: currentToken ? "PUT" : "DELETE",
    headers: currentToken ? { Authorization: `Bearer ${currentToken}` } : {},
  });
  if (response.status === 200) {
    const result = await response.json();
    if (!result.ok) {
      throw new Error(`unexpected result ${JSON.stringify(result)}`);
    }
  } else {
    throw new Error(await response.text());
  }
});
