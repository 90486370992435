<script lang="ts" setup>
const emits = defineEmits(["close"]);
</script>

<template>
  <div class="bottom_action_bar">
    <slot name="top"></slot>
    <div class="inner">
      <slot name="action"></slot>
      <div class="right">
        <slot name="cancel">
          <button class="button right" @click="emits('close')">
            {{ $t("cancel") }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottom_action_bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 225px;
  box-sizing: border-box;
  background: #363636;
  box-shadow: 0px 0px 8px 0px #00000040;
  color: #fff;
  height: 65px;
  z-index: 100;
  font-size: 0.9rem;
}

.inner {
  display: flex;
  align-items: center;
  padding: 0.8em 2.5em 0.8em 1.5em;
}

.right {
  margin-left: auto;
}

button.button.right {
  color: #fff;
  background: transparent;
  border-color: #fff;
}
</style>
