<template>
  <div :class="{ background: true, active: state.active }">
    <div class="banner">
      <div class="icon" @click="close">
        <span class="material-symbols-outlined"> close </span>
      </div>
      <div class="inner">
        <h2>{{ $t("unsupportedBrowser.header") }}</h2>
        <p>{{ $t("unsupportedBrowser.info") }}</p>

        <ul>
          <li>
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/2/28/Firefox_logo%2C_2017.svg"
              />
              <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
            </div>
          </li>

          <li>
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/87/Google_Chrome_icon_%282011%29.png"
              />
            </div>
            <a href="https://www.google.com/chrome/">Chrome</a>
          </li>

          <li>
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg"
              />
            </div>
            <a href="https://support.apple.com/downloads/safari">Safari</a>
          </li>

          <li>
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/b/b8/Microsoft_Edge_logo_%282015%E2%80%932019%29.svg"
              />
            </div>
            <a href="https://www.microsoft.com/en-us/edge">Edge</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent } from "vue";

interface ComponentState {
  active: boolean;
}

export default defineComponent({
  setup() {
    const state: ComponentState = reactive({
      active: true,
    });

    const close = () => (state.active = false);

    return {
      close,
      state,
    };
  },
});
</script>

<style scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: none;
}

.background.active {
  display: block;
}

.banner {
  position: relative;
  left: 50%;
  top: 50%;
  width: 40%;
  background: #fff;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.inner {
  padding: 5%;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  display: inline-block;
  margin-right: 10px;
}

ul li div {
  text-align: center;
}

ul li img {
  height: 60px;
}

ul li a {
  margin-top: 5px;
  display: block;
  font-size: 13px;
  text-decoration: none;
  color: #36b6b9;
}

.icon {
  float: right;
  cursor: pointer;
  padding: 5px;
}
</style>
