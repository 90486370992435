<template>
  <div :class="css">
    {{ message }}
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from "vue";

interface Props {
  state: string;
  message: string;
}

interface CSSProps {
  success?: boolean;
  error?: boolean;
  flashmessage: boolean;
}

export default defineComponent({
  props: {
    state: { required: true },
    message: { required: false },
  },
  setup(props, context) {
    const css = computed(() => {
      const cssClass: CSSProps = {
        success: props.state === "success",
        error: props.state === "error",
        flashmessage: true,
      };

      return cssClass;
    });

    return {
      css,
    };
  },
});
</script>

<style lang="scss" scoped>
.flashmessage {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1001;
  font-size: 0.9em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  padding: 0 16px;
  line-height: 3em;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.flashmessage.success {
  border: 1px solid #48c78e;
}

.flashmessage.error {
  box-shadow: 0 0 0 1px #e0b4b4;
  background-color: #fff6f6;
  color: #9f3a38;
}
</style>
