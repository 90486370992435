<script lang="ts" setup>
import { PropType, ref, computed, watch } from "vue";
import { type PageConfigFragment } from "@/graphql/operations";
import { Tooltip } from "@/components";

const props = defineProps({
  page: {
    type: Object as PropType<PageConfigFragment>,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits([
  "goToNextPage",
  "goToPreviousPage",
  "goToPreviousStep",
]);
</script>

<template>
  <div class="wizard_header">
    <span
      v-if="currentStep === 2"
      class="material-symbols-outlined clickable"
      @click="emit('goToPreviousStep')"
    >
      arrow_back
    </span>
    <label>
      <template v-if="page.pageNumber === 1">
        {{ $t("magicPageStudio.wizard.header.firstPage") }}
      </template>
      <template v-else-if="currentStep === 1">
        {{
          $t("magicPageStudio.wizard.header.firstStep", {
            pageNumber: page.pageNumber,
          })
        }}
      </template>
      <template v-else>
        {{
          $t("magicPageStudio.wizard.header.secondStep", {
            pageNumber: page.pageNumber,
          })
        }}
      </template>
    </label>
    <div class="icons">
      <tooltip class="bottom">
        <template v-slot>
          <span
            class="material-symbols-outlined"
            :class="{ disabled: page.pageNumber === 1 }"
            @click="emit('goToPreviousPage')"
          >
            arrow_back
          </span>
        </template>
        <template #tooltip>
          <template v-if="page.pageNumber > 1">
            {{ $t("magicPageStudio.wizard.previousPage") }}
          </template>
          <template v-else>
            {{ $t("magicPageStudio.wizard.firstPage") }}
          </template>
        </template>
      </tooltip>
      <tooltip class="bottom">
        <template v-slot>
          <span
            class="material-symbols-outlined"
            :class="{ disabled: page.pageNumber === numPages }"
            @click="emit('goToNextPage')"
          >
            arrow_forward
          </span>
        </template>
        <template #tooltip>
          <template v-if="page.pageNumber < numPages">
            {{ $t("magicPageStudio.wizard.nextPage") }}
          </template>
          <template v-else>
            {{ $t("magicPageStudio.wizard.lastPage") }}
          </template>
        </template>
      </tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wizard_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.icons {
  margin-left: auto;
}

.icons .material-symbols-outlined {
  margin-left: 0.5em;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.icons .material-symbols-outlined.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.material-symbols-outlined + label {
  margin-left: 0.5em;
}
</style>
