<template>
  <Modal :active="active" @close="emits('close')">
    <template #header>
      {{ $t("customFlows.removePageModal.title") }}
    </template>

    <template #body>
      {{ $t("customFlows.removePageModal.message") }}
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="removePage">
          {{ $t("customFlows.removePageModal.submit") }}
        </button>
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from "@/components";
import { useRemoveFlowItemMutation } from "@/graphql/operations";

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  pageId: {
    type: String,
    required: true
  }
});

const emits = defineEmits(["close", "pageRemoved", "error"]);

const { executeMutation: deleteFlowItem } = useRemoveFlowItemMutation();
const removePage = async () => {
  try {
    const res = await deleteFlowItem({
      id: props.pageId
    });
    if (res.error) {
      throw new Error(res.error.message);
    }
    emits("pageRemoved");
  } catch {
    emits("error")
  }
}
</script>

<style lang="scss" scoped>

</style>
