import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';

import firebase from 'firebase/compat/app';

const config = import.meta.env.VITE_FIREBASE_CONFIG;

export const app = initializeApp(JSON.parse(config as string));
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const realtimeDb = getDatabase(app);

export const compatApp = firebase.initializeApp(JSON.parse(config as string));
//db.settings({ experimentalForceLongPolling: true });