<script lang="ts" setup>
import { PropType } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

interface Item {
  title: string;
  routeParams: any
};

const props = defineProps({
  items: {
    type: Array as PropType<Array<Item>>,
    required: true
  }
});
</script>

<template>
  <ul>
    <li
      v-for="(tab, i) in items"
      :key="i"
      :class="{ active: tab.routeParams.name === route.name }"
    >
      <router-link :to="tab.routeParams">
        {{ tab.title }}
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #CECECE;
}

ul li {
  padding: 0;
  display: inline-block;
  margin: 0 1em -1px 0;
}

ul li.active {
  font-weight: 500;
  border-bottom: 1px solid #363636;
}

ul li a {
  padding: .5em 2em;
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

</style>
