<script lang="ts" setup>
import { PropType, ref, computed } from "vue";
import { DocumentSpaceLink } from "@/graphql/operations";
import { Dropdown, Draggable } from "@/components";
import { useDeleteDocumentSpaceLinkMutation } from "@/graphql/operations";
import DocumentSpaceLinkEditor from "./DocumentSpaceLinkEditor.vue";

const props = defineProps({
  links: {
    type: Array as PropType<Array<DocumentSpaceLink>>,
    required: true,
  },
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const contextMenuItems = [
  {
    value: "edit",
    title: "Edit",
  },
  {
    value: "remove",
    title: "Remove Link",
  },
];

const sortedLinks = computed(() => props.links);

const emits = defineEmits(["remove", "success", "updatePosition"]);
const emitSuccess = () => emits("success");

const editorActive = ref<boolean>(false);
const editorLink = ref<DocumentSpaceLink | undefined>(undefined);

const addLink = () => {
  editorLink.value = undefined;
  editorActive.value = true;
};

const { executeMutation: deleteLink } = useDeleteDocumentSpaceLinkMutation();
const remove = async (id: string) => {
  const result = await deleteLink({ id: id });
  //FIXME handle error

  emitSuccess();
};

const handleAction = (id: string, action: string) => {
  if (action === "remove") {
    remove(id);
  } else {
    editorLink.value = props.links.find((l) => l.id === id);
    editorActive.value = true;
  }
};

const updatePosition = (
  targetIndex: number,
  sourceIndex: number,
  id: string
) => {
  let position: number;
  if (targetIndex === 0) {
    position = sortedLinks.value[0].position + 1;
  } else if (targetIndex === sortedLinks.value.length) {
    position = sortedLinks.value[sortedLinks.value.length - 1].position - 1;
  } else if (targetIndex > sourceIndex) {
    position =
      (sortedLinks.value[targetIndex - 1].position +
        sortedLinks.value[targetIndex].position) /
      2;
  } else {
    position =
      (sortedLinks.value[targetIndex].position +
        sortedLinks.value[targetIndex - 1].position) /
      2;
  }

  emits("updatePosition", id, position);
};

const isVisible = ref(true);
const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
};
</script>

<template>
  <div class="link_list">
    <div class="title_container">
      <h4>
        <span class="material-symbols-outlined" @click="toggleVisibility">
          <template v-if="isVisible"> keyboard_arrow_down </template>
          <template v-else> keyboard_arrow_right </template>
        </span>
        {{ $t("documentSpace.links.title") }}
      </h4>
    </div>

    <template v-if="isVisible">
      <div class="row action">
        <span class="material-symbols-outlined" @click="addLink"> add </span>
        <div class="cell" @click="addLink">
          {{ $t("documentSpace.addLink.buttonText") }}
        </div>
      </div>

      <Draggable
        v-if="sortedLinks && sortedLinks.length > 0"
        :numItems="sortedLinks.length"
        @update="updatePosition"
      >
        <div
          class="row"
          v-for="(link, idx) in sortedLinks"
          :key="link.id"
          :data-id="link.id"
          :data-idx="idx"
        >
          <div class="cell stretch center">
            {{ link.sectionTitle }}
          </div>

          <div class="cell stretch center">
            <div>
              <a target="__blank" :href="link.url">{{ link.title }}</a>
            </div>
          </div>

          <div class="cell center">
            <Dropdown
              class="right"
              :items="contextMenuItems"
              @change="(action) => handleAction(link.id, action)"
            >
              <span class="material-symbols-outlined">more_vert</span>
            </Dropdown>
          </div>
        </div>
      </Draggable>
    </template>

    <DocumentSpaceLinkEditor
      v-if="editorActive"
      :active="editorActive"
      :spaceId="documentSpaceId"
      :link="editorLink"
      @close="editorActive = false"
      @success="emitSuccess"
    />
  </div>
</template>

<style lang="scss" scoped>
.link_list {
  padding: 1em 1em;
}

.draggable .row {
  padding: 0.5em 0;
}

.draggable .row .cell {
  line-height: 2.1em;
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.button .material-symbols-outlined {
  font-size: 1em;
  line-height: 1.5;
  vertical-align: middle;
  margin-right: 5px;
}

.title_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  height: 41px;
}

.title_container h4 {
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.1em;
}

.cell a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.info {
  text-align: center;
  font-style: italic;
  margin-top: 2em;
}

b {
  font-weight: 500;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}

.row.action {
  background: #fafafa;
  width: 100%;
  display: flex;
  padding: 0.5em 0;
  font-size: 0.9rem;
  align-items: center;
  cursor: pointer;
}

.row.action .cell {
  padding: 0 0.5em 0 1em;
  line-height: 2.4em;
  flex: 1;
  color: $color-secondary;
}

.row.action .material-symbols-outlined {
  color: $color-secondary;
}

h4 .material-symbols-outlined {
  margin-right: 0.3em;
}

h4 .material-symbols-outlined:hover {
  cursor: pointer;
}
</style>
