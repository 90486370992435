<script lang="ts" setup>
import { computed, ref } from "vue";
import { Modal } from "@/components";
import {
  useCreateDocumentSpaceCrmMappingMutation,
  CrmContact,
} from "@/graphql/operations";
import { useI18n } from "vue-i18n";
import ContactPicker from "./ContactPicker.vue";

const { t } = useI18n({});

const props = defineProps({
  documentSpaceId: {
    required: true,
    type: String,
  },
  documentSpaceName: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(["close", "success", "error"]);
const emitClose = () => emit("close");
const emitSuccess = (contactId: string) => emit("success", contactId);
const emitError = () => emit("error");


const selectedContact = ref<CrmContact | null>(null);

const changeSelection = (contact: CrmContact) => {
  console.log("change selection", contact)
  selectedContact.value = contact;
};

const submitDisabled = computed(() => !selectedContact.value);

const updateName = ref<boolean>(true);

const { executeMutation } = useCreateDocumentSpaceCrmMappingMutation();
const submit = async () => {
  if (!selectedContact.value) {
    return;
  }

  let name = props.documentSpaceName;
  if (updateName.value) {
    name = formatName(selectedContact.value);
  }

  const r = await executeMutation({
    spaceId: props.documentSpaceId,
    crmContactId: selectedContact.value.id,
    name: name,
  });

  if (!r.error) {
    emitSuccess(selectedContact.value.id);
  } else {
    emitError();
  }

  emitClose();
};

const formatName = (contact: CrmContact) => {
  const nameParts = [contact.lastName];
  if (contact.firstName) {
    nameParts.push(contact.firstName);
  }

  let name = nameParts.join(", ");
  return name;
};
</script>

<template>
  <modal :active="true" @close="emitClose" :scrolling="true" class="large">
    <template #header> CRM Integration: Select a contact </template>

    <template #body>
      <ContactPicker @submit="submit" @change="changeSelection" />
    </template>

    <template #footer>
      <div class="checkbox_with_label">
        <div class="checkbox_container">
          <input
            type="checkbox"
            class="checkbox"
            id="checkbox"
            v-model="updateName"
          />
        </div>
        <label>{{ $t("crmContactSearch.updateName.label") }}</label>
      </div>
      <div class="buttons">
        <button
          :class="{ button: true, primary: true, disabled: submitDisabled }"
          @click="submit"
        >
          {{ $t("submit") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </modal>
</template>

<style scoped lang="scss">
$checkbox-width: 40px;
$color-secondary: #34b5ba;

.checkbox_container {
  vertical-align: middle;
  padding: 0.8em 0.8em 0.8em 0.5em;
  cursor: pointer;
}

.modal .footer .buttons {
  left: auto;
}

.checkbox_with_label .checkbox_container {
  padding: 0;
  margin-right: 0.5em;
}

.body_container {
  height: 100%;
}
</style>
