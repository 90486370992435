<script lang="ts" setup>
import { Breadcrumbs } from "@/components";
import { useWorkspaceWithChildWorkspacesQuery } from "@/graphql/operations";
import { useRoute, useRouter } from "vue-router";
import { onBeforeUnmount, computed } from "vue";

const route = useRoute();
const router = useRouter();

const workspaceId = route.params.workspaceId as string;
const { fetching, executeQuery, data } = useWorkspaceWithChildWorkspacesQuery({
  variables: {
    id: workspaceId,
  },
  requestPolicy: "cache-and-network",
});

const intervalId = setInterval(() => {
  executeQuery();
}, 5 * 1000);

onBeforeUnmount(() => {
  clearInterval(intervalId);
});

const workspace = computed(() => data.value?.workspace);
const subfolders = computed(
  () => data.value?.workspace?.childWorkspaces?.nodes
);

const navItems = computed(() => {
  if (!data.value?.workspace) {
    return [];
  }

  const ancestors = data.value?.workspace?.ancestors?.nodes || [];
  const items: Array<{ text: string; href?: string }> = ancestors.map((a) => ({
    text: a!.name,
    href: router.resolve({
      name: "Workspace",
      params: { workspaceId: a!.id },
    }).fullPath,
  }));

  items.push({
    text: data.value?.workspace?.name,
  });

  return items;
});
</script>

<template>
  <breadcrumbs :items="navItems" />
</template>
