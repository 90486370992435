<template>
  <Modal :active="active" @close="emits('close')">
    <template #header>
      {{ $t("customFlows.renameModal.title") }}
    </template>

    <template #body>
      {{ $t("customFlows.renameModal.message") }}

      <input
        type="text"
        v-model="newTitle"
        class="input"
        :placeholder="$t('customFlows.renameModal.placeholder')"
        @keyup.enter="renameFlow"
      />
    </template>

    <template #footer>
      <div class="buttons">
        <button
          class="button primary"
          @click="renameFlow"
          :disabled="emptyName"
        >
          {{ $t("customFlows.renameModal.submit") }}
        </button>
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from "@/components";
import { computed, ref } from "vue";
import { useRenameCustomFlowMutation } from "@/graphql/operations";

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  flowId: {
    type: String,
    required: true
  },
  previousTitle: {
    type: String,
    required: true
  }
});
const emits = defineEmits(["close", "success", "error"]);

const newTitle = ref<String>(props.previousTitle ?? "");
const emptyName = computed(() => newTitle.value.trim().length === 0);

const { executeMutation: executeRename } = useRenameCustomFlowMutation();
const renameFlow = async () => {
  const res = await executeRename({
    title: newTitle.value.trim(),
    id: props.flowId,
  });

  if (res.error) {
    emits("error");
  } else {
    emits("success");
  }
};
</script>

<style scoped lang="scss">
.input {
  margin-top: 1em;
}
</style>
