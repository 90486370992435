<template>
  <FlashMessage v-if="flashMessageActive" :state="resultState === 'error' ? 'error' : ''">
    {{ $t(`workspaceDetail.move.${resultState}`) }}
    <a
      v-if="resultState !== 'error'"
      class="link"
      @click="redirectToWorkspace"
    >
      {{ $t("workspaceDetail.move.goToWorkspace") }}
    </a>
  </FlashMessage>

  <folder-picker
    @close="emitClose"
    @submit="submit"
    :active="modalActive"
    :sourceItemName="workspace.name"
    :action="'MOVE'" />

</template>

<script lang="ts" setup>
import { ref, PropType } from "vue";
import { FlashMessage, FolderPicker } from "@/components";
import { Workspace, useMoveFolderMutation } from "@/graphql/operations";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({
  workspace: {
    type: Object as PropType<Workspace>,
    required: true,
  },
});

const router = useRouter();
const store = useStore();

const emit = defineEmits(["close", "success"]);

const flashMessageActive = ref<boolean>(false);
const modalActive = ref<boolean>(true);
const resultState = ref<string>("");

const closePopup = () => modalActive.value = false;
const emitClose = () => emit("close");
const showFlashMessage = (() => {
  flashMessageActive.value = true;
})

const targetWorkspaceId = ref<string | null>(null);

const { executeMutation } = useMoveFolderMutation();
const submit = async (targetWorkspaceId: string) => {

  const result = await executeMutation({
    id: props.workspace.id,
    parentId: targetWorkspaceId
  });

  emit("success");
  closePopup()
  showFlashMessage();
  window.setTimeout(() => (emit("close")), 4000);
};

function redirectToWorkspace() {
  if (targetWorkspaceId) {
    router.push({
      name: "Workspace",
      params: { workspaceId: targetWorkspaceId.value! },
    });
  }
}
</script>

