import { createI18n } from "vue-i18n";
import { translations } from "./translations";

export const locale = /^de\b/.test(navigator.language) ? "de" : "en";

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: locale,
  messages: translations
});