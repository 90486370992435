<template>
  <Modal :active="active" @close="emits('close')">
    <template #header>
      {{ $t("documentSpaces.deleteConfirmation.title") }}
    </template>

    <template #body>
      {{ $t("documentSpaces.deleteConfirmation.message") }}
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
        <button class="button primary" @click="deleteSharedSpace">
          {{ $t("documentSpaces.deleteConfirmation.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from "@/components";
import { useDeleteDocumentSpaceMutation } from "@/graphql/operations";
import { useFlashMessage } from "@/hooks";
import { useI18n } from "vue-i18n";

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  spaceId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["close", "success"]);

const { displayFlashMessage } = useFlashMessage();
const { t } = useI18n();

const { executeMutation: executeDelete } = useDeleteDocumentSpaceMutation();
const deleteSharedSpace = async () => {
  await executeDelete({ id: props.spaceId });
  displayFlashMessage(t("documentSpaces.deleteConfirmation.success"));
  emits("success");
};
</script>
