<script lang="ts" setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Modal, WorkspaceTree } from "@/components";
import { RootWorkspaceFragment } from "@/graphql/operations";

const props = defineProps({
  sourceItemName: {
    type: String,
    required: true,
  },
  action: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
});

const store = useStore();

const emit = defineEmits(["close", "submit"]);

const rootWorkspaces = computed(() =>
  store.getters.rootWorkspaces?.filter(
    (w: RootWorkspaceFragment) => w.myPrivileges?.mayAdministrate
  )
);

const emitClose = () => emit("close");

const selectedWorkspace = ref<string | undefined>(
  rootWorkspaces.value ? rootWorkspaces.value[0].id : undefined
);
const select = (workspaceId: string) => {
  selectedWorkspace.value = workspaceId;
};

const submit = () => {
  console.log(selectedWorkspace.value);
  emit("submit", selectedWorkspace.value);
};
</script>

<template>
  <Modal :active="active" @close="emit('close')" class="medium scrolling">
    <template v-slot:header>
      <div>
        <div>
          {{ $t(`folderPicker.header.${action}`, { name: sourceItemName }) }}
        </div>
      </div>
    </template>

    <template v-slot:body>
      <workspace-tree
        :selected="selectedWorkspace"
        :singleWorkspace="true"
        :mustBeAdmin="true"
        @change="select"
      />
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button class="button" @click="emitClose">
          {{ $t("folderPicker.cancel") }}
        </button>
        <button
          :class="{
            button: true,
            primary: true,
            disabled: selectedWorkspace === undefined,
          }"
          @click="submit"
        >
          {{ $t(`folderPicker.submit.${action}`) }}
        </button>
      </div>
    </template>
  </Modal>
</template>