<template>
  <FlashMessage
    v-if="flashMessageActive"
    :state="resultState === 'error' ? 'error' : ''"
  >
    {{ $t(`docCopyDialog.actionComplete.${resultState}.${action}`) }}
    <a v-if="resultState !== 'error'" class="link" @click="redirectToWorkspace">
      {{ $t("docCopyDialog.actionComplete.goToWorkspace") }}
    </a>
  </FlashMessage>

  <folder-picker
    @close="emitClose"
    @submit="submit"
    :active="modalActive"
    :sourceItemName="doc.displayTitle"
    :action="action"
  />
</template>

<script lang="ts" setup>
import { ref, PropType, computed } from "vue";
import { FlashMessage, FolderPicker } from "@/components";
import DocumentService from "@/document";
import { WorkspaceDocumentFragment, Workspace } from "@/graphql/operations";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({
  doc: {
    type: Object as PropType<WorkspaceDocumentFragment>,
    required: true,
  },
  action: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const store = useStore();

const emit = defineEmits(["close", "success"]);

const flashMessageActive = ref<boolean>(false);
const modalActive = ref<boolean>(true);
const resultState = ref<string>("");

const closePopup = () => (modalActive.value = false);
const emitClose = () => emit("close");
const showFlashMessage = () => {
  flashMessageActive.value = true;
};

const targetWorkspaceId = ref<string | null>(null);

const submit = async (workspaceId: string) => {
  targetWorkspaceId.value = workspaceId;

  try {
    switch (props.action) {
      case "COPY":
        await DocumentService.copyToWorkspace(
          props.doc,
          targetWorkspaceId.value
        );
        break;

      case "MOVE":
        await DocumentService.moveToWorkspace(
          props.doc,
          store.getters.currentWorkspace.id,
          targetWorkspaceId.value
        );
        break;

      default:
        break;
    }

    resultState.value = "success";
  } catch {
    resultState.value = "error";
  }

  emit("success");
  closePopup();
  showFlashMessage();
  window.setTimeout(() => emit("close"), 4000);
};

function redirectToWorkspace() {
  if (targetWorkspaceId) {
    router.push({
      name: "Workspace",
      params: { workspaceId: targetWorkspaceId.value! },
    });
  }
}
</script>
