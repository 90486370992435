<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  Breadcrumbs,
  DraggableGrid,
  FlashMessage,
  LoadingSpinner,
  Dropdown,
} from "@/components";
import DeleteCustomFlowModal from "./DeleteCustomFlowModal.vue";
import DocumentAndPagesPicker from "./DocumentAndPagesPicker.vue";
import RenameCustomFlowModal from "./RenameCustomFlowModal.vue";
import DeleteFlowPageModal from "./DeleteFlowPageModal.vue";
import ShareCustomFlowModal from "./share_flow_modal/ShareCustomFlowModal.vue";
import CustomFlowPage from "./CustomFlowPage.vue";
import {
  useDeleteCustomFlowMutation,
  useCustomFlowQuery,
  useAppendFlowItemsMutation,
  useReorderFlowPageMutation,
  useAppendVideoMutation,
  type FlowItem,
} from "@/graphql/operations";

const route = useRoute();
const router = useRouter();

const flowId = computed(() => route.params.id as string);

const { t } = useI18n({});

const {
  data,
  fetching: flowFetching,
  executeQuery,
} = useCustomFlowQuery({
  variables: {
    id: flowId.value,
  },
  requestPolicy: "cache-and-network",
});

const flow = computed(() => data.value?.flow);
const pages = computed(() => flow.value?.flowItems.nodes);

const legalAppendixPages = computed(() => flow.value?.legalAppendixPages.nodes);
const hasLegalAppendixPages = computed(
  () => legalAppendixPages.value && legalAppendixPages.value.length > 0
);

/**
 * Breadcrumbs
 */
const breadcrumbItems = computed(() => [
  {
    text: "Custom Flows",
    href: router.resolve({ name: "CustomFlows" }).fullPath,
  },
  {
    text: flow.value?.title,
  },
]);

/**
 * Flash Message
 */
const flashMessage = {
  isActive: ref<boolean>(false),
  state: ref<string>(""),
  message: ref<string>(""),
  spaceId: ref<string | undefined | null>(""),
  timer: 0,
  showRouter: false,
  show: (
    message: string,
    state: string,
    spaceId?: string,
    showRouter: boolean = false
  ) => {
    clearTimeout(flashMessage.timer);
    flashMessage.isActive.value = true;
    flashMessage.message.value = message;
    flashMessage.state.value = state;
    flashMessage.spaceId.value = spaceId;
    flashMessage.showRouter = showRouter;

    flashMessage.timer = window.setTimeout(() => {
      flashMessage.isActive.value = false;
      flashMessage.message.value = "";
    }, 3000);
  },
};

/**
 * Delete Flow
 */
const showDeleteModal = ref<boolean>(false);
const displayDeleteConfirmation = () => {
  showDeleteModal.value = true;
};

const { executeMutation: executeDelete } = useDeleteCustomFlowMutation();
const deleteFlow = async () => {
  try {
    const res = await executeDelete({ id: flowId.value });
    if (res.error) {
      throw new Error(res.error.message);
    }
  } catch {
    flashMessage.show(t("customFlows.flashMessages.deleteFlow.error"), "error");
  }

  flashMessage.show(
    t("customFlows.flashMessages.deleteFlow.success"),
    "success"
  );
  router.push({ name: "CustomFlows" });
  showDeleteModal.value = false;
};

/**
 * Add Pages
 */
const isDocumentPagePickerActive = ref<boolean>(false);
const openDocumentPagePicker = () => {
  isDocumentPagePickerActive.value = true;
};

const closeDocumentPagePicker = () => {
  isDocumentPagePickerActive.value = false;
};

const { executeMutation: addFlowItems } = useAppendFlowItemsMutation();
const appendFlowPages = async (pageIds: Array<string>) => {
  try {
    const res = await addFlowItems({
      flowId: flowId.value,
      pageIds: pageIds,
    });
    if (res.error) {
      throw new Error(res.error.message);
    }
    closeDocumentPagePicker();
    await executeQuery();
    flashMessage.show(
      t("customFlows.flashMessages.addingPages.success"),
      "success"
    );
  } catch {
    flashMessage.show(
      t("customFlows.flashMessages.addingPages.error"),
      "error"
    );
  }
};

const { executeMutation: addVideoItem } = useAppendVideoMutation();
const addVideo = async (videoId: string) => {
  console.log("videoId:", videoId)
  const res = await addVideoItem({
    flowId: flowId.value,
    videoId: videoId,
  });

  if (res.error) {
    flashMessage.show(
      t("customFlows.flashMessages.addingPages.error"),
      "error"
    );
  } else {
    closeDocumentPagePicker();
    await executeQuery();
    flashMessage.show(
      t("customFlows.flashMessages.addingPages.success"),
      "success"
    );
  }
};

/**
 * Delete Flow Pages
 */
const showRemovePageModal = ref<boolean>(false);
const flowPageIdToBeRemoved = ref<string | null>(null);

const handleRemoveFlowPage = (id: string) => {
  flowPageIdToBeRemoved.value = id;
  showRemovePageModal.value = true;
};

const handlePageDeleted = async () => {
  showRemovePageModal.value = false;
  await executeQuery();
  flashMessage.show(
    t("customFlows.flashMessages.deletingPage.success"),
    "success"
  );
};
const handleErrorDeletingPage = () => {
  showRemovePageModal.value = false;
  flashMessage.show(t("customFlows.flashMessages.deletingPage.error"), "error");
};
const closeRemovePageModal = () => {
  showRemovePageModal.value = false;
};

/**
 * Presenter
 */
const viewPageInPresenter = (pageNumber: number = 1) => {
  router.push({
    name: "ViewCustomFlowPageByPage",
    params: { id: flowId.value },
    query: { page: pageNumber },
  });
};

/**
 * Drag and Drop
 */
const { executeMutation } = useReorderFlowPageMutation();
async function placeBefore(flowItemId: string, beforeId: string) {
  await executeMutation({
    flowItemId: flowItemId,
    beforeId: beforeId,
  });
}

async function placeBehind(flowItemId: string, behindId: string) {
  await executeMutation({
    flowItemId: flowItemId,
    behindId: behindId,
  });
}

/**
 * Rename Flow
 */
const isRenameModalActive = ref<boolean>(false);
const openRenameModal = () => {
  isRenameModalActive.value = true;
};
const closeRenameModal = () => {
  isRenameModalActive.value = false;
};
const handleFlowRenamed = () => {
  executeQuery();
  flashMessage.show(
    t("customFlows.flashMessages.renamingFlow.success"),
    "success"
  );
  closeRenameModal();
};

/**
 * Share Flow to Shared Space
 */
const isShareModalActive = ref<boolean>(false);
const openShareModal = () => {
  isShareModalActive.value = true;
};
const closeShareModal = () => {
  isShareModalActive.value = false;
};
const handleShareFlow = (resultState: string, spaceIds: Array<string>) => {
  flashMessage.show(
    resultState === "success"
      ? t("customFlows.flashMessages.share.success")
      : t("customFlows.flashMessages.share.error"),
    resultState,
  );
  closeShareModal();
};

const getDocumentTitle = (page: FlowItem | undefined) => {
  if (!page) {
    return null;
  }

  return page.page
    ? page!.page?.document?.displayTitle
    : page!.video?.displayTitle;
};

const actionItems = [
  {
    value: "append_pages",
    title: t("customFlows.detail.addPages"),
  },
  {
    value: "rename",
    title: t("customFlows.detail.rename"),
  },
  {
    value: "delete",
    title: t("customFlows.detail.delete"),
  },
];

const handleAction = (action: string) => {
  switch (action) {
    case "append_pages":
      openDocumentPagePicker();
      break;

    case "rename":
      openRenameModal();
      break;

    case "delete":
      displayDeleteConfirmation();
      break;
  }
};
</script>

<template>
  <FlashMessage
    v-if="flashMessage.isActive.value"
    :state="flashMessage.state.value"
  >
    {{ flashMessage.message.value }}
    <router-link
      v-if="flashMessage.showRouter"
      class="link"
      :to="{
        name: 'DocumentSpace',
        params: { id: flashMessage.spaceId.value },
      }"
    >
      {{ $t("customFlows.shareModal.addedToSpace.goToSpace") }}
    </router-link>
  </FlashMessage>

  <div class="pane page_content flow_container">
    <div v-if="flowFetching" class="loading_wrapper">
      <div class="loading_status">
        <LoadingSpinner :color="'black'" />
      </div>
    </div>

    <div v-else-if="!flow" class="empty_pane_container">
      <span class="material-symbols-outlined-outlined"> error_outline </span>
      <span class="text">
        {{ $t("customFlows.detail.doesNotExist") }}
      </span>
    </div>

    <div v-else class="flow_content_wrapper">
      <div class="title_container">
        <Breadcrumbs :items="breadcrumbItems" class="breadcrumbs" />

        <div class="control_container">
          <Dropdown :items="actionItems" @change="handleAction">
            <div>
              <span class="material-symbols-outlined">more_vert</span>
            </div>
          </Dropdown>

          <button
            :class="{
              button: true,
              primary: true,
              disabled: pages?.length === 0,
            }"
            @click="viewPageInPresenter(1)"
          >
            <span class="material-symbols-outlined">play_arrow</span>
            {{ $t("customFlows.detail.present") }}
          </button>
          <button
            :class="{
              button: true,
              primary: true,
              disabled: pages?.length === 0,
            }"
            @click="openShareModal"
          >
            <span class="material-symbols-outlined">share</span>
            {{ $t("customFlows.detail.share") }}
          </button>
        </div>
      </div>

      <div v-if="pages && pages?.length > 0">
        <draggable-grid
          class="columns grid_drag flow_gallery"
          :ignore-last-elem="true"
          @placeAbove="placeBefore"
          @placeBelow="placeBehind"
        >
          <template v-for="(page, idx) in pages" :key="page!.id">
            <CustomFlowPage
              v-if="page"
              :pageId="page!.pageId!"
              :videoId="page!.video?.id"
              :videoThumbnail="page!.video?.thumbnailDownloadUrl"
              :id="page!.id"
              :index="idx + 1"
              :document-title="getDocumentTitle(page as FlowItem) ?? ''"
              @delete="handleRemoveFlowPage"
              @present="viewPageInPresenter"
              @dblclick="viewPageInPresenter(idx + 1)"
              :data-idx="idx"
              :data-id="page!.id"
              :data-test="`custom-flow-page-${page!.page?.pageNumber}`"
            />

            <div
              v-if="pages.length - 1 === idx"
              class="add_pages_container"
              :data-ignored="true"
            >
              <button class="item_button" @click="openDocumentPagePicker">
                <span class="material-symbols-outlined-outlined"> note_add </span>
                <label>{{ $t("customFlows.detail.addPagesLabel") }}</label>
              </button>
            </div>
          </template>
        </draggable-grid>
      </div>

      <div v-else class="add_pages_container">
        <button class="item_button" @click="openDocumentPagePicker" data-test="add-page-ctrl">
          <span class="material-symbols-outlined-outlined"> note_add </span>
          <label>{{ $t("customFlows.detail.addPagesLabel") }}</label>
        </button>
      </div>

      <div
        v-if="pages && pages.length && hasLegalAppendixPages"
        class="legal_appendix_container"
      >
        <div class="divide">
          <hr />
        </div>

        <div ref="legalAppendixContainerElem" class="legal_appendix_gallery">
          <div
            v-for="page in legalAppendixPages"
            :key="page?.id"
            class="legal_appendices"
          >
            <CustomFlowPage
              v-if="page"
              :page-id="page!.id"
              :id="page!.id"
              :index="0"
              :is-appendix="true"
              :document-title="page!.document?.displayTitle ?? ''"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <DocumentAndPagesPicker
    v-if="isDocumentPagePickerActive"
    @close="closeDocumentPagePicker"
    @submit="appendFlowPages"
    @addVideo="addVideo"
  />

  <delete-custom-flow-modal
    v-if="showDeleteModal"
    :active="true"
    :flowId="flowId"
    :title="flow!.title"
    @close="showDeleteModal = false"
    @success="deleteFlow"
  />

  <rename-custom-flow-modal
    v-if="isRenameModalActive"
    :active="true"
    :flowId="flowId"
    :previous-title="flow?.title!"
    @close="closeRenameModal"
    @success="handleFlowRenamed"
    @error="
      flashMessage.show(
        $t('customFlows.flashMessages.errorRenamingFlow'),
        'error'
      )
    "
  />

  <delete-flow-page-modal
    v-if="showRemovePageModal"
    :active="true"
    :pageId="flowPageIdToBeRemoved!"
    @close="closeRemovePageModal"
    @pageRemoved="handlePageDeleted"
    @error="handleErrorDeletingPage"
  />

  <share-custom-flow-modal
    v-if="isShareModalActive"
    :active="true"
    :flowId="flowId"
    @close="closeShareModal"
    @success="handleShareFlow"
  />
</template>

<style lang="scss" scoped>
$pageWidth: 335px;
$primary: #35b6ba;
$accentLight: #3fe0f0;

.pane {
  height: 100%;

  .loading_wrapper {
    height: 100%;

    .loading_status {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 75px);
    }
  }

  &.empty_pane_container {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .material-symbols-outlined-outlined {
      font-size: 6em;
      opacity: 0.4;
    }

    .text {
      margin: 0.6em 0 1.5em 0;
    }
  }

  &.flow_container {
    display: flex;
    flex-direction: column;

    .flow_content_wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      .title_container {
        display: flex;
        margin-bottom: 2em;

        .breadcrumbs {
          font-size: 1.1rem;
          font-weight: 500;
          flex: 1;
        }

        .control_container {
          display: flex;
          align-items: center;

          .button {
            margin-left: 0.5em;
          }
        }
      }

      .flow_gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
      }

      .add_pages_container {
        .item_button {
          width: $pageWidth;
          height: $pageWidth;
          border-radius: 0.35em;
          border: 1px solid transparent;
          background-color: transparent;
          display: flex;
          align-self: flex-start;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 0.5em;

          &:hover {
            border: 1px solid #dbdbdb;
            cursor: pointer;
          }

          .material-symbols-outlined-outlined {
            font-size: 4em;
            opacity: 0.7;
          }

          label {
            font-size: medium;
          }
        }
      }

      .divide {
        width: 100%;
        margin: 2em 0;
        hr {
          border: 1px solid #dbdbdb;
          border-radius: 1px;
        }
      }

      .legal_appendix_container {
        display: flex;
        flex-direction: column;

        .legal_appendix_gallery {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 20px;
        }
      }
    }
  }
}
</style>
