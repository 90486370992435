<script lang="ts" setup>
import {
  type DocumentSpaceConfig,
  type DocumentSpaceLink,
} from "@/graphql/operations";
import { PropType, ref } from "vue";
import ConfigLinkRow from "./ConfigLinkRow.vue";

const props = defineProps({
  config: {
    type: Object as PropType<DocumentSpaceConfig>,
    required: true,
  },
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["success"]);

const isVisible = ref(true);
const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
};
</script>

<template>
  <div class="link_list">
    <div class="title_container">
      <h4>
        <span class="material-symbols-outlined" @click="toggleVisibility">
          <template v-if="isVisible"> keyboard_arrow_down </template>
          <template v-else> keyboard_arrow_right </template>
        </span>
        {{ $t("documentSpace.configLinks.title") }}
      </h4>
    </div>

    <template v-if="isVisible">
      <config-link-row
        v-for="link in config.documentSpaceLinksByConfigId?.nodes"
        :link="link as DocumentSpaceLink"
        :documentSpaceId="documentSpaceId"
        @success="emits('success')"
      >
      </config-link-row>
    </template>
  </div>
</template>

<style scoped lang="scss">
.link_list {
  padding: 1em 1em;
}

.title_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  height: 41px;
}

.title_container h4 {
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.1em;
}

h4 .material-symbols-outlined {
  margin-right: 0.3em;
}

h4 .material-symbols-outlined:hover {
  cursor: pointer;
}
</style>
