import { DocumentData } from "firebase/firestore";

enum Position {
  TopLeft = "topLeft",
  TopRight = "topRight",
  BottomRight = "bottomRight",
  BottomLeft = "bottomLeft",
  Center = "center"
}

enum Fit {
  Fill = "fill",
  Contain = "contain"
}

const DEFAULT_QR_CODE_POSITION = Position.BottomRight;
const DEFAULT_VIDEO_FIT = Fit.Fill;
const DEFAULT_DISPLAY_QR_ON_PRESENTATION = false;

export class ScreenSessionConfig {

  backgroundImage: string | null;
  backgroundVideo: string | null;
  qrCodePosition: Position;
  videoFit: Fit;
  displayQROnPresentation: boolean;

  static fromDocument(
    doc: DocumentData
  ): ScreenSessionConfig {
    return new ScreenSessionConfig(
        doc.backgroundImage || null,
        doc.backgroundVideo || null,
        doc.qrCodePosition as Position || DEFAULT_QR_CODE_POSITION,
        doc.videoFit as Fit || DEFAULT_VIDEO_FIT,
        doc.displayQROnActivePresentation || DEFAULT_DISPLAY_QR_ON_PRESENTATION
    );
  }

  static defaultConfig(): ScreenSessionConfig {
    return new ScreenSessionConfig(
        null,
        null,
        DEFAULT_QR_CODE_POSITION,
        DEFAULT_VIDEO_FIT,
        DEFAULT_DISPLAY_QR_ON_PRESENTATION
    );
  }

  constructor(
      backgroundImage: string | null,
      backgroundVideo: string | null,
      qrCodePosition: Position,
      videoFit: Fit,
      displayQROnPresentation: boolean) {
    this.backgroundImage = backgroundImage;
    this.backgroundVideo = backgroundVideo;
    this.qrCodePosition = qrCodePosition;
    this.videoFit = videoFit;
    this.displayQROnPresentation = displayQROnPresentation;
  }

  hasBackground() {
    return this.backgroundImage !== null || this.backgroundVideo !== null;
  }

}
