<template>
  <div class="flow-presenter" ref="flowPresenter">
    <div class="flow-presenter__buttons-at-top">
      <button class="button" @click="closePresenter">
        <span class="material-symbols-outlined-outlined"> arrow_back </span>
      </button>
      <slot name="buttonsAtTop"></slot>
    </div>

    <!-- loading spinner -->
    <div
      class="flow-presenter__spinner"
      v-if="!isGalleryActive && (!renderedForTheFirstTime || fetching)"
    >
      <loading-spinner color="black" />
    </div>

    <flow-gallery
      v-show="isGalleryActive"
      :flowId="props.flowId"
      :currentPageNumber="oneIndexedPage"
      @update:page="oneIndexedPage = $event"
      @close="toggleGallery"
    />

    <template v-if="!isGalleryActive && currentDocumentDownloadUrl">
      <template
        v-for="url in allDocumentDownloadUrls"
        :key="`pdfjs-presenter-with-${url}`"
      >
        <pdf-js-presenter
          class="flow-presenter__pdf"
          style="grid-row: 1 / 3"
          v-show="url === currentDocumentDownloadUrl"
          :document="getStorageProxyUrl(url)"
          :page="url === currentDocumentDownloadUrl ? projectorPage : 1"
          @rendered="renderedForTheFirstTime = true"
        />
      </template>
    </template>

    <video-presenter
      class="flow-presenter__video"
      v-else-if="!isGalleryActive && currentItem?.video?.downloadUrl"
      :document="getStorageProxyUrl(currentItem.video.downloadUrl)"
      :documentId="currentItem.video.id"
      @rendered="renderedForTheFirstTime = true"
      :autoplay="false"
    />
    <div v-else-if="!isGalleryActive">
      Item is neither page nor video, but
      <pre>{{ currentItem }}</pre>
      .
    </div>

    <div class="flow-presenter__pdf-controls">
      <button class="button" @click="previousPage" :disabled="isFirstPage">
        <span class="material-symbols-outlined-outlined"> arrow_left </span>
      </button>

      <span> {{ oneIndexedPage }} / {{ items?.length }} </span>

      <button class="button" @click="nextPage" :disabled="isLastPage">
        <span class="material-symbols-outlined-outlined"> arrow_right </span>
      </button>

      <slot
        name="fullscreenButton"
        :isFullscreen="isFullscreen"
        :toggleFullscreen="toggleFullscreen"
      >
        <button class="button" @click="toggleFullscreen">
          <tooltip v-if="isFullscreen">
            <template v-slot>
              <span class="material-symbols-outlined">close_fullscreen</span>
            </template>
            <template #tooltip>{{
              $t("flowPresenter.controls.closeFullscreen")
            }}</template>
          </tooltip>

          <tooltip v-else>
            <template v-slot>
              <span class="material-symbols-outlined">fullscreen</span>
            </template>
            <template #tooltip>{{
              $t("flowPresenter.controls.openFullscreen")
            }}</template>
          </tooltip>
        </button>
      </slot>

      <slot
        name="galleryButton"
        :isGalleryActive="isGalleryActive"
        :toggleGallery="toggleGallery"
      >
        <button class="button" @click="toggleGallery">
          <tooltip v-if="isGalleryActive">
            <template v-slot>
              <span class="material-symbols-outlined">crop_7_5</span>
            </template>
            <template #tooltip>
              {{ $t("flowPresenter.controls.closeGallery") }}
            </template>
          </tooltip>

          <tooltip v-else>
            <template v-slot>
              <span class="material-symbols-outlined">apps</span>
            </template>
            <template #tooltip>
              {{ $t("flowPresenter.controls.openGallery") }}
            </template>
          </tooltip>
        </button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFlowQuery } from "@/graphql/operations";
import { useFullscreen, useVModel, onKeyStroke } from "@vueuse/core";
import { computed, ref, onBeforeUnmount, watch } from "vue";
import { getStorageProxyUrl } from "@/util";
import PdfJsPresenter from "./PdfJsPresenter.vue";
import VideoPresenter from "./VideoPresenter.vue";
import LoadingSpinner from "./common/LoadingSpinner.vue";
import { useProjector } from "@/util/presentation";
import router from "@/router";
import FlowGallery from "./FlowGallery.vue";
import { Tooltip } from "@/components";
import { AnalyticsService, Events } from "@/util";

const props = withDefaults(
  defineProps<{
    flowId: string;
    page?: number;
  }>(),
  { page: 1 }
);

const emit = defineEmits<{
  (e: "update:page", value: number): void;
}>();

const oneIndexedPage = useVModel(props, "page", emit, {
  passive:
    true /* gives this component an internal value, if the page property is not provided. */,
  defaultValue: 1 /* should be 1-indexed to match page numbers in DocumentPresenter.vue */,
});

const zeroIndexedPage = computed({
  get() {
    return oneIndexedPage.value - 1;
  },
  set(value: number) {
    oneIndexedPage.value = value + 1;
  },
});

onKeyStroke("ArrowLeft", (e) => {
  previousPage();
});

onKeyStroke("ArrowRight", (e) => {
  nextPage();
});

function nextPage() {
  zeroIndexedPage.value = Math.min(
    zeroIndexedPage.value + 1,
    (items.value?.length ?? 1) - 1
  );
}
function previousPage() {
  zeroIndexedPage.value = Math.max(zeroIndexedPage.value - 1, 0);
}

const isFirstPage = computed(() => oneIndexedPage.value === 1);
const isLastPage = computed(() => oneIndexedPage.value === items.value?.length);

const { data: dataOfFlow, fetching } = useCustomFlowQuery({
  variables: computed(() => ({ id: props.flowId })),
});

const unwatch = watch(
  dataOfFlow,
  (newData, oldData) => {
    if (newData && !oldData && newData.flow) {
      AnalyticsService.sendEvent(Events.OpenEntity, {
        flow_id: newData.flow.id,
      });
    }
  },
  { immediate: true }
);

const renderedForTheFirstTime = ref(false);
const flow = computed(() => dataOfFlow.value?.flow);
const items = computed(() => flow.value?.flowItems.nodes);
const currentItem = computed(() => items.value?.[zeroIndexedPage.value]);

watch(
  currentItem,
  (newItem, oldItem) => {
    if (newItem && oldItem && newItem.pageId === oldItem.pageId) {
      return;
    }

    if (
      newItem?.page?.document?.id !== oldItem?.page?.document?.id &&
      oldItem?.page?.document?.id
    ) {
      AnalyticsService.sendEvent(Events.CloseEntity, {
        document_id: oldItem.page.document.id,
        flow_id: props.flowId,
      });
    }

    if (oldItem && oldItem.pageId && oldItem.page) {
      AnalyticsService.sendEvent(Events.CloseEntity, {
        page_id: oldItem.pageId,
        document_id: oldItem.page.document!.id,
        flowId: props.flowId,
      });
    }

    if (
      newItem?.page?.document?.id !== oldItem?.page?.document?.id &&
      newItem?.page?.document?.id
    ) {
      AnalyticsService.sendEvent(Events.OpenEntity, {
        document_id: newItem.page.document.id,
        flow_id: props.flowId,
      });
    }

    if (newItem && newItem.pageId && newItem.page) {
      AnalyticsService.sendEvent(Events.OpenEntity, {
        page_id: newItem.pageId,
        document_id: newItem.page.document!.id,
        flow_id: props.flowId,
      });
    }
  },
  { immediate: true }
);

const currentDocumentDownloadUrl = computed(
  () => currentItem.value?.page?.document?.downloadUrl
);

const allDocumentDownloadUrls = computed(
  () =>
    new Set(
      dataOfFlow.value?.flow?.flowItems.nodes
        .map((item) => item?.page?.document?.downloadUrl)
        .filter((url) => !!url) as string[]
    )
);

const { document: projectorDocument, page: projectorPage } = useProjector();
watch(
  computed(() => currentItem.value?.page?.document ?? currentItem.value?.video),
  (newValue, oldValue) => {
    projectorDocument.value = newValue ?? undefined;
  },
  { immediate: true }
);

watch(
  computed(() => currentItem.value?.page?.pageNumber),
  (newValue, oldValue) => {
    if (newValue !== undefined) {
      projectorPage.value = newValue;
    }
  },
  { immediate: true }
);

const closePresenter = () => {
  router.push({ name: "CustomFlow", params: { id: props.flowId } });
};

onBeforeUnmount(() => {
  projectorDocument.value = undefined;
  if (dataOfFlow.value && dataOfFlow.value.flow) {
    if (
      currentItem.value &&
      currentItem.value.pageId &&
      currentItem.value.page
    ) {
      AnalyticsService.sendEvent(Events.CloseEntity, {
        page_id: currentItem.value.pageId,
        document_id: currentItem.value.page.document!.id,
        flow_id: props.flowId,
      });

      AnalyticsService.sendEvent(Events.CloseEntity, {
        document_id: currentItem.value.page.document!.id,
        flow_id: props.flowId,
      });
    }

    AnalyticsService.sendEvent(Events.CloseEntity, {
      flow_id: dataOfFlow.value.flow.id,
    });
  }
});

/**
 * Gallery
 */
const isGalleryActive = ref<boolean>(false);

const toggleGallery = () => {
  isGalleryActive.value = !isGalleryActive.value;
};

/**
 * Fullscreen
 */
const flowPresenter = ref<HTMLElement | null>(null);
const { isFullscreen, toggle: toggleFullscreen } = useFullscreen(flowPresenter);
</script>

<style lang="scss" scoped>
.flow-presenter {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template:
    [top-start] "top" min-content [top-end]
    [main-start] "main" 1fr [main-end]
    [bottom-start] "bottom" min-content [bottom-end]
    /
    [col-start] 1fr [col-end];
  background-color: #fafafa;
}

.flow-presenter__spinner {
  grid-area: main;
  align-self: center;
  justify-self: center;
}

.flow-presenter__video,
.flow-presenter__pdf,
.flow-presenter__gallery {
  justify-self: stretch;
  align-self: stretch;
  grid-area: top-start / col-start / main-end / col-end;
  //grid-area: main;
}

.flow-presenter__pdf-controls,
.flow-presenter__buttons-at-top {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  grid-area: bottom;

  .button span {
    margin-right: 0px;
  }
}

.flow-presenter__buttons-at-top {
  justify-self: flex-start;
  align-self: flex-start;
  display: inline-flex;
  justify-content: flex-start;
  //background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 10;
  grid-area: top;
}

.flow-presenter__buttons-at-top .button {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.flow-presenter__buttons-at-top:empty {
  background: none;
}

/* see https://css-tricks.com/inclusively-hidden/ */
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.presenter_state_switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1em;

  div {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 1em;
    z-index: 100;
    border: 1px solid #dbdbdb;
    border-radius: 0.35em;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      padding: 0;
      border: none;

      &:hover {
        background-color: #dbdbdb;
        cursor: pointer;
      }

      &:first-child {
        border-radius: 0.35em 0.35em 0 0;
        border-right: 1px solid #dbdbdb;
      }
    }
  }
}

.presenter_wrap {
  height: 100%;

  .align_horizontally {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }
}
</style>
