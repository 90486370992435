import { createI18n } from "vue-i18n";

const messages = {
  en: {
    documents: "Documents",
    links: "Links"
  },
  de: {
    documents: "Dokumente",
    links: "Links"
  },
  fr: {
    documents: "Documents",
    links: "Links"
  },
  it: {
    documents: "Documenti",
    links: "Links"
  }
};

export const getTranslator = (locale: string) => {
  return createI18n({
    legacy: false,
    locale,
    messages
  });
}
