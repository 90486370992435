<script lang="ts" setup>
import { PropType, computed, watch } from "vue";
import { useFlashMessage, useDeleteConfirmation } from "@/hooks";
import { useForm, useField } from "vee-validate";
import { string } from "yup";
import { type Config } from "./Config";

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
  idx: {
    type: Number,
    required: false,
  },
});

const emits = defineEmits(["close", "reloadData", "newItem", "changeState"]);
const { displayFlashMessage } = useFlashMessage();
const { displayDeleteConfirmation } = useDeleteConfirmation();

const { validate, errors } = useForm();

const initialLink = computed(() => {
  if (props.config.footerLinks && props.idx !== undefined) {
    return props.config.footerLinks[props.idx];
  } else {
    return {
      url: null,
      text: null,
    };
  }
});

const { value: url } = useField<string>("url", string().url().required(), {
  initialValue: initialLink.value.url,
});

const { value: text } = useField<string>("text", string().required(), {
  initialValue: initialLink.value.text,
});

watch(initialLink, (newLink, oldLink) => {
  if (newLink !== oldLink) {
    url.value = newLink.url;
    text.value = newLink.text;
  }
});

const submit = async () => {
  const validationResult = await validate();
  if (!validationResult.valid) {
    console.log("invalid data");
    return;
  }

  const links = props.config.footerLinks || [];

  //new link
  if (props.idx === undefined) {
    links.push({
      url: url.value,
      text: text.value,
    });
  } else {
    links[props.idx] = {
      url: url.value,
      text: text.value,
    };
  }

  const res = await props.config.updateFooterLinks(links);
  if (res.error) {
    displayFlashMessage(res.error.message, "error");
  } else {
    displayFlashMessage("Success", "success");
    if (props.idx === undefined) {
      emits("newItem", links.length - 1);
    } else {
      emits("reloadData");
    }
  }
};

const hasChanges = computed(
  () =>
    url.value !== initialLink.value.url || text.value !== initialLink.value.text
);

watch(hasChanges, () => {
  emits("changeState", hasChanges.value);
});

const displayConfirmation = async () => {
  if (props.idx === undefined || !props.config.footerLinks) {
    return;
  }

  const confirmed = await displayDeleteConfirmation(
    "templateEditor.toolbar.footerLink.delete"
  );

  if (confirmed) {
    const links = props.config.footerLinks?.filter(
      (_, index) => index !== props.idx
    );
    const res = await props.config.updateFooterLinks(links);
    if (res.error) {
      displayFlashMessage(res.error.message, "error");
    } else {
      displayFlashMessage("Success", "success");
    }
  }

  emits("reloadData");
  emits("close");
};
</script>

<template>
  <div class="toolbar_body">
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.link.linkTitle.label") }}</label>
      </div>
      <div>
        <input
          type="text"
          class="input"
          v-model="text"
          :class="{ invalid: errors.text !== undefined }"
        />
        <p v-if="errors.text !== undefined" class="help">
          {{ errors.text }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="label_container">
        <label>{{ $t("templateEditor.toolbar.link.url.label") }}</label>
      </div>
      <div>
        <input
          type="text"
          class="input"
          v-model="url"
          :class="{ invalid: errors.url !== undefined }"
          placeholder="https://www.pitchview.io"
        />
        <p v-if="errors.url !== undefined" class="help">
          {{ errors.url }}
        </p>
      </div>
    </div>
    <div class="row bottom" v-if="idx !== undefined">
      <button class="button" @click="displayConfirmation">
        <span class="material-symbols-outlined">delete</span>
        {{ $t("templateEditor.toolbar.delete") }}
      </button>
    </div>
  </div>
  <div class="toolbar_footer" @click="submit">
    <div class="button primary" :class="{ disabled: !hasChanges }">
      {{ $t("templateEditor.toolbar.save") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar_body {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  height: calc(100vh - 60px - 60px - 82px);
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.toolbar_footer {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}

.label_container {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}

.label_container label {
  flex: 1;
  font-weight: 500;
}

.label_container .link {
  font-weight: normal;
}

.row {
  margin-bottom: 1.5em;
}

.row.bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.image {
  background: #e0e0e0;
  height: 70px;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

input[type="checkbox"] {
  margin-right: 0.5em;
}

p.help {
  font-size: 0.8em;
  color: #f14668;
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  height: 0.8em;
}
</style>
