<template>
  <article>
    <header><img class="logo" :src="brandURL" /></header>
    <main>
      <h1>{{ $t("codeInput.header") }}</h1>
      <form class="pin" novalidate>
        <!--
        use numeric keyboard on mobile
        https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
        -->
        <input
          v-for="(d, i) in digits"
          :key="`digit-${i}`"
          :ref="addDigitField"
          v-model="digits[i]"
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          maxlength="1"
          @focus="selectOnFocus"
          @input="selectNext($event as InputEvent)"
          @keyup.backspace="selectPrevious"
          @keyup.left="selectPrevious"
          @keyup.enter="startPresentation()"
        />
      </form>
      <div v-if="codeInvalid">Code ungültig oder abgelaufen</div>
      <button :disabled="!presentationId" @click.prevent="startPresentation()">
        {{ $t("codeInput.connect") }}
      </button>
    </main>
  </article>
</template>

<script lang="ts" setup>
import { reactive, computed, ref, onMounted, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ComponentPublicInstance } from '@vue/runtime-core'
import {
  collection,
  where,
  query,
  getDocs,
  doc,
  getDoc,
  CollectionReference,
  DocumentSnapshot,
} from 'firebase/firestore'
import { db } from '@/fire'
import { Presentation } from '@/models'
import pitchviewLogo from '@/assets/images/pitchview_logo.png'
import { getStorageProxyUrl } from '@/util'

const VALID_TIMERANGE = 86400 * 1000 // 24h in milliseconds
const router = useRouter()
const route = useRoute()
const digits = ref(['', '', '', ''])
const digitFields = reactive<HTMLInputElement[]>([])
const presentationSnapshot = ref<DocumentSnapshot<Presentation>>()
const presentation = computed(() => presentationSnapshot.value?.data())
const presentationId = computed(() => presentationSnapshot.value?.id)
const pin = computed(() => digits.value.join(''))
const codeComplete = computed(() => digits.value.every((d) => d.trim()))
const loading = ref(false)
const codeInvalid = computed(
  () => codeComplete.value && !loading.value && !presentation.value
)

const loadingBrand = ref(false)
const brand = ref<{ brandURL?: string }>()
const brandURL = computed(() => {
  if (brand.value?.brandURL) return getStorageProxyUrl(brand.value.brandURL)
  else if (loadingBrand.value) return undefined
  else return pitchviewLogo
})

const addDigitField = (ref: Element | ComponentPublicInstance | null) => {
  if (!digitFields.includes(ref as HTMLInputElement))
    digitFields.push(ref as HTMLInputElement)
}

watchEffect(async () => {
  if (!route.hash?.trim()) brand.value = undefined
  else
    try {
      loadingBrand.value = true
      const snapshot = await getDoc(
        doc(db, 'brands', route.hash.substring(1))
      )
      brand.value = snapshot.data()
    } finally {
      loadingBrand.value = false
    }
})

onMounted(() => digitFields[0]?.focus())

const selectOnFocus = (event: Event) => {
  ;(event.target as HTMLInputElement).select()
}

const selectNext = (event: InputEvent) => {
  if (event.inputType !== 'insertText') return
  const currentIndex = digitFields.indexOf(event.target as HTMLInputElement)
  digitFields[currentIndex + 1]?.focus()
}

const selectPrevious = (event: KeyboardEvent) => {
  const currentIndex = digitFields.indexOf(event.target as HTMLInputElement)
  digitFields[currentIndex - 1]?.focus()
}

const startPresentation = () => {
  const id = presentationId.value
  const target = `/presentation/${id}`
  if (id) router.push({ path: target })
}

watchEffect(async () => {
  if (!codeComplete.value) {
    presentationSnapshot.value = undefined
    return
  }

  try {
    loading.value = true
    const { size, docs } = await getDocs(
      query(
        collection(
          db,
          'presentations'
        ) as CollectionReference<Presentation>,
        where('code', '==', pin.value),
        where('uploadedAt', '>', new Date(Date.now() - VALID_TIMERANGE))
      )
    )

    if (size > 0) presentationSnapshot.value = docs[0]
    else presentationSnapshot.value = undefined
  } finally {
    loading.value = false
  }
})
</script>

<style scoped>
article {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content auto;
  justify-items: stretch;
  align-items: stretch;
  background-color: white;
  /*background-color: #eff1f4;*/
}

header {
  padding: 10px 20px;
  border-bottom: 1px solid #d0d4d9;
}

header img.logo {
  height: 30px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

main h1 {
  font-size: 2rem;
  font-weight: normal;
}

main button,
main .button {
  background: #0066cc;
  color: white;
  border: none;
  padding: 0.8em 1.2em;
  border-radius: 4px;
  font-size: 1rem;
  -webkit-appearance: none;
}

.button:disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.pin {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

input {
  width: 40px;
  box-sizing: content-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1.1rem;
  text-align: center;
}
</style>
