import { ref, reactive, watch, Ref, computed, isRef, readonly } from 'vue'

function isEqual(a: any, b: any) {
  // Check if both are the same object
  if (a === b) return true;

  // Check if they are not objects (this covers null as well)
  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false;
  }

  // Compare array lengths
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;

    // Compare each element in the array
    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) return false;
    }
    return true;
  }

  // Compare object keys and values
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) return false;

  for (let key of aKeys) {
    // Check if both objects have the same keys
    if (!b.hasOwnProperty(key)) return false;

    // Recursively compare values
    if (!isEqual(a[key], b[key])) return false;
  }

  return true;
}

import {
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  DocumentReference,
  Firestore,
} from 'firebase/firestore'
import { Unsubscribe } from 'firebase/auth'

export const reactiveDoc = <T>(
  firestore: Firestore,
  path: string | Ref<string> | (() => string)
) => {
  const exists = ref<boolean>()
  const document = reactive<Partial<T>>({})
  const documentReferenceRef = computed(
    () =>
      doc(
        firestore,
        typeof path === 'string' ? path : isRef(path) ? path.value : path()
      ) as DocumentReference<T>
  )

  let unsubscribe: Unsubscribe

  // incoming changes
  watch(
    documentReferenceRef,
    (documentReference) => {
      if (unsubscribe) unsubscribe()
      unsubscribe = onSnapshot(documentReference, (snapshot) => {
        const snapshotExists = snapshot.exists()
        exists.value = snapshotExists
        if (snapshotExists) {
          const properties = Object.getOwnPropertyNames(document) as Array<
            keyof T
          >
          properties.forEach((property) => {
            delete (document as T)[property]
          })
          Object.assign(document, snapshot.data())
        } else {
          const properties = Object.getOwnPropertyNames(document) as Array<
            keyof T
          >
          properties.forEach((property) => {
            delete (document as T)[property]
          })
        }
      })
    },
    {
      immediate: true,
    }
  )

  watch(
    document,
    async () => {
      const currentSnapshop = await getDoc(documentReferenceRef.value)
      if (!isEqual(currentSnapshop.data(), document))
        await setDoc(documentReferenceRef.value, document as T, {
          merge: false,
        })
    },
    {
      deep: true,
    }
  )

  return { exists: readonly(exists), document }
}
