<script lang="ts" setup>
import {
  useDocumentStatisticsQuery,
  MaDocumentStatistic,
  MaDocumentStatisticsOrderBy
} from "@/graphql/operations";
import { computed, ref, unref } from "vue";
import { useI18n } from "vue-i18n";
import DocumentStatsCard from "./DocumentStatsCard.vue";
import { Dropdown, LoadingSpinner, Search } from "@/components";

const { t } = useI18n({});

const orderByValues = [
  {
    value: "NUM_VISITS_DESC",
    title: t("documentAnalytics.orderBy.numVisits")
  },
  {
    value: "NUM_SHARES_DESC",
    title: t("documentAnalytics.orderBy.numShares")
  }
];

const orderBy = ref<string>("NUM_VISITS_DESC");

const limitStep = 10;
const limit = ref<number>(limitStep);
const search = ref<string>("");

const { fetching, data, executeQuery } = useDocumentStatisticsQuery({
  variables: computed(() => { return {
    orderBy: [unref(orderBy) as MaDocumentStatisticsOrderBy],
    first: limit,
    search: unref(search)
  } }),
  requestPolicy: "cache-and-network"
});
const stats = computed(() => data.value?.maDocumentStatistics?.nodes);
const totalCount = computed(() => data.value?.maDocumentStatistics?.totalCount);

const setOrderBy = (value: string) => {
  orderBy.value = value;
  limit.value = limitStep;
}

const loadMore = () => {
  limit.value += limitStep;
}

const filterDocuments = (searchStr: string) => {
  search.value = searchStr;
  executeQuery();
}
</script>

<template>
  <div class="pane page_content">
    <div class="title_container">
      <h3>{{ $t("documentAnalytics.title") }}</h3>

      <div class="control">
        <Search
          :placeholder="$t('documentAnalytics.search')"
          v-model="search"
          @submit="filterDocuments"
          @enter="filterDocuments"
          @clear="filterDocuments"
        />

        <label>{{ $t("documentAnalytics.orderBy.label") }}</label>
        <Dropdown
          :items="orderByValues"
          :value="orderBy"
          :displayTitle="true"
          @change="setOrderBy" />
      </div>
    </div>

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-else-if="stats && stats.length > 0" class="content">
      <document-stats-card v-for="doc in stats" :key="doc!.documentId!" :doc="doc as MaDocumentStatistic" />

      <div class="link_container" v-if="totalCount && totalCount > limit">
        <a class="link" @click="loadMore">{{ $t("documentAnalytics.displayMore") }}</a>
      </div>
    </div>

    <div v-else-if="search.length > 0" class="empty_pane">
      <div>
        <span class="material-symbols-outlined">
          search
        </span>

        <h2>{{ $t("documentAnalytics.emptySearch.title") }}</h2>
        <p class="info">
          {{ $t("documentAnalytics.emptySearch.info") }}
        </p>
      </div>
    </div>

    <div v-else class="empty_pane">
      <div>
        <span class="material-symbols-outlined">
          analytics
        </span>

        <h2>{{ $t("documentAnalytics.empty.title") }}</h2>
        <p class="info">
          {{ $t("documentAnalytics.empty.info") }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title_container {
  display: flex;
}

.title_container h3 {
  flex: 1;
}

.title_container .control {
  font-size: .9rem;
  display: flex;
  align-items: center;
}

.title_container .control label {
  margin: 0 .5em 0 1.5em;
  white-space: nowrap;
}

.page_content {
  height: 100%;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.content {
  padding-bottom: 3.5em;
}

.link_container {
  margin-top: 1.8em;
  display: flex;
  justify-content: center;
}

.empty_pane {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
}

.empty_pane > div {
  flex: none;
  text-align: center;
  margin-top: 10%;
}

.empty_pane .material-symbols-outlined {
  font-size: 6em;
  opacity: .4;
}

.empty_pane h2 {
  margin-bottom: 0;
}

.empty_pane p {
  margin: 0.6em 0 1.5em 0;
}
</style>
