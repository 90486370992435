<template>
  <article :class="{ 'bg-black': isVideo }">
    <header v-if="inFullscreen" />
    <header v-else>
      <img class="logo" :src="brandURL" />
    </header>
    <template v-if="!presentationExists">
      <main />
      <teleport to="#overlays">
        <div class="overlay shout">{{ $t("loading") }}</div>
      </teleport>
    </template>
    <template v-else>
      <!-- Beendet Overlay -->
      <teleport v-if="presentation?.code === 'invalid'" to="#overlays">
        <div class="overlay">
          <div class="shout">{{ $t("presentation.stopped.title") }}</div>
          <router-link to="/connect" class="button">
            {{ $t("presentation.stopped.newCode") }}
          </router-link>
        </div>
      </teleport>

      <!-- Pausiert Overlay -->
      <teleport v-else-if="presentation.isPaused" to="#overlays">
        <div class="overlay shout">{{ $t("presentation.paused.title") }}</div>
      </teleport>

      <!-- Video -->
      <template v-if="isVideo">
        <video-player
          v-if="normalizedUrl && presentation.video"
          ref="video"
          :src="normalizedUrl"
          :state="presentation.video.state"
          :position="videoPosition"
          :laserpointer="laserpointer"
          :type="presentation.mimeType ?? ''"
          @canplaythrough="acknowledgeUpdateIfInSync()"
          @state-changed="video = $event"
          @waiting="videoCanPlay = false"
          @ended="videoCanPlay = false"
        >
          <template #allowButton="{ allow, videoBlocked }">
            <div v-if="videoBlocked" class="overlay">
              <button @click="allow()">
                {{ $t("presentation.enableVideo.label") }}
              </button>
            </div>
          </template>
          <template #muteButton="{ mute, unmute, muted }">
            <teleport to="#footer-button-bar">
              <button @click="muted ? unmute() : mute()">
                <span v-if="muted" class="material-symbols-outlined">volume_off</span>
                <span v-else class="material-symbols-outlined">volume_up</span>
              </button>
            </teleport>
          </template>
          <template #unmuteButton />
        </video-player>
      </template>

      <!-- PDF -->
      <!--

        :key="`viewer-for-${presentation.url}`"
      -->
      <pdf-js-presenter
        v-else-if="normalizedUrl && presentation.page"
        style="grid-row: 2 / 3"
        ref="viewer"
        class="this__pdf-viewer"
        :document="normalizedUrl"
        :page="presentation.page"
        :zoom="zoom"
        :laserpointer="laserpointer"
        :enable-annotations="presentation.linksCanBeClickedByViewer"
        @rendered="renderedPage = $event"
        @loaded="loadedUrl = $event?.toString()"
        @update:number-of-pages="pageCount = $event"
      />

      <!-- Nicht unterstütztes Format
      <div v-else class="overlay shout">Präsentation nicht gefunden</div>
      -->
    </template>
    <footer
      id="footer-button-bar"
      :class="{ 'bg-black': isVideo, floating: isVideo }"
      style="grid-row: 3"
    >
      <template v-if="!isVideo">
        <button :disabled="presentation.isViewOnly" @click="decrement()">
          <span class="material-symbols-outlined"> arrow_back </span>
        </button>
        <div v-if="presentation && !presentation.isViewOnly && isPdf">
          <span style="color: black">
            {{ presentation.page }} / {{ pageCount }}
          </span>
        </div>
        <button :disabled="presentation.isViewOnly" @click="increment()">
          <span class="material-symbols-outlined"> arrow_forward </span>
        </button>
      </template>
      <button id="Fullscreen">
        <span class="material-symbols-outlined" @click="fullscreen()"> fullscreen </span>
      </button>
    </footer>
  </article>
</template>

<script lang="ts" setup>
import { Unsubscribe } from "@firebase/util";
import { onValue, ref as firebaseRef } from "firebase/database";
import { FieldValue, serverTimestamp } from "firebase/firestore";
import { computed, ref, watch, onBeforeUnmount, nextTick } from "vue";
import { PdfJsPresenter } from "@/components";
import VideoPlayer from "./VideoPlayer.vue";
import { db, realtimeDb } from "@/fire";
import { Presentation, Point, Rect } from "@/models";
import pitchviewLogo from "@/assets/images/pitchview_logo.png";
import { getStorageProxyUrl, getSessionId, reactiveDoc } from "@/util";
import { useFullscreen, useThrottleFn } from "@vueuse/core";
import "@/assets/overlays.css";

const props = defineProps({
  presentationId: {
    type: String,
    required: true,
  },
});

export interface Audience {
  lastReceivedUpdateId: string;
  lastAppliedUpdateId?: string;
  tabState?: "hidden" | "visible" | "closed";
  video?: null | {
    state: "PAUSED" | "SEEKED" | "PLAYING" | "ENDED" | "WAITING" | null;
    stateChangedAt: FieldValue;
    stateChangedAtPosition: number | null;
  };
}

let unsubscribeZoomListener: Unsubscribe | null = null;
let unsubscribeLaserpointerListener: Unsubscribe | null = null;

const viewer = ref<HTMLDivElement>();
const { toggle: fullscreen, isFullscreen: inFullscreen } =
  useFullscreen(viewer);

const { document: presentation, exists: presentationExists } =
  reactiveDoc<Presentation>(db, () => `presentations/${props.presentationId}`);

const { document: audience } = reactiveDoc<Audience>(
  db,
  () => `presentations/${props.presentationId}/audience/${getSessionId()}`
);

const zoom = ref<Rect>({ top: 0, left: 0, bottom: 0, right: 0 });
const laserpointer = ref<Point | undefined>();
const videoCanPlay = ref<boolean>();
const video = ref<{
  state: "PLAYING" | "PAUSED" | "SEEKED" | "ENDED" | "WAITING";
  position: number | null;
}>();
const pageCount = ref<number>();

const brandURL = computed(() => {
  return presentation.brandURL
    ? getStorageProxyUrl(presentation.brandURL)
    : pitchviewLogo;
});

const isPdf = computed(() => {
  switch (presentation.mimeType) {
    case "application/pdf":
    case undefined:
      return true;
    case null:
    default:
      return false;
  }
});

const isVideo = computed(() => {
  switch (presentation.mimeType) {
    case "application/mp4":
    case "video/mp4":
      return true;
    default:
      return false;
  }
});

let idCounter = 0;

// increment / decrement
const increment = () => {
  const currentPage = presentation.page ?? 1;
  const nextPage = Math.min(currentPage + 1, pageCount.value ?? 1);
  if (currentPage !== nextPage) {
    console.log(`increment from ${currentPage} to ${nextPage}`);
    presentation.page = nextPage;
    presentation.lastPageUpdateId = (idCounter++).toString();
  }
};

const decrement = () => {
  const currentPage = presentation.page ?? 1;
  const nextPage = Math.max(currentPage - 1, 1);
  if (currentPage !== nextPage) {
    console.log(`decrement from ${currentPage} to ${nextPage}`);
    presentation.page = nextPage;
    presentation.lastPageUpdateId = (idCounter++).toString();
  }
};

// sync features
const receivedUpdateId = ref<string>();
const loadedUrl = ref<string>();
const renderedPage = ref<number>();

const isInSync = computed<boolean>(() => {
  if (!presentationExists.value) {
    return false;
  }

  if (isPdf.value) {
    return (
      receivedUpdateId.value === presentation.lastPageUpdateId &&
      loadedUrl.value === getStorageProxyUrl(presentation.url || "") &&
      renderedPage.value !== undefined &&
      presentation.page !== undefined &&
      renderedPage.value >= presentation.page
    );
  } else if (isVideo.value) {
    const videoInPresentation = presentation.video;
    const currentVideo = video.value;
    if (!videoInPresentation) {
      // no current command, so can't be in sync
      return receivedUpdateId.value === presentation.lastPageUpdateId;
    } else {
      return (
        // compare ids
        receivedUpdateId.value === presentation.lastPageUpdateId &&
        // compare state
        (videoInPresentation?.state === currentVideo?.state ||
          (videoInPresentation?.state === "SEEK" &&
            currentVideo?.state === "SEEKED") ||
          // in seek and paused state, we accept comparable times to advance update id
          (["SEEK", "PAUSED"].includes(videoInPresentation?.state) &&
            typeof currentVideo?.position === "number" &&
            Math.abs(
              videoInPresentation.stateChangedAtPosition - currentVideo.position
            ) < 0.2))
      );
    }
  } else {
    console.warn("this presentation neither isPdf, nor isVideo");
    return false;
  }
});

const receivedUpdate = async () => {
  if (presentation.lastPageUpdateId) {
    receivedUpdateId.value = presentation.lastPageUpdateId;
    audience.lastReceivedUpdateId = presentation.lastPageUpdateId;
  }
};

const acknowledgeUpdateIfInSync = () => {
  const applicableUpdateId = presentation.lastPageUpdateId;
  if (
    presentation.lastPageUpdateId &&
    isInSync.value &&
    audience.lastAppliedUpdateId !== applicableUpdateId
  ) {
    audience.lastAppliedUpdateId = applicableUpdateId;
  }
};

watch(
  () => presentation.lastPageUpdateId,
  () => {
    receivedUpdate();
    acknowledgeUpdateIfInSync();
  }
);

watch(presentation, acknowledgeUpdateIfInSync, { deep: true });

watch(
  [videoCanPlay, video, renderedPage, loadedUrl, isInSync],
  acknowledgeUpdateIfInSync
);

watch(video, (currentVideo) => {
  if (currentVideo)
    audience.video = {
      state: currentVideo.state ?? null,
      stateChangedAt: serverTimestamp(),
      stateChangedAtPosition: currentVideo.position ?? null,
    };
  else audience.video = null;
  acknowledgeUpdateIfInSync();
});

// video features

const normalizedUrl = computed(() => {
  const url = presentation.url;
  if (!url) {
    return undefined;
  } else if (url.toLowerCase().trim() === "placeholder") {
    return undefined;
  } else {
    return getStorageProxyUrl(url);
  }
});

const throttledZoomUpdate = useThrottleFn(
  (snapshot) => {
    zoom.value = snapshot.val() as Rect;
  },
  80,
  true
);

const throttledLaserpointerUpdate = useThrottleFn(
  (snapshot) => {
    laserpointer.value = snapshot.val() as Point;
  },
  20,
  true
);

watch(
  () => props.presentationId,
  (presentationId) => {
    // listen for zoom changes
    const zoomRef = firebaseRef(
      realtimeDb,
      `/presentations/${presentationId}/zoom`
    );

    if (unsubscribeZoomListener) unsubscribeZoomListener();
    unsubscribeZoomListener = onValue(zoomRef, throttledZoomUpdate);

    // listen for laserpointer changes
    const laserpointerRef = firebaseRef(
      realtimeDb,
      `/presentations/${presentationId}/laserpointer`
    );

    if (unsubscribeLaserpointerListener) unsubscribeLaserpointerListener();
    unsubscribeLaserpointerListener = onValue(
      laserpointerRef,
      throttledLaserpointerUpdate
    );
  },
  { immediate: true }
);

const videoPosition = ref(0);
const intervalId = setInterval(() => {
  if (!presentation.video) {
    return;
  }

  if (presentation.video.state !== "PLAYING") {
    videoPosition.value = presentation.video.stateChangedAtPosition;
    return;
  }

  const timeSinceStateChange =
    Math.floor(Date.now() / 1000) - presentation.video.stateChangedAt.seconds;
  const position =
    presentation.video.stateChangedAtPosition + timeSinceStateChange;
  nextTick(() => {
    videoPosition.value = position;
  });
}, 5000);

const visibilityChangeHandler = () => {
  if (document.visibilityState === "hidden") {
    audience.tabState = "hidden";
  } else {
    audience.tabState = "visible";
  }
}

document.addEventListener("visibilitychange", visibilityChangeHandler);

onBeforeUnmount(() => {
  clearInterval(intervalId);
  document.removeEventListener("visibilitychange", visibilityChangeHandler);
})
</script>

<style scoped>
.this__pdf-viewer {
}

.bg-black {
  background-color: black;
}

.floating {
  position: fixed;
  background-color: transparent;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}

article {
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  justify-items: stretch;
  align-items: stretch;
  background-color: #eff1f4;
  position: relative;
}

article header {
  padding: 10px 20px;
}

#prevent-clicks {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(255, 0, 0, 0.2); */
  z-index: 100;
}

article footer {
  padding: 1rem;
}

header img.logo {
  height: 30px;
}

footer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

footer button,
footer .button {
  padding: 0.35rem;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  padding: 0.35rem 1rem;
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  min-height: 1.5em;
  font-size: 1rem;
  color: #363636;
  -webkit-appearance: none;
}

footer button:disabled,
footer .button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}
</style>
