<script lang="ts" setup>
import { ref } from "vue";
import { Modal } from "@/components";

const emits = defineEmits(["close", "submit"]);

const visible = ref(false);
const translationKey = ref("");
let resolveCallback: ((value: boolean) => void) | null = null;

function show(key: string) {
  translationKey.value = key;
  visible.value = true;

  return new Promise((resolve) => {
    resolveCallback = resolve;
  });
}

function confirmDelete() {
  visible.value = false;
  if (resolveCallback) resolveCallback(true);
}

function cancelDelete() {
  visible.value = false;
  if (resolveCallback) resolveCallback(false);
}

defineExpose({ show });
</script>

<template>
  <modal :active="visible" @close="cancelDelete">
    <template #header>
      {{ $t(`${translationKey}.title`) }}
    </template>

    <template #body>
      {{ $t(`${translationKey}.message`) }}
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="confirmDelete">
          {{ $t("deleteConfirmation.submit") }}
        </button>
        <button class="button" @click="cancelDelete">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </modal>
</template>
