<script lang="ts" setup>
import {
  LoadingSpinner,
  Modal,
  Tooltip,
  Search,
  UpgradeModal,
  CreateDocumentSpaceModal,
} from "@/components";
import { ref, reactive, computed } from "vue";
import {
  useDocumentSpacePickerQuery,
  type DocumentSpace,
} from "@/graphql/operations";
import { useSpaceModalDisplay } from "@/composables";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  documentId: {
    type: String,
    required: false,
  },
});

const {
  displayCreateDocumentSpaceModal,
  displayUpgradeModal,
  handleModalDisplay,
} = useSpaceModalDisplay();

const emit = defineEmits([
  "close",
  "submit",
  "errorCreatingSpace",
  "errorInvalidInput",
]);
const emitClose = () => emit("close");
const emitSubmit = () => {
  emit("submit", selectedSpaces.value);
};

enum State {
  ADD_TO_EXISTING,
  ADD_TO_NEW,
}

const currentState = ref<State>(State.ADD_TO_EXISTING);

const search = ref<string>("");
const setSearch = (value: string) => {
  search.value = value;
};

const { data, fetching, executeQuery } = useDocumentSpacePickerQuery({
  variables: reactive({
    search: search,
    documentId: props.documentId ?? null,
  }),
  requestPolicy: "cache-and-network",
});

const newSpaces = ref<Array<string>>([]);

const handleNewSpace = async (spaceId: string) => {
  await executeQuery();
  newSpaces.value.push(spaceId);
  currentState.value = State.ADD_TO_EXISTING;
  selectedSpaces.value.push(spaceId);
};

const changeState = async () => {
  await handleModalDisplay("create");
  if (displayCreateDocumentSpaceModal.value) {
    currentState.value =
      currentState.value === State.ADD_TO_NEW
        ? State.ADD_TO_EXISTING
        : State.ADD_TO_NEW;

    search.value = "";
  }
};

const selectedSpaces = ref<Array<string>>([]);
const toggleSpace = (spaceId: string) => {
  if (selectedSpaces.value.includes(spaceId)) {
    selectedSpaces.value.splice(selectedSpaces.value.indexOf(spaceId), 1);
  } else {
    selectedSpaces.value.push(spaceId);
  }
};

const isDisabled = (space: DocumentSpace) => {
  return space.documentSpaceItems!.nodes.length > 0;
};

const toggleAllItemsSelection = () => {
  if (allItemsSelected.value) {
    selectedSpaces.value = [];
  } else {
    selectedSpaces.value = data
      .value!.documentSpaces!.nodes.filter(
        (space): space is DocumentSpace => space !== null
      )
      .filter((space) => !isDisabled(space))
      .map((space) => space.id);
  }
};

const allItemsSelected = computed(() =>
  data.value?.documentSpaces?.nodes
    .filter((space): space is DocumentSpace => space !== null)
    .every(
      (space: DocumentSpace) =>
        isDisabled(space) || selectedSpaces.value.includes(space.id)
    )
);
</script>

<template>
  <Modal
    :active="true"
    class="content_scroll medium_large"
    @close="emitClose"
    v-if="currentState === State.ADD_TO_EXISTING"
  >
    <template v-slot:header>
      {{ $t("documentSpacePicker.header") }}
    </template>

    <template v-slot:body>
      <div class="body_container">
        <div class="controls">
          <Search
            :placeholder="$t('documentSpacePicker.search.placeholder')"
            @change="setSearch"
            @submit="setSearch"
          />

          <button class="button primary right" @click="changeState">
            <span class="material-symbols-outlined"> add </span>
            {{ $t("documentSpacePicker.createNew.buttonText") }}
          </button>
        </div>
        <div v-if="fetching" class="loading_status">
          <LoadingSpinner :color="'black'" />
        </div>
        <div
          v-else-if="
            data?.documentSpaces?.nodes &&
            data?.documentSpaces?.nodes.length > 0
          "
          class="results"
        >
          <div class="container">
            <input
              type="checkbox"
              @change="toggleAllItemsSelection"
              :checked="allItemsSelected"
            />
          </div>

          <ul>
            <li
              v-for="space in data.documentSpaces.nodes"
              :key="space!.id!"
              :class="{ disabled: isDisabled(space as DocumentSpace) }"
              @click.stop="
                !isDisabled(space as DocumentSpace) && toggleSpace(space!.id)
              "
            >
              <Tooltip class="left">
                <template v-slot>
                  <div class="flex_container">
                    <input
                      type="checkbox"
                      :value="space!.id"
                      :checked="selectedSpaces.includes(space!.id) || isDisabled(space as DocumentSpace)"
                      :disabled="isDisabled(space as DocumentSpace)"
                    />
                    {{ space!.name! }}
        <span v-if="store.getters.numSpaceConfigs > 1" class="badge">
          {{ space!.config?.name }}
        </span>
                  </div>
                </template>
                <template
                  v-if="space!.documentSpaceItems!.nodes.length > 0"
                  #tooltip
                >
                  {{ $t("documentSpacePicker.alreadySelected") }}
                </template>
              </Tooltip>

              <label v-if="newSpaces.includes(space!.id)" class="label">
                {{ $t("new") }}
              </label>
            </li>
          </ul>
        </div>
        <div v-else class="info">
          <div>
            {{ $t("documentSpacePicker.emptyList") }}
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <label v-if="selectedSpaces.length > 0">
        {{
          $t("documentSpacePicker.submitLabel", {
            count: selectedSpaces.length,
          })
        }}</label
      >
      <div class="buttons">
        <button class="button left" @click="emitClose">
          {{ $t("cancel") }}
        </button>
        <button
          class="button primary"
          :class="{ disabled: selectedSpaces.length === 0 }"
          @click="emitSubmit"
        >
          {{ $t("submit") }}
        </button>
      </div>
    </template>
  </Modal>

  <CreateDocumentSpaceModal
    v-else-if="currentState === State.ADD_TO_NEW"
    :redirect="false"
    @success="handleNewSpace"
    @error="emit('errorCreatingSpace')"
  >
    <template #header>
      <span
        class="material-symbols-outlined"
        @click="currentState = State.ADD_TO_EXISTING"
      >
        arrow_back
      </span>
    </template>
  </CreateDocumentSpaceModal>

  <upgrade-modal
    v-if="displayUpgradeModal"
    @close="displayUpgradeModal = false"
  />
</template>

<style scoped lang="scss">
.results {
  height: calc(100% - 60px);
  overflow: scroll;
  font-size: 0.9rem;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

ul li {
  line-height: 2.5;
  border-top: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  padding: 0.3em 0;
}

ul li:last-child {
  border-bottom: none;
}

ul li:hover {
  cursor: pointer;
  background: #f9fafb;
}

ul li.disabled:hover {
  cursor: initial;
}

input[type="checkbox"] {
  font-size: 0.9em;
  margin-right: 0.5em;
}

.controls {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.controls .button {
  margin-left: auto;
}

label {
  font-size: 0.9rem;
  line-height: 2;
}
.body_container {
  height: 100%;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}

.label {
  text-transform: uppercase;
  color: $color-secondary;
  margin-left: auto;
  padding-right: 0.3em;
}

.info {
  font-size: 0.9rem;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.container {
  padding: 0.3em 0 0.5em 0;
}

.badge {
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  background: #d6fafb;
  font-size: 0.7em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-left: 0.5em;
}
</style>
