<template>
  <flash-message
    v-if="flashMessageActive"
    :state="resultState === 'error' ? 'error' : ''"
  >
    {{ $t(`customFlows.newFlowCreated.${resultState}`)}}
  </flash-message>

  <Modal :active="modalActive" @close="emits('close')" data-test="create-flow-modal">
    <template v-slot:header>
      {{ $t("customFlows.newFlowCreated.header") }}
    </template>

    <template v-slot:body>
      <div>
        <label class="label">
          {{ $t("customFlows.newFlowCreated.inputLabel") }}
        </label>
        <input class="input" type="text" v-model="title" ref="input_title" @keyup.enter="submit" />
      </div>
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button
          :class="{
            button: true,
            primary: true,
            disabled: title.length === 0
          }"
          @click="submit"
        >
          <span class="material-symbols-outlined" data-v-ced23842="">save</span>
          {{ $t("customFlows.newFlowCreated.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Modal, FlashMessage } from "@/components";
import { onMounted, ref } from "vue";
import { useCreateCustomFlowMutation } from "@/graphql/operations";

const emits = defineEmits(["close", "success"]);

const title = ref<string>("");
const flashMessageActive = ref<boolean>(false);
const modalActive = ref<boolean>(true);
const resultState = ref<string>("");

const { executeMutation } = useCreateCustomFlowMutation();
const submit = async() => {
  const result = await executeMutation({
    title: title.value.trim()
  });

  if (result.error) {
    resultState.value = "error";
  } else {
    const id = result.data?.createFlow?.flow?.id;
    emits("success", id);
    resultState.value = "success";
  }

  modalActive.value = false;
  flashMessageActive.value = true;
  window.setTimeout(() => (emits("close")), 2000);
}

const input_title = ref<HTMLInputElement>();
onMounted(() => {
  if (!input_title.value) {
    return;
  }

  input_title.value!.focus();
})
</script>

<style scoped>
label {
  font-size: .9em;
  display: block;
  line-height: 2em;
}
</style>
