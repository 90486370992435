import { v4 as uuid4 } from 'uuid'

export const getSessionId = (): string => {
  let sessionId = sessionStorage.getItem('sessionId')
  if (!sessionId) {
    sessionId = uuid4()
    sessionStorage.setItem('sessionId', sessionId)
  }
  return sessionId
}
