<script lang="ts" setup>
import { computed } from "vue";
import { useAllDocumentSpaceConfigsQuery } from "@/graphql/operations";
import { Formatter } from "@/util";

const { data, fetching } = useAllDocumentSpaceConfigsQuery();
const configs = computed(() => {
  const teams = data.value?.myAdministratedTeams?.nodes;
  if (teams) {
    return teams.flatMap(t => t?.documentSpaceConfigs?.nodes);
  }
  return []; 
});
</script>

<template>
    <div v-if="!configs && fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <template v-else-if="configs && configs.length > 0">
      <div class="title_container">
        <h4>{{ $t("templates.title") }}</h4>
        <div class="controls">
          <div class="control"></div>
        </div>
      </div>
      <div>
        <table class="data_table clickable">
          <thead>
            <th>{{ $t("templates.columns.name") }}</th>
            <th>{{ $t("templates.columns.team") }}</th>
            <th>{{ $t("templates.columns.lastModified") }}</th>
          </thead>
          <tbody>
            <tr v-for="config in configs" :key="config!.id">
              <td>
                <router-link
                  :to="{
                    name: 'TemplateEditor',
                    params: { id: config!.id },
                  }"
                >
                  {{ config!.name }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'TemplateEditor',
                    params: { id: config!.id },
                  }"
                >
                  {{ config!.team?.name }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'TemplateEditor',
                    params: { id: config!.id },
                  }"
                >
                  {{
                    Formatter.formatDate(
                      new Date(config!.updatedAt),
                      "%Y-%m-%d %H:%M"
                    )
                  }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </template>
</template>

<style scoped>
.title_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
}

.title_container h4 {
  font-weight: 500;
  flex: 1;
  margin: 0;
}

button {
  font-size: 0.9em;
}

.pane {
  height: 100%;
}

.page_content {
  height: 100%;
}

.empty_pane {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
}

.empty_pane > div {
  flex: none;
  text-align: center;
  margin-top: 5em;
}

.empty_pane h2 {
  margin-bottom: 0;
}

.empty_pane p {
  margin: 0.6em 0 1.5em 0;
}

table.data_table {
}

table a {
  text-decoration: none;
  width: 100%;
  color: inherit;
  font-weight: normal;
  display: inline-block;
}

table th {
  background: #f2f2f2;
}
</style>
