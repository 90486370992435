<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import {
  useCreateDocumentSpaceMutation,
  CrmContact,
} from "@/graphql/operations";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal, ContactPicker } from "@/components";
import { useFlashMessage } from "../hooks/useFlashMessage";
import { useI18n } from "vue-i18n";

const { displayFlashMessage } = useFlashMessage();
const { t } = useI18n();

const store = useStore();
const router = useRouter();

const emit = defineEmits(["close", "success", "error"]);

const props = defineProps({
  redirect: {
    type: Boolean,
    default: true,
  },
});

const withCRMIntegration = computed(() =>
  store.getters.user.hasCRMIntegration()
);

const isCreatingDocumentSpace = ref<boolean>(false);
const inputName = ref();
const name = ref("");

enum STEPS {
  SELECT_CONTACT = "selectContact",
  INSERT_NAME = "insertName",
}

const currentStep = ref<STEPS>(
  withCRMIntegration.value ? STEPS.SELECT_CONTACT : STEPS.INSERT_NAME
);

onMounted(() => {
  if (!inputName.value) {
    return;
  }

  inputName.value.select();
});

const { executeMutation } = useCreateDocumentSpaceMutation();
const submit = async () => {
  if (name.value.length === 0) {
    return;
  }

  if (isCreatingDocumentSpace.value) {
    return; // skip multiple clicks in short timeframe
  } else {
    isCreatingDocumentSpace.value = true;
  }

  const result = await executeMutation({
    name: name.value,
    crmContactId: selectedContact.value?.id,
  });

  if (result.error) {
    emit("error");
  } else {
    const spaceId =
      result.data?.createDocumentSpaceWithCrmContact?.result?.documentSpace?.id;
    if (props.redirect) {
      displayFlashMessage(t("documentSpaces.successCreateNew"));
      router.push({ name: "DocumentSpace", params: { id: spaceId } });
    }

    emit("success", spaceId);
  }

  emit("close");

  // reset the flag after a short delay
  window.setTimeout(() => {
    isCreatingDocumentSpace.value = false;
  }, 1000);
};

const selectedContact = ref<CrmContact>();
const setSelectedContact = (contact: CrmContact, defaultName: string) => {
  selectedContact.value = contact;
  name.value = defaultName;
};

const continueWithSelection = () => {
  submit();
};

const skip = () => {
  selectedContact.value = undefined;
  name.value = "";
  currentStep.value = STEPS.INSERT_NAME;
};

const goBack = () => {
  selectedContact.value = undefined;
  name.value = "";
  currentStep.value = STEPS.SELECT_CONTACT;
};
</script>

<template>
  <Modal
    v-if="currentStep === STEPS.INSERT_NAME"
    :active="true"
    @close="emit('close')"
  >
    <template v-slot:header>
      <slot name="header">
      </slot>
      {{ $t(`createDocumentSpaceModal.header.${currentStep}`) }}
    </template>

    <template v-slot:body>
      <div>
        <label class="label">
          {{ $t("createDocumentSpaceModal.name") }}
        </label>
        <form @submit.prevent="submit">
          <input class="input" type="text" v-model="name" ref="inputName" />
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <div class="buttons">
        <button
          v-if="withCRMIntegration"
          class="button secondary"
          @click="goBack"
        >
          {{ $t("createDocumentSpaceModal.previousStep") }}
        </button>
        <button v-else @click="emit('close')" class="button secondary">
          {{ $t("cancel") }}
        </button>
        <button
          :class="{
            button: true,
            primary: true,
            disabled: name.length === 0,
          }"
          @click="submit"
        >
          <span class="material-symbols-outlined">save</span>
          {{ $t("submit") }}
        </button>
      </div>
    </template>
  </Modal>

  <Modal
    v-else
    :active="true"
    @close="emit('close')"
    class="medium_large"
    :scrolling="true"
  >
    <template v-slot:header>
      <slot name="header">
      </slot>
        {{ $t(`createDocumentSpaceModal.header.${currentStep}`) }}
    </template>

    <template v-slot:body>
      <contact-picker @change="setSelectedContact" @submit="submit" @close="emit('close')"/>
    </template>

    <template v-slot:footer>
      <div class="buttons">
        <button class="button secondary" @click="skip">
          <span class="material-symbols-outlined">save</span>
          {{ $t("createDocumentSpaceModal.skip") }}
        </button>
        <button
          :class="{
            button: true,
            primary: true,
            disabled: !selectedContact,
          }"
          @click="submit"
        >
          <span class="material-symbols-outlined">save</span>
          {{ $t("createDocumentSpaceModal.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
label {
  font-size: 0.9em;
  display: block;
  line-height: 2em;
}

.row {
  margin-bottom: 1em;
}

.contact_info {
  background-color: #fafafa;
  font-size: 0.9em;
  padding: 1em 0.5em;
}

.main_info {
  font-weight: 600;
}
</style>
