<template>
  <div class="gallery_container" ref="galleryContainerElem">
    <div class="gallery" ref="galleryElem">
      <template
        v-if="
          document &&
          document.documentRevisionByIdAndCurrentRevisionNumber
            ?.documentPagesByDocumentIdAndRevisionNumber &&
          document.documentRevisionByIdAndCurrentRevisionNumber
            .documentPagesByDocumentIdAndRevisionNumber.nodes
        "
      >
        <div
          v-for="page in normalPages"
          :key="page?.id"
          class="gallery_item"
          ref="galleryItems"
          :data-id="page?.pageNumber"
        >
          <div class="img_pagination_wrapper">
            <thumbnail
              v-if="page"
              :pageId="
                visiblePages.has(page.pageNumber) ? page.id : undefined
              "
              :class="{ active: currentPageNumber === page.pageNumber }"
              :draggable="false"
              @click="selectPage(page.pageNumber)"
              @dblclick="selectAndClose(page.pageNumber)"
            />
            <span>Page {{ page?.pageNumber }}</span>
          </div>
        </div>

        <span class="breaker"></span>

        <div
          v-for="page in magicPages"
          :key="page?.id"
          class="gallery_item"
          ref="galleryItems"
          :data-id="page?.pageNumber"
        >
          <div class="img_pagination_wrapper">
            <thumbnail
              v-if="page"
              :pageId="page.id"
              :draggable="false"
              @click="selectPage(page.pageNumber, page.pageType)"
            />
            <span>Page {{ page?.pageNumber }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { GetDocumentByFirestoreIdQuery, PageType } from "@/graphql/operations";
import { Thumbnail } from "@/components";

const THUMBNAIL_WIDTH = 350;

type Document = GetDocumentByFirestoreIdQuery["document"];

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  document: {
    type: Object as () => Document,
    required: true,
  },
  currentPageNumber: {
    type: Number,
    required: true,
  },
  scroll: {
    type: Number,
    required: true,
  },
});

const galleryItems = ref([]);

const visiblePages = ref(new Set());

const emits = defineEmits([
  "update:page",
  "closeGallery",
  "reopenOnPopoverClose",
]);

const normalPages = computed(() =>
  props.document?.documentRevisionByIdAndCurrentRevisionNumber?.documentPagesByDocumentIdAndRevisionNumber.nodes.filter(
    (page) => page!.pageType === PageType.Normal
  )
);
const magicPages = computed(() =>
  props.document?.documentRevisionByIdAndCurrentRevisionNumber?.documentPagesByDocumentIdAndRevisionNumber.nodes.filter(
    (page) => page!.pageType !== PageType.Normal
  )
);

const wasPreviousPopover = ref<boolean>(false);

const selectPage = (pageNumber: number, pageType?: string) => {
  if (pageType === PageType.Popover) {
    wasPreviousPopover.value = true;
    emits("reopenOnPopoverClose", galleryContainerElem.value?.scrollTop);
  } else {
    wasPreviousPopover.value = false;
  }

  emits("update:page", pageNumber);
};

const selectAndClose = (pageNumber: number) => {
  wasPreviousPopover.value = false;
  emits("update:page", pageNumber);
  emits("closeGallery");
};

const galleryContainerElem = ref<HTMLDivElement | null>(null);
const galleryElem = ref<HTMLDivElement | null>(null);

let numThumbnailsPerRow = 0;

const resizeGalleryWidth = (entries: ResizeObserverEntry[]) => {
  if (galleryContainerElem.value && galleryElem.value) {
    galleryElem.value.style.maxWidth = calculateGalleryWidth(
      entries[0].contentRect.width
    );
    scrollIntoView();
  }
};

const calculateGalleryWidth = (width: number): string => {
  // thumbnails per row = total width + 20 (last thumbnail right gap) / thumbnail width + 20 (border) + 20 (gap)
  numThumbnailsPerRow = Math.floor((width + 20) / (THUMBNAIL_WIDTH + 20 + 20));
  // gallerywidth = thumbnails per row * thumbnail width + 20 (border) + thumbnails per row - 1 (last in row) * 20 (gap)
  const galleryWidth =
    Math.floor(numThumbnailsPerRow * (THUMBNAIL_WIDTH + 20)) +
    (numThumbnailsPerRow - 1) * 20;
  return `${galleryWidth}px`;
};

const scrollIntoView = () => {
  if (!galleryContainerElem.value) return;
  if (!wasPreviousPopover.value) {
    const currentPageRow = Math.floor(
      (props.currentPageNumber - 1) / numThumbnailsPerRow
    );
    const normalPageRows = Math.ceil(
      normalPages.value!.length / numThumbnailsPerRow
    );
    const magicPageRows = Math.ceil(
      magicPages.value!.length / numThumbnailsPerRow
    );
    const rowHeight =
      magicPageRows > 0
        ? (galleryContainerElem.value.scrollHeight - 32) /
          (normalPageRows + magicPageRows)
        : galleryContainerElem.value.scrollHeight / normalPageRows;

    galleryContainerElem.value.scrollTo({
      top: currentPageRow * rowHeight,
      behavior: "smooth",
    });
  } else {
    galleryContainerElem.value?.scrollTo({
      top: props.scroll,
      behavior: "smooth",
    });
  }
};

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    const id = entry.target.getAttribute("data-id");
    if (id !== null && entry.isIntersecting) {
      visiblePages.value.add(parseInt(id, 10));
    }
  });
};

// watcher / observer
watch(() => props.currentPageNumber, scrollIntoView);
const resizeObserver = new ResizeObserver(resizeGalleryWidth);

const intersectionObserver = new IntersectionObserver(handleIntersection, {
  root: null, // The viewport
  rootMargin: "0px",
  threshold: 0.1, // 10% of the image must be visible
});

onMounted(() => {
  if (galleryContainerElem.value) {
    resizeObserver.observe(galleryContainerElem.value);
    galleryItems.value.forEach((element) => {
      intersectionObserver.observe(element);
    });
  }
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
  intersectionObserver.disconnect();
});
</script>

<style scoped lang="scss">
$primary: #35b6ba;
$hover: #dbdbdb;

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.5em;
}

.gallery_container {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  height: calc(
    100vh - 74.75px - 72px
  ); // total height - bottom controls - navbar
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .gallery_item {
    border-radius: 1em;
    cursor: pointer;

    .img_pagination_wrapper {
      position: relative;

      .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        border: 10px solid transparent;
        user-select: none;
        width: 350px;
        height: 244px;

        &:hover {
          border: 10px solid $hover;
        }

        &.active {
          border: 10px solid $primary;
        }

        &:deep img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: #fafafa;
        }
      }

      span {
        position: absolute;
        bottom: 1em;
        left: 1em;
        background-color: $primary;
        color: white;
        padding: 0.35em 0.65em;
        border-radius: 0.35em;
        opacity: 0.85;
      }
    }
  }

  .breaker {
    width: 100%;
    height: 0px;
    background-color: #dbdbdb;
    margin: 1em 0;
  }
}
</style>
