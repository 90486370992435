<template>
  <projector-header />
  <div class="stacked-projector">
    <projector-button style="z-index: 100" />
    <div ref="stack">
      <transition-group name="list">
        <!-- iterate stack of documents from bottom to top -->
        <div
          class="presenter"
          v-for="(id, index) in documentIds"
          :key="`${id}-${index}`"
          :style="{'z-index': 1 + index}"
        >
          <document-presenter
            :document-id="id"
            v-model:page="pageNumbers[index]"
            :fetching="fetching"
            @go-to-document="(id) => goToDocument(id, index)"
          >
            <template #fullscreenButton>
              <button class="button" @click="toggleFullscreen()">
                <tooltip v-if="isFullscreen">
                  <template v-slot>
                    <span class="material-symbols-outlined">close_fullscreen</span>
                  </template>
                  <template #tooltip>{{
                    $t("documentPresenter.controls.closeFullscreen")
                  }}</template>
                </tooltip>
                <tooltip v-else>
                  <template v-slot>
                    <span class="material-symbols-outlined">fullscreen</span>
                  </template>
                  <template #tooltip>{{
                    $t("documentPresenter.controls.openFullscreen")
                  }}</template>
                </tooltip>
              </button>
            </template>
            <template #buttonsAtTop="{ pageIsPopover }">
              <button class="button" @click="goBack" v-if="!pageIsPopover">
                <span class="material-symbols-outlined">arrow_back</span>
              </button>
            </template>
          </document-presenter>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
// https://github.com/Pitchview/Projector_iOS/blob/developer/slides/helper/AudienceStatusTracker.swift
import { computed, watch, ref, onUnmounted } from "vue";
import { useFullscreen } from "@vueuse/core";

import { DocumentPresenter, Tooltip, ProjectorButton } from "@/components";
import { useProjector } from "@/util";
import { useGetDocumentByFirestoreIdQuery } from "@/graphql/operations";
import { useRouter, useRoute, LocationQuery } from "vue-router";
import { useDocumentStore } from "@/store/document";
import ProjectorHeader from "./ProjectorHeader.vue";

const router = useRouter();
const route = useRoute();
const documentStore = useDocumentStore();

const goBack = () => {
  if (window.history.length > 1) {
    router.back();
  } else {
    router.push({
      name: "Workspace",
      params: { workspaceId: route.params.workspaceId as string },
    });
  }
};

const goToDocument = (id: string, index: number) => {
  router.push({ ...route, query: { fid: [...documentIds.value, id] } });
};

const documentIds = ref<string[]>([]);
// get the current document ids
const setDocumentIds = (query: LocationQuery) => {
  const fid = query.fid;
  documentIds.value =
    fid === null
      ? []
      : typeof fid === "string"
      ? [fid]
      : ([...fid] as string[]);
};

setDocumentIds(route.query);
watch(route, (newRoute) => {
  setDocumentIds(newRoute.query);
});

let stack = ref<HTMLDivElement | null>(null);
const { isFullscreen, toggle: toggleFullscreen } = useFullscreen(stack);

let pageNumbers = ref<number[]>([]);
let documents = ref<Document[]>([]);

watch(documentIds, (newArray) => {
  pageNumbers.value = pageNumbers.value.slice(0, newArray.length);
  documents.value = documents.value.slice(0, newArray.length);
});

// get the top-most document
// -------------------------
const {
  data: topMostDocumentResult,
  executeQuery,
  fetching,
} = useGetDocumentByFirestoreIdQuery({
  variables: computed(() => ({
    id: documentIds.value[documentIds.value.length - 1],
  })),
  pause: computed(() => documentIds.value.length < 1),
  requestPolicy: "cache-and-network",
});

// synchronize the top-most document with the projector, if active
// ---------------------------------------------------------------
const { document: documentInPresentation, page: pageInPresentation } =
  useProjector();

let topMostDocument = computed(() => topMostDocumentResult.value?.document);
watch(topMostDocument, (newTopMostDocument, oldTopMostDocument) => {
  //document did not change
  if (JSON.stringify(newTopMostDocument) === JSON.stringify(documentInPresentation.value)) {
    return;
  }

  documentStore.set(
    newTopMostDocument?.id ?? null,
    newTopMostDocument?.doesSupportSharedspace ?? false
  );

  documentInPresentation.value = newTopMostDocument ?? undefined;
});

const intervalId = window.setInterval(() => {
  executeQuery();
}, 5000);

watch(
  computed(() => pageNumbers.value[pageNumbers.value.length - 1]),
  (newValue, oldValue) => {
    pageInPresentation.value = newValue;
  },
  { immediate: true }
);

onUnmounted(() => {
  documentInPresentation.value = undefined;
  window.clearInterval(intervalId);
});
</script>

<style scoped>
.stacked-projector {
  position: relative;
  width: calc(100% - 3em);
  height: calc(100vh - 65px);
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.presenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.presenter button span {
  margin-right: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.8s ease 0.5s;
}
.list-enter-from,
.list-leave-to {
  transform: translateY(100%);
}
</style>
