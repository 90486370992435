<script lang="ts" setup>
import { PropType, ref, computed, watch } from "vue";
import {
  type PageConfigFragment,
  type RootWorkspaceFragment,
} from "@/graphql/operations";
import { Tooltip, Modal } from "@/components";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  page: {
    type: Object as PropType<PageConfigFragment>,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
  hasChanges: {
    type: Boolean,
    required: true,
  },
  missingDocument: {
    type: Boolean,
    required: true,
  },
  crossLinksEnabled: {
    type: Boolean,
    required: true,
  },
  selectedType: {
    type: String,
    required: true,
  },
  previousIsMagic: {
    type: Boolean,
    required: true,
  },
  nextIsMagic: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close", "changeMode", "changeStep", "submit"]);

watch(
  () => props.crossLinksEnabled,
  () => {
    localCrossLinksEnabled.value = props.crossLinksEnabled;
  }
);

const numAdminWorkspaces = computed(() =>
  store.getters.rootWorkspaces.reduce(
    (acc: number, w: RootWorkspaceFragment) =>
      acc + (w?.myPrivileges?.mayAdministrate ? 1 : 0),
    0
  )
);

const displayWarningPrevious = computed(
  () => props.selectedType === "NORMAL" && props.previousIsMagic
);
const displayWarningNext = computed(
  () => props.selectedType !== "NORMAL" && !props.nextIsMagic
);

const displayCrossLinksWarning = ref(false);
const localCrossLinksEnabled = ref(props.crossLinksEnabled);
const displayWarningIfCrossLinks = () => {
  if (localCrossLinksEnabled.value) {
    localCrossLinksEnabled.value = false;
    displayCrossLinksWarning.value = true;
  } else {
    emits("changeMode", localCrossLinksEnabled.value);
  }
};

const useNormalMode = () => {
  localCrossLinksEnabled.value = false;
  displayCrossLinksWarning.value = false;
  emits("changeMode", localCrossLinksEnabled.value);
};

const useCrossLinksMode = () => {
  localCrossLinksEnabled.value = true;
  displayCrossLinksWarning.value = false;
  emits("changeMode", localCrossLinksEnabled.value);
};
</script>

<template>
  <div class="footer_container">
    <div v-if="displayWarningPrevious" class="warning">
      <span class="material-symbols-outlined"> info </span>
      {{ $t("magicPageStudio.convertPrevious.warning") }}
    </div>
    <div v-else-if="displayWarningNext" class="warning">
      <span class="material-symbols-outlined"> info </span>
      {{ $t("magicPageStudio.convertSubsequent.warning") }}
    </div>

    <div v-if="currentStep === 2 && numAdminWorkspaces > 1">
      <div class="control">
        <input
          type="checkbox"
          v-model="localCrossLinksEnabled"
          @change.prevent="displayWarningIfCrossLinks"
        />
        <label>
          {{ $t("magicPageStudio.wizard.advanced.label") }}

          <tooltip class="multiline left">
            <template v-slot>
              <span class="material-symbols-outlined"> help </span>
            </template>
            <template #tooltip>
              {{ $t("magicPageStudio.wizard.advanced.info") }}
            </template>
          </tooltip>
        </label>
      </div>
    </div>

    <div class="buttons">
      <button class="button" @click="emits('close')">
        {{ $t("magicPageStudio.cancelEdit") }}
      </button>
      <button
        class="button primary"
        v-if="selectedType === 'MAGIC_LINK' && currentStep === 1"
        @click="emits('changeStep', 2)"
      >
        {{ $t("magicPageStudio.wizard.nextButton.label") }}
      </button>
      <button
        v-else
        class="primary button"
        :class="{ disabled: !hasChanges || missingDocument }"
        @click="emits('submit')"
      >
        {{ $t("magicPageStudio.saveButton.label") }}
      </button>
    </div>
  </div>

  <teleport to="body">
    <Modal :active="displayCrossLinksWarning" class="transparent no_header">
      <template #body>
        <p class="info">
          {{ $t("magicPageStudio.wizard.advanced.warning") }}
        </p>
      </template>
      <template #footer>
        <div class="buttons">
          <button class="button" @click="useCrossLinksMode">
            {{ $t("magicPageStudio.wizard.advanced.acceptButton") }}
          </button>
          <button class="button primary" @click="useNormalMode">
            {{ $t("magicPageStudio.wizard.advanced.resetButton") }}
          </button>
        </div>
      </template>
    </Modal>
  </teleport>
</template>

<style scoped lang="scss">
.footer_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.warning {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.warning .material-symbols-outlined {
  margin-right: 0.3em;
}

.control {
  display: flex;
  align-items: center;
}

.control label {
  margin-left: 0.5em;
  display: block;
  line-height: 1.4em;
}

.control label .material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: middle;
}
</style>
