<script lang="ts" setup>
import { computed, reactive, ref, watch } from "vue";
import { LoadingSpinner, Search, Tooltip } from "@/components";
import {
  useCrmContactsQuery,
  CrmContact,
  CrmContactsOrderBy,
  useDocumentSpaceConfigsQuery,
} from "@/graphql/operations";
import { useI18n } from "vue-i18n";
import { useDebounceFn } from "@vueuse/core";
import ExpandedRow from "./ExpandedRow.vue";

const { t } = useI18n({});

const props = defineProps({
  spaceMode: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["submit", "change", "close"]);

const highlightedIdx = ref<number>(0);

const { data: configsData } = useDocumentSpaceConfigsQuery();
const numSpaceConfigs = computed(
  () => configsData.value?.documentSpaceConfigs?.totalCount || 1
);

const search = ref<string>("");
const currentSearch = ref<string>(search.value);
const limit = 100;
const offset = ref<number>(0);
const orderBy = computed<CrmContactsOrderBy[]>(() =>
  search.value === ""
    ? [CrmContactsOrderBy.LastNameAsc, CrmContactsOrderBy.FirstNameAsc]
    : []
);

const { data, fetching } = useCrmContactsQuery({
  variables: reactive({
    search: currentSearch,
    offset: offset,
    limit: limit,
    orderBy: orderBy,
  }),
  requestPolicy: "cache-and-network",
});

const debouncedFn = useDebounceFn(
  () => {
    currentSearch.value = search.value;
  },
  700,
  { maxWait: 5000 }
);

const hasNextPage = computed(
  () => data.value?.searchCrmContactsByName?.pageInfo?.hasNextPage
);
const hasPreviousPage = computed(
  () => data.value?.searchCrmContactsByName?.pageInfo?.hasPreviousPage
);

const filterReset = ref<boolean>(false);

const setFilter = (filter: string) => {
  if (typeof filter !== "string") {
    return;
  }
  search.value = filter;
  debouncedFn();
};

const selectedContact = ref<string | null>(null);

const contacts = computed(() => data.value?.searchCrmContactsByName?.nodes);
watch(contacts, () => (highlightedIdx.value = -1));

const changeSelection = (id: string) => {
  const contact = contacts.value!.find((c) => c!.id === id);
  if (!contact) {
    return;
  }

  if (
    props.spaceMode &&
    contact.documentSpaceCrmMappings.totalCount >= numSpaceConfigs.value
  ) {
    console.log("display warning");
    return;
  }

  selectedContact.value = id;
  expandedRows.value = [id];

  const nameParts = [contact.lastName];
  if (contact.firstName) {
    nameParts.push(contact.firstName);
  }

  const name = nameParts.join(", ");
  emit("change", contact, name);
};

const submit = async () => {
  if (!selectedContact.value) {
    return;
  }

  emit("submit");
};

const formatName = (contact: CrmContact) => {
  const name = [contact.lastName];
  if (contact.firstName) {
    name.push(contact.firstName);
  }

  return name.join(", ");
};

const listElem = ref();

const handleArrowDown = () => {
  listElem.value.children[highlightedIdx.value].tabindex = "-1";
  listElem.value.children[highlightedIdx.value].blur();
  highlightedIdx.value = Math.min(
    highlightedIdx.value + 1,
    contacts.value?.length || 0
  );
  listElem.value.children[highlightedIdx.value].tabindex = 0;
  listElem.value.children[highlightedIdx.value].focus();
};

const handleArrowUp = () => {
  listElem.value.children[highlightedIdx.value].tabindex = "-1";
  listElem.value.children[highlightedIdx.value].blur();
  highlightedIdx.value = Math.max(0, highlightedIdx.value - 1);
  listElem.value.children[highlightedIdx.value].tabindex = 0;
  listElem.value.children[highlightedIdx.value].focus();
};

const handleEnter = () => {
  changeSelection(contacts.value![highlightedIdx.value]!.id);
  submit();
};

const handleKeyDown = (ev: KeyboardEvent) => {
  if (ev.code === "ArrowDown") {
    handleArrowDown();
  } else if (ev.code === "ArrowUp") {
    handleArrowUp();
  } else if (ev.code === "Enter") {
    handleEnter();
  }
};

const goToPreviousPage = () => {
  offset.value = offset.value - limit;
};

const goToNextPage = () => {
  offset.value = offset.value + limit;
};

const expandedRows = ref<Array<string>>([]);
const toggleRow = (contactId: string) => {
  if (expandedRows.value.includes(contactId)) {
    expandedRows.value.splice(expandedRows.value.indexOf(contactId), 1);
  } else {
    expandedRows.value.push(contactId);
  }
};
</script>

<template>
  <div @keydown="handleKeyDown" class="body_container">
    <div>
      <Search
        :placeholder="$t('crmContactSearch.search.placeholder')"
        :resetValue="filterReset"
        @change="setFilter"
      />
    </div>

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>
    <div v-else-if="contacts && contacts.length > 0">
      <div class="controls">
        <div class="control">
          <tooltip>
            <template v-slot>
              <span
                :class="{
                  'material-symbols-outlined': true,
                  disabled: !hasPreviousPage,
                }"
                @click="goToPreviousPage"
              >
                chevron_left
              </span>
            </template>
            <template #tooltip>{{
              $t("crmContacts.pagination.previous")
            }}</template>
          </tooltip>
          <tooltip>
            <template v-slot>
              <span
                :class="{ 'material-symbols-outlined': true, disabled: !hasNextPage }"
                @click="goToNextPage"
              >
                chevron_right
              </span>
            </template>
            <template #tooltip>{{
              $t("crmContacts.pagination.next")
            }}</template>
          </tooltip>
        </div>
      </div>
      <div class="clickable navigatable_list" ref="listElem">
        <div v-for="(contact, i) in contacts" :key="contact!.id">
          <div
            :class="{
            row: true,
            clickable_row: true,
            highlighted: i === highlightedIdx || selectedContact === contact!.id,
            disabled: spaceMode && contact!.documentSpaceCrmMappings.totalCount >= numSpaceConfigs
          }"
            @click.stop="changeSelection(contact!.id)"
            tabindex="-1"
          >
            <div>
              <div class="checkbox_container">
                <input
                  type="radio"
                  :disabled="spaceMode && contact!.documentSpaceCrmMappings.totalCount >= numSpaceConfigs"
                  :id="contact!.id"
                  :value="contact!.id"
                  :checked="selectedContact === contact!.id"
                  :class="{ disabled: spaceMode && contact!.documentSpaceCrmMappings.totalCount >= numSpaceConfigs }"
                />
              </div>
            </div>

            <div class="main_column">
              <Tooltip class="left">
                <template v-slot>
                  <div>
                    <b>
                      {{ formatName(contact! as CrmContact) }}
                    </b>
                  </div>
                  <div class="info">
                    {{ contact!.email }}
                  </div>
                  <div class="info">
                    {{ contact!.organization }}
                    <template
                      v-if="contact!.organization && contact!.territory"
                    >
                      &mdash;
                    </template>
                    {{ contact!.territory }}
                  </div>
                </template>
                <template
                  #tooltip
                  v-if="spaceMode && contact!.documentSpaceCrmMappings.totalCount >= numSpaceConfigs"
                >
                  {{ $t("crmContacts.warning.spaces") }}
                </template>
              </Tooltip>
            </div>
            <div class="col" style="width: 15%">
              {{
                contact!.crmContactPhaseMappingsByContactId?.nodes[0]?.phase
                  ?.name
              }}
            </div>
            <div
              v-if="spaceMode && contact!.documentSpaceCrmMappings.totalCount > 0"
              class="right"
            >
              <router-link
                @click="emit('close')"
                v-if="numSpaceConfigs === 1"
                class="link"
                :to="{
                  name: 'DocumentSpace',
                  params: {
                    id: contact?.documentSpaceCrmMappings.nodes[0]
                      ?.documentSpaceId,
                  },
                }"
              >
                {{ $t("crmContacts.openSpace") }}
              </router-link>
              <div v-else class="container">
                <span class="text">
                  {{
                    $t("crmContacts.info.spaces.totalCount", {
                      count: contact!.documentSpaceCrmMappings.totalCount,
                    })
                  }}
                </span>
                <span
                  class="clickable material-symbols-outlined"
                  @click.stop="toggleRow(contact!.id)"
                >
                  {{
                    expandedRows.includes(contact!.id)
                      ? "keyboard_arrow_down"
                      : "chevron_right"
                  }}
                </span>
              </div>
            </div>
          </div>
          <ExpandedRow
            v-if="spaceMode && expandedRows.includes(contact!.id) && contact!.documentSpaceCrmMappings.totalCount > 0"
            :contactId="contact!.id"
            :class="{selected: selectedContact === contact!.id}"
            @close="emit('close')"
          />
        </div>
      </div>
      <div class="controls">
        <div class="control">
          <tooltip>
            <template v-slot>
              <span
                :class="{
                  'material-symbols-outlined': true,
                  disabled: !hasPreviousPage,
                }"
                @click="goToPreviousPage"
              >
                chevron_left
              </span>
            </template>
            <template #tooltip>{{
              $t("crmContacts.pagination.previous")
            }}</template>
          </tooltip>
          <tooltip>
            <template v-slot>
              <span
                :class="{ 'material-symbols-outlined': true, disabled: !hasNextPage }"
                @click="goToNextPage"
              >
                chevron_right
              </span>
            </template>
            <template #tooltip>{{
              $t("crmContacts.pagination.next")
            }}</template>
          </tooltip>
        </div>
      </div>
    </div>
    <div v-else class="info">
      {{ $t("crmContactSearch.emptyResult") }}
    </div>
  </div>
</template>

<style scoped lang="scss">
$checkbox-width: 40px;
$color-secondary: #34b5ba;

.navigatable_list {
  width: 100%;
  font-size: 0.9rem;
}

.navigatable_list .row {
  display: flex;
  align-items: center;
  border-top: 1px solid #f4f4f4;
  padding: 0.1em 0;
}

.main_column {
  padding: 0.5em 0;
}

.navigatable_list.clickable .row.highlighted,
.navigatable_list.clickable .row:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.expanded_row.selected {
  background-color: #fafafa;
}

.navigatable_list.clickable .row.highlighted.disabled,
.navigatable_list.clickable .row.disabled:hover {
  cursor: initial;
}

.navigatable_list .row > div {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 2em;
}

.navigatable_list .row > div.right {
  margin-right: 1em;
}

.navigatable_list .row > div:first-child {
  width: $checkbox-width;
  margin-right: 0;
}

.navigatable_list .row > div:nth-child(3) {
  margin-left: auto;
}

.row b {
  font-weight: 500;
  line-height: 1.5;
}

.row .info {
  font-size: 0.9em;
}

input[type="radio"] {
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid #555555;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}

input[type="radio"].disabled {
  background-color: #dadada;
  pointer-events: none;
}

input[type="radio"].disabled:hover {
  cursor: default;
  pointer-events: none;
}

input[type="radio"]:checked {
  background-color: $color-secondary;
}

.checkbox_container {
  vertical-align: middle;
  padding: 0.8em 0.8em 0.8em 0.5em;
}

.modal .footer .buttons {
  left: auto;
}

.checkbox_with_label {
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
}

.checkbox_with_label .checkbox_container {
  padding: 0;
  margin-right: 0.5em;
}

.body_container {
  height: 100%;
}
.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em 0;
}

.control span {
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
}

.control span:hover {
  background-color: #f4f4f4;
}

.control span.disabled {
  pointer-events: none;
  cursor: initial;
  opacity: 0.3;
}

.col {
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  color: inherit;
}

.right .container {
  display: flex;
  align-items: center;
}

.right .container .text {
  width: 110px;
}
</style>
