<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const hasDocumentSpaces = computed(() =>
  store.getters.user.hasDocumentSpaces()
);

const hasAnalytics = computed(() => store.getters.user.hasAnalytics());
const hasDocumentAnalytics = computed(() =>
  store.getters.user.hasDocumentAnalytics()
);
const hasUsageAnalytics = computed(() =>
  store.getters.user.hasUsageAnalytics()
);
</script>

<template>
  <div class="pane page_content">
    <div class="title_container">
      <h3>{{ $t("analyticsOverview.title") }}</h3>

      <p class="info">
        {{ $t("analyticsOverview.info") }}
      </p>
    </div>

    <div class="info_box" v-if="hasAnalytics && hasDocumentSpaces">
      <p class="info">
        {{ $t("analyticsOverview.spaceAnalytics.info") }}
      </p>
      <router-link class="link" :to="{ name: 'Dashboard' }">
        {{ $t("analyticsOverview.spaceAnalytics.linkText") }}
      </router-link>
    </div>

    <div class="info_box" v-if="hasDocumentAnalytics">
      <p class="info">
        {{ $t("analyticsOverview.documentAnalytics.info") }}
      </p>
      <router-link class="link" :to="{ name: 'DocumentAnalytics' }">
        {{ $t("analyticsOverview.documentAnalytics.linkText") }}
      </router-link>
    </div>

    <div class="info_box" v-if="hasUsageAnalytics">
      <p class="info">
        {{ $t("analyticsOverview.usageAnalytics.info") }}
      </p>
      <router-link class="link" :to="{ name: 'UsageAnalytics' }">
        {{ $t("analyticsOverview.usageAnalytics.linkText") }}
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.pane {
  font-size: 0.9rem;
}

.info_box {
  border: 1px solid #cecece;
  padding: 1.3em;
  max-width: 600px;
  border-radius: 0.3em;
  margin-bottom: 1.8em;
}

.info_box .info {
  padding: 0;
  margin: 0 0 1em 0;
}
</style>
