import { defineStore } from "pinia";

type State = {
  id: null | string,
  isShareable: boolean
}

export const useDocumentStore = defineStore('document', {
  state: () => {
    return {
      id: null,
      isShareable: false
    } as State
  },
  actions: {
    set(id: string | null, isShareable: boolean) {
      this.id = id;
      this.isShareable = isShareable;
    }
  }
});
