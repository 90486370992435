<template>
  <div v-if="fetching" class="loading_wrapper">
    <div class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>
  </div>

  <template v-else>
    <template v-if="documents && documents.length > 0">
      <template v-if="store.getters.currentWorkspace?.root?.myPrivileges?.mayAdministrate">
        <draggable-grid
          class="columns grid_drag"
          @placeAbove="placeAbove"
          @placeBelow="placeBelow">
          <template
            v-for="doc, idx in documents"
            :key="doc!.id"
          >
            <PVDocCard
              v-if="doc"
              @click="navigateToProjector(doc!.firestoreId)"
              @edit="showEditor(doc!.id)"
              @copy="showCopyDialog(doc, CopyAction.COPY)"
              @move="showCopyDialog(doc, CopyAction.MOVE)"
              @addToSpace="showDocumentSpacePicker(doc!.id)"
              class="column"
              :doc="doc"
              :data-idx="idx"
              :data-id="doc!.id"
              :data-test="`document-${doc.displayTitle}`"
            >
            </PVDocCard>
          </template>
        </draggable-grid>
      </template>
      <template v-else>
        <div class="columns">
          <template
            v-for="doc, idx in documents"
            :key="doc!.id"
          >
            <PVDocCard
              v-if="doc"
              @click="navigateToProjector(doc!.firestoreId)"
              @edit="showEditor(doc!.id)"
              @copy="showCopyDialog(doc, CopyAction.COPY)"
              @move="showCopyDialog(doc, CopyAction.MOVE)"
              @addToSpace="showDocumentSpacePicker(doc!.id)"
              class="column"
              :key="doc!.id"
              :doc="doc"
            >
            </PVDocCard>
          </template>
        </div>
      </template>
    </template>

    <div v-else-if="!hasSubfolders" class="info">
      <div>
        <div>
          <span class="material-symbols-outlined"> folder_open </span>
        </div>
        <span class="text">{{ $t("pvdocuments.empty") }}</span>
      </div>
    </div>
  </template>

  <doc-copy-dialog
    v-if="state.copyDialogActive"
    :doc="state.copyDoc!"
    :action="state.copyAction!"
    @close="closeCopyDialog"
  />

  <ShareDocumentModal 
    v-if="state.documentSpacePickerActive"
    :docId="state.spacePickerDocId!"
    @close="state.documentSpacePickerActive = false"
  />
</template>

<script lang="ts" setup>
import { reactive, computed, onBeforeUnmount } from "vue";
import {
  PVDocCard,
  DocCopyDialog,
  DraggableGrid,
  LoadingSpinner,
  ShareDocumentModal
} from "@/components";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  useReorderDocumentMutation,
  useWorkspaceDocumentsQuery,
  WorkspaceDocumentFragment
} from "@/graphql/operations";

const store = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  hasSubfolders: {
    type: Boolean,
    required: true
  }
});

const workspaceId = route.params.workspaceId as string;

interface UpdateDocProps {
  id?: string;
  mimeType?: string;
}

enum CopyAction {
  MOVE = "MOVE",
  COPY = "COPY",
}

interface ComponentData {
  editorActive: boolean;
  documentEditId: string | null;
  openDocId: string | null;
  copyDialogActive: boolean;
  copyDoc: WorkspaceDocumentFragment | null;
  copyAction: CopyAction | null;
  documentSpacePickerActive: boolean;
  spacePickerDocId: string | null;
}

const state: ComponentData = reactive({
  editorActive: false,
  documentEditId: null,
  openDocId: null,
  copyDialogActive: false,
  copyDoc: null,
  copyAction: null,
  documentSpacePickerActive: false,
  spacePickerDocId: null,
});

const { data, fetching, executeQuery } = useWorkspaceDocumentsQuery({
  variables: {
    workspaceId: workspaceId
  },
  requestPolicy: "cache-and-network"
});

const intervalId = setInterval(() => {
  executeQuery();
}, 4 * 1000);

onBeforeUnmount(() => {
  clearInterval(intervalId);
});

const documents = computed(() => data.value?.documents?.nodes);

function showEditor(pvDocId: string) {
  state.openDocId = pvDocId;
  state.editorActive = true;
}

function closeEditor() {
  state.editorActive = false;
}

function showCopyDialog(doc: WorkspaceDocumentFragment | undefined | null, action: CopyAction) {
  if (!doc) {
    return;
  }

  state.copyDoc = doc;
  state.copyAction = action;
  state.copyDialogActive = true;
}

function closeCopyDialog() {
  state.copyDialogActive = false;
  state.copyAction = null;
  state.copyDoc = null;
}

function showDocumentSpacePicker(pvDocId: string) {
  state.spacePickerDocId = pvDocId;
  state.documentSpacePickerActive = true;
}

function navigateToProjector(pvDocId: string) {
  /* navigate to projector */
  router.push({
    name: "StackedProjector",
    params: {
      workspaceId: route.params.workspaceId,
    },
    query: {
      fid: pvDocId,
    },
  });
}

const { executeMutation: reorderDocument } = useReorderDocumentMutation();
async function placeAbove(docId: string, aboveId: string) {
  await reorderDocument({
    documentId: docId,
    aboveId: aboveId
  });
}

async function placeBelow(docId: string, belowId: string) {
  await reorderDocument({
    documentId: docId,
    belowId: belowId
  });
}
</script>

<style lang="scss" scoped>
.info {
  height: calc(100vh - 40px - 2em - 38px - 0.8rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info .text {
  opacity: 0.8;
}

.info .material-symbols-outlined {
  font-size: 14rem;
  color: #ebebeb;
  margin-top: -2rem;
}

.loading_wrapper {
  height: 100%;

  .loading_status {
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
