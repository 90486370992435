<script lang="ts" setup>
import { Modal } from "@/components";
import { ref, onMounted } from "vue";
import { useCreateSubfolderMutation } from "@/graphql/operations";

const name = ref<string>("");

const emit = defineEmits(["close", "success", "error"]);
const emitClose = () => {
  name.value = "";
  emit("close");
}

const props = defineProps({
  active: {
    type: Boolean
  },
  id: {
    type: String,
    required: true
  }
});

const { executeMutation } = useCreateSubfolderMutation();
const submit = async () => {
  //TODO validate
  const result = await executeMutation({
    parentId: props.id,
    name: name.value
  });

  if (result.error) {
    emit("error");
  } else {
    const newWorkspaceId = result.data?.createWorkspaceAndJoin?.result?.createdWorkspace?.id;
    emit("success", newWorkspaceId);
  }

  emitClose();
}

const input = ref<HTMLInputElement>();
onMounted(() => {
  if (!input.value) {
    return;
  }

  input.value!.focus();
})
</script>

<template>
  <Modal @close="emitClose" :active="active">
    <template #header>
      {{ $t("workspaceDetail.createSubfolder.modalHeader")}}
    </template>
    <template #body>
      <input type="text" class="input" ref="input" v-model="name" />
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="submit">
          {{ $t("submit") }}
        </button>
        <button class="button secondary" @click="emitClose">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
