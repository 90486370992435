<template>
  <div v-if="props.covers" ref="canvas" class="laserpointer-canvas">
    <div
      ref="pointer"
      class="laserpointer-pointer"
      :style="{ left, top, display }"
    >
      <svg height="20" width="20">
        <circle cx="10" cy="10" r="10" :fill="color" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  Ref,
  watch,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue'

const props = defineProps<{
  x: number
  y: number
  color?: string
  covers?: HTMLElement
}>()

const canvas: Ref<HTMLDivElement | null> = ref(null)
const pointer: Ref<HTMLDivElement | null> = ref(null)

const align = () => {
  if (!(props.covers && canvas.value)) return
  canvas.value.style.width = props.covers.offsetWidth + 'px'
  canvas.value.style.height = props.covers.offsetHeight + 'px'
  canvas.value.style.top = props.covers.offsetTop + 'px'
  canvas.value.style.left = props.covers.offsetLeft + 'px'
  const zIndex = getComputedStyle(props.covers).zIndex
  if (!isNaN(parseInt(zIndex))) {
    canvas.value.style.zIndex = (zIndex + 1).toString()
  }
}

// use a resize observer to resize the canvas
const resizeObserver = new ResizeObserver(align)

const left = computed<string>(() => {
  if (!props.x || !canvas.value || !pointer.value) return '0px'
  else
    return (
      props.x * canvas.value.clientWidth - pointer.value.clientHeight / 2 + 'px'
    )
})

const top = computed<string>(() => {
  if (!props.y || !canvas.value || !pointer.value) return '0px'
  else
    return (
      props.y * canvas.value.clientHeight -
      pointer.value.clientHeight / 2 +
      'px'
    )
})

const display = computed<string>(() => {
  if (!props.x || !props.y) return 'none'
  else return 'absolute'
})

watch(
  () => props.covers,
  (newElement, oldElement) => {
    if (oldElement) resizeObserver.unobserve(oldElement)
    if (newElement) {
      resizeObserver.observe(newElement)
      align()
    }
  },
  { immediate: true }
)

onMounted(() => {
  align()
})

onBeforeUnmount(() => {
  if (props.covers) resizeObserver.unobserve(props.covers)
  resizeObserver.disconnect()
})
</script>

<style>
.laserpointer-canvas {
  position: absolute;
  /*
  delegate clicks (and other pointer events) to the covered element, see:
  https://stackoverflow.com/questions/33264310/in-html-can-i-disable-user-interaction-with-an-entire-dom-sub-tree/33264477
  */
  pointer-events: none;
  user-select: none;
}

.laserpointer-pointer {
  position: absolute;
}
</style>
