<template>
  <div>
    <form
      @drop.prevent="useFirstFile"
      @dragover.prevent
      @submit.prevent="submit"
      class="dropzone"
    >
      <div class="container">
        <div class="inner_container">
          <div class="drop_icon">
            <span class="material-symbols-outlined">download</span>
          </div>

          <form @submit.prevent="submit">
            <input
              @change="submit"
              id="file-selector"
              ref="fileInput"
              name="my-funny-file"
              type="file"
              style="display: none"
              :accept="Array.from(allowedMimeTypes).join(',')"
              :multiple="!singleFile"
            />
            <label class="button primary" for="file-selector">
              {{ $t("upload.browseFiles.title") }}
            </label>
          </form>
          <div class="info">{{ $t("upload.dropTitle") }}</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";
const ALLOWED_MIME_TYPES = new Set([
  "application/pdf",
  "video/mp4",
  ".docx",
  ".pptx",
  ".xlsx",
  ".png",
  "image/jpeg",
]);

const props = defineProps({
  mimeTypes: {
    type: Array,
    required: false,
    default: undefined,
  },
  singleFile: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["submit"]);

const fileInput: Ref<HTMLInputElement | null> = ref(null);

const allowedMimeTypes = props.mimeTypes
  ? new Set(props.mimeTypes)
  : ALLOWED_MIME_TYPES;

const useFirstFile = async (event: DragEvent): Promise<void> => {
  if (!event.dataTransfer) {
    console.error("data transfer is null");
    return;
  }

  emit("submit", event.dataTransfer.files);
};

async function submit() {
  if (!fileInput.value) {
    console.error("not mounted correctly");
    return;
  }

  emit("submit", fileInput.value.files);
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1em;
}

.drop_icon span {
  font-size: 6em;
  color: rgba(25, 23, 17, 0.2);
}

.button {
  font-size: 1em;
  margin: 25px 0 5px 0;
}

.info {
  display: block;
  font-size: 0.9em;
  margin-top: 0.5em;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px dashed rgba(25, 23, 17, 0.6);
}

.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.inner_container {
  display: block;
}
</style>
