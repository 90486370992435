<script lang="ts" setup>
import {
  type DocumentSpaceLink,
  useCreateDocumentSpaceLinkMappingMutation,
  useDeleteDocumentSpaceLinkMappingMutation,
} from "@/graphql/operations";
import { Tooltip } from "@/components";
import { PropType, ref } from "vue";

const props = defineProps({
  link: {
    type: Object as PropType<DocumentSpaceLink>,
    required: true,
  },
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["success"]);

const displayTranslations = ref<boolean>(false);

const { executeMutation } = useCreateDocumentSpaceLinkMappingMutation();
const { executeMutation: executeDelete } =
  useDeleteDocumentSpaceLinkMappingMutation();
const handleChange = async () => {
  if (
    props.link.documentSpaceLinkMappingsByLinkId.nodes &&
    props.link.documentSpaceLinkMappingsByLinkId.nodes.length > 0
  ) {
    const res = await executeDelete({
      mappingId: props.link.documentSpaceLinkMappingsByLinkId.nodes[0]!.id,
    });

    if (!res.error) {
      emits("success");
    }
  } else {
    const res = await executeMutation({
      linkId: props.link.id,
      spaceId: props.documentSpaceId,
    });

    if (!res.error) {
      emits("success");
    }
  }
};
</script>

<template>
  <div class="row">
    <Tooltip v-if="link.isMandatory" class="left">
      <template v-slot>
        <input type="checkbox" :checked="true" :disabled="true" />
      </template>
      <template #tooltip>{{
        $t("documentSpace.configLinks.mandatoryLink.info")
      }}</template>
    </Tooltip>

    <input
      v-else
      type="checkbox"
      @change="handleChange"
      :checked="link.documentSpaceLinkMappingsByLinkId.nodes?.length > 0"
    />
    <div class="cell stretch center">
      <b>{{ link.sectionTitle }}</b>
      <span
        v-if="link.documentSpaceLinkTranslationsByLinkId.nodes.length > 0"
        class="material-symbols-outlined clickable"
        @click="displayTranslations = !displayTranslations"
      >
        <template v-if="displayTranslations"> expand_less </template>
        <template v-else>expand_more</template>
      </span>
    </div>
    <div class="cell stretch center">
      <div>
        <a target="__blank" :href="link.url">{{ link.title }}</a>
      </div>
    </div>
    <div class="cell center icon_container">
      <Tooltip v-if="link.isMandatory" class="right">
        <template v-slot>
          <span class="material-symbols-outlined">lock</span>
        </template>
        <template #tooltip>{{
          $t("documentSpace.configLinks.mandatoryLink.info")
        }}</template>
      </Tooltip>
    </div>
  </div>
  <template v-if="displayTranslations">
    <div
      v-for="translation in link.documentSpaceLinkTranslationsByLinkId.nodes"
      :key="translation!.id"
      class="row inner"
    >
      <div class="cell stretch center">
        {{ translation!.languageCode }}:
        {{ translation!.sectionTitle }}
      </div>
      <div class="cell stretch center">
        <div>
          <a target="__blank" :href="translation!.url">{{
            translation!.title
          }}</a>
        </div>
      </div>
      <div class="cell center icon_container"></div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.row {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #fff;
  background: #fff;
  width: 100%;
  display: flex;
  padding: 0.5em 0;
  font-size: 0.9rem;
  align-items: center;
  box-sizing: border-box;
}

.row + .row.inner {
  border-top: none;
}

.row.inner {
  padding-left: 22px;
}

.row:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.cell {
  padding: 0 0.5em;
  line-height: 2.1em;
}

.cell.stretch {
  flex: 1;
}

.cell.grow {
  flex-grow: 1;
}

.cell.center {
  align-self: center;
}

.material-symbols-outlined {
  font-size: 1.2em;
  vertical-align: middle;
}

.cell a {
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.icon_container {
  width: 30px;
  text-align: right;
}

input[type="checkbox"]:disabled {
  opacity: 0.4;
}

b {
  font-weight: 500;
}
</style>
