<script lang="ts" setup>
import {
  type LabelsOfDocumentView,
  type Maybe,
  type CrmContact,
  useMarkAsVisitMutation,
  useMarkAsSelfStudyMutation,
} from "@/graphql/operations";
import { PropType, ref, computed } from "vue";
import { Dropdown, Modal, ContactPicker } from "@/components";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import LabelButton from "./LabelButton.vue";

const { t } = useI18n();
const store = useStore();

const emit = defineEmits(["success", "error"]);

const props = defineProps({
  viewIds: {
    type: Array as PropType<string[]>,
    required: true,
  },
  label: {
    type: Object as PropType<Maybe<LabelsOfDocumentView>> | undefined,
  },
  singleButton: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
  },
});

const items = [
  {
    value: "presentation",
    title: t("documentHistory.label.presentation.title"),
  },
  { value: "self-study", title: t("documentHistory.label.self-study.title") },
];

const hasCRMIntegration = computed(() =>
  store.getters.user.hasCRMIntegration()
);

const displayContactPicker = ref(false);

const handleChange = async (value: string) => {
  if (value === "presentation" && hasCRMIntegration.value) {
    displayContactPicker.value = true;
  } else if (value === "presentation") {
    markAsPresentation();
  } else {
    markAsSelfStudy();
  }
};

const selectedContact = ref<CrmContact | undefined>(undefined);
const selectContact = (contact: CrmContact) => {
  selectedContact.value = contact;
};

const { executeMutation } = useMarkAsVisitMutation();
const markAsPresentation = async () => {
  const res = await executeMutation({
    documentViewIds: props.viewIds,
    crmContactId: selectedContact.value ? selectedContact.value.id : undefined,
  });

  if (res.error) {
    emit("error");
  } else {
    emit("success");
  }

  reset();
};

const { executeMutation: markSelfStudy } = useMarkAsSelfStudyMutation();
const markAsSelfStudy = async () => {
  const res = await markSelfStudy({
    documentViewIds: props.viewIds,
  });

  if (res.error) {
    emit("error");
  } else {
    emit("success");
  }

  reset();
};

const reset = () => {
  selectedContact.value = undefined;
  displayContactPicker.value = false;
};
</script>

<template>
  <div class="document_view_label">
    <template v-if="singleButton">
      <LabelButton :type="props.type" @click="handleChange" />
    </template>

    <template v-else>
      <LabelButton
        v-if="label?.crmContact"
        :type="'contact'"
        @click="displayContactPicker = true"
      >
        {{ label.crmContact.firstName }} {{ label.crmContact.lastName }}
      </LabelButton>

      <LabelButton
        v-else-if="
          hasCRMIntegration &&
          label?.text === 'presentation' &&
          !label?.crmContact
        "
        :type="'contact-add'"
        @click="displayContactPicker = true"
      />

      <dropdown
        :items="items"
        :value="label ? label.text : undefined"
        @change="handleChange"
      >
        <LabelButton :type="label?.text">
          <template #right>
            <span class="material-symbols-outlined"> more_vert </span>
          </template>
        </LabelButton>
      </dropdown>
    </template>

    <Modal
      v-if="displayContactPicker"
      :active="true"
      :scrolling="true"
      @close="reset"
    >
      <template #header> CRM Integration: Select a contact </template>
      <template #body>
        <ContactPicker :spaceMode="false" @change="selectContact" @submit="markAsPresentation" />
      </template>
      <template #footer>
        <div class="buttons">
          <button class="button secondary" @click="markAsPresentation">
            {{ $t("skip") }}
          </button>
          <button
            :class="{
              button: true,
              primary: true,
              disabled: selectedContact === undefined,
            }"
            @click="markAsPresentation"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.document_view_label {
  display: flex;
  align-items: center;
}

.dropdown_button + .dropdown {
  margin-left: 0.5em;
}

.modal {
  color: initial;
}
</style>
