<script lang="ts" setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  Tabs,
  Breadcrumbs,
  DocCopyDialog,
  Tooltip,
  FlashMessage,
} from "@/components";
import { storage } from "@/fire";
import { useStore } from "vuex";
import { useDocumentQuery } from "@/graphql/operations";
import { getStorageProxyUrl } from "@/util";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import DocumentDeleteConfirmation from "./DocumentDeleteConfirmation.vue";

const store = useStore();

const route = useRoute();
const router = useRouter();
const docId = route.params.documentId as string;

const { fetching, data } = useDocumentQuery({
  variables: {
    id: docId,
  },
  requestPolicy: "cache-and-network",
});

const document = computed(() => data.value?.document);

const tabItems = computed(() => {
  const items = [
    {
      title: "Properties",
      routeParams: { name: "DocumentProperties", params: route.params },
    },
  ];

  if (document.value?.mimeType !== "application/mp4") {
    items.push({
      title: "Studio",
      routeParams: { name: "MagicPagesStudio", params: route.params },
    });
  }

  return items;
});

const breadcrumbItems = computed(() => [
  {
    text: store.getters.currentWorkspace?.name,
    href: router.resolve({
      name: "Workspace",
      params: { workspaceId: route.params.workspaceId as string },
    }).fullPath,
  },
  {
    text: document.value?.displayTitle,
  },
]);

const projectorLink = computed(
  () =>
    router.resolve({
      name: "StackedProjector",
      params: { workspaceId: route.params.workspaceId },
      query: { fid: document.value?.firestoreId },
    }).fullPath
);

enum CopyAction {
  MOVE = "MOVE",
  COPY = "COPY",
}

const copyDialogActive = ref<boolean>(false);
const copyAction = ref<CopyAction | null>(null);

const showCopyDialog = (action: CopyAction) => {
  copyAction.value = action;
  copyDialogActive.value = true;
};

const closeCopyDialog = () => {
  copyDialogActive.value = false;
};

const flashMessageActive = ref<boolean>(false);
const displayFlashMessage = () => {
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 1500);
};

const deleteConfirmationActive = ref<boolean>(false);
const showDeleteConfirmation = () => {
  deleteConfirmationActive.value = true;
};

const closeDeleteConfirmation = () => {
  deleteConfirmationActive.value = false;
};

const mimeTypes: Record<string, string> = {
  "application/mp4": "mp4",
  "video/mp4": "mp4",
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
};

const download = async () => {
  if (!document.value) {
    return;
  }

  const path = document.value.originalMimeType
    ? `original_files/${document.value.storageId.split(".")[0]}.${
        mimeTypes[document.value.originalMimeType]
      }`
    : document.value.storageId;
  const pathRef = storageRef(storage, path);
  const url = await getDownloadURL(pathRef);
  const link = window.document.createElement("a");
  link.href = getStorageProxyUrl(url);
  link.setAttribute("download", document.value.fileName);
  window.document.body.appendChild(link);
  link.click();
  window.document.body.removeChild(link);
};
</script>

<template>
  <div class="pane page_content">
    <FlashMessage
      v-if="flashMessageActive"
      :state="'success'"
      :message="'Erfolgreich gespeichert'"
    />

    <div v-if="fetching"></div>

    <template v-else>
      <div class="title_container">
        <Breadcrumbs :items="breadcrumbItems" />

        <div class="buttons">
          <Tooltip>
            <template v-slot>
              <a :href="projectorLink" target="_blank">
                <span class="material-symbols-outlined filled"> visibility </span>
              </a>
            </template>
            <template #tooltip>{{ $t("pvDocEditor.preview") }}</template>
          </Tooltip>

          <Tooltip>
            <template v-slot>
              <a :download="document!.fileName" @click="download">
                <span class="material-symbols-outlined filled"> download </span>
              </a>
            </template>
            <template #tooltip>{{ $t("pvDocEditor.download") }}</template>
          </Tooltip>

          <Tooltip v-if="false">
            <template v-slot>
              <span
                class="material-symbols-outlined filled"
                @click="showCopyDialog(CopyAction.COPY)"
                v-if="
                  store.getters.currentWorkspace?.root.myPrivileges
                    .mayAdministrate && store.getters.workspaces.length > 1
                "
              >
                file_copy
              </span>
            </template>
            <template #tooltip>{{ $t("pvDocEditor.copy") }}</template>
          </Tooltip>

          <Tooltip>
            <template v-slot>
              <span
                class="material-symbols-outlined filled"
                @click="showCopyDialog(CopyAction.MOVE)"
                v-if="
                  store.getters.currentWorkspace?.root.myPrivileges.mayAdministrate &&
                  store.getters.workspaces.length > 1
                "
              >
                drive_file_move
              </span>
            </template>
            <template #tooltip>{{ $t("pvDocEditor.move") }}</template>
          </Tooltip>

          <Tooltip>
            <template v-slot>
              <span
                class="material-symbols-outlined filled"
                @click="showDeleteConfirmation"
                v-if="
                  store.getters.currentWorkspace?.root.myPrivileges
                    .mayAdministrate
                "
                data-test="delete-doc-ctrl"
              >
                delete
              </span>
            </template>
            <template #tooltip>{{ $t("pvDocEditor.delete") }}</template>
          </Tooltip>
        </div>
      </div>

      <Tabs :items="tabItems"></Tabs>

      <router-view></router-view>
    </template>

    <doc-copy-dialog
      v-if="copyDialogActive"
      :active="copyDialogActive"
      :doc="document!"
      :action="copyAction as string"
      @close="closeCopyDialog"
      @success="displayFlashMessage"
    />

    <document-delete-confirmation
      v-if="deleteConfirmationActive"
      :docId="docId"
      @close="closeDeleteConfirmation"
    />
  </div>
</template>

<style lang="scss" scoped>
$border-light: #ebebeb;
.page_content {
  height: 100%;
  position: relative;
}

.title_container {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.title_container .breadcrumbs {
  flex: 1;
}

.buttons {
  color: #4a4a4a;
}

.buttons a {
  color: inherit;
}

.buttons .material-symbols-outlined {
  font-size: 1.1em;
  border: 1px solid $border-light;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
  margin-left: 0.5em;
}

.buttons .material-symbols-outlined:hover {
  cursor: pointer;
  background: #f4f4f4;
}
</style>
