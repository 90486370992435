<script lang="ts" setup>
import {
  CrmContact,
  useCrmContactsQuery,
  CrmContactsOrderBy,
} from "@/graphql/operations";
import { computed, ref, reactive } from "vue";
import { Search, Tooltip } from "@/components";
import CrmContactInfo from "./CRMContactInfo.vue";
import { useDebounceFn } from "@vueuse/core";

const search = ref<string>("");
const currentSearch = ref<string>(search.value);
const limit = 100;
const offset = ref<number>(0);
const orderBy = computed<CrmContactsOrderBy[]>(() =>
  search.value.trim() === ""
    ? [CrmContactsOrderBy.LastNameAsc, CrmContactsOrderBy.FirstNameAsc]
    : []
);

const { data, executeQuery } = useCrmContactsQuery({
  variables: reactive({
    search: currentSearch,
    offset: offset,
    limit: limit,
    orderBy: orderBy,
  }),
  requestPolicy: "cache-and-network",
});

const debouncedFn = useDebounceFn(
  () => {
    currentSearch.value = search.value;
  },
  700,
  { maxWait: 5000 }
);

const crmContacts = computed(() => data.value?.searchCrmContactsByName?.nodes);
const hasNextPage = computed(
  () => data.value?.searchCrmContactsByName?.pageInfo?.hasNextPage
);
const hasPreviousPage = computed(
  () => data.value?.searchCrmContactsByName?.pageInfo?.hasPreviousPage
);

const searchStringReset = ref<boolean>(false);
const setSearchString = (str: string) => {
  search.value = str.trim();
  debouncedFn();
};

const goToPreviousPage = () => {
  offset.value = offset.value - limit;
};

const goToNextPage = () => {
  offset.value = offset.value + limit;
};
</script>

<template>
  <div class="page_content">
    <div class="title_container">
      <h3>{{ $t("crmContacts.title") }}</h3>

      <div class="search_container">
        <Search
          :placeholder="$t('crmContactSearch.search.placeholder')"
          :resetValue="searchStringReset"
          @change="setSearchString"
        />
      </div>
    </div>

    <div class="controls">
      <div class="control">
        <tooltip>
          <template v-slot>
            <span
              :class="{ 'material-symbols-outlined': true, disabled: !hasPreviousPage }"
              @click="goToPreviousPage"
            >
              chevron_left
            </span>
          </template>
          <template #tooltip>{{
            $t("crmContacts.pagination.previous")
          }}</template>
        </tooltip>
        <tooltip>
          <template v-slot>
            <span
              :class="{ 'material-symbols-outlined': true, disabled: !hasNextPage }"
              @click="goToNextPage"
            >
              chevron_right
            </span>
          </template>
          <template #tooltip>{{ $t("crmContacts.pagination.next") }}</template>
        </tooltip>
      </div>
    </div>

    <crm-contact-info
      v-for="contact in crmContacts"
      :key="contact!.id"
      :contact="contact! as CrmContact"
    />
  </div>
</template>

<style scoped>
.title_container {
  display: flex;
}

.title_container h3 {
  flex: 1;
  font-size: 1.1rem;
  font-weight: 500;
}

.search_container {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.control span {
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
}

.control span:hover {
  background-color: #f4f4f4;
}

.control span.disabled {
  pointer-events: none;
  cursor: initial;
  opacity: 0.3;
}
</style>
