const BASE_URL = import.meta.env.VITE_BACKEND_HOST || window.location.origin;

export function getStorageProxyUrl(originalUrl: string) {
  try {
    const u = new URL(originalUrl);
    if (u.host !== "firebasestorage.googleapis.com") {
      return originalUrl;
    }

    const parts = u.pathname.match(/\/v0\/b\/.*\.com\/(.*)/);
    if (!parts || parts.length !== 2) {
      return originalUrl;
    }

    const path = `${parts[1]}`;
    const proxyUrl = new URL(path, BASE_URL);
    proxyUrl.search = u.search;

    return proxyUrl.toString();
  } catch (e) {
    return originalUrl;
  }
}
