<script lang="ts" setup>
import { reactive, PropType, ref } from "vue";
import { Dropdown, UserAvatar, Modal } from "@/components";
import { Membership } from "@/graphql/operations"
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ChangeRoleDialog from "./ChangeRoleDialog.vue"

const { t } = useI18n({});
const store = useStore();

const currentUserId = ref(store.getters.user.postgresId);

const contextMenuItems = [
  { value: "change_role", title: t("teamDetail.members.changeRole.title") },
  { value: "remove", title: t("teamDetail.members.removeFromTeam.title") },
];

interface ComponentState {
  displayConfirmation: boolean;
  userToBeRemoved: string | null;
}

const props = defineProps({
  memberships: {
    type: Array as PropType<Array<Membership>>,
    required: true,
  },
  isAdmin: {
    type: Boolean,
  },
});

const emit = defineEmits(["accept", "decline", "removeUser", "reloadData"]);

const state: ComponentState = reactive({
  displayConfirmation: false,
  userToBeRemoved: null,
});

function acceptRequest(membershipId: string) {
  emit("accept", { id: membershipId });
}

function declineRequest(membershipId: string) {
  emit("decline", { id: membershipId });
}

const handleAction = (membership: Membership, action: string) => {
  switch (action) {
    case "remove":
      displayRemoveConfirmation(membership.id);
      break;

    case "change_role":
      displayChangeRoleDialog(membership);
      break;
  }
}

function displayRemoveConfirmation(membershipId: string) {
  state.displayConfirmation = true;
  state.userToBeRemoved = membershipId;
}

const changeRoleDialogActive = ref<boolean>(false);
const membershipToEdit = ref<Membership | null>(null)
function displayChangeRoleDialog(membership: Membership) {
  membershipToEdit.value = membership;
  changeRoleDialogActive.value = true;
}

function removeUser() {
  emit("removeUser", { id: state.userToBeRemoved });
  state.displayConfirmation = false;
}
</script>

<template>
  <div>
    <table class="data_table">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="membership in memberships" :key="membership.id">
          <td>
            <UserAvatar
              :email="membership!.user!.email"
              :name="membership!.user!.displayName!"
            />

            <div class="user_name">
              <div v-if="membership!.user!.displayName">
                {{ membership!.user!.displayName }}
              </div>
              <div class="small">
                {{ membership!.user!.email }}
              </div>
            </div>
          </td>
          <td>
            <span v-if="membership!.mayAdministrate">Admin</span>
            <span v-if="membership!.user!.firebaseAuthId === null">
              Not yet registered
            </span>
          </td>
          <td>
            <template v-if="isAdmin && membership!.user!.id !== currentUserId">
              <Dropdown
                class="right"
                :items="contextMenuItems"
                @change="(action) => handleAction(membership!, action)"
              >
                <span class="material-symbols-outlined">more_vert</span>
              </Dropdown>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <template v-if="state.displayConfirmation">
      <Modal :active="true" @close="state.displayConfirmation = false">
        <template v-slot:header>Remove User From Team</template>
        <template v-slot:body>
          Are you sure you want to remove the user from the team?
        </template>
        <template v-slot:footer>
          <div class="buttons">
            <button class="button primary" @click="removeUser">Yes</button>
            <button class="button" @click="state.displayConfirmation = false">
              Cancel
            </button>
          </div>
        </template>
      </Modal>
    </template>

    <change-role-dialog
      v-if="changeRoleDialogActive"
      :membership="membershipToEdit as Membership"
      @close="changeRoleDialogActive = false"
      @reloadData="emit('reloadData')">
    </change-role-dialog>
  </div>
</template>


<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  margin-right: 5px;
}

table thead tr th:last-child,
table tbody tr td:last-child {
  width: 30px;
}

table tbody tr:hover {
  background: #f9fafb;
}

table {
  margin-bottom: 25px;
}

.user_name {
  display: inline-block;
  vertical-align: middle;
}

.user_name div {
  line-height: 1.3;
}

.user_name .small {
  font-size: 0.8em;
}
</style>
