<script lang="ts" setup>
import {
  useUserContactDetailQuery,
  useUpdateContactDetailsMutation,
} from "@/graphql/operations";
import { computed, ref, Ref } from "vue";
import { FlashMessage, LoadingSpinner, UserAvatar } from "@/components";
import { getStorageProxyUrl } from "@/util";
import {
  getStorage,
  ref as reference,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuid } from "uuid";
import ImageCropModal from "./ImageCropModal.vue";

const storage = getStorage();

const { data, fetching } = useUserContactDetailQuery();
const userDetails = computed(
  () => data?.value?.myAccount?.nodes[0]?.userContactDetail
);

const requestState = ref<string>("");
const flashMessageActive = ref<boolean>(false);
const showFlashMessage = (state: string) => {
  requestState.value = state;
  flashMessageActive.value = true;
  window.setTimeout(() => (flashMessageActive.value = false), 2000);
};

const { executeMutation } = useUpdateContactDetailsMutation();
const submit = async () => {
  const result = await executeMutation({
    id: userDetails!.value!.id,
    displayName: userDetails!.value!.displayName,
    email: userDetails!.value!.email,
    jobTitle: userDetails!.value!.jobTitle,
    phone: userDetails!.value!.phone,
    profileImageUrl: userDetails!.value!.profileImageUrl,
  });

  if (!result.error) {
    displayImageCrop.value = false;
  }

  showFlashMessage(result.error === undefined ? "success" : "error");
};

const fileEndings: { [key: string]: string } = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
};

const imageUrl = ref<string | undefined>();
const displayImageCrop = ref<boolean>(false);

const currentFileEnding = ref<string | undefined>();

const imageError = ref<string | undefined>();

const fileInput: Ref<HTMLInputElement | null> = ref(null);
const selectImage = async () => {
  const files = fileInput?.value?.files;
  if (!files || files.length === 0) {
    return;
  }

  const file = files[0];
  if (!fileEndings.hasOwnProperty(file.type)) {
    imageError.value = "wrongType";
    return;
  }

  const maxFileSize = 1 * 1024 * 1024; // 1MB
  if (file.size > maxFileSize) {
    imageError.value = "tooLarge";
    return;
  }

  imageError.value = undefined;

  currentFileEnding.value = fileEndings[file.type];

  const reader = new FileReader();
  reader.onload = (e) => {
    if (e.target && e.target.result) {
      imageUrl.value = e.target.result as string;
      displayImageCrop.value = true;
      fileInput.value = null;
    }
  };
  reader.readAsDataURL(file);
};

const uploadImage = async (blob: Blob) => {
  const id = uuid();
  const filePath = `profile_images/${id}.${currentFileEnding.value}`;

  const imageRef = reference(storage, filePath);
  const r = await uploadBytes(imageRef, blob);
  const downloadUrl = await getDownloadURL(imageRef);
  userDetails!.value!.profileImageUrl = downloadUrl;
  submit();
};
</script>

<template>
  <div class="page_content">
    <FlashMessage :state="requestState" v-if="flashMessageActive">
      {{ $t(`profile.stateMessage.${requestState}`) }}
    </FlashMessage>

    <h3>{{ $t("profile.title") }}</h3>

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-else-if="userDetails">
      <div class="image_container">
        <div>
          <label class="label">{{ $t("profile.photo") }}</label>
        </div>

        <p v-if="imageError" class="help">
          {{ $t(`profile.fileSelect.error.${imageError}`) }}
        </p>

        <div class="flex_container">
          <UserAvatar
            class="large"
            :profileImgUrl="
              userDetails.profileImageUrl
                ? getStorageProxyUrl(userDetails.profileImageUrl)
                : undefined
            "
            :name="userDetails.displayName || undefined"
          />

          <form @submit.prevent="selectImage">
            <input
              @change="selectImage"
              id="file-selector"
              ref="fileInput"
              name="my-funny-file"
              type="file"
              style="display: none"
            />
            <label class="button secondary" for="file-selector">
              <span class="material-symbols-outlined">file_upload</span>
              {{ $t("profile.fileSelect.title") }}
            </label>
          </form>
        </div>
      </div>

      <form @submit.prevent="submit">
        <label class="label">{{ $t("profile.email") }}</label>
        <input class="input" type="text" :value="userDetails.email" disabled />

        <label class="label">{{ $t("profile.name") }}</label>
        <input class="input" type="text" v-model="userDetails.displayName" />

        <label class="label">{{ $t("profile.jobTitle") }}</label>
        <input class="input" type="text" v-model="userDetails.jobTitle" />

        <label class="label">{{ $t("profile.phone") }}</label>
        <input class="input" type="text" v-model="userDetails.phone" />

        <button class="button primary" @click="submit">
          <span class="material-symbols-outlined">save</span>
          {{ $t("profile.submit") }}
        </button>
      </form>
    </div>

    <div v-else>something went wrong</div>
  </div>

  <image-crop-modal
    v-if="displayImageCrop && imageUrl"
    :imageUrl="imageUrl"
    @close="displayImageCrop = false"
    @submit="uploadImage"
  />
</template>

<style lang="scss" scoped>
$border-strong: #d0d4d9;
form {
  max-width: 450px;
}

h3 {
  margin-top: 0;
}

label.label {
  font-size: 0.9em;
  line-height: 1.8;
  font-weight: 500;
}

form input {
  margin-bottom: 1.1em;
}

.flex_container {
  display: flex;
  align-items: end;
}

.image_container {
  max-width: 450px;
  border-bottom: 1px solid #d0d4d9;
  margin-bottom: 1.8em;
  padding-bottom: 1.5em;
}

.image_container .button {
  margin-left: 10px;
}

.page_content {
  height: 100%;
}

.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}

p.help {
  font-size: 0.9em;
  color: #f14668;
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  height: 0.8em;
}
</style>
