<template>
  <div>
    <div v-if="state === 'NO_VALUE'">
      <p class="info">
        <b>{{ $t("tenantUserSearch.emptyResult.header") }}</b>
        {{ $t("tenantUserSearch.emptyResult.info") }}
      </p>
    </div>

    <div v-else-if="state === 'INVALID_EMAIL'">
      <div class="field">
        {{ $t("tenantUserSearch.invalidEmail.info") }}
      </div>
    </div>

    <div v-else>
      <div class="field" @click="submit">
        <b>Einladen:</b>
        <span class="right">
          <span>{{ search }}</span>
          <span class="checkbox_container"><input type="checkbox" /></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable no-useless-escape */

import { defineComponent, computed } from "vue";

enum State {
  NO_VALUE = "NO_VALUE",
  INVALID_EMAIL = "INVALID_EMAIL",
  VALID_EMAIL = "VALID_EMAIL",
}

interface ComponentState {
  state: State;
}

function isValidEmail(str: string) {
  const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const m = str.toLowerCase().match(mailFormat);

  return m !== null;
}

function isBeginOfEmail(str: string) {
  return new RegExp("^.*@.*$").test(str);
}

export default defineComponent({
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    //const state: ComponentState = reactive({
    //  state: State.NO_VALUE
    //});

    const state = computed(() => {
      if (isValidEmail(props.search)) {
        return State.VALID_EMAIL;
      }

      if (isBeginOfEmail(props.search)) {
        return State.INVALID_EMAIL;
      }

      return State.NO_VALUE;
    });

    function submit() {
      context.emit("submit");
    }

    return {
      state,
      submit,
    };
  },
});

/* eslint-enable no-useless-escape */
</script>

<style scoped>
.info {
  text-align: center;
}

.info b {
  display: block;
}

.field {
  border-bottom: 1px solid #f4f4f4;
  background: #f4f4f4;
  height: 38px;
  line-height: 38px;
  padding: 0 5px;
}

.field .right {
  float: right;
}

.checkbox_container {
  margin-left: 5px;
}
</style>
