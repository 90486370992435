<script setup lang="ts">
import { computed, PropType } from "vue";
import {
  type DocumentSearchResultFragment,
  useDocumentsByWorkspaceQuery,
  useWorkspaceDetailsQuery,
} from "@/graphql/operations";
import { LoadingSpinner, Breadcrumbs } from "@/components";
import DocumentRow from "./DocumentRow.vue";

const props = defineProps({
  workspaceId: {
    type: String,
    required: true,
  },
  singleSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  onlyShareable: {
    type: Boolean,
    required: false,
    default: false,
  },
  selection: {
    type: Array as PropType<Array<String>>,
    required: true,
  },
  mustSupportFlows: {
    type: Boolean,
    required: false,
    default: false,
  },
  excluded: {
    type: Array as PropType<Array<String>>,
    required: false,
    default: [],
  },
  selectExcluded: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emits = defineEmits(["change"]);
const changeEmit = (doc: DocumentSearchResultFragment) => {
  if (isDisabled(doc)) {
    return;
  }

  emits("change", doc);
};

const { data: documentData, fetching } = useDocumentsByWorkspaceQuery({
  variables: computed(() => ({
    workspaceId: props.workspaceId,
  })),
  requestPolicy: "cache-and-network",
});

const documents = computed(() => {
  return documentData.value?.documents?.nodes;
});

const isDisabled = (doc: DocumentSearchResultFragment) => {
  return (
    (props.onlyShareable && !doc.doesSupportSharedspace) ||
    props.excluded.includes(doc.id) ||
    (props.mustSupportFlows && !doc.doesSupportFlows)
  );
};

const { data: workspaceData } = useWorkspaceDetailsQuery({
  variables: computed(() => ({
    id: props.workspaceId,
  })),
  requestPolicy: "cache-and-network",
});

const workspace = computed(() => workspaceData.value?.workspace);

const navItems = computed(() => {
  if (!workspace.value) {
    return [];
  }

  const ancestors = workspace.value?.ancestors?.nodes || [];
  const items: Array<{ text: string; href?: string }> = ancestors.map((a) => ({
    text: a!.name,
  }));

  items.push({
    text: workspace.value.name,
  });

  return items;
});
</script>

<template>
  <breadcrumbs :items="navItems"></breadcrumbs>

  <div v-if="fetching">
    <LoadingSpinner :color="'black'" />
  </div>

  <div v-else-if="documents?.length === 0" class="empty_result">
    {{ $t("documentSearch.emptyResult") }}
  </div>

  <div v-else class="scroll_container">
    <table class="clickable">
      <tbody>
        <document-row
          v-for="document in documents"
          :key="document!.id"
          :document="document as DocumentSearchResultFragment"
          :selected="(excluded.includes(document!.id) && selectExcluded) || selection.includes(document!.id)"
          :disabled="isDisabled(document!)"
          :disabledForFlows="mustSupportFlows && !(document!.doesSupportFlows)"
          :disabledForSpaces="onlyShareable && !(document!.doesSupportSharedspace)"
          :singleSelect="singleSelect"
          @select="changeEmit(document!)"
        />
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.empty_result {
  padding: 1em;
  text-align: center;
}

.breadcrumbs {
  font-size: 1em;
}

.scroll_container {
  height: 100%;
  overflow: scroll;
}
</style>
