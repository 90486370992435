<template>
  <form :class="{ search: true, focused: isFocused }" @submit.prevent="submit">
    <span class="icon material-symbols-outlined"> search </span>

    <input
      class="input"
      type="text"
      ref="inputElem"
      :placeholder="placeholder"
      v-model="search"
      @keydown="handleKeyEvent"
      @focus="handleFocus"
      @blur="handleBlur"
    />
    <button
      @click="clear"
      :class="{ hidden: !(displayExit || search.length > 0) }"
    >
      <span class="material-symbols-outlined"> close </span>
    </button>
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  placeholder: {
    type: String,
  },
  resetValue: {
    type: Boolean,
  },
  initialFocus: {
    type: Boolean,
    default: false,
  },
  displayExit: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits([
  "change",
  "submit",
  "arrowDown",
  "arrowUp",
  "enter",
  "clear",
  "focusUpdate",
]);

const search = ref("");

watch(search, (curValue, oldValue) => {
  emit("change", curValue);
});

watch(props, () => {
  if (props.resetValue) {
    search.value = "";
  }
});

function submit() {
  emit("submit", search.value);
}

function clear() {
  search.value = "";
  emit("clear", "");
}

function handleKeyEvent(ev: KeyboardEvent) {
  if (ev.code === "ArrowDown") {
    emit("arrowDown");
  } else if (ev.code === "ArrowUp") {
    emit("arrowUp");
  } else if (ev.code === "Enter") {
    ev.preventDefault();
    emit("enter", search.value);
  }
}

const inputElem = ref();
onMounted(() => {
  if (!inputElem.value) {
    return;
  }

  if (props.initialFocus) {
    inputElem.value!.focus();
  }
});

const isFocused = ref(false);
const handleFocus = () => {
  emit("focusUpdate", true);
  isFocused.value = true;
};

const handleBlur = () => {
  isFocused.value = false;
  window.setTimeout(() => {
    emit("focusUpdate", false);
  }, 200);
};
</script>

<style scoped>
.search {
  display: block;
  overflow: visible;
  border: 1px solid #bbb;
  border-radius: 3px;
  background-color: #fff;
  height: 35px;
  width: 100%;
}

.search:hover {
  border-color: #1a1918;
}

.search.focused {
  box-shadow: 0px 8px 32px rgba(30, 25, 25, 0.03),
    0px 8px 16px rgba(30, 25, 25, 0.03), 0px 4px 8px rgba(30, 25, 25, 0.03),
    0px 2px 4px rgba(30, 25, 25, 0.03), 0px 1px 2px rgba(30, 25, 25, 0.03);
  border-color: transparent;
}

input.input[type="text"] {
  float: left;
  border: none;
  border-radius: 0px;
  width: calc(100% - 110px);
  min-width: 300px;
  height: 35px;
}

input.input[type="text"]:active,
input.input[type="text"]:focus {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
}

button,
.icon {
  background-color: transparent;
  display: inline-block;
  font-weight: 600;
  line-height: 33px;
  color: #24292e;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 15px;
  user-select: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  float: left;
  border: none;
}

.material-symbols-outlined {
  vertical-align: middle;
  font-size: 1.3em;
}

.icon.material-symbols-outlined {
  font-size: 1em;
  color: #736c64;
  padding-right: 5px;
}

.search.focused .icon.material-symbols-outlined {
  color: #1a1918;
}

button.hidden {
  cursor: inital;
  pointer-events: none;
  visibility: hidden;
}

form button:last-child {
  float: right;
}
</style>
