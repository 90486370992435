<script lang="ts" setup>
import { onMounted } from "vue";
import { ref } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["cancel", "submit"]);

const inputElem = ref();

onMounted(() => {
  if (inputElem.value) {
    inputElem.value.focus();
  }
});

const inputValue = ref(props.value ?? "");

const submit = () => {
  emits("submit", inputValue.value);
};

const cancel = () => {
  emits("cancel");
  inputValue.value = props.value;
};

const handleKeydown = (event: KeyboardEvent) => {
  switch (event.key) {
    case "Enter":
      submit();
      break;

    case "Escape":
      cancel();
      break;
  }
};
</script>

<template>
  <div class="editable_input">
    <input v-model="inputValue" @keydown="handleKeydown" ref="inputElem" />
    <div class="icons">
      <span
        class="material-symbols-outlined icon primary"
        role="button"
        @click="submit"
      >
        check
      </span>
      <span
        class="material-symbols-outlined icon"
        role="button"
        @click="cancel"
      >
        close
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editable_input {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fafafa;
  border-bottom: 1px solid $color-secondary;
  padding: 0 8px;
}

input {
  flex: 1;
  border: none;
  outline: none;
  padding: 12px 0;
  background: #fafafa;
}

.icons {
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.icon.primary {
  color: $color-secondary;
}

.icon:hover {
  cursor: pointer;
}
</style>
