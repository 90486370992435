<script lang="ts" setup>
import { ref, PropType, watch } from "vue";
import {
  useUpdateDocumentSpaceNameMutation,
  useUpdateDocumentSpaceMessageMutation,
  DocumentSpace,
} from "@/graphql/operations";
import { EditableInput, Modal } from "@/components";
import { onClickOutside } from '@vueuse/core'
import { onMounted } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { onBeforeUnmount } from "vue";

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const emit = defineEmits(["success"]);

const textElem = ref();

const name = ref<string>(props.documentSpace.name || "");
const currentName = ref<string>(props.documentSpace.name || "");
const personalMessage = ref<string>(props.documentSpace.personalMessage || "");
const currentPersonalMessage = ref<string>(
  props.documentSpace.personalMessage || ""
);

watch(props, () => {
  if (props.documentSpace.name) {
    if (props.documentSpace.name !== name.value) {
      if (currentName.value === name.value) {
        currentName.value = props.documentSpace.name;
      }
      name.value = props.documentSpace.name;
    }
  }

  if (props.documentSpace.personalMessage) {
    if (props.documentSpace.personalMessage !== personalMessage.value) {
      if (currentPersonalMessage.value === personalMessage.value) {
        currentPersonalMessage.value = props.documentSpace.personalMessage;
      }

      personalMessage.value = props.documentSpace.personalMessage;
    }
  }
});

const validateName = (value: string, callback: Function) => {
  const isValid = value.length > 0;
  if (isValid) {
    updateName(value);
    editMode.value = false;
  }
  //callback(isValid);
  //if (!isValid) {
  //  currentName.value = props.documentSpace.name ?? "";
  //  name.value = props.documentSpace.name ?? "";
  //}
};

const { executeMutation: executeUpdateName } =
  useUpdateDocumentSpaceNameMutation();
const updateName = async (newName: string) => {
  await executeUpdateName({
    id: props.documentSpace.id,
    name: newName,
  });
  emit("success");
};

const { executeMutation: executeUpdateMessage } =
  useUpdateDocumentSpaceMessageMutation();

const updateMessage = async () => {
  console.log("update message")
  const res = await executeUpdateMessage({
    id: props.documentSpace.id,
    message: currentPersonalMessage.value || "",
  });

  emit("success");
  unfocusText();
  displayConfirmation.value = false;
};

const editMode = ref(false); 

const cancel = () => {
  currentPersonalMessage.value = personalMessage.value;
  unfocusText();
  displayConfirmation.value = false;
}

const unfocusText = () => {
  textElem.value.classList.remove("active");
}

const focusText = () => {
  textElem.value.classList.add("active");
}

onClickOutside(textElem, () => {
  if (currentPersonalMessage.value !== personalMessage.value) {
    displayConfirmation.value = true;
  }
})

const displayConfirmation = ref(false);

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (currentPersonalMessage.value !== personalMessage.value) {
    event.preventDefault();
    return '';
  }
}

onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
})
</script>

<template>
  <div class="texts">
    <div class="inner">
      <EditableInput
        v-if="editMode"
        :value="currentName"
        @submit="validateName"
        @cancel="editMode = false"
      />
      <div v-else class="container clickable" @click="editMode = true">
        <h2>
          {{ currentName }}
        </h2>
        <span class="material-symbols-outlined"> edit </span>
      </div>
    </div>

    <div class="inner">
      <form @submit.prevent ref="textElem" @click="focusText">
        <textarea
          v-model="currentPersonalMessage"
          class="textarea"
          :placeholder="$t('documentSpace.personalMessage.placeholder')"
        />
        <div class="icons">
      <span
        class="material-symbols-outlined icon primary"
        role="button"
        @click.stop="updateMessage"
        :class="{ disabled: currentPersonalMessage === personalMessage }"
      >
        check
      </span>
      <span
        class="material-symbols-outlined icon"
        role="button"
        @click.stop="cancel"
      >
        close
      </span>
    </div>
      </form>
    </div>
  </div>

  <Modal :active="displayConfirmation">
    <template #body>
      {{ $t("documentSpace.personalMessage.unsavedChanges") }}
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button" @click="cancel">{{ $t("documentSpace.personalMessage.discard") }}</button>
        <button class="button primary" @click="updateMessage">{{ $t("documentSpace.personalMessage.save") }}</button>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.texts {
  margin: 0.5em 0 0 0;
}

.inner {
  padding: 0 1em;
  margin-top: 0.5em;
}

textarea {
  max-width: 100%;
  min-height: 10em;
  margin-bottom: 5px;
}

.container {
  display: flex;
  align-items: center;
}

h2 {
  line-height: 2.8rem;
  font-size: 1.2rem;
  margin: 0;
  font-weight: 500;
}

.material-symbols-outlined {
  font-size: 1rem;
  padding: 0.5em;
}

.clickable:hover {
  cursor: pointer;
}

form {
  position: relative;
}

form .icons {
  position: absolute;
  bottom: .6em;
  right: .6em;
  display: none;
}

form .icons .icon {
  font-size: 1.7rem;
  padding: 0;
}

.icon.primary {
  color: $color-secondary;
}

.icon:hover {
  cursor: pointer;
}

form.active .icons {
  display: block;
}

.icon.disabled {
  opacity: .5;
  pointer-events: none;
}
</style>
