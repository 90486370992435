<script lang="ts" setup>
import { Breadcrumbs, LoadingSpinner, Tooltip } from "@/components";
import { computed, PropType } from "vue";
import {
  DocumentFilter,
  DocumentCondition,
  useSearchDocumentsQuery,
  type DocumentSearchResultFragment,
} from "@/graphql/operations";
import DocumentRow from "./DocumentRow.vue";

const props = defineProps({
  filter: {
    type: String,
    required: true,
  },
  singleSelect: {
    type: Boolean,
    required: false,
    default: false,
  },
  onlyShareable: {
    type: Boolean,
    required: false,
    default: false,
  },
  mustSupportFlows: {
    type: Boolean,
    required: false,
    default: false,
  },
  selection: {
    type: Array as PropType<Array<String>>,
    required: true,
  },
  excluded: {
    type: Array as PropType<Array<String>>,
    required: false,
    default: [],
  },
  selectExcluded: {
    type: Boolean,
    required: false,
    default: true,
  },
  mustBeAdmin: {
    type: Boolean,
    required: false,
    default: false,
  },
  rootId: {
    type: String,
    required: false,
    default: null,
  },
});

const emits = defineEmits(["change"]);

const changeEmit = (doc: DocumentSearchResultFragment) => {
  if (isDisabled(doc)) {
    return;
  }

  emits("change", doc);
};

const isDisabled = (doc: DocumentSearchResultFragment) => {
  return (
    (props.onlyShareable && !doc.doesSupportSharedspace) ||
    props.excluded.includes(doc.id) ||
    (props.mustSupportFlows && !doc.doesSupportFlows)
  );
};

const { data: documentData, fetching } = useSearchDocumentsQuery({
  variables: {
    // Some `props.attribute` must be wrapped in a computed reference to further propagate changes. This is to avoid stale data or reactivity issues.
    term: computed(() => props.filter),
    mustBeAdmin: computed(() => props.mustBeAdmin),
    rootId: computed(() => props.rootId),
  },
  requestPolicy: "cache-and-network",
});

const documents = computed(() => {
  return documentData.value?.searchDocuments?.nodes;
});
</script>

<template>
  <div v-if="fetching">
    <LoadingSpinner :color="'black'" />
  </div>

  <div v-else-if="documents?.length === 0" class="empty_result">
    {{ $t("documentSearch.emptyResult") }}
  </div>

  <div v-else data-test="document-search-result">
    <table class="clickable">
      <tbody>
        <document-row
          v-for="document in documents"
          :key="document!.document!.id"
          :document="document!.document as DocumentSearchResultFragment"
          :selected="(excluded.includes(document!.document!.id) && selectExcluded) || selection.includes(document!.document!.id)"
          :disabled="isDisabled(document!.document!)"
          :singleSelect="singleSelect"
          @select="changeEmit(document!.document!)"
          :match="document?.matchInDisplayTitle ?? undefined"
          :data-test="`document-${document!.document!.displayTitle}`"
        />
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.loading_status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls {
  padding: 1em 0;
  border-bottom: 1px solid #dbdbdb;
}

.control {
  margin-right: 1em;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.empty_result {
  padding: 1em;
  text-align: center;
}
</style>
