<script lang="ts" setup>
import { PropType, ref, computed } from "vue";
import {
  useCloneDocumentSpaceMutation,
  type DocumentSpace,
} from "@/graphql/operations";
import { useI18n } from "vue-i18n";
import { Dropdown, Tooltip, UpgradeModal } from "@/components";
import { Formatter } from "@/util";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DeleteSpaceModal from "../DeleteSpaceModal.vue";
import { useSpaceModalDisplay } from "@/composables";

const {
  displayCopyDocumentSpaceModal,
  displayUpgradeModal,
  handleModalDisplay,
} = useSpaceModalDisplay();

const emits = defineEmits(["reloadData"]);

const { t } = useI18n({});
const router = useRouter();
const store = useStore();

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
});

const contextMenuItems = computed(() => {
  const i = [{ value: "copy", title: t("documentSpaces.copy") }];

  const myAccess = props.documentSpace.documentSpaceAccesses.nodes.find(
    (a) => a?.user?.id === store.getters.user.postgresId
  );

  if (myAccess && myAccess.role === "owner") {
    i.push({ value: "remove", title: t("documentSpaces.delete") });
  }

  return i;
});

const displayConfirmation = ref<boolean>(false);
const handleAction = async (action: string) => {
  if (action === "remove") {
    displayConfirmation.value = true;
  } else {
    await handleModalDisplay("copy");
    if (displayCopyDocumentSpaceModal.value) {
      copy();
    }
  }
};

const { executeMutation: executeCopy } = useCloneDocumentSpaceMutation();
const copy = async () => {
  const result = await executeCopy({
    id: props.documentSpace.id,
    newName: t("documentSpaces.copyName", { name: props.documentSpace.name }),
  });

  if (!result.data?.cloneDocumentSpace?.clonedSpace?.id) {
    //TODO handle error
    return;
  }

  const url = router.resolve({
    name: "DocumentSpace",
    params: { id: result.data?.cloneDocumentSpace?.clonedSpace.id },
  });

  window.open(url.href, "_blank")?.focus();
};

const deleteSpace = async () => {
  displayConfirmation.value = false;
  emits("reloadData");
};
</script>

<template>
  <tr>
    <td>
      <router-link
        :to="{
          name: 'DocumentSpace',
          params: { id: documentSpace.id },
        }"
      >
        {{ documentSpace.name }}

        <Tooltip v-if="documentSpace.documentSpaceAccesses.nodes.length > 1">
          <template v-slot>
            <span class="material-symbols-outlined"> group </span>
          </template>
          <template #tooltip>
            {{ $t("documentSpaces.sharedDoc") }}
          </template>
        </Tooltip>
        <span v-if="store.getters.numSpaceConfigs > 1" class="badge">
          {{ documentSpace.config?.name }}
        </span>
      </router-link>
    </td>
    <td>
      <router-link
        :to="{
          name: 'DocumentSpace',
          params: { id: documentSpace.id },
        }"
      >
        {{
          Formatter.formatDate(
            new Date(documentSpace.updatedAt),
            "%Y-%m-%d %H:%M"
          )
        }}
      </router-link>
    </td>
    <td>
      <router-link
        :to="{
          name: 'DocumentSpace',
          params: { id: documentSpace.id },
        }"
      >
        {{
          $t(
            "documentSpaces.numDocuments",
            documentSpace.documentSpaceItems?.totalCount || 0
          )
        }}
      </router-link>
    </td>
    <td>
      <Dropdown class="right" :items="contextMenuItems" @change="handleAction">
        <span class="material-symbols-outlined">more_vert</span>
      </Dropdown>
    </td>
  </tr>
  <delete-space-modal
    v-if="displayConfirmation"
    :active="true"
    :space-id="documentSpace.id"
    @close="displayConfirmation = false"
    @success="deleteSpace"
  />

  <upgrade-modal
    v-if="displayUpgradeModal"
    @close="displayUpgradeModal = false"
  />
</template>

<style scoped lang="scss">
table a {
  text-decoration: none;
  width: 100%;
  color: inherit;
  font-weight: normal;
}

table thead tr th:last-child,
table tbody tr td:last-child {
  width: 30px;
}

table td .material-symbols-outlined {
  font-size: 1.3em;
  border-radius: 50%;
  padding: 0.2em;
}

table td .material-symbols-outlined {
  font-size: 1.3em;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.tooltip_container {
  margin-left: 0.5em;
}

td a {
  display: inline-block;
}

.badge {
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  background: #d6fafb;
  font-size: 0.7em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-left: 0.5em;
}
</style>
