<template>
  <transition name="fade">
    <div v-if="visible" class="flash_message" :class="type">{{ message }}</div>
  </transition>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const visible = ref(false);
const type = ref("");
const message = ref("");

function show(msg: string, msgType: string) {
  visible.value = true;
  message.value = msg;
  type.value = msgType;

  setTimeout(() => {
    visible.value = false;
  }, 3000);
}

defineExpose({ show });
</script>

<style scoped lang="scss">
.flash_message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1001;
  font-size: 0.9em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  padding: 0 16px;
  line-height: 3em;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  opacity: 1;
}

.flash_message.success {
  border: 1px solid #48c78e;
}

.flash_message.error {
  box-shadow: 0 0 0 1px #e0b4b4;
  background-color: #fff6f6;
  color: #9f3a38;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
