function hash(str: string): number {
  return [...str]
    .map(c => c.charCodeAt(0))
    .reduce((acc, curValue) => acc + curValue);
}

type SystemColors = {
  [key: string]: string;
};

const systemColors: SystemColors = {
  SYSTEM_RED: "rgb(255, 69, 58)",
  SYSTEM_BLUE: "rgb(10, 132, 255)",
  SYSTEM_TEAL: "rgb(64, 200, 224)",
  SYSTEM_YELLOW: "rgb(255, 214, 10)",
  SYSTEM_ORANGE: "rgb(255, 159, 10)",
  SYSTEM_GREEN: "rgb(48, 209, 88)",
  SYSTEM_PINK: "rgb(255, 55, 95)",
  SYSTEM_PURPLE: "rgb(191, 90, 242)",
  SYSTEM_INDIGO: "rgb(94, 92, 230)"
};

export class ColorUtil {
  static getIOSSystemColorForName(name: string): string {
    const h = hash(name);
    const colors = Object.keys(systemColors);
    const idx = h % colors.length;
    return systemColors[colors[idx]];
  }

  static getIOSSystemColor() {
    return null;
  }
}
