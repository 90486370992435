<script lang="ts" setup>
import { ref, unref, computed, watch } from "vue";
import {
  useUsageAnalyticsQuery,
  useSpaceUsageAnalyticsQuery,
} from "@/graphql/operations";
import { Dropdown, Breadcrumbs, Tooltip, LoadingSpinner } from "@/components";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const route = useRoute();

const currentYear = new Date().getFullYear();
const yearValues = [currentYear, currentYear - 1, currentYear - 2].map((y) => ({
  value: y.toString(),
  title: y.toString(),
}));

const year = ref<number>(
  route.query.y ? parseInt(route.query.y as string, 10) : currentYear
);
const alias = ref<string | undefined>(
  route.query.a ? (route.query.a as string) : undefined
);

const updateParams = () => {
  router.push({
    name: "UsageAnalytics",
    query: { a: alias.value, y: year.value },
  });
};

const updateYear = (value: string) => {
  year.value = parseInt(value, 10);
  updateParams();
};

const { data, fetching } = useUsageAnalyticsQuery({
  variables: computed(() => {
    return {
      year: unref(year),
    };
  }),
  requestPolicy: "cache-and-network",
});

const { data: spaceData, fetching: spaceFetching } =
  useSpaceUsageAnalyticsQuery({
    variables: computed(() => {
      return {
        year: unref(year),
      };
    }),
    requestPolicy: "cache-and-network",
  });

const aliases = computed(() =>
  data.value?.maUsageByTeamsAndYears?.nodes?.map((n) => ({
    title: n!.analyticsAlias!,
    value: n!.analyticsAlias!,
  }))
);

watch(aliases, () => {
  if (!alias.value && aliases.value && aliases.value.length > 0) {
    alias.value = aliases.value[0]!.title!;
  }
});

const updateAlias = (value: string) => {
  alias.value = value;
  updateParams();
};

const analytics = computed(() =>
  store.getters.user.hasAllTimeMembershipUsageAnalytics()
    ? data.value?.maUsageByTeamsAllTimeMembershipAndYears?.nodes?.find(
        (n) => n?.analyticsAlias === alias.value
      )
    : data.value?.maUsageByTeamsAndYears?.nodes.find(
        (n) => n?.analyticsAlias === alias.value
      )
);

const userAnalytics = computed(() =>
  data.value?.maUsageByTeams?.nodes?.find(
    (n) => n?.analyticsAlias === alias.value
  )
);

const spaceAnalytics = computed(() =>
  spaceData.value?.maSpaceUsageByTeamsAndYears?.nodes?.find(
    (n) => n?.analyticsAlias === alias.value
  )
);

const emptySpaceAnalytics = computed(() => {
  if (!spaceAnalytics.value) {
    return true;
  }

  return (
    parseInt(spaceAnalytics.value.nTotalSpaceVisits!, 10) === 0 &&
    parseInt(spaceAnalytics.value.nTotalDocumentVisits!, 10) === 0 &&
    parseInt(spaceAnalytics.value.nTotalDownloads!, 10) === 0 &&
    parseInt(spaceAnalytics.value.nTotalClicks!, 10) === 0
  );
});

const navItems = computed(() => [
  {
    text: "Usage Analytics",
    href: "/u/analytics/usage",
  },
  {
    text: alias.value,
  },
]);

const hideNewUsersColumns = computed(() => {
  return store.getters.user.hideNewUsersColumnInUsageAnalytics();
});
</script>

<template>
  <div class="pane page_content">
    <div class="title_container">
      <breadcrumbs :items="navItems"></breadcrumbs>

      <div class="controls">
        <Dropdown
          v-if="aliases"
          :items="aliases"
          :value="alias"
          :displayTitle="true"
          @change="updateAlias"
        />
      </div>
    </div>

    <div v-if="fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>

    <div v-if="userAnalytics">
      <section>
        <h4>
          {{ $t("usageAnalytics.users.title") }}
        </h4>

        <div class="cards" v-if="userAnalytics">
          <div class="card">
            <h3>
              {{ $t("usageAnalytics.nUsersTotal.title") }}
              <Tooltip>
                <template v-slot>
                  <span class="material-symbols-outlined"> help </span>
                </template>
                <template #tooltip>
                  {{ $t("usageAnalytics.nUsersTotal.info") }}
                </template>
              </Tooltip>
            </h3>
            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.total")
              }}</span>
              <div class="value">{{ userAnalytics.nUsersTotal }}</div>
            </div>

            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.registered")
              }}</span>
              <div class="value">{{ userAnalytics.nUsersTotalRegistered }}</div>
            </div>
          </div>
          <div class="card" v-if="!hideNewUsersColumns">
            <h3>{{ $t("usageAnalytics.nNewUsersInLastSixMonths.title") }}</h3>
            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.total")
              }}</span>
              <div class="value">
                {{ userAnalytics.nNewUsersCreatedInLastSixMonths }}
              </div>
            </div>

            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.registered")
              }}</span>
              <div class="value">
                {{ userAnalytics.nNewUsersRegisteredInLastSixMonths }}
              </div>
            </div>
          </div>
          <div class="card" v-if="!hideNewUsersColumns">
            <h3>{{ $t("usageAnalytics.nNewUsersInLastYear.title") }}</h3>
            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.total")
              }}</span>
              <div class="value">
                {{ userAnalytics.nNewUsersCreatedInLastYear }}
              </div>
            </div>

            <div>
              <span class="value_title">{{
                $t("usageAnalytics.users.registered")
              }}</span>
              <div class="value">
                {{ userAnalytics.nNewUsersRegisteredInLastYear }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="!fetching">
      <div class="section_controls">
        <h3>{{ $t("usageAnalytics.byYear.title") }}</h3>
        <Dropdown
          :items="yearValues"
          :value="year.toString()"
          :displayTitle="true"
          @change="updateYear"
        />
      </div>
    </div>

    <section v-if="analytics">
      <h4>{{ $t("usageAnalytics.app.title") }}</h4>
      <div class="cards">
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nTimesOpenedTheApp.title") }}
            <Tooltip>
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nTimesOpenedTheApp.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ analytics.nTimesOpenedTheApp }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nUsersThatOpenedTheAppAtLeastOnce.title") }}
            <Tooltip>
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{
                  $t("usageAnalytics.nUsersThatOpenedTheAppAtLeastOnce.info")
                }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ analytics.nUsersThatOpenedTheAppAtLeastOnce }}
          </div>
        </div>
      </div>
    </section>

    <section v-if="analytics">
      <h4>{{ $t("usageAnalytics.documents.title") }}</h4>
      <div class="cards">
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nTimesOpenedADocument.title") }}
            <Tooltip class="multiline">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>{{
                $t("usageAnalytics.nTimesOpenedADocument.info")
              }}</template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ analytics.nTimesOpenedADocument }}
          </div>
        </div>
        <div class="card">
          <h3>{{ $t("usageAnalytics.nUploads.title") }}</h3>
          <div class="value">
            {{ analytics.nUploads }}
          </div>
        </div>
      </div>
    </section>

    <section
      v-else-if="!spaceFetching && spaceAnalytics && emptySpaceAnalytics"
      class="centered"
    >
      <span class="material-symbols-outlined greater_icon"> analytics </span>
      <h3>{{ $t("usageAnalytics.noData.title") }}</h3>
      <span>
        {{ $t("usageAnalytics.noData.text") }}
      </span>
    </section>

    <section v-if="!spaceFetching && spaceAnalytics && !emptySpaceAnalytics">
      <h4>{{ $t("usageAnalytics.documentSpaces.title") }}</h4>
      <div class="cards">
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nSpaces.title") }}
            <Tooltip>
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nSpaces.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ spaceAnalytics.nTotalSpaces }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nSpaceVisits.title") }}
            <Tooltip class="multiline">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nSpaceVisits.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ spaceAnalytics.nTotalSpaceVisits }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nDocumentVisits.title") }}
            <Tooltip class="multiline right">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nDocumentVisits.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ spaceAnalytics.nTotalDocumentVisits }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nDownloads.title") }}
            <Tooltip class="multiline">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nDownloads.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ spaceAnalytics.nTotalDownloads }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nLinkClicks.title") }}
            <Tooltip class="multiline">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nLinkClicks.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ spaceAnalytics.nTotalClicks }}
          </div>
        </div>
      </div>
    </section>

    <section v-if="analytics">
      <h4>{{ $t("usageAnalytics.presentations.title") }}</h4>
      <div class="cards">
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nPresentations.title") }}
            <Tooltip class="multiline">
              <template v-slot>
                <span class="material-symbols-outlined"> help </span>
              </template>
              <template #tooltip>
                {{ $t("usageAnalytics.nPresentations.info") }}
              </template>
            </Tooltip>
          </h3>
          <div class="value">
            {{ analytics.nPresentations }}
          </div>
        </div>
        <div class="card">
          <h3>
            {{ $t("usageAnalytics.nUsersThatPresentedAtLeastOnce.title") }}
          </h3>
          <div class="value">
            {{ analytics.nUsersThatPresentedAtLeastOnce }}
          </div>
        </div>
      </div>
    </section>

    <div v-if="!fetching && !analytics && !userAnalytics">
      <p class="info">
        {{ $t("usageAnalytics.notFound") }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
$border-light: #ebebeb;
$border-strong: #cecece;

.page_content {
  font-size: 0.9rem;
}

.title_container {
  display: flex;
}

.title_container .breadcrumbs {
  flex: 1;
}

.controls .dropdown {
  margin-left: 0.8em;
}

.section_controls {
  display: flex;
  margin-top: 2em;
  padding-top: 1.8em;
  border-top: 1px solid #cecece;
}

.section_controls h3 {
  font-size: 1.1em;
  margin: 0;
  line-height: 37px;
  margin-right: 0.5em;
}

section {
  margin-top: 2em;
}

.section_controls + section {
  margin-top: 1em;
}

section h4 {
  margin-top: 0;
  margin-bottom: 0.7em;
}

.cards {
  display: grid;
  grid-template-columns: 31.5% 31.5% 31.5%;
  column-gap: 2.6%;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1em;
}

.card {
  border-radius: 0.3em;
  border: 1px solid $border-strong;
  box-sizing: border-box;
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  font-size: 0.9rem;
  background: #fff;
  padding: 1rem 1.2rem 1rem 1.2rem;
}

.card h3 {
  font-size: 1.1em;
  color: #8a8a8a;
  font-weight: 400;
  margin-top: 0;
}

.card h3 .material-symbols-outlined,
h4 .material-symbols-outlined {
  font-size: 1em;
}

.card .value {
  font-size: 1.3em;
  font-weight: 500;
}

.card .value_title {
  width: 80px;
  display: inline-block;
}

.value_title + .value {
  display: inline-block;
}

h4 .tooltip_container {
  font-weight: 400;
}

.loading_status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.greater_icon {
  font-size: 5em;
}
</style>
