<script lang="ts" setup>
import { PropType, computed } from "vue";
import {
  DocumentSpaceItem,
  useDeleteDocumentSpaceItemMutation,
  useUpdateDocumentSpaceItemPositionMutation,
} from "@/graphql/operations";
import { Dropdown } from "@/components";
import { getStorageProxyUrl, MimeType, Formatter } from "@/util";

const props = defineProps({
  documents: {
    type: Array as PropType<Array<DocumentSpaceItem>>,
    required: true,
  },
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const contextMenuItems = [{ value: "remove", title: "Remove Document" }];

const emit = defineEmits(["success"]);

const { executeMutation: deleteDocumentSpaceItem } =
  useDeleteDocumentSpaceItemMutation();

const removeDocument = async (docId: string) => {
  await deleteDocumentSpaceItem({
    docId: docId,
    spaceId: props.documentSpaceId,
  });
  emit("success");
};

const sortedDocuments = computed(() => {
  return props.documents;
});

const { executeMutation: executeUpdatePosition } =
  useUpdateDocumentSpaceItemPositionMutation();

const updatePosition = async (
  targetIndex: number,
  sourceIndex: number,
  id: string
) => {
  let newPosition;
  const docs = sortedDocuments.value;
  const docLength = docs.length;

  if (targetIndex === 0) {
    // Placing at the beginning of the list, make it higher than the current highest
    newPosition = parseFloat(docs[0].position) + 1;
  } else if (targetIndex >= docLength) {
    const lastPosition = parseFloat(docs[docLength - 1].position);
    // Placing at the end of the list, ensure it's still a positive number, less than the current smallest
    newPosition = lastPosition > 1 ? lastPosition - 1 : lastPosition / 2;
  } else {
    // Calculating position when placed between two documents
    const nextDocPosition = parseFloat(docs[targetIndex - 1].position); // Document immediately after the drop spot
    const prevDocPosition = parseFloat(docs[targetIndex].position); // Document immediately before the drop spot
    newPosition = (nextDocPosition + prevDocPosition) / 2;

    // Correct for precision issues if newPosition coincides with next or prev
    if (newPosition === nextDocPosition || newPosition === prevDocPosition) {
      newPosition =
        prevDocPosition - Math.abs(nextDocPosition - prevDocPosition) / 10; // Small decrement
    }
  }

  const res = await executeUpdatePosition({
    docId: id,
    spaceId: props.documentSpaceId,
    position: newPosition,
  });

  emit("success");
};
</script>

<template>
  <div class="document_list">
      <li
        v-for="(doc, idx) in sortedDocuments"
        class="row"
        :key="doc.id"
        :data-id="doc.id"
        :data-idx="idx"
        :data-index="idx"
      >
        <div class="cell thumbnail_container">
          <router-link
            :to="{ name: 'StackedProjector', params: { 'workspaceId': doc.document!.workspaceId }, query: {'fid': doc.document!.firestoreId}  }"
            target="_blank"
            draggable="false"
          >

            <img
              class="thumbnail_image"
              :src="getStorageProxyUrl(doc.document!.thumbnailDownloadUrl)"
            />
          </router-link>
        </div>

        <div class="cell grow center">
          <router-link
            :to="{ name: 'StackedProjector', params: { 'workspaceId': doc.document!.workspaceId }, query: {'fid': doc.document!.firestoreId}  }"
            target="_blank"
            draggable="false"
          >
            <div>
              {{ doc.document!.displayTitle }}
            </div>
            <div class="info">
              <template v-if="MimeType.isVideo(doc.document!.mimeType)">
                <span>{{
                  Formatter.formatDuration(doc.document!.pages)
                }}</span>
              </template>
              <template v-else>
                <span>{{
                  $t("documentSearch.document.numPages", {
                    count: doc.document!.pages,
                  })
                }}</span>
              </template>
              <span> - </span>
              <span>
                {{ MimeType.getName(doc.document!.mimeType) }}
              </span>
            </div>
          </router-link>
        </div>

        <div class="cell center">
          <Dropdown
            class="right"
            :items="contextMenuItems"
            @change="removeDocument(doc.document!.id)"
          >
            <span class="material-symbols-outlined">more_vert</span>
          </Dropdown>
        </div>
      </li>
  </div>
</template>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}

.thumbnail_container {
  height: 60px;
  width: 60px;
  margin-right: 0.5em;
}

.thumbnail_container a {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  display: inline-block;
}

.draggable img.thumbnail_image {
  object-fit: contain;
  border-radius: 4%;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
}

.info {
  color: rgba(82, 74, 62, 0.82);
  font-size: 0.9em;
}
</style>
