import { ref, computed, watch, Ref, readonly } from "vue";
import { db } from "@/fire";
import { doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { createGlobalState, useSessionStorage } from "@vueuse/core";
import { useFirestore } from "@vueuse/firebase";
import { v4 as uuid4 } from "uuid";
import { Presentation } from "@/models";
import { useStore } from "vuex";
import { DownloadableDocumentFragment } from "@/graphql/operations";

export const useProjector = createGlobalState(() => {
  const store = useStore();
  const generateCode = () => Math.floor(1000 + Math.random() * 9000).toString();

  const isEnabled = useSessionStorage("projector-enabled", false);
  const presentationId = useSessionStorage<string | null>(
    "presentation-id",
    null
  );
  const code = useSessionStorage<string | null>("presentation-code", null);
  const document = ref<DownloadableDocumentFragment>();
  const page = ref(1);
  const videoState = ref<{ state: string, position: number } | null>(null);

  const presentation = useFirestore(
    computed(
      () =>
        !!presentationId.value && doc(db, "presentations", presentationId.value)
    ),
    undefined
  ) as Ref<Presentation | null | undefined>;

  const brandURL = computed(
    () =>
      store.getters.user.mergedConfig?.brandUrl ??
      "https://firebasestorage.googleapis.com/v0/b/remote-a0246.appspot.com/o/logoimages%2Fpitchview_logo.png?alt=media&token=9d01c852-7acb-41f5-94b8-35878f8cf13d"
  );
  const shareLink = computed(
    () => store.getters.user.mergedConfig?.shareLink ?? "projektor.live"
  );

  watch(isEnabled, (nowIsEnabled) => {
    presentationId.value = nowIsEnabled ? uuid4() : null;
    code.value = nowIsEnabled ? generateCode() : null;
  });

  watch(presentationId, (newPresentationId, oldPresentationId) => {
    if (newPresentationId && !oldPresentationId) {
      const documentData: any = {
        pages: document.value?.pages ?? null,
        page: page.value,
        uploadedAt: serverTimestamp(),
        name: "__placeholder_name__",
        code: code.value ?? "invalid",
        isViewOnly: true,
        isPaused: !document.value?.downloadUrl,
        lastPageUpdateId: uuid4(),
        url: document.value?.downloadUrl ?? "placeholder",
        brandURL: brandURL.value,
        mimeType: document.value?.mimeType ?? "",
        documentId: document.value?.firestoreId ?? "placeholder",
      };

      if (document.value?.mimeType !== "application/pdf" && videoState.value) {
        documentData.page = -1;
        documentData.video = {
          state: videoState.value.state,
          stateChangedAt: Date.now(),
          stateChangedAtPosition: videoState.value.position
        }
      }

      setDoc(doc(db, "presentations", newPresentationId), documentData);
    }

    if (oldPresentationId && !newPresentationId) {
      updateDoc(doc(db, "presentations", oldPresentationId), {
        isPaused: true,
        url: "placeholder",
        code: "invalid",
        lastPageUpdateId: uuid4(),
        documentId: "placeholder"
      });
    }
  });

  watch([document, page], ([currentDocument, currentPage]) => {
    const id = presentationId.value;
    if (!id) return;

    if (currentDocument && currentDocument.deletedAt === null) {
      updateDoc(doc(db, "presentations", id), {
        url: currentDocument.downloadUrl,
        isPaused: false,
        page: currentDocument.mimeType === "application/pdf" ? currentPage : -1,
        lastPageUpdateId: uuid4(),
        mimeType: currentDocument.mimeType ?? "",
        documentId: currentDocument.firestoreId
      });
    }

    if (!currentDocument) {
      updateDoc(doc(db, "presentations", id), {
        url: "placeholder",
        isPaused: true,
        page: currentPage ?? null, // currentPage might be undefined, and undefined is an unsupported type in firestore
        lastPageUpdateId: uuid4(),
        documentId: "placeholder"
      });
    }
  });

  watch(videoState, (newVideoState, oldVideoState) => {
    const id = presentationId.value;
    if (!id) return;


    if (newVideoState) {
      updateDoc(doc(db, "presentations", id), {
        lastPageUpdateId: uuid4(),
        video: {
          state: newVideoState.state,
          stateChangedAt: serverTimestamp(),
          stateChangedAtPosition: newVideoState.position
        }
      });
    }
  });

  return {
    isEnabled,
    document,
    page,
    presentation,
    code: readonly(code),
    presentationId: readonly(presentationId),
    brandURL,
    shareLink,
    videoState
  };
});
