<script lang="ts" setup>
import { ref, reactive, computed, watch } from "vue";
import { useSearchDocumentSpacesQuery } from "@/graphql/operations";
import { useDebounceFn } from "@vueuse/core";
import { Formatter } from "@/util";
import { LoadingSpinner } from "@/components";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  searchTerm: {
    type: String,
    required: true,
  },
});

const currentSearch = ref<string>(props.searchTerm || "");

const { data, fetching } = useSearchDocumentSpacesQuery({
  variables: reactive({
    term: currentSearch,
  }),
  requestPolicy: "cache-and-network",
});

const totalCount = computed(() => data.value?.searchDocumentSpaces?.totalCount);
const matches = computed(() => data.value?.searchDocumentSpaces?.nodes);

const debouncedFn = useDebounceFn(
  () => {
    currentSearch.value = props.searchTerm;
  },
  700,
  { maxWait: 5000 }
);

watch(props, () => {
  debouncedFn();
});
</script>

<template>
  <div>
    <div v-if="!matches && fetching" class="loading_status">
      <LoadingSpinner :color="'black'" />
    </div>
    <template
      v-else-if="matches && matches.length > 0 && searchTerm.length > 0"
    >
      <div class="title_container">
        <h3>
          {{ $t("documentSpaces.search.totalCount", { count: totalCount }) }}
        </h3>
        <div class="controls">
          <div class="control">
            <label
              >{{ $t("documentSpaces.search.controls.sortBy.label") }}:</label
            >
            <span class="value">{{
              $t("documentSpaces.search.controls.sortBy.mostRelevant.label")
            }}</span>
          </div>
        </div>
      </div>

      <table class="data_table clickable">
        <thead>
          <th>{{ $t("documentSpaces.columns.name") }}</th>
          <th></th>
          <th>{{ $t("documentSpaces.columns.lastModified") }}</th>
        </thead>
        <tbody>
          <tr v-for="match in matches" :key="match!.documentSpace!.id">
            <td>
              <router-link
                :to="{
                name: 'DocumentSpace',
                params: { id: match!.documentSpace!.id },
              }"
              >
                <div class="name">
                  <span
                    v-if="match!.matchInName"
                    v-html="match!.matchInName"
                  ></span>
                  <span v-else>
                    {{ match!.documentSpace?.name }}
                  </span>

                  <span v-if="store.getters.numSpaceConfigs > 1" class="badge">
                    {{ match!.documentSpace?.config?.name }}
                  </span>
                </div>
                <div class="name_subtitle">
                  <span
                    v-if="match!.matchInPersonalMessage"
                    v-html="match!.matchInPersonalMessage"
                  ></span>
                  <span v-else>
                    {{ match!.documentSpace?.personalMessage }}
                  </span>
                </div>
              </router-link>
            </td>
            <td>
              <div v-for="(document, i) in match?.documentMatches" :key="i">
                <img :src="document?.document?.thumbnailDownloadUrl" />
                <span
                  v-if="document?.matchInFileName"
                  class="doc_name"
                  v-html="document?.matchInFileName"
                ></span>
                <span
                  v-else
                  class="doc_name"
                  v-html="document?.matchInDisplayTitle"
                ></span>
              </div>
            </td>
            <td>
              <div>
                {{
                  Formatter.formatDate(
                    new Date(match!.documentSpace?.updatedAt),
                    "%Y-%m-%d %H:%M"
                  )
                }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template v-else-if="searchTerm.length === 0">
      <div class="title_container">
        <h3>Find your Shared Spaces</h3>
      </div>
      <div>Please enter a search term</div>
    </template>

    <template v-else>
      <div class="title_container">
        <h3>
          {{ $t("documentSpaces.search.totalCount", { count: 0 }) }}
        </h3>
      </div>
      <div>
        <h4>No results found</h4>
        <p>Try different keywords</p>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.title_container {
  margin-top: 1.3em;
  margin-bottom: 0.5em;
  display: flex;
}

.title_container h3 {
  font-size: 1.1rem;
  font-weight: 500;
  flex: 1;
}

table.data_table thead th:first-child {
  padding-left: 0;
}

table.data_table {
  box-shadow: none;
}

.search {
  max-width: 765px;
}

table a {
  color: #444;
  text-decoration: none;
}

table.data_table tbody td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1.7;
  vertical-align: middle;
}

td div {
  line-height: 1.7;
  padding: 0.2em 0;
}

td span {
  display: inline-block;
}
td span ::v-deep b {
  font-weight: inherit;
  background-color: #deebff;
  display: inline-block;
}

.name {
  font-weight: bold;
}

.name_subtitle {
  font-size: 0.9em;
}

img {
  height: 40px;
  width: auto;
  display: block;
  border: 1px solid #bbb5ae;
}

.doc_name {
  font-size: 0.9em;
}

.controls {
  display: flex;
}

.control {
  margin-left: auto;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.control .value {
  background-color: #ececec;
  border-radius: 999px;
  padding: 0 1em;
  line-height: 2.5em;
  height: 2.5em;
  margin-left: 0.3em;
}

.loading_status {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  background: #d6fafb;
  font-size: 0.7em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-left: 0.5em;
  font-weight: 400;
}
</style>
