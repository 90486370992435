<script lang="ts" setup>
import { PropType } from "vue";
import { CrmContact } from "@/graphql/operations";
import { Modal } from "@/components";

const props = defineProps({
  crmContact: {
    type: Object as PropType<CrmContact>,
    required: true,
  },
});

const emits = defineEmits(["close"]);
</script>

<template>
  <Modal :active="true" @close="emits('close')">
    <template #header> </template>

    <template #body>
      <h3>{{ crmContact.firstName }} {{ crmContact.lastName }}</h3>
      <h5 v-if="crmContact.territory">{{ crmContact.territory }}</h5>

      <div class="info_row" v-if="crmContact.email">
        <span class="material-symbols-outlined">email</span>
        {{ crmContact.email }}
      </div>
      <div class="info_row" v-if="crmContact.mobile">
        <span class="material-symbols-outlined">call</span> {{ crmContact.mobile }}
      </div>
      <div class="info_row" v-if="crmContact.organization">
        <span class="material-symbols-outlined">corporate_fare</span>
        {{ crmContact.organization }}
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.info_row {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
}

.material-symbols-outlined {
  font-size: 1em;
  margin-top: 2px;
  vertical-align: middle;
  margin-right: 0.5em;
}

h3 {
  margin: 0 0 5px 0;
}

h5 {
  margin-top: 0;
  font-weight: normal;
  font-size: 1em;
}
</style>
