<template>
  <div class="public_header" :class="{ grey: isThemeGrey }">
    <div class="nav_left">
      <Brand />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Brand from "@/components/Brand.vue";

const route = useRoute();

const isThemeGrey = computed(() => route.meta.theme === "grey");
</script>

<style lang="scss" scoped>
$logo-height: 34px;

.logo {
  height: $logo-height;
}

.public_header {
  padding: 10px 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.public_header.grey {
  border: none;
  background-color: #eff1f4;
}

.nav_left {
  display: flex;
  flex: 0;
  align-self: stretch;
  align-items: center;
}

.nav_right {
  margin-left: auto;
}

a {
  line-height: $logo-height;
  text-decoration: none;
  color: inherit;
}

.nav_middle {
  flex: 1;
}

.menu {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  color: #21395f;
  font-size: 0.9em;
}

.menu li {
  display: inline-block;
  padding: 0px 15px;
  line-height: 34px;
}

.menu li a {
  color: inherit;
  text-decoration: none;
}

.menu li a.router-link-exact-active {
  color: #36b6b9;
}
</style>
