<script lang="ts" setup>
import { Modal, FlashMessage } from "@/components";
import { Membership, useChangeRoleMutation } from "@/graphql/operations";
import { PropType, ref } from "vue";

const emit = defineEmits(["close", "reloadData"]);
const props = defineProps({
  membership: {
    type: Object as PropType<Membership>,
    required: true
  }
})

const initialRole = props.membership.mayAdministrate ? "admin" : "member";
const selectedRole = ref<string>(initialRole);

const modalActive = ref<boolean>(true);
const flashMessageActive = ref<boolean>(false);
const isError = ref<boolean>(false);

const { executeMutation } = useChangeRoleMutation();
const submit = async () => {
  const res = await executeMutation({
    id: props.membership.id,
    mayAdministrate: selectedRole.value === "admin"
  });

  isError.value = res.error !== undefined;
  flashMessageActive.value = true;
  modalActive.value = false;
  emit("reloadData");

  window.setTimeout(() => {
    flashMessageActive.value = false;
    emit("close");
  }, 1500);
}
</script>

<template>
  <div>
    <flash-message
      v-if="flashMessageActive"
      :state="isError ? 'error' : 'success'"
      :message="$t(`teamDetail.members.changeRole.${isError ? 'error' : 'success'}`)" />

    <Modal :active="modalActive" @close="emit('close')">
      <template v-slot:header>
        {{ $t("teamDetail.members.changeRole.header", { name: membership.user?.displayName }) }}
      </template>
      <template v-slot:body>
        <div class="inner">
          <div>{{ $t("teamDetail.members.changeRole.label") }}</div>

          <form>
            <div>
              <div class="form_checkbox">
                <label for="admin">
                  <input type="radio" id="admin" value="admin" v-model="selectedRole" />
                  {{ $t("teamDetail.members.changeRole.admin.label")}}
                </label>
                <p class="note">{{ $t("teamDetail.members.changeRole.admin.info")}}</p>
              </div>

              <div class="form_checkbox">
                <label for="member">
                  <input type="radio" id="member" value="member" v-model="selectedRole" />
                  {{ $t("teamDetail.members.changeRole.member.label")}}
                </label>
                <p class="note">{{ $t("teamDetail.members.changeRole.member.info")}}</p>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-slot:footer>
        <div class="buttons">
          <button
            :class="{ button: true, dangerous: true, disabled: selectedRole === initialRole }"
            @click="submit"
          >
            {{ $t("teamDetail.members.changeRole.submit") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style scoped>
.inner {
  font-size: .9em;
}

.form_checkbox {
  padding-left: 20px;
  margin: 15px 0;
  vertical-align: middle;
}

.form_checkbox label {
  position: static;
  font-weight: bold;
}

.form_checkbox label input {
  float: left;
  margin: 2px 0 0 -20px;
  vertical-align: middle;
}

p {
  font-size: .9em;
  color: #736c64;
  margin-top: .3em;
}
</style>