<script lang="ts" setup>
import { PropType, ref, computed } from "vue";
import {
  type DocumentSpaceSection,
  type DocumentSpaceItem,
  SpaceItemMoveType,
  useMoveSpaceItemMutation,
  useCreateDocumentSpaceItemMutation,
  useMoveSpaceItemToSectionMutation,
} from "@/graphql/operations";
import { DocumentSearchModal, Tooltip, Draggable } from "@/components";
import DocumentList from "./DocumentList.vue";
import CreateSectionModal from "./CreateSectionModal.vue";
import SectionControls from "./SectionControls.vue";

const props = defineProps({
  sections: {
    type: Array as PropType<Array<DocumentSpaceSection>>,
    required: true,
  },
  documentSpaceId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["success"]);

const handleSuccess = () => {
  emits("success");
};
const { executeMutation: createDocumentSpaceItem } =
  useCreateDocumentSpaceItemMutation();

const addDocuments = async (docIds: Array<string>) => {
  if (!sectionId.value) {
    return;
  }

  for (const docId of docIds) {
    await createDocumentSpaceItem({
      docId: docId,
      spaceId: props.documentSpaceId,
      sectionId: sectionId.value,
    });
  }

  closeSearch();
  handleSuccess();
};

const displaySearch = ref<boolean>(false);
const sectionId = ref<string>();

const openSearch = (id: string) => {
  sectionId.value = id;
  displaySearch.value = true;
};
const closeSearch = () => (displaySearch.value = false);

const documentIds = computed(() =>
  props.sections
    .map((s) =>
      s!.documentSpaceItemsBySectionId!.nodes!.map((d) => d!.document!.id)
    )
    ?.flat()
);

const displayCreateModal = ref<boolean>(false);

const { executeMutation: moveItem } = useMoveSpaceItemMutation();
const placeAbove = async (id: string, targetId: string) => {
  const res = await moveItem({
    withId: id,
    targetItemId: targetId,
    location: SpaceItemMoveType.Before,
  });

  if (res.error) {
  } else {
    handleSuccess();
  }
};

const placeBelow = async (id: string, targetId: string) => {
  const res = await moveItem({
    withId: id,
    targetItemId: targetId,
    location: SpaceItemMoveType.After,
  });

  if (res.error) {
  } else {
    handleSuccess();
  }
};

const { executeMutation: moveItemToSection } =
  useMoveSpaceItemToSectionMutation();
const moveToSection = async (itemId: string, sectionId: string) => {
  const res = await moveItemToSection({
    withId: itemId,
    targetSectionId: sectionId
  });

  if (res.error) {
  } else {
    handleSuccess();
  }
};

const hiddenSections = ref<Array<string>>([]);
const toggleVisibility = (sectionId: string) => {
  if (hiddenSections.value.includes(sectionId)) {
    hiddenSections.value.splice(hiddenSections.value.indexOf(sectionId), 1);
  } else {
    hiddenSections.value.push(sectionId);
  }
};

const sectionNames = computed(() => props.sections.map((s) => s.title));
</script>

<template>
  <div class="container">
    <Draggable
      @placeBelow="placeBelow"
      @placeAbove="placeAbove"
      :numItems="documentIds.length"
      @placeInDropzone="moveToSection"
    >
      <div v-for="(section, idx) in sections" class="content_container">
        <SectionControls
          :title="section.title"
          :id="section.id"
          :spaceId="documentSpaceId"
          :numSections="sections.length"
          :sectionIdx="idx"
          :numItems="section.documentSpaceItemsBySectionId.nodes.length"
          :sectionNames="sectionNames"
          @success="handleSuccess"
          @toggleVisibility="toggleVisibility(section!.id)"
          :isVisible="!hiddenSections.includes(section!.id)"
        />

        <template v-if="!hiddenSections.includes(section!.id)">
          <template
            v-if="section.documentSpaceItemsBySectionId.nodes.length === 0"
          >
            <div class="dropzone" :data-id="section!.id" data-dropzone>
              <div class="action_row">
                <span
                  class="material-symbols-outlined"
                  @click="openSearch(section.id)"
                >
                  add
                </span>
                <div class="cell" @click="openSearch(section.id)">
                  {{ $t("documentSpace.addDocument.buttonText") }}
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="action_row">
              <span
                class="material-symbols-outlined"
                @click="openSearch(section.id)"
              >
                add
              </span>
              <div class="cell" @click="openSearch(section.id)">
                {{ $t("documentSpace.addDocument.buttonText") }}
              </div>
            </div>

            <DocumentList
              :documents="section.documentSpaceItemsBySectionId.nodes as Array<DocumentSpaceItem>"
              :documentSpaceId="documentSpaceId"
              @success="handleSuccess"
            />
          </template>
        </template>
      </div>
    </Draggable>

    <div @click="displayCreateModal = true" class="link">
      <span class="material-symbols-outlined"> playlist_add </span>
      {{ $t("documentSpace.sections.add") }}
    </div>
  </div>
  <template v-if="displaySearch">
    <DocumentSearchModal
      @close="closeSearch"
      @submit="addDocuments"
      :onlyShareable="true"
      :excluded="documentIds"
    />
  </template>

  <CreateSectionModal
    v-if="displayCreateModal"
    :documentSpaceId="documentSpaceId"
    :sectionNames="sectionNames"
    @close="displayCreateModal = false"
    @success="handleSuccess"
  />
</template>

<style lang="scss" scoped>
.container {
  padding: 1em;
}

.content_container {
  margin-bottom: 1.8em;
}

.action_row {
  background: #fafafa;
  width: 100%;
  display: flex;
  padding: 0.5em 0;
  font-size: 0.9rem;
  align-items: center;
}

.action_row:hover {
  cursor: pointer;
}

.action_row .cell {
  padding: 0 0.5em 0 1em;
  line-height: 2.4em;
  flex: 1;
  color: $color-secondary;
}

.action_row .material-symbols-outlined {
  color: $color-secondary;
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 2.5em;
  margin-bottom: 1em;
  font-size: 1.1em;
  font-weight: 500;
}

.link .material-symbols-outlined {
  margin-right: 0.3em;
}
</style>
