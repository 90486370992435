<template>
  <DocumentSpacePicker
    v-if="!actionBarActive"
    :documentId="docId"
    @close="emitClose"
    @submit="submit"
  />

  <BottomActionBar v-if="actionBarActive">
    <template #top>
      <div class="action_bar_progress" ref="undoProgress"></div>
    </template>
    <template #action>
      <template v-if="selectedSpaces.length === 1">
        <label>{{ $t("documentSpacePicker.undoBar.resultLabel.single") }}</label>:
        <router-link class="link" :to="{ name: 'DocumentSpace', params: { id: selectedSpaces[0] } }">
          {{ $t("documentSpacePicker.undoBar.goToSpace") }}
        </router-link>
      </template>
      <template v-else>
        <label>{{ $t("documentSpacePicker.undoBar.resultLabel.multiple", { count: selectedSpaces.length}) }}</label>
      </template>
    </template>
    <template #cancel>
      <button class="button right dangerous" @click="undo">{{ $t("undo") }}</button>
    </template>
  </BottomActionBar>
</template>

<script setup lang="ts">
import { DocumentSpacePicker, BottomActionBar } from "@/components";
import { ref, onBeforeUnmount } from "vue";
import { useCreateSpaceItemsMutation } from "@/graphql/operations";
import { useDocumentStore } from "@/store/document";

const documentStore = useDocumentStore();

const emit = defineEmits(["close", "update"]);
const emitClose = () => emit("close");

const props = defineProps({
  docId: {
    type: String,
    required: false,
  },
});

const undoProgress = ref();

const { executeMutation: createDocumentSpaceItem } =
  useCreateSpaceItemsMutation();

const actionBarActive = ref<boolean>(false);

const hasOutstandingSave = ref(false);
const selectedSpaces = ref<Array<string>>([]);

let timeoutId: number | null;
const submit = (spaceIds: Array<string>) => {
  selectedSpaces.value = spaceIds;
  actionBarActive.value = true;
  hasOutstandingSave.value = true;

  timeoutId = window.setTimeout(async () => {
    await save();
    emit("close");
  }, 5000);
};

const undo = () => {
  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }

  hasOutstandingSave.value = false;
  emit("close");
};

const save = async () => {
  if (!hasOutstandingSave.value) {
    return;
  }

  const targets = selectedSpaces.value.map((id: string) => ({ spaceId: id }));

  const documentId = props.docId ? props.docId : documentStore.id;
  await createDocumentSpaceItem({
    documentId: documentId!,
    targets: targets,
  });

  hasOutstandingSave.value = false;
  emit("close");
};

window.addEventListener("beforeunload", save, false);

onBeforeUnmount(async () => {
  if (timeoutId) {
    window.clearTimeout(timeoutId);
    timeoutId = null;
  }

  window.removeEventListener("beforeunload", save, false);
  await save();
});
</script>

<style lang="scss" scoped>
.link {
  color: #fff;
  margin-left: .3em;
}

.action_bar_progress {
  background-color: #D34A43;
  height: 4px;
  width: 0px;
  animation: fillProgress 5s ease-in-out forwards;
}

@keyframes fillProgress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
