<script lang="ts" setup>
import { ref, PropType, computed, watch } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["submit", "changeState"]);

const text = ref<string | null>(props.text);
watch(() => props.text, (newText, oldText) => {
  if (newText !== oldText) {
    text.value = newText;
  }
})

const hasChanges = computed(() => text.value !== props.text);
watch(hasChanges, () => { emits("changeState", hasChanges.value) })

const submit = async () => {
  emits("submit", text.value);
};
</script>

<template>
  <div class="toolbar_body">
    <div class="row">
      <textarea class="textarea" v-model="text"></textarea>
    </div>
  </div>
  <div class="toolbar_footer" @click="submit">
    <div class="button primary" :class="{ disabled: !hasChanges }">
      {{ $t("templateEditor.toolbar.save") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar_body {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  height: calc(100vh - 60px - 60px - 82px);
  box-sizing: border-box;
  overflow-y: scroll;
}

.toolbar_footer {
  padding: 1em;
  font-size: 0.9rem;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}

.material-symbols-outlined {
  font-size: 1.1rem;
  cursor: pointer;
}

.label_container {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}

.label_container label {
  flex: 1;
  font-weight: 500;
}

.label_container .link {
  font-weight: normal;
}

.row {
  margin-bottom: 1.5em;
}

.image {
  background: #e0e0e0;
  height: 70px;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

input[type="checkbox"] {
  margin-right: 0.5em;
}
</style>
