<script lang="ts" setup>
import { Tooltip, ShareDocumentModal } from "@/components";
import { useDocumentStore } from "@/store/document";
import { ref } from "vue";

const documentStore = useDocumentStore();
const documentSpacePickerActive = ref<boolean>(false);
</script>

<template>
  <div class="title_container">
    <div class="right">
      <div :class="{ disabled: !documentStore.isShareable }">
        <tooltip class="bottom">
          <template v-slot>
            <span
              class="material-symbols-outlined icon clickable"
              @click="documentSpacePickerActive = true"
            >
              share
            </span>
          </template>
          <template #tooltip>
            <span v-if="documentStore.isShareable">
              {{ $t("navbar.shareDocument.label") }}
            </span>
            <span v-else>
              {{ $t("navbar.shareDocument.notShareable") }}
            </span>
          </template>
        </tooltip>
      </div>
    </div>
  </div>

  <ShareDocumentModal 
    v-if="documentSpacePickerActive"
    :docId="documentStore.id ?? undefined"
    @close="documentSpacePickerActive = false"
  />
</template>

<style scoped lang="scss">
.title_container {
  display: flex;
  align-items: center;
  margin: 1.5em 1.5em 5px 1.5em;
}

.right {
  margin-left: auto;
}

.icon {
  font-size: 1.1em;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  padding: 0.58em;
  line-height: 1;
  margin-left: 0.5em;
}

.icon:hover {
  cursor: pointer;
  background: #f4f4f4;
}
</style>
