export enum MembershipState {
  ACTIVE = "active",
  INVITED = "invited",
  WAITS_FOR_ACCESS = "waits_for_access"
}

interface Membership {
  currentState: MembershipState;
  id: number;
  mayAdministrate: boolean;
}

export class Team {
  id: number;
  name: string;
  isVisible: boolean;
  memberCount: number;
  myMembership: Membership | null;

  static fromResult(data: any) {
    const memberships = data.memberships
      ? data.memberships
      : data.userMemberships;
    const myMembership = data.myMembership
      ? {
          currentState: data.myMembership.currentState,
          id: data.myMembership.id,
          mayAdministrate: data.myMembership.mayAdministrate
        }
      : null;

    return new Team(
      data.id,
      data.name,
      data.isVisible,
      memberships.totalCount,
      myMembership
    );
  }

  constructor(
    id: number,
    name: string,
    isVisible: boolean,
    memberCount: number,
    myMembership: Membership | null
  ) {
    this.id = id;
    this.name = name;
    this.isVisible = isVisible;
    this.memberCount = memberCount;
    this.myMembership = myMembership;
  }
}
