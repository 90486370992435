<script lang="ts" setup>
import { PropType, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Modal, UserChip } from "@/components";
import {
  DocumentSpaceAccess,
  useTransferOwnershipMutation,
} from "@/graphql/operations";
import AccessDropdown from "./AccessDropdown.vue";

const { t } = useI18n();

const emits = defineEmits(["close", "refreshData"]);

const props = defineProps({
  newOwner: {
    type: Object as PropType<DocumentSpaceAccess>,
    required: true,
  },
  visibleUserCount: {
    type: Number,
    required: true,
  },
  visibleUserLimit: {
    type: Number,
    required: true,
  },
});

const defaultVisibility = computed(() =>
  props.visibleUserCount < props.visibleUserLimit ||
  props.newOwner.displayContactDetails
    ? "visible"
    : "hidden"
);

const items = computed(() => {
  const i = [];
  i.push({
    value: "hidden",
    title: t("documentSpace.access.hideDetails.title"),
    info: "Contact details won't be displayed in the shared space",
    displayCurrentSelection: true,
  });

  if (
    props.visibleUserCount < props.visibleUserLimit ||
    props.newOwner.displayContactDetails
  ) {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.info"),
      displayCurrentSelection: true,
    });
  } else {
    i.push({
      value: "visible",
      title: t("documentSpace.access.displayDetails.title"),
      info: t("documentSpace.access.displayDetails.disabled.info"),
      disabled: true,
      displayCurrentSelection: true,
    });
  }

  return i;
});

const visibility = ref<string>(defaultVisibility.value);
const setVisibility = (v: string) => {
  visibility.value = v;
};

const { executeMutation: executeTransferOwnership } =
  useTransferOwnershipMutation();

const submit = async () => {
  const result = await executeTransferOwnership({
    accessId: props.newOwner.id,
    displayContactDetails: visibility.value === "visible",
  });

  if (result.error) {
    //TODO handle error;
  }

  emits("refreshData");
  emits("close");
};
</script>

<template>
  <modal :active="true" @close="emits('close')">
    <template #header>
      <span
        class="material-symbols-outlined left clickable"
        @click="emits('close')"
      >
        keyboard_backspace
      </span>
      {{ $t("documentSpace.access.transferOwnership.title") }}
    </template>

    <template #body>
      <div class="inner">
        <div class="flex_container">
          <user-chip
            :displayName="newOwner.user!.displayName!"
            :email="newOwner.user!.email!"
            :profileImgUrl="newOwner.user!.userContactDetail?.profileImageUrl || undefined"
          />
          <access-dropdown
            class="settings"
            :items="items"
            :value="visibility"
            @change="setVisibility"
          />
        </div>
        <div class="message info">
          {{ $t("documentSpace.access.transferOwnership.info") }}
        </div>
      </div>
    </template>

    <template #footer>
      <div class="buttons">
        <div class="button primary" @click="submit">
          {{ $t("documentSpace.access.transferOwnership.submit") }}
        </div>
      </div>
    </template>
  </modal>
</template>

<style scoped lang="scss">
.info {
  margin-top: 1em;
}

.material-symbols-outlined.left {
  margin-right: 0.3em;
}

.material-symbols-outlined.clickable:hover {
  cursor: pointer;
}
</style>
