<template>
  <div class="page_content">
    <div v-if="loading">lädt... {{ loading }}</div>

    <div v-else-if="errorWithUsers">
      Ein unvorhergesehener Fehler: {{ errorWithUsers }}
    </div>

    <div v-else>
      <h3>{{ $t("joinableTeams.header") }}</h3>
      <div v-if="myJoinableTeams && myJoinableTeams.length === 0">
        <p>Wir konnten keine Teams finden, denen du beitreten kannst</p>
      </div>

      <div class="columns" v-else>
        <TeamCard
          v-for="team in myJoinableTeams"
          :key="team.id"
          :team="team"
          @join="joinTeam"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import {
  useMyJoinableTeamsQuery,
  useJoinTeamMutation,
} from "@/graphql/operations";
import { TeamCard } from "@/components";
import { Team } from "@/models";

const {
  fetching: loading,
  error: errorWithUsers,
  data: usersResult,
} = useMyJoinableTeamsQuery();

const myJoinableTeams = computed(() =>
  usersResult?.value?.myJoinableTeams?.nodes.map((t) => Team.fromResult(t))
);

//FIXME refetch joinable teams
const { executeMutation: joinTeam, fetching: currentlyJoining } =
  useJoinTeamMutation();
</script>

<style lang="scss" scoped>
$content_height: 150px;

.columns {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: $content-height;
  column-gap: 20px;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
}

h3 {
  font-size: 1.1rem;
  font-weight: 500;
}

</style>
