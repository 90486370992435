<script lang="ts" setup>
import { ref } from "vue";
import { Modal, ImageCrop } from "@/components";

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  },
});

const emits = defineEmits(["close", "submit"]);

const cropperElem = ref();

const submit = () => {
  if (!cropperElem.value) {
    return;
  }

  cropperElem.value.submit();
}

const handleCrop = (blob: Blob, dataUrl: string) => {
  emits("submit", blob, dataUrl);
}
</script>

<template>
  <Modal :active="true" @close="emits('close')" :scrolling="true" class="large">
    <template #header>
      {{ $t("templateEditor.toolbar.banner.image.crop.title") }}
    </template>

    <template #body>
      <p>
      {{ $t("templateEditor.toolbar.banner.image.crop.info") }}
      </p>
      <image-crop
      :imageUrl="imageUrl"
      :aspectRatio="4.28"
      ref="cropperElem"
      @submit="handleCrop"
    />
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="submit">
      {{ $t("templateEditor.toolbar.banner.image.crop.submit") }}
        </button>
        <button class="button" @click="emits('close')">
      {{ $t("templateEditor.toolbar.banner.image.crop.cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.cropper {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}
</style>