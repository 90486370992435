<script lang="ts" setup>
import { ref } from "vue";
import { Search, CreateDocumentSpaceModal, UpgradeModal } from "@/components";
import DocumentSpaceSearch from "./DocumentSpaceSearch.vue";
import DocumentSpaceOverview from "./Overview.vue";
import { useSpaceModalDisplay } from "@/composables";

const emits = defineEmits(["contentLoaded"]);

const {
  displayCreateDocumentSpaceModal,
  displayUpgradeModal,
  handleModalDisplay,
} = useSpaceModalDisplay();

const searchActive = ref<Boolean>(false);
const handleSearchFocus = (value: Boolean) => {
  searchActive.value = value;
};

const searchTerm = ref<string>("");
const setSearch = (val: string) => {
  searchTerm.value = val;
};
</script>

<template>
  <div class="pane page_content">
    <div class="title_bar">
      <Search
        :initialFocus="false"
        @change="setSearch"
        :placeholder="$t('documentSpaces.search.placeholder')"
      />
      <div class="control_container">
        <button class="button primary" @click="handleModalDisplay('create')">
          <span class="material-symbols-outlined">add</span>
          {{ $t("documentSpaces.createNew") }}
        </button>
      </div>
    </div>

    <template v-if="searchTerm.length > 0">
      <document-space-search :searchTerm="searchTerm" />
    </template>

    <template v-else>
      <document-space-overview
        @contentLoaded="emits('contentLoaded')"
        @onCreate="displayCreateDocumentSpaceModal = true"
      />
    </template>
  </div>

  <create-document-space-modal
    v-if="displayCreateDocumentSpaceModal"
    @close="displayCreateDocumentSpaceModal = false"
  />

  <upgrade-modal
    v-if="displayUpgradeModal"
    @close="displayUpgradeModal = false"
  />
</template>

<style scoped>
.title_bar {
  display: flex;
  align-items: center;
}

.title_bar .control_container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

button {
  font-size: 0.9em;
}

.search {
  max-width: 765px;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.pane {
  height: 100%;
}

.page_content {
  height: 100%;
}
</style>
