<template>
  <!--
  <pdf-js-presenter
    document="https://firebasestorage.googleapis.com/v0/b/pitchview-projector.appspot.com/o/9552e88a-65a2-4f42-9edf-45b8b5637630.png?alt=media&token=a17d53a6-2d8e-4d80-aca4-29bb022e2d6e"
  />
  <pdf-js-presenter
  document="https://firebasestorage.googleapis.com/v0/b/pitchview-projector.appspot.com/o/858134bc1a0cb9d01a3d4a42b4c0ee66.pdf?alt=media&token=53bb7f06-6ecb-4403-aade-f7c71ffcc5b8"
  :page="pageNumber"
  :scale="4"
  :zoom="{ top: 0.0, bottom: 1.0, left: 0.0, right: 1.0 }"
  />
-->
  <div class="grid-wrapper">
    <pdf-js-presenter
      document="https://firebasestorage.googleapis.com:443/v0/b/pitchview-projector.appspot.com/o/A8C9099C-9160-477D-A82C-D7F948CEF6FB.pdf?alt=media&token=27514f43-9ffe-4ad5-80d1-a5a3f576ea02"
      v-model:page="pageNumber"
      :scale="4"
      :zoom="{ top: 0.5, bottom: 1.0, left: 0.5, right: 1.0 }"
      enable-annotations
      enable-text-selection
    />
  </div>
  <button @click="pageNumber = pageNumber + 1">+</button>
  <button @click="pageNumber = pageNumber - 1">-</button>
  <div>{{ pageNumber }}</div>
</template>

<script lang="ts" setup>
import PdfJsPresenter from "@/components/PdfJsPresenter.vue";
import { ref } from "vue";

const pageNumber = ref(1);
</script>

<style scoped>
.grid-wrapper {
  display: grid;
  width: 100%;
  height: 80vh;
  justify-items: stretch;
  align-items: stretch;
}
</style>
