<script lang="ts" setup>
import Documents from "./Documents.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import {
  SubfolderCard,
  LoadingSpinner,
  Tooltip,
  DocumentUploadModal,
  Dropdown,
  PromomatsImporter
} from "@/components";
import { useWorkspaceWithChildWorkspacesQuery } from "@/graphql/operations";
import { useStore } from "vuex";
import WorkspaceBreadcrumbs from "./WorkspaceBreadcrumbs.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const emits = defineEmits(["contentLoaded"]);

const workspaceId = route.params.workspaceId as string;

/* fetch child workspaces */
const { fetching, executeQuery, data } = useWorkspaceWithChildWorkspacesQuery({
  variables: {
    id: workspaceId,
  },
  requestPolicy: "cache-and-network",
});

watch(data, (newData, oldData) => {
  if (newData && !oldData) {
    emits("contentLoaded");
  }
}, { immediate: true });

const intervalId = setInterval(() => {
  executeQuery();
}, 6 * 1000);

onBeforeUnmount(() => {
  clearInterval(intervalId);
});

const workspace = computed(() => data.value?.workspace);
const subfolders = computed(
  () => data.value?.workspace?.childWorkspaces?.nodes
);

const uploadPopupActive = ref<boolean>(false);
const promomatsImporterActive = ref(false);

const uploadActions = computed(() => {
  const items = [];
  if (workspace.value?.myPrivileges?.mayAdministrate) {
    items.push(
      { title: "Upload from local device", value: "upload" },
    );
  }

  if (store.getters.user.hasPromomatsImport()) {
    items.push(
      { title: "Import from Vault PromoMats", value: "promomats_import" }
    )
  }

  return items;
});

function handleUploadAction(value: string) {
  switch (value) {
    case "upload":
      uploadPopupActive.value = true;
      break;

    case "promomats_import":
      promomatsImporterActive.value = true;
      break;
  }
  console.log("handle upload action")
}
</script>

<template>
  <div class="user_workspace page_content" data-test="workspace-page">
    <div v-if="fetching" class="empty_pane">
      <loading-spinner />
    </div>

    <div v-else-if="workspace">
      <div class="title_container">
        <workspace-breadcrumbs />

        <div class="right" v-if="uploadActions.length > 0">
          <dropdown class="right_align" @change="handleUploadAction" :items="uploadActions">
            <template v-slot>
              <span
                class="material-symbols-outlined icon clickable"
                data-test="upload-ctrl"
              >
                file_upload
              </span>
            </template>
          </dropdown>

          <tooltip class="bottom">
            <template v-slot>
              <router-link :to="{ name: 'WorkspaceDetail' }" data-test="goto-workspace-settings-ctrl">
                <span class="material-symbols-outlined icon clickable">
                  more_vert
                </span>
              </router-link>
            </template>
            <template #tooltip> Details </template>
          </tooltip>
        </div>
      </div>

      <div v-if="subfolders && subfolders.length > 0" class="subfolder_grid">
        <template v-for="subfolder in subfolders" :key="subfolder!.id">
          <SubfolderCard v-if="subfolder !== null" :workspace="subfolder!" />
        </template>
      </div>

      <Documents :hasSubfolders="subfolders !== undefined && subfolders.length > 0" />
    </div>

    <div v-else class="empty_pane info">
      <span class="text">{{ $t("workspace.notFound") }}</span>
    </div>
  </div>

  <DocumentUploadModal
    v-if="uploadPopupActive"
    :active="uploadPopupActive"
    @close="uploadPopupActive = false"
  >
  </DocumentUploadModal>

  <PromomatsImporter v-if="promomatsImporterActive" @close="promomatsImporterActive = false" />
</template>

<style lang="scss" scoped>
.user_workspace .empty_pane {
  height: calc(100vh - 40px - 2em);
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info .text {
  opacity: 0.8;
}

.subfolder_grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  margin-bottom: 2em;
}

.title_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
}

.title_container .right {
  margin-left: auto;
}

.icon.material-symbols-outlined {
  color: $color-secondary;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  padding-top: 0.18em;
  box-sizing: border-box;
  border: 1px solid $color-secondary;
  border-radius: 50%;
  margin-left: 0.2em;
  font-size: 24px;
}
</style>
