import { App } from "vue";

export default function directive(app: App) {
  app.directive('click-outside', {
    beforeMount(el, binding) {
      el.clickOutsideEvent = (ev: Event) => {
        ev.stopPropagation();

        if (!(el === ev.target || el.contains(ev.target))) {
          binding.value(ev, el);
        }
      };

      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener("click", el.clickOutsideEvent);
    }
  })
}