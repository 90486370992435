<script lang="ts" setup>
import { Search, Dropdown, Modal } from "@/components";
import { useI18n } from "vue-i18n";
import { ref, computed, watch } from "vue";
import {
  useFindExternalDocumentsQuery,
  ExternalDocumentFragment,
} from "@/graphql/operations";
import Document from "./Document.vue";
import { useDebounceFn } from "@vueuse/core";
import { token } from "@/init";
import { HTTP } from "@/util";
import { PMDoc } from "./PromomatsDocument";
import { captureException } from "@sentry/vue";

const { t } = useI18n();

const BASE_URL = import.meta.env.VUE_APP_BACKEND_URL || "/backend";

const emit = defineEmits(["change", "close", "next"]);

const limit = 200;

const sortItems = [
  {
    value: "name__v,ASC",
    title: t("promomatsIntegration.sort.nameAsc"),
  },
  {
    value: "name__v,DESC",
    title: t("promomatsIntegration.sort.nameDesc"),
  },
  {
    value: "version_creation_date__v,ASC",
    title: t("promomatsIntegration.sort.versionCreationAsc"),
  },
  {
    value: "version_creation_date__v,DESC",
    title: t("promomatsIntegration.sort.versionCreationDesc"),
  },
  {
    value: "version_modified_date__v,ASC",
    title: t("promomatsIntegration.sort.versionModificationAsc"),
  },
  {
    value: "version_modified_date__v,DESC",
    title: t("promomatsIntegration.sort.versionModificationDesc"),
  },
];

const currentSort = ref("version_creation_date__v,ASC");
const updateSort = (value: string) => {
  currentSort.value = value;
  fetch();
};

const documents = ref<Array<PMDoc>>([]);
const fetching = ref(true);
const error = ref<string>();

const fetch = async () => {
  fetching.value = true;
  documents.value = [];
  error.value = undefined;

  let offset = 0;
  while (true) {
    try {
      const numDocs = await fetchAll(offset);
      fetching.value = false;

      if (numDocs < limit) {
        break;
      }

      offset += limit;
    } catch (e) {
      fetching.value = false;
      error.value = e instanceof Error ? e.message : "An error occurred";
      captureException(e);
      break;
    }
  }
};

const fetchAll = async (offset: Number) => {
  const url = new URL(BASE_URL + "/veeva/documents", window.location.origin);

  if (currentSearch.value && currentSearch.value.length > 0) {
    url.searchParams.append("search", currentSearch.value);
  }

  url.searchParams.append("limit", limit.toString());
  url.searchParams.append("offset", offset.toString());

  const sortParams = currentSort.value.split(",");
  url.searchParams.append("sortBy", sortParams[0]);
  url.searchParams.append("sort", sortParams[1]);

  const headers = new Map();
  headers.set("Authorization", `Bearer ${token.value}`);
  const response = await HTTP.get(url.toString(), headers);
  const data = await response.json();

  if (data.responseStatus === "FAILURE") {
    throw new Error(data.errors[0]?.message);
  }

  let docs = data.documents.map((d: { document: PMDoc }) => d.document);
  documents.value = documents.value.concat(docs);

  return docs.length;
};

const currentSearch = ref("");
const updateSearch = (value: string) => {
  currentSearch.value = value;
};

const fetchSearchResults = useDebounceFn(
  () => {
    fetch();
  },
  700,
  { maxWait: 5000 }
);

watch(currentSearch, () => {
  fetchSearchResults();
});

fetch();

const { data } = useFindExternalDocumentsQuery();
const importedDocuments = computed(
  () =>
    data.value?.documents?.nodes
      ?.filter((doc): doc is ExternalDocumentFragment => doc !== null)
      .reduce((d, doc) => {
        d[doc.externalId!] = doc;
        return d;
      }, {} as Record<string, ExternalDocumentFragment>) || {}
);

const selectedDocuments = ref<Set<Number>>(new Set());
const documentSelection = computed(() =>
  Array.from(selectedDocuments.value).map((docId: Number) =>
    documents.value.find((d) => d.id === docId)
  )
);

const updateSelectedDocuments = (docId: Number, isSelected: boolean) => {
  if (isSelected && !selectedDocuments.value.has(docId)) {
    selectedDocuments.value.add(docId);
  } else if (!isSelected && selectedDocuments.value.has(docId)) {
    selectedDocuments.value.delete(docId);
  }

  emit("change", documentSelection.value);
};
</script>

<template>
  <Modal
    :active="true"
    @close="emit('close')"
    class="huge"
    data-test="upload-modal"
  >
    <template v-slot:header>
      {{ $t("promomatsIntegration.title") }}
    </template>
    <template v-slot:body>
      <div class="inner">
        <div class="controls">
          <Search
            @change="updateSearch"
            :placeholder="$t('promomatsIntegration.search.placeholder')"
          />
          <div class="control">
            <label>{{ $t("promomatsIntegration.sort.label") }}:</label>
            <dropdown
              :items="sortItems"
              :value="currentSort"
              @change="updateSort"
              class="right"
            >
              <div class="link">
                {{ sortItems.find((i) => i.value === currentSort)?.title }}
              </div>
            </dropdown>
          </div>
        </div>

        <div v-if="fetching">Loading...</div>
        <div v-else-if="error" class="error">Error: {{ error }}</div>
        <div v-else-if="documents" class="content">
          <table class="data_table">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>{{ $t("promomatsIntegration.columns.status") }}</th>
                <th class="align_right">
                  {{ $t("promomatsIntegration.columns.expirationDate") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <Document
                v-for="(doc, idx) in documents"
                :key="idx"
                :doc="doc"
                :workspaceDoc="importedDocuments[doc.id.toString()]"
                @change="
                  (isSelected) => updateSelectedDocuments(doc.id, isSelected)
                "
              />
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="buttons">
        <button
          class="primary button"
          :class="{ disabled: selectedDocuments.size === 0 }"
          @click="emit('next')"
        >
          {{
            $t("promomatsIntegration.next", {
              numDocs: selectedDocuments.size,
            })
          }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.inner {
  height: 100%;
}

.content {
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding-top: 0.5em;
}

.controls {
  display: flex;
  align-items: center;
}

.controls input {
  margin-left: auto;
  height: 16px;
  width: 16px;
  padding: 0.5em;
}

.controls .clickable {
  cursor: pointer;
}

.controls .clickable.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.controls .search {
  margin-right: 1em;
}

.controls .control {
  font-size: 0.9em;
  display: flex;
  width: 240px;
  padding-right: 2em;
  justify-content: flex-end;
}

.controls .control label {
  margin-right: 0.5em;
}

.controls .control .link {
  text-decoration: none;
  font-weight: 500;
}

table.data_table {
  box-shadow: none;
  table-layout: fixed;
}

table.data_table thead tr th:first-child {
  width: 94px;
}

table.data_table thead tr th:nth-child(2) {
  width: 50%;
}

table.data_table thead tr th:nth-child(3) {
  width: 70px;
}

table.data_table thead tr th.align_right {
  text-align: right;
}

table.data_table thead tr th:last-child {
  width: 40px;
}
</style>
