<script lang="ts" setup>
import { ref, onMounted } from "vue";
import {
  useCreateDocumentSpaceSectionMutation,
  useCreateSpaceSectionAfterMutation,
  Maybe,
} from "@/graphql/operations";
import { Modal } from "@/components";

const props = defineProps({
  documentSpaceId: {
    type: String,
    required: false,
  },
  afterSectionId: {
    type: String,
    required: false,
  },
  sectionNames: {
    type: Array<Maybe<string> | undefined>,
    required: true
  },
});

const emits = defineEmits(["success", "close"]);

const inputElem = ref();

onMounted(() => {
  if (!inputElem.value) {
    return;
  }

  inputElem.value.focus();
});

const newName = ref<string>("");

const { executeMutation } = useCreateDocumentSpaceSectionMutation();
const { executeMutation: createAfter } = useCreateSpaceSectionAfterMutation();
const submit = async () => {
  if (!props.documentSpaceId && !props.afterSectionId) {
    return;
  }

  let res;
  if (props.documentSpaceId) {
    res = await executeMutation({
      spaceId: props.documentSpaceId,
      title: newName.value,
    });
  } else {
    res = await createAfter({
      afterSectionId: props.afterSectionId!,
      title: newName.value,
    });
  }

  if (res!.error) {
    //TODO handle errir
  } else {
    emits("close");
    emits("success");
  }
};
</script>

<template>
  <Modal :active="true" @close="emits('close')">
    <template #header>
      {{ $t("documentSpace.sections.createNew.title") }}
    </template>
    <template #body>
      <input
        class="input"
        type="text"
        v-model="newName"
        :placeholder="$t('documentSpace.sections.createNew.placeholderName')"
        ref="inputElem"
      />
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button secondary" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
        <button
          class="button primary"
          :class="{ disabled: newName.length === 0 || sectionNames.includes(newName) }"
          @click="submit"
        >
          {{ $t("submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
