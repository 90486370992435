<template>
  <div class="team_members">
    <div v-if="loading">
      Loading
    </div>

    <div v-else>
      <button
        class="button primary"
        v-if="myMembership!.mayAdministrate"
        @click="displaySearch = true"
      >
        {{ $t("teamDetail.addUser.buttonText") }}
      </button>

      <section v-if="subteamMemberships && subteamMemberships.length > 0">
        <!-- Subteams -->
        <h4>Subteams</h4>
        <table class="data_table">
          <thead>
            <tr>
              <th>Name</th>
              <th>count of users</th>
              <th>count of subteams</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="membership, idx in subteamMemberships" :key="idx">
              <td>
                <router-link
                  :to="{
                    name: 'TeamDetail',
                    params: { id: membership!.subteam!.id },
                  }"
                >
                  {{ membership!.subteam!.name }}
                </router-link>
              </td>
              <td>{{ membership!.subteam!.userMemberships!.totalCount }}</td>
              <td>{{ membership!.subteam!.subteamMemberships!.totalCount }}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section
        v-if="myMembership!.mayAdministrate && invitedUserMemberships.length > 0"
      >
        <h4>{{ $t("teamDetail.invitedUsers.title") }}</h4>
        <UserMembershipsList
          :memberships="invitedUserMemberships!"
          :isAdmin="myMembership!.mayAdministrate"
          @removeUser="removeUserFromTeam"
        />
      </section>

      <section
        v-if="myMembership!.mayAdministrate && pendingUserMemberships.length > 0"
      >
        <h4>{{ $t("teamDetail.requests.title") }}</h4>
        <PendingMembershipsList
          :memberships="pendingUserMemberships!"
          @accept="acceptRequest"
          @decline="declineRequest"
        />
      </section>

      <section>
        <!-- Active Users -->
        <h4>{{ $t("teamDetail.members.title") }}</h4>
        <UserMembershipsList
          :memberships="activeUserMemberships!"
          :isAdmin="myMembership!.mayAdministrate"
          @removeUser="removeUserFromTeam"
          @reloadData="executeQuery"
        />
      </section>

      <template v-if="displaySearch">
        <TenantUserSearch
          :teamId="teamId"
          :tenantId="tenantId"
          @submit="inviteUsers"
          @close="displaySearch = false"
        ></TenantUserSearch>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, ComputedRef } from "vue";
import { TenantUserSearch } from "@/components";
import PendingMembershipsList from "./PendingMembershipsList.vue";
import UserMembershipsList from "./UserMembershipsList.vue";
import { useRoute } from "vue-router";
import {
  useTeamDetailsByIdQuery,
  useRemoveUserFromTeamMutation,
  useSendInvitationsMutation,
  useAcceptTeamMembershipRequestMutation,
  useDeclineTeamMembershipRequestMutation,
  Membership
} from "@/graphql/operations";

const route = useRoute();

const displaySearch = ref<boolean>(false);

const teamId = route.params.id as string;
const { data: teamResults, fetching: loading, executeQuery } = useTeamDetailsByIdQuery({
  variables: {
    id: teamId
  },
  requestPolicy: "cache-and-network"
});

// extract team and memberships
const team = computed(() => teamResults?.value?.team);

const activeUserMemberships: ComputedRef<Membership[]> = computed(() => {
  if (team.value?.activeUserMemberships?.nodes) {
    return team.value!.activeUserMemberships!.nodes! as Membership[];
  } else {
    return [];
  }
});

const pendingUserMemberships: ComputedRef<Membership[]> = computed(() => {
  if (team.value?.pendingUserMemberships?.nodes) {
    return team.value!.pendingUserMemberships!.nodes! as Membership[];
  } else {
    return [];
  }
});

const invitedUserMemberships: ComputedRef<Membership[]> = computed(() => {
  if (team.value?.invitedUserMemberships?.nodes) {
    return team.value!.invitedUserMemberships!.nodes! as Membership[]
  } else {
    return []
  }
});

const subteamMemberships = computed(() =>
  team.value?.subteamMemberships.nodes.filter(n => n !== null)
);
const myMembership = computed(() => team.value?.myMembership);
const tenantId = computed(() => team.value?.tenant?.id!);

const { executeMutation: sendInvitations } = useSendInvitationsMutation();

function inviteUsers(payload: any) {
  //TODO error handling
  sendInvitations(payload);
  displaySearch.value = false;
}

const { executeMutation: removeUserFromTeam } =
  useRemoveUserFromTeamMutation();
const { executeMutation: acceptRequest } =
  useAcceptTeamMembershipRequestMutation();
const { executeMutation: declineRequest } =
  useDeclineTeamMembershipRequestMutation();
</script>

<style lang="scss" scoped>
.team_members {
  padding: 1em 0;
}

h4 {
  font-weight: 500;
  margin: 0;
  line-height: 2.8;
}

.button {
  float: right;
  font-size: 0.9em;
}
</style>
