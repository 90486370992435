<script lang="ts" setup>
import { ref, PropType } from "vue";
import { Modal, Tooltip } from "@/components";
import { type Config } from "./Config";

const active = ref(false);

const props = defineProps({
  config: {
    type: Object as PropType<Config>,
    required: true,
  },
  currentLanguage: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["submit"]);

const submit = () => {
  if (!selectedElement.value) {
    return;
  }

  emits("submit", selectedElement.value);
  close();
};

const close = () => {
  selectedElement.value = null;
  active.value = false;
};

const selectedElement = ref<
  "link" | "footer" | "logo" | "footerLink" | "legalNote" | null
>(null);
const select = (
  elementType: "link" | "footer" | "logo" | "footerLink" | "legalNote"
) => {
  selectedElement.value = elementType;
};
</script>

<template>
  <button class="button" @click="active = true">
    <span class="material-symbols-outlined"> add </span>
    {{ $t(`templateEditor.addContent.buttonText`) }}
  </button>

  <modal :active="active" @close="close">
    <template #header>
      {{ $t(`templateEditor.addContent.buttonText`) }}
    </template>

    <template #body>
      <div class="container">
        <tooltip class="bottom left element">
          <template v-slot>
            <div
              @click="select('link')"
              :class="{
                selected: selectedElement === 'link',
                disabled: currentLanguage !== 'default',
              }"
            >
              <span class="material-symbols-outlined"> link </span>
              <div class="element_name">
                {{ $t(`templateEditor.toolbar.link.title`) }}
              </div>
            </div>
          </template>
          <template v-if="currentLanguage !== 'default'" #tooltip>{{
            $t("templateEditor.addContent.disabledText")
          }}</template>
        </tooltip>

        <div
          class="element"
          :class="{ selected: selectedElement === 'footer' }"
          @click="select('footer')"
          v-if="!config.footerText || config.footerText.length === 0"
        >
          <span class="material-symbols-outlined"> article </span>
          <div class="element_name">
            {{ $t(`templateEditor.toolbar.footer.title`) }}
          </div>
        </div>

        <div
          class="element"
          :class="{ selected: selectedElement === 'footerLink' }"
          @click="select('footerLink')"
        >
          <span class="material-symbols-outlined"> link </span>
          <div class="element_name">
            {{ $t(`templateEditor.toolbar.footerLink.title`) }}
          </div>
        </div>

        <div
          class="element"
          :class="{ selected: selectedElement === 'logo' }"
          @click="select('logo')"
          v-if="!config.logoUrl && currentLanguage === 'default'"
        >
          <span class="material-symbols-outlined"> image </span>
          <div class="element_name">
            {{ $t(`templateEditor.toolbar.logo.title`) }}
          </div>
        </div>

        <div
          class="element"
          :class="{ selected: selectedElement === 'legalNote' }"
          @click="select('legalNote')"
          v-if="!config.legalNote || config.legalNote.length === 0"
        >
          <span class="material-symbols-outlined"> info </span>
          <div class="element_name">
            {{ $t(`templateEditor.toolbar.legalNote.title`) }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="buttons">
        <button
          class="button primary"
          :class="{ disabled: !selectedElement }"
          @click="submit"
        >
          {{ $t("submit") }}
        </button>
        <button class="button" @click="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </modal>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  padding: 1em 0;
}

.element {
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.05);
  border: 1px solid #dbdbdb;
  border-radius: 5%;
  padding: 0.8em;
  width: 70px;
  text-align: center;
  margin-right: 1em;
}

.element:hover {
  cursor: pointer;
  box-shadow: 0 0.25em 0.8em rgba(0, 0, 0, 0.1);
}

.element.selected {
  border: 1px solid $color-secondary;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: initial;
}
</style>
