<template>
  <div class="page_not_found">
    <div class="wrapper">
      <h1 class="title bold">{{ $t("notFound.title") }}</h1>
      <p>{{ $t("notFound.info") }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
$background: #f1f3f5;
.page_not_found {
  background: $background;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin: auto;
  width: 50%;
  height: 50%;
  max-width: 600px;
  max-height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

p {
  margin: 2em 0;
}
</style>
