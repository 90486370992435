import { HTTP } from "@/util/http";

const BASE_URL = import.meta.env.VUE_APP_BACKEND_URL || "/backend";

export interface UploadResponseData {
  storageId: string,
  downloadUrl: string,
  thumbnailStorageId: string,
  thumbnailDownloadUrl: string,
  mimeType: string,
  originalMimeType: string | null,
  pageCount?: number,
  duration?: number,
  originalFileDownloadUrl?: string
}

export class UploadService {

  static async upload(
    formData: FormData,
    token: string,
    onProgress?: (p: number) => void): Promise<UploadResponseData> {
    const headers = new Map();
    headers.set("Authorization", `Bearer ${token}`);

    const fetchUrl = BASE_URL + "/upload";
    const response = await HTTP.postFormData(
      fetchUrl,
      headers,
      formData,
      onProgress);

    const data = JSON.parse(response);
    return data;
  }

}

