<template>
  <Modal :active="active" @close="emits('close')">
    <template #header>
      {{ $t("customFlows.deleteConfirmation.title") }}
    </template>

    <template #body>
      {{ $t("customFlows.deleteConfirmation.message", title) }}
    </template>

    <template #footer>
      <div class="buttons">
        <button class="button primary" @click="deleteFlow">
          {{ $t("customFlows.deleteConfirmation.submit") }}
        </button>
        <button class="button" @click="emits('close')">
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from "@/components";
import { useDeleteCustomFlowMutation } from "@/graphql/operations";

const props = defineProps({
  active: {
    type: Boolean,
    required: true
  },
  flowId: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  }
});
const emits = defineEmits(["close", "success"]);

const { executeMutation: executeDelete } = useDeleteCustomFlowMutation();
const deleteFlow = async () => {
  await executeDelete({ id: props.flowId });
  emits("success");
};
</script>

<style scoped>

</style>