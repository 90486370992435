<script lang="ts" setup>
import { ref, watch, PropType, onMounted, onBeforeUnmount } from "vue";

const menuOverlay = ref();

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  refElem: {
    type: Object as PropType<HTMLElement | null>,
    required: false,
  },
});

watch(props, () => {
  if (!menuOverlay.value || !props.refElem) {
    return;
  }

  if (props.active) {
    menuOverlay.value.classList.add("active");
    positionOverlay();
  } else {
    menuOverlay.value.classList.remove("active");
  }
});

const positionOverlay = () => {
  if (props.refElem) {
    const pos = props.refElem.getBoundingClientRect();
    menuOverlay.value.style.minWidth = `${pos.width}px`;
    menuOverlay.value.style.top = `${pos.top + 40 + window.scrollY}px`;

    menuOverlay.value.style.left = `${
      pos.x - menuOverlay.value.offsetWidth + pos.width
    }px`;
  }
};

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};

const resizeObeserver = new ResizeObserver((entries) => {
  for (let entry of entries) {
    positionOverlay();
  }
});

onMounted(() => {
  if (menuOverlay.value) {
    resizeObeserver.observe(menuOverlay.value);
  }
});

onBeforeUnmount(() => {
  if (menuOverlay.value) {
    resizeObeserver.unobserve(menuOverlay.value);
  }
});
</script>

<template>
  <div class="menu_overlay" ref="menuOverlay">
    <div>
      <div class="header">
        <div class="icon" @click="close">
          <span class="material-symbols-outlined"> close </span>
        </div>
        <slot name="header"></slot>
      </div>

      <div class="body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu_overlay {
  display: none;
  border: 1px solid #ebebeb;
  padding: 0.8em 1.5em 1.2em 1.5em;
  position: absolute;
  background: #fff;
  font-size: 0.9rem;
  box-shadow: 0 4px 12px 0 #0000001a;
  z-index: 99;
}

.menu_overlay.active {
  display: block;
}

.header {
  font-weight: 600;
  line-height: 48px;
}

.header .icon {
  float: right;
  cursor: pointer;
}

.header .icon.left {
  float: left;
  margin-right: 5px;
}

.header .icon .material-symbols-outlined {
  vertical-align: middle;
  font-size: 1.3em;
}

.body {
  display: block;
}
</style>
