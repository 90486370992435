import { HTTP } from "@/util";
import { token } from "@/init";

export interface Consent {
  permissionStatusEmail: null | string,
  permissionEmail: null | string,
  permissionStatusMobileMessages: null | string,
  permissionMobileNumber: null | string
}

export async function fetchCRMConsentStatus(contactId: string): Promise<Consent> {
  const headers = new Map<string, string>();
  headers.set("Authorization", `Bearer ${token.value!}`);

  const res = await HTTP.get(`/backend/consents?crm_contact_id=${contactId}`, headers);
  if (res.ok) {
    const data = await res.json();
    return data as Consent;
  } else {
    const text = await res.text();
    throw new Error(text);
  }

}