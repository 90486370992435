import { useEventBus } from '@vueuse/core';

interface FlashMessageEvent {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
}

export function useFlashMessage() {
  const flashMessageBus = useEventBus<FlashMessageEvent>('flashMessage');

  function displayFlashMessage(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'success') {
    flashMessageBus.emit({ message, type });
  }

  return { displayFlashMessage };
}