<template>
  <div ref="videoWrapper" class="video-player wrapper">
    <div class="inner-wrapper">
      <video
        ref="videoElement"
        :muted="muted"
        :src="src"
        preload="auto"
        playsinline
        webkit-playsinline="webkit-playsinline"
        @canplay="emit('canplay', $event)"
        @canplaythrough="emit('canplaythrough', $event)"
        @seeked="
          emit('stateChanged', {
            state: 'SEEKED',
            position: getCurrentTime(),
          })
        "
        @playing="
          // prettier-ignore
          // eslint-disable-next-line prettier/prettier
          emit('playing', $event);
          emit('stateChanged', {
            state: 'PLAYING',
            position: getCurrentTime(),
          })
        "
        @pause="
          // prettier-ignore
          // eslint-disable-next-line prettier/prettier
          emit('pause', $event);
          emit('stateChanged', {
            state: 'PAUSED',
            position: getCurrentTime(),
          })
        "
        @waiting="
          // prettier-ignore
          // eslint-disable-next-line prettier/prettier
          emit('waiting', $event);
          emit('stateChanged', {
            state: 'WAITING',
            position: getCurrentTime(),
          })
        "
        @ended="
          // prettier-ignore
          // eslint-disable-next-line prettier/prettier
          emit('waiting', $event);
          emit('stateChanged', { state: 'ENDED', position: getCurrentTime() })
        "
      >
        <source :src="src" :type="type" />
      </video>
      <laserpointer
        v-if="laserpointer"
        v-bind="laserpointer"
        :covers="videoElement"
      />
      <div
        v-if="progress !== null"
        class="progress"
        :style="{ width: progress + '%' }"
      />
    </div>
    <slot name="buttons" v-bind="{ allow, play, pause }">
      <slot name="allowButton" v-bind="{ allow, videoBlocked }">
        <button v-if="videoBlocked" @click="allow()">Video erlauben</button>
      </slot>
      <slot name="muteButton" v-bind="{ unmute, mute, muted }">
        <button @click="muted ? unmute() : mute()">
          <span v-if="muted">Ton an</span>
          <span v-else>Ton aus</span>
        </button>
      </slot>
    </slot>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  Ref,
} from 'vue'
import Laserpointer from './Laserpointer.vue';
import { Point } from '@/models'

export interface Props {
  src: string
  type: string
  state: 'SEEK' | 'PLAYING' | 'PAUSED'
  laserpointer?: Point & { color?: string }
  position: number
}

interface Emits {
  (
    e: 'stateChanged',
    payload: {
      state: 'SEEKED' | 'PLAYING' | 'PAUSED' | 'WAITING' | 'ENDED'
      position: number | null
    }
  ): void
  (e: 'mounted'): void
  (e: 'canplay', payload: Event): void
  (e: 'canplaythrough', payload: Event): void
  (e: 'playing', payload: Event): void
  (e: 'pause', payload: Event): void
  (e: 'waiting', payload: Event): void
}

const emit = defineEmits<Emits>()
const props = withDefaults(defineProps<Props>(), {
  type: 'video/mp4',
  laserpointer: undefined,
})

const videoElement = ref<HTMLVideoElement>()
const videoWrapper = ref<HTMLDivElement | null>(null)
const videoBlocked = ref<boolean>(
  localStorage.getItem('videoBlocked') === 'true'
)
const showButtonOverlay = ref<boolean>(false)
const progress = ref<number | null>(null)
let showButtonOverlayTimeout: number
const muted = ref<boolean>(true)
const paused = ref<boolean | null>(null)

watch(showButtonOverlay, (value) => {
  if (value) {
    window.clearTimeout(showButtonOverlayTimeout)
    showButtonOverlayTimeout = window.setTimeout(() => {
      showButtonOverlay.value = false
    }, 4000)
  }
})

function isReferenced<T>(
  element: Ref<T>,
  message?: string
): element is Ref<NonNullable<T>> {
  if (!element.value) {
    throw new Error(message || 'element is not referenced')
  } else {
    return true
  }
}

const getCurrentTime = () => {
  if (videoElement.value) {
    return videoElement.value.currentTime || 0
  } else {
    return null
  }
}

const play = async () => {
  try {
    await videoElement.value?.play()
    videoBlocked.value = false
  } catch (error) {
    console.error(error)
    videoBlocked.value = true
  }
}

const seek = (position?: number) => {
  if (!isReferenced(videoElement)) {
    return
  }
  // use given time, if any, otherwise use time given by property
  videoElement.value.currentTime =
    position === undefined ? props.position : position

  // then pause!
  videoElement.value.pause()

  // then say you have seeked
  emit('stateChanged', {
    state: 'SEEKED',
    position: getCurrentTime(),
  })
}

const mute = () => {
  muted.value = true
}

const unmute = () => {
  muted.value = false
}

const pause = () => {
  if (!isReferenced(videoElement)) {
    return
  }
  videoElement.value.pause()
  videoElement.value.currentTime = props.position
}

const allow = async () => {
  await play()
  if (props.state !== 'PLAYING') {
    seek()
  }
}

const rescaleVideo = () => {
  if (!isReferenced(videoWrapper) || !isReferenced(videoElement)) {
    return
  }
  const { height: wrapperHeight, width: wrapperWidth } =
    videoWrapper.value.getBoundingClientRect()
  const { videoHeight, videoWidth } = videoElement.value
  const rescale = Math.min(
    wrapperWidth / videoWidth,
    wrapperHeight / videoHeight
  )
  console.debug('resize video', {
    wrapperWidth,
    wrapperHeight,
    videoWidth,
    videoHeight,
    rescale,
  })
  videoElement.value.width = videoWidth * rescale
  videoElement.value.height = videoHeight * rescale
}

const videoWrapperResizeObserver = new ResizeObserver(() => {
  rescaleVideo()
})

onUnmounted(() => {
  if (videoWrapper.value) {
    videoWrapperResizeObserver.unobserve(videoWrapper.value)
  }
  videoWrapperResizeObserver.disconnect()
})

const playOrPause = () => {
  const value = props.state
  console.log("current video state: ", value)
  if (value === 'SEEK') {
    seek(props.position)
  } else if (value === 'PLAYING') {
    //seek(props.position)
    play()
  } else if (value === 'PAUSED') {
    const currentTime = getCurrentTime()
    if (!currentTime || currentTime >= props.position) {
      // pause immediately, if you're at or beyond the presenter's position
      pause()
    } else {
      // pause a little bit later, when you're at the presenter's position
      setTimeout(pause, props.position - currentTime)
    }
  }
}

onMounted(() => {
  emit('mounted')

  if (!videoElement.value || !videoWrapper.value) {
    console.error("video element (or it's wrapper) is not referenced yet")
    return
  }

  videoWrapperResizeObserver.observe(videoWrapper.value)

  const enableButtonOverlay = () => {
    showButtonOverlay.value = true
  }

  const calculateProgress = () => {
    const duration = videoElement.value?.duration
    const currentTime = videoElement.value?.currentTime
    progress.value =
      duration == null || currentTime == null
        ? null
        : (currentTime / duration) * 100
  }

  videoElement.value.addEventListener('touchmove', enableButtonOverlay)
  videoElement.value.addEventListener('mousemove', enableButtonOverlay)
  videoElement.value.addEventListener('timeupdate', calculateProgress)
  videoElement.value.addEventListener('durationchange', calculateProgress)
  videoElement.value.addEventListener('loadedmetadata', rescaleVideo)
  videoElement.value.addEventListener('pause', () => {
    paused.value = true
  })
  videoElement.value.addEventListener('playing', () => {
    paused.value = false
  })

  watch(
    () => props.state,
    () => {
      if (!videoElement.value) {
        console.error('video element is not referenced any longer')
        return
      }
      playOrPause()
    },
    {
      immediate: true,
    }
  )
})

watch(
  () => props.position,
  (value) => {
    if (!isReferenced(videoElement)) {
      return
    }

    if (Math.abs(value - videoElement.value.currentTime) > 1) {
      videoElement.value.currentTime = value
    }
  }
)

watch(
  () => props.src,
  (value) => {
    if (!isReferenced(videoElement)) {
      return
    }
    /*
     * Whenever we change the source programmatically,
     * we have to do so by manipulating the src attribute
     * of the media element.
     *
     * We tried to manipulate the src attribute of a
     * nested <source> element instead. However,
     * this will fail, even according to the standard,
     * please see here: https://html.spec.whatwg.org/#attr-source-src
     *
     */
    videoElement.value.src = value
    playOrPause()
  }
)

watch(
  videoBlocked,
  (value) => {
    localStorage.setItem('videoBlocked', value ? 'true' : 'false')
  },
  {
    immediate: true,
  }
)
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.wrapper {
  display: flex;
  max-width: 100vw;
  max-height: calc(100vh - 4rem);
  width: 100%;
  height: 100%;
  margin: auto auto;
  overflow: hidden;
  flex-grow: 0;

  .inner-wrapper {
    position: relative;
    flex-direction: column;
    overflow: hidden;
    margin: auto auto;

    .button-overlay {
      z-index: 80;
      position: absolute;
      right: 3rem;
      bottom: 2rem;
    }

    .progress {
      height: 3px;
      background-color: #db006e;
    }
  }
}
</style>
