<template>
  <div id="app_auth">
    <template v-if="state.loading"> Loading </template>
    <template v-else>
      <Sidebar />
      <div id="content" ref="contentRef">
        <ProjectorButton v-if="displayLauncher" />
        <GlobalFlashMessage ref="flashMessageRef" />
        <DeleteConfirmation ref="deleteConfirmationRef" />
        <router-view :key="updateParam" @contentLoaded="onContentLoaded" />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, watch, ref, onMounted, nextTick } from "vue";
import { Sidebar, GlobalFlashMessage, DeleteConfirmation } from "@/components";
import { token } from "@/init";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ProjectorButton from "./components/ProjectorButton.vue";
import { useEventBus } from "@vueuse/core";
import { useDeleteConfirmation } from "@/hooks";

const router = useRouter();
const route = useRoute();
const store = useStore();

const contentRef = ref<HTMLElement | null>(null);

interface FlashMessageEvent {
  message: string;
  type: "success" | "error" | "info" | "warning";
}

const flashMessageRef = ref<{
  show: (msg: string, type: string) => void;
} | null>(null);
const flashMessageBus = useEventBus<FlashMessageEvent>("flashMessage");

const { deleteConfirmationRef } = useDeleteConfirmation();

onMounted(() => {
  flashMessageBus.on(({ message, type }) => {
    if (flashMessageRef.value) {
      flashMessageRef.value!.show(message, type);
    }
  });
});

const state = reactive({
  loading: true,
});

const updateParam = computed(() => {
  if (route.meta.routeUpdateParam) {
    return route.params[route.meta.routeUpdateParam as string] as string;
  }

  return "";
});
const displayLauncher = computed(() => route.meta.displayLauncher);

watch(
  token,
  async () => {
    if (token.value !== undefined) {
      state.loading = false;
    }
  },
  { immediate: true }
);

let savedScrollPosition: number | null = null;
let previousPath: string | null = null;

router.beforeEach((to, from, next) => {
  const scrollElement = contentRef.value;

  if (scrollElement) {
    if (from.meta.saveScrollPosition) {
      // Save scroll position when leaving a page with scroll preservation enabled
      savedScrollPosition = scrollElement.scrollTop;
      previousPath = from.path;
    }

    if (
      to.meta.saveScrollPosition &&
      savedScrollPosition &&
      previousPath === to.path
    ) {
    } else {
      scrollElement.scrollTop = 0;
    }
  }
  next();
});

const onContentLoaded = async () => {
  nextTick(() => {
    if (
      contentRef.value &&
      route.meta.saveScrollPosition &&
      savedScrollPosition &&
      previousPath === route.path
    ) {
      contentRef.value.scrollTop = savedScrollPosition;
      savedScrollPosition = null;
      previousPath = null;
    }
  });
};

onMounted(() => {
  const scrollElement = contentRef.value;

  // Restore scroll position when returning to a page with scroll preservation
  if (
    scrollElement &&
    route.meta.saveScrollPosition &&
    savedScrollPosition !== null
  ) {
    scrollElement.scrollTop = savedScrollPosition;
  }
});
</script>

<style lang="scss">
#app_auth {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#content {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
