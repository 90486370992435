<script lang="ts" setup>
import { UserAvatar } from "@/components";

const props = defineProps({
  profileImgUrl: {
    type: String,
    required: false,
  },
  displayName: {
    type: String,
    required: false,
  },
  email: {
    type: String,
    required: true,
  },
  removeable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["remove"]);
</script>

<template>
  <div class="user_chip">
    <user-avatar
      class="small"
      :appearance="'subtle'"
      :name="displayName!"
      :email="email!"
      :profileImgUrl="profileImgUrl"
    />

    {{ displayName ? displayName : email }}
    <span
      v-if="removeable"
      class="material-symbols-outlined"
      @click="emits('remove')"
    >
      close
    </span>
  </div>
</template>

<style lang="scss" scoped>
.user_chip {
  font-size: 1em;
  display: flex;
  align-items: center;
  background: rgba(206, 205, 202, 0.5);
  padding: 4px 8px 4px 6px;
  border-radius: 4px;
}

.user_chip .avatar {
  font-size: 0.8em;
  margin-right: 0.5em;
}

.user_chip .material-symbols-outlined {
  font-size: 0.9em;
  padding: 5px 5px 3px 8px;
  color: rgb(55, 53, 47);
  cursor: pointer;
}
</style>
