<script lang="ts" setup>
import { computed, onBeforeUnmount, watch } from "vue";
import { useDocumentSpacesQuery, DocumentSpace } from "@/graphql/operations";
import SpaceRow from "./SpaceRow.vue";
import { LoadingSpinner } from "@/components";

const emit = defineEmits(["onCreate", "contentLoaded"]);

const { data, fetching, executeQuery } = useDocumentSpacesQuery({
  requestPolicy: "cache-and-network",
});

watch(data, (newData, oldData) => {
  if (newData && !oldData) {
    emit("contentLoaded");
  }
}, { immediate: true });

const spaces = computed(() => data.value?.documentSpaces?.nodes);

const intervalId = window.setInterval(() => executeQuery(), 5000);
onBeforeUnmount(() => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }
});
</script>

<template>
  <div v-if="!spaces && fetching" class="loading_status">
    <LoadingSpinner :color="'black'" />
  </div>

  <div v-else-if="!spaces || spaces.length === 0">
    <div class="empty_pane">
      <div>
        <span class="material-symbols-outlined"> post_add </span>

        <h2>{{ $t("documentSpaces.emptyState.title") }}</h2>
        <p class="info">
          {{ $t("documentSpaces.emptyState.info") }}
        </p>
        <button class="button primary" @click="emit('onCreate')">
          {{ $t("documentSpaces.createNew") }}
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="title_container">
      <h3>{{ $t("documentSpaces.title") }}</h3>
      <div class="controls">
        <div class="control">
          <label
            >{{ $t("documentSpaces.search.controls.sortBy.label") }}:</label
          >
          <span class="value">{{
            $t("documentSpaces.search.controls.sortBy.modified.label")
          }}</span>
        </div>
      </div>
    </div>
    <table class="data_table clickable">
      <thead>
        <th>{{ $t("documentSpaces.columns.name") }}</th>
        <th>{{ $t("documentSpaces.columns.lastModified") }}</th>
        <th>{{ $t("documentSpaces.columns.content") }}</th>
        <th></th>
      </thead>
      <tbody>
        <space-row
          v-for="space in spaces"
          :key="space!.id"
          :documentSpace="space as DocumentSpace"
          @reloadData="executeQuery"
        />
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.title_container {
  display: flex;
  margin-top: 1.3em;
}

.title_container h3 {
  font-size: 1.1rem;
  font-weight: 500;
  flex: 1;
}

table.data_table {
  box-shadow: none;
}

table.data_table thead th:first-child {
  padding-left: 0;
}

.empty_pane {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
}

.empty_pane > div {
  flex: none;
  text-align: center;
  margin-top: 5em;
}

.empty_pane .material-symbols-outlined {
  font-size: 6em;
  opacity: 0.4;
}

.empty_pane h2 {
  margin-bottom: 0;
}

.empty_pane p {
  margin: 0.6em 0 1.5em 0;
}

.controls {
  display: flex;
}

.control {
  margin-left: auto;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.control .value {
  background-color: #ececec;
  border-radius: 999px;
  padding: 0 1em;
  line-height: 2.5em;
  height: 2.5em;
  margin-left: 0.3em;
}

.loading_status {
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-symbols-outlined {
  vertical-align: middle;
}
</style>
