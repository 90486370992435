<script lang="ts" setup>
import { Modal, FlashMessage } from "@/components";
import { onMounted, ref } from "vue";
import { useCreateWorkspaceMutation } from "@/graphql/operations";
import { reloadWorkspaces } from "@/init";

const emit = defineEmits(["success", "close"]);

const name = ref<string>("");
const flashMessageActive = ref<boolean>(false);
const modalActive = ref<boolean>(true);
const resultState = ref<string>("");
const isSubmitted = ref<boolean>(false);

const { executeMutation } = useCreateWorkspaceMutation();
const submit = async () => {
  isSubmitted.value = true; // prevent multiple submissions on multiple clicks while waiting for the response

  const result = await executeMutation({
    name: name.value.trim()
  });

  if (result.error) {
    resultState.value = "error";
  } else {
    const id = result.data?.createWorkspaceAndJoin?.result?.createdWorkspace?.id;
    await reloadWorkspaces();
    emit("success", id);
    resultState.value = "success";
  }

  modalActive.value = false;
  flashMessageActive.value = true;
  window.setTimeout(() => (emit("close")), 2000);
}

const input_name = ref<HTMLInputElement>();
onMounted(() => {
  if (!input_name.value) {
    return;
  }

  input_name.value!.focus();
})
</script>

<template>
  <flash-message
    v-if="flashMessageActive"
    :state="resultState === 'error' ? 'error' : ''"
  >
    {{ $t(`createWorkspaceModal.created.${resultState}`)}}
  </flash-message>

  <Modal :active="modalActive" @close="emit('close')" data-test="create-workspace-modal">
    <template v-slot:header>
      {{ $t("createWorkspaceModal.header") }}
    </template>
    <template v-slot:body>
      <div>
        <label class="label">
          {{ $t("createWorkspaceModal.inputLabel") }}
        </label>
        <input class="input" type="text" v-model="name" ref="input_name" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="buttons">
        <button
          :class="{
            button: true,
            primary: true,
            disabled: name.length === 0 || isSubmitted
          }"
          @click="submit"
          data-test="create-workspace-submit"
        >
          <span class="material-symbols-outlined" data-v-ced23842="">save</span>
          {{ $t("createWorkspaceModal.submit") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
label {
  font-size: .9em;
  display: block;
  line-height: 2em;
}
</style>
