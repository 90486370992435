<script lang="ts" setup>
import { computed, PropType } from "vue";
import { Formatter } from "@/util";
import {
  useTopPagesPerSpaceAndDocumentQuery,
  MaDocumentSpaceDocumentStatistic,
  Interval,
} from "@/graphql/operations";

const props = defineProps({
  doc: {
    type: Object as PropType<MaDocumentSpaceDocumentStatistic>,
    required: true,
  },
  spaceId: {
    required: true,
    type: String,
  },
});

const { fetching, data } = useTopPagesPerSpaceAndDocumentQuery({
  variables: {
    spaceId: props.spaceId,
    docId: props!.doc!.documentId!,
  },
});

const topPages = computed(() =>
  data?.value?.maDocumentSpacePageStatistics?.nodes?.filter((n) => n !== null)
);

const formatDuration = (duration: Interval) => {
  return Formatter.formatDuration({
    hours: duration.hours || 0,
    minutes: duration.minutes || 0,
    seconds: duration.seconds || 0,
  });
};
</script>

<template>
  <div class="doc_stats">
    <div class="card_title">
      <b>{{ doc.displayTitle }}</b>
      <span v-if="doc.mimeType === 'application/mp4'" class="tag"> Video </span>
    </div>

    <div class="container">
      <div class="col">
        <div class="image">
          <img :src="doc.thumbnailDownloadUrl!" />
        </div>
      </div>

      <div class="col">
        <table class="data_table">
          <tbody>
            <tr>
              <td>
                {{ $t("documentSpaceAnalytics.topDocuments.metrics.visits") }}:
              </td>
              <td class="right">
                <b>{{ doc.numVisits }}</b>
              </td>
            </tr>
            <tr v-if="doc.mimeType !== 'application/mp4'">
              <td>
                {{
                  $t("documentSpaceAnalytics.topDocuments.metrics.downloads")
                }}:
              </td>
              <td class="right">
                <b>{{ doc.numDownloads }}</b>
              </td>
            </tr>
            <tr>
              <td>
                &empty;
                {{
                  $t("documentSpaceAnalytics.topDocuments.metrics.duration")
                }}:
              </td>
              <td class="right">
                <b>{{
                  formatDuration(doc.averageDurationInDocumentPerVisit!)
                }}</b>
              </td>
            </tr>
            <tr>
              <td>
                {{
                  $t("documentSpaceAnalytics.topDocuments.metrics.maxDuration")
                }}:
              </td>
              <td class="right">
                <b>{{
                  formatDuration(doc.maxDurationInDocumentAcrossVisits!)
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col">
        <table class="data_table" v-if="doc.mimeType !== 'application/mp4'">
          <thead>
            <tr>
              <th>
                {{ $t("documentSpaceAnalytics.topPages.metrics.pageNumber") }}
              </th>
              <th>
                {{ $t("documentSpaceAnalytics.topPages.metrics.numVisits") }}
              </th>
              <th>
                &empty;
                {{
                  $t("documentSpaceAnalytics.topPages.metrics.averageDuration")
                }}
              </th>
              <th>
                {{ $t("documentSpaceAnalytics.topPages.metrics.maxDuration") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="page in topPages" :key="page!.pageNumber!">
              <td>{{ page!.pageNumber }}</td>
              <td>{{ page!.numVisits }}</td>
              <td>
                {{ formatDuration(page!.averageDurationOnPagePerVisit!) }}
              </td>
              <td>
                {{ formatDuration(page!.maxDurationOnPageAcrossVisits!) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$border-strong: #cecece;
.doc_stats {
  border-radius: 0.3em;
  border: 1px solid $border-strong;
  box-sizing: border-box;
  color: #4a4a4a;
  max-width: 100%;
  font-size: 0.9rem;
  background: #fff;
  padding: 1rem 1.2rem 1rem 1.2rem;
  margin-bottom: 1em;
}

.doc_stats .container {
  display: flex;
  margin-top: 0.5em;
}

.doc_stats .col {
  width: calc(45% - 6em);
}

.doc_stats .col:first-child {
  width: 12em;
  margin-left: 0;
}

.doc_stats .col:last-child {
  margin-left: 5%;
}

.card .container {
  display: flex;
}

.doc_stats .card_title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc_stats .image {
  height: 8em;
}

.doc_stats .image img {
  height: auto;
  max-height: 8em;
  width: auto;
  max-width: 100%;
}

.doc_stats .info {
  margin-top: 0.5em;
  width: 100%;
  line-height: 1.3em;
}

.doc_stats .info span {
  width: 90px;
  display: inline-block;
}

.data_table {
  box-shadow: none;
}

table.data_table thead th,
table.data_table tbody td {
  line-height: 1.8;
}

table.data_table td.right {
  text-align: right;
}

.tag {
  background-color: #eaeaea;
  padding: 0.5em 0.8em;
  border-radius: 2em;
  margin-left: 0.3em;
  display: inline-block;
  font-size: 0.9em;
  line-height: initial;
}
</style>
