<script lang="ts" setup>
import { Modal, UserAvatar, Dropdown } from "@/components";
import {
  DocumentSpace,
  DocumentSpaceAccess,
  useUpdateAccessMutation,
  useRemoveAccessMutation,
} from "@/graphql/operations";
import { PropType, ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import AccessUserSearch from "./AccessUserSearch.vue";
import CollaboratorRow from "./CollaboratorRow.vue";
import TransferOwnershipModal from "./TransferOwnershipModal.vue";

const props = defineProps({
  documentSpace: {
    type: Object as PropType<DocumentSpace>,
    required: true,
  },
  visibleUserCount: {
    type: Number,
    required: true,
  },
  visibleUserLimit: {
    type: Number,
    required: true,
  },
});

const store = useStore();

const emits = defineEmits(["close", "refreshData", "changeState"]);
const emitClose = () => emits("close");

const myAccess = computed(() =>
  props.documentSpace.documentSpaceAccesses.nodes.find(
    (a) => a?.user?.id === store.getters.user.postgresId
  )
);

const userAccess = computed(() =>
  props.documentSpace.documentSpaceAccesses.nodes.filter(
    (a) => a?.user?.id !== store.getters.user.postgresId
  )
);

const transferOwnershipModalActive = ref<boolean>(false);
const newOwner = ref<DocumentSpaceAccess>();

const { executeMutation: updateVisibility } = useUpdateAccessMutation();
const { executeMutation: executeRemoveAccess } = useRemoveAccessMutation();
const updateAccess = async (action: string, access: DocumentSpaceAccess) => {
  // remove access
  if (action === "remove") {
    if (access.role === "owner") {
      return;
    }

    const result = await executeRemoveAccess({ id: access.id });
    if (result.error) {
      //TODO handle error;
    }
  } else if (action === "transfer_ownership") {
    if (myAccess.value!.role !== "owner") {
      return;
    }

    transferOwnershipModalActive.value = true;
    newOwner.value = access;
  } else {
    // update visibility
    const displayContactDetails = action === "visible";
    if (displayContactDetails === access.displayContactDetails) {
      return;
    }

    const result = await updateVisibility({
      id: access.id,
      displayContactDetails: displayContactDetails,
    });

    if (result.error) {
      //TODO handle error;
    }
  }

  //display flash message?
  emits("refreshData");
};

const searchActive = ref<boolean>(false);
const handleSearchActive = (isActive: boolean) => {
  if (isActive) {
    emits("changeState");
  }
};
</script>

<template>
  <modal :active="true" @close="emitClose" :scrolling="true" class="medium">
    <template #header> Access </template>

    <template #body>
      <access-user-search
        :documentSpace="documentSpace"
        @refreshData="emits('refreshData')"
        :active="searchActive"
        @stateChange="handleSearchActive"
      />

      <h3>
        {{ $t("documentSpace.access.title") }}
      </h3>

      <div>
        <collaborator-row
          v-if="myAccess"
          :access="myAccess"
          :isCurrentUser="true"
          :visibleUserCount="visibleUserCount"
          :visibleUserLimit="visibleUserLimit"
          :isOwner="myAccess.role === 'owner'"
          @update="updateAccess"
        />

        <collaborator-row
          v-for="access in userAccess"
          :key="access!.id"
          :access="access!"
          :isCurrentUser="false"
          :visibleUserCount="visibleUserCount"
          :visibleUserLimit="visibleUserLimit"
          :isOwner="myAccess!.role === 'owner'"
          @update="updateAccess"
        />
      </div>

      <transfer-ownership-modal
        v-if="transferOwnershipModalActive && newOwner"
        :newOwner="newOwner"
        :visibleUserCount="visibleUserCount"
        :visibleUserLimit="visibleUserLimit"
        @close="transferOwnershipModalActive = false"
        @refreshData="emits('refreshData')"
      />
    </template>
  </modal>
</template>

<style scoped lang="scss">
h3 {
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 1.2em;
}
</style>
