<template>
  <header v-if="!(config && config.backgroundVideo)">
    <img class="logo" :src="brandURL" />
  </header>
  <div
    ref="wrapperElem"
    :class="{ wrapper: true, fullscreen: state.isFullscreen }"
    :style="backgroundStyle"
  >
    <video
      v-if="config && config.backgroundVideo && !state.presentationID"
      autoplay
      muted
      loop
      :class="{ videoBackground: true, [config.videoFit]: true }"
    >
      <source
        ref="videoElem"
        :src="config.backgroundVideo"
        type="video/mp4"
      />
    </video>

    <div
      v-show="showQRCodeElem"
      :class="{
        qr_container: true,
        [config.qrCodePosition]: true,
        small: presentationIsActive,
      }"
    >
      <div>
        <div class="brand" v-if="!config.backgroundImage">
          <!--- <Brand /> -->
        </div>

        <canvas ref="qrCodeElem"></canvas>

        <div class="controls">
          <button id="Fullscreen" @click="requestFullscreen()" class="button">
            <span class="material-symbols-outlined"> fullscreen </span>
          </button>
        </div>
      </div>
    </div>

    <Presentation
      v-if="presentationIsActive"
      :presentationId="state.presentationID!"
    />
  </div>
</template>

<script lang="ts" setup>
import qrcode from "qrcode";
import { db } from "@/fire";
import {
  doc,
  onSnapshot,
  setDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { v4 as uuid4 } from "uuid";
import { useRoute } from "vue-router";
import {
  ref,
  onMounted,
  computed,
  watch,
  reactive,
  onBeforeUnmount,
} from "vue";
import { ScreenSessionConfig } from "@/models";
import pitchviewLogo from "@/assets/images/pitchview_logo.png";
import { getStorageProxyUrl } from "@/util";
import { Presentation } from "@/components";

const brandURL = computed(() => {
  return pitchviewLogo;
});

const route = useRoute();

interface ComponentState {
  configID: string | null;
  sessionID: string | null;
  presentationID: string | null;
  brandID: string | undefined | null;
  isFullscreen: boolean;
}

const config = ref<ScreenSessionConfig>(ScreenSessionConfig.defaultConfig());

async function createSession(): Promise<string> {
  const id = uuid4();
  const collectionRef = collection(db, "screensessions");

  await setDoc(doc(collectionRef, id), {
    updatedAt: Date.now(),
  });

  return id;
}

function generateQRCode(
  elem: HTMLCanvasElement | null,
  sessionID: string,
  small: boolean
) {
  const url = new URL(document.location.origin);
  url.pathname = "drop_scan";
  url.searchParams.append("id", sessionID);

  if (elem === null) {
    return;
  }

  qrcode.toCanvas(elem, url.href, {
    errorCorrectionLevel: "Q",
    width: small ? 128 : 256,
  });
}

function isFullscreen() {
  return (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  );
}

function exitFullscreen() {
  (
    document.exitFullscreen ||
    document.mozCancelFullScreen ||
    document.webkitExitFullscreen ||
    document.msExitFullscreen
  ).bind(document)();
}

async function enterFullscreen(elem: HTMLElement) {
  await (
    elem.requestFullscreen ||
    elem.mozRequestFullScreen ||
    elem.webkitRequestFullscreen ||
    elem.msRequestFullscreen
  ).bind(elem)({ navigationUI: "hide" });
}

async function unlinkPresentation(sessionID: string) {
  const collectionRef = collection(db, "screensessions");
  await setDoc(
    doc(collectionRef, sessionID),
    { presentationID: null },
    { merge: true }
  );
}

function logScreenParams(sessionID: string, backgroundVideo: string | null) {
  const logs: any = {
    screenWidth: screen.width,
    screenHeight: screen.height,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    userAgent: navigator.userAgent,
    backgroundVideo: backgroundVideo,
  };

  const docRef = doc(db, "screenlogs", sessionID);
  setDoc(docRef, { [Date.now()]: logs }, { merge: true });
}

const state: ComponentState = reactive({
  configID: (route.params.id as string) || null,
  sessionID: null,
  presentationID: null,
  brandID: null,
  isFullscreen: false,
});

const unWatchFunctions = new Map();

const qrCodeElem = ref<HTMLCanvasElement | null>(null);
const wrapperElem = ref(null);
const videoElem = ref<HTMLSourceElement | null>(null);

const presentationIsActive = computed(() => state.presentationID !== null);
watch(presentationIsActive, (newValue, prevValue) => {
  generateQRCode(qrCodeElem.value, state.sessionID!, newValue);
});
const showQRCodeElem = computed(
  () => !presentationIsActive.value || config.value.displayQROnPresentation
);

watch(videoElem, () => {
  if (videoElem.value) {
    videoElem.value.addEventListener(
      "error",
      (e) => {
        config.value.backgroundVideo = null;
      },
      false
    );
  }
});

function watchPresentation() {
  if (unWatchFunctions.has("presentation")) {
    unWatchFunctions.get("presentation")();
    unWatchFunctions.delete("presentation");
  }

  if (state.presentationID === null) {
    return;
  }

  const docRef = doc(db, "presentations", state.presentationID);
  const fn = onSnapshot(docRef, async (pDoc) => {
    if (pDoc.data()!.code === "invalid") {
      await unlinkPresentation(state.sessionID!);
    }
  });

  unWatchFunctions.set("presentation", fn);
}

function watchSession() {
  const docRef = doc(db, "screensessions", state.sessionID!);
  const fn = onSnapshot(docRef, (doc) => {
    const presentationID =
      doc.data()!.presentationID === undefined
        ? null
        : doc.data()!.presentationID;

    if (presentationID === state.presentationID) {
      return;
    }

    state.presentationID = presentationID;
    watchPresentation();
  });

  unWatchFunctions.set("session", fn);
}

function watchConfig() {
  if (state.configID === null) {
    return;
  }

  const docRef = doc(db, "screensession_configs", state.configID);

  const fn = onSnapshot(docRef, (doc) => {
    config.value = ScreenSessionConfig.fromDocument(doc.data()!);
    logScreenParams(state.sessionID!, config.value.backgroundVideo);
  });

  unWatchFunctions.set("config", fn);
}

const requestFullscreen = async () => {
  const elem = wrapperElem.value;
  if (elem === null) {
    return;
  }

  const setFullscreenChange = () => {
    if (!isFullscreen()) {
      state.isFullscreen = false;
    }
  };

  document.removeEventListener("fullscreenchange", setFullscreenChange, false);

  if (isFullscreen()) {
    exitFullscreen();
    state.isFullscreen = false;
  } else {
    await enterFullscreen(elem);
    state.isFullscreen = true;
    document.addEventListener("fullscreenchange", setFullscreenChange, false);
  }
};

const backgroundStyle = computed(() => {
  if (!config.value.hasBackground()) {
    return { "background-color": "#fff" };
  }

  return state.presentationID ||
    config.value.backgroundVideo ||
    !config.value.backgroundImage
    ? {}
    : {
        background: `url(${getStorageProxyUrl(
          config.value.backgroundImage
        )}) no-repeat center center fixed`,
        "background-size": "cover",
      };
});

onMounted(async () => {
  state.sessionID = await createSession();
  watchSession();
  watchConfig();
  logScreenParams(state.sessionID!, config.value.backgroundVideo);
  generateQRCode(
    qrCodeElem.value,
    state.sessionID!,
    presentationIsActive.value
  );
});

function unMount() {
  for (const fn of unWatchFunctions.values()) {
    fn();
  }

  const docRef = doc(db, "screensessions", state.sessionID!);
  deleteDoc(docRef);
}

onBeforeUnmount(unMount);
window.onbeforeunload = unMount;
</script>

<style lang="scss" scoped>
header {
  padding: 10px 20px;
}
header img.logo {
  height: 30px;
}
.wrapper {
  width: 100%;
  height: calc(100% - 50px);
  background-color: #000;
  position: relative;
}

.wrapper.fullscreen .controls {
  display: none;
}

.wrapper .controls {
  margin-top: 10px;
  text-align: center;
}

.wrapper .brand {
  display: none;
  margin-bottom: 15px;
}

.wrapper.fullscreen .brand {
  display: block;
}

::v-deep img.logo {
  height: 60px;
}

.qr_container {
  color: #f1f1f1;
  padding: 20px;
  position: absolute;
  z-index: 110;
}

.qr_container.bottomRight {
  bottom: 20px;
  right: 20px;
}

.qr_container.bottomLeft {
  bottom: 20px;
  left: 20px;
}

.qr_container.topLeft {
  top: 20px;
  left: 20px;
}

.qr_container.topRight {
  top: 20px;
  right: 20px;
}

.qr_container.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qr_container.small .brand {
  display: none;
}

.videoBackground.fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.videoBackground.contain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}
</style>
