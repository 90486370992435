<script lang="ts" setup>
import { computed, ref } from "vue";

const tooltip = ref<HTMLElement>();
const container = ref<HTMLElement>();

const isPlacedAtBottom = computed(
    () => container.value ? container.value.classList.contains("bottom") : false);

const setPosition = () => {
  if (!tooltip.value) {
    return;
  }

  if (isPlacedAtBottom.value) {
    tooltip.value.style.bottom = `-${tooltip.value.offsetHeight}px`;
  } else {
    tooltip.value.style.top = `-${tooltip.value.offsetHeight}px`;
  }
}
</script>

<template>
  <div class="tooltip_container" ref="container">
    <div @mouseover="setPosition">
      <slot class="text"></slot>
    </div>
    <div class="tooltip" ref="tooltip">
      <slot name="tooltip"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: 3px;
  font-size: .8rem;
  line-height: 1.8;
  background: #404040;
  color: #fff;
  border-radius: .3rem;
  box-sizing: border-box;
  padding: 0 .4rem;
  top: -1.7rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;
  white-space: nowrap;
  z-index: 99;
}

.tooltip_container.multiline .tooltip {
  width: 400px;
  white-space: pre-line;
}

.tooltip_container.bottom .tooltip {
  bottom: -1.5em;
  top: auto;
}

.tooltip_container.left .tooltip {
  transform: none;
  left: 0;
}

.tooltip_container.right .tooltip {
  transform: translate(-95%, 0);
}

.tooltip_container:hover .tooltip {
  display: block;
}

.tooltip_container::v-deep span {
  vertical-align: middle;
}

.delayed .tooltip {
  opacity: 0;

  animation: fadeIn 0s;
  animation-delay: .7s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
</style>
